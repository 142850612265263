import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorConfigurationProvider } from '../../../../services/editor-configuration.provider';
import { StateService } from '../../../../services/state.service';
import { DisplayMode } from '../../../../types/display-mode';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnDestroy {
  @Input() isTrainingMode: boolean;

  devModeAvailable = false;
  devModeControl: FormControl;
  supportModeControl: FormControl;
  showDevModeToggle$: Observable<boolean>;

  subscriptions = new Subscription();

  constructor(
    private stateService: StateService,
    private cdRef: ChangeDetectorRef,
    editorConfigurationProvider: EditorConfigurationProvider
  ) {
    this.devModeControl = new FormControl(false);
    this.supportModeControl = new FormControl(false);
    this.devModeAvailable = editorConfigurationProvider.isDevModeAvailable();

    // ReSy: Zeigt den Debug-Schalter nur, wenn der Displaymodus aktiv ist
    this.showDevModeToggle$ = this.stateService
      .getDisplayMode()
      .pipe(map(displayMode => displayMode === DisplayMode.DEBUG));

    this.subscriptions.add(
      this.stateService.getDevMode().subscribe(nextDevMode => {
        if (!this.devModeAvailable && nextDevMode) {
          this.devModeControl.setValue(false, { emitEvent: false });
          this.stateService.setDevMode(false);
        } else {
          this.devModeControl.setValue(nextDevMode);
        }
        this.cdRef.markForCheck();
      })
    );

    this.subscriptions.add(
      this.devModeControl.valueChanges.subscribe(value => {
        this.stateService.setDevMode(value);
        this.cdRef.markForCheck();
      })
    );

    this.subscriptions.add(
      this.stateService.getSupportMode().subscribe(nextSupportMode => {
        this.supportModeControl.setValue(nextSupportMode, { emitEvent: false });
        this.cdRef.markForCheck();
      })
    );

    this.subscriptions.add(
      this.supportModeControl.valueChanges.subscribe(value => {
        this.stateService.setSupportMode(value);
        this.cdRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
