import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { LoadingSpinnerService } from '../../../portal/shared/loading-spinner/loading-spinner.service';
import { ComponentCanDeactivate } from '../../../portal/shared/pending-changes.guard';
import { CommunicationService } from '../../../services/communication.service';
import { ConceptModelService } from '../../../services/concept-model.service';
import { DatepickerService } from '../../../services/datepicker.service';
import { DynamicComponentFactoryService } from '../../../services/dynamic-component-factory.service';
import { FieldTypeService } from '../../../services/field-type.service';
import { IdGenerationService } from '../../../services/id-generation.service';
import { LoadingService } from '../../../services/loading.service';
import { MaintenanceWindowService } from '../../../services/maintenance-window.service';
import { NavigationService } from '../../../services/navigation.service';
import { NoticeSubmittingService } from '../../../services/notice-submitting.service';
import { RsNoticeSubmittingServiceV2 } from '../../../services/rs-notice-submitting-v2.service';
import { SearchService } from '../../../services/search/search.service';
import { SyncedFieldService } from '../../../services/synced-field.service';
import { TranslationService } from '../../../services/translation.service';
import { ExpansionService } from '../../../services/ui/expansion.service';
import { FormTitleService } from '../../../services/ui/form-title.service';
import { BusinessRuleValidationService } from '../../../services/validation/business-rule-validation.service';
import { DynamicModelPropertyService } from '../../../services/view-model/dynamic-model-property.service';
import { EFormsMetaDataService } from '../../../services/view-model/eforms-meta-data.service';
import { EVergabeMetaDataService } from '../../../services/view-model/evergabe-meta-data.service';
import { ModelListenerService } from '../../../services/view-model/model-listener.service';
import { RepeatableSectionService } from '../../../services/view-model/repeatable-section.service';
import { SchemeIdService } from '../../../services/view-model/scheme-id.service';
import { DisplayMode } from '../../../types/display-mode';
import { NoticeDefinition } from '../../../types/notice-definition';
import { NoticeSubType } from '../../../types/notice-types';
import { Language } from '../../../types/data-model';
import { StateService } from '../../../services/state.service';
import { NoticeDataResolver } from '../../../services/notice-data-resolver.service';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { ContactService } from '../../../services/contact.service';
import { firstValueFrom, Observable } from 'rxjs';
import { ModelStateService } from '../../../services/view-model/model-state.service';
import { ModelGenerationService } from '../../../services/view-model/model-generation.service';
import { ComponentModel } from '../../../view-model/component-model';
import { BaseModel, ModelWithChildren } from '../../../view-model/base-model';
import { MultilingualModel } from '../../../view-model/type/multilingual-model';
import { ModelValidationService } from '../../../services/view-model/model-validation.service';
import { ReadonlyService } from '../../../services/readonly.service';
import { ConceptModel } from '../../../types/concept-node';
import { CaptionService } from '../../../services/view-model/caption.service';
import { PrefillFieldsService } from '../../../services/prefill-fields.service';
import { SelectionCriterionService } from '../../../services/selection-criterion.service';
import { CodelistModelService } from '../../../services/ui/codelist-model.service';
import { ExpressionEvaluationService } from '../../../services/expression/expression-evaluation.service';
import { PreferredReleaseDateService } from '../../../services/view-model/preferred-release-date.service';
import { FormTypeService } from '../../../services/form-type.service';
import { RsTemplateSubmittingService } from '../../../services/rs-template-submitting.service';
import { SdkService } from "../../../services/sdk.service";
import { EFormsMigrationService } from "../../../services/e-forms-migration.service";
import { RepeatableService } from '../../form-components/repeatable/repeatable.service';

@Component({
  selector: 'app-form-root',
  styleUrls: ['./form-root.component.scss'],
  templateUrl: './form-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ModelValidationService,
    ModelGenerationService,
    RepeatableService,
    SchemeIdService,
    ModelListenerService,
    FormTitleService,
    ContactService,
    CaptionService,
    ExpansionService,
    EFormsMigrationService,
    SdkService,
    EVergabeMetaDataService,
    NoticeDataResolver,
    BusinessRuleValidationService,
    SyncedFieldService,
    DynamicModelPropertyService,
    ReadonlyService,
    NoticeSubmittingService,
    RsNoticeSubmittingServiceV2,
    RsTemplateSubmittingService,
    TranslationService,
    ConceptModelService,
    FieldTypeService,
    EFormsMetaDataService,
    DynamicComponentFactoryService,
    RepeatableSectionService,
    IdGenerationService,
    LoadingService,
    MaintenanceWindowService,
    DatepickerService,
    ModelStateService,
    NavigationService,
    SearchService,
    PrefillFieldsService,
    SelectionCriterionService,
    FormTypeService,
    CodelistModelService,
    ExpressionEvaluationService,
    PreferredReleaseDateService,
  ],
})
export class FormRootComponent implements OnInit, ComponentCanDeactivate {
  notice: NoticeDefinition;
  noticeSubType: NoticeSubType;
  importedConceptModel: ConceptModel;
  configInitialized = false;

  constructor(
    private stateService: StateService,
    private route: ActivatedRoute,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private noticeDataResolver: NoticeDataResolver,
    private contactService: ContactService,
    private cdRef: ChangeDetectorRef,
    private modelStateService: ModelStateService,
    private modelGenerationService: ModelGenerationService,
    private modelValidationService: ModelValidationService,
    private conceptModelService: ConceptModelService,
    private selectionCriterionService: SelectionCriterionService,
    private captionService: CaptionService,
    private communicationService: CommunicationService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {}

  // ReSy: Mechanismus, um Nutzer bei ungespeicherten Aenderungen beim Verlassen der Maske zu warnen
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    let modified: boolean;
    this.stateService
      .getModified()
      .pipe(take(1))
      .subscribe(value => (modified = value));
    return !modified;
  }

  async ngOnInit(): Promise<void> {
    // ReSy: zu Beginn einen LoadingSpinner anzeigen, um die UX zu verbessern
    setTimeout(() => this.loadingSpinnerService.start());

    this.stateService.reset();

    document.getElementsByTagName('html')[0].style['font-size'] = '14px';
    await this.contactService.loadContacts();
    await this.selectionCriterionService.loadSelectionCriteria();

    firstValueFrom(this.route.params).then(async params => {
      const noticeData = await this.noticeDataResolver.resolve(
        params['subTypeId'],
        this.editorConfigurationProvider.getTenderingProcedureId()
      );
      this.notice = noticeData.noticeDefinition;
      this.importedConceptModel = noticeData.importedConceptModel;

      if (this.importedConceptModel) {
        this.initLanguage(this.importedConceptModel);
      }

      this.captionService.init(noticeData.noticeDefinition);

      const componentModel = await this.modelGenerationService.generateModel(
        this.notice,
        this.importedConceptModel
      );
      this.modelStateService.initComponentModel(componentModel);
      this.initStateSubscriptions(componentModel);

      this.noticeSubType = noticeData.noticeSubType;

      this.configInitialized = true;
      // ReSy: LoadingSpinner wieder ausblenden, wenn Initialisierung abgeschlossen
      this.loadingSpinnerService.stop();
      this.cdRef.markForCheck();
    });

    // ReSy: beim Initialisieren der Maske den Displaymodus Displaymodus setzen und aus dem Backend anfragen
    this.stateService.setDevMode(false);
    this.stateService.setDisplayMode(DisplayMode.DETAIL);
    this.communicationService.getDisplayMode().then(value => {
      this.stateService.setDisplayMode(value);
    });
  }

  private initLanguage(conceptModel: ConceptModel): void {
    const mainLanguage = [
      conceptModel.root.children.find(obj => obj.businessTerm === 'BT-702(a)-notice')?.value,
    ];
    const allLanguages = mainLanguage.concat(
      conceptModel.root.children.find(obj => obj.businessTerm === 'BT-702(b)-notice')?.value
    );

    if (allLanguages?.includes(Language.Eng)) {
      this.stateService.addLanguage(Language.Eng);
    }

    if (!allLanguages?.includes(Language.Deu)) {
      this.stateService.removeLanguage(Language.Deu);
    }
  }

  private haveLanguagesNotChanged(newLanguages: Language[], oldLanguages: Language[]) {
    return (
      newLanguages.length === oldLanguages.length &&
      newLanguages.every(newActiveLanguage =>
        oldLanguages.some(alreadyActive => alreadyActive === newActiveLanguage)
      )
    );
  }

  private initStateSubscriptions(componentModel: ComponentModel) {
    this.stateService.getLanguages().subscribe(async newActiveLanguages => {
      if (
        !this.configInitialized ||
        this.haveLanguagesNotChanged(newActiveLanguages, componentModel.activeLanguages)
      ) {
        return;
      }

      componentModel.activeLanguages = newActiveLanguages;
      const conceptModel = await this.conceptModelService.generateConceptModel(componentModel);
      await this.triggerMultilingualModelUpdate(componentModel, conceptModel);
      this.modelValidationService.refreshNotifications(componentModel, true);
    });
  }

  private async triggerMultilingualModelUpdate(
    model: BaseModel | ComponentModel,
    conceptModel: ConceptModel
  ) {
    if (model instanceof MultilingualModel) {
      if (model.touched) {
        await this.modelValidationService.validateModel(model, true, conceptModel, false);
      }
      model.updateView();
    } else if (ModelStateService.hasChildren(model)) {
      for (const childNode of (model as ModelWithChildren).children) {
        await this.triggerMultilingualModelUpdate(childNode, conceptModel);
      }
    }
  }
}
