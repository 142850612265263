<table mat-table [dataSource]="dataSource" matSort matSortActive="username" matSortDirection="asc">
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Benutzername</th>
    <td mat-cell *matCellDef="let element">{{ element.username }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gruppenrolle</th>
    <td mat-cell *matCellDef="let element">{{ roleToLabel(element.role) }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Aktionen</th>
    <td mat-cell *matCellDef="let element">
      <a *ngIf="isMember(element) && iAmOwner" (click)="confirmRemoveMember(element)" class="action-link"
        >Mitglied entfernen</a
      >
      <a *ngIf="isMember(element) && (iAmOwner || hasSupportRole)" (click)="confirmTransferOwnership(element)" class="action-link"
        >Mitglied zum Besitzer machen</a
      >
      <a *ngIf="isInvited(element) && iAmOwner" (click)="handleWithdrawInvitation(element)" class="action-link"
        >Einladung zurückziehen</a
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns | filterDisplayColumns:['actions']:iAmOwner || hasSupportRole"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns | filterDisplayColumns:['actions']:iAmOwner || hasSupportRole"
    [ngClass]="{ owner: isOwner(row) }"></tr>
</table>
