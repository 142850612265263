import { ConceptModel, ConceptNode } from '../../types/concept-node';
import { Fields } from '../../types/field-types';
import { FieldValueMatch, FieldValueType, Language, LocalisedText } from '../../types/data-model';
import { BT_NOTICE_SUBTYPE, ND_ROOT } from './constants';
import { ConceptModelUtils } from './concept-model-utils';
import { BaseModel } from '../../view-model/base-model';
import { RepeatableGroupModel } from '../../view-model/repeatable-group-model';
import { GroupModel } from '../../view-model/group-model';
import { FieldModel } from '../../view-model/field-model';
import { RepeatableFieldModel } from '../../view-model/repeatable-field-model';
import { MultilingualModel } from '../../view-model/type/multilingual-model';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';
import { SectionModel } from '../../view-model/section-model';
import { ComponentModel } from '../../view-model/component-model';

export class ConceptModelGen {
  constructor(private fieldsDocument: Fields, private activeLanguages: Language[]) {}

  public createConceptModel(componentModel: ComponentModel): ConceptModel {
    const xmlStructure = this.fieldsDocument.xmlStructure;
    const root = xmlStructure.find(item => item.id === ND_ROOT);
    const rootConceptNode = new ConceptNode(root.id, null, root);
    this.parseChildren(rootConceptNode, [...componentModel.children]);
    return {
      noticeSubTypeCode: ConceptModelUtils.findFirstChild(rootConceptNode, BT_NOTICE_SUBTYPE)
        ?.value,
      root: rootConceptNode,
    } as ConceptModel;
  }

  private parseChildren(parent: ConceptNode, baseModel: BaseModel[]): void {
    this.fieldsDocument.xmlStructure
      .filter(item => parent.businessTerm === item.parentId)
      .forEach(item => {
        const repeatableGroups: BaseModel[] = this.findRepeatableGroups(baseModel, item.id);
        if (repeatableGroups.length > 0) {
          repeatableGroups.forEach(repeatableGroup => {
            const node = new ConceptNode(item.id, null, item);
            parent.children.push(node);
            node.sourceModel = repeatableGroup;
            node.parent = parent;
            this.parseChildren(node, [repeatableGroup]);
          });
        } else {
          const node = new ConceptNode(item.id, null, item);
          parent.children.push(node);
          node.parent = parent;
          this.parseChildren(node, baseModel);
        }
      });

    this.fieldsDocument.fields
      .filter(item => item.parentNodeId === parent.businessTerm)
      .forEach(item => {
        const matchedNodes = this.findFieldValues(baseModel, item.id);
        if (matchedNodes.length > 0) {
          matchedNodes.forEach(nodeMatch => {
            const node = new ConceptNode(item.id, nodeMatch.value, item, nodeMatch.modelRef);
            node.sourceModel = nodeMatch.modelRef;
            parent.children.push(node);
            node.parent = parent;
          });
        }
      });
  }

  private findRepeatableGroups(
    viewModel: BaseModel[],
    nodeId: string
  ): (GroupModel | SectionModel)[] {
    const matches: (GroupModel | SectionModel)[] = [];
    viewModel.forEach(node => {
      this._findRepeatableGroups(node, nodeId, matches);
    });
    return matches;
  }

  private _findRepeatableGroups(
    viewModel: BaseModel,
    id: string,
    matches: (GroupModel | SectionModel)[]
  ): void {
    if (viewModel instanceof RepeatableGroupModel || viewModel instanceof RepeatableSectionModel) {
      if (viewModel.noticeNode.nodeId === id) {
        matches.push(...viewModel.children);
      } else {
        viewModel.children.forEach(childModel =>
          this._findRepeatableGroups(childModel, id, matches)
        );
      }
    }

    if (viewModel instanceof GroupModel || viewModel instanceof SectionModel) {
      viewModel.children.forEach(childModel => this._findRepeatableGroups(childModel, id, matches));
    }
  }

  private findFieldValues(viewModels: BaseModel[], id: string): FieldValueMatch[] {
    const matches: FieldValueMatch[] = [];
    viewModels.forEach(viewModel => {
      this._findFieldValues(viewModel, id, matches);
    });
    return matches;
  }

  private _findFieldValues(viewModel: BaseModel, id: string, matches: FieldValueMatch[]): void {
    if (viewModel instanceof RepeatableGroupModel || viewModel instanceof RepeatableSectionModel) {
      viewModel.children.forEach((nestedGroup: GroupModel | SectionModel) =>
        nestedGroup.children.forEach(groupChild => this._findFieldValues(groupChild, id, matches))
      );
    }
    if (viewModel instanceof GroupModel || viewModel instanceof SectionModel) {
      viewModel.children.forEach(viewModelChild =>
        this._findFieldValues(viewModelChild, id, matches)
      );
    }

    if (viewModel.noticeNode.id === id) {
      if (viewModel instanceof RepeatableFieldModel) {
        viewModel.children?.forEach(child => {
          this.pushValues(matches, child.value, child);
        });
      } else if (viewModel instanceof FieldModel<any>) {
        this.pushValues(matches, viewModel.value, viewModel);
      }
    }
  }

  private pushValues(
    matches: FieldValueMatch[],
    value: FieldValueType,
    viewModel: BaseModel
  ): void {
    if (value === undefined || value === null) {
      matches.push({
        modelRef: viewModel,
        value: null,
      });
      return;
    }

    if (viewModel instanceof MultilingualModel) {
      this.activeLanguages.forEach(lang => {
        switch (lang) {
          case Language.Deu:
            matches.push({
              modelRef: viewModel,
              value: { text: viewModel.value.DEU, language: Language.Deu } as LocalisedText,
            });
            break;
          case Language.Eng:
            matches.push({
              value: { text: viewModel.value.ENG, language: Language.Eng } as LocalisedText,
              modelRef: viewModel,
            });
            break;
          default:
            throw new Error(`Unsupported language code ${lang}`);
        }
      });
    } else {
      matches.push({ value, modelRef: viewModel });
    }
  }

  public static isBt(id: string): boolean {
    return !!id.match(/(?:BT|OPP|OPT|OPA)-\d*/dgm);
  }
}
