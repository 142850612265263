import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextFieldComponent } from '../text-field.component';

@Component({
  templateUrl: '../text-field.component.html',
  styleUrls: ['../text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlComponent extends TextFieldComponent {
  placeholder = 'https://www.beispiel.de';
}
