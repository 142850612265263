import { createReducer, on } from '@ngrx/store';
import * as FormChangeTypeAction from './form-change-type.action';
import { ModelChangeType } from '../../types/model-changed-types';

export const initialState = ModelChangeType.UNCHANGED;

/**
 * Reducer for the change type of the form
 */
export const formChangeTypeReducer = createReducer(
  initialState,
  on(FormChangeTypeAction.updateChangeType, (_state, props) => props.modelChangeType)
);
