import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { StateService } from '../../../services/state.service';
import { IndicatorData } from '../../../store/app.state';
import { distinctUntilChanged } from 'rxjs/operators';
import { IdRefModel } from '../../../view-model/type/id-ref.model';

@Component({
  templateUrl: './id-ref.component.html',
  styleUrls: ['./id-ref.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdRefComponent extends AbstractFormComponent implements OnInit {
  control = new FormControl();
  options: { id: string; label: string; showId: boolean }[] = [];
  hasError: boolean;
  errorMessage: string;
  placeholder = 'Bitte auswählen';
  model: IdRefModel;

  constructor(private stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    this.handleIndicatorChanges();
    this.handleValueChanges();
  }

  private updateOptions(indicators: Map<string, IndicatorData>): void {
    this.options = [];

    this.model.noticeNode._idSchemes?.forEach(scheme => {
      indicators.get(scheme)?.values.forEach(value => {
        const label = value.label ?? `${value.id}`;
        this.options.push({ id: value.id, label, showId: true });
      });
    });
  }

  private handleIndicatorChanges(): void {
    this.subscription.add(
      this.stateService.getIndicator().subscribe(indicators => {
        this.updateOptions(indicators);

        if (this.model.value && !this.options.find(item => item.id === this.model.value)) {
          this.model.value = null;
          this.control.setValue('');
        } else {
          this.control.setValue(
            this.model.value ? this.options.find(item => item.id === this.model.value) : ''
          );
        }
        this.cdRef.markForCheck();
      })
    );
  }

  private handleValueChanges(): void {
    this.subscription.add(
      this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        const newValue = value?.id ? value.id : null;
        if (this.model.value !== newValue) {
          this.model.value = newValue;
          this.onInputValueChanged();
        }
      })
    );
  }
}
