import { Component, OnInit } from '@angular/core';
import { ModelStateService } from '../../../../services/view-model/model-state.service';
import { RepeatableGroupModel } from '../../../../view-model/repeatable-group-model';
import { CodelistModel } from '../../../../view-model/type/codelist-model';
import { AbstractFormComponent } from '../../abstract-form/abstract-form.component';

@Component({
  selector: 'app-gr-lot-result-received-submissions',
  templateUrl: './gr-lot-result-received-submissions.component.html',
})

export class GrLotResultReceivedSubmissionsComponent extends AbstractFormComponent implements OnInit {
  model: RepeatableGroupModel;
  isVisible: boolean;
  labelPrefix: string = 'Anzahl der ';

  ngOnInit(): void {
    const zuschlagsstatus = ModelStateService.findModelById<CodelistModel>(this.model.parent, 'BT-142-LotResult');
    this.isVisible = this.evaluateVisibility(zuschlagsstatus);

    this.subscription.add(zuschlagsstatus.modelChangedEmitter.subscribe(() => {
      this.isVisible = this.evaluateVisibility(zuschlagsstatus);
    }));
  }

  private evaluateVisibility(model: CodelistModel): boolean {
    return model.value !== 'open-nw';
  }
}
