import { FieldModel } from '../field-model';
import { DateModel } from './date-model';

/**
 * This model represents the seconds passed since the beginning of the day.
 */
export class TimeModel extends FieldModel<string> {
  private _relatedDateModel: DateModel;

  constructor() {
    super();
  }

  get relatedDateModel(): DateModel {
    return this._relatedDateModel;
  }

  set relatedDateModel(value: DateModel) {
    this._relatedDateModel = value;
  }
}
