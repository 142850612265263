import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { TemplateOverviewResponse } from '../../api/templates';

export enum ActionType {
  CREATE_NOTICE = 'Bekanntmachung anlegen',
  EDIT_TEMPLATE = 'Vorlage bearbeiten',
  COPY_TEMPLATE = 'Vorlage kopieren',
  DELETE_TEMPLATE = 'Vorlage löschen',

  COPY_TEMPLATE_OF_GROUPMEMBER = 'Vorlage zu mir kopieren',
  CREATE_NOTICE_FROM_GROUPMEMBER = 'Bekanntmachung bei mir anlegen',
}

export interface ActionEvent {
  id: number;
  actiontype: ActionType;
}

@Component({
  selector: 'app-vorlagen-uebersicht-table',
  templateUrl: './vorlagen-uebersicht-table.component.html',
  styleUrls: ['./vorlagen-uebersicht-table.component.scss'],
})
export class VorlagenUebersichtTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  @Input() noticeTemplateOverviews: TemplateOverviewResponse[];
  @Input() iAmOwner: boolean;
  @Output() clickActionType = new EventEmitter<ActionEvent>();
  @Output() clickEditName = new EventEmitter<number>();

  dataSource: MatTableDataSource<TemplateOverviewResponse> = new MatTableDataSource();
  displayedColumns = [
    'noticeSubTypeCode',
    'edit',
    'name',
    'description',
    'customizationId',
    'lastEditedAt',
    'actions',
  ];

  constructor(private genericDialogService: GenericDialogService) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.noticeTemplateOverviews);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.noticeTemplateOverviews?.currentValue) {
      this.dataSource.data = changes.noticeTemplateOverviews.currentValue;
    }
  }

  onActionClick(element: TemplateOverviewResponse, action: ActionType) {
    this.clickActionType.emit({ id: element.templateId, actiontype: action });
  }

  onClickEditName(templateOverview: TemplateOverviewResponse) {
    this.clickEditName.emit(templateOverview.templateId);
  }

  onClickWarnIcon() {
    this.genericDialogService.openWarningDialog(
      'Aktualisierung des eForms-Standards',
      'Verstanden',
      '<p>Der eForms-Standard wurde aktualisiert, seit diese Vorlage das letzte Mal bearbeitet wurde. </p>' +
        '<p>Mit der Aktion "Vorlage bearbeiten" können Sie die Vorlage öffnen, bearbeiten und gemäß des neuen eForms-Standards speichern. Danach können Sie die Vorlage wie gewohnt weiter nutzen.</p>'
    );
  }

  getActionsOfTemplate(template: TemplateOverviewResponse): ActionType[] {
    if (this.iAmOwner) {
      if (template.isLatestSDKVersion) {
        return [
          ActionType.CREATE_NOTICE,
          ActionType.EDIT_TEMPLATE,
          ActionType.COPY_TEMPLATE,
          ActionType.DELETE_TEMPLATE,
        ];
      } else {
        return [ActionType.EDIT_TEMPLATE, ActionType.DELETE_TEMPLATE];
      }
    } else {
      if (template.isLatestSDKVersion) {
        return [
          ActionType.CREATE_NOTICE_FROM_GROUPMEMBER,
          ActionType.COPY_TEMPLATE_OF_GROUPMEMBER,
        ];
      } else {
        return [ActionType.COPY_TEMPLATE_OF_GROUPMEMBER];
      }
    }
  }
}
