import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import {
  NoticeControllerService,
  ViewerResponseDto,
} from '../../api/notices';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';

@Injectable({
  providedIn: 'root',
})
export class BekanntmachungenVerwaltungService {

  constructor(
    private noticeControllerService: NoticeControllerService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {}

  getPreview(rsNoticeId: number): Observable<ViewerResponseDto> {
    this.loadingSpinnerService.start();
    return this.noticeControllerService.getNoticePreview(rsNoticeId).pipe(
      tap(response => {
        if (response?.url) {
          window.open(response.url, '_blank');
        }
      }),
      finalize(() => this.loadingSpinnerService.stop()),
    );
  }

  exportNotice(rsNoticeId: number) {
    this.loadingSpinnerService.start();
    return this.noticeControllerService.getNoticeExportAsZip(rsNoticeId, 'response').pipe(
      tap(response => {
        const blob = response.body as unknown as Blob;

        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition?.match(/(?<=filename=)(.+)/)?.[1] || 'export.zip';

        this.openBlobAsUrl(blob, filename);
      }),
      finalize(() => this.loadingSpinnerService.stop()),
    );
  }

  importNotice(data: Blob): Observable<any> {
    return this.noticeControllerService.importNotice(data as unknown as Array<string>);
  }

  private openBlobAsUrl(blob: Blob, filename: string | null) {
    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');

    downloadLink.href = blobUrl;
    downloadLink.download = filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
