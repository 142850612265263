import { FieldModel } from '../field-model';
import { TimeModel } from './time-model';
import { LocalDate, LocalDateTime, LocalTime } from '@js-joda/core';

export class DateModel extends FieldModel<string> {
  private _relatedTimeModel: TimeModel;

  // Minimal Date selectable in Date-Picker
  private _minDate: LocalDate;

  constructor() {
    super();
  }

  get relatedTimeModel(): TimeModel {
    return this._relatedTimeModel;
  }

  set relatedTimeModel(value: TimeModel) {
    this._relatedTimeModel = value;
  }

  asLocalDate(): LocalDate {
    if (this.value) {
      if (this.value.endsWith('Z')) {
        this.value = this.value.slice(0, this.value.length - 1);
      }
      return LocalDate.parse(this.value);
    }
    return null;
  }

  /**
   * Returns the Model-Value as Date with the time part, of the related TimeModel. If no related model, this will return the date at Midnight.
   */
  withRelatedTime(): LocalDateTime {
    if (!this.relatedTimeModel?.value) {
      // return midnight date, when time not set yet
      return this.asLocalDate()?.atStartOfDay();
    }

    const localDate = this.asLocalDate();
    if (!localDate) return null;
    const localTime = LocalTime.parse(this.relatedTimeModel.value);
    return LocalDateTime.of(localDate, localTime);
  }

  get minDate(): LocalDate {
    return this._minDate;
  }

  set minDate(value: LocalDate) {
    this._minDate = value;
  }
}
