import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { CommunicationService } from '../../../communication.service';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';

/**
 * Checks, if the internal reference is already used by another TenderingProcedure
 */
export class InternalReferenceValidator extends EVergabeSpecificValidator<FieldModel<string>> {
  private static ENDS_WITH_DOT_ERROR_MESSAGE =
    'Die interne Kennung darf nicht mit einem Punkt enden.';
  private static INTERNAL_REFERENCE_ALREADY_USED_MESSAGE =
    'Die interne Kennung wird bereits Verwendet.';
  private static CONTAINS_P_TAGS_ERROR_MESSAGE =
    'Die Eingabe darf keine P-Tags enthalten (<p>, </p>, <P> und </P>).';
  private static P_TAG_BLACKLIST = ['<p>', '</p>', '<P>', '</P>'];

  constructor(
    private communicationService: CommunicationService,
    private editorConfigurationProvider: EditorConfigurationProvider
  ) {
    super();
  }

  shouldAttach(model: FieldModel<string>): boolean {
    return (
      model.noticeNode.id === 'BT-22-Procedure' &&
      !this.editorConfigurationProvider.getTenderingProcedureId()
    );
  }

  /**
   * Validates the Internal Reference for e-Vergabe specific Requirements
   * * MaxLength: 50 (Already given in eForms-SDK)
   * * Not ending with a Period
   * * Can't contain Characters, which are not encodable in ISO-8859-1
   * * Not used by another already existing procedure
   */
  async validate(model: FieldModel<string>): Promise<ValidationNotification[]> {
    const reference = model.value;
    if (!reference) {
      return this.empty();
    }

    if (reference.endsWith('.')) {
      return this.ofSingle(InternalReferenceValidator.ENDS_WITH_DOT_ERROR_MESSAGE);
    }

    if (InternalReferenceValidator.P_TAG_BLACKLIST.find(tag => reference.includes(tag))) {
      return this.ofSingle(InternalReferenceValidator.CONTAINS_P_TAGS_ERROR_MESSAGE);
    }

    const invalidCharsErrorMessage = await this.getInvalidCharErrorMessage(reference);
    if (invalidCharsErrorMessage) {
      return this.ofSingle(invalidCharsErrorMessage);
    }

    if (!(await this.communicationService.isInternalReferenceAvailable(reference))) {
      return this.ofSingle(InternalReferenceValidator.INTERNAL_REFERENCE_ALREADY_USED_MESSAGE);
    }

    return this.empty();
  }

  private async getInvalidCharErrorMessage(reference: string): Promise<string | null> {
    const invalidCharactersInReference =
      await this.communicationService.getInvalidCharacterInReferenceNumber(reference);

    if (invalidCharactersInReference.length > 0) {
      return `Die Eingabe enthält ungültige Zeichen. Häufig handelt es sich dabei um Aufzählungszeichen aus ` +
      `Textverarbeitungsprogrammen. Die folgenden Zeichen sind nach DIN SPEC 91379 ungültig: ` +
      `${invalidCharactersInReference.join(
        ','
      )}`;
    }
    return null;
  }
}
