<app-field-header
  *ngIf="isField && !model.isInsideRepeatable"
  [hidden]="model.isHidden"
  [model]="fieldHeaderModel">
</app-field-header>

<ng-container *ngFor="let child of fieldChildren; let i = index">
  <app-repeatable-field
    (addComponent)="addComponent()"
    (deleteComponent)="deleteComponent(i)"
    *ngIf="isField"
    [showAddButton]="fieldChildren.length === i + 1"
    [isLastItem]="fieldChildren.length === 1"
    [model]="child">
  </app-repeatable-field>
</ng-container>
<ng-container *ngFor="let child of groupChildren; let i = index">
  <app-repeatable-group
    (addComponent)="addComponent()"
    (deleteComponent)="deleteComponent(i)"
    *ngIf="!isField"
    [showAddButton]="groupChildren.length === i + 1"
    [showDeleteButton]="showDeleteButton(groupChildren)"
    [model]="child">
  </app-repeatable-group>
</ng-container>

<div *ngIf="showBaseElement" class="group-wrapper">
  <mat-expansion-panel disabled>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span [outerHTML]="getPanelTitle()"></span>
      </mat-panel-title>
      <div *ngIf="!model.isReadonly" class="button-container">
        <button
          mat-mini-fab
          (click)="addComponent(); $event.stopPropagation();"
          (keydown.space)="addComponent(); stopDefaultBehavior($event)"
          (keydown.enter)="addComponent(); stopDefaultBehavior($event)"
          class="repeatable-control eForm-icon-button normal"
          matTooltip="Element hinzufügen"
          aria-label="Element hinzufügen">
          <mat-icon>+</mat-icon>
        </button>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</div>
