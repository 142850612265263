import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { GenericDialogService } from '../../services/ui/generic-dialog.service';
import { NewsDto } from '../api/notices';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-neuigkeiten-verwaltung',
  templateUrl: './neuigkeiten-verwaltung.component.html',
  styleUrls: ['./neuigkeiten-verwaltung.component.scss'],
})
export class NeuigkeitenVerwaltungComponent {
  titleMaxLength = 500;

  newsForm = this.formBuilder.group({
    date: [this.convertToDatetimeLocalValue(new Date()), Validators.required],
    type: [NewsDto.TypeEnum.LandingPageNews, Validators.required],
    title: ['', [Validators.required, Validators.maxLength(this.titleMaxLength)]],
    content: [''],
  });
  types = Object.values(NewsDto.TypeEnum);
  news$: Observable<NewsDto[]>;
  displayedColumns: string[] = ['date', 'type', 'title', 'actions'];

  @ViewChild('newsFormElm') newsFormElm: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private newsService: NewsService,
    private router: Router,
    private genericDialogService: GenericDialogService
  ) {
    this.news$ = this.newsService.getNews();
    this.newsService.updateNews();
  }

  get title() {
    return this.newsForm.get('title');
  }

  onSubmit(): void {
    const newsItem: NewsDto = {
      id: 0,
      type: this.newsForm.value.type,
      content: this.newsForm.value.content?.trim().replace(/[\n\r\t]/g, ''),
      newsDate: new Date(this.newsForm.value.date),
      title: this.newsForm.value.title?.trim(),
    };

    this.newsService
      .addNews(newsItem)
      .pipe(
        tap(() => this.router.navigate(['.'])),
        catchError(err => {
          this.genericDialogService.openInfoDialog(
            'Fehler beim Speichern der Neuigkeit',
            'Schließen',
            'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.'
          );
          return err;
        })
      )
      .subscribe();
  }

  deleteAll(): void {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Alle Neuigkeiten löschen',
      'Alle Neuigkeiten löschen',
      'Abbrechen',
      'Sind Sie sicher, dass Sie alle Neuigkeiten unwiderruflich löschen möchten?'
    );

    from(confirmationResult)
      .pipe(
        filter(confirmed => confirmed),
        switchMap(() => this.newsService.deleteAllNews()),
        tap(() => this.router.navigate(['.'])),
        catchError(err => {
          this.genericDialogService.openInfoDialog(
            'Fehler beim Löschen der Neuigkeiten',
            'Schließen',
            'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.'
          );
          return err;
        })
      )
      .subscribe();
  }

  deleteNews(id: number) {
    this.newsService.deleteNews(id).subscribe();
  }

  copyNews(news: NewsDto) {
    this.newsForm.patchValue({
      title: news.title,
      type: news.type,
      content: news.content,
    });
    this.newsFormElm.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  /**
   * Generiert aus dem aktuellen Date einen String, der als Value fuer das `datetime-local`-Feld
   * dient.
   * Beispiel: 05.03.2024 14:22:56.123 => "2024-03-05T14:22"
   */
  private convertToDatetimeLocalValue(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
}
