import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProcedureTypeService {
  private static readonly TYPES_WITH_PARTICIPATION_PHASE = new Set<string>([
    'restricted',
    'neg-w-call',
    'comp-dial',
    'innovation',
  ]);

  public isProcedureTypeWithParticipationPhase(codelistValue: string) {
    return ProcedureTypeService.TYPES_WITH_PARTICIPATION_PHASE.has(codelistValue);
  }
}
