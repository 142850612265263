import {Injectable} from '@angular/core';
import {ComponentModel} from '../view-model/component-model';
import {FieldModel} from '../view-model/field-model';
import {ModelStateService} from './view-model/model-state.service';
import {Severity, ValidationNotification} from '../types/app-types';
import {SdkService} from './sdk.service';
import {GenericDialogService} from './ui/generic-dialog.service';
import {EditorConfigurationProvider} from './editor-configuration.provider';

/**
 * Service for Displaying Warnings when Opening a Notice in a new eForms Version, which contains migrated field.
 */
@Injectable()
export class EFormsMigrationService {
  private migratedFieldIds: Set<string> = null;

  constructor(
    private sdkService: SdkService,
    private genericDialogService: GenericDialogService,
    private editorConfigurationProvider: EditorConfigurationProvider
  ) {}

  /**
   * Changes the Notice-Version Field to the current Version available in the SDK and displays a warning on all field, which have been migrated.
   */
  public async loadMigrationWarnings(componentModel: ComponentModel): Promise<void> {
    if (!this.editorConfigurationProvider.isMigratedFromEarlierEFormsVersion()) {
      return;
    }

    const currentVersion = ModelStateService.findModelById<FieldModel<string>>(
      componentModel,
      'OPT-002-notice'
    );

    const eFormsDEVersionFromModelValue = this.getMajorVersionFromModelValue(currentVersion.value);
    if (this.migratedFieldIds == null && eFormsDEVersionFromModelValue != null) {
      await this.loadMigratedFields(eFormsDEVersionFromModelValue, componentModel.noticeId);
      this.addWarningsForMigratedFields(componentModel);
      if (!this.editorConfigurationProvider.isRS()) {
        await this.genericDialogService.openInfoDialog(
          'Verfahren wurde erfolgreich migriert',
          'Bestätigen',
          'Die Bekanntmachung wurde automatisiert in einer neueren e-Forms DE Version geladen und dabei migriert. Bitte prüfen Sie sorgfältig, dass die unter "Warnungen" aufgelisteten Felder weiterhin korrekt sind. Falls Sie keine Aktualisierung der Bekanntmachung wünschen, aber Metadaten aktualisieren wollen, ist dies auch weiterhin möglich, solange nur Felder innerhalb der Metadaten Sektion geändert wurden.'
        );
      }
    }
  }

  private addWarningsForMigratedFields(componentModel: ComponentModel): boolean {
    const migratedModels = ModelStateService.findModelsByIds(componentModel, this.migratedFieldIds);
    migratedModels.forEach(model => {
      if (model instanceof FieldModel) {
        model.fieldNotifications.push(
          new ValidationNotification(
            'Dieses Feld wurde auf eine neue eForms Version migriert. Bitte stellen Sie sicher, dass alle Werte weiterhin korrekt sind.',
            Severity.WARNING
          )
        );
      }
    });
    return false;
  }

  private getMajorVersionFromModelValue(sdkVersionWithMinor: string): string | null {
    const pattern = /eforms-(de|sdk)-(\d+\.\d+).*/;
    const match = RegExp(pattern).exec(sdkVersionWithMinor);
    return match.length > 2 ? match[2] : null;
  }

  private async loadMigratedFields(version: string, subTypeId: string): Promise<void> {
    const migratedFields = await this.sdkService.getMigratedFields(version);
    this.migratedFieldIds = new Set<string>();
    if (migratedFields?.[subTypeId]) {
      migratedFields[subTypeId].forEach(id => this.migratedFieldIds.add(id));
    }
  }
}
