import { GlobalConfig } from '@eui/core';

export const GLOBAL: GlobalConfig = {
  appTitle: 'erfassungsmaske',
  i18n: {
    i18nService: {
      defaultLanguage: 'de',
      languages: ['en', 'de'],
    },
    i18nLoader: {
      i18nFolders: ['i18n-eui', 'i18n', 'i18n-ecl'],
    },
  },
  locale: {
    id: 'de-DE',
    available: ['de-DE', 'en-US'],
    bindWithTranslate: false,
  },
  user: {
    defaultUserPreferences: {
      dashboard: {},
      lang: 'de-DE',
    },
  },
};
