import { FieldModel } from '../field-model';

export class IndicatorModel extends FieldModel<boolean> {
  getSchemeId(): string {
    return this.noticeNode._idScheme;
  }

  get value(): boolean {
    return super.value;
  }

  set value(value: boolean) {
    super.value = value;
  }
}
