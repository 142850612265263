import { Injectable } from '@angular/core';
import { SdkType } from '../../types/sdk-type';
import { SectionModel } from '../../view-model/section-model';
import { ContentType, DisplayType, NoticeNode } from '../../types/notice-definition';
import { GroupModel } from '../../view-model/group-model';
import { firstValueFrom } from 'rxjs';
import { RsEditorConfigurationProvider } from '../rs-editor-configuration.provider';
import { ModelGenerationService } from './model-generation.service';
import { SdkService } from '../sdk.service';
import { StateService } from '../state.service';
import { IdGenerationService } from '../id-generation.service';
import { ComponentModel } from '../../view-model/component-model';
import { ConceptModel, ConceptNode } from '../../types/concept-node';
import { ConceptModelService } from '../concept-model.service';
import { ConceptModelUtils } from '../parser/concept-model-utils';

@Injectable()
export class EFormsMetaDataService {
  private _eFormsMetaData: GroupModel;

  constructor(
    private sdkService: SdkService,
    private stateService: StateService,
    private idGenerationService: IdGenerationService,
    private conceptModelService: ConceptModelService,
    private rsConfig: RsEditorConfigurationProvider
  ) {}

  public async createEFormsMetadata(
    detailsSection: SectionModel,
    metadata: NoticeNode[],
    modelGenerationService: ModelGenerationService,
    componentModel: ComponentModel,
    importedConceptModel?: ConceptModel
  ): Promise<void> {
    const eFormsMetaData = new GroupModel();
    eFormsMetaData.parent = detailsSection;
    const metaDataConceptNode = await this.createConceptModelForEFormsMetadata(
      metadata,
      importedConceptModel
    );

    const fields = await Promise.all(
      metadata.map(metaDataField => {
        if (metaDataField._repeatable) {
          return modelGenerationService.addRepeatableField(
            metaDataField,
            eFormsMetaData,
            metaDataConceptNode
          );
        }
        return modelGenerationService.addField(
          metaDataField,
          eFormsMetaData,
          this.conceptModelService.findFieldValue(metaDataField, metaDataConceptNode)
        );
      })
    );

    fields.forEach(field => eFormsMetaData.addChild(field));

    this._eFormsMetaData = eFormsMetaData;
    detailsSection.children.push(eFormsMetaData);
    eFormsMetaData.translatedLabel = 'eForms Metadaten';
    eFormsMetaData.root = componentModel;
    eFormsMetaData.noticeNode = {
      id: EFORMS_METADATA_ID,
      contentType: ContentType.GROUP,
      displayType: DisplayType.GROUP,
      description: '',
      _label: `field|name|${EFORMS_METADATA_ID}`,
    };
  }

  get eFormsMetaData(): GroupModel {
    return this._eFormsMetaData;
  }

  /**
   * Creates the eForms-Metadata Section and prefills it with data from the ConceptModel. Some bts are form-specific or need to be generated when empty.
   */
  private async createConceptModelForEFormsMetadata(
    metadata: NoticeNode[],
    conceptModel?: ConceptModel
  ): Promise<ConceptNode> {
    const noticeSubTypeId = await firstValueFrom(this.stateService.getNoticeId());
    const noticeTypes = await this.sdkService.getNoticeTypes();
    const noticeSubType = noticeTypes.noticeSubTypes.find(it => it.subTypeId === noticeSubTypeId);

    const metaRootNode = new ConceptNode('ND-ROOT', null, null);
    metaRootNode.children = metadata
      .map(item => {
        switch (item.id) {
          case 'BT-02-notice':
            return { businessTerm: item.id, value: noticeSubType.type } as ConceptNode;
          case 'BT-03-notice':
            return { businessTerm: item.id, value: noticeSubType.formType } as ConceptNode;
          case 'BT-04-notice':
          case 'BT-701-notice':
            return {
              businessTerm: item.id,
              value: this.findBtValueOrDefault(
                conceptModel,
                item.id,
                this.idGenerationService.getRandomUUIDv4()
              ),
            } as ConceptNode;
          case 'BT-757-notice':
            return {
              businessTerm: item.id,
              value: this.findBtValueOrDefault(conceptModel, item.id, '01'),
            } as ConceptNode;
          case 'BT-702(a)-notice':
            return {
              businessTerm: item.id,
              value: this.findBtValueOrDefault(conceptModel, item.id, 'DEU'),
            } as ConceptNode;
          case 'OPT-001-notice':
            return { businessTerm: item.id, value: noticeTypes.ublVersion } as ConceptNode;
          case 'OPT-002-notice':
            // ReSy: Im EU-SDK gibt es keine presetValues fuer OPT-002-notice, daher hier den "alten" Weg anwenden
            if (this.rsConfig.getSdk() === SdkType.EU) {
              return {
                businessTerm: item.id,
                value: this.parseEFormsVersionFromEUSDK(noticeTypes.sdkVersion)
              } as ConceptNode;
            } else {
              return {
                businessTerm: item.id,
                value: this.findBtValueOrDefault(conceptModel, item.id, null),
              } as ConceptNode;
            }
          case 'OPP-070-notice':
            return { businessTerm: item.id, value: noticeSubType.subTypeId } as ConceptNode;
          default:
            return {
              businessTerm: item.id,
              value: this.findBtValueOrDefault(conceptModel, item.id, null),
            } as ConceptNode;
        }
      })
      .filter(item => item !== undefined);

    return metaRootNode;
  }

  private findBtValueOrDefault(conceptModel: ConceptModel, bt: string, defaultValue: any) {
    if (!conceptModel) return defaultValue;

    const btNode = ConceptModelUtils.findFirstChild(conceptModel.root, bt);
    if (btNode) {
      return btNode.value;
    }
    return defaultValue;
  }

  //Input: 1.10.0
  //Output: eforms-sdk-1.10
  private parseEFormsVersionFromEUSDK(euSdkFullVersion: string): string {
    const pattern = /(\d+\.\d+).*/;
    const match = euSdkFullVersion.match(pattern);

    if (match && match.length > 0) {
      return `eforms-sdk-${match[1]}`;
    } else {
      throw new Error('Can not parse EU-SDK-Version: ' + euSdkFullVersion);
    }
  }
}

export const EFORMS_METADATA_ID = 'eforms-meta-data';
