<div class="form-component-input-group" [hidden]="model.isHidden">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"> </app-field-header>
  <div class="input-group-addon">
    <app-select
      [control]="control"
      [options]="options"
      [isMandatory]="model.isMandatory"
      [isReadonly]="model.isReadonly"
      [hasError]="hasInputError()"
      [htmlId]="htmlId"
      [ariaLabel]="model.translatedLabel"></app-select>
  </div>
  <app-validation-message [model]="model"></app-validation-message>
</div>
