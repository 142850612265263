import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigableNotification } from '../../../types/NavigableNotification';
import { Subscription } from 'rxjs';
import { Severity } from '../../../types/app-types';
import { ModelValidationService } from '../../../services/view-model/model-validation.service';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotificationBoxComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Output() headerSizeOut: EventEmitter<number> = new EventEmitter<number>();
  @Output() expandOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  notifications: NavigableNotification[] = [];
  errors: NavigableNotification[] = [];
  warnings: NavigableNotification[] = [];
  Severity = Severity;
  selectedSeverity = null;
  isExpanded = false;

  private subscription = new Subscription();

  constructor(
    private modelValidationService: ModelValidationService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.refreshHeaderSize();
  }

  ngOnInit(): void {
    this.initNotificationChangeHandling();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  buttonClicked(event: Event, severity: Severity) {
    if (this.errors.length + this.warnings.length > 0) {
      if (this.selectedSeverity === severity) {
        this.setExpanded(!this.isExpanded);
      } else {
        this.setExpanded(true);
      }
      this.selectedSeverity = severity;
    }
  }

  headerClicked() {
    if (this.selectedSeverity === null) return;
    this.setExpanded(!this.isExpanded);
  }

  private initNotificationChangeHandling() {
    this.subscription.add(
      this.modelValidationService.formNavigableNotifications.subscribe(notifications => {
        this.handleNotificationChange(notifications);
      })
    );
  }

  private handleNotificationChange(notifications: NavigableNotification[]) {
    this.notifications = notifications;
    this.errors = this.filterNotificationsBySeverity(notifications, Severity.ERROR);
    this.warnings = this.filterNotificationsBySeverity(notifications, Severity.WARNING);
    if (this.errors.length === 0 && this.warnings.length === 0) {
      this.selectedSeverity = null;
      this.setExpanded(false);
    } else if (this.errors.length === 0) {
      this.selectedSeverity = Severity.WARNING;
    } else {
      this.selectedSeverity = Severity.ERROR;
    }

    if (this.errors.length > 0 || this.warnings.length > 0) {
      this.setExpanded(true);
    }

    this.cdRef.markForCheck();
  }

  private filterNotificationsBySeverity(
    notifications: NavigableNotification[],
    severity: Severity
  ): NavigableNotification[] {
    return notifications.filter(item => item.validationNotification.severity === severity);
  }

  private setExpanded(expanded: boolean) {
    if (expanded !== this.isExpanded) {
      this.isExpanded = expanded;
      this.expandOut.emit(this.isExpanded);
    }
  }

  private refreshHeaderSize() {
    const header = document.getElementById('notification-box-header');
    this.headerSizeOut.emit(header?.offsetHeight);
  }
}
