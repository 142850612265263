import { Injectable } from '@angular/core';
import { SdkService } from './sdk.service';
import { ConceptModelGen } from './parser/concept-model-gen';
import { StateService } from './state.service';
import { firstValueFrom } from 'rxjs';
import { ConceptModel, ConceptNode } from '../types/concept-node';
import { ComponentModel } from '../view-model/component-model';
import { NoticeNode } from '../types/notice-definition';
import { Amount, FieldValueType, Measure, Multilingual } from '../types/data-model';
import { FieldInfoType } from '../types/field-types';

@Injectable()
export class ConceptModelService {
  constructor(private sdkService: SdkService, private stateService: StateService) {}

  public async generateConceptModel(componentModel: ComponentModel): Promise<ConceptModel> {
    const activeLanguages = await firstValueFrom(this.stateService.getLanguages());
    const fieldDef = await this.sdkService.getFields();

    const conceptModelGen = new ConceptModelGen(fieldDef, activeLanguages);

    return conceptModelGen.createConceptModel(componentModel);
  }

  public findFieldValue(noticeNode: NoticeNode, importedConceptNode?: ConceptNode): FieldValueType {
    if (this.needsValueMapping(noticeNode)) {
      return this.findAndMapSpecialFieldValue(noticeNode, importedConceptNode);
    }
    if (importedConceptNode?.businessTerm === noticeNode.id) {
      return importedConceptNode.value;
    }

    if (!importedConceptNode || !importedConceptNode.children) {
      return null;
    }

    for (const child of importedConceptNode.children) {
      const found = this.findFieldValue(noticeNode, child);
      if (found !== undefined && found !== null) return found;
    }
  }

  public findRepeatableFieldNode(
    noticeNode: NoticeNode,
    importedConceptNode: ConceptNode,
    matched: ConceptNode[]
  ) {
    if (importedConceptNode?.businessTerm === noticeNode.id) {
      return matched.push(importedConceptNode);
    }

    if (!importedConceptNode || !importedConceptNode.children) {
      return;
    }

    for (const child of importedConceptNode.children) {
      this.findRepeatableFieldNode(noticeNode, child, matched);
    }
  }

  private needsValueMapping(noticeNode: NoticeNode) {
    const fieldInfo = this.sdkService.getFieldInfo(noticeNode.id);
    return [FieldInfoType.TEXT_MULTILINGUAL, FieldInfoType.AMOUNT, FieldInfoType.MEASURE].includes(
      fieldInfo.type
    );
  }

  private findAndMapSpecialFieldValue(
    noticeNode: NoticeNode,
    conceptModel?: ConceptNode
  ): FieldValueType {
    const matched: ConceptNode[] = [];
    this.findRepeatableFieldNode(noticeNode, conceptModel, matched);
    if (matched.length === 0) {
      return null;
    }
    switch (this.sdkService.getFieldInfo(noticeNode.id)?.type) {
      case FieldInfoType.TEXT_MULTILINGUAL:
        const multilingual = {} as Multilingual;
        const eng = matched.find(i => i.qualifier === 'ENG')?.value;
        const deu = matched.find(i => i.qualifier === 'DEU')?.value;

        if (deu) {
          multilingual.DEU = deu;
        }
        if (eng) {
          multilingual.ENG = eng;
        }
        return multilingual;
      case FieldInfoType.AMOUNT:
        return {
          amount: matched[0].value,
          currencyID: matched[0].qualifier,
        } as Amount;
      case FieldInfoType.MEASURE:
        return {
          amount: matched[0].value,
          unit: matched[0].qualifier,
        } as Measure;
    }
    return null;
  }
}
