import { CodelistModel } from './codelist-model';

export class MetadataOffertypeCodelistModel extends CodelistModel {
  private _previousNoOfLots = 0;
  get previousNoOfLots(): number {
    return this._previousNoOfLots;
  }

  set previousNoOfLots(value: number) {
    this._previousNoOfLots = value;
  }
}
