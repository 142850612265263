import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormRootComponent } from '../components/layout/form-root/form-root.component';
import { BekanntmachungenAuswahlComponent } from './bekanntmachung/bekanntmachungen-auswahl/bekanntmachungen-auswahl.component';
import { BekanntmachungenVerwaltungComponent } from './bekanntmachung/bekanntmachungen-verwaltung/bekanntmachungen-verwaltung.component';
import { NeuigkeitenVerwaltungComponent } from './neuigkeiten-verwaltung/neuigkeiten-verwaltung.component';
import { NutzerkontoComponent } from './nutzerkonto/nutzerkonto.component';
import { AnleitungenComponent } from './redaktionssystem/anleitung/anleitungen.component';
import { BarrierefreiheitComponent } from './redaktionssystem/barrierefreiheit/barrierefreiheit.component';
import { DatenschutzComponent } from './redaktionssystem/datenschutz/datenschutz.component';
import { FaqComponent } from './redaktionssystem/faq/faq.component';
import { RedaktionssystemComponent } from './redaktionssystem/home/redaktionssystem/redaktionssystem.component';
import { ImpressumComponent } from './redaktionssystem/impressum/impressum.component';
import { KontaktComponent } from './redaktionssystem/kontakt/kontakt.component';
import { LeichteSpracheComponent } from './redaktionssystem/leichte-sprache/leichte-sprache.component';
import { LizenzenComponent } from './redaktionssystem/lizenzen/lizenzen.component';
import { NutzungsbedingungenComponent } from './redaktionssystem/nutzungsbedingungen/nutzungsbedingungen.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { CheckLockGuard } from './shared/check-lock.guard';
import { PendingChangesGuard } from './shared/pending-changes.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { RemoveLockGuard } from './shared/remove-lock.guard';
import { VorlagenUbersichtComponent } from './vorlagen-uebersicht/vorlagen-ubersicht.component';
import { VorlagenAuswahlComponent } from './vorlagen-auswahl/vorlagen-auswahl.component';
import { GruppenUebersichtComponent } from './gruppen/gruppen-uebersicht/gruppen-uebersicht.component';
import {EditorConfigResolver} from "./editor-config.resolver";
import {
  AuthErrorDialogRedirectTargetComponent
} from "./shared/auth-error-dialog/auth-error-dialog-redirect-target/auth-error-dialog-redirect-target.component";

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: RedaktionssystemComponent },
  {
    path: 'bekanntmachungen',
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'verwalten', pathMatch: 'full' },
      {
        path: 'verwalten',
        component: BekanntmachungenVerwaltungComponent,
      },
      {
        path: 'verwalten/notice/:subTypeId/:sectionId',
        component: FormRootComponent,
        data: { enableErfassungsmaskeMode: true },
        canActivate: [CheckLockGuard],
        canDeactivate: [PendingChangesGuard, RemoveLockGuard],
        resolve: {editor: EditorConfigResolver},
        runGuardsAndResolvers: "paramsOrQueryParamsChange"
      },
      {
        path: 'anlegen',
        component: BekanntmachungenAuswahlComponent,
      },
      {
        path: 'anlegen/notice/:subTypeId/:sectionId',
        component: FormRootComponent,
        data: { enableErfassungsmaskeMode: true },
        canActivate: [CheckLockGuard],
        canDeactivate: [PendingChangesGuard, RemoveLockGuard],
        resolve: {editor: EditorConfigResolver},
        runGuardsAndResolvers: "paramsOrQueryParamsChange"
      },
    ],
  },
  {
    path: 'vorlagen',
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'verwalten', pathMatch: 'full' },
      {
        path: 'verwalten',
        component: VorlagenUbersichtComponent,
      },
      {
        path: 'verwalten/notice/:subTypeId/:sectionId',
        component: FormRootComponent,
        data: { enableErfassungsmaskeMode: true },
        canDeactivate: [PendingChangesGuard],
        resolve: {editor: EditorConfigResolver},
        runGuardsAndResolvers: "paramsOrQueryParamsChange"
      },
      {
        path: 'anlegen',
        component: VorlagenAuswahlComponent,
      },
      {
        path: 'anlegen/notice/:subTypeId/:sectionId',
        component: FormRootComponent,
        data: { enableErfassungsmaskeMode: true },
        canDeactivate: [PendingChangesGuard],
        resolve: {editor: EditorConfigResolver},
        runGuardsAndResolvers: "paramsOrQueryParamsChange"
      },
    ],
  },
  {
    path: 'gruppen',
    component: GruppenUebersichtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'neuigkeiten',
    component: NeuigkeitenVerwaltungComponent,
    canActivate: [AuthGuard],
    data: { roles: ['reporter'] },
  },
  {
    path: 'nutzerkonto',
    component: NutzerkontoComponent,
    canActivate: [AuthGuard],
  },

  { path: 'leichte-sprache', component: LeichteSpracheComponent },
  { path: 'barrierefreiheit', component: BarrierefreiheitComponent },
  { path: 'nutzungsbedingungen', component: NutzungsbedingungenComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'kontakt', component: KontaktComponent },
  { path: 'anleitungen', component: AnleitungenComponent },
  { path: 'lizenzen', component: LizenzenComponent },
  {
    path: '_special',
    children: [
      {
        path: 'AuthErrorDialogRedirectTarget',
        component: AuthErrorDialogRedirectTargetComponent,
      }
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
