import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SdkService } from '../../../services/sdk.service';
import { NoticeSubType, NoticeTypes } from '../../../types/notice-types';
import { TranslationService } from '../../../services/translation.service';
import {
  ClientPlatform,
  FormMode,
  LotSupport,
  OperationMode,
  PublicationMode,
} from '../../../types/editor-configuration';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-notice',
  templateUrl: './select-notice.component.html',
  styleUrls: ['./select-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectNoticeComponent {
  noticeTypes: NoticeTypes;
  tenderingProcedureId: number;
  formModeControl = new FormControl(FormMode.FORM_CREATE);
  operatorModeControl = new FormControl(OperationMode.TRAINING);
  clientPlatformControl = new FormControl(ClientPlatform.OBA);
  cancellationControl = new FormControl('false');
  resetProcedureControl = new FormControl('false');
  offerDeadlineChangeAllowedControl = new FormControl('true');
  participationDeadlineChangeAllowedControl = new FormControl('true');
  tenderingProcedureIdControl = new FormControl();
  publicationModeControl = new FormControl(PublicationMode.WITH_PUBLICATION);
  lotSupportControl = new FormControl(LotSupport.STRUCTURE);
  formModeOptions = [
    FormMode.FORM_CREATE,
    FormMode.FORM_UPDATE,
    FormMode.FORM_UPDATE_META,
    FormMode.FORM_ALTER_BEFORE_RELEASE,
  ];
  operatorModeOptions = [OperationMode.TRAINING, OperationMode.PRODUCTION];
  clientPlatformOptions = [ClientPlatform.OBA, ClientPlatform.SEB];
  publicationModeOptions = [PublicationMode.WITH_PUBLICATION, PublicationMode.WITHOUT_PUBLICATION];
  lotSupportOptions = [LotSupport.STRUCTURE, LotSupport.NONE];
  updateAfterRejected = new FormControl('false');
  isMigration = new FormControl('false');
  showDevMode = new FormControl('true');
  migratedFromEarlierEFormsVersionControl = new FormControl('false');

  constructor(
    private sdkService: SdkService,
    private translationService: TranslationService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.translationService.initTranslationService().then(async () => {
      this.noticeTypes = await this.sdkService.getNoticeTypes();
      this.cdRef.markForCheck();
    });
  }

  isWithInitialData(noticeID: string): boolean {
    switch (noticeID) {
      case '1':
      case '16':
      case '24':
      case '29':
        return true;
      default:
        return false;
    }
  }
  translate(key: string): string {
    return this.translationService.translate(key);
  }

  navigate(noticeSubType: NoticeSubType): void {
    const queryParams = {
      mode: this.formModeControl.value,
      operationMode: this.operatorModeControl.value,
      clientPlatform: this.clientPlatformControl.value,
      cancellation: this.cancellationControl.value,
      publicationMode: this.publicationModeControl.value,
      resetProcedure: this.resetProcedureControl.value,
      offerDeadlineChangeAllowed: this.offerDeadlineChangeAllowedControl.value,
      participationDeadlineChangeAllowed: this.participationDeadlineChangeAllowedControl.value,
      updateAfterRejected: this.updateAfterRejected.value,
      isMigration: this.isMigration.value,
      showDevMode: this.showDevMode.value,
      lotSupport: this.lotSupportControl.value,
      migratedFromEarlierEFormsVersion: this.migratedFromEarlierEFormsVersionControl.value
    };

    if (this.tenderingProcedureIdControl.value) {
      queryParams['tenderingProcedureId'] = this.tenderingProcedureIdControl.value;
    }

    const navigationExtras: NavigationExtras = { queryParams };

    this.router.navigate([`/notice/${noticeSubType.subTypeId}/details`], navigationExtras);
  }
}
