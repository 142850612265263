import { ModelListener } from './ModelListener';
import { ModelStateService } from '../view-model/model-state.service';
import { OFFER_TYPE_ID } from '../view-model/evergabe-meta-data.service';
import { FieldModel } from '../../view-model/field-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { MetadataOffertypeCodelistModel } from '../../view-model/type/metadata-offertype-codelist-model';
import { FormType } from '../../types/form-type';
import { OfferType } from '../../view-model/e-vergabe-meta-data';
import { CodelistModel } from '../../view-model/type/codelist-model';

export class NumberOfLotsListener extends ModelListener<FieldModel<any>> {
  constructor(private editorConfigurationProvider: EditorConfigurationProvider) {
    super();
  }

  onChange(model: FieldModel<any>): void {
    const offerTypeModel: MetadataOffertypeCodelistModel = ModelStateService.findModelById(
      model.root,
      OFFER_TYPE_ID
    );

    const procurementTypeModel: CodelistModel = ModelStateService.findModelById(
      model.root,
      'BT-105-Procedure'
    );

    if (offerTypeModel === null || this.procedureIsReleased() || model.root.formType === FormType.PRIOR_INFORMATION) {
      return;
    }

    const prevNoOfLots = offerTypeModel.previousNoOfLots;
    const nextNoOfLots = ModelStateService.findModelsById(model.root, 'BT-137-Lot').length;

    offerTypeModel.previousNoOfLots = nextNoOfLots;

    if (this.procurementTypeHasNegotiationRounds(procurementTypeModel)) {
      offerTypeModel.value = this.getOfferTypeWhenLotsAreConsideredInCurrentFormType(
        model.root.formType,
        OfferType.PROCEDURE
      );
      offerTypeModel.isReadonly = true;
    } else {
      if (this.transitionFromTechnicalLotToFunctionalLots(prevNoOfLots, nextNoOfLots)) {
        offerTypeModel.value = null;
      } else if (nextNoOfLots <= 1) {
        offerTypeModel.value = this.getOfferTypeWhenLotsAreConsideredInCurrentFormType(
          model.root.formType,
          OfferType.PROCEDURE
        );
      }
      offerTypeModel.isReadonly = nextNoOfLots <= 1;
    }

    offerTypeModel.emitChange();
  }

  procurementTypeHasNegotiationRounds(procurementTypeModel: CodelistModel) {
    if (procurementTypeModel === null) {
      return false;
    }
    return (
      procurementTypeModel.value === 'neg-w-call' ||
      procurementTypeModel.value === 'neg-wo-call' ||
      procurementTypeModel.value === 'innovation'
    );
  }

  getOfferTypeWhenLotsAreConsideredInCurrentFormType(
    formType: FormType,
    selectedOfferType: OfferType
  ) {
    return formType !== FormType.PRIOR_INFORMATION ||
      this.editorConfigurationProvider.hasLotSupport()
      ? selectedOfferType
      : null;
  }

  transitionFromTechnicalLotToFunctionalLots(prevNoOfLots: number, nextNoOfLots: number) {
    return prevNoOfLots === 1 && nextNoOfLots === 2;
  }

  procedureIsReleased() {
    return (
      this.editorConfigurationProvider.isChangeNotice() ||
      this.editorConfigurationProvider.isUpdateMetaData() ||
      this.editorConfigurationProvider.isUpdateAfterRejected()
    );
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return this.editorConfigurationProvider.isOBA() && model.noticeNode?.id === 'BT-137-Lot';
  }
}
