import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { GroupModel } from 'src/app/view-model/group-model';
import { ModelValidationService } from '../../../../services/view-model/model-validation.service';
import { AbstractCustomGroup } from '../custom-group-component/abstract-custom-group.component';
import { FormControl, Validators } from '@angular/forms';
import { CodelistItem } from '../../../../types/field-types';
import { RepeatableGroupModel } from '../../../../view-model/repeatable-group-model';
import { NoticeNode } from '../../../../types/notice-definition';
import { SdkService } from '../../../../services/sdk.service';
import { FieldModel } from '../../../../view-model/field-model';
import { RepeatableService } from '../../repeatable/repeatable.service';
import { CodelistModel } from '../../../../view-model/type/codelist-model';
import { Severity } from '../../../../types/app-types';
import { StateService } from '../../../../services/state.service';

@Component({
  selector: 'app-element-for-each-codelist-item',
  templateUrl: './element-for-each-codelist-item.component.html',
  styleUrls: ['./element-for-each-codelist-item.component.scss'],
})

export class ElementForEachCodelistItemComponent extends AbstractCustomGroup implements OnInit, AfterViewInit {
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  @Input() model: RepeatableGroupModel;
  @Input() isVisible: boolean = true;
  @Input() isRequired: boolean = false;
  @Input() alwaysExpanded: boolean = false;
  @Input() labelPrefix: string = '';
  formFields: FormField[] = [];
  supportMode: boolean;
  fieldBtId: string;
  codelistModel: CodelistModel;
  protected codelist: CodelistItem[];
  protected readonly Severity = Severity;

  private modelValidationService: ModelValidationService = inject(ModelValidationService);

  constructor(protected sdkService: SdkService, protected repeatableService: RepeatableService, protected stateService: StateService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.initWithModel(this.model);

    this.codelistModel = this.model.children[0].children.find(child => child instanceof CodelistModel) as CodelistModel;
    await this.createFormFields(this.isRequired);
    this.subscription.add(
      this.stateService.getSupportMode().subscribe(value => (this.supportMode = value))
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.expansionPanel?.expandedChange.subscribe(expanded => {
        this.model.isExpanded = expanded;
      })
    );
  }

  async findCodelist(noticeNode: NoticeNode): Promise<CodelistItem[] | null> {
    for (const child of noticeNode.content) {
      const fields = await this.sdkService.getFields();
      const targetField = fields.fields.find(field => field.id === child.id);
      if (targetField?.codeList?.value?.id) {
        return (await this.sdkService.getCodeList(targetField?.codeList?.value?.id)).reverse();
      }
    }
    for (const child of noticeNode.content) {
      const found = this.findCodelist(child);
      if (found) return found;
    }
    return null;
  }

  async createFormFields(isRequired?: boolean) {
    this.codelist = await this.findCodelist(this.model.noticeNode);
    if (!this.codelist) return;
    this.fieldBtId = this.model.children[0].children.find(child => child.constructor === FieldModel).fieldInfo.id;

    this.formFields = this.codelist.map((item: CodelistItem) => {
      let targetField = null;
      this.model.children.forEach(group => {
        if (
          group.children.some(child => child instanceof CodelistModel && child.value === item.id)
        ) {
          targetField = this.getValueModel(group);
        }
      });

      const formControl = new FormControl();

      if (isRequired) {
        formControl.addValidators(Validators.required);
      }

      if (targetField) {
        formControl.setValue(targetField.value);
      }
      return {
        formControl: formControl,
        refModel: targetField,
        codelistItem: item,
      };
    });
  }

  async onChange() {
    await this.onInputChange();
  }

  getCodelistModel(group: GroupModel): CodelistModel {
    return group.children.find(child => child instanceof CodelistModel) as CodelistModel;
  }

  getValueModel(group: GroupModel): FieldModel<any> {
    return group.children.find(
      child => !(child instanceof CodelistModel) && child instanceof FieldModel
    ) as FieldModel<any>;
  }

  async buildSubTree(): Promise<void> {
    this.model.clear();

    const filledFormFields = this.formFields.filter(field => {
      const fieldValue = field.formControl.value;
      return fieldValue !== '' && +fieldValue >= 0 && fieldValue !== null;
    });

    // RepeatableGroup enthält immer mindestens eine Gruppe
    if (filledFormFields.length === 0) {
      const group = (await this.repeatableService.addComponent(this.model, false, false)) as GroupModel;

      // die folgenden beiden Zeilen validieren das Model erneut und sorgen dafuer, dass die Validierungsmeldung erscheint.
      await this.modelValidationService.validateModel(this.getCodelistModel(group), true);
      await this.modelListenerService.onModelValueChange(this.getValueModel(group));

      return;
    }

    for (const field of filledFormFields) {
      const group = (await this.repeatableService.addComponent(
        this.model,
        false,
        false
      )) as GroupModel;
      field.refModel = this.getValueModel(group);
      field.codelistModel = this.getCodelistModel(group);
      field.refModel.value = field.formControl.value;
      field.codelistModel.value = field.codelistItem.id;
      await this.modelListenerService.onModelValueChange(field.refModel);
    }
  }

  public collectNotifications(): Set<string> {
    const notificationMessages = this.model.children.map(groupModel => {
      return groupModel.children.map(childModel => {
        return childModel.validationNotifications.map(notification => notification.message);
      });
    }).flat(2);
    return new Set(notificationMessages);
  };
}

export interface FormField {
  formControl: FormControl;
  refModel?: FieldModel<any>;
  codelistModel?: CodelistModel;
  codelistItem: CodelistItem;
}
