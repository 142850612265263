import { Injectable } from '@angular/core';
import { SdkService } from './sdk.service';
import { NoticeNode } from '../types/notice-definition';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private readonly termsMap = new Map<string, string>();

  private initialised = false;

  constructor(private sdkService: SdkService) {}

  public async initTranslationService() {
    if (this.initialised) {
      return;
    }
    const translationDocument: XMLDocument = await this.sdkService.getTranslationFiles();
    this.processDocument(translationDocument);
  }

  public translate(label: string): string {
    if (this.termsMap.has(label)) {
      return this.termsMap.get(label);
    }
    return label;
  }

  public translateIfAvailable(label: string): string {
    if (this.termsMap.has(label)) {
      return this.termsMap.get(label);
    }
    return undefined;
  }

  public getTooltips(noticeNode: NoticeNode): string[] {
    const fieldInfo = this.sdkService.getFieldInfo(noticeNode.id);
    const tooltips = [];

    let hint: string;
    if (fieldInfo) {
      const keyField = 'field|hint|' + fieldInfo.id;
      const keyBT = 'business-term|hint|' + fieldInfo.btId;
      hint = this.termsMap.get(keyField) ?? this.termsMap.get(keyBT) ?? null;
    } else {
      const keyGroup = 'group|hint|' + noticeNode.id;
      hint = this.termsMap.get(keyGroup) ?? null;
    }
    if (hint) {
      tooltips.push(hint);
    }

    let eVergabeHints: string[];
    if (fieldInfo) {
      const keyField = 'field|eVergabe-hint';
      eVergabeHints = this.findTooltips(keyField, fieldInfo.id);
      if (!eVergabeHints.length) {
        const keyBT = 'business-term|eVergabe-hint';
        eVergabeHints = this.findTooltips(keyBT, fieldInfo.id);
      }
    } else {
      const keyGroup = 'group|eVergabe-hint';
      eVergabeHints = this.findTooltips(keyGroup, noticeNode.id);
    }
    tooltips.push(...eVergabeHints);

    return tooltips;
  }

  private findTooltips(keyPrefix: string, id: string) {
    const tooltips = [];

    for (let index = 1; this.termsMap.has(`${keyPrefix}_${index}|${id}`); index++) {
      tooltips.push(this.termsMap.get(`${keyPrefix}_${index}|${id}`));
    }

    return tooltips;
  }

  private processDocument(xmlDocument: XMLDocument) {
    const entries = xmlDocument.getElementsByTagName('entry');

    Array.from(entries).forEach(element => {
      const key = element.getAttribute('key');
      const value = element.textContent;
      this.termsMap.set(key, value);
    });

    this.initialised = true;
  }
}
