import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorDialogComponent } from '../../shared/error-dialog/error-dialog.component';
import { HistoryNoticeOverviewDto, NoticeOverviewDto } from '../../api/notices';
import { MatDialog } from '@angular/material/dialog';
import { NoticeOverviewViewModel } from '../NoticeOverviewViewModel';
import { CreateHistoryService } from './create-history.service';

@Component({
  selector: 'app-bekanntmachung-table',
  templateUrl: './bekanntmachung-table.component.html',
  styleUrls: ['./bekanntmachung-table.component.scss'],
})
export class BekanntmachungTableComponent implements OnInit, AfterViewInit {
  @Input() data: NoticeOverviewDto[] = [];

  @Output() triggerAction = new EventEmitter<{ actionType: string, rsNoticeId: number; }>();

  noticesWithHistory: NoticeOverviewViewModel[] = [];
  onlyVisibleNotices: NoticeOverviewViewModel[] = [];

  displayedColumns: string[] = [
    'unterart',
    'name',
    'lastEdited',
    'tedStatus',
    'dseStatus',
    'actions',
  ];
  dataSource: MatTableDataSource<NoticeOverviewViewModel>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private historyService: CreateHistoryService, private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.shouldShowErrorColumn()) {
      this.displayedColumns = ['error', ...this.displayedColumns];
    }

    this.dataSource = new MatTableDataSource();
    this.dataSource.sortingDataAccessor = (item: NoticeOverviewViewModel, property) => {
      switch (property) {
        case 'unterart':
          return +item.notice.subTypeId;
        case 'lastEdited':
          /* Die Historie soll stets mit der aktuellen Bekanntmachung verkettet bleiben. Um das zu gewährleisten
           * verwenden die Bekanntmachungen aus der Historie für die Sortierung ebenfalls das Datum ihres Parents.
           * Damit die Historie ebenfalls auf- und absteigend sortiert werden kann wird ihr eigenes Datum hinten an
           * gehängt und stellt somit die sekundäre Sortierung dar.
           * Hierbei würde allerdings auch die aktuelle Bekanntmachung hinter die Historie rutschen. Die Anhängsel "-0"
           * und "-9" sorgen dafür, dass diese Bekanntmachung unabhängig von der Sortierung immer am Anfang steht.
           */
          return item.parent
            ? item.parent.lastEdited.toString() + '-' + item.notice.lastEdited.toString()
            : this.sort.direction.toString() === 'asc'
            ? item.notice.lastEdited.toString() + '-0'
            : item.notice.lastEdited.toString() + '-9';
        case 'tedStatus':
          /* Beim TED-Status wird nur nach dem Status der aktuellen Bekanntmachung sortiert
           * Due Historie wird weiterhin einfach unter diese Bekanntmachung gehängt
           **/
          return item.parent ? item.parent.tedStatus : item.notice.tedStatus;
        case 'dseStatus':
          /* Wird analog zum TED-Status behandelt */
          return item.parent ? item.parent.dseStatus : item.notice.dseStatus;
        default:
          return item.notice[property];
      }
    };

    this.noticesWithHistory = this.historyService.create(this.data);
    this.applyFilter();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  showErrorDetailDialog(notice: NoticeOverviewDto): void {
    this.dialog.open(ErrorDialogComponent, {
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
      data: {
        title: 'Fehlerinformationen',
        dismissLabel: 'Schließen',
        errorType: notice.errorType,
        errorText: notice.errorText,
        success: false,
      },
    });
  }

  onShowHistory(element: NoticeOverviewViewModel): void {
    element.showHistory = !element.showHistory;
    this.noticesWithHistory = this.noticesWithHistory.map(n => ({
      ...n,
      isVisible: element.historyIds.includes(n.notice.rsNoticeId)
        ? element.showHistory
        : n.isVisible,
    }));

    this.applyFilter();
  }

  onTriggerAction(payload: { actionType: string, rsNoticeId: number; }): void {
    this.triggerAction.emit(payload);
  }

  private applyFilter(): void {
    this.onlyVisibleNotices = this.noticesWithHistory.filter(notice => notice.isVisible);
    this.dataSource.data = this.onlyVisibleNotices;
  }

  private shouldShowErrorColumn() {
    const isErrorOnTopLevel = this.data.some(notice => this.hasError(notice));
    const isErrorInHistory = this.data.reduce(
      (errorFound, notice) => errorFound || notice.history.some(n => this.hasError(n)),
      false
    );
    return isErrorOnTopLevel || isErrorInHistory;
  }

  private hasError(notice: NoticeOverviewDto | HistoryNoticeOverviewDto): boolean {
    return !!notice.errorType || !!notice.errorText;
  }
}
