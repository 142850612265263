import { MultilingualModel } from './multilingual-model';

export class Bt300BrDeKmuModel extends MultilingualModel {
  private _isKmuSelectionEnabled: boolean;
  private _kmuValues: string[];

  constructor() {
    super();
  }

  get isKmuSelectionEnabled(): boolean {
    return this._isKmuSelectionEnabled;
  }

  set isKmuSelectionEnabled(value: boolean) {
    this._isKmuSelectionEnabled = value;
  }

  get kmuValues(): string[] {
    return this._kmuValues ?? [];
  }

  set kmuValues(value: string[]) {
    this._kmuValues = value;
  }
}
