import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { IndicatorModel } from '../../../view-model/type/indicator-model';

@Component({
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndicatorComponent extends AbstractFormComponent implements OnInit {
  inputControl = new FormControl();
  model: IndicatorModel;

  ngOnInit(): void {
    this.initValue();

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: false });
    } else {
      this.handleInputChanges();
    }
    this.handleModelChanges();
  }

  reset(): void {
    this.inputControl.setValue(undefined);
  }

  private initValue(): void {
    if (this.model.value != null) {
      this.inputControl.setValue(this.model.value);
    }
  }

  private handleInputChanges(): void {
    this.subscription.add(
      this.inputControl.valueChanges.subscribe(value => {
        this.model.value = value != null ? value : undefined;
        this.onInputValueChanged();
      })
    );
  }

  private handleModelChanges() {
    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => {
        this.inputControl.setValue(this.model.value, {emitEvent: false});
        this.cdRef.markForCheck();
      })
    );
  }
}
