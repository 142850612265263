import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog.component';
import { ErrorDialogData } from './error-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  constructor(private dialog: MatDialog) {}

  public showErrorDetailsDialog(
    error: ErrorDialogData | string,
    dismissLabel: string = 'OK',
    title: string = 'Aktion fehlgeschlagen',
    content: string = 'Bitte versuchen Sie es erneut. Sollte der Fehler wiederholt auftreten, wenden Sie sich bitte an den Support.'
  ) {
    this.dialog.open(ErrorDialogComponent, {
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
      data: {
        title: title,
        content: content,
        dismissLabel,
        errorType: this.getErrorType(error),
        errorText: this.getErrorText(error),
        success: false,
      },
    });
  }

  private getErrorType(error: ErrorDialogData | string): string {
    return typeof error === 'object' ? error.errorType : null;
  }

  private getErrorText(error: ErrorDialogData | string): string {
    return typeof error === 'object' ? error.errorText : error;
  }
}
