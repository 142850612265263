import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogData } from './error-dialog.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  now = new Date();

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data?: ErrorDialogData) {}

  getFullErrorMessage(errorType: string, errorText: string): string {
    return (errorType ? `${errorType}: ` : '').concat(errorText ?? '');
  }
}
