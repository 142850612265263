<app-hinweis-banner
  [title]="'Wozu dienen Vorlagen?'"
  [content]="'Hier können Sie Vorlagen anlegen. Das Anlegen von Vorlagen ist sinnvoll, wenn Sie regelmäßig ähnliche Bekanntmachungen veröffentlichen. Sie können entweder nahezu vollständige Bekanntmachungen oder nur bestimmte Teile einer Bekanntmachung (z.B. Informationen zum Auftraggeber, Ausschlussgründe und/oder Eignungskriterien) als Vorlage speichern.'">
</app-hinweis-banner>

<div class="container-with-margin">
  <app-filter-table
    *skeleton="loading$ | async; height: '700px'; width: '100%'; type: 'table'"
    [data]="data"
    (rowClick)="onRowClick($event)"></app-filter-table>
</div>

<app-vorlagen-name-dialog
  #vorlagenNameDialog
  dialogTitle="Vorlage anlegen"
  (confirm)="changeTemplateMetadata($event)"></app-vorlagen-name-dialog>
