<div class="search-wrapper" id="search">
  <input
    (focus)="onFocus()"
    [formControl]="searchControl"
    class="form-text-field"
    id="search-input"
    placeholder="Volltextsuche..." />
  <button
    mat-icon-button
    matIconSuffix
    class="input-clearable-icon"
    (click)="focusOrClearSearch()"
    *ngIf="searchControl.value"
    aria-label="Sucheingabe löschen">
    <mat-icon>close</mat-icon>
  </button>
  <button
    mat-icon-button
    matIconSuffix
    [tabIndex]="-1"
    class="input-clearable-icon"
    *ngIf="!searchControl.value"
    (click)="focusOrClearSearch()"
    aria-label="Sucheingabe fokussieren">
    <mat-icon>search</mat-icon>
  </button>


  <div *ngIf="($isSearchActive | async) && searchControl.value?.length >= 1" class="dropdown">
    <div *ngIf="hasResults()">
      <app-search-results
        [focusFirstResultEmitter]="focusFirstResultEmitter"
        [query]="searchControl.value"
        [results]="searchResults.slice(0, visibleItems)"
        [$isSearchActive]="$isSearchActive">
      </app-search-results>
    </div>

    <div *ngIf="!minimumCharsReached" class="result-footer">
      <span>Bitte geben Sie mindestens 3 Zeichen ein.</span>
    </div>
    <div *ngIf="minimumCharsReached && !hasResults()" class="result-footer">
      <span>Es wurden keine Elemente gefunden.</span>
    </div>
    <div *ngIf="minimumCharsReached && searchResults.length > visibleItems" class="result-footer">
      <span>
        {{ visibleItems }} von {{ searchResults.length }} Treffern.
        <a
          (keydown.enter)="this.onShowAllResults()"
          (mousedown)="this.onShowAllResults()"
          tabindex="0">
          Alle Anzeigen
        </a>
      </span>
    </div>
  </div>
</div>
