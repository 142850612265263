import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GenericDialogService } from '../../services/ui/generic-dialog.service';
import { NoticeLockControllerService } from '../api/notices';
import { ErrorType } from './error-type.enum';
import { RefreshNoticeLockService } from './refresh-notice-lock.service';

@Injectable()
export class CheckLockGuard implements CanActivate {

  private genericDialogService = inject(GenericDialogService);
  private router = inject(Router);
  private refreshLockService = inject(RefreshNoticeLockService);
  private lockService = inject(NoticeLockControllerService);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    const warnAndCancel$ = defer(() => this.refreshLockService.showNoticeLockedErrorDialog('Bearbeitung aktuell nicht möglich')).pipe(
      map(() => this.router.createUrlTree(['bekanntmachungen', 'verwalten']))
    );

    const rsNoticeId = route.queryParamMap.get('rsNoticeId');
    if (!rsNoticeId) {
      return true;
    }
    return this.lockService.createOrUpdateLock(+rsNoticeId).pipe(
      map(() => {
        this.refreshLockService.start(+rsNoticeId);
        return true;
      }),
      catchError(err => err.error?.errorType === ErrorType.NOTICE_LOCKED ? warnAndCancel$ : throwError(() => err))
    );
  }
}
