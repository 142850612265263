import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchResult } from '../../../../../types/app-types';
import { BaseModel } from '../../../../../view-model/base-model';
import { FieldModel } from '../../../../../view-model/field-model';
import { ComponentModel } from '../../../../../view-model/component-model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavigationService } from '../../../../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() results: SearchResult[];
  @Input() query: string;
  @Input() $isSearchActive: BehaviorSubject<boolean>;
  @Input() focusFirstResultEmitter: EventEmitter<void>;
  private subscription: Subscription = new Subscription();

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscription = this.focusFirstResultEmitter?.subscribe(() => {
      (document.getElementsByClassName('search-result')[0] as HTMLElement)?.focus();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  navigateToResult(result: SearchResult) {
    this.$isSearchActive.next(false);
    this.navigationService.navigate(result.entry, this.activatedRoute, result.language);
  }

  modelHasValue(model: BaseModel): boolean {
    if (!(model instanceof FieldModel)) {
      return false;
    }

    return !!model.value;
  }

  getBreadcrumbsLabelsForEntry(searchResult: SearchResult): string[] {
    const breadcrumbs = [];

    let model = searchResult.entry.parent;
    while (!(model instanceof ComponentModel)) {
      if (!model.repeatable) breadcrumbs.push(model.translatedLabel);
      model = model.parent;
    }

    if (searchResult.lot) {
      breadcrumbs.push(searchResult.lot);
    }

    return breadcrumbs.reverse();
  }

  getBreadcrumbsFirstLabelForEntry(searchResult: SearchResult): string {
    return this.getBreadcrumbsLabelsForEntry(searchResult)[0];
  }

  getToolTipForBreadcrumb(result: SearchResult): string {
    const fullPath = this.getBreadcrumbsLabelsForEntry(result);
    fullPath.push(result.label);
    return fullPath.join(' > ');
  }

  onNavigateInResults($event: KeyboardEvent) {
    if ($event.key === 'ArrowDown') {
      $event.stopPropagation();
      $event.preventDefault();
      if (document.activeElement?.nextElementSibling) {
        (document.activeElement?.nextElementSibling as HTMLElement)?.focus();
      }
    }
    if ($event.key === 'ArrowUp') {
      $event.stopPropagation();
      $event.preventDefault();
      if (document.activeElement?.previousElementSibling) {
        (document.activeElement?.previousElementSibling as HTMLElement)?.focus();
      }
    }
  }

  onEscDown() {
    this.$isSearchActive.next(false);
  }
}
