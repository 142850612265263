import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InvitationModel } from '../../model/invitation.model';
import {GroupModel} from "../../../api/user-service";

@Component({
  selector: 'app-einladung-item',
  templateUrl: './einladung-item.component.html',
  styleUrls: ['./einladung-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinladungItemComponent {
  @Input() einladung: InvitationModel;
  @Input() gruppe: GroupModel;

  @Output() acceptInvitation = new EventEmitter<{ groupId: number, invitationId: number }>();
  @Output() declineInvitation = new EventEmitter<{ groupId: number, invitationId: number }>();

  onAcceptInvitation(groupId: number, invitationId: number): void {
    this.acceptInvitation.emit({groupId, invitationId});
  }

  onDeclineInvitation(groupId: number, invitationId: number): void {
    this.declineInvitation.emit({groupId, invitationId});
  }
}
