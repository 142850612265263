import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-neueigkeit',
  templateUrl: './neueigkeit.component.html',
  styleUrls: ['./neueigkeit.component.scss'],
})
export class NeueigkeitComponent {
  @Input()
  datum: Date;

  @Input()
  titel: string;

  @Input()
  contentHtmlAsString: string;
}
