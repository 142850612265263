import { Injectable } from '@angular/core';
import { EditorConfigurationProvider } from './editor-configuration.provider';
import { SdkService } from './sdk.service';
import { TranslationService } from './translation.service';
import { StateService } from './state.service';
import { MaintenanceWindowService } from './maintenance-window.service';
import { NoticeDefinition } from '../types/notice-definition';
import { NoticeSubType } from '../types/notice-types';
import { CommunicationService } from './communication.service';
import { EVergabeMetaDataService } from './view-model/evergabe-meta-data.service';
import { ConceptModel } from '../types/concept-node';
import { firstValueFrom } from 'rxjs';
import { RsEditorConfigurationProvider } from './rs-editor-configuration.provider';
import { RsNoticeSubmittingServiceV2 } from './rs-notice-submitting-v2.service';
import { RsTemplateSubmittingService } from './rs-template-submitting.service';

export interface NoticeData {
  noticeDefinition: NoticeDefinition;
  noticeSubType: NoticeSubType;
  importedConceptModel?: ConceptModel;
}

@Injectable()
export class NoticeDataResolver {
  constructor(
    private stateService: StateService,
    private translationService: TranslationService,
    private maintenanceWindowService: MaintenanceWindowService,
    private sdkService: SdkService,
    private communicationService: CommunicationService,
    private eVergabeMetadataService: EVergabeMetaDataService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private rsConfig: RsEditorConfigurationProvider,
    private rsNoticeSubmittingServiceV2: RsNoticeSubmittingServiceV2,
    private rsTemplateSubmittingService: RsTemplateSubmittingService
  ) {}

  async resolve(noticeID: string, tenderingProcedureId: number | null): Promise<NoticeData> {
    this.stateService.updateNoticeId(noticeID);

    const initFieldTypesPromise = this.sdkService.initFieldTypes();
    const initTranslationServicePromise = this.translationService.initTranslationService();
    const initMaintenanceWindowsPromise = this.maintenanceWindowService.initMaintenanceWindows();

    const initNoticeDefinitionAndSubTypePromise = this.sdkService
      .getNoticeDefinition(noticeID)
      .then(async noticeDefinition => ({
        noticeDefinition,
        noticeSubType: await this.sdkService.getNoticeSubType(noticeDefinition.noticeId),
      }));

    const eVergabeMetaDataPromise =
      !!tenderingProcedureId && !this.editorConfigurationProvider.isRS()
        ? this.communicationService.getEVergabeMetaData()
        : null;

    const conceptModel = this.editorConfigurationProvider.isRS()
      ? await this.getConceptModel(noticeID)
      : await this.communicationService.loadData(noticeID, tenderingProcedureId);

    const importedConceptModelPromise = conceptModel ?? null;

    const allPromises = Promise.all([
      initFieldTypesPromise,
      initTranslationServicePromise,
      initMaintenanceWindowsPromise,
      initNoticeDefinitionAndSubTypePromise,
      eVergabeMetaDataPromise,
      importedConceptModelPromise,
    ]);

    return allPromises
      .then(
        ([
          initFieldTypesResult,
          initTranslationServiceResult,
          initMaintenanceWindowsResult,
          initNoticeDefinitionAndSubTypeResult,
          eVergabeMetaData,
          importedConceptModel,
        ]) => {
          if (eVergabeMetaData) {
            this.eVergabeMetadataService.initMetaData(eVergabeMetaData);
          }

          return {
            noticeDefinition: initNoticeDefinitionAndSubTypeResult.noticeDefinition,
            noticeSubType: initNoticeDefinitionAndSubTypeResult.noticeSubType,
            importedConceptModel,
          };
        }
      )
      .catch(error => Promise.reject(error));
  }

  // ReSy: Lade das ConceptModel je nach Modus entweder ueber Notice- oder TemplateService.
  private async getConceptModel(noticeId: string): Promise<ConceptModel> {
    return this.rsConfig.isTemplateMode()
      ? await firstValueFrom(this.rsTemplateSubmittingService.loadData())
      : await firstValueFrom(this.rsNoticeSubmittingServiceV2.loadData(noticeId));
  }
}
