import { Injectable } from '@angular/core';
import { ModelChangeType } from '../../types/model-changed-types';
import { BaseModel, ModelWithChildren } from '../../view-model/base-model';
import { FieldModel } from '../../view-model/field-model';
import { ModelStateService } from './model-state.service';
import { ComponentModel } from '../../view-model/component-model';
import { EVERGABE_METADATA_ID } from './evergabe-meta-data.service';
import { MultilingualModel } from '../../view-model/type/multilingual-model';
import { AmountModel } from '../../view-model/type/amount-model';
import { MeasureModel } from '../../view-model/type/measure-model';
import { GroupModel } from '../../view-model/group-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';

/**
 * Checks if the Model has been changed since start of the editing Session.
 * This will NOT detect values which have been pre-set from their Notice-Node as changed. If so, every notice would immediately count as changed.
 */
@Injectable({
  providedIn: 'root',
})
export class ModelChangeDetectionService {
  constructor(private editorConfigurationProvider: EditorConfigurationProvider) {}

  /**
   * Searches the Model and checks, which kind of changes have been done since the Model was loaded.
   */
  public getModelChangeType(node: BaseModel | ComponentModel): ModelChangeType {
    // Always count initial notices as changed
    if (this.editorConfigurationProvider.isCreationMode() || this.editorConfigurationProvider.isResetProcedure()) {
      return ModelChangeType.CHANGED;
    }

    if (ModelStateService.hasChildren(node)) {
      const model = node as ModelWithChildren;
      let changeType = ModelChangeType.UNCHANGED;
      for (const child of model.children) {
        const result = this.getModelChangeType(child);
        if (result !== ModelChangeType.UNCHANGED) {
          if (
            node instanceof GroupModel &&
            node.noticeNode?.id === EVERGABE_METADATA_ID &&
            changeType !== ModelChangeType.CHANGED
          ) {
            changeType = ModelChangeType.META_ONLY_CHANGE;
          } else {
            changeType = result;
          }
        }
      }
      return changeType;
    } else if (node instanceof FieldModel && this.hasModelChanged(node)) {
      return ModelChangeType.CHANGED;
    }
    return ModelChangeType.UNCHANGED;
  }

  private hasModelChanged(fieldModel: FieldModel<any>): boolean {
    if (fieldModel instanceof MultilingualModel) {
      return (
        fieldModel.value?.DEU !== fieldModel.valueAfterModelInit?.DEU ||
        fieldModel.value?.ENG !== fieldModel.valueAfterModelInit?.ENG
      );
    } else if (fieldModel instanceof AmountModel) {
      return (
        fieldModel.value?.amount !== fieldModel.valueAfterModelInit?.amount ||
        fieldModel.value?.currencyID !== fieldModel.valueAfterModelInit?.currencyID
      );
    } else if (fieldModel instanceof MeasureModel) {
      return (
        fieldModel.value?.amount !== fieldModel.valueAfterModelInit?.amount ||
        fieldModel.value?.unit !== fieldModel.valueAfterModelInit?.unit
      );
    }

    return !this.isValueEqual(fieldModel.value, fieldModel.valueAfterModelInit);
  }

  private isValueEqual(a: any, b: any) {
    if (a === null || a === undefined || b === null || b === undefined) {
      // eslint-disable-next-line eqeqeq
      return a == b;
    }
    return a === b;
  }
}
