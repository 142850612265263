import { ValidationNotification } from './app-types';
import { BaseModel } from '../view-model/base-model';

/**
 * Container for notification messages from validation. Also holds information for the notification navigation.
 */
export class NavigableNotification {
  private readonly _validationNotification: ValidationNotification;
  private readonly _label: string;
  private readonly _model: BaseModel;

  constructor(
    validationNotification: ValidationNotification,
    model: BaseModel,
    label: string = model.translatedLabel
  ) {
    this._validationNotification = validationNotification;
    this._label = label;
    this._model = model;
  }

  get validationNotification(): ValidationNotification {
    return this._validationNotification;
  }

  get label(): string {
    return this._label;
  }

  get model(): BaseModel {
    return this._model;
  }
}
