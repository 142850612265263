import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { StateService } from '../../../services/state.service';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { ActivatedRoute } from '@angular/router';
import { ModelGenerationService } from '../../../services/view-model/model-generation.service';
import { SectionModel } from '../../../view-model/section-model';
import { RepeatableSectionModel } from '../../../view-model/repeatable-section-model';
import { SchemeIdService } from '../../../services/view-model/scheme-id.service';
import { ModelStateService } from '../../../services/view-model/model-state.service';
import { NavigationService } from '../../../services/navigation.service';
import { BaseModel } from '../../../view-model/base-model';
import { Subscription } from 'rxjs';
import { ModelListenerService } from '../../../services/view-model/model-listener.service';
import { FormType } from '../../../types/form-type';
import { IdModel } from '../../../view-model/type/id-model';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-repeatable-section',
  templateUrl: './repeatable-section.component.html',
  styleUrls: ['./repeatable-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RepeatableSectionComponent implements OnInit, OnDestroy {
  @ViewChildren('sectionPanels') expansionPanels!: QueryList<MatExpansionPanel>;
  @ViewChild('container') container: ElementRef;
  @Input() model: RepeatableSectionModel;
  @Input() procedureSection: SectionModel;
  structuralChangeAllowed: boolean;
  typeName: string;
  selectedSection: SectionModel;
  isTechnical: boolean = false;

  private subscription = new Subscription();

  constructor(
    private stateService: StateService,
    private modelStateService: ModelStateService,
    private genericDialogService: GenericDialogService,
    private activatedRoute: ActivatedRoute,
    private modelGenerationService: ModelGenerationService,
    private cdRef: ChangeDetectorRef,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private schemeIdService: SchemeIdService,
    private navigationService: NavigationService,
    private modelListenerService: ModelListenerService
  ) {}

  ngOnInit(): void {
    this.structuralChangeAllowed = this.isStructuralChangeAllowed();

    this.typeName = this.getName();

    this.model.modelChangedEmitter.subscribe(() => {
      this.cdRef.markForCheck();
    });
    this.subscription.add(
      this.stateService.getSectionId().subscribe(this.onSectionChanges.bind(this))
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSectionChanges(sectionId: string) {
    this.selectedSection = this.modelStateService.getSectionModel(sectionId);

    const panelIndex = this.model.children.findIndex(section =>
      section?.children?.includes(this.selectedSection)
    );
    if (panelIndex > -1) {
      this.expansionPanels?.get(panelIndex)?.open();
      this.moveScrollbar(panelIndex);
    }

    this.cdRef.markForCheck();
  }

  async remove(sectionModel: SectionModel): Promise<void> {
    const name2 = this.model.noticeNode._idScheme === 'LOT' ? 'Loses' : 'Teils';
    const isCreationOrMissingLotSupport =
      this.editorConfigurationProvider.isCreationMode() ||
      !this.editorConfigurationProvider.hasLotSupport() ||
      this.modelStateService.componentModel.formType === FormType.PRIOR_INFORMATION;

    const documentDeletionMessage = isCreationOrMissingLotSupport ? ' ' : ' sowie alle Dokumente ';
    if (
      await this.genericDialogService.confirmWithDialog(
        `Löschen des ${name2} bestätigen`,
        'Löschen bestätigen',
        'Abbrechen',
        `Möchten Sie das ${this.getName()} wirklich löschen? Alle Daten${documentDeletionMessage}innerhalb des ${name2} gehen dabei verloren.`
      )
    ) {
      // Removed LotId Model
      const lotId = this.getSectionIdModel(sectionModel);

      // Searches for all defined IdModels inside the section to be removed. Then removes all references to that ID Globally.
      // Finally, it will reorder all remaining IdModels of that SchemeID, to prevent holes from forming.
      this.schemeIdService.removeIdModelsAndGlobalReferencesForRemovedSectionAndRearrangeGapsInIndex(
        sectionModel
      );

      // Remove Section in ComponentModel
      this.modelGenerationService.removeRepeatableSection(sectionModel);

      // Rename first Lot/Part if necessary
      this.schemeIdService.renameFirstLotOrPartToMatchNamingRequirements(lotId);

      this.cdRef.markForCheck();

      if (lotId) {
        await this.modelListenerService.onModelValueChange(lotId);
      }
    }
  }

  getSchemeIdentifier(section: SectionModel) {
    const idModel = ModelStateService.findModelById(
      section,
      section.noticeNode._identifierFieldId
    ) as IdModel;

    const idAsNumber = Number(idModel?.value?.split('-')[1]);

    const isLot = idModel?.value?.startsWith('LOT');

    if (idAsNumber === 0) {
      this.isTechnical = true;
      return isLot ? 'Technisches Los' : 'Technischer Teil';
    }
    this.isTechnical = false;
    return isLot ? `Los ${idAsNumber}` : `Teil ${idAsNumber}`;
  }

  async add(): Promise<void> {
    const addedSectionModel = await this.modelGenerationService.addRepeatableSection(
      this.model.noticeNode,
      this.model
    );
    await this.modelGenerationService.initSubtree(addedSectionModel);
    this.stateService.setModified(true);
    this.schemeIdService.renameFirstLotOrPartToMatchNamingRequirements(
      this.getSectionIdModel(addedSectionModel)
    );
    this.cdRef.markForCheck();
  }

  changeSection(section: BaseModel): void {
    if (section instanceof SectionModel) {
      this.navigationService.changeSection(section, this.activatedRoute);
    }
  }

  getTooltip(): string {
    return `Durch eForms ist mindestens ein ${this.getName()} in einem Verfahren notwendig.`;
  }

  async copy(sectionModel: SectionModel) {
    const addedSectionModel = await this.modelGenerationService.copyRepeatableSection(
      this.model.noticeNode,
      this.model,
      sectionModel.sectionSchemeId
    );

    await this.modelGenerationService.initSubtree(addedSectionModel);
    this.stateService.setModified(true);

    this.schemeIdService.renameFirstLotOrPartToMatchNamingRequirements(
      this.getSectionIdModel(addedSectionModel)
    );
    this.cdRef.markForCheck();
  }

  private moveScrollbar(panelIndex: number) {
    const container = this.container?.nativeElement;
    const element = container?.querySelectorAll('mat-expansion-panel-header')[panelIndex];
    element?.scrollIntoView();
  }

  stopDefaultBehavior(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private getName(): 'Los' | 'Teil' {
    return this.model.noticeNode._idScheme === 'LOT' ? 'Los' : 'Teil';
  }

  private getSectionIdModel(sectionModel: SectionModel): IdModel {
    const modelId = this.model.noticeNode._idScheme === 'LOT' ? 'BT-137-Lot' : 'BT-137-Part';
    return ModelStateService.findModelById<IdModel>(sectionModel, modelId);
  }

  /**
   * Structural Changes are only allowed in the initial notice, or when altered, only before it is sent to the Vermittlungsdienst.
   */
  private isStructuralChangeAllowed(): boolean {
    return (
      (this.editorConfigurationProvider.isCreationMode() ||
        this.editorConfigurationProvider.isAlterBeforeRelease()) &&
      (this.editorConfigurationProvider.isRS() ||
        [FormType.NOTICE, FormType.PRIOR_INFORMATION].includes(
          this.modelStateService.componentModel.formType
        ))
    );
  }
}
