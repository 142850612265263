import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMarkAsterisk]',
})
export class MarkAsteriskDirective implements OnInit {
  @Input() appMarkAsterisk: boolean;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.elRef.nativeElement, 'margin-left', '4px');
  }

  @HostBinding('style.color')
  public get color(): string {
    if (!!this.appMarkAsterisk) {
      return '#e13b3b';
    }
  }

  @HostBinding('textContent')
  public get textContent(): string {
    if (!!this.appMarkAsterisk) {
      return '*';
    }
  }
}
