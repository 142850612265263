import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { OFFER_TYPE_ID } from '../../../view-model/evergabe-meta-data.service';
import { CodelistModel } from '../../../../view-model/type/codelist-model';
import { ModelStateService } from '../../../view-model/model-state.service';
import { MetadataOffertypeCodelistModel } from '../../../../view-model/type/metadata-offertype-codelist-model';
import { OfferType } from '../../../../view-model/e-vergabe-meta-data';

export class OfferTypeValidator extends EVergabeSpecificValidator<FieldModel<string>> {
  private static readonly LOT_NOT_SUPPORTED_FOR_NEGOTIATION_ROUNDS_ERROR_MSG =
    'Bei Verfahren mit Verhandlungsrunden ist nur "Verfahren" erlaubt.';
  private static readonly LOT_NOT_SUPPORTED_FOR_TECHNICAL_LOT_ERROR_MSG =
    'Existiert nur ein Los, darf nur "Verfahren" gewählt werden.';

  shouldAttach(model: FieldModel<string>): boolean {
    return model.noticeNode.id === OFFER_TYPE_ID;
  }

  async validate(model: FieldModel<string>): Promise<ValidationNotification[]> {
    const offerTypeModel: MetadataOffertypeCodelistModel = ModelStateService.findModelById(
      model.root,
      OFFER_TYPE_ID
    );

    const procurementTypeModel: CodelistModel = ModelStateService.findModelById(
      model.root,
      'BT-105-Procedure'
    );

    if (this.procurementTypeHasNegotiationRounds(procurementTypeModel)) {
      if (offerTypeModel?.value === OfferType.LOT) {
        return this.ofSingle(OfferTypeValidator.LOT_NOT_SUPPORTED_FOR_NEGOTIATION_ROUNDS_ERROR_MSG);
      }
    } else {
      const nextNoOfLots = ModelStateService.findModelsById(model.root, 'BT-137-Lot').length;
      if (nextNoOfLots === 1) {
        if (offerTypeModel?.value === OfferType.LOT) {
          return this.ofSingle(OfferTypeValidator.LOT_NOT_SUPPORTED_FOR_TECHNICAL_LOT_ERROR_MSG);
        }
      }
    }

    return this.empty();
  }

  procurementTypeHasNegotiationRounds(procurementTypeModel: CodelistModel) {
    if (procurementTypeModel === null || procurementTypeModel === undefined) {
      return false;
    }
    return (
      procurementTypeModel.value === 'neg-w-call' ||
      procurementTypeModel.value === 'neg-wo-call' ||
      procurementTypeModel.value === 'innovation'
    );
  }
}
