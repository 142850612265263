import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { DateModel } from '../../../../view-model/type/date-model';
import { ModelStateService } from '../../../view-model/model-state.service';
import { FieldInfo } from '../../../../types/field-types';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';

export class ProcedureDeadlineAfterParticipationDeadlineValidator extends EVergabeSpecificValidator<
  FieldModel<string>
> {
  private static readonly DEADLINE_MUST_BE_AFTER_PARTICIPATION_DEADLINE =
    'Die Frist für den Eingang der Angebote muss nach der Frist für den Eingang der Teilnahmeanträge liegen.';

  shouldAttach(model: FieldModel<string>): boolean {
    if (model.noticeNode.id === 'BT-131(d)-Lot' || model.noticeNode.id === OFFER_DEADLINE_DATE_ID) {
      this.addDependencies(model.fieldInfo);
      return true;
    }
    return false;
  }

  async validate(model: DateModel): Promise<ValidationNotification[]> {
    const participationDeadlineDateModel = ModelStateService.findModelById(
      model.root,
      'BT-1311(d)-Lot'
    ) as DateModel;

    if (
      participationDeadlineDateModel?.value &&
      !participationDeadlineDateModel.withRelatedTime().isBefore(model.withRelatedTime())
    ) {
      return this.ofSingle(
        ProcedureDeadlineAfterParticipationDeadlineValidator.DEADLINE_MUST_BE_AFTER_PARTICIPATION_DEADLINE
      );
    }
    return this.empty();
  }

  /**
   *  Add Participation Deadline as dependency
   */
  private addDependencies(fieldInfo: FieldInfo) {
    if (!fieldInfo.dependencyFields.includes('BT-1311(t)-Lot')) {
      fieldInfo.dependencyFields.push('BT-1311(t)-Lot');
    }
    if (!fieldInfo.dependencyFields?.includes('BT-1311(d)-Lot')) {
      fieldInfo.dependencyFields.push('BT-1311(d)-Lot');
    }
  }
}
