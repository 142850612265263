import { ModelValidator } from '../ModelValidator';
import { ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';
import { CodelistModel } from '../../../../view-model/type/codelist-model';
import { ModelStateService } from '../../../view-model/model-state.service';

export class MainNatureInAtLeastOneLotValidator extends ModelValidator<CodelistModel> {
  private static MAIN_NATURE_LOT_ID = 'BT-23-Lot';
  private static MAIN_NATURE_PART_ID = 'BT-23-Part';
  private static MAIN_NATURE_PROCEDURE_ID = 'BT-23-Procedure';

  validate(codelistModel: CodelistModel): Promise<ValidationNotification[]> {
    this.addModelDependency(codelistModel, MainNatureInAtLeastOneLotValidator.MAIN_NATURE_LOT_ID);
    this.addModelDependency(codelistModel, MainNatureInAtLeastOneLotValidator.MAIN_NATURE_PART_ID);

    const mainNature = ModelStateService.findModelById(
      codelistModel.root,
      MainNatureInAtLeastOneLotValidator.MAIN_NATURE_PROCEDURE_ID
    ) as CodelistModel;

    const lotNature = ModelStateService.findModelsById(
      codelistModel.root,
      MainNatureInAtLeastOneLotValidator.MAIN_NATURE_LOT_ID
    ) as CodelistModel[];

    const partNature = ModelStateService.findModelsById(
      codelistModel.root,
      MainNatureInAtLeastOneLotValidator.MAIN_NATURE_PART_ID
    ) as CodelistModel[];

    if (
      lotNature.length > 0 &&
      lotNature.some(lotMainNature => lotMainNature.value !== null) &&
      !lotNature.some(lotMainNature => lotMainNature.value === mainNature.value)
    ) {
      return this.ofSingle(this.getMessageFor('Losebene'));
    }

    if (
      partNature.length > 0 &&
      partNature.some(lotPartNature => lotPartNature.value !== null) &&
      !partNature.some(lotPartNature => lotPartNature.value === mainNature.value)
    ) {
      return this.ofSingle(this.getMessageFor('Teilebene'));
    }

    return this.empty();
  }

  shouldAttach(model: FieldModel<string>): boolean {
    return model?.noticeNode?.id === MainNatureInAtLeastOneLotValidator.MAIN_NATURE_PROCEDURE_ID;
  }

  private getMessageFor(type: 'Losebene' | 'Teilebene') {
    return `Die Art des Auftrags auf Verfahrensebene muss mit mindestens einer Art des Auftrags auf ${type} übereinstimmen.`;
  }

  private addModelDependency(codelistModel: CodelistModel, dependencyId: string) {
    if (!codelistModel.fieldInfo.dependencyFields) {
      codelistModel.fieldInfo.dependencyFields = [];
    }
    if (!codelistModel.fieldInfo.dependencyFields.includes(dependencyId)) {
      codelistModel.fieldInfo.dependencyFields.push(dependencyId);
    }
  }
}
