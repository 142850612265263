import { Injectable } from '@angular/core';
import { Contact } from '../types/contact';
import { CommunicationService } from './communication.service';
import { GroupModel } from '../view-model/group-model';
import { MultilingualModel } from '../view-model/type/multilingual-model';
import { RepeatableFieldModel } from '../view-model/repeatable-field-model';
import { FieldModel } from '../view-model/field-model';
import { BaseModel } from '../view-model/base-model';
import { firstValueFrom } from 'rxjs';
import { ModelListenerService } from './view-model/model-listener.service';
import { ModelStateService } from './view-model/model-state.service';
import { CodelistModel } from '../view-model/type/codelist-model';
import { NoticeNode } from '../types/notice-definition';
import { CodelistModelService } from './ui/codelist-model.service';
import { StateService } from './state.service';
import { Language } from '../types/data-model';

@Injectable()
export class ContactService {
  private contacts: Contact[] = [];

  constructor(
    private communicationService: CommunicationService,
    private modelListenerService: ModelListenerService,
    private codeListModelService: CodelistModelService,
    private stateService: StateService
  ) {}

  async loadContacts(): Promise<void> {
    const contactsWrapper = await this.communicationService.loadContacts();
    this.contacts = contactsWrapper.contacts;
  }

  async addContact(model: GroupModel, isTouchPoint: boolean): Promise<void> {
    const organizationGroup = isTouchPoint ? null : 'GR-Company';
    const addressGroup = isTouchPoint ? 'GR-TouchPoint-Address' : 'GR-Company-Address';
    const contactGroup = isTouchPoint ? 'GR-TouchPoint-Contact' : 'GR-Company-Contact';

    const contact = {
      officialName: await this.getFieldValue(
        model,
        this.buildBtId('BT-500-Organization', isTouchPoint),
        organizationGroup
      ),
      nationalId: organizationGroup
        ? await this.getFieldValue(
            model,
            this.buildBtId('BT-501-Organization', isTouchPoint),
            organizationGroup
          )
        : '',
      url: await this.getFieldValue(
        model,
        this.buildBtId('BT-505-Organization', isTouchPoint),
        organizationGroup
      ),
      urlBuyer: await this.getFieldValue(
        model,
        this.buildBtId('BT-509-Organization', isTouchPoint),
        organizationGroup
      ),
      address: await this.getFieldValue(
        model,
        this.buildBtId('BT-510(a)-Organization', isTouchPoint),
        addressGroup
      ),
      town: await this.getFieldValue(
        model,
        this.buildBtId('BT-513-Organization', isTouchPoint),
        addressGroup
      ),
      postalCode: await this.getFieldValue(
        model,
        this.buildBtId('BT-512-Organization', isTouchPoint),
        addressGroup
      ),
      nuts: await this.getFieldValue(
        model,
        this.buildBtId('BT-507-Organization', isTouchPoint),
        addressGroup
      ),
      country: await this.getFieldValue(
        model,
        this.buildBtId('BT-514-Organization', isTouchPoint),
        addressGroup
      ),
      contactPoint: await this.getFieldValue(
        model,
        this.buildBtId('BT-502-Organization', isTouchPoint),
        contactGroup
      ),
      email: await this.getFieldValue(
        model,
        this.buildBtId('BT-506-Organization', isTouchPoint),
        contactGroup
      ),
      phone: await this.getFieldValue(
        model,
        this.buildBtId('BT-503-Organization', isTouchPoint),
        contactGroup
      ),
      fax: await this.getFieldValue(
        model,
        this.buildBtId('BT-739-Organization', isTouchPoint),
        contactGroup
      ),
    } as Contact;

    this.contacts.push(contact);
    await this.communicationService.saveContacts(this.contacts);
  }

  async applyContact(model: GroupModel, contact: Contact, isTouchPoint: boolean) {
    const organizationGroupId = isTouchPoint ? null : 'GR-Company';
    const addressGroupId = isTouchPoint ? 'GR-TouchPoint-Address' : 'GR-Company-Address';
    const contactGroupId = isTouchPoint ? 'GR-TouchPoint-Contact' : 'GR-Company-Contact';
    const countryId = await this.getCountryId(contact.country);

    await this.setValue(
      model,
      this.buildBtId('BT-500-Organization', isTouchPoint),
      organizationGroupId,
      contact.officialName
    );

    if (!isTouchPoint) {
      await this.setValue(
        model,
        this.buildBtId('BT-501-Organization', isTouchPoint),
        organizationGroupId,
        contact.nationalId
      );
    }

    await this.setValue(
      model,
      this.buildBtId('BT-505-Organization', isTouchPoint),
      organizationGroupId,
      contact.url
    );
    await this.setValue(
      model,
      this.buildBtId('BT-509-Organization', isTouchPoint),
      organizationGroupId,
      contact.urlBuyer
    );
    await this.setValue(
      model,
      this.buildBtId('BT-510(a)-Organization', isTouchPoint),
      addressGroupId,
      contact.address
    );

    await this.setValue(
      model,
      this.buildBtId('BT-513-Organization', isTouchPoint),
      addressGroupId,
      contact.town
    );

    await this.setValue(
      model,
      this.buildBtId('BT-512-Organization', isTouchPoint),
      addressGroupId,
      contact.postalCode
    );
    await this.setValue(
      model,
      this.buildBtId('BT-507-Organization', isTouchPoint),
      addressGroupId,
      contact.nuts
    );

    await this.setValue(
      model,
      this.buildBtId('BT-514-Organization', isTouchPoint),
      addressGroupId,
      countryId ? countryId : contact.country
    );
    await this.setValue(
      model,
      this.buildBtId('BT-502-Organization', isTouchPoint),
      contactGroupId,
      contact.contactPoint
    );
    await this.setValue(
      model,
      this.buildBtId('BT-506-Organization', isTouchPoint),
      contactGroupId,
      contact.email
    );
    await this.setValue(
      model,
      this.buildBtId('BT-503-Organization', isTouchPoint),
      contactGroupId,
      contact.phone
    );
    await this.setValue(
      model,
      this.buildBtId('BT-739-Organization', isTouchPoint),
      contactGroupId,
      contact.fax
    );
  }

  getContacts(): Contact[] {
    return this.contacts ? this.contacts : [];
  }

  deleteContact(contact: Contact): Contact[] {
    const key = this.contacts.indexOf(contact, 0);
    this.contacts.splice(key, 1);
    this.communicationService.saveContacts(this.contacts);
    return this.contacts;
  }

  copyContact(contact: Contact) {
    this.contacts.push(contact);
    this.communicationService.saveContacts(this.contacts);
    return this.contacts;
  }

  editContact(currentContact: Contact, importedContact: Contact): Contact[] {
    if (currentContact && importedContact) {
      const indexOfOldContact = this.contacts.indexOf(importedContact, 0);
      this.contacts[indexOfOldContact] = currentContact;
      this.communicationService.saveContacts(this.contacts);
    }
    return this.contacts;
  }

  private getTargetField(
    groupModel: GroupModel,
    btId: string,
    targetGroupId: string
  ): FieldModel<any> {
    let targetGroup = groupModel;
    if (targetGroupId) {
      const foundTargetGroup = this.findInGroupModel(groupModel, targetGroupId);
      if (foundTargetGroup instanceof GroupModel) {
        targetGroup = foundTargetGroup;
      }
    }

    return this.findInGroupModel(targetGroup, btId) as FieldModel<any>;
  }

  private async setValue(
    groupModel: GroupModel,
    btId: string,
    targetGroupId: string,
    value: string
  ) {
    const targetField = this.getTargetField(groupModel, btId, targetGroupId);
    if (!targetField) {
      return;
    }

    if (targetField instanceof RepeatableFieldModel) {
      targetField.children[0].value = value;
      targetField.children[0].emitChange();
    } else if (targetField instanceof MultilingualModel) {
      await this.setMultiLingualValue(targetField, value);
    } else {
      targetField.value = value;
    }

    // Group can be minified, so components don't exist yet. So we have to trigger the Chance-Cycle manually.
    this.modelListenerService.onModelValueChange(targetField);
  }

  private async setMultiLingualValue(targetField: MultilingualModel, value: string) {
    const languages = await firstValueFrom(this.stateService.getLanguages());
    const hasDE = languages.includes(Language.Deu);
    if (hasDE) {
      targetField.germanValue = value;
    } else {
      targetField.englishValue = value;
    }
  }

  private async getFieldValue(groupModel: GroupModel, btId: string, targetGroupId: string) {
    const languages = await firstValueFrom(this.stateService.getLanguages());
    const hasDE = languages.includes(Language.Deu);

    const targetField = this.getTargetField(groupModel, btId, targetGroupId);
    if (!targetField) {
      return;
    }

    if (targetField instanceof RepeatableFieldModel) {
      return targetField.children[0].value;
    } else if (targetField instanceof MultilingualModel) {
      return hasDE && targetField.germanValue ? targetField.germanValue : targetField.englishValue;
    } else {
      return targetField.value;
    }
  }

  private findInGroupModel(groupModel: GroupModel, btId: string): BaseModel | null {
    const baseModel = ModelStateService.findModelById<BaseModel>(groupModel, btId);
    if (!baseModel) {
      console.warn(`${btId} not found in:`, groupModel.children);
    }
    return baseModel;
  }

  private buildBtId(btId: string, isTouchPoint: boolean): string {
    return isTouchPoint ? `${btId}-TouchPoint` : `${btId}-Company`;
  }

  private async getCountryId(country: string): Promise<string> {
    const countryList = await this.loadCountryList();
    return countryList.find(item => item.label.toLowerCase() === country?.toLowerCase())?.id;
  }

  private async loadCountryList(): Promise<{ id: string; label: string }[]> {
    const codeListCountryModel = new CodelistModel(this.codeListModelService);
    codeListCountryModel.noticeNode = { id: 'BT-514-Organization-Company' } as NoticeNode;
    await codeListCountryModel.loadSdkProperties();
    return codeListCountryModel.codeList;
  }
}
