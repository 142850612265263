import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import readonlyFields from '../../../../services/readonly-fields.json';

@Component({
  selector: 'app-rs-template-tooltips',
  templateUrl: './rs-template-tooltip.component.html',
  styleUrls: ['./rs-template-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsTemplateTooltipComponent implements OnInit {
  @Input() btId: string;
  text: string;
  showTooltip: boolean;

  ngOnInit(): void {
    if (readonlyFields.rsTemplateFields.fieldsWithPlaceholderValue.includes(this.btId)) {
      this.text = 'Dieses Feld ist in der Vorlagenfunktion mit einem Platzhalterwert befüllt.';
      this.showTooltip = true;
    } else if (readonlyFields.rsTemplateFields.fieldsWithoutPlaceholderValue.includes(this.btId)) {
      this.text = 'Dieses Feld ist in der Vorlagenfunktion nicht änderbar.';
      this.showTooltip = true;
    } else {
      this.text = '';
      this.showTooltip = false;
    }
  }
}
