import { Pipe, PipeTransform } from '@angular/core';
import { NoticeTypesService } from '../notice-types.service';
import { Observable } from 'rxjs';

@Pipe({ name: 'noticeTypeToLabel' })
export class NoticeTypeToLabelPipe implements PipeTransform {
  constructor(private noticeTypesService: NoticeTypesService) {}

  transform(type: string): Observable<string> {
    return this.noticeTypesService.getTypeLabelByNoticeType(type);
  }
}
