import { FieldValueType } from './data-model';

export interface NoticeDefinition {
  noticeId: string;
  ublVersion: string;
  sdkVersion: string;

  metadata: NoticeNode[];
  content: NoticeNode[];
}

export interface NoticeNode {
  id: string;
  nodeId?: string;
  _identifierFieldId?: string;

  _idScheme?: string;
  _idSchemes?: string[];

  contentType: ContentType;
  displayType: DisplayType;
  description: string;
  _label: string;
  _captionFieldId?: string;
  _repeatable?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  collapsed?: boolean;
  valueSource?: string;
  presetValue?: FieldValueType; // form-type specific preset, added in Pre-Processing
  _presetValue?: FieldValueType | '{NOW}'; // eForms SDK Specific preset value
  skipEditorValidation?: boolean; // Will be true, if the editor should not validate this field. This is important for fields, which are set in backend.
  content?: NoticeNode[];
}

export enum DisplayType {
  TEXTAREA = 'TEXTAREA',
  TEXTBOX = 'TEXTBOX',
  COMBOBOX = 'COMBOBOX',
  RADIO = 'RADIO',
  GROUP = 'GROUP',
  SECTION = 'SECTION',
}

export enum ContentType {
  GROUP = 'group',
  FIELD = 'field',
}
