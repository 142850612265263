import { Injectable } from '@angular/core';
import { GroupModel } from '../../view-model/group-model';
import { RepeatableGroupModel } from '../../view-model/repeatable-group-model';
import { FieldModel } from '../../view-model/field-model';
import { ModelStateService } from '../view-model/model-state.service';
import { SectionModel } from '../../view-model/section-model';
import { ComponentModel } from '../../view-model/component-model';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';

@Injectable()
export class ExpansionService {
  private componentModel = this.modelStateService.componentModel;
  private descriptionsExpanded = true;

  constructor(private modelStateService: ModelStateService) {}

  expandAllGroups() {
    this.expandCollapseGroupsRecursive(true, this.componentModel);
  }

  collapseAllGroups() {
    this.expandCollapseGroupsRecursive(false, this.componentModel);
  }

  toggleDescriptions() {
    this.descriptionsExpanded = !this.descriptionsExpanded;
    this.toggleDescriptionsRecursive(this.componentModel);
  }

  private expandCollapseGroupsRecursive(
    expand: boolean,
    root: ComponentModel | SectionModel | RepeatableSectionModel | GroupModel | RepeatableGroupModel
  ) {
    if (root instanceof GroupModel || root instanceof RepeatableGroupModel) {
      root.isExpanded = expand;
    }
    for (const element of root.children) {
      if (!(element instanceof FieldModel) && element.children) {
        this.expandCollapseGroupsRecursive(expand, element);
      }
    }
  }

  private toggleDescriptionsRecursive(
    root: ComponentModel | SectionModel | RepeatableSectionModel | GroupModel | RepeatableGroupModel
  ) {
    for (const element of root.children) {
      if (element instanceof FieldModel || element instanceof GroupModel) {
        element.showDescription = this.descriptionsExpanded;
      }
      if (!(element instanceof FieldModel)) {
        this.toggleDescriptionsRecursive(element);
      }
    }
  }
}
