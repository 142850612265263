import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GroupModel } from '../../../../../view-model/group-model';
import { DialogComponent } from '../../../../layout/dialog/dialog.component';
import { SelectionCriterion } from '../../../../../types/selection-criterion';
import { SelectionCriterionService } from '../../../../../services/selection-criterion.service';
import { GenericDialogService } from '../../../../../services/ui/generic-dialog.service';
import { ModelStateService } from '../../../../../services/view-model/model-state.service';
import { Subscription } from 'rxjs';
import { CodelistModel } from '../../../../../view-model/type/codelist-model';
import { NoticeNode } from '../../../../../types/notice-definition';
import { CodelistModelService } from '../../../../../services/ui/codelist-model.service';
import { FieldModel } from '../../../../../view-model/field-model';
import { MultilingualModel } from '../../../../../view-model/type/multilingual-model';
import { StateService } from '../../../../../services/state.service';
import { Language } from '../../../../../types/data-model';

@Component({
  selector: 'app-selection-criteria-book',
  templateUrl: './selection-criteria-book.component.html',
  styleUrls: ['./selection-criteria-book.component.scss'],
})
export class SelectionCriteriaBookComponent implements OnInit, OnDestroy {
  @ViewChild('selectionCriteriaBookDialog') selectionCriteriaBookDialog: DialogComponent;
  @Input() model: GroupModel;
  criteria: SelectionCriterion[];
  filteredCriteria: SelectionCriterion[];
  searchQuery = '';
  codeListType: { id: string; label: string }[];
  subscription = new Subscription();
  selectedLanguages: Language[] = [];

  constructor(
    private selectionCriterionService: SelectionCriterionService,
    private genericDialogService: GenericDialogService,
    private cdRef: ChangeDetectorRef,
    private codeListModelService: CodelistModelService,
    private stateService: StateService
  ) {}

  async ngOnInit() {
    this.codeListType = await this.createCodeList('BT-809-Lot');

    const descriptionModel: MultilingualModel = ModelStateService.findModelById(
      this.model,
      'BT-750-Lot'
    );

    this.subscription.add(
      descriptionModel?.modelChangedEmitter.subscribe(() => this.cdRef.markForCheck())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openSelectionCriteriaBookDialog() {
    this.criteria = this.selectionCriterionService.getSelectionCriteria();
    this.filteredCriteria = this.criteria;
    this.searchQuery = '';
    this.sortCriteria();
    this.selectionCriteriaBookDialog.openDialog('65%');
  }

  async saveSelectionCriterion() {
    await this.selectionCriterionService.addCriterion(this.model);
    await this.genericDialogService.showResultDialog(
      'Eignungskriterium speichern',
      'Das Eignungskriterium wurde erfolgreich gespeichert.',
      'Schließen',
      true
    );
  }

  onClose(): void {
    this.criteria = null;
  }

  async deleteCriterion(selected: SelectionCriterion) {
    const confirm = await this.genericDialogService.confirmWithDialog(
      'Möchten Sie wirklich das Eignungskriterium löschen?',
      'Löschen',
      'Abbrechen'
    );
    if (confirm) {
      this.criteria = this.selectionCriterionService.deleteCriterion(selected);
      this.searchCriterion(this.searchQuery);
      this.cdRef.markForCheck();
    }
  }

  saveCriteriaChanges(criteria: SelectionCriterion[]) {
    this.criteria = criteria;
    this.searchCriterion(this.searchQuery);
    this.sortCriteria();
  }

  sortCriteria() {
    this.criteria.sort((a, b) => (a.criterionType?.toLowerCase() > b.criterionType?.toLowerCase() ? 1 : -1));
  }

  searchCriterion(search: string) {
    if (!search) {
      this.filteredCriteria = this.criteria;
    } else {
      this.filteredCriteria = this.criteria.filter(criterion => {
        const criterionValues: any = Object.values(criterion);
        return criterionValues.some(
          value => value && String(value).trim().toLowerCase().includes(search.trim().toLowerCase())
        );
      });
    }
  }

  isSaveAllowed(): boolean {
    if(!this.hasSelectionCriteria()) return false;

    const criterionType = ModelStateService.findModelById<FieldModel<string>>(
      this.model,
      'BT-809-Lot'
    )?.value;
    if (!criterionType) {
      return false
    }
    const description = ModelStateService.findModelById<MultilingualModel>(
      this.model,
      'BT-750-Lot'
    );
    if (!description) {
      return false
    }
    return !!(description.value.DEU || description.value.ENG)
  }

  hasSelectionCriteria(): boolean {
    const criterionTypeModel = ModelStateService.findModelById<FieldModel<string>>(
      this.model,
      'BT-809-Lot'
    );
    if(!criterionTypeModel) return false;

    return !criterionTypeModel.isHidden;
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.searchCriterion('');
  }

  async setCriterion(selected: SelectionCriterion) {
    await this.selectionCriterionService.applyCriterion(this.model, selected);
    this.selectionCriteriaBookDialog.close();
    await this.genericDialogService.showResultDialog(
      'Eignungskriterium einfügen',
      'Das Eignungskriterium wurde erfolgreich eingefügt.',
      'Schließen',
      true
    );
  }

  async createCodeList(bt: string) {
    const model = new CodelistModel(this.codeListModelService);
    model.noticeNode = { id: bt } as NoticeNode;
    await model.loadSdkProperties();
    return model.codeList;
  }

  findCodeListItemLabel(itemId: string, codelist) {
    return codelist.filter(item => item.id === itemId)[0].label;
  }
}
