import { Injectable } from '@angular/core';
import { isHoliday, getHolidayByDate, Holiday } from 'feiertagejs';
import { convert, LocalDate } from '@js-joda/core';
import { DateUtilsService } from './date-utils-service';

@Injectable({
  providedIn: 'root',
})
export class HolidayUtilsService {
  constructor(private dateUtilsService: DateUtilsService) {
    /* Wraps the feiertagejs api, it should only be called from this abstraction layer. */
  }

  public isNationwideHoliday(date: LocalDate): boolean {
    return isHoliday(this.dateUtilsService.toJsDate(date.atStartOfDay()), 'BUND');
  }

  public getNationwideHolidayByDate(date: LocalDate): string {
    if (isHoliday(convert(date).toDate(), 'BUND')) {
      const holiday = getHolidayByDate(
        this.dateUtilsService.toJsDate(date.atStartOfDay()),
        'BUND'
      ) as Holiday;
      return holiday.translate('de');
    }
    return '';
  }
}
