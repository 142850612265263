import { FieldInfo, FieldInfoType, XmlStructure } from './field-types';
import { Amount, EfxModelType, FieldValueType, LocalisedText, Measure } from './data-model';
import { BaseModel } from '../view-model/base-model';
import { convert, DateTimeFormatter, LocalTime, ZonedDateTime, ZoneId } from '@js-joda/core';
import '@js-joda/timezone'; // Needed for ZoneId.of
import { DateModel } from '../view-model/type/date-model';
import { TimeModel } from '../view-model/type/time-model';

export class ConceptNode {
  public nodeIndex = ConceptNode.globalNodeIndex++;

  businessTerm: string;
  value: string;
  qualifier: string;

  children: ConceptNode[] = [];
  // Value, which will be accessed by EFX-JS Validation.
  // Needs to be a javascript primitive OR Date
  nativeValue: EfxModelType;
  metadata: FieldInfo | XmlStructure;
  parent: ConceptNode;

  sourceModel: BaseModel;

  private static globalNodeIndex = 0;
  private static berlinZoneId = ZoneId.of('Europe/Berlin');

  constructor(
    id: string,
    value: FieldValueType,
    metadata: FieldInfo | XmlStructure,
    baseModel?: BaseModel
  ) {
    this.businessTerm = id;
    this.metadata = metadata;

    if (value != null) {
      switch ('type' in this.metadata ? this.metadata.type : undefined) {
        case FieldInfoType.AMOUNT:
          const amount: Amount = value as Amount;
          this.value = amount?.amount;
          this.qualifier = amount?.currencyID;
          break;
        case FieldInfoType.MEASURE:
          const measure: Measure = value as Measure;
          this.value = measure?.amount;
          this.qualifier = measure?.unit;
          break;
        case FieldInfoType.TEXT_MULTILINGUAL:
          const text: LocalisedText = value as LocalisedText;
          this.value = text?.text;
          this.qualifier = text?.language;
          break;
        case FieldInfoType.CODE:
          this.value = value as string;
          this.qualifier = (metadata as FieldInfo)?.codeList?.value.id;
          break;
        case FieldInfoType.DATE:
          if (baseModel instanceof DateModel) {
            // Use LocalDate from Model, when ConceptNode is created from BaseModel. Calculate the ZoneOffset by interpreting the date as a Date in Berlin Timezone.
            const localDate = baseModel.asLocalDate();
            if (localDate) {
              const relatedTimeOrMidnight = baseModel.withRelatedTime();
              const zonedRelatedTimeOrMidnight = ZonedDateTime.of(
                relatedTimeOrMidnight,
                ConceptNode.berlinZoneId
              );
              this.value = localDate.format(DateTimeFormatter.ISO_LOCAL_DATE);
              this.qualifier = zonedRelatedTimeOrMidnight.offset().toString();
            }
          } else {
            // Parse Input Value when loading existing concept-model
            const inputSplit = (value as string).split('+');
            this.value = inputSplit[0];
            this.qualifier = inputSplit[1];
          }

          break;
        case FieldInfoType.TIME:
          if (baseModel instanceof TimeModel) {
            const withRelatedTime = baseModel.relatedDateModel.withRelatedTime();
            // Field can be null
            if (withRelatedTime) {
              const zonedDateTime = ZonedDateTime.of(withRelatedTime, ConceptNode.berlinZoneId);
              this.value = baseModel.value
                ? LocalTime.parse(baseModel.value).format(DateTimeFormatter.ISO_LOCAL_TIME)
                : null;
              this.qualifier = zonedDateTime.offset().toString();
            }
          } else {
            const splitTime = (value as string).split('+');
            this.value = splitTime[0];
            this.qualifier = splitTime[1];
          }

          break;
        default:
          this.value = value as string;
      }

      // Convert types to js-types for easy processing in EFX-JS Validation
      switch ('type' in this.metadata ? this.metadata.type : undefined) {
        case FieldInfoType.DATE:
          // Model is only needed for EFX, so we only need to set it when we are creating the Node from a TimeModel.
          if (baseModel instanceof DateModel) {
            this.nativeValue = convert(baseModel.asLocalDate().atStartOfDay()).toDate();
          }
          break;
        case FieldInfoType.TIME:
          // Represent Time as Seconds from Midnight.
          // Model is only needed for EFX, so we only need to set it when we are creating the Node from a TimeModel.
          if (baseModel instanceof TimeModel && baseModel.value) {
            this.nativeValue = LocalTime.parse(baseModel.value.split('+')[0]).toSecondOfDay();
          }
          break;
        case FieldInfoType.TEXT_MULTILINGUAL:
          // Multilingual Component: Use Text as nativeValue
          if (value) {
            this.nativeValue = (value as LocalisedText).text;
          }
          break;
        case FieldInfoType.AMOUNT:
          if ((value as Amount).amount) {
            this.nativeValue = Number((value as Amount).amount);
          }
          break;
        case FieldInfoType.MEASURE:
          if ((value as Measure).amount) {
            this.nativeValue = Number((value as Measure).amount);
          }
          break;
        case FieldInfoType.NUMBER:
          this.nativeValue = Number(value);
          break;
        case FieldInfoType.INTEGER:
          this.nativeValue = Number(value);
          break;
        case FieldInfoType.INDICATOR:
          this.nativeValue = value === 'true' || value === true;
          break;
        default:
          this.nativeValue = value as string;
      }
    }
  }

  public isRepeatable(): boolean {
    if (this.isNode()) {
      const struct = this.metadata as XmlStructure;
      return struct.repeatable;
    }

    const meta = this.metadata as FieldInfo;
    return !!meta.repeatable?.value || meta.type === FieldInfoType.TEXT_MULTILINGUAL;
  }

  private isNode(): boolean {
    return this.businessTerm.startsWith('ND-');
  }
}

export class ConceptModel {
  noticeSubTypeCode: string;
  root: ConceptNode;
}
