/**
 * Swagger Template-Service - OpenAPI 3.0
 * Ein Service zum verwalten von Vorlagen für eForms Bekanntmachungen.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConceptNode { 
    businessTerm?: string;
    value?: string;
    qualifier?: string;
    children?: Array<ConceptNode>;
    parent?: ConceptNode;
}

