import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from '../../../components/layout/dialog/dialog.component';
import { TemplateOverviewResponse } from '../../api/templates';

export interface VorlagenMetadataConfirmedEvent {
  id: number;
  metadata: VorlagenMetadata;
}

export interface VorlagenMetadata {
  name: string;
  description?: string;
}

@Component({
  selector: 'app-vorlagen-name-dialog',
  templateUrl: './vorlagen-name-dialog.component.html',
  styleUrls: ['./vorlagen-name-dialog.component.scss'],
})
export class VorlagenNameDialogComponent {
  nameMaxLength = 50;
  descriptionMaxLength = 400;
  @ViewChild('vorlagenNameDialog') vorlagenNameDialog: DialogComponent;
  @Input() dialogTitle?: string;
  @Input() acceptButtonLabel?: string;
  @Output() confirm = new EventEmitter<VorlagenMetadataConfirmedEvent>();

  vorlagenInputForm: FormGroup;

  private templateId: number;

  openDialog(template?: TemplateOverviewResponse) {
    this.templateId = template?.templateId;
    this.vorlagenInputForm = new FormGroup({
      name: new FormControl(template?.name ?? '', [
        Validators.required,
        Validators.maxLength(this.nameMaxLength),
      ]),
      description: new FormControl(
        template?.description ?? '',
        Validators.maxLength(this.descriptionMaxLength)
      ),
    });
    this.validateIfConfirmButtonIsEnabled();
    return this.vorlagenNameDialog.openDialog();
  }

  onClickWeiter() {
    this.confirm.emit({
      id: this.templateId,
      metadata: {
        name: this.vorlagenInputForm.get('name').value.trim(),
        description: this.vorlagenInputForm.get('description').value.trim(),
      },
    });
  }

  validateIfConfirmButtonIsEnabled() {
    if (this.vorlagenInputForm.valid) {
      this.vorlagenNameDialog.enableAcceptButton();
    } else {
      this.vorlagenNameDialog.disableAcceptButton();
    }
  }

  onDismiss() {
    this.vorlagenInputForm.reset();
  }

  remainingNameCharacters(): number {
    if (
      this.vorlagenInputForm.get('name').value === undefined ||
      this.vorlagenInputForm.get('name').value === null
    ) {
      return this.nameMaxLength;
    }

    return this.nameMaxLength - this.vorlagenInputForm.get('name').value.length;
  }

  remainingDescriptionCharacters(): number {
    if (
      this.vorlagenInputForm.get('description').value === undefined ||
      this.vorlagenInputForm.get('description').value === null
    ) {
      return this.descriptionMaxLength;
    }

    return this.descriptionMaxLength - this.vorlagenInputForm.get('description').value.length;
  }
}
