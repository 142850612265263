import { EfxModelType } from '../../../types/data-model';
import { ConceptModel, ConceptNode } from '../../../types/concept-node';
import { ConceptModelGen } from '../../parser/concept-model-gen';
import { BaseModel } from '../../../view-model/base-model';
import { Fields } from '../../../types/field-types';

/**
 * Stateful Provider for Symbol-Resolution inside the ConceptModel.
 */
export class SymbolResolutionProvider {
  // ConceptModel, all caches are valid for.
  private static cacheValidForConceptModel: ConceptModel = null;
  // Static Structural Cache for field.json. Always valid for a given fields.json.
  private static structureCache = new Map();
  // Cache for SymbolResolutionProvider
  private static resolverCache = new Map();
  // Maps a BaseModel to its node inside the ConceptModel.
  private static baseModelToConceptNodeCache = new Map();

  // Root-Node of the ConceptModel
  private readonly _conceptModelRoot: ConceptNode;
  // The ConceptNode inside the ConceptModel, which is corresponding to the sourceModel
  private readonly _sourceConceptNode: ConceptNode;
  // ConceptNode inside the ConceptModel, which represents the Context, in which the EFX Expression is executed in.
  private readonly _efxContextNode: ConceptNode;

  // Instance-Cache for repeated node resolutions
  private localNodeResolveCache = new Map();
  // Map which holds all resolutions and their results. Used for Developer-Debug.
  private _resolutionResultMap = {} as any;
  // Index for resolution, only needed for _resolutionResultMap
  private _resolutionIndex = 0;

  /**
   * Gets a Resolver for the given sourceModel, ContextNodeId and conceptModel.
   * This Factory Method uses a Cache, which will reuse already created SymbolResolvers.
   * Creating a SymbolResolver is costly, as it will search the concept-model to find the source-model corresponding node.
   * The Cache is only valid for a specific given ConceptModel.
   *
   * @see constructor
   */
  public static getResolver(
    sourceModel: BaseModel,
    efxContextNodeId: string,
    conceptModel: ConceptModel,
    fields: Fields
  ): SymbolResolutionProvider {
    const cacheKey = efxContextNodeId + 'MODEL' + sourceModel.modelIndex;
    if (conceptModel === this.cacheValidForConceptModel) {
      const cacheHit = this.resolverCache.get(cacheKey);
      if (cacheHit !== undefined) {
        cacheHit._resolutionResultMap = {};
        return cacheHit;
      }
    } else {
      // The cache has to be invalidated, when a new concept-model has been generated.
      this.resolverCache.clear();
      this.baseModelToConceptNodeCache.clear();
      this.cacheValidForConceptModel = conceptModel;
    }

    const symbolResolver = new SymbolResolutionProvider(
      sourceModel,
      efxContextNodeId,
      conceptModel,
      fields
    );
    this.resolverCache.set(cacheKey, symbolResolver);
    return symbolResolver;
  }

  /**
   * A SymbolResolutionProvider is the Stateful Representation of a single EFX Symbol Resolution Request.
   *
   * @param sourceModel of the Field of which the EFX Function originates from. This is needed to find the corresponding Node inside the ConceptModel.
   * @param efxContextNodeId the execution context of the EFX Expression. The Execution Context of "{ND-ROOT} TRUE" would be ND-ROOT.
   * @param conceptModel the conceptModel of the form
   * @param fields repository from the sdk
   */
  public constructor(
    private sourceModel: BaseModel,
    private efxContextNodeId: string,
    conceptModel: ConceptModel,
    private fields: Fields
  ) {
    this._conceptModelRoot = conceptModel.root;
    this._sourceConceptNode = this.findSourceConceptNode(this._conceptModelRoot);
    this._efxContextNode = this.findEfxContextNode(this._sourceConceptNode);
  }

  /**
   * This will locate the ConceptNode Representation of the given {@link sourceModel} inside the given ConceptModel.
   */
  public findSourceConceptNode(node: ConceptNode): ConceptNode {
    const cacheHit = SymbolResolutionProvider.baseModelToConceptNodeCache.get(this.sourceModel);
    if (cacheHit !== undefined) {
      return cacheHit;
    }

    if (node.sourceModel === this.sourceModel) {
      SymbolResolutionProvider.baseModelToConceptNodeCache.set(this.sourceModel, node);
      return node;
    }

    for (const child of node.children) {
      const found = this.findSourceConceptNode(child);
      if (found) {
        return found;
      }
    }
    return undefined;
  }

  /**
   * This will look up the ConceptNode inside the subtree of the {@param node}, which the {@link efxContextNodeId} is referring to
   *
   * @param node current subtree to search for the Context Node.
   */
  public findEfxContextNode(node: ConceptNode): ConceptNode {
    if (!node) return undefined;

    if (node.businessTerm === this.efxContextNodeId) {
      if (ConceptModelGen.isBt(node.businessTerm)) return node.parent;
      return node;
    }

    if (node.parent == null) {
      return undefined;
    }

    return this.findEfxContextNode(node.parent);
  }

  /**
   * Symbol Resolver for the Transpiled EFX Expressions.
   * This function is getting passed into the evaluated JS-Validation function.
   * Will return a List of all values of the given nodeId in the current context.
   *
   * @param nodeId
   * @param context
   * @param absoluteContext
   */
  public resolveFieldList(
    nodeId: string,
    context: ConceptNode,
    absoluteContext = false
  ): EfxModelType[] {
    const symbol = this.resolveNode(nodeId, context, absoluteContext);
    return symbol
      .filter(
        node => node.value !== null && node.value !== undefined && !node.sourceModel?.isForbidden
      )
      .map(item => item.nativeValue);
  }

  /**
   * Symbol Resolver for the Transpiled EFX Expressions.
   * This function is getting passed into the evaluated JS-Validation Functions.
   * Will return a List of all found ConceptNodes in the given context.
   *
   * @param nodeId nodeid which is to be Searched
   * @param context the Node, which is containing the function.
   * @param absoluteContext resolution is absolute to context
   * @private
   */
  public resolveNode(nodeId: string, context: ConceptNode, absoluteContext = false): ConceptNode[] {
    if (!this._conceptModelRoot) throw new Error('ConceptModel not loaded');
    const cacheKey = nodeId + 'node' + context.nodeIndex + absoluteContext;
    const cacheHit = this.localNodeResolveCache.get(cacheKey);
    if (cacheHit !== undefined) {
      return cacheHit;
    }

    // Does the FormTree contain the NodeId?
    const targetNode: ConceptNode[] = this.walkUpTreeUntilBtFound(
      nodeId,
      absoluteContext ? this._conceptModelRoot : context,
      absoluteContext
    );

    // Save resolution result as metadata for debugging
    this._resolutionResultMap[`${nodeId}_${this._resolutionIndex++}`] = targetNode;

    this.localNodeResolveCache.set(cacheKey, targetNode);
    return targetNode;
  }

  /**
   * Searches for a collection of a given Field-/NodeId.
   * When the given Field-/NodeId exists in the structure of the current Node (by fields.json), it will return all results in its subtree.
   * When the Field ID can not exist inside the structure of the node, it will search inside the parent, until it is found.
   *
   * @param searchId of the field or node
   * @param currentNode current context of the search
   * @param absolute only true, if the resolution is an absolute EFX Path.
   */
  private walkUpTreeUntilBtFound(
    searchId: string,
    currentNode: ConceptNode,
    absolute: boolean
  ): ConceptNode[] {
    if (!currentNode) {
      return [];
    }

    if (absolute || this.existsInStructureTreeOfContextBt(searchId, currentNode.businessTerm)) {
      return this.findInSubTree(searchId, currentNode);
    } else {
      return this.walkUpTreeUntilBtFound(searchId, currentNode.parent, false);
    }
  }

  /**
   * Searches the Fields structure to determine, if the Field ID to be searched, exists in the subtree of the given Context Field-ID.
   * This consumes a lot of cpu time, especially in large notices with many Lots. This is why we cache results.
   * Important: The cache never gets stale! It's solely based on the fields.json.
   *
   * @param searchFieldId Field-/Node ID of the Field/Node to be searched
   * @param contextFieldId Field-/Node ID of the Current Context-Node
   */
  private existsInStructureTreeOfContextBt(searchFieldId: string, contextFieldId: string): boolean {
    const cacheHit = SymbolResolutionProvider.structureCache.get(searchFieldId + contextFieldId);
    if (cacheHit !== undefined) {
      return cacheHit;
    }
    const fieldExistsInContext = this.fields.fields.some(
      field => field.parentNodeId === contextFieldId && field.id === searchFieldId
    );
    if (fieldExistsInContext) {
      return this.fillCacheAndReturnResult(searchFieldId, contextFieldId, true);
    }

    const nodeExistsInStructure = this.fields.xmlStructure.some(
      element => element.parentId === contextFieldId && element.id === searchFieldId
    );

    if (nodeExistsInStructure) {
      return this.fillCacheAndReturnResult(searchFieldId, contextFieldId, true);
    }

    const childStructures = this.fields.xmlStructure.filter(
      element => element.parentId === contextFieldId
    );

    for (const childStruc of childStructures) {
      if (this.existsInStructureTreeOfContextBt(searchFieldId, childStruc.id)) {
        return this.fillCacheAndReturnResult(searchFieldId, contextFieldId, true);
      }
    }
    return this.fillCacheAndReturnResult(searchFieldId, contextFieldId, false);
  }

  /**
   * Each Lookup always returns the same result for a given contextFieldId + searchFieldId combination.
   * We're using that as a key, to cache the result into a Map.
   */
  private fillCacheAndReturnResult(
    searchFieldId: string,
    contextFieldId: string,
    result: boolean
  ): boolean {
    SymbolResolutionProvider.structureCache.set(searchFieldId + contextFieldId, result);
    return result;
  }

  private findInSubTree(bt: string, node: ConceptNode): ConceptNode[] {
    if (node.businessTerm === bt) {
      return [node];
    }

    return node.children.flatMap(child => this.findInSubTree(bt, child));
  }

  /**
   * Returns an Object, containing all resolved symbols with its values in this instance.
   */
  get resolutionResultMap(): any {
    return this._resolutionResultMap;
  }

  /**
   * ConceptNode representation of the originating sourceModel
   */
  get sourceConceptNode(): ConceptNode {
    return this._sourceConceptNode;
  }

  /**
   * ConceptNode, which represents the Context in which the EFX Function is executed.
   */
  get efxContextNode(): ConceptNode {
    return this._efxContextNode;
  }
}
