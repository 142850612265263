import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextFieldComponent } from '../text-field.component';

@Component({
  templateUrl: '../text-field.component.html',
  styleUrls: ['../text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent extends TextFieldComponent {
  placeholder = '+49 228 12345';
}
