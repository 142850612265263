import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { CodelistModel } from '../../../view-model/type/codelist-model';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-codelist',
  templateUrl: './codelist.component.html',
  styleUrls: ['./codelist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodelistComponent extends AbstractFormComponent implements OnInit {
  @Input()
  model: CodelistModel;
  focusEmitter = new EventEmitter<(el: HTMLElement) => void>();
  codeListItems: { id: string; label: string }[] = [];
  control = new FormControl();
  supportMode: boolean;
  inputType: CodelistComponentType;

  constructor(private stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    this.createCodeListItems();

    this.control.setValue(
      this.model.value ? this.codeListItems.find(item => item.id === this.model.value) : ''
    );

    if (this.model.isReadonly) {
      this.control.disable();
    }

    this.inputType = this.determineInputType();

    this.handleModelChange();
    this.handleInputChanges();
    this.handleSupportModeChanges();
    this.handleFocusChange();

    this.cdRef.markForCheck();
  }

  private determineInputType(): CodelistComponentType {
    if (this.model.isAutoComplete || this.model.isCodeField) {
      return CodelistComponentType.Autocomplete;
    } else if (this.codeListItems.length > 1 && this.codeListItems.length <= 4) {
      return CodelistComponentType.RadioButton;
    } else {
      return CodelistComponentType.Select;
    }
  }

  protected focus(): void {
    this.focusEmitter.emit(super.focus.bind(this));
  }

  private createCodeListItems(): void {
    if (Array.isArray(this.model.codeList)) {
      this.codeListItems = this.model.codeList;
      return;
    }
    for (const [key, value] of Object.entries(this.model.codeList)) {
      this.codeListItems.push({ id: key, label: String(value) });
    }
  }

  private handleModelChange() {
    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => {
        const controlValue = this.control.value?.id ? this.control.value.id : null;
        if (this.model.value !== controlValue) {
          this.control.setValue(
            this.model.value ? this.codeListItems.find(item => item.id === this.model.value) : ''
          );
        }
        this.updateReadOnlyForControl();
        this.cdRef.markForCheck();
      })
    );
  }

  private handleInputChanges(): void {
    this.subscription.add(
      this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(controlValue => {
        const newValue = controlValue?.id ? controlValue.id : null;
        if (this.model.value !== newValue) {
          this.model.value = newValue;
        }
        this.onInputValueChanged();
      })
    );
  }

  private handleSupportModeChanges(): void {
    this.subscription.add(
      this.stateService.getSupportMode().subscribe(next => {
        this.supportMode = next;
        this.cdRef.markForCheck();
      })
    );
  }

  private updateReadOnlyForControl() {
    if (this.model.isReadonly !== this.control.disabled) {
      if (this.model.isReadonly) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  private handleFocusChange(): void {
    this.subscription.add(
      this.focusEmitter.subscribe(fn => fn(document.getElementById(this.htmlId)))
    );
  }

  protected readonly CodelistComponentType = CodelistComponentType;
}

export enum CodelistComponentType {
  Select = 'select',
  Autocomplete = 'autocomplete',
  RadioButton = 'radio-button'
}
