<app-dialog>
  <div class="dialog-content">
    <ng-container [ngSwitch]="data.errorType">
      <ng-container *ngSwitchCase="AuthErrorDialogType.LOGOUT">
        <p>Ihre Sitzung ist abgelaufen oder wurde beendet. Nachdem Sie die Sitzung erneuert haben, können Sie Ihre Arbeit hier fortsetzen.</p>
      </ng-container>
      <ng-container *ngSwitchCase="AuthErrorDialogType.USER_CHANGED">
        <p>Das Nutzerkonto der Sitzung wurde gewechselt. Bitte melden Sie sich mit dem erwarteten Nutzerkonto an.</p>
        <p>Im Anschluss können Sie Ihre Arbeit hier fortsetzen.</p>
      </ng-container>
    </ng-container>

    <pre *ngIf="data.errorText">{{ data.errorText }}</pre>

    <h3>Fehlerdetails</h3>
    <p>Typ: {{ data.errorType }}</p>
    <p>Zeitpunkt: {{ now }}</p>

    <div class="buttons">
      <button class="eForms-button eForms-button--primary" (click)="loginPage()">
        <ng-container [ngSwitch]="data.errorType">
          <ng-container *ngSwitchCase="AuthErrorDialogType.LOGOUT">
            <mat-icon>login</mat-icon>
            <span>Sitzung erneuern</span>
          </ng-container>
          <ng-container *ngSwitchCase="AuthErrorDialogType.USER_CHANGED">
            <mat-icon>login</mat-icon>
            <span>Zum Login</span>
          </ng-container>
        </ng-container>
      </button>
    </div>
  </div>
</app-dialog>
