<div
  class="notification-content"
  [tabIndex]="0"
  (click)="navigateTo()"
  (keydown.space)="navigateTo()"
  (keydown.enter)="navigateTo()"
  [ngSwitch]="this.notification.validationNotification.severity">
  <mat-icon *ngSwitchCase="Severity.ERROR" class="alert-danger">dangerous</mat-icon>
  <mat-icon *ngSwitchCase="Severity.WARNING" class="alert-warning">warning</mat-icon>
  <p class="notification-content-message">
    {{ index + 1 }}. {{ notification.label }}{{ this.multilingual }}:
    {{ notification.validationNotification.message }}
  </p>
</div>
