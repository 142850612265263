import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SortUtilsService } from "../../../utils/sort-utils-service";

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() options: { id: string; label: string; showId?: boolean }[];
  @Input() ariaLabel: string;
  @Input() isMandatory: boolean;
  @Input() isReadonly: boolean;
  @Input() hasError: boolean;
  @Input() htmlId: string;
  @Input() alwaysShowId = false;
  placeholder: string;

  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private subscription = new Subscription();
  private sortUtilsService: SortUtilsService = inject(SortUtilsService);

  ngOnInit() {
    if (this.control.value === null) {
      this.control.setValue('', { emitEvent: false });
    }
    this.placeholder = this.isMandatory ? 'Bitte wählen' : 'Keine Angabe';
    this.sortOptions();
    this.subscription.add(this.control.valueChanges.subscribe(() => this.cdRef.markForCheck()));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private sortOptions() {
    if (this.options.length === 0) return;

    if (this.options[0].showId) {
      this.options.sort((a, b) => a.id.localeCompare(b.id, 'de', { sensitivity: 'base' }));
    } else {
      this.options.sort((a, b) => this.sortUtilsService.sortCodeListItems(a, b));
    }
  }
}
