import { inject, Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { NoticeControllerService, ProcedureControllerService, ProcedureDto, ProcedureOverviewDto } from '../../api/notices';
import { UserSettings, UserSettingsService } from '../../api/user-service';
import { ErrorType } from '../../shared/error-type.enum';
import { RefreshNoticeLockService } from '../../shared/refresh-notice-lock.service';
import { StateService } from '../../shared/state.service';
import { BekanntmachungenVerwaltungState, initialState } from './bekanntmachungen-verwaltung-state.model';
import { BekanntmachungenVerwaltungService } from './bekanntmachungen-verwaltung.service';

@Injectable({
  providedIn: 'root',
})
export class BekanntmachungenVerwaltungStateService extends StateService<BekanntmachungenVerwaltungState> {

  loading$: Observable<boolean> = this.select(state => state.loading);
  loggedInUser$: Observable<KeycloakProfile> = this.select(state => state.loggedInUser).pipe(filter(Boolean));
  procedures$: Observable<ProcedureOverviewDto[]> = this.select(state => state.procedures);
  maxProceduresPerPage$: Observable<number> = this.select(state => state.maxProceduresPerPage);
  favoriteProcedures$: Observable<number[]> = this.select(state => state.favoriteProcedures);
  hiddenProcedures$: Observable<number[]> = this.select(state => state.hiddenProcedures);
  filter$: Observable<{ showFavorites: boolean; showHidden: boolean; favoriteProcedures: number[]; hiddenProcedures: number[] }> = this.select(state => ({
    showFavorites: state.showFavorites,
    showHidden: state.showHidden,
    favoriteProcedures: state.favoriteProcedures,
    hiddenProcedures: state.hiddenProcedures,
  }));

  private keycloak = inject(KeycloakService);
  private procedureControllerService = inject(ProcedureControllerService);
  private userSettingsService = inject(UserSettingsService);
  private bekanntmachungenVerwaltungService = inject(BekanntmachungenVerwaltungService);
  private noticeControllerService = inject(NoticeControllerService);
  private refreshNoticeLockService = inject(RefreshNoticeLockService);

  constructor() {
    super(initialState);
  }

  init(): void {
    this.setState(initialState);

    this.doWithLoading(
      from(this.keycloak.loadUserProfile()).pipe(
        tap(loggedInUser => this.setState({ loggedInUser, selectedUserId: loggedInUser.id })),
        switchMap(loggedInUser => forkJoin({
          procedures: this.procedureControllerService.getProceduresOverview(loggedInUser.id),
          settings: this.userSettingsService.getUserSettings()
        })),
        map(({ procedures, settings }) => ({ procedures, settings: this.mergeFavoriteAndHiddenInfo(procedures, settings)})),
        tap(({ procedures, settings }) => this.setState({ procedures, ...settings })),
      )
    ).pipe(
      switchMap(({ procedures, settings }) => this.migrateFavoriteAndHiddenProcedures(procedures)),
    ).subscribe();
  }

  /**
   * Kombiniert die Informationen ueber favorisierte und ausgeblendete Verfahren aus den Verfahren selbst und bereits in den UserSettings gespeicherte Infos.
   *
   * TODO: Dieser Schritt entfaellt, sobald entschieden wird, den Migrationszeitraum zu beenden.
   */
  private mergeFavoriteAndHiddenInfo(procedures: ProcedureOverviewDto[], settings: UserSettings): UserSettings {
    const favoriteProcedures = [...new Set([...settings.favoriteProcedures, ...procedures.filter(p => p.favorite).map(p => p.id)])];
    const hiddenProcedures = [...new Set([...settings.hiddenProcedures, ...procedures.filter(p => !p.visible).map(p => p.id)])];
    return { ...settings, favoriteProcedures, hiddenProcedures };
  }

  /**
   * Updatet alle Verfahren, die bisher als Favorit oder Ausgeblendet gespeichert waren. Setzt fuer diese Verfahren favorite: false und visible: true, damit diese bei darauffolgenden
   * Malen nicht erneut geupdatet werden.
   * Falls es Verfahren gibt, die als Favorit/Ausgeblendet markiert waren, werden die UserSettings gespeichert, um die entsprechenden ProcedureIds in der neuen Struktur zu persistieren.
   *
   * TODO: Dieser Schritt entfaellt, sobald entschieden wird, den Migrationszeitraum zu beenden.
   */
  private migrateFavoriteAndHiddenProcedures(procedures: ProcedureOverviewDto[]): Observable<void[]> {
    const proceduresToUpdate = procedures
      .filter(procedure => procedure.favorite || !procedure.visible)
      .map(procedure => ({ ...procedure, favorite: false, visible: true }));
    return from(proceduresToUpdate).pipe(
      mergeMap(procedure => this.procedureControllerService.updateProcedure(this.mapToProcedureDto(procedure)), 3),
      catchError(err => {
        console.error('Die Änderung an der Verfahrens-Sichtbarkeits-Einstellung konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Netzwerkverbindung!');
        return of(null);
      }),
      toArray(),
      filter(updatedProcedures => updatedProcedures.length > 0),
      tap(() => this.saveSettings())
    );
  }

  selectUser(selectedUserId: string): void {
    this.setState({ selectedUserId });
    this.doWithLoading(
      this.procedureControllerService.getProceduresOverview(selectedUserId)
    ).subscribe(procedures => this.setState({ procedures }));
  }

  updateFavoriteFilter(checked: boolean): void {
    this.setState({ showFavorites: checked });
  }

  updateHiddenFilter(checked: boolean): void {
    this.setState({ showHidden: checked });
  }

  updatePageSize(size: number): void {
    if (size !== this.state.maxProceduresPerPage) {
      this.setState({ maxProceduresPerPage: size });
      this.saveSettings();
    }
  }

  updateFavoriteProcedures(procedureId: number): void {
    this.setState({ favoriteProcedures: this.toggleProcedureId(this.state.favoriteProcedures, procedureId) });
    this.saveSettings();
  }

  updateHiddenProcedures(procedureId: number): void {
    this.setState({ hiddenProcedures: this.toggleProcedureId(this.state.hiddenProcedures, procedureId) });
    this.saveSettings();
  }

  handleNoticeAction(actionType: string, rsNoticeId: number): void {
    switch (actionType) {
      case 'DELETE_DRAFT':
        const procedureOfDraft = this.state.procedures.find(procedure => procedure.notices.find(notice => notice.rsNoticeId === rsNoticeId));
        const cleanFavoritesAndHiddenCall$ = procedureOfDraft?.notices?.length === 1 ? this.userSettingsService.removeProcedureFromFavoriteAndHiddenLists(procedureOfDraft.id) : of(null);

        this.doWithLoading(
          cleanFavoritesAndHiddenCall$.pipe(
            switchMap(() => this.noticeControllerService.deleteNotice(rsNoticeId)),
            switchMap(() => this.procedureControllerService.getProceduresOverview(this.state.selectedUserId)),
            tap((procedures: ProcedureOverviewDto[]) => this.setState({ procedures })),
            catchError(err => {
              if (err.error.errorType === ErrorType.NOTICE_LOCKED) {
                return this.refreshNoticeLockService.showNoticeLockedErrorDialog('Löschen aktuell nicht möglich');
              }
              return throwError(() => err);
            }),
          )
        ).subscribe();
        break;
      case 'STOP':
        this.doWithLoading(
          this.noticeControllerService.stopNotice(rsNoticeId).pipe(
            switchMap(() => this.procedureControllerService.getProceduresOverview(this.state.selectedUserId)),
            tap((procedures: ProcedureOverviewDto[]) => this.setState({ procedures })),
          )
        ).subscribe();
        break;
      case 'SHOW_PREVIEW':
        this.bekanntmachungenVerwaltungService.getPreview(rsNoticeId).subscribe();
        break;
      case 'EXPORT_CN':
        this.bekanntmachungenVerwaltungService.exportNotice(rsNoticeId).subscribe();
        break;
    }
  }

  private doWithLoading<T>(action$: Observable<T>): Observable<T> {
    this.setState({ loading: true });
    return action$.pipe(
      finalize(() => this.setState({ loading: false })),
    );
  }

  private toggleProcedureId(list: number[], id: number): number[] {
    return list.includes(id) ? [...list.filter(item => item !== id)] : [...list, id];
  }

  private saveSettings(): void {
    const { loggedInUser, maxProceduresPerPage, favoriteProcedures, hiddenProcedures} = this.state;
    from(this.userSettingsService.createOrUpdateUserSettings({
      userId: loggedInUser.id,
      maxProceduresPerPage,
      favoriteProcedures,
      hiddenProcedures
    })).subscribe();
  }

  private mapToProcedureDto(procedure: ProcedureOverviewDto): ProcedureDto {
    return {
      id: procedure.id,
      procedureId: procedure.procedureId,
      legalBasis: procedure.legalBasis,
      favorite: procedure.favorite,
      visible: procedure.visible,
    };
  }

}
