import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { FieldModel } from '../../../view-model/field-model';

@Component({
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent extends AbstractFormComponent implements OnInit {
  inputControl = new FormControl();
  maxLength: number;
  model: FieldModel<string>;

  ngOnInit(): void {
    if (this.model.value) {
      this.inputControl.setValue(this.model.value);
    }

    this.maxLength = +this.model.fieldInfo.maxLength;

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: false });
    }
  }
}
