<header>
  <div class="left">
    <img
      *ngIf="isEVergabe"
      class="evergabe-logo"
      src="../../../../assets/img/evergabe_druck.svg"
      alt="eVergabe-Logo" />
  </div>

  <div id="panel-languages-container" class="middle">
    <ng-container *ngIf="!isUpdateMetaData && isEVergabe">
      <label class="language-label">Erfassungssprachen:</label>
      <input
        class="form-input-checkbox"
        type="checkbox"
        id="checkbox-deu"
        [formControl]="controlGerman"
        aria-label="Erfassungssprache Deutsch"
        checked />
      <label for="checkbox-deu">Deutsch</label>

      <input
        class="form-input-checkbox"
        type="checkbox"
        id="checkbox-eng"
        [formControl]="controlEnglish"
        aria-label="Erfassungssprache Englisch" />
      <label for="checkbox-eng">Englisch</label>
    </ng-container>
  </div>

  <app-toolbar class="right" [isTrainingMode]="isTrainingMode"></app-toolbar>
</header>
