import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from '../../../components/layout/dialog/dialog.component';
import { GroupModel } from '../../api/user-service';

@Component({
  selector: 'app-edit-group-dialog',
  templateUrl: './edit-group-dialog.component.html',
  styleUrls: ['./edit-group-dialog.component.scss'],
})
export class EditGroupDialogComponent {
  nameMaxLength = 100;

  @Output() confirm = new EventEmitter<GroupModel>();

  @ViewChild(DialogComponent) gruppenNameDialog: DialogComponent;

  gruppenInputForm: FormGroup;

  private gruppe: GroupModel;

  openDialog(gruppe?: GroupModel) {
    this.gruppe = gruppe;
    this.gruppenInputForm = new FormGroup({
      name: new FormControl(gruppe ? gruppe.name : null, [
        Validators.required,
        Validators.maxLength(this.nameMaxLength),
      ]),
    });
    this.gruppenNameDialog.openDialog();
    this.validateIfConfirmButtonIsEnabled();
  }

  onClickWeiter() {
    if (this.gruppenInputForm.valid) {
      this.confirm.emit({
        ...this.gruppe,
        ...this.gruppenInputForm.value,
      });
    }
  }

  validateIfConfirmButtonIsEnabled() {
    if (this.gruppenInputForm.valid) {
      this.gruppenNameDialog.enableAcceptButton();
    } else {
      this.gruppenNameDialog.disableAcceptButton();
    }
  }

  onDismiss() {
    this.gruppenInputForm.reset();
  }

  remainingNameCharacters(): number {
    if (
      this.gruppenInputForm.get('name').value === undefined ||
      this.gruppenInputForm.get('name').value === null
    ) {
      return this.nameMaxLength;
    }

    return this.nameMaxLength - this.gruppenInputForm.get('name').value.length;
  }
}
