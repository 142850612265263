import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StateService } from '../../services/state.service';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private stateService: StateService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent instanceof HttpResponse) {
          if (httpEvent.headers.has('x-notice-last-edited')) {
            const lastEditedHeader = httpEvent.headers.get('x-notice-last-edited');
            const [rsNoticeId, lastEditedValue] = lastEditedHeader.split('=');
            this.stateService.addNoticeLastEdited(+rsNoticeId, lastEditedValue);
          }
        }
      })
    );
  }
}
