import { ModelValidator } from '../ModelValidator';
import { ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';
import { MultilingualModel } from '../../../../view-model/type/multilingual-model';
import { MeasureModel } from '../../../../view-model/type/measure-model';
import { AmountModel } from '../../../../view-model/type/amount-model';

export class MaxLengthValidator extends ModelValidator<FieldModel<string> | FieldModel<number> | AmountModel | MeasureModel> {
  constructor() {
    super();
  }

  validate(fieldModel: FieldModel<string> | FieldModel<number> | AmountModel | MeasureModel): Promise<ValidationNotification[]> {
    const validationMessage = `Die maximale Länge von ${fieldModel.fieldInfo.maxLength} Zeichen wurde überschritten.`;

    if (fieldModel instanceof AmountModel || fieldModel instanceof MeasureModel) {
      if (fieldModel.value?.amount.length > +fieldModel.fieldInfo.maxLength) {
        return this.ofSingle(validationMessage);
      }
    } else {
      if (typeof fieldModel.value === "number" && fieldModel.value?.toString().length > +fieldModel.fieldInfo.maxLength) {
        return this.ofSingle(validationMessage);
      } else if (typeof fieldModel.value === "string" && fieldModel.value?.length > +fieldModel.fieldInfo.maxLength) {
        return this.ofSingle(validationMessage);
      }
    }
    return this.empty();
  }

  shouldAttach(model: FieldModel<string> | FieldModel<number> | AmountModel | MeasureModel): boolean {
    return !(model instanceof MultilingualModel) && !!model.fieldInfo?.maxLength;
  }
}
