import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-nutzerkonto',
  templateUrl: './nutzerkonto.component.html',
  styleUrls: ['./nutzerkonto.component.scss'],
})
export class NutzerkontoComponent {
  iframeUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${window.location.protocol}//${window.location.hostname}/auth/realms/redaktionssystem/account/`
    );
  }
}
