import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  Optional,
  Renderer2,
} from '@angular/core';
import { formatNumber, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appInputNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberDirective),
      multi: true,
    },
  ],
})
export class InputNumberDirective implements ControlValueAccessor {
  @Input() maxLength: string;
  @Input() @Optional() fractionDigits = 0;
  @Input() @Optional() signed = false;

  private _onChange: (val: string) => void;
  private _onTouched: () => void;

  private _viewValue = '';
  private _modelValue = '';

  private groupSymbolsOfInput = 0;
  private replacedInvalidCharsOfInput = 0;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    if (value === '') {
      this.propagateToModel('');
      return;
    }

    const filteredInputValue: string = this.filterInvalidInputChars(value);

    this.propagateValue(filteredInputValue);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.maxLength) {
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

      if (allowedKeys.includes(event.key) || this._modelValue.length < Number(this.maxLength)) {
        return;
      }

      event.preventDefault();
    }
  }

  @HostListener('blur')
  onBlur() {
    this._onTouched();
    if (this._modelValue) {
      const formattedNumber = this.formatNumberToLocale(this._modelValue);
      this.setViewValue(formattedNumber);
    } else {
      this.setViewValue('');
    }
  }

  writeValue(value: any): void {
    this._modelValue = value === 0 || value ? String(value) : ''; // null/undefined -> empty string
    let viewValue = '';
    if (this._modelValue) {
      viewValue = this.formatNumberToLocale(this._modelValue);
    }

    this.setViewValue(viewValue);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  private filterInvalidInputChars(value: string): string {
    const localeDecimalSymbol = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);

    // clear and count group chars
    this.groupSymbolsOfInput = 0;
    const valueWithoutGroupSymbols = value.replace(this.getLocalGroupSymbolRegExp(), c => {
      this.groupSymbolsOfInput++;
      return '';
    });

    // filter all but numbers and decimal operator and sign
    const invalidCharsRegExp = new RegExp(
      `[^\\d${this.fractionDigits ? '\\' + localeDecimalSymbol : ''}${this.signed ? '-' : ''}]`,
      'g'
    );
    this.replacedInvalidCharsOfInput = 0;
    return valueWithoutGroupSymbols.replace(invalidCharsRegExp, x => {
      this.replacedInvalidCharsOfInput++;
      return '';
    });
  }

  private propagateValue(value: string) {
    if (value === '') {
      this.resetViewValue();
      return;
    }

    if (value === '-') {
      this.propagateToModel('');
      return;
    }

    const enFormattedValue = value.replace(
      getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal),
      '.'
    );
    const number = Number(enFormattedValue);
    if (isNaN(number)) {
      this.resetViewValue();
      return;
    }

    const viewValueNew = this.createViewValue(number, value);
    const groupSymbolOffset = this.determineGroupSymbolOffset(viewValueNew);
    this.propagateToView(viewValueNew, groupSymbolOffset);

    const modelValueNew = this.createModelValue(number, enFormattedValue);
    this.propagateToModel(modelValueNew);
  }

  private determineGroupSymbolOffset(viewValueNew: string) {
    let groupSymbolOffset = 0;
    if (this._viewValue !== viewValueNew) {
      const newGroupSymbols = viewValueNew.match(this.getLocalGroupSymbolRegExp())?.length;
      groupSymbolOffset = (newGroupSymbols ? newGroupSymbols : 0) - this.groupSymbolsOfInput;
    }
    return groupSymbolOffset;
  }

  private getLocalGroupSymbolRegExp() {
    return new RegExp('\\' + getLocaleNumberSymbol(this.locale, NumberSymbol.Group), 'g');
  }

  private createViewValue(number: number, rawValue: string) {
    let negative = false;
    if (rawValue.startsWith('-')) {
      negative = true;
    }

    const integerPart = Math.trunc(number).toString();
    let formattedIntegerPartToLocale = this.formatNumberToLocale(integerPart);

    if (negative && !formattedIntegerPartToLocale.startsWith('-')) {
      formattedIntegerPartToLocale = '-' + formattedIntegerPartToLocale;
    }

    const truncatedDecimalPartWithSeparator = this.getTruncatedDecimalPartWithSeparator(
      rawValue,
      getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal)
    );

    return formattedIntegerPartToLocale + truncatedDecimalPartWithSeparator;
  }

  private propagateToView(viewValue: string, groupSymbolOffset: number) {
    const start = this.elementRef.nativeElement?.selectionStart;
    const end = this.elementRef.nativeElement?.selectionEnd;

    this.setViewValue(viewValue);

    this.updateCursor(start, end, groupSymbolOffset);
  }

  private setViewValue(viewValue: string) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', viewValue);
    this._viewValue = viewValue;
  }

  private updateCursor(start, end, groupSymbolOffset: number) {
    const startOffset = start + groupSymbolOffset - this.replacedInvalidCharsOfInput;
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'selectionStart',
      startOffset > 0 ? startOffset : 0
    );
    const endOffset = end + groupSymbolOffset - this.replacedInvalidCharsOfInput;
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'selectionEnd',
      endOffset > 0 ? endOffset : 0
    );
  }

  private resetViewValue() {
    if (this._modelValue) {
      const formattedValue = this.formatNumberToLocale(this._modelValue);
      this.propagateToView(formattedValue, 0);
    } else {
      this.propagateToView('', 0);
    }
  }

  private formatNumberToLocale(number: string) {
    return formatNumber(+number, this.locale, `1.0-${this.fractionDigits}`);
  }

  private createModelValue(number: number, formattedValue: string) {
    let integerPart = Math.trunc(number).toString();
    if (formattedValue.startsWith('-')) {
      if (number === 0) {
        integerPart = '0';
      } else if (integerPart === '0') {
        integerPart = '-0';
      }
    }

    const truncatedDecimalPartWithSeparator = this.getTruncatedDecimalPartWithSeparator(
      number.toString(),
      '.'
    );

    return integerPart + truncatedDecimalPartWithSeparator;
  }

  private propagateToModel(modelValue: string) {
    if (this._modelValue !== modelValue) {
      this._modelValue = modelValue;
      const newModelValue = modelValue ? modelValue : null; // empty string -> null
      this._onChange(newModelValue);
    }
  }

  private getTruncatedDecimalPartWithSeparator(value: string, separator: string) {
    const commaPosition = value.indexOf(separator);
    return commaPosition >= 0
      ? value.substring(commaPosition, commaPosition + this.fractionDigits + 1)
      : '';
  }
}
