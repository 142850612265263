import { ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';
import { ModelValidator } from '../ModelValidator';
import { BusinessRuleValidationService } from '../../business-rule-validation.service';
import { ConceptModel } from '../../../../types/concept-node';

export class BusinessRuleValidator extends ModelValidator<FieldModel<any>> {
  constructor(private businessRuleValidationService: BusinessRuleValidationService) {
    super();
  }

  async validate(
    fieldModel: FieldModel<any>,
    conceptModel?: ConceptModel
  ): Promise<ValidationNotification[]> {
    const businessRuleResult = await this.businessRuleValidationService.validate(
      fieldModel,
      conceptModel
    );
    fieldModel.businessRulesResult = businessRuleResult;

    return businessRuleResult
      .filter(brr => brr.validationMessage)
      .map(brr => new ValidationNotification(brr.validationMessage, brr.severity));
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model.fieldInfo?.assert?.constraints?.length > 0;
  }
}
