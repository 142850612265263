import { Injectable, ViewContainerRef } from '@angular/core';
import { AbstractFormComponent } from '../components/form-components/abstract-form/abstract-form.component';
import { FieldTypeService } from './field-type.service';
import { SectionModel } from '../view-model/section-model';
import { GroupModel } from '../view-model/group-model';
import { RepeatableGroupModel } from '../view-model/repeatable-group-model';
import { RepeatableFieldModel } from '../view-model/repeatable-field-model';
import { FieldModel } from '../view-model/field-model';
import { TimeModel } from '../view-model/type/time-model';

@Injectable()
export class DynamicComponentFactoryService {
  constructor(private fieldTypeService: FieldTypeService) {}

  public generateComponents(
    viewContainerRef: ViewContainerRef,
    containerModel: SectionModel | GroupModel | RepeatableGroupModel | RepeatableFieldModel<any>
  ): AbstractFormComponent[] {
    return containerModel.children
      .filter(model => !(model instanceof TimeModel && model.relatedDateModel))
      .map(model => this.generateComponent(viewContainerRef, model));
  }

  public generateComponent(
    viewContainerRef: ViewContainerRef,
    baseModel:
      | FieldModel<any>
      | RepeatableFieldModel<any>
      | GroupModel
      | RepeatableGroupModel
      | SectionModel
  ): AbstractFormComponent {
    const componentType = this.fieldTypeService.getComponentForNoticeNode(
      baseModel.noticeNode,
      baseModel.isInsideRepeatable
    );
    if (!componentType) {
      return;
    }
    const component = viewContainerRef.createComponent(componentType)
      .instance as AbstractFormComponent;

    component.initWithModel(baseModel);

    return component;
  }
}
