import { Directive, EventEmitter, HostListener, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimAndMapEmptyToNull]',
})
export class TrimAndMapEmptyToNullDirective {
  @Output() private _ngModelChange = new EventEmitter();

  /**
   * @param _ngControl
   *   A reference to the ngControl object instance of the host, if present.
   */
  constructor(@Optional() private _ngControl?: NgControl) {}

  get ngModelChange(): EventEmitter<any> {
    return this._ngModelChange;
  }

  /**
   * Handles the trimming of the input value of the host element on blur.
   */
  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    if (typeof value.trim === 'function') {
      const trimmedValue = value.trim();
      this.updateFormControl(trimmedValue, value);
      this.propagateChange(trimmedValue, value);
    }
  }

  private updateFormControl(trimmedValue: string, value: string) {
    if (trimmedValue === '') {
      this._ngControl?.control.setValue(null);
    } else if (trimmedValue !== value) {
      this._ngControl?.control.setValue(trimmedValue);
    }
  }

  private propagateChange(trimmedValue: string, value: string) {
    if (trimmedValue !== value) {
      this._ngModelChange.emit(trimmedValue);
    }
  }
}
