import { FieldModel } from '../../view-model/field-model';
import { ModelListener } from './ModelListener';
import { Bt300BrDeKmuModel } from '../../view-model/type/bt300-br-de-kmu-model';
import { ModelStateService } from '../view-model/model-state.service';
import { GroupModel } from '../../view-model/group-model';
import { SectionModel } from '../../view-model/section-model';
import { IndicatorModel } from '../../view-model/type/indicator-model';

/**
 * Listens for KMU Changes and Updates the BT-300-Lot/BT-300-Part specific model accordingly.
 * See {@link BT300BR26DeKmuSelectionComponent}
 */
export class BT300BrDe26KmuChangedListener extends ModelListener<Bt300BrDeKmuModel> {
  constructor(private btIdSuffix: 'Part' | 'Lot') {
    super();
  }

  onChange(model: FieldModel<any>): void {
    const searchContext = (model.parent as GroupModel).parent as SectionModel;
    const kmuSelectorModel = ModelStateService.findModelById<IndicatorModel>(
      searchContext,
      `BT-726-${this.btIdSuffix}`
    );

    const bt300KmuModel = ModelStateService.findModelById<Bt300BrDeKmuModel>(
      searchContext,
      `BT-300-${this.btIdSuffix}`
    );

    bt300KmuModel.isKmuSelectionEnabled = kmuSelectorModel?.value === true;

    if (bt300KmuModel.isKmuSelectionEnabled) {
      bt300KmuModel.value = {
        ENG: this.formatKmuValueToRequiredFirstLineString(
          bt300KmuModel.kmuValues,
          bt300KmuModel.englishValue
        ),
        DEU: this.formatKmuValueToRequiredFirstLineString(
          bt300KmuModel.kmuValues,
          bt300KmuModel.germanValue
        ),
      };
    } else {
      bt300KmuModel.value = { ENG: bt300KmuModel.englishValue, DEU: bt300KmuModel.germanValue };
    }

    bt300KmuModel.emitChange();
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return (
      model.noticeNode?.id === `BT-300-${this.btIdSuffix}` ||
      model.noticeNode?.id === `BT-726-${this.btIdSuffix}`
    );
  }

  private formatKmuValueToRequiredFirstLineString(kmuValues: string[], other: string) {
    if (kmuValues.length === 0) {
      return other;
    }

    const kmuValueString = kmuValues
      .map(kmuValue => `#Besonders auch geeignet für:${kmuValue ?? ''}#`)
      .join(',');
    return `${kmuValueString}${other ? '\n' + other : ''}`;
  }
}
