<div [hidden]="model.isHidden" class="item-container">
  <div class="input-container">
    <ng-template view></ng-template>
  </div>
  <div class="button-container">
    <div *ngIf="!model.isReadonly" class="repeatable-control">
      <button
        mat-mini-fab
        (click)="delete()"
        (keydown.space)="delete()"
        (keydown.enter)="delete()"
        *ngIf="!isLastItem"
        class="repeatable-control eForm-icon-button danger"
        matTooltip="Element entfernen"
        aria-label="Element entfernen">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-mini-fab
        (click)="add()"
        (keydown.space)="add()"
        (keydown.enter)="add()"
        *ngIf="showAddButton"
        class="repeatable-control eForm-icon-button normal"
        matTooltip="Element hinzufügen"
        aria-label="Element hinzufügen">
        <mat-icon>+</mat-icon>
      </button>
    </div>
  </div>
</div>
