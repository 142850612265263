import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteDataService } from '../../shared/route-data.service';

@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
})
export class PortalFooterComponent {

  useLiteFooter$: Observable<boolean> = inject(RouteDataService).routeData$.pipe(
    map((data: { enableErfassungsmaskeMode?: boolean }) => !!data.enableErfassungsmaskeMode)
  );

}
