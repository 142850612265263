import { Component, inject, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  ResolveStart,
  Router,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { LoadingSpinnerService } from './portal/shared/loading-spinner/loading-spinner.service';
import { RouteDataService } from './portal/shared/route-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading$: Observable<boolean>;

  enableErfassungsmaskeMode$: Observable<boolean> = inject(RouteDataService).routeData$.pipe(
    map((data: { enableErfassungsmaskeMode?: boolean }) => !!data.enableErfassungsmaskeMode)
  );

  constructor(private router: Router, private loadingSpinnerService: LoadingSpinnerService) {}

  ngOnInit(): void {
    const showLoaderEvents$ = this.router.events.pipe(
      filter(e => e instanceof ResolveStart),
      map(() => true)
    );

    const hideLoaderEvents$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => false)
    );

    this.router.events
      .pipe(filter(e => e instanceof NavigationCancel || e instanceof NavigationError))
      .subscribe(error => {
        console.error(error);
      });

    this.isLoading$ = merge(
      hideLoaderEvents$,
      showLoaderEvents$,
      this.loadingSpinnerService.isLoading$()
    );
  }
}
