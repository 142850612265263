import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading$(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  start(): void {
    this.loading$.next(true);
  }

  stop(): void {
    this.loading$.next(false);
  }
}
