import { AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KeycloakProfile } from 'keycloak-js';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProcedureOverviewDto } from '../../api/notices';
import { GroupModel } from '../../api/user-service';
import { GruppenService } from '../../gruppen/gruppen.service';
import { BekanntmachungenVerwaltungStateService } from './bekanntmachungen-verwaltung-state.service';

@Component({
  selector: 'app-bekanntmachungen-verwaltung',
  templateUrl: './bekanntmachungen-verwaltung.component.html',
  styleUrls: ['./bekanntmachungen-verwaltung.component.scss'],
})
export class BekanntmachungenVerwaltungComponent implements OnInit, AfterViewInit, OnDestroy {

  loading$: Observable<boolean>;
  dropdownData$: Observable<{ loggedInUser: KeycloakProfile; groups: GroupModel[] }>;
  groups$: Observable<GroupModel[]>;
  loggedInUser$: Observable<KeycloakProfile>;
  filter$: Observable<{ showFavorites: boolean; showHidden: boolean; favoriteProcedures: number[]; hiddenProcedures: number[] }>;

  isFirstPage: boolean = true;
  pageSizeOptions: number[] = [10, 25, 50];

  dataSource = new MatTableDataSource<ProcedureOverviewDto>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private state = inject(BekanntmachungenVerwaltungStateService);
  private gruppenService = inject(GruppenService);
  private cdr = inject(ChangeDetectorRef);

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.loading$ = this.state.loading$;
    this.groups$ = this.gruppenService.groups$;
    this.loggedInUser$ = this.state.loggedInUser$;
    this.filter$ = this.state.filter$;
    this.dropdownData$ = combineLatest({loggedInUser: this.loggedInUser$, groups: this.groups$}).pipe(filter(res => !!res.loggedInUser));
    this.state.procedures$.pipe(takeUntil(this.destroy$)).subscribe(procedures => this.dataSource.data = procedures);

    // take actions when specific properties change
    this.filter$.pipe(takeUntil(this.destroy$)).subscribe(filter => {
      this.dataSource.filterPredicate = (procedure) =>
        (!filter.showFavorites || filter.favoriteProcedures.includes(procedure.id)) &&
        (filter.showHidden || !filter.hiddenProcedures.includes(procedure.id));
      this.dataSource.filter = 'dummy';
    });

    // trigger loading everything
    this.state.init();
    this.gruppenService.loadGroupsAndInvitations();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.state.maxProceduresPerPage$.pipe(
      filter(() => !!this.paginator),
      takeUntil(this.destroy$),
    ).subscribe(pageSize => {
      this.paginator._changePageSize(pageSize);
      this.paginator.firstPage();
      this.cdr.detectChanges();
    });
  }

  userFilterChanged(userId: string): void {
    this.state.selectUser(userId);
  }

  favoritesFilterChanged(event: MatCheckboxChange): void {
    this.state.updateFavoriteFilter(event.checked);
  }

  hiddenFilterChanged(event: MatCheckboxChange): void {
    this.state.updateHiddenFilter(event.checked);
  }

  toggleFavorite($event: MouseEvent, procedure: ProcedureOverviewDto): void {
    $event.stopPropagation();
    this.state.updateFavoriteProcedures(procedure.id);
  }

  toggleHidden($event: MouseEvent, procedure: ProcedureOverviewDto): void {
    $event.stopPropagation();
    this.state.updateHiddenProcedures(procedure.id);
  }

  onPageEvent(event: PageEvent) {
    this.state.updatePageSize(event.pageSize);
    this.isFirstPage = event.pageIndex === 0;

    window.scrollTo(0, 0);
    // If scrolling didn't work (because of changes to the DOM, try again in 100ms)
    setTimeout(() => {
      if (window.screenY < 100) window.scrollTo(0, 0);
    }, 100);
  }

  onTriggerAction(payload: { actionType: string, rsNoticeId: number; }): void {
    this.state.handleNoticeAction(payload.actionType, payload.rsNoticeId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
