import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ComponentDirective } from '../../../component.directive';
import { FieldModel } from '../../../../view-model/field-model';
import { DynamicComponentFactoryService } from '../../../../services/dynamic-component-factory.service';

@Component({
  selector: 'app-repeatable-field',
  templateUrl: './repeatable-field.component.html',
  styleUrls: ['./repeatable-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatableFieldComponent implements OnInit {
  @ViewChild(ComponentDirective, { static: true }) public view: ComponentDirective;

  @Output() addComponent = new EventEmitter<void>();
  @Output() deleteComponent = new EventEmitter<void>();
  @Input() model: FieldModel<any>;
  @Input() showAddButton: boolean;
  @Input() isLastItem: boolean;

  constructor(private dynamicComponentFactoryService: DynamicComponentFactoryService) {}

  ngOnInit(): void {
    this.dynamicComponentFactoryService.generateComponent(this.view.viewContainerRef, this.model);
  }

  add(): void {
    this.addComponent.emit();
  }

  delete(): void {
    this.deleteComponent.emit();
  }
}
