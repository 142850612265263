import { GroupMemberModel, GroupMemberRole, GroupModel } from '../api/user-service';

export namespace GruppenUtils {

  export function findInGroup(group: GroupModel, userId: string): GroupMemberModel {
    return group.members.find(member => member.userId === userId);
  }

  export function isInGroup(group: GroupModel, userId: string): boolean {
    return !!findInGroup(group, userId);
  }

  export function isOwner(group: GroupModel, userId: string): boolean {
    return findOwner(group)?.userId === userId;
  }

  export function findOwner(group: GroupModel): GroupMemberModel {
    return group.members.find(member => member.role === GroupMemberRole.Owner);
  }

  export function findGroupsWithInvitation(groups: GroupModel[], userId: string): GroupModel[] {
    return groups.filter(group => findInGroup(group, userId)?.role === GroupMemberRole.Invited);
  }

  export function findGroupsWithMembership(groups: GroupModel[], userId: string): GroupModel[] {
    return groups.filter(group => findInGroup(group, userId)?.role !== GroupMemberRole.Invited);
  }

  export function filterByGroupnameOrUsernames(groups: GroupModel[], searchTerm: string): GroupModel[] {
    return groups.filter(group =>
      group.name.toLowerCase().includes(searchTerm)
      || group.members.some(member => member.username.toLowerCase().includes(searchTerm))
    );
  }

}
