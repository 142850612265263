import { Component } from '@angular/core';
import { NewsDto } from '../../../api/notices';
import { Observable } from 'rxjs';
import { NewsService } from '../../../../services/news.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-redaktionssystem',
  templateUrl: './redaktionssystem.component.html',
  styleUrls: ['./redaktionssystem.component.scss'],
})
export class RedaktionssystemComponent {
  news$: Observable<NewsDto[]>;

  constructor(private newsService: NewsService) {
    this.news$ = this.newsService.getLatestLandingpageNewsList();
    this.newsService.updateNews();
  }
}
