import { Injectable } from '@angular/core';
import { SectionModel } from '../../view-model/section-model';
import { ComponentModel } from '../../view-model/component-model';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';
import { BaseModel, ModelWithChildren } from '../../view-model/base-model';
import { GroupModel } from '../../view-model/group-model';
import { RepeatableGroupModel } from '../../view-model/repeatable-group-model';
import { RepeatableFieldModel } from '../../view-model/repeatable-field-model';
import { FieldModel } from '../../view-model/field-model';

@Injectable()
export class ModelStateService {
  private _componentModel: ComponentModel;

  public initComponentModel(componentModel: ComponentModel): void {
    this._componentModel = componentModel;
  }

  /**
   * Returns the first model in the subtree, which has the given id set in its notice node.
   *
   * @param searchRoot root model to be searched in
   * @param id of the target model
   */
  public static findModelById<T>(searchRoot: ModelWithChildren, id: string): T | null {
    for (const child of searchRoot.children) {
      if (child?.noticeNode?.id === id) {
        return child as T;
      }
      if (ModelStateService.hasChildren(child)) {
        const result = ModelStateService.findModelById(child as ModelWithChildren, id);
        if (result) {
          return result as T;
        }
      }
    }
    return null;
  }

  /**
   * Returns all models in the subtree, which have the given id as dependencyField.
   *
   * @param searchRoot root model to be searched in
   * @param id of the target dependencyField
   */
  public static findModelsByDependencyId(searchRoot: ModelWithChildren, id: string): BaseModel[] {
    const found = [];
    for (const child of searchRoot.children) {
      if (child?.fieldInfo?.dependencyFields?.includes(id) && child.noticeNode.id !== id) {
        found.push(child);
      }
      if (ModelStateService.hasChildren(child)) {
        found.push(...ModelStateService.findModelsByDependencyId(child as ModelWithChildren, id));
      }
    }
    return found;
  }

  /**
   * Returns all models in the subtree, which have at least on of the ids on the idSet in their dependencyFields array.
   *
   * @param searchRoot root model to be searched in
   * @param idSet id's which should be contained (at least one) inside the dependencyFields array of the target models
   */
  public static findFieldModelsByDependencyIds(
    searchRoot: ModelWithChildren,
    idSet: Set<string>
  ): FieldModel<any>[] {
    const found = [];
    for (const child of searchRoot.children) {
      if (child?.fieldInfo?.dependencyFields?.some(id => idSet.has(id))) {
        found.push(child);
      }
      if (ModelStateService.hasChildren(child)) {
        found.push(
          ...ModelStateService.findFieldModelsByDependencyIds(child as ModelWithChildren, idSet)
        );
      }
    }
    return found;
  }

  /**
   * Returns all models in the subtree, which have the given id set in its notice node.
   *
   * @param searchRoot root model to be searched in
   * @param id of the target model
   */
  public static findModelsById(searchRoot: ModelWithChildren, id: string): BaseModel[] {
    const found = [];
    for (const child of searchRoot.children) {
      if (child?.noticeNode?.id === id) {
        found.push(child);
      }
      if (ModelStateService.hasChildren(child)) {
        found.push(...ModelStateService.findModelsById(child as ModelWithChildren, id));
      }
    }
    return found;
  }

  /**
   * Returns all models in the subtree, which id is contained in the ids set.
   *
   * @param searchRoot root model to be searched in
   * @param ids of all models to be searched for
   */
  public static findModelsByIds(searchRoot: ModelWithChildren, ids: Set<string>): BaseModel[] {
    const found = [];
    for (const child of searchRoot.children) {
      if (ids.has(child?.noticeNode?.id)) {
        found.push(child);
      }
      if (ModelStateService.hasChildren(child)) {
        found.push(...ModelStateService.findModelsByIds(child as ModelWithChildren, ids));
      }
    }
    return found;
  }

  public static hasChildren(baseModel: BaseModel | ComponentModel): boolean {
    return (
      baseModel instanceof SectionModel ||
      baseModel instanceof GroupModel ||
      baseModel instanceof ComponentModel ||
      baseModel instanceof RepeatableSectionModel ||
      baseModel instanceof RepeatableGroupModel ||
      baseModel instanceof RepeatableFieldModel
    );
  }

  public getSectionModel(sectionId: string): SectionModel {
    return this.findMatchingSection(this._componentModel.children, sectionId);
  }

  private findMatchingSection(
    sectionModels: (SectionModel | RepeatableSectionModel)[],
    sectionId: string
  ): SectionModel | null {
    for (const sectionModel of sectionModels) {
      if (sectionModel instanceof RepeatableSectionModel) {
        return this.findMatchingSection(sectionModel.children, sectionId);
      }

      if (sectionModel.sectionId === sectionId) {
        return sectionModel;
      }
      const subMatch = this.findMatchingSection(
        sectionModel.children.filter(child => child instanceof SectionModel) as SectionModel[],
        sectionId
      );
      if (subMatch) {
        return subMatch;
      }
    }

    return null;
  }

  get componentModel(): ComponentModel {
    return this._componentModel;
  }
}
