import { Injectable } from '@angular/core';
import { ConceptModel } from '../types/concept-node';
import { EditorConfigurationProvider } from './editor-configuration.provider';
import { CommunicationService } from './communication.service';
import { EVergabeMetaData } from '../view-model/e-vergabe-meta-data';
import { FormType } from '../types/form-type';
import { GenericDialogService } from './ui/generic-dialog.service';
import { ModelChangeType } from '../types/model-changed-types';

@Injectable()
export class NoticeSubmittingService {
  constructor(
    private editorConfigurationProvider: EditorConfigurationProvider,
    private communicationService: CommunicationService,
    private genericDialogService: GenericDialogService
  ) {}

  /**
   * Submits the current notice to the Backend.
   *
   * @param conceptModel the ConcpetModel to send to the backend
   * @param metadata optional e-Vergabe specific MetaData. Null when not needed.
   * @param formType notice FormType
   * @param releasePriorInformation set to true, to also release prior information after creation (e-Vergabe)
   * @param changeType
   */
  public async submitNotice(
    conceptModel: ConceptModel,
    metadata: EVergabeMetaData,
    formType: FormType,
    releasePriorInformation: boolean,
    changeType: ModelChangeType
  ) {
    const existingProcedureId = this.editorConfigurationProvider.getTenderingProcedureId();
    if (
      this.editorConfigurationProvider.isUpdateMetaData() ||
      changeType === ModelChangeType.META_ONLY_CHANGE
    ) {
      return await this.updateMetaDataOnly(metadata, existingProcedureId);
    }

    if (this.editorConfigurationProvider.isResetProcedure()) {
      return await this.resetProcedure(conceptModel, metadata, existingProcedureId);
    }

    if (
      this.editorConfigurationProvider.isChangeNotice() ||
      this.editorConfigurationProvider.isAlterBeforeRelease() ||
      // ReSy: Pruefung auf AlterUpdateBeforeRelease
      this.editorConfigurationProvider.isAlterUpdateBeforeRelease()
    ) {
      return await this.sendChangeNotice(conceptModel, metadata, formType, existingProcedureId);
    }

    if (formType === FormType.EXPOST) {
      return await this.createExPost(
        conceptModel,
        metadata,
        formType,
        existingProcedureId,
        this.editorConfigurationProvider.isCancellation()
      );
    }

    if (formType === FormType.EXANTE) {
      return await this.createExAnte(conceptModel, metadata, formType, existingProcedureId);
    }

    const createdProcedureId = await this.createNotice(conceptModel, metadata, formType);
    if (createdProcedureId !== null) {
      if (formType === FormType.PRIOR_INFORMATION && releasePriorInformation) {
        await this.releasePriorInformation(createdProcedureId);
      } else {
        await this.showCreateSuccessDialogAndClose(formType, createdProcedureId);
      }
    }
  }

  private async updateMetaDataOnly(metadata: EVergabeMetaData, procedureId: number) {
    try {
      await this.communicationService.updateMetaData(procedureId, metadata);
      await this.showMetaUpdateSuccessDialogAndClose(procedureId);
    } catch (e) {
      await this.showMetaUpdateFailedDialog();
    }
  }

  private async sendChangeNotice(
    conceptModel: ConceptModel,
    metadata: EVergabeMetaData,
    formType: FormType,
    procedureId: number
  ) {
    try {
      if (this.editorConfigurationProvider.isRS()) {
        await this.communicationService.createChangeNotice(procedureId, conceptModel, metadata);
      } else {
        switch (formType) {
          case FormType.PRIOR_INFORMATION:
          case FormType.NOTICE:
            await this.communicationService.createChangeNotice(procedureId, conceptModel, metadata);
            break;
          case FormType.EXANTE:
            await this.communicationService.createChangeExAnte(procedureId, conceptModel, metadata);
            break;
          case FormType.EXPOST:
            await this.communicationService.createChangeExPost(procedureId, conceptModel, metadata);
            break;
          case FormType.CONTRACT_MODIFICATION:
            await this.showChangeNoticeCreateFailed();
        }
      }
      if (this.editorConfigurationProvider.isAlterBeforeRelease()) {
        await this.showUpdateSuccessAndClose(procedureId);
      } else {
        await this.showChangeNoticeCreateSuccessAndClose(procedureId);
      }
    } catch (e) {
      await this.showChangeNoticeCreateFailed();
    }
  }

  private async createExPost(
    conceptModel: ConceptModel,
    metadata: EVergabeMetaData,
    formType: FormType,
    procedureId: number,
    cancellation: boolean
  ) {
    try {
      await this.communicationService.createExPost(
        procedureId,
        conceptModel,
        metadata,
        cancellation
      );
      await this.showCreateSuccessDialogAndClose(formType, procedureId);
    } catch (e) {
      await this.showCreateFailedDialog(formType);
    }
  }

  private async releasePriorInformation(procedureId: number) {
    try {
      await this.communicationService.releasePriorInformation(procedureId);
      await this.showCreateSuccessDialogAndClose(FormType.PRIOR_INFORMATION, procedureId, true);
    } catch (e) {
      await this.showPriorInformationReleaseFailed();
      await this.communicationService.closeAndReplicate(procedureId);
    }
  }

  private async resetProcedure(
    conceptModel: ConceptModel,
    metadata: EVergabeMetaData,
    procedureId: number
  ) {
    try {
      await this.communicationService.createResetProcedureNotice(
        procedureId,
        conceptModel,
        metadata
      );
      await this.showResetSuccessDialogAndClose(procedureId);
    } catch (e) {
      await this.showResetFailedDialog();
    }
  }

  private async createExAnte(
    conceptModel: ConceptModel,
    metadata: EVergabeMetaData,
    formType: FormType,
    procedureId: number
  ) {
    try {
      await this.communicationService.createExAnte(procedureId, conceptModel, metadata);
      await this.showCreateSuccessDialogAndClose(formType, procedureId);
    } catch (e) {
      await this.showCreateFailedDialog(formType);
    }
  }

  private async createNotice(
    conceptModel: ConceptModel,
    metadata: EVergabeMetaData,
    formType: FormType
  ): Promise<number | null> {
    try {
      const result = await this.communicationService.createProcedure(conceptModel, metadata);
      return result.tenderingProcedureId;
    } catch (e) {
      await this.showCreateFailedDialog(formType);
      return null;
    }
  }

  private async showCreateSuccessDialogAndClose(
    formType: FormType,
    procedureId: number,
    andReleased = false
  ) {
    const content = `${
      formType === FormType.NOTICE ? 'Das' : 'Die'
    } ${formType} wurde erfolgreich angelegt${andReleased ? ' und freigegeben' : ''}.`;
    await this.genericDialogService.showResultDialog(
      `Anlage erfolgreich`,
      content,
      'Schließen',
      true
    );

    await this.communicationService.closeAndReplicate(procedureId);
  }

  private async showResetSuccessDialogAndClose(procedureId: number) {
    await this.genericDialogService.showResultDialog(
      `Anlage erfolgreich`,
      'Die Änderungsbekanntmachung für das Zurücksetzten des Verfahrens wurde erfolgreich erstellt.',
      'Schließen',
      true
    );

    await this.communicationService.closeAndReplicate(procedureId);
  }

  private async showResetFailedDialog() {
    await this.genericDialogService.showResultDialog(
      `Anlage fehlgeschlagen`,
      'Die Änderungsbekanntmachung für das Zurücksetzten des Verfahrens konnte nicht erstellt werden. Bitte versuchen Sie es erneut. Sollte der Fehler wiederholt auftreten, exportieren Sie die XML Datei und senden Sie diese mit Fehlerbeschreibung per E-Mail an den Support.',
      'Schließen',
      false
    );
  }

  private async showChangeNoticeCreateSuccessAndClose(tenderingProcedureId: number) {
    await this.genericDialogService.showResultDialog(
      `Anlage erfolgreich`,
      'Die Änderungsbekanntmachung wurde erfolgreich angelegt.',
      'Schließen',
      true
    );

    await this.communicationService.closeAndReplicate(tenderingProcedureId);
  }

  private async showUpdateSuccessAndClose(tenderingProcedureId: number) {
    await this.genericDialogService.showResultDialog(
      `Aktualisierung erfolgreich`,
      'Die Änderungen wurden erfolgreich gespeichert.',
      'Schließen',
      true
    );

    await this.communicationService.closeAndReplicate(tenderingProcedureId);
  }

  private async showChangeNoticeCreateFailed() {
    await this.genericDialogService.showResultDialog(
      `Anlage der Änderungsbekanntmachung fehlgeschlagen`,
      'Die Änderungsbekanntmachung konnte nicht angelegt werden. Bitte versuchen Sie es erneut. Sollte der Fehler wiederholt auftreten, exportieren Sie die XML Datei und senden Sie diese mit Fehlerbeschreibung per E-Mail an den Support.',
      'Bearbeitung fortsetzen',
      false
    );
  }

  private async showCreateFailedDialog(formType: FormType) {
    await this.genericDialogService.showResultDialog(
      `Anlage ${
        formType === FormType.NOTICE ? 'des ' + formType + 's' : 'der ' + formType
      } fehlgeschlagen`,
      `${
        formType === FormType.NOTICE ? 'Das ' + formType : 'Die ' + formType
      } konnte nicht angelegt werden. Bitte versuchen Sie es erneut. Sollte der Fehler wiederholt auftreten, exportieren Sie die XML Datei und senden Sie diese mit Fehlerbeschreibung per E-Mail an den Support.`,
      'Bearbeitung fortsetzen',
      false
    );
  }

  private async showPriorInformationReleaseFailed() {
    await this.genericDialogService.showResultDialog(
      `Freigabe der Vorinformation fehlgeschlagen`,
      `Die Vorinformation wurde angelegt, konnte aber im Anschluss nicht freigegeben werden.`,
      'Schließen',
      false
    );
  }

  private async showMetaUpdateSuccessDialogAndClose(procedureId: number) {
    await this.genericDialogService.showResultDialog(
      `Aktualisierung erfolgreich`,
      `Die e-Vergabe Metadaten wurden erfolgreich aktualisiert.`,
      'Schließen',
      true
    );

    await this.communicationService.closeAndReplicate(procedureId);
  }

  private async showMetaUpdateFailedDialog() {
    await this.genericDialogService.showResultDialog(
      `Aktualisierung fehlgeschlagen`,
      `Die e-Vergabe Metadaten konnten nicht aktualisiert werden. Bitte versuchen Sie es erneut. Sollte der Fehler wiederholt auftreten, exportieren Sie die XML Datei und senden Sie diese mit Fehlerbeschreibung per E-Mail an den Support.`,
      'Bearbeitung fortsetzen',
      false
    );
  }
}
