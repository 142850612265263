<app-hinweis-banner
  [title]="'Schon gewusst?'"
  [content]="'Unter &quot;Bekanntmachungen&quot; &gt; &quot;Verwalten&quot; können Sie mit den Aktionen &quot;Auftragsbekanntmachung anlegen&quot;, &quot;Ex-Post anlegen&quot;, und &quot;Auftragsänderung anlegen&quot; eine Nachfolge-Bekanntmachung zu einer bereits veröffentlichten Bekanntmachung anlegen. So werden die Bekanntmachungen nicht nur korrekt miteinander verknüpft, es werden so auch viele Felder automatisch mit bereits erfassten Informationen vorbefüllt.'">
</app-hinweis-banner>

<div class="xml-import-selector" *ngIf="featureFlagService.isFeatureEnabled('XML_IMPORT')">
  <div>
    <label for="xml-input" class="link-style">XML-Import</label>
    <input
      id="xml-input"
      type="file"
      accept=".xml"
      (change)="importXmlFile($event)"
      class="hidden" />
    <mat-icon matTooltip="Importiere Bekanntmachung von XML.">import_export</mat-icon>
  </div>
</div>

<div class="container-with-margin">
  <app-filter-table
    *skeleton="loading$ | async; height: '700px'; width: '100%'; type: 'table'"
    [data]="data"
    (rowClick)="onRowClick($event)"></app-filter-table>
</div>
