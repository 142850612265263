<div class="banner-container">
  <div
    class="banner-item"
    *ngFor="let news of newsList"
    [ngClass]="'type-' + news.type.toLowerCase()">
    <div class="banner-text">
      <div class="banner-date">{{ news.newsDate | date : 'dd.MM.yyyy HH:mm' }} Uhr</div>
      <div class="banner-title">{{ news.title }}</div>
    </div>
  </div>
</div>
