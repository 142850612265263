import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SortUtilsService } from "../../../utils/sort-utils-service";

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() options: { id: string; label: string }[];
  @Input() isMandatory: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() hasError: boolean;
  @Input() htmlId: string;
  totalLabelLength: number;

  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private subscription = new Subscription();
  private sortUtilsService: SortUtilsService = inject(SortUtilsService);
  groupName: string;

  ngOnInit(): void {
    this.groupName = `${this.htmlId}-group`;

    if (this.control.value === null) {
      this.control.setValue('', { emitEvent: false });
    }
    this.sortOptions();
    this.subscription.add(
      this.control.valueChanges.subscribe(() => this.cdRef.markForCheck())
    );

    let labelLengths = this.options.map((element) => element.label.length);
    this.totalLabelLength = labelLengths.reduce((accumulator, currentValue) => accumulator + currentValue,0);
  }

  reset(): void {
    this.control.setValue(undefined);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private sortOptions() {
    if (this.options.length === 0) return;

    this.options.sort((a, b) => this.sortUtilsService.sortCodeListItems(a, b));
  }
}
