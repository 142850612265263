import { MatPaginatorIntl } from '@angular/material/paginator';

export const getGermanPaginatorIntl = (): MatPaginatorIntl => {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Verfahren pro Seite';
  paginatorIntl.nextPageLabel = 'nächste Seite';
  paginatorIntl.previousPageLabel = 'vorherige Seite';
  paginatorIntl.firstPageLabel = 'erste Seite';
  paginatorIntl.lastPageLabel = 'letzte Seite';
  paginatorIntl.getRangeLabel = (page, pageSize, length): string =>
    `Seite ${page + 1} von ${Math.ceil(length / pageSize)}`;

  return paginatorIntl;
};
