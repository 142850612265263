import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { ARCHIVE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';
import { DateModel } from '../../../../view-model/type/date-model';
import { LocalDate } from '@js-joda/core';

export class ArchivingDateInValidCenturyValidator extends EVergabeSpecificValidator<
  FieldModel<string>
> {
  private static readonly DEADLINE_INVALID_CENTURY_ERROR_MSG =
    'Das angegebene Datum muss sich zwischen dem 1. Januar 2000 und dem 31.Dezember 2099 befinden.';

  private static readonly YEAR_MAX = 2099;

  shouldAttach(model: FieldModel<string>): boolean {
    return model.noticeNode.id === ARCHIVE_DATE_ID;
  }

  async validate(model: DateModel): Promise<ValidationNotification[]> {
    const maxYear = LocalDate.of(ArchivingDateInValidCenturyValidator.YEAR_MAX, 12, 31);
    if (model.asLocalDate().isAfter(maxYear)) {
      return this.ofSingle(ArchivingDateInValidCenturyValidator.DEADLINE_INVALID_CENTURY_ERROR_MSG);
    }
    return this.empty();
  }
}
