import { Component, OnInit } from "@angular/core";
import { KeycloakService } from 'keycloak-angular';
import { from, Observable } from "rxjs";

@Component({
  selector: 'app-auth-error-dialog-redirect-target',
  templateUrl: './auth-error-dialog-redirect-target.component.html',
  styleUrls: ['./auth-error-dialog-redirect-target.component.scss'],
})
export class AuthErrorDialogRedirectTargetComponent implements OnInit {

  isLoggedIn$: Observable<boolean>

  constructor(
    private keycloak: KeycloakService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn$ = from(this.keycloak.isLoggedIn());

    this.isLoggedIn$.subscribe(success => {
      if(success) window.close();
    });
  }
}
