import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { MeasureModel } from '../../../view-model/type/measure-model';

@Component({
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureComponent extends AbstractFormComponent implements OnInit {
  amountControl = new FormControl();
  unitControl = new FormControl();
  model: MeasureModel;
  ariaLabelForUnit: string;
  htmlIdForUnit: string;

  ngOnInit(): void {
    this.ariaLabelForUnit = `${this.model.translatedLabel}-Einheit`;
    this.htmlIdForUnit = `${this.htmlId}-Einheit`;

    this.initValue();
    this.handleUnitChanges();

    if (this.model.isReadonly) {
      this.amountControl.disable({ emitEvent: false });
      this.unitControl.disable({ emitEvent: false });
    }
  }

  commitValue(): void {
    this.model.value = {
      amount: this.amountControl.value,
      unit: this.unitControl.value.id,
    };
    this.onInputValueChanged();
  }

  private handleUnitChanges(): void {
    this.subscription.add(
      this.unitControl.valueChanges.subscribe(() => {
        this.commitValue();
      })
    );
  }

  private initValue(): void {
    if (this.model.value?.amount) {
      this.amountControl.setValue(this.model.value.amount);
    }
    if (this.model.value?.unit) {
      this.unitControl.setValue(
        this.model.units.find(unit => unit.id === this.model.value.unit) ?? null
      );
    }
  }
}
