<app-hinweis-banner
  title="Wozu dienen Gruppen?"
  content="Hier können Sie Gruppen zur Zusammenarbeit mit Kollegen erstellen und verwalten. Mitglieder einer Gruppe können auf alle Bekanntmachungen der anderen Mitglieder zugreifen. So können Sie z.B. bequem Vertretungen einrichten.">
</app-hinweis-banner>

<div class="container-with-margin">
  <ng-container *hasAnyRole="['support']; invert true">
    <h2>Eingegangene Einladungen</h2>
    <div *skeleton="einladungenLoading$ | async; height: '65px'; width: '100%'">
      <app-einladung-list
        [einladungen]="einladungen$ | async"
        (acceptInvitation)="onAcceptInvitation($event)"
        (declineInvitation)="onDeclineInvitation($event)">
      </app-einladung-list>
    </div>
  </ng-container>

  <h2>Gruppenmitgliedschaften</h2>
  <div *skeleton="gruppenLoading$ | async; height: '65px'; width: '100%'">
    <app-gruppen-list
      [gruppen]="gruppen$ | async"
      (createGroup)="onCreateGroup($event)"
      (updateGroup)="onUpdateGroup($event)"
      (leaveGroup)="onLeaveGroup($event)"
      (removeGroup)="onRemoveGroup($event)"
      (invitationSent)="onInvitationSent($event)"
      (removeMember)="onRemoveMember($event)"
      (transferOwnership)="onTransferOwnership($event)"
      (withdrawInvitation)="onWithdrawInvitation($event)"
      (search)="onSearch($event)"
    >
    </app-gruppen-list>
  </div>
</div>
