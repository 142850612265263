import { Component, Input, OnInit } from '@angular/core';
import { NewsService } from '../../../services/news.service';
import { NewsDto } from '../../api/notices';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-hinweis-banner',
  templateUrl: './hinweis-banner.component.html',
  styleUrls: ['./hinweis-banner.component.scss'],
})
export class HinweisBannerComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;

  showBanner$: Observable<boolean>;

  constructor(private newsService: NewsService) {}

  ngOnInit(): void {
    this.showBanner$ = this.newsService.getBannerNews().pipe(
      map((newsList: NewsDto[]) => newsList.length === 0)
    );
  }
}
