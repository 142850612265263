import { Component, OnInit } from '@angular/core';
import { RepeatableGroupModel } from '../../../../view-model/repeatable-group-model';
import { CodelistModel } from '../../../../view-model/type/codelist-model';
import { ModelStateService } from '../../../../services/view-model/model-state.service';
import { AbstractFormComponent } from '../../abstract-form/abstract-form.component';

@Component({
  selector: 'app-gr-lot-result-cvd-vehicles-statistics',
  templateUrl: './gr-lot-result-cvd-vehicles-statistics.component.html',
})
export class GrLotResultCvdVehiclesStatisticsComponent extends AbstractFormComponent implements OnInit {
  model: RepeatableGroupModel;
  isVisible: boolean;

  ngOnInit() {
    const fahrzeugkategorie = ModelStateService.findModelById<CodelistModel>(this.model.parent, 'BT-723-LotResult');
    this.isVisible = this.evaluateVisibility(fahrzeugkategorie);

    this.subscription.add(fahrzeugkategorie.modelChangedEmitter.subscribe(() => {
      this.isVisible = this.evaluateVisibility(fahrzeugkategorie);
    }));
  }

  private evaluateVisibility(model: CodelistModel): boolean {
    return !!model.value && !model.isForbidden;
  }
}
