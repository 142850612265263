import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  private _loadingEventEmitter = new BehaviorSubject<boolean>(true);

  /**
   * Activates the Loading Spinner.
   * Waits 50ms before returning, to allow the Spinner to show up. Otherwise, the Validation will freeze the UI.
   */
  async setLoading(loading: boolean) {
    this._loadingEventEmitter.next(loading);
    return await new Promise(res => {
      setTimeout(() => {
        res(true);
      }, 50);
    });
  }

  loadingEventEmitter(): Subject<boolean> {
    return this._loadingEventEmitter;
  }
}
