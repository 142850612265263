import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';

import { FieldModel } from '../../../view-model/field-model';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberComponent extends AbstractFormComponent implements OnInit {
  inputControl: FormControl = new FormControl();
  @Input() model: FieldModel<number>;

  ngOnInit(): void {
    if (this.model.value !== undefined && this.model.value !== null) {
      this.inputControl.setValue(this.model.value);
    }

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: false });
    }
  }

  commitModel() {
    this.model.value = this.inputControl.value !== undefined && this.inputControl.value !== null
      ? +this.inputControl.value
      : null;
    this.onInputValueChanged();
  }
}
