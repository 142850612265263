import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GruppenService } from '../gruppen.service';
import { InvitationModel } from '../model/invitation.model';
import { GroupMemberModel, GroupModel } from "../../api/user-service";

@Component({
  selector: 'app-gruppen-uebersicht',
  templateUrl: './gruppen-uebersicht.component.html',
  styleUrls: ['./gruppen-uebersicht.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GruppenUebersichtComponent implements OnInit {
  gruppen$: Observable<GroupModel[]>;
  gruppenLoading$: Observable<boolean>;
  einladungen$: Observable<InvitationModel[]>;
  einladungenLoading$: Observable<boolean>;

  private gruppenService = inject(GruppenService);

  ngOnInit(): void {
    this.gruppen$ = this.gruppenService.filteredGroups$;
    this.gruppenLoading$ = this.gruppenService.groupsLoading$;
    this.einladungen$ = this.gruppenService.invitations$;
    this.einladungenLoading$ = this.gruppenService.invitationsLoading$;

    this.gruppenService.init();
    this.gruppenService.loadGroupsAndInvitations();
  }

  onCreateGroup(gruppe: GroupModel): void {
    this.gruppenService.createGroup(gruppe);
  }

  onUpdateGroup(gruppe: GroupModel): void {
    this.gruppenService.updateGroup(gruppe);
  }

  onLeaveGroup(gruppe: GroupModel): void {
    this.gruppenService.leaveGroup(gruppe);
  }

  onRemoveGroup(gruppe: GroupModel): void {
    this.gruppenService.removeGroup(gruppe);
  }

  onInvitationSent(payload: { groupId: number; invitedMember: GroupMemberModel }): void {
    this.gruppenService.addInvitedMemberToGroup(payload.groupId, payload.invitedMember);
  }

  onRemoveMember(payload: { memberId: number; groupId: number }): void {
    this.gruppenService.removeMember(payload.memberId, payload.groupId);
  }

  onTransferOwnership(payload: { memberId: number; groupId: number }): void {
    this.gruppenService.transferOwnership(payload.memberId, payload.groupId);
  }

  onWithdrawInvitation(payload: { memberId: number; groupId: number }): void {
    this.gruppenService.withdrawInvitation(payload.memberId, payload.groupId);
  }

  onAcceptInvitation(event: {groupId: number, invitationId: number}): void {
    this.gruppenService.acceptInvitation(event.groupId, event.invitationId);
  }

  onDeclineInvitation(event: {groupId: number, invitationId: number}): void {
    this.gruppenService.declineInvitation(event.groupId, event.invitationId);
  }

  onSearch(searchTerm: string): void {
    this.gruppenService.setFilter(searchTerm);
  }
}
