<div class="portal-wrapper">
  <app-portal-header></app-portal-header>
  <div class="portal-divider"></div>
  <app-neuigkeiten-banner></app-neuigkeiten-banner>
  <app-portal-content class="portal-content" [ngClass]="{ overflow: (enableErfassungsmaskeMode$ | async) === true }">
    <app-loading-spinner *ngIf="(isLoading$ | async) === true"></app-loading-spinner>
    <router-outlet></router-outlet>
  </app-portal-content>
  <app-portal-footer></app-portal-footer>
</div>
