import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent {
  @Input()
  hasContentFrame = true;

  @Input()
  isContentColorWhite = true;

  @Input()
  titel: string;
}
