/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewsDto { 
    id?: number;
    newsDate: Date;
    type: NewsDto.TypeEnum;
    title: string;
    content: string;
}
export namespace NewsDto {
    export type TypeEnum = 'LANDING_PAGE_NEWS' | 'INFO_BANNER' | 'WARNING_BANNER';
    export const TypeEnum = {
        LandingPageNews: 'LANDING_PAGE_NEWS' as TypeEnum,
        InfoBanner: 'INFO_BANNER' as TypeEnum,
        WarningBanner: 'WARNING_BANNER' as TypeEnum
    };
}


