import { Injectable } from '@angular/core';
import { FieldModel } from '../view-model/field-model';
import { BaseModel } from '../view-model/base-model';
import { ModelStateService } from './view-model/model-state.service';
import { Severity, ValidationNotification } from '../types/app-types';
import { EditorConfigurationProvider } from './editor-configuration.provider';

/**
 * Stateful Provider, for storing the global values for Lot-Fields, which should be in a synchronized state.
 */
@Injectable()
export class SyncedFieldService {
  private static SYNCED_FIELD_INFO =
    'Dieser Wert gilt für alle Lose. Verschiedene Werte in unterschiedlichen Losen werden zurzeit nicht Unterstützt. Änderungen werden automatisch für alle anderen Lose übernommen.';

  // List of all BTs which should be synced across the Formular.
  private SYNCED_BTS = [
    'BT-131(d)-Lot',
    'BT-1311(d)-Lot',
    'BT-131(t)-Lot',
    'BT-1311(t)-Lot',
    'BT-14-Lot',
    'BT-132(d)-Lot', // Opening-Date
    'BT-132(t)-Lot', // Opening-Date
  ];

  private syncedValueMap = new Map<string, any>();

  constructor(private editorConfigurationProvider: EditorConfigurationProvider) {}

  public updateSyncedFields(originModel: FieldModel<any>) {
    if (!this.editorConfigurationProvider.isEVergabePlatform() || !this.isSyncedModel(originModel))
      return;
    this.syncedValueMap.set(originModel.noticeNode.id, originModel.value);
    const foundModels = ModelStateService.findModelsById(
      originModel.root,
      originModel.noticeNode.id
    );
    foundModels
      .filter(model => model !== originModel && model instanceof FieldModel)
      .forEach((model: FieldModel<any>) => {
        model.value = originModel.value;
        model.emitChange();
      });
  }

  public configureNewModel(newModel: FieldModel<any>) {
    if (!this.editorConfigurationProvider.isEVergabePlatform() || !this.isSyncedModel(newModel))
      return;
    if (this.syncedValueMap.has(newModel.noticeNode.id)) {
      newModel.value = this.syncedValueMap.get(newModel.noticeNode.id);
    } else {
      this.syncedValueMap.set(newModel.noticeNode.id, newModel.value);
    }
    newModel.fieldNotifications.push(
      new ValidationNotification(SyncedFieldService.SYNCED_FIELD_INFO, Severity.INFO)
    );
  }

  public findSyncedModels(originModel: FieldModel<any>): FieldModel<any>[] {
    if (!this.editorConfigurationProvider.isEVergabePlatform() || !this.isSyncedModel(originModel))
      return [];

    const foundModels = ModelStateService.findModelsById(
      originModel.root,
      originModel.noticeNode.id
    );

    return foundModels.filter(
      model => model instanceof FieldModel && model !== originModel
    ) as FieldModel<any>[];
  }

  private isSyncedModel(fieldModel: FieldModel<any>): boolean {
    return this.SYNCED_BTS.some(bt => fieldModel.noticeNode?.id === bt);
  }
}
