<div [hidden]="model.isHidden" class="form-component-input-group">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="input-container">
    <div class="value-container">
      <input
        [id]="htmlId"
        (blur)="commitValue()"
        [attr.aria-label]="model.translatedLabel"
        [class.has-error]="hasInputError()"
        [class.required]="model.isMandatory && !model.isReadonly"
        [formControl]="amountControl"
        class="form-text-field"
        appInputNumber
        [fractionDigits]="0"
        [maxLength]="model.fieldInfo?.maxLength"
      />
    </div>
    <div class="unit-container">
      <app-select
        [htmlId]="htmlIdForUnit"
        [control]="unitControl"
        [options]="model.units"
        [ariaLabel]="ariaLabelForUnit"
        [isMandatory]="model.isMandatory"
        [isReadonly]="model.isReadonly"
        [hasError]="hasInputError()">
      </app-select>
    </div>
  </div>
  <app-validation-message [model]="model"></app-validation-message>
</div>
