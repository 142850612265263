import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { StateService } from '../state.service';
import { IdModel } from '../../view-model/type/id-model';
import { GroupModel } from '../../view-model/group-model';
import { SectionModel } from '../../view-model/section-model';
import { RepeatableGroupModel } from '../../view-model/repeatable-group-model';
import { IdRefModel } from '../../view-model/type/id-ref.model';
import { ModelValidationService } from './model-validation.service';
import { BaseModel, ModelWithChildren } from '../../view-model/base-model';
import { SdkService } from '../sdk.service';
import { FieldModel } from '../../view-model/field-model';
import { ModelStateService } from './model-state.service';
import { ComponentModel } from '../../view-model/component-model';
import { MultilingualModel } from '../../view-model/type/multilingual-model';

@Injectable()
export class SchemeIdService {
  // List of Fields, which provide the caption for the given schemeId
  private _captionFieldsRef: CaptionRef[] = [];

  constructor(
    private stateService: StateService,
    private modelValidationService: ModelValidationService,
    private sdkService: SdkService
  ) {}

  async createIndicator(model: IdModel): Promise<void> {
    if (model.value) {
      this.stateService.addIndicator({ id: model.value, label: null });
    } else {
      await this.createNew(model);
    }

    await this.registerCaptionFieldForModel(model);
  }

  /**
   * Updates the Scheme-ID of succeeding Items after the removed one to keep a continuous indexing.
   *
   * @param removedIdModel model if the removed ID.
   */
  normalizeRemainingIdsOnRemoval(removedIdModel: IdModel) {
    // Special case: When an initial value is available, there is attached data on server side to this Scheme ID. We need to track, that we deleted this Section to tell the Server what Section has been deleted.
    if (removedIdModel.initialValue && this.isPartOrLot(removedIdModel)) {
      removedIdModel.root.deletedInitialLotIds.push(removedIdModel.initialValue);
    }

    // Update all following id models and update their index to prevent holes.
    const idModelsFollowingRemovedIdModel = this.getSortedIdModelAfterGivenSchemeId(
      removedIdModel,
      removedIdModel.value
    );

    let lastSectionId = this.getNumericalIndexFromScheme(removedIdModel.value);

    for (const idModel of idModelsFollowingRemovedIdModel) {
      const oldId = idModel.value;
      const newId = this.getSchemeIdFromNumericalIndex(
        lastSectionId,
        removedIdModel.noticeNode._idScheme
      );
      this.renameIdModelAndNotify(idModel, oldId, newId);

      idModel.emitChange();
      lastSectionId++;
    }
  }

  /**
   * Checks if the first Lot is fulfilling the Naming Convention, expected by the EU.
   * If there is only one Lot, it's not an actual Lot, but a technical one. The EU requires this to be called LOT-0000.
   * When another Lot has been added, the first Lot needs to be renamed to LOT-0001 and the second one to LOT-0002.
   */
  renameFirstLotOrPartToMatchNamingRequirements(modifiedModel: IdModel) {
    if (!this.isPartOrLot(modifiedModel)) {
      return;
    }

    const allLotIdModels = ModelStateService.findModelsById(
      modifiedModel.root,
      modifiedModel.noticeNode.id
    ) as IdModel[];

    const technicalIdentifier = `${modifiedModel.getSchemeId()}-0000`;
    if (allLotIdModels.length === 1 && allLotIdModels[0].value !== technicalIdentifier) {
      // We only have one LOT, and it's not called correctly.
      this.renameIdModelAndNotify(allLotIdModels[0], allLotIdModels[0].value, technicalIdentifier);
    } else if (allLotIdModels.length > 1 && allLotIdModels[0].value === technicalIdentifier) {
      // We have more than one lot, but the first Lot has the technical Lot Identifier.
      for (let index = allLotIdModels.length - 1; index >= 0; index--) {
        // Start from the last item, so we don't use the same reference twice
        const targetModel = allLotIdModels[index];
        this.renameIdModelAndNotify(
          targetModel,
          targetModel.value,
          this.getSchemeIdFromNumericalIndex(index + 1, modifiedModel.getSchemeId())
        );
      }
    }
    modifiedModel.emitChange();
  }

  /**
   * Searches for defined IdModels inside the given removedSectionModel and then removes all References inside the ComponentModel to it.
   */
  removeIdModelsAndGlobalReferencesForRemovedSectionAndRearrangeGapsInIndex(
    removedSectionModel: SectionModel
  ) {
    removedSectionModel.children.forEach(child => {
      if (child instanceof GroupModel || child instanceof RepeatableGroupModel) {
        this.removeIndicatorRefsInCompModelForRemovedGroup(child);
      }

      if (child instanceof SectionModel) {
        this.removeIdModelsAndGlobalReferencesForRemovedSectionAndRearrangeGapsInIndex(child);
      }

      if (child instanceof IdModel) {
        this.removeIndicatorField(child);
      }
    });
  }

  /**
   * Searches for defined IdModels inside the given removedGroupModel and then removes all References inside the ComponentModel to it.
   */
  removeIndicatorRefsInCompModelForRemovedGroup(
    removedGroupModel: GroupModel | RepeatableGroupModel
  ) {
    removedGroupModel.children.forEach(child => {
      if (child instanceof GroupModel) {
        this.removeIndicatorRefsInCompModelForRemovedGroup(child);
      }

      if (child instanceof RepeatableGroupModel) {
        this.removeIndicatorRefsInCompModelForRemovedGroup(child);
      }

      if (child instanceof IdModel) {
        this.removeIndicatorField(child);
      }
    });
  }

  updateSchemeCaption(srcModel: FieldModel<any>, capRef: CaptionRef) {
    const targetSchemeValue = this.findSchemeValueForCaptionField(srcModel, capRef);

    let label = srcModel.value;
    if (srcModel instanceof MultilingualModel) {
      label = srcModel.germanValue ?? srcModel.englishValue;
    }

    if (label === '') {
      label = null;
    }
    this.stateService.updateIndicatorLabel({ id: targetSchemeValue, label });
  }

  private getSortedIdModelAfterGivenSchemeId(removedIdModel: IdModel, schemeId: string): IdModel[] {
    const idModelsForAllSections = ModelStateService.findModelsById(
      removedIdModel.root,
      removedIdModel.noticeNode.id
    ) as IdModel[];

    return idModelsForAllSections
      .sort(
        (a, b) =>
          this.getNumericalIndexFromScheme(a.value) - this.getNumericalIndexFromScheme(b.value)
      )
      .filter(
        idModel =>
          this.getNumericalIndexFromScheme(idModel.value) >
          this.getNumericalIndexFromScheme(schemeId)
      );
  }

  private isPartOrLot(idModel: IdModel) {
    return ['LOT', 'PAR'].includes(idModel.getSchemeId());
  }

  private findSchemeValueForCaptionField(model: BaseModel, capRef: CaptionRef): string {
    if (model.parent && !(model.parent instanceof ComponentModel)) {
      const targetModel = ModelStateService.findModelById(model.parent, capRef.schemeIdField);
      if (targetModel) return (targetModel as FieldModel<any>).value;
      return this.findSchemeValueForCaptionField(model.parent, capRef);
    }
  }

  private async registerCaptionFieldForModel(model: IdModel) {
    if (!this._captionFieldsRef.some(ref => ref.schemeId === model.getSchemeId())) {
      const fields = await this.sdkService.getFields();

      const targetXmlStruc = fields.xmlStructure.find(
        struc => struc.identifierFieldId === model.noticeNode.id
      );
      if (targetXmlStruc) {
        this._captionFieldsRef.push({
          schemeId: model.getSchemeId(),
          captionFieldId: targetXmlStruc.captionFieldId,
          schemeIdField: model.noticeNode.id,
        });
      }
    }
  }

  private removeIndicatorField(model: IdModel) {
    this.stateService.removeIndicator(model.value);
    this.updateAllIdRefs(model.root, model.value, null);

    this.normalizeRemainingIdsOnRemoval(model);
  }

  private renameIdModelAndNotify(model: IdModel, oldId: string, newId: string | null) {
    this.updateAllIdRefs(model.root, oldId, newId);
    model.value = newId;
    this.stateService.updateIndicatorSchemeIdRefreshHighest({
      indicator: model.getSchemeId(),
      oldId,
      newId,
    });
  }

  private updateAllIdRefs(model: ModelWithChildren, oldIdRef: string, newIdRef: string | null) {
    model.children.forEach(child => {
      if (child instanceof IdRefModel && child.value === oldIdRef) {
        child.value = newIdRef;
        this.modelValidationService.validateModel(child, true);
      }

      if (child.children) {
        this.updateAllIdRefs(child, oldIdRef, newIdRef);
      }
    });
  }

  private async createNew(model: IdModel): Promise<void> {
    model.value = this.getSchemeIdFromNumericalIndex(
      await this.getCurrentIndexForScheme(model.getSchemeId()),
      model.getSchemeId()
    );
    this.stateService.addIndicator({ id: model.value, label: null });
  }

  private async getCurrentIndexForScheme(indicatorScheme: string): Promise<number> {
    const indicatorState = await this.getIndicatorState();
    const highest = indicatorState.get(indicatorScheme)?.highest;

    return highest !== undefined ? highest + 1 : 0;
  }

  private getSchemeIdFromNumericalIndex(index: number, idScheme: string): string {
    return `${idScheme}-${String(index).padStart(4, '0')}`;
  }

  private getNumericalIndexFromScheme(schemeId: string): number {
    const numericalPart = schemeId.split('-')[1];
    return Number(numericalPart);
  }

  private async getIndicatorState() {
    return firstValueFrom(this.stateService.getIndicator());
  }

  get captionFieldsRef(): CaptionRef[] {
    return this._captionFieldsRef;
  }
}

interface CaptionRef {
  schemeId: string;
  captionFieldId: string;
  schemeIdField: string;
}
