import { Injectable } from '@angular/core';
import { SearchResult } from '../../types/app-types';
import { ModelStateService } from '../view-model/model-state.service';
import { FieldModel } from '../../view-model/field-model';
import { RepeatableFieldModel } from '../../view-model/repeatable-field-model';
import { MultilingualModel } from '../../view-model/type/multilingual-model';
import { AmountModel } from '../../view-model/type/amount-model';
import { MeasureModel } from '../../view-model/type/measure-model';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { Language } from '../../types/data-model';
import { BaseModel, ModelWithChildren } from '../../view-model/base-model';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';
import { SectionModel } from '../../view-model/section-model';

@Injectable()
export class SearchService {
  static DATE_REGEX = /\d{4}-\d{1,2}-\d{1,2}/g;

  constructor(private modelStateService: ModelStateService) {}

  public search(query: string): SearchResult[] {
    const results: SearchResult[] = [];

    const componentModel = this.modelStateService.componentModel;

    componentModel.children.forEach(child =>
      this._search(child, query.toLowerCase().trimStart().trimEnd(), results)
    );

    return results;
  }

  _search(
    group: ModelWithChildren,
    query: string,
    currentResult: SearchResult[],
    lot?: string
  ): SearchResult[] {
    // Don't show search results in hidden groups
    if (group instanceof BaseModel && group.isHidden) {
      return currentResult;
    }

    for (const child of group.children) {
      if (child instanceof FieldModel && !(child instanceof RepeatableFieldModel)) {
        // Field
        const match = this.matchEntry(child, query, lot);

        if (match) {
          currentResult.push(match);
        }
      } else {
        // Group
        if (group instanceof RepeatableSectionModel) {
          lot = (child as SectionModel).sectionSchemeId;
        }

        this._search(child, query, currentResult, lot);
      }
    }

    return currentResult;
  }

  private matchEntry(
    entry: FieldModel<any>,
    query: string,
    lot: string | undefined
  ): SearchResult | null {
    if (!entry || entry.isHidden) return null;
    const id = entry.noticeNode.id;
    // filters out the two language bts in the toolbar
    if (id === 'BT-702(a)-notice' || id === 'BT-702(b)-notice') return null;

    const label = entry.translatedLabel;

    const resultIndex = id.trimStart().toLowerCase().indexOf(query.toLowerCase());
    if (resultIndex > -1 || label.toLowerCase().trimStart().includes(query)) {
      return {
        previewText: this.formatSearchPreviewValue(entry),
        type: 'id',
        entry,
        label,
        lot,
      };
    }

    return this.isValueMatching(entry, query, lot, label);
  }

  private isValueMatching(
    field: FieldModel<any>,
    query: string,
    lot: string | undefined,
    label: string
  ): SearchResult | null {
    if (field.value === undefined || field.value === null) return null;

    const fieldValueAsString = this.formatSearchPreviewValue(field);

    const multilingualMatch = this.matchMultilingual(field, query, lot, label);
    if (multilingualMatch) {
      return multilingualMatch;
    }

    if (
      fieldValueAsString.toLowerCase().includes(query) ||
      this.isDateMatching(fieldValueAsString, query)
    ) {
      return {
        entry: field,
        label,
        lot,
        type: 'string',
        previewText: fieldValueAsString,
      };
    }

    return null;
  }

  private matchMultilingual(
    field: FieldModel<any>,
    query: string,
    lot: string,
    label: string
  ): SearchResult | null {
    if (!(field instanceof MultilingualModel)) return null;

    if (field.value.DEU?.trimStart().toLowerCase()?.includes(query)) {
      return {
        entry: field,
        label,
        lot,
        type: 'string',
        previewText: field.value.DEU,
        language: Language.Deu,
      };
    }

    if (field.value.ENG?.trimStart().toLowerCase().includes(query)) {
      return {
        entry: field,
        label,
        lot,
        type: 'string',
        previewText: field.value.ENG,
        language: Language.Eng,
      };
    }

    return null;
  }

  private isDateMatching(fielValue: string, query: string): boolean {
    const dateMatch = fielValue.match(SearchService.DATE_REGEX);
    if (dateMatch) {
      const dateParts = dateMatch[0].split('-');
      if (
        `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`.includes(query) ||
        `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`.includes(query)
      ) {
        return true;
      }
    }

    return false;
  }

  private formatSearchPreviewValue(field: FieldModel<any>): string {
    const value = field.value;
    if (this.isEmpty(value)) {
      return '';
    }

    if (field instanceof CodelistModel) {
      const entry = field.codeList.find(codeListItem => codeListItem.id === value);
      return entry ? `${entry.label} (${value})` : '';
    }

    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'number') {
      return `${value}`;
    }

    if (field instanceof MultilingualModel) {
      return value.DEU ?? value.ENG ?? '';
    }

    // there should be only objects after this point
    return this.formatValueObjects(field);
  }

  private formatValueObjects(field: FieldModel<any>): string {
    if (typeof field.value !== 'object') return '';

    if (field instanceof AmountModel)
      return `${field.value.amount ?? ''} ${field.value.currencyID ?? ''}`;
    if (field instanceof MeasureModel)
      return `${field.value.amount ?? ''} ${field.value.unit ?? ''}`;

    return '';
  }

  private isEmpty(item: any) {
    return item === undefined || item === null || item === '';
  }
}
