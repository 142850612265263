import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateService } from '../state.service';
import procedureTypes from './procedure-types-de.json';
import { SdkService } from '../sdk.service';
import { TranslationService } from '../translation.service';

@Injectable()
export class FormTitleService {
  private formInfoSubject: BehaviorSubject<FormInfo> = new BehaviorSubject<FormInfo>(null);
  private noticeId: string;
  private procedureType: string;

  constructor(
    private sdkService: SdkService,
    private translationService: TranslationService,
    stateService: StateService
  ) {
    stateService.getActiveProcedureType().subscribe(async procedureType => {
      this.procedureType = procedureType;
      await this.updateTitle();
    });
    stateService.getNoticeId().subscribe(async noticeId => {
      this.noticeId = noticeId;
      await this.updateTitle();
    });
  }

  public getFormInfo(): Observable<FormInfo> {
    return this.formInfoSubject;
  }

  private async updateTitle() {
    const titleWithProcedureType = procedureTypes[`${this.noticeId}_${this.procedureType}`];
    const titleWithoutProcedureType = procedureTypes[`${this.noticeId}`];
    if (titleWithProcedureType) {
      this.formInfoSubject.next(titleWithProcedureType);
    } else if (titleWithoutProcedureType) {
      this.formInfoSubject.next(titleWithoutProcedureType);
    } else {
      const subtype = await this.sdkService.getNoticeSubType(this.noticeId);
      if (subtype) {
        this.formInfoSubject.next({
          bekanntmachungsart: this.translationService.translate(subtype._label),
          richtlinie: null,
          vergaberechtsrahmen: null,
        });
      }
    }
  }
}

export interface FormInfo {
  bekanntmachungsart: string;
  richtlinie: string;
  vergaberechtsrahmen: string;
}
