import { Injectable } from '@angular/core';

import { MaintenanceWindow } from '../types/app-types';
import { CommunicationService } from './communication.service';
import { ChronoUnit, DateTimeFormatter, LocalDate, LocalDateTime } from '@js-joda/core';
import { EditorConfigurationProvider } from './editor-configuration.provider';

@Injectable()
export class MaintenanceWindowService {
  private _maintenanceWindows: MaintenanceWindow[] = [];

  constructor(
    private communicationService: CommunicationService,
    private editorConfigurationProvider: EditorConfigurationProvider
  ) {}

  get maintenanceWindows(): MaintenanceWindow[] {
    return this._maintenanceWindows;
  }

  public async initMaintenanceWindows() {
    if (this.editorConfigurationProvider.isSEB()) {
      this._maintenanceWindows = [];
    } else {
      this._maintenanceWindows = await this.communicationService.getMaintenanceWindows();
      this._maintenanceWindows.sort((a, b) => a.durationFrom.compareTo(b.durationFrom));
    }
  }

  public findSingleMaintenanceWindowCollision(date: LocalDateTime): MaintenanceWindow | undefined {
    return date
      ? this._maintenanceWindows.find(
          value =>
            value.durationFrom.isEqual(date) ||
            value.durationTill.isEqual(date) ||
            (value.durationFrom.isBefore(date) && value.durationTill.isAfter(date))
        )
      : undefined;
  }

  public checkIfDayIsCompletelyCovered(date: LocalDate) {
    let start = date.atStartOfDay().truncatedTo(ChronoUnit.MINUTES);
    const end = start.plusDays(1).truncatedTo(ChronoUnit.MINUTES);
    let covered = false;

    for (const mw of this.findWindowsForDate(date)) {
      const mwFrom = mw.durationFrom.truncatedTo(ChronoUnit.MINUTES);
      const mwTill = mw.durationTill.truncatedTo(ChronoUnit.MINUTES);

      if (
        (mwFrom.isBefore(start) || mwFrom.isEqual(start)) &&
        (mwTill.isEqual(start) || mwTill.isAfter(start))
      ) {
        if (mwTill.isEqual(end) || mwTill.isAfter(end)) {
          covered = true;
          break;
        }
        start = mwTill;
      }
    }

    return covered || start.isEqual(end) || start.isAfter(end);
  }

  public findWindowsForDate(date: LocalDate): MaintenanceWindow[] {
    return this._maintenanceWindows.filter(
      value =>
        (value.durationFrom.toLocalDate().isBefore(date) &&
          value.durationTill.toLocalDate().isAfter(date)) ||
        value.durationFrom.toLocalDate().isEqual(date) ||
        value.durationTill.toLocalDate().isEqual(date)
    );
  }

  public createMaintenanceWindowCollisionMessage(
    maintenanceWindowCollision: MaintenanceWindow
  ): string {
    return `Das ausgewählte Datum liegt in einem Wartungsfenster (${this.toString(
      maintenanceWindowCollision
    )}).`;
  }

  public toString(maintenanceWindowCollision: MaintenanceWindow): string {
    const from = this.toDeLocaleString(maintenanceWindowCollision.durationFrom);
    const till = this.toDeLocaleString(maintenanceWindowCollision.durationTill);

    return `${from} - ${till}`;
  }

  private toDeLocaleString(date: LocalDateTime): string {
    return date.format(DateTimeFormatter.ofPattern('dd.MM.uuuu HH:mm'));
  }
}
