/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NoticeActionDto { 
    type: NoticeActionDto.TypeEnum;
    formMode?: NoticeActionDto.FormModeEnum;
}
export namespace NoticeActionDto {
    export type TypeEnum = 'SHOW_PREVIEW' | 'EDIT_DRAFT' | 'EDIT_SENT_NOTICE' | 'EDIT_SENT_CHANGE_NOTICE' | 'DELETE_DRAFT' | 'STOP' | 'CREATE_CHANGE_NOTICE' | 'CREATE_CN' | 'CREATE_CAN' | 'CREATE_CAN_MODIF' | 'EXPORT_CN' | 'CREATE_TEMPLATE';
    export const TypeEnum = {
        ShowPreview: 'SHOW_PREVIEW' as TypeEnum,
        EditDraft: 'EDIT_DRAFT' as TypeEnum,
        EditSentNotice: 'EDIT_SENT_NOTICE' as TypeEnum,
        EditSentChangeNotice: 'EDIT_SENT_CHANGE_NOTICE' as TypeEnum,
        DeleteDraft: 'DELETE_DRAFT' as TypeEnum,
        Stop: 'STOP' as TypeEnum,
        CreateChangeNotice: 'CREATE_CHANGE_NOTICE' as TypeEnum,
        CreateCn: 'CREATE_CN' as TypeEnum,
        CreateCan: 'CREATE_CAN' as TypeEnum,
        CreateCanModif: 'CREATE_CAN_MODIF' as TypeEnum,
        ExportCn: 'EXPORT_CN' as TypeEnum,
        CreateTemplate: 'CREATE_TEMPLATE' as TypeEnum
    };
    export type FormModeEnum = 'Create' | 'Update' | 'AlterBeforeRelease' | 'AlterUpdateBeforeRelease';
    export const FormModeEnum = {
        Create: 'Create' as FormModeEnum,
        Update: 'Update' as FormModeEnum,
        AlterBeforeRelease: 'AlterBeforeRelease' as FormModeEnum,
        AlterUpdateBeforeRelease: 'AlterUpdateBeforeRelease' as FormModeEnum
    };
}


