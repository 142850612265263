import { Injectable } from '@angular/core';
import { SelectionCriterion } from '../types/selection-criterion';
import { CommunicationService } from './communication.service';
import { GroupModel } from '../view-model/group-model';
import { MultilingualModel } from '../view-model/type/multilingual-model';
import { FieldModel } from '../view-model/field-model';
import { ModelListenerService } from './view-model/model-listener.service';
import { ModelStateService } from './view-model/model-state.service';
import { StateService } from './state.service';
import { Language } from '../types/data-model';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SelectionCriterionService {
  private criteria: SelectionCriterion[] = [];

  constructor(
    private communicationService: CommunicationService,
    private modelListenerService: ModelListenerService,
    private stateService: StateService
  ) {}

  async loadSelectionCriteria(): Promise<void> {
    const criteriaWrapper = await this.communicationService.loadCriteria();
    this.criteria = criteriaWrapper.criteria;
  }

  getSelectionCriteria(): SelectionCriterion[] {
    return this.criteria ? this.criteria : [];
  }

  async applyCriterion(model: GroupModel, criterion: SelectionCriterion) {
    await this.setValue(model, 'BT-809-Lot', criterion.criterionType);
    await this.setValue(model, 'BT-750-Lot', criterion.description);
    await this.setValue(model, 'BT-40-Lot', criterion.secondPhase);
  }

  async addCriterion(model: GroupModel): Promise<void> {
    const selectionCriterion: SelectionCriterion = {
      criterionType: await this.getFieldValue(model, 'BT-809-Lot'),
      description: await this.getFieldValue(model, 'BT-750-Lot'),
      secondPhase: await this.getFieldValue(model, 'BT-40-Lot'),
    };
    this.criteria.push(selectionCriterion);
    await this.communicationService.saveCriteria(this.criteria);
  }

  deleteCriterion(criterion: SelectionCriterion): SelectionCriterion[] {
    const key = this.criteria.indexOf(criterion);
    this.criteria.splice(key, 1);
    this.communicationService.saveCriteria(this.criteria);
    return this.criteria;
  }

  copyCriterion(criterion: SelectionCriterion) {
    this.criteria.push(criterion);
    this.communicationService.saveCriteria(this.criteria);
    return this.criteria;
  }

  editCriterion(
    currentCriterion: SelectionCriterion,
    importedCriterion: SelectionCriterion
  ): SelectionCriterion[] {
    if (currentCriterion && importedCriterion) {
      const indexOfOldContact = this.criteria.indexOf(importedCriterion, 0);
      this.criteria[indexOfOldContact] = currentCriterion;
      this.communicationService.saveCriteria(this.criteria);
    }
    return this.criteria;
  }

  async getFieldValue(groupModel: GroupModel, btId: string): Promise<any> {
    const languages = await firstValueFrom(this.stateService.getLanguages());
    const targetModel = ModelStateService.findModelById<FieldModel<any>>(groupModel, btId);
    const hasDE = languages.includes(Language.Deu);
    if (targetModel instanceof MultilingualModel) {
      return hasDE && targetModel.germanValue ? targetModel.germanValue : targetModel.englishValue;
    } else {
      return targetModel.value;
    }
  }

  private async setValue(groupModel: GroupModel, btId: string, value: any) {
    const targetField = ModelStateService.findModelById<FieldModel<any>>(groupModel, btId);
    if (targetField instanceof MultilingualModel) {
      await this.setMultiLingualValue(targetField, value);
    } else {
      targetField.value = value;
    }
    targetField.emitChange();
    this.modelListenerService.onModelValueChange(targetField);
  }

  private async setMultiLingualValue(targetField: MultilingualModel, value: string) {
    const languages = await firstValueFrom(this.stateService.getLanguages());
    const hasDE = languages.includes(Language.Deu);
    if (hasDE) {
      targetField.germanValue = value;
    } else {
      targetField.englishValue = value;
    }
  }
}
