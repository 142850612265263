import { DateUtilsService } from '../../../../utils/date-utils-service';
import { DateModel } from '../../../../view-model/type/date-model';
import { ValidationNotification } from '../../../../types/app-types';
import { EVergabeSpecificValidator } from '../ModelValidator';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';

export class DeadlineTodayValidator extends EVergabeSpecificValidator<DateModel> {
  private static readonly BTS_FOR_DEADLINE_TODAY_VALIDATION: string[] = [
    'BT-1311(d)-Lot', // participation deadline
    'BT-131(d)-Lot', // offer deadline
    OFFER_DEADLINE_DATE_ID, // provisional offer deadline
    'BT-132(d)-Lot', // offer opening deadline
  ];
  private static readonly DEADLINE_TODAY_ERROR_MSG =
    'Das angegebene Datum muss nach dem heutigen Tag liegen.';

  constructor(
    private dateUtils: DateUtilsService,
    private editorConfigurationProvider: EditorConfigurationProvider
  ) {
    super();
  }

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    const chosenDate = dateModel.asLocalDate();
    const tomorrow = this.dateUtils.getLocalDateOfTomorrow();
    if (!dateModel.isReadonly && dateModel.value && chosenDate.isBefore(tomorrow)) {
      return this.ofSingle(DeadlineTodayValidator.DEADLINE_TODAY_ERROR_MSG);
    }
    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return (
      this.editorConfigurationProvider.isProductionMode() &&
      DeadlineTodayValidator.BTS_FOR_DEADLINE_TODAY_VALIDATION.includes(model.noticeNode.id)
    );
  }
}
