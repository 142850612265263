import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldModel } from '../../../../view-model/field-model';
import { AbstractFormComponent } from '../../abstract-form/abstract-form.component';

@Component({
  selector: 'app-bt09-selector',
  templateUrl: './bt-09-selection.component.html',
  styleUrls: ['./bt-09-selection.component.scss'],
})
export class BT09SelectionComponent extends AbstractFormComponent implements OnInit, OnDestroy {
  enableCrossBorderLawControl: FormControl;

  model: FieldModel<string>;

  ngOnInit(): void {
    this.enableCrossBorderLawControl = new FormControl(false);
    this.enableCrossBorderLawControl.setValue(!!this.model.value);

    this.subscription.add(
      this.enableCrossBorderLawControl.valueChanges.subscribe(value => {
        this.model.value = value ? 'CrossBorderLaw' : null;
        this.onInputValueChanged();
      })
    );

    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => this.cdRef.markForCheck())
    );
  }
}
