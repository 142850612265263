import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { defer, Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { JwtService } from '../api/user-service';

@Injectable()
export class UserServiceJwtInterceptor implements HttpInterceptor {

  private jwt: string = undefined;

  private URLS_WITHOUT_JWT = [
    '/users/api/jwt',
    'assets/licences/license-report-npmjs.html',
    '/api/news',
    '/api/version'
  ];

  constructor(
    private jwtService: JwtService
  ) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.URLS_WITHOUT_JWT.includes(httpRequest.url)) {
      return next.handle(httpRequest);
    }

    /**
     * 1. In `updateRequest` im Header des aktuellen Requests den user-service-jwt setzen und Request abschicken
     * 2. Im Fehlerfall (Server antwortet mit Fehler oder `updateRequest` wirft Fehler) wird der retry-Mechanismus ausgefuehrt
     * 3. Retry-Mechanismus: Hole einen neuen JWT und versuche den urspruenglichen Request erneut (insg. max. 3 weitere Versuche)
     * 4. Nach 3 weiteren Versuchen: endgueltiger Fehler -> Fehlerdialog ueber HttpErrorInterceptor
     */
    const createRequest$ = defer(() => next.handle(this.updateRequest(httpRequest)));
    return createRequest$.pipe(
      retry({
        count: 3,
        delay: () => {
          return this.jwtService.getJwt().pipe(
            tap(jwt => this.jwt = jwt),
          );
        }
      }),
    );
  }

  private updateRequest(req: HttpRequest<any>): HttpRequest<any> {
    if (!this.jwt || !this.isJwtValid(this.jwt)) {
      throw new Error('JWT not found or expired');
    }

    return req.clone({
      headers: req.headers.set('x-user-service-jwt', this.jwt)
    });
  }

  private isJwtValid(jwt: string): boolean {
    const { exp } = jwtDecode(jwt);
    return Date.now() < exp * 1000;
  }
}
