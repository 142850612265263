<app-template [hasContentFrame]="true" [titel]="'Datenschutzerklärung'">
  <section>
    <p>
      Das Beschaffungsamt des BMI betreibt als nicht rechtsfähige Behörde der Bundesrepublik Deutschland hier unter folgender Domain das
      Redaktionssystem des Datenservice Öffentlicher Einkauf:
    </p>
    <p>
      <a href="https://resy.datenservice-oeffentlicher-einkauf.de" target="_blank">www.resy.datenservice-oeffentlicher-einkauf.de</a>
    </p>
    <p>
      Personenbezogene Daten werden von uns nur im notwendigen Umfang verarbeitet. Welche Daten zu welchem Zweck und auf welcher Grundlage
      benötigt und verarbeitet werden, richtet sich maßgeblich nach der Art der Leistung, die von Ihnen in Anspruch genommen wird,
      beziehungsweise ist abhängig davon, für welchen Zweck diese benötigt werden. Wir haben technische und organisatorische Maßnahmen
      getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz sowohl von uns als auch von unseren externen Dienstleistern
      beachtet werden. Die Verarbeitung personenbezogener Daten im BeschA erfolgt in Übereinstimmung mit der Europäischen
      Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">1. Grundlagen</h3>
    <section>
      <h4 style="font-weight: bold">1.1 Verantwortlicher und Datenschutzbeauftragter</h4>
      <p>
        Verantwortlich für die Verarbeitung von personenbezogenen Daten ist das:
      </p>
      <p><b>Beschaffungsamt des BMI</b></p>
      <p>
        Brühler Straße 3<br />
        53119 Bonn<br />
        Deutschland <br />
        Telefon: +49 22899 610-0<br />
        Telefax: +49 (0)22899 610-1610<br />
        E-Mail: <a href="mailto:poststelle@bescha.bund.de">poststelle@bescha.bund.de</a><br />
      </p>
      <p>
        Bei konkreten Fragen zum Schutz Ihrer Daten wenden Sie sich bitte an den Datenschutzbeauftragten im Beschaffungsamt des BMI:
      </p>
      <p><b>Beschaffungsamt des BMI</b></p>
      <p>
        Datenschutzbeauftragter -<br />
        Brühler Str. 3<br />
        53119 Bonn<br />
        Telefon: +49 (0)22899 610 - 0<br />
        E-Mail: <a href="mailto:datenschutz@bescha.bund.de">datenschutz@bescha.bund.de</a><br />
      </p>
      <p>
        Vergabestellen und Zuwendungsempfänger sind Verantwortliche für alle personenbezogenen Daten, die sie im Laufe ihrer Bekanntmachung
        verarbeiten. Bitte wenden Sie sich mit Fragen, die eine konkrete Bekanntmachung betreffen an die bekannt gegebene Stelle.
      </p>
    </section>
    <section>
      <h4 style="font-weight: bold">1.2 Personenbezogene Daten</h4>
      <p>
        Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
        Als identifizierbar wird eine natürliche Person angesehen, die direkt oder
        indirekt – insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
        Online-Kennung – identifiziert werden kann.
      </p>
    </section>
    <section>
      <h4 style="font-weight: bold">1.3 Minderjährigen Schutz</h4>
      <p>
        Personen unter 16 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns
        übermitteln. Sollten personenbezogene Daten von Kindern und Jugendlichen zum Besuch der Liegenschaft erforderlich sein, gilt Ziffer
        6.3. Die Daten werden nicht an Dritte weitergegeben.
      </p>
    </section>
    <section>
      <h4 style="font-weight: bold">1.4 Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
      <p>
        Das Beschaffungsamt des BMI (BeschA) verarbeitet bei der Wahrnehmung der ihm obliegenden im öffentlichen Interesse liegenden
        Aufgaben personenbezogene Daten. Zu den öffentlichen Aufgaben des BeschA gehört insbesondere auch die Öffentlichkeitsarbeit und
        dabei u.a. die Bereitstellung von Informationen für die Öffentlichkeit im Rahmen des vorliegenden Internetauftritts. Rechtsgrundlage
        der Verarbeitung ist hier
        Artikel 6 Abs. 1 Buchstabe e DSGVO in Verbindung mit der entsprechenden innerstaatlichen oder europäischen Aufgabennorm bzw. in
        Verbindung mit § 3 BDSG.
      </p>
      <p>
        Soweit eine Verarbeitung personenbezogener Daten im Einzelfall zur Erfüllung einer rechtlichen Verpflichtung, der das BeschA
        unterliegt, erforderlich sein sollte, dient zudem Artikel 6 Abs. 1 Buchstabe c DSGVO als Rechtsgrundlage.
        Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages erforderlich ist, dessen Vertragspartei die
        betroffene Person ist, dient im Einzelfall auch Artikel 6 Absatz 1 Buchstabe b DSGVO als Rechtsgrundlage. Dies gilt auch für
        Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Als zivilrechtliche Vertragspartei wird
        das BeschA insbesondere im Bereich der Personalgewinnung und Beschaffung tätig.
      </p>
      <p>
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung
        personenbezogener Daten erforderlich machen, ist Artikel 6 Absatz 1 Buchstabe d DSGVO die Rechtsgrundlage.
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses des BeschA oder eines Dritten erforderlich und überwiegen die
        Interessen, Grundrechte und Grundfreiheiten der betroffenen Person das erstgenannte Interesse nicht, so dient Artikel 6 Absatz 1
        Buchstabe f DSGVO als Rechtsgrundlage für die Verarbeitung.
      </p>
    </section>
  </section>
  <section>
    <h3 style="font-weight: bold">2. Datenverarbeitung im Zusammenhang mit dem Besuch dieser Internetseite</h3>
    <section>
      <h4 style="font-weight: bold">2.1 Datenerfassung</h4>
      <p>
        Bei jedem Zugriff eines Nutzers auf unser Internet-Angebot und bei jedem Abruf einer Datei werden Daten über diesen Vorgang
        vorübergehend in einer Protokolldatei verarbeitet. <br />
        Im Einzelnen werden über jeden Zugriff/Abruf folgende Daten gespeichert:
      </p>
      <ul>
        <li>Datum und Uhrzeit des Abrufs (Zeitstempel), sowie die IP-Adresse des zugreifenden Geräts bzw. Servers</li>
        <li>Anfragedetails und Zieladresse (Protokollversion, HTTP-Methode, Referrer, UserAgent-String),</li>
        <li>Name der abgerufenen Datei und übertragene Datenmenge (angefragte URL inkl. Query-String, Größe in Byte) sowie</li>
        <li>Meldung, ob der Abruf erfolgreich war (HTTP Status Code).</li>
      </ul>
      <p>
        Auf Grundlage des Artikel 6 Absatz 1 Buchstabe e DSGVO in Verbindung mit § 3 BDSG wertet das BeschA im Rahmen der
        Öffentlichkeitsarbeit und zur bedarfsorientierten Bereitstellung von Informationen zu den vom BeschA wahrzunehmenden Aufgaben
        Nutzungsinformationen zu statistischen Zwecken aus.
      </p>
      <p>
        Daten, die beim Zugriff auf das Internetangebot des BeschA protokolliert wurden, werden an Dritte nur übermittelt, soweit wir
        rechtlich dazu verpflichtet sind oder die Weitergabe im Falle von Angriffen auf die Kommunikationstechnik des Bundes zur Rechts-
        oder Strafverfolgung erforderlich ist. Eine Weitergabe in anderen Fällen erfolgt nicht. Eine Zusammenführung dieser Daten mit
        anderen Datenquellen erfolgt durch das BeschA nicht.
      </p>
      <p>
        Auf der Internetseite werden Sie über Links auch zu anderen Anbietern oder Web-Portalen geführt, die nicht zum BeschA gehören. Die
        Datenschutzerklärung des BeschA gilt nicht für die Webseiten, die Sie mittels Hyperlinks aufrufen können.
      </p>
    </section>
    <section>
      <h4 style="font-weight: bold">2.2 Cookies</h4>
      <p>
        Diese Website verwendet Cookies. Cookies sind kleine Informationseinheiten, die auf Ihrem Endgerät abgelegt werden. Diese werden
        dafür genutzt, Sie bzw. Ihr Gerät für die Sitzung (Session) - also die Zeit der Nutzung der Website und ihrer Funktionen -
        wiederzuerkennen. Folgende Cookies kommen zum Einsatz:
      </p>
      <ul>
        <li>
          <b>AUTH_SESSION_ID und AUTH_SESSION_ID_LEGACY <br />
            Zweck/Einsatz:</b> Wird bei Aufruf des Redaktionssystems gesetzt. Gewährleistung, dass alle Anfragen desselben Endgeräts an
          denselben
          Webserver weitergeleitet werden. Das *_LEGACY-Cookie wird gesetzt, um auch ältere Geräte weiterhin unterstützen zu können.
        </li>
        <li>
          <b>KEYCLOAK_IDENTITY und KEYCLOAK_IDENTITY_LEGACY <br />
            Zweck/Einsatz:</b> Wird nach Aufruf des Nutzerkontos gesetzt. Enthält ein Token mit der authentifizierten Nutzer-ID des
          Nutzerkontos. Das *_LEGACY-Cookie wird gesetzt, um auch ältere Geräte weiterhin unterstützen zu können.
        </li>
        <li>
          <b>KEYCLOAK_SESSION und KEYCLOAK_SESSION_LEGACY <br />
            Zweck/Einsatz:</b> Wird bei Aufruf des Nutzerkontos gesetzt. ID der aktuellen Browsersitzung des Bundesportals. Das
          *_LEGACY-Cookie wird gesetzt, um auch ältere Geräte weiterhin unterstützen zu können.
        </li>
      </ul>
      <p>
        <b>Gültigkeitsdauer:</b> Die Löschung der Cookies erfolgt nach Beendigung der Browser-Sitzung, spätestens jedoch nach 30 Minuten,
        wenn
        keine Aktivität in der Webanwendung erfolgt. Ihre Aktivität wird durch den Aufruf von Funktionen und Inhaltsseiten des
        Redaktionssystems festgestellt. In diesem Fall wird die Gültigkeit der Cookies jeweils um weitere 30 Minuten erneuert.
      </p>
      <p>
        Die eingesetzten Cookies sind gemäß § 25 Abs. 2 TDDDG als technisch erforderlich einzuordnen. Durch diese technisch notwendigen
        Cookies erhobenen Daten werden nicht zur Erstellung von Nutzerprofilen verwendet.
      </p>
      <p>
        Die Verarbeitung der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. e) DSGVO i.V.m. § 3 BDSG.
      </p>
      <p>
        <b>Hinweis:</b> Sie können sich mit jedem Internetbrowser anzeigen lassen, wenn Cookies gesetzt werden und was sie enthalten.
        Abhängig
        davon, welchen Browser Sie verwenden, können Sie bereits in Ihrem Browser einstellen, ob Sie Cookies generell zulassen, ob Sie nur
        bestimmte Cookies akzeptieren oder alle Cookies ablehnen wollen. Über Ihren Browser können Sie in der Regel auch einsehen, welche
        Cookies auf Ihrem Zugriffsgerät gespeichert sind, Sie können diese dann auch ganz oder teilweise löschen.
      </p>
    </section>
  </section>

  <section>
    <h3 style="font-weight: bold">3. Verarbeitung personenbezogener Daten im Rahmen der Kontaktaufnahme</h3>
    <p>
      Die Verarbeitung der personenbezogenen Daten geschieht in Abhängigkeit des Kontaktweges. Hierbei wird zwischen Kontaktaufnahme per
      E-Mail, per Brief oder Telefon unterschieden.
    </p>
    <section>
      <h4 style="font-weight: bold">3.1 Kontaktaufnahme mit dem BeschA per E-Mail</h4>
      <p>
        Die Kontaktaufnahme mit dem BeschA per E-Mail ist neben den personengebundenen dienstlichen E-Mail-Adressen der Beschäftigten und
        diverse Funktionspostfächern auch über diese zentrale E-Mail-Adresse möglich: <a href="mailto:poststelle@bescha.bund.de">poststelle@bescha.bund.de</a>.
        Die an die zentrale Adresse
        gesandten und in der für die zentrale Nachrichtenverteilung zuständigen Organisationseinheit gespeicherten personenbezogenen Daten
        werden nach der Weiterleitung an die zuständigen Organisationseinheiten des BeschA nach Wegfall des Verarbeitungszweckes bzw. der
        für
        die Aufbewahrung und Löschung geltenden gesetzlichen Pflichten gelöscht.
      </p>
      <p>
        In den Organisationseinheiten werden die von Ihnen übermittelten Daten (z.B.: Name, Vorname, Anschrift), zumindest jedoch die
        E-Mail-Adresse, sowie die in der E-Mail enthaltenen Informationen (inklusive ggf. von Ihnen übermittelter personenbezogener Daten)
        zum
        Zwecke der Kontaktaufnahme und Bearbeitung Ihres Anliegens gemäß den für die Aufbewahrung von Schriftgut geltenden Fristen
        gespeichert.
      </p>
      <p>
        Wir weisen Sie darauf hin, dass die Verarbeitung der Daten auf Grundlage von Artikel 6 Absatz 1 Buchstabe e DSGVO in Verbindung mit
        § 3 BDSG erfolgt. Eine Verarbeitung der von Ihnen übermittelten personenbezogenen Daten ist zum Zweck der Bearbeitung Ihres
        Anliegens erforderlich.
      </p>
    </section>
    <section>
      <h4 style="font-weight: bold">3.2 Kontaktaufnahme per Brief</h4>
      <p>
        Sofern Sie an das BeschA einen Brief schreiben, werden die von Ihnen übermittelten Daten (z.B.: Name, Vorname, Anschrift) und die im
        Brief enthaltenen Informationen (ggf. von Ihnen übermittelte personenbezogenen Daten) zum Zwecke der Kontaktaufnahme und Bearbeitung
        Ihres Anliegens gemäß den für die Aufbewahrung von Schriftgut geltenden Fristen gespeichert.
        Wir weisen Sie darauf hin, dass die Verarbeitung der Daten auf Grundlage von Artikel 6 Absatz 1 Buchstabe e DSGVO in Verbindung mit
        § 3 BDSG erfolgt. Eine Verarbeitung der von Ihnen übermittelten personenbezogenen Daten ist zum Zweck der Bearbeitung Ihres
        Anliegens erforderlich.
      </p>
    </section>
    <section>
      <h4 style="font-weight: bold">3.3 Kontaktaufnahme per Telefon</h4>
      <p>
        Wenn Sie mit einem/r Beschäftigten des BeschA per Telefon Kontakt aufnehmen, werden, soweit dies erforderlich ist, personenbezogene
        Daten von Ihnen zur Bearbeitung Ihres Anliegens erhoben und verarbeitet.
        Wir weisen Sie darauf hin, dass die Verarbeitung der Daten auf Grundlage von Artikel 6 Absatz 1 Buchstabe e DSGVO in Verbindung mit
        § 3 BDSG erfolgt. Eine Verarbeitung der von Ihnen übermittelten personenbezogenen Daten ist zum Zweck der Bearbeitung Ihres
        Anliegens erforderlich.
      </p>
    </section>
  </section>

  <section>
    <h3 style="font-weight: bold">4. Verarbeitung personenbezogener Daten im Rahmen von Geschäftsbeziehungen</h3>
    <section>
      <p>
        Wir verarbeiten personenbezogene Daten, die wir im Rahmen unserer Geschäftsbeziehung von Ihnen erhalten. Zudem verarbeiten wir -
        soweit für die Erbringung unserer Dienstleistung erforderlich - personenbezogene Daten, die wir aus öffentlich zugänglichen Quellen
        (Handels- und Vereinsregister, Gewerbezentralregister bzw. Wettbewerbsregister, Presse, Internet) zulässigerweise gewinnen oder die
        uns von anderen Behörden des Bundes und der Länder oder von sonstigen Dritten (z.B. Auskunfteien) berechtigt übermittelt werden.
      </p>
      <p>
        Relevante personenbezogene Daten sind Personalien (Name, Adresse und andere Kontaktdaten wie Telefonnummer und E-Mail-Adresse und
        IP).
        Wir speichern persönliche Daten im Zusammenhang mit Bekanntmachungen über ein Webformulare , Daten aus der Erfüllung unserer
        vertraglichen Verpflichtungen (z.B. Zahlungsverkehr), Dokumentationsdaten (z.B. über Fragen und Antworten) sowie Daten unserer
        Beschäftigten.Wir weisen Sie darauf hin, dass die Verarbeitung der Daten auf Grundlage von Artikel 6 Absatz 1 Buchstabe e DSGVO in
        Verbindung mit § 3 BDSG erfolgt. Eine Verarbeitung der von Ihnen übermittelten personenbezogenen Daten ist zum Zweck der Bearbeitung
        Ihres Anliegens erforderlich.
      </p>
      <h4 style="font-weight: bold">4.1 Daten, die bei der Registrierung erhoben werden</h4>
      <ul>
        <li>Genaue Bezeichnung der Organisation</li>
        <li>Vorname</li>
        <li>Nachname</li>
        <li>Telefonnummer</li>
        <li>E-Mail-Adresse</li>
      </ul>
    </section>
  </section>

  <section>
    <h3 style="font-weight: bold">5. Datenempfänger</h3>
    <p>
      Empfänger der personenbezogenen Daten sind nur die für die Bearbeitung der einzelnen Vorgänge zuständigen Personen und diejenigen, die
      ein berechtigtes Interesse durch ihre Beteiligung darlegen können. So bekommen insbesondere bei Beschaffungen nur Personen bei dem
      Bedarfsträger und der Vergabestelle Angebote zur Kenntnis. Außerdem können verantwortliche Personen des technischen Supports der
      Plattformen (Hosting durch GISA GmbH) sowie zuständige Datenschutzbeauftragte, Vorgesetzte, Qualitätsmanager, Innenrevisoren,
      Rechnungs- und Preisprüfer und Angehörige der Rechnungshöfe Einblick in Ihre Daten bekommen. In den Veröffentlichungen von
      Wettbewerbsergebnissen geben wir grundsätzlich nur Unternehmensdaten bekannt.
    </p>
  </section>

  <section>
    <h3 style="font-weight: bold">6. Datenspeicherung und Datenlöschung</h3>
    <p>
      Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange es für die Erfüllung unserer vertraglichen und gesetzlichen
      Pflichten erforderlich ist. Dabei ist zu beachten, dass einige unsere Geschäftsbeziehungen auf Jahre angelegte Dauerschuldverhältnisse
      sind.
    </p>
    <p>
      Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten nicht mehr erforderlich, werden sie regelmäßig gelöscht, es
      sei denn, deren - befristete - Weiterverarbeitung ist erforderlich zu folgenden Zwecken:
    </p>
    <p>
      1. Erfüllung handels- und steuerrechtlicher Aufbewahrungspflichten:
      Zu nennen sind das Handelsgesetzbuch (HGB), die Abgabenordnung (AO). Die dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation
      betragen zwei bis zehn Jahre. In Fällen der Beschaffung im Zusammenhang mit EU-geförderten
      Programmen beträgt die Aufbewahrungsfrist in der Regel 10 Jahre nach Ablauf des Förderprogramms.
    </p>
    <p>
      2. Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften. Nach den §§ 195 ff. des Bürgerlichen Gesetzbuches
      (BGB) können diese Verjährungsfristen bis zu 30 Jahre betragen, wobei die regelmäßige Verjährungsfrist drei Jahre
      beträgt.
    </p>
  </section>

  <section>
    <h3 style="font-weight: bold">7. Einsatz automatisierter Entscheidungsfindung</h3>
    <p>
      Im Zusammenhang mit dem Betrieb dieser Webseite nutzen wir grundsätzlich keine automatisierte Entscheidungsfindung einschließlich
      Profiling im Sinne von Artikel 22 DSGVO. Sofern wir derartige Verfahren in Einzelfällen einsetzen, werden wir Sie hierüber im
      rechtlich vorgesehenen Umfang gesondert informieren.
    </p>
  </section>

  <section>
    <h3 style="font-weight: bold">8. Ihre Rechte</h3>
    <p>
      Sie haben gegenüber dem BeschA folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
    </p>
    <ul>
      <li>
        <b>Recht auf Auskunft, Art. 15 DSGVO</b> <br />
        Mit dem Recht auf Auskunft erhält der Betroffene eine umfassende Einsicht in die ihn angehenden Daten und einige andere wichtige
        Kriterien wie beispielsweise die Verarbeitungszwecke oder die Dauer der Speicherung. Es gelten die in § 34 BDSG geregelten Ausnahmen
        von diesem Recht.
      </li>
      <li>
        <b>Recht auf Berichtigung, Art. 16 DSGVO</b> <br />
        Das Recht auf Berichtigung beinhaltet die Möglichkeit für den Betroffenen, unrichtige oder unvollständige ihn angehende
        personenbezogene Daten korrigieren zu lassen
      </li>
      <li>
        <b>Recht auf Löschung, Art. 17 DSGVO</b> <br />
        Das Recht auf Löschung beinhaltet die Möglichkeit für den Betroffenen, Daten beim Verantwortlichen löschen zu lassen. Dies ist
        allerdings nur dann möglich, wenn die ihn angehenden personenbezogenen Daten nicht mehr notwendig sind, rechtswidrig verarbeitet
        werden oder eine diesbezügliche Einwilligung widerrufen wurde. Es gelten die in § 35 BDSG geregelten Ausnahmen von diesem Recht.
      </li>
      <li>
        <b>Recht auf Einschränkung der Verarbeitung, Art. 18 DSGVO</b> <br />
        Das Recht auf Einschränkung der Verarbeitung beinhaltet die Möglichkeit für den Betroffenen, eine weitere Verarbeitung der ihn
        angehenden personenbezogenen Daten vorerst zu verhindern. Eine Einschränkung tritt vor allem in der Prüfungsphase anderer
        Rechtewahrnehmungen durch den Betroffenen ein.
      </li>
      <li>
        <b>Recht auf Datenübertragbarkeit, Art. 20 DSGVO</b> <br />
        Das Recht auf Datenübertragbarkeit beinhaltet die Möglichkeit für den Betroffenen, die ihn angehenden personenbezogenen Daten in
        einem gängigen, maschinenlesbaren Format vom Verantwortlichen zu erhalten, um sie ggf. an einen anderen Verantwortlichen
        weiterleiten zu lassen. Gemäß Art. 20 Abs. 3 Satz 2 DSGVO steht dieses Recht aber dann nicht zur Verfügung, wenn die
        Datenverarbeitung der Wahrnehmung öffentlicher Aufgaben dient.
      </li>
      <li>
        <b>Recht auf Widerruf der Einwilligung, Art.7 Abs.3 DSGVO</b> <br />
        Soweit die Verarbeitung der personenbezogenen Daten auf Grundlage einer Einwilligung erfolgt, kann der Betroffene diese jederzeit
        für den entsprechenden Zweck widerrufen. Die Rechtmäßigkeit der Verarbeitung aufgrund der getätigten Einwilligung bleibt bis zum
        Eingang des Widerrufs unberührt.
      </li>
      <li>
        <b>Recht auf Widerspruch gegen die Erhebung, Verarbeitung und bzw. oder Nutzung, Art. 21 DSGVO</b> <br />
        Das Recht auf Widerspruch beinhaltet die Möglichkeit, für Betroffene, in einer besonderen Situation der weiteren Verarbeitung ihrer
        personenbezogenen Daten zu widersprechen, soweit diese durch die Wahrnehmung öffentlicher Aufgaben oder öffentlicher sowie privater
        Interessen rechtfertigt ist. Es gelten die in § 36 BDSG geregelten Ausnahmen von diesem Recht.
      </li>
    </ul>
    <p>
      Zur Ausübung der vorgenannten Rechte können Sie sich schriftlich an die unter Ziffer 1.1 genannten Stellen wenden. Ihnen steht zudem
      gemäß Art. 77 DSGVO ein Beschwerderecht bei der datenschutzrechtlichen Aufsichtsbehörde, dem Bundesbeauftragten für den Datenschutz
      und die Informationsfreiheit, zu. Sie können sich mit Fragen und Beschwerden auch an den unter Ziffer 1.1 genannten
      Datenschutzbeauftragten im BeschA wenden.
    </p>
  </section>
</app-template>
