import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RsEditorConfiguration } from '../types/rs-editor-configuration';
import { SdkType } from '../types/sdk-type';

@Injectable({
  providedIn: 'root',
})
export class RsEditorConfigurationProvider {
  private editorConfiguration: RsEditorConfiguration;

  getSubTypeId(): string {
    return this.editorConfiguration.subTypeId;
  }

  getSdk(): SdkType {
    return ['T01', 'T02'].includes(this.editorConfiguration.subTypeId) ? SdkType.EU : SdkType.DE;
  }

  isTemplateMode(): boolean {
    return this.editorConfiguration.isTemplateMode;
  }

  getRsTemplateId(): number {
    return this.editorConfiguration.rsTemplateId;
  }

  getRsNoticeId(): number {
    return this.editorConfiguration.rsNoticeId;
  }

  getBaseRsNoticeId(): number {
    return this.editorConfiguration.baseRsNoticeId;
  }

  public setConfigurationFromRoute(params: Params, queryParams: Params) {
    this.readConfiguration(params, queryParams);
  }

  private readConfiguration(params: Params, queryParams: Params) {
    if (!params.subTypeId) {
      console.error('Parameters missing or incomplete');
    }

    this.editorConfiguration = {
      subTypeId: params.subTypeId,
      isTemplateMode: queryParams.isTemplate === 'true',
      rsTemplateId: queryParams.rsTemplateId ? +queryParams.rsTemplateId : null,
      rsNoticeId: queryParams.rsNoticeId ? +queryParams.rsNoticeId : null,
      baseRsNoticeId: queryParams.baseRsNoticeId ? +queryParams.baseRsNoticeId : null,
    };
  }
}
