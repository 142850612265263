import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { combineLatest, defer, from, iif, Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GenericDialogService } from '../../services/ui/generic-dialog.service';
import { NoticeControllerService } from '../api/notices';
import { ConceptModel, TemplateOverviewResponse, VorlagenService } from '../api/templates';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { NavigationUtil } from '../shared/navigation.util';
import { StateService } from '../shared/state.service';
import { VorlagenNameDialogComponent } from './vorlagen-name-dialog/vorlagen-name-dialog.component';
import { initialState, VorlagenVerwaltungState } from './vorlagen-verwaltung-state.model';

@Injectable({
  providedIn: 'root',
})
export class VorlagenVerwaltungStateService extends StateService<VorlagenVerwaltungState> {

  loading$: Observable<boolean> = this.select(state => state.loading);
  loggedInUser$: Observable<KeycloakProfile> = this.select(state => state.loggedInUser).pipe(filter(Boolean));
  selectedUserId$: Observable<string> = this.select(state => state.selectedUserId);
  iAmOwner$: Observable<boolean> = combineLatest([this.loggedInUser$, this.selectedUserId$]).pipe(map(([loggedInUser, selectedId]) => loggedInUser.id === selectedId));
  templates$: Observable<TemplateOverviewResponse[]> = this.select(state => state.templates);

  private keycloak = inject(KeycloakService);
  private vorlagenService = inject(VorlagenService);
  private noticeControllerService = inject(NoticeControllerService);
  private genericDialogService = inject(GenericDialogService);
  private loadingSpinnerService = inject(LoadingSpinnerService);
  private router = inject(Router);

  constructor() {
    super(initialState);
  }

  init(): void {
    this.setState(initialState);

    this.doWithLoading(
      from(this.keycloak.loadUserProfile()).pipe(
        tap(loggedInUser => this.setState({ loggedInUser, selectedUserId: loggedInUser.id })),
        switchMap(loggedInUser => this.vorlagenService.getUsersTemplates(loggedInUser.id)),
        tap(templates => this.setState({ templates })),
      )
    ).subscribe();
  }

  selectUser(selectedUserId: string): void {
    this.setState({ selectedUserId });
    this.doWithLoading(
      this.vorlagenService.getUsersTemplates(selectedUserId)
    ).subscribe(templates => this.setState({ templates }));
  }

  showUpdateDialog(templateId: number, dialogComponent: VorlagenNameDialogComponent): void {
    const dialogRef = dialogComponent.openDialog(this.state.templates.find(template => template.templateId === templateId));

    dialogComponent.confirm.pipe(
      takeUntil(dialogRef.afterClosed()),
      switchMap(confirmResult => this.vorlagenService.updateTemplateMetaDataById(templateId, { name: confirmResult.metadata.name, description: confirmResult.metadata.description }).pipe(
        tap(() => {
          this.setState({
            templates: this.state.templates.map(template => {
              const { name, description } = confirmResult.metadata;
              return template.templateId === templateId
                ? { ...template, name, description }
                : template;
            })
          });
        }),
      )),
    ).subscribe();
  }

  showCopyDialog(templateId: number, dialogComponent: VorlagenNameDialogComponent): void {
    const originalTemplate = this.state.templates.find(template => template.templateId === templateId);
    const dialogRef = dialogComponent.openDialog({
      ...originalTemplate,
      templateId: undefined,
      name: `Kopie von ${originalTemplate.name}`,
    });

    dialogComponent.confirm.pipe(
      takeUntil(dialogRef.afterClosed()),
      switchMap(confirmResult => this.vorlagenService.copyTemplateById(templateId, { name: confirmResult.metadata.name, description: confirmResult.metadata.description })),
      tap(response => {
        if (this.state.loggedInUser?.id === this.state.selectedUserId) {
          this.setState({
            templates: [...this.state.templates, response],
          });
        } else {
          this.selectUser(this.state.loggedInUser.id);
        }
      }),
    ).subscribe();
  }

  createDraftFromTemplate(templateId: number): void {
    this.loadingSpinnerService.start();
    this.vorlagenService.getTemplateDataById(templateId).pipe(
      map(json => json as unknown as ConceptModel),
      map(conceptModel => ({
        content: {
          noticeSubTypeCode: conceptModel.noticeSubTypeCode,
          root: conceptModel.root,
        },
        metadata: null,
      })),
      switchMap(dto => this.noticeControllerService.createNoticeForNewProcedure(dto, 'body').pipe(
        tap(rsNoticeId => {
          if (rsNoticeId) {
            this.router.navigate(
              [
                'bekanntmachungen',
                'anlegen',
                'notice',
                dto.content.noticeSubTypeCode,
                'details',
              ],
              {
                queryParams: NavigationUtil.buildQueryParamsEditNewNotice(rsNoticeId),
              }
            );
          }
        }),
      )),
      finalize(() => this.loadingSpinnerService.stop()),
    ).subscribe();
  }

  editTemplate(templateId: number, activatedRoute: ActivatedRoute): void {
    const template = this.state.templates.find(template => template.templateId === templateId);
    iif(
      () => template.isLatestSDKVersion,
      of(true),
      defer(() => this.genericDialogService.confirmWithWarningDialog(
        'WARNUNG: Aktualisierung des eForms-Standards',
        'Akzeptieren',
        'Abbrechen',
        '<b>Grund der Warnung</b><br>' +
        'Der eForms-Standard wurde aktualisiert, seit diese Vorlage das letzte Mal bearbeitet wurde.<br><br>' +
        '<b>Was ist zu beachten?</b><br>' +
        'Nach dem Öffnen können Sie die Vorlage wie gewohnt anzeigen und bearbeiten. Bitte überprüfen ' +
        'Sie vor dem Speichern der Vorlage, ob alle wichtigen Daten vorhanden sind. Unter Umständen ' +
        'kann es bei der Aktualisierung des eForms-Standards zu einem teilweisen Datenverlust kommen.'
      )),
    ).pipe(
      filter(Boolean),
      tap(() => {
        this.router.navigate(['notice', template.noticeSubTypeCode, 'details'], {
          relativeTo: activatedRoute,
          queryParams: NavigationUtil.buildQueryParamsTemplate(!template.isLatestSDKVersion, templateId),
        });
      })
    ).subscribe();
  }

  removeTemplate(templateId: number): void {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Vorlage löschen',
      'Vorlage löschen',
      'Abbrechen',
      'Sind Sie sicher, dass Sie die Vorlage löschen möchten?'
    );

    from(confirmationResult).pipe(
      filter(confirmed => confirmed),
      switchMap(() => this.doWithLoading(
        this.vorlagenService.deleteTemplateById(templateId).pipe(
          tap(() => {
            this.setState({
              templates: this.state.templates.filter(template => template.templateId !== templateId),
            });
          }),
        )
      )),
    ).subscribe();
  }

  private doWithLoading<T>(action$: Observable<T>): Observable<T> {
    this.setState({ loading: true });
    return action$.pipe(
      finalize(() => this.setState({ loading: false })),
    );
  }

}
