import { FieldModel } from '../../view-model/field-model';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { ModelListener } from './ModelListener';
import { SectionModel } from '../../view-model/section-model';
import { BaseModel } from '../../view-model/base-model';
import { MultilingualModel } from '../../view-model/type/multilingual-model';
import { CaptionService } from '../view-model/caption.service';
import { GroupModel } from '../../view-model/group-model';
import { ComponentModel } from '../../view-model/component-model';

/**
 * Listens for Lot-Description Changes and Updates the Name of the Lot in the Sidebar
 */
export class CaptionValueChangedListener extends ModelListener<FieldModel<any>> {
  constructor(private captionService: CaptionService) {
    super();
  }

  onChange(model: FieldModel<any>): void {
    const captionValue = this.getCaptionValue(model);
    const targetFieldId = this.captionService.getTargetFieldId(model.noticeNode.id);
    const parentField = this.getParentNode(targetFieldId, model);
    if (!parentField) {
      throw new Error(
        'can not find parent node ' + targetFieldId + ' within child node ' + model.noticeNode.id
      );
    }

    if (parentField instanceof GroupModel || parentField instanceof SectionModel) {
      const groupModel = parentField;
      groupModel.customTitle = captionValue;
      (groupModel.parent as BaseModel).emitChange();
    }
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return this.captionService.isCaptionForAnyField(model?.noticeNode?.id);
  }

  private getCaptionValue(model: FieldModel<any>): string {
    if (!model) {
      return;
    }

    if (model instanceof MultilingualModel) {
      return model.value.DEU ?? model.value.ENG ?? undefined;
    } else if (model instanceof CodelistModel) {
      return model.codeList?.find(option => option.id === model.value)?.label;
    } else {
      return model.value ?? undefined;
    }
  }

  private getParentNode(id: string, model: BaseModel): BaseModel {
    if (model.parent && !(model.parent instanceof ComponentModel)) {
      const parent = model.parent as BaseModel;
      if (parent.noticeNode.id === id) {
        return parent;
      }
      return this.getParentNode(id, parent);
    }
    return undefined;
  }
}
