import { Injectable } from '@angular/core';
import { SectionModel } from '../view-model/section-model';
import prefillingFields from './prefilling-fields.json';
import { ModelStateService } from './view-model/model-state.service';
import { FieldModel } from '../view-model/field-model';
import { RepeatableSectionModel } from '../view-model/repeatable-section-model';
import { ModelListenerService } from './view-model/model-listener.service';
import { MultilingualModel } from '../view-model/type/multilingual-model';
import { RepeatableFieldModel } from '../view-model/repeatable-field-model';

@Injectable()
export class PrefillFieldsService {
  constructor(private modelListenerService: ModelListenerService) {}

  public async prefillFields(
    sourceSection: SectionModel,
    targetSection: RepeatableSectionModel,
    selectedFields: string[]
  ) {
    const fields = this.getFields(targetSection, selectedFields);

    for (const bt in fields) {
      const sourceModel: FieldModel<any> = ModelStateService.findModelById(
        sourceSection,
        fields[bt]
      );
      const targetModel: FieldModel<any> = ModelStateService.findModelById(targetSection, bt);
      if (!targetModel || !sourceModel) {
        continue;
      }
      await this.setValue(sourceModel, targetModel);
      await this.modelListenerService.onModelValueChange(targetModel);
    }
  }

  private async setValue(sourceModel: FieldModel<any>, targetModel: FieldModel<any>) {
    if (targetModel instanceof MultilingualModel) {
      this.setMultiLingualValue(sourceModel as MultilingualModel, targetModel);
      targetModel.emitChange();
    } else if (targetModel instanceof RepeatableFieldModel) {
      targetModel.children[0].value = this.getValueFromRepeatable(
        sourceModel as RepeatableFieldModel<any>
      );
      targetModel.children[0]?.emitChange();
    } else {
      targetModel.value = sourceModel.value;
      targetModel.emitChange();
    }
  }

  private setMultiLingualValue(sourceModel: MultilingualModel, targetModel: MultilingualModel) {
    targetModel.englishValue = sourceModel.englishValue;
    targetModel.germanValue = sourceModel.germanValue;
  }

  private getValueFromRepeatable(sourceModel: RepeatableFieldModel<any>): string {
    return sourceModel.children[0].value;
  }

  private getFields(targetSection: RepeatableSectionModel, selectedFields: string[]) {
    const jsonFields =
      targetSection.noticeNode._idScheme === 'LOT' ? prefillingFields.Lot : prefillingFields.Part;

    const filteredFields = Object.entries(jsonFields).filter(([key]) =>
      selectedFields.some(bt => key.includes(bt))
    );
    return Object.fromEntries(filteredFields);
  }
}
