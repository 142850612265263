import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EditorConfigurationProvider } from '../../../../../../services/editor-configuration.provider';
import { Contact } from '../../../../../../types/contact';
import { ContactService } from '../../../../../../services/contact.service';
import { DialogComponent } from '../../../../../layout/dialog/dialog.component';
import { CodelistModel } from '../../../../../../view-model/type/codelist-model';
import { CodelistModelService } from '../../../../../../services/ui/codelist-model.service';
import { NoticeNode } from '../../../../../../types/notice-definition';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Severity } from '../../../../../../types/app-types';
import { PostalCodeService } from '../../../../../../services/postal-code.service';
import { nutsCodeValidator } from '../../../../../directives/valid-nuts-code-address-book/valid-nuts-code.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['/change-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeAddressComponent implements OnInit, OnDestroy {
  @ViewChild('changeAddressDialog') changeAddressDialog: DialogComponent;
  @Input() importedContact: Contact;
  @Output() contactsEmitter = new EventEmitter<Contact[]>();
  contactInputForm = new FormGroup({
    officialName: new FormControl('', Validators.required),
    nationalId: new FormControl(),
    address: new FormControl(),
    town: new FormControl(),
    nuts: new FormControl(null),
    postalCode: new FormControl(),
    contactPoint: new FormControl(),
    phone: new FormControl(
      '',
      Validators.pattern(
        '^((\\(\\+?[0-9]+\\))|\\+?[0-9]+)( - |-| )?(((\\(\\d+\\))|\\d+)( - |-| )?)*(\\d+)( )?$'
      )
    ),
    fax: new FormControl(
      '',
      Validators.pattern(
        '^((\\(\\+?[0-9]+\\))|\\+?[0-9]+)( - |-| )?(((\\(\\d+\\))|\\d+)( - |-| )?)*(\\d+)( )?$'
      )
    ),
    email: new FormControl(
      '',
      Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,63}$')
    ),
    url: new FormControl(
      '',
      Validators.pattern(
        '^((((H|h)(T|t)|(F|f))(T|t)(P|p)((S|s)?)):(/){2})(www\\.)?([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,6}(\\W(.)*$|$)'
      )
    ),
    urlBuyer: new FormControl(
      '',
      Validators.pattern(
        '^((((H|h)(T|t)|(F|f))(T|t)(P|p)((S|s)?)):(/){2})(www\\.)?([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,6}(\\W(.)*$|$)'
      )
    ),
  });
  isCopy = false;
  codeListModelLand: CodelistModel;
  codeListModelNUTS: CodelistModel;
  codeListLand: { id: string; label: string }[];
  codeListNUTS: { id: string; label: string }[];
  visibleItemsNUTS: { id: string; label: string }[] = [];
  noSearchResult = false;
  insufficientInput = false;
  private subscription = new Subscription();
  isRS: boolean;

  constructor(
    private contactService: ContactService,
    private codeListModelService: CodelistModelService,
    private postalCodeService: PostalCodeService,
    private editorConfigurationProvider: EditorConfigurationProvider,
  ) {}

  async ngOnInit() {
    this.isRS = this.editorConfigurationProvider.isRS();
    await this.loadCodeLists();

    this.contactInputForm.controls.nuts.setValidators([nutsCodeValidator(this.codeListNUTS)]);

    this.importContact();
    this.registerFormValidityListener();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async loadCodeLists() {
    this.codeListModelLand = new CodelistModel(this.codeListModelService);
    this.codeListModelLand.noticeNode = { id: 'BT-514-Organization-Company' } as NoticeNode;
    await this.codeListModelLand.loadSdkProperties();
    this.codeListLand = this.codeListModelLand.codeList;

    this.codeListModelNUTS = new CodelistModel(this.codeListModelService);
    this.codeListModelNUTS.noticeNode = { id: 'BT-507-Organization-Company' } as NoticeNode;
    await this.codeListModelNUTS.loadSdkProperties();
    this.codeListNUTS = this.codeListModelNUTS.codeList;
  }

  openChangeAddressDialog(mode: boolean, title: string) {
    this.changeAddressDialog.title = title;
    this.isCopy = mode;
    this.changeAddressDialog.openDialog();
  }

  closeChangeAddressDialog() {
    this.importContact();
  }

  importContact() {
    Object.keys(this.contactInputForm.controls).forEach(controlName => {
      const contactValue = this.importedContact[controlName];
      if (controlName === 'nuts') {
        const listItem = this.getItemFromNutsCodeList(contactValue);
        this.setValueToControl(controlName, listItem, contactValue);
      } else {
        this.setValueToControl(controlName, null, contactValue);
      }
    });

    this.codeListModelLand.value = this.importedContact?.country;
  }

  getItemFromNutsCodeList(contactValue: string): { id: string; label: string } {
    return this.codeListNUTS?.find(item => item?.id === contactValue);
  }

  setValueToControl(
    controlName: string,
    listItem: { id: string; label: string },
    contactValue: string
  ) {
    if (listItem) {
      this.contactInputForm.get(controlName).setValue(listItem);
    } else {
      this.contactInputForm.get(controlName).setValue(contactValue);
    }
  }

  getCurrentContact(): Contact {
    const currentContact = new Contact();
    Object.keys(this.contactInputForm.controls).forEach(controlName => {
      this.setValueToContact(currentContact, controlName);
    });
    currentContact.country = this.codeListModelLand.value;
    return currentContact;
  }

  setValueToContact(currentContact: Contact, controlName: string) {
    const value = this.contactInputForm.get(controlName).value;
    if (controlName === 'nuts') {
      currentContact[controlName] = value?.id ? value.id : value;
    } else {
      currentContact[controlName] = value;
    }
  }

  saveContactChanges() {
    if (this.isCopy) {
      this.copyContact();
    } else {
      this.changeContact();
    }
    this.importContact();
  }

  changeContact() {
    const contacts = this.contactService.editContact(
      this.getCurrentContact(),
      this.importedContact
    );
    this.contactsEmitter.emit(contacts);
  }

  copyContact() {
    const contacts = this.contactService.copyContact(this.getCurrentContact());
    this.contactsEmitter.emit(contacts);
  }

  displayNUTS(value: any) {
    if (!value?.id) {
      return value;
    }
    return value.id;
  }

  onNUTSSearch() {
    const searchItem = this.contactInputForm.get('nuts').value;
    if (!searchItem || typeof searchItem !== 'string') {
      this.noSearchResult = true;
      this.visibleItemsNUTS = [];
    }

    const searchedValue = searchItem.toString().toLowerCase();

    if (searchedValue.length < 3) {
      this.insufficientInput = true;
      this.noSearchResult = false;
      this.visibleItemsNUTS = [];
      return;
    }

    this.insufficientInput = false;
    const nuts = this.findNutsCodeForPostalCode(searchedValue);

    this.visibleItemsNUTS = this.codeListNUTS.filter(
      item =>
        item.id.toLowerCase().includes(nuts ? nuts.toLowerCase() : searchedValue) ||
        item.label.toLowerCase().includes(searchedValue)
    );

    if (this.visibleItemsNUTS.length === 0) {
      this.noSearchResult = true;
      return;
    }
    this.noSearchResult = false;
  }

  registerFormValidityListener() {
    if (this.contactInputForm.invalid) {
      this.changeAddressDialog.disableAcceptButton();
    }
    this.subscription.add(
      this.contactInputForm.statusChanges.subscribe(status => {
        if (status === 'INVALID') {
          this.changeAddressDialog.disableAcceptButton();
        } else {
          this.changeAddressDialog.enableAcceptButton();
        }
      })
    );
  }

  getLabel(codeListItems: { id: string; label: string }[], control: AbstractControl): string {
    const controlValue = control.value?.id ? control.value.id : control.value;
    const label = codeListItems?.find(item => item.id === controlValue)?.label;
    return label ? label : '';
  }

  private findNutsCodeForPostalCode(postalCode: string) {
    const nuts = this.postalCodeService.getNutsCodeForPostalCode(postalCode);
    return nuts ? this.codeListNUTS.find(item => item.id === nuts).id : null;
  }

  protected readonly Severity = Severity;
}
