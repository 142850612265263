import { inject, Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { combineLatest, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateService } from '../shared/state.service';
import { GroupMemberModel, GroupMemberRole, GroupModel, GroupsService } from '../api/user-service';
import { GruppenUtils } from './gruppen.utils';
import { InvitationModel} from './model/invitation.model';

interface GroupState {
  groups: GroupModel[];
  filteredGroups: GroupModel[];
  groupsLoading: boolean;
  invitations: InvitationModel[];
  invitationsLoading: boolean;
  filter: string;
}

const initialState: GroupState = {
  groups: [],
  filteredGroups: [],
  groupsLoading: false,
  invitations: [],
  invitationsLoading: false,
  filter: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class GruppenService extends StateService<GroupState> {
  groupsLoading$: Observable<boolean> = this.select(state => state.groupsLoading);
  groups$: Observable<GroupModel[]> = this.select(state => state.groups);

  filter$: Observable<string> = this.select(state => state.filter);
  filteredGroups$: Observable<GroupModel[]> = combineLatest([this.groups$, this.filter$]).pipe(
    map(([groups, filter]) => this.filterGroups(groups, filter)),
  );

  invitationsLoading$: Observable<boolean> = this.select(state => state.invitationsLoading);
  invitations$: Observable<InvitationModel[]> = this.select(state => state.invitations);

  private lastGroupsAndInvitationsRaw: GroupModel[] = [];

  private keycloak = inject(KeycloakService);
  private groupsService = inject(GroupsService);

  constructor() {
    super(initialState);
  }

  init(): void {
    this.setState(initialState);
  }

  loadGroupsAndInvitations(): void {
    this.setState({ groupsLoading: true, invitationsLoading: true });
    const userdata$ = from(this.keycloak.loadUserProfile());
    const groups$ = this.groupsService.getGroups();

    combineLatest([userdata$, groups$]).subscribe(([userdata, groups]) => {
      this.lastGroupsAndInvitationsRaw = groups;

      const memberGroups: GroupModel[] = GruppenUtils.findGroupsWithMembership(groups, userdata.id);
      const invitedGroups: GroupModel[] = GruppenUtils.findGroupsWithInvitation(groups, userdata.id);

      // convert invitedGroups from GroupModel to InvitationModel
      const invitations: InvitationModel[] = this.groupModelsToInvitationModelsMapping(invitedGroups, userdata.id);

      this.setState({
        groups: memberGroups, groupsLoading: false, invitations, invitationsLoading: false
      })
    })
  }

  setFilter(searchTerm: string): void {
    this.setState({ filter: searchTerm });
  }

  createGroup(gruppe: GroupModel): void {
    this.groupsService.createGroup(gruppe)
      .subscribe(createdGroup =>
        this.setState({
          groups: [
            ...this.state.groups,
            createdGroup
          ],
        })
      );
  }

  updateGroup(gruppe: GroupModel): void {
    this.groupsService.updateGroup(gruppe.id, gruppe).subscribe(() =>
      this.setState({
        groups: this.state.groups.map(group => (group.id === gruppe.id ? gruppe : group)),
    }));
  }

  async leaveGroup(gruppe: GroupModel): Promise<void> {
    const userdata = await this.keycloak.loadUserProfile();
    const member = GruppenUtils.findInGroup(gruppe, userdata.id);
    this.groupsService.deleteGroupMember(gruppe.id, member.id).subscribe(() =>
      this.setState({
        groups: this.state.groups.filter(group => group.id !== gruppe.id) })
    )
  }

  removeGroup(gruppe: GroupModel): void {
    this.groupsService.deleteGroup(gruppe.id).subscribe( () =>
      this.setState({
        groups: this.state.groups.filter(group => group.id !== gruppe.id)
      })
    )
  }

  addInvitedMemberToGroup(groupId: number, invitedMember: GroupMemberModel): void {
    this.setState({
      groups: this.state.groups.map(group => (
          group.id === groupId ?
            { ...group, members: [...group.members, invitedMember] } :
            group
        )
      ),
    });
  }

  removeMember(memberId: number, groupId: number): void {
    this.groupsService.deleteGroupMember(groupId, memberId).subscribe( () =>
      this.setState({
        groups: this.state.groups.map(group => {
          if (group.id === groupId) {
            return { ...group, members: group.members.filter(m => m.id !== memberId) };
          }
          return group;
        }),
      })
    )
  }

  transferOwnership(memberId: number, groupId: number): void {
    const involvedGroup = this.state.groups.find(group => group.id === groupId);
    const currentOwner = GruppenUtils.findOwner(involvedGroup);

    this.groupsService.transferOwnership(groupId, memberId).subscribe(() => {
      involvedGroup.members = involvedGroup.members.map(member => {
        if (member.userId === currentOwner.userId) {
          return { ...member, role: GroupMemberRole.Member };
        } else if (member.id === memberId) {
          return { ...member, role: GroupMemberRole.Owner };
        }
        return member;
      });

      this.setState({
        groups: this.state.groups.map(group => group.id === groupId ? involvedGroup : group),
      });
    });
  }

  withdrawInvitation(memberId: number, groupId: number): void {
    this.groupsService.deleteGroupMember(groupId, memberId).subscribe( () =>
      this.setState({
        groups: this.state.groups.map(group => {
          if (group.id === groupId) {
            return { ...group, members: group.members.filter(m => m.id !== memberId) };
          }
          return group;
        }),
      })
    )
  }

  acceptInvitation(groupId: number, invitationId: number): void {
    this.groupsService.acceptInvitation( groupId, invitationId).subscribe( () => {
      const involvedGroup = this.lastGroupsAndInvitationsRaw.find(group => group.id === groupId)
      involvedGroup.members = involvedGroup.members.map(member => member.id === invitationId ?
        { ...member, role: GroupMemberRole.Member } : member)

      this.setState({
        groups: [ ...this.state.groups, this.lastGroupsAndInvitationsRaw.find(group => group.id === groupId) ],
        invitations: this.state.invitations.filter(invitation => invitation.id !== invitationId),
      })
    });
  }

  declineInvitation(groupId: number, invitationId: number): void {
    this.groupsService.deleteGroupMember(groupId, invitationId).subscribe( () =>
      this.setState({ invitations: this.state.invitations.filter(invitation => invitation.id !== invitationId) })
    );
  }

  private groupModelToInvitationModelMapping(group: GroupModel, userId): InvitationModel {
    const me = group.members.find(member => member.userId === userId);
    const inviter = group.members.find(member => member.role === GroupMemberRole.Owner)
    return {
      id: me.id,
      groupId: group.id,
      groupName: group.name,
      datetime: me.creationDate,
      invitedBy: {
        userId: inviter.userId,
        username: inviter.username,
        name: inviter.name,
        organization: inviter.organisation,
      }
    }
  }

  private groupModelsToInvitationModelsMapping(groups: GroupModel[], userId): InvitationModel[] {
    return groups.map(group => this.groupModelToInvitationModelMapping(group, userId))
  }

  private filterGroups(groups: GroupModel[], filter: string): GroupModel[] {
    return !filter ? groups : GruppenUtils.filterByGroupnameOrUsernames(groups, filter.toLowerCase());
  }
}
