import { createReducer, on } from '@ngrx/store';
import * as ProcedureTypeActions from './procedure-type.action';

/**
 * Reducer for the name of the active procedure type
 */
export const procedureTypeReducer = createReducer(
  '',
  on(ProcedureTypeActions.update, (_state, props) => props.name)
);
