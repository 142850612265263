<div [hidden]="model.isHidden" class="wrapper">
  <div class="language-text-area-input">
    <span class="language-text-area-span form-component-icon">{{ label }} </span>
    <textarea
      [id]="htmlId"
      [formControl]="control"
      (blur)="validate()"
      class="form-textarea"
      [attr.aria-label]="model.translatedLabel + ' ' + label"
      [class.has-error]="hasInputError()"
      [class.required]="model.isMandatory && !model.isReadonly"
      [appMaxLengthBadge]="maxLength"
      appTrimAndMapEmptyToNull
      rows="2">
    </textarea>
  </div>
  <ng-container *ngIf="!model.isHidden">
    <app-validation-message [model]="model" [language]="this.language"></app-validation-message>
  </ng-container>
</div>
