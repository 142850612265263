import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptModelService } from '../../../services/concept-model.service';
import { RsEditorConfigurationProvider } from '../../../services/rs-editor-configuration.provider';
import { RsNoticeSubmittingServiceV2 } from '../../../services/rs-notice-submitting-v2.service';
import { NoticeDefinition } from '../../../types/notice-definition';
import { StateService } from '../../../services/state.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { Language } from '../../../types/data-model';
import { CommunicationService } from '../../../services/communication.service';
import { FormTypeService } from '../../../services/form-type.service';
import { FormType } from '../../../types/form-type';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { LoadingService } from '../../../services/loading.service';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { ModelStateService } from '../../../services/view-model/model-state.service';
import { ModelValidationService } from '../../../services/view-model/model-validation.service';
import { EVergabeMetaDataService } from '../../../services/view-model/evergabe-meta-data.service';
import { NoticeSubmittingService } from '../../../services/notice-submitting.service';
import { ModelChangeType } from '../../../types/model-changed-types';
import { RsTemplateSubmittingService } from '../../../services/rs-template-submitting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() notice: NoticeDefinition;
  formType: FormType;
  formValidity = false;
  modifiedSinceLastSave = true;
  devMode = false;
  isUpdateMetaData: boolean;
  canSaveDraft: boolean;
  isRsTemplateMode: boolean;
  isOBA: boolean;
  isRS: boolean;
  isSEB: boolean;
  isChangeNotice: boolean;
  isUpdateChangeNotice: boolean;
  isPriorInformation: boolean;
  isAlterBeforeRelease: boolean;
  cancelText: string;
  lastChangeType: ModelChangeType;

  private subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formTypeService: FormTypeService,
    private conceptModelService: ConceptModelService,
    private communicationService: CommunicationService,
    private stateService: StateService,
    private cdRef: ChangeDetectorRef,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private modelValidationService: ModelValidationService,
    private loadingService: LoadingService,
    private genericDialogService: GenericDialogService,
    private modelStateService: ModelStateService,
    private eVergabeMetaDataService: EVergabeMetaDataService,
    private noticeSubmittingService: NoticeSubmittingService,
    private rsNoticeSubmittingServiceV2: RsNoticeSubmittingServiceV2,
    private rsTemplateSubmittingService: RsTemplateSubmittingService,
    private rsConfig: RsEditorConfigurationProvider,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isUpdateMetaData = this.editorConfigurationProvider.isUpdateMetaData();
    this.isChangeNotice = this.editorConfigurationProvider.isChangeNotice();
    // ReSy: Pruefung auf AlterUpdateBeforeRelease
    this.isUpdateChangeNotice = this.editorConfigurationProvider.isAlterUpdateBeforeRelease();
    this.isAlterBeforeRelease = this.editorConfigurationProvider.isAlterBeforeRelease();

    this.formType = await this.formTypeService.getFormType(this.notice.noticeId);
    this.isPriorInformation = this.formType === FormType.PRIOR_INFORMATION;

    // ReSy: Ob Template-Modus oder nicht aus config auslesen
    this.isRsTemplateMode = this.rsConfig.isTemplateMode();

    this.canSaveDraft =
      this.editorConfigurationProvider.getTenderingProcedureId() !== null &&
      !this.isUpdateMetaData &&
      !this.isAlterBeforeRelease &&
      !this.isRsTemplateMode;

    this.isOBA = this.editorConfigurationProvider.isOBA();
    this.isRS = this.editorConfigurationProvider.isRS();
    this.isSEB = this.editorConfigurationProvider.isSEB();

    this.subscription.add(
      this.stateService.getValidity().subscribe(validity => {
        this.formValidity = validity;
        this.cdRef.markForCheck();
      })
    );

    this.subscription.add(
      this.stateService.getFormChangeType().subscribe(changeType => {
        this.lastChangeType = changeType;
        this.cdRef.markForCheck();
      })
    );

    this.subscription.add(
      this.stateService.getModified().subscribe(modified => {
        this.modifiedSinceLastSave = modified;
        this.cdRef.markForCheck();
      })
    );

    this.cancelText = this.isUpdateMetaData ? 'Änderung abbrechen' : 'Erfassung abbrechen';
    this.subscription.add(
      this.stateService.getDevMode().subscribe(devMode => {
        this.devMode = devMode;
        this.cdRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSendText() {
    if (
      this.isUpdateMetaData ||
      (this.lastChangeType === ModelChangeType.META_ONLY_CHANGE &&
        !this.editorConfigurationProvider.isCreationMode())
    ) {
      return 'Metadaten Speichern';
    }
    if (this.editorConfigurationProvider.isResetProcedure()) {
      return 'Verfahrensstatus zurücksetzen';
    }
    if (this.editorConfigurationProvider.isCancellation()) {
      return 'Verfahren aufheben';
    }

    let label: string;
    if (
      this.isChangeNotice ||
      (this.isRS && this.isUpdateChangeNotice) ||
      this.editorConfigurationProvider.isAlterBeforeRelease() ||
      this.editorConfigurationProvider.isUpdateMetaData()
    ) {
      label = `${this.formType} aktualisieren`;
    } else if ([FormType.PRIOR_INFORMATION, FormType.NOTICE].includes(this.formType)) {
      label = `${this.formType} anlegen`;
    } else {
      label = `${this.formType} erstellen`;
    }
    if (this.lastChangeType === ModelChangeType.UNCHANGED) {
      label += ' (keine Änderungen)';
    }
    return label;
  }

  /**
   * Returns true if the file has been exported successfully and false or undefined otherwise.
   */
  async exportModel() {
    await this.loadingService.setLoading(true);

    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );

    try {
      return await this.communicationService.exportModel(conceptModel);
    } catch (e) {
      await this.genericDialogService.openInfoDialog(
        'Fehler beim Export',
        'Schließen',
        'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.'
      );
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  async exportXML() {
    const result = await this.exportModel();
    if (result?.exported) {
      await this.genericDialogService.openInfoDialog(
        'Formular erfolgreich exportiert!',
        'Schließen',
        'Das Formular wurde erfolgreich an den von Ihnen ausgewählten Ort Exportiert.'
      );
    }
  }

  async saveIntermediateStateForSEB() {
    const result = await this.exportModel();

    if (result?.exported) {
      await this.genericDialogService.openInfoDialog(
        'Formular erfolgreich gespeichert',
        'Schließen',
        'Das Formular wurde erfolgreich an den von Ihnen ausgewählten Ort gespeichert.'
      );
    }
  }

  async saveFinalStateForSEB() {
    await this.validate();

    if (this.formValidity || this.devMode) {
      await this.saveIntermediateStateForSEB();
    }
  }

  async validate(keepSpinning = false): Promise<void> {
    await this.loadingService.setLoading(true);
    const valid = await this.modelValidationService.validateAll(
      this.modelStateService.componentModel
    );
    const devModeEnabled = await firstValueFrom(this.stateService.getDevMode());
    // ReSy: Wenn clientseitig valide, validiere im Backend
    if (valid || devModeEnabled) {
      try {
        await this.rsNoticeSubmittingServiceV2.validateComponentModel(
          this.modelStateService.componentModel
        );
      } catch (e) {
        this.loadingService.setLoading(false);
        return;
      }
    }
    this.modelValidationService.entireValidationOngoing.next(false);
    if (!keepSpinning) this.loadingService.setLoading(false);
    this.cdRef.markForCheck();
  }

  async validateForSEB() {
    await this.validate();

    if (this.formValidity) {
      await this.genericDialogService.openInfoDialog(
        'Kein Fehler bei der Validierung',
        'Schließen',
        'Die Validierung wurde erfolgreich durchgeführt. Sie können jetzt den finalen Stand speichern.'
      );
    }
  }

  async openPreview(): Promise<void> {
    await this.loadingService.setLoading(true);
    const languages = await firstValueFrom(this.stateService.getLanguages());
    const previewLanguage = languages.includes(Language.Deu) ? Language.Deu : Language.Eng;
    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );
    try {
      await this.communicationService.openPreview(previewLanguage, conceptModel);
    } catch (e) {
      await this.genericDialogService.openInfoDialog(
        'Fehler beim Erstellen der Voransicht',
        'Schließen',
        'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.'
      );
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  async submitForm(andReleasePriorInformation = false) {
    await this.validate(true);

    if (!this.formValidity && !this.devMode) {
      this.loadingService.setLoading(false);
      return;
    }

    if (!(await this.confirmFormSubmit(andReleasePriorInformation))) {
      this.loadingService.setLoading(false);
      return;
    }

    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );

    await this.noticeSubmittingService.submitNotice(
      conceptModel,
      this.eVergabeMetaDataService.eVergabeMetaData,
      this.formType,
      andReleasePriorInformation,
      this.lastChangeType
    );
    this.loadingService.setLoading(false);
  }

  async cancelAndClose() {
    const confirm = await this.genericDialogService.confirmWithDialog(
      this.isUpdateMetaData
        ? 'Möchten Sie das Ändern des Formulars abbrechen?'
        : 'Möchten Sie die Anlage abbrechen?',
      this.isUpdateMetaData ? 'Ändern abbrechen' : 'Anlage abbrechen',
      'Zurück zum Formular',
      this.canSaveDraft
        ? 'Hiermit wird die Bearbeitung abgebrochen. Sie können die "Entwurf Speichern" Funktion verwenden. Damit werden Ihre Eingaben gespeichert und stehen später zur weiteren Bearbeitung zur Verfügung.'
        : 'Ihre Änderungen am Formular werden nicht gespeichert und die Bearbeitung wird abgebrochen. Nutzen Sie den die XML-Export, um die Bekanntmachung lokal abzuspeichern und später wieder zu importieren.'
    );

    if (confirm) {
      await this.communicationService.cancelAndClose();
    }
  }

  async cancelAndCloseForRedaktionssystem() {
    const confirm = await this.genericDialogService.confirmWithDialog(
      'Möchten Sie die Erfassung abbrechen?',
      'Erfassung abbrechen',
      'Zurück zum Formular',
      'Ihre Änderungen am Formular werden nicht gespeichert und die Bearbeitung wird abgebrochen.'
    );

    if (confirm) {
      this.router.navigate(['../../..'], { relativeTo: this.route });
    }
  }

  async saveDraft() {
    const procedureId = this.editorConfigurationProvider.getTenderingProcedureId();
    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );
    await this.communicationService.saveDraft(
      procedureId,
      conceptModel,
      this.eVergabeMetaDataService.eVergabeMetaData,
      this.editorConfigurationProvider.isCancellation()
    );
    this.stateService.setModified(false);
    this.cdRef.markForCheck();
  }

  public async saveTemplateForRedaktionssystem() {
    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );

    const rsTemplateId = await firstValueFrom(this.rsTemplateSubmittingService.save(conceptModel));

    this.stateService.setModified(false);
    this.cdRef.markForCheck();

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { rsTemplateId },
      queryParamsHandling: 'merge',
    });
  }

  public async saveForRedaktionssystem(): Promise<void> {
    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );
    const rsNoticeId = await firstValueFrom(
      this.rsNoticeSubmittingServiceV2.save(conceptModel, true)
    );

    this.stateService.setModified(false);
    this.cdRef.markForCheck();

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { rsNoticeId, baseRsNoticeId: undefined },
      queryParamsHandling: 'merge',
    });
  }

  public async saveAndSendForRedaktionssystem(): Promise<void> {
    await this.validate();
    if (!this.formValidity && !this.devMode) {
      return;
    }

    if (!(await this.rsNoticeSubmittingServiceV2.confirmSubmit())) {
      return;
    }

    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );

    const rsNoticeId = await firstValueFrom(
      this.rsNoticeSubmittingServiceV2.save(conceptModel, false)
    );

    this.stateService.setModified(false);
    this.cdRef.markForCheck();

    try {
      await firstValueFrom(this.rsNoticeSubmittingServiceV2.send(rsNoticeId));
      this.router.navigate(['bekanntmachungen', 'verwalten']);
    } catch (error) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: { rsNoticeId, baseRsNoticeId: undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  private async confirmFormSubmit(andReleasePriorInformation: boolean) {
    if (andReleasePriorInformation) {
      return this.genericDialogService.confirmWithDialog(
        'Bitte bestätigen Sie die Anlage und Freigabe der Vorinformation',
        'Anlage und Freigabe bestätigen',
        'Abbrechen',
        'Bei Bestätigung wird die Vorinformation angelegt und freigegeben. Bei Abbruch können Sie das Formular weiter bearbeiten.'
      );
    }

    if (
      this.isUpdateMetaData ||
      this.isChangeNotice ||
      this.isAlterBeforeRelease ||
      (this.isRS && this.isUpdateChangeNotice)
    ) {
      return this.genericDialogService.confirmWithDialog(
        'Möchten Sie Ihre Änderungen speichern?',
        'Änderungen speichern',
        'Abbrechen',
        'Bitte bestätigen Sie Ihre Aktion. Bei Abbruch können Sie das Formular weiter bearbeiten.'
      );
    }
    switch (this.formType) {
      case FormType.NOTICE:
        return this.genericDialogService.confirmWithDialog(
          'Bitte bestätigen Sie die Anlage des Verfahrens.',
          'Anlage bestätigen',
          'Abbrechen',
          'Bei Bestätigung wird das Verfahren angelegt. Bei Abbruch können Sie das Formular weiter bearbeiten.'
        );
      case FormType.PRIOR_INFORMATION:
        return this.genericDialogService.confirmWithDialog(
          'Bitte bestätigen Sie die Anlage der Vorinformation',
          'Anlage bestätigen',
          'Abbrechen',
          'Bei Bestätigung wird die Vorinformation angelegt. Bei Abbruch können Sie das Formular weiter bearbeiten.'
        );
    }
    return this.genericDialogService.confirmWithDialog(
      'Aktion bestätigen',
      'Bestätigen',
      'Abbrechen',
      'Bitte bestätigen Sie Ihre Aktion. Bei Abbruch können Sie das Formular weiter bearbeiten.'
    );
  }
}
