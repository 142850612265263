import { BaseModel } from '../view-model/base-model';
import { Project } from './project';
import { LocalDate, LocalDateTime } from '@js-joda/core';

export declare type FieldValueType =
  | string
  | boolean
  | number
  | Amount
  | Measure
  | Multilingual
  | LocalisedText;

export declare type FieldModelType = FieldValueType | LocalDate | LocalDateTime | Project;

export declare type EfxModelType = string | number | boolean | Date;

export interface FieldValueMatch {
  value: FieldValueType;
  modelRef: BaseModel;
}

export interface ParentGroup {
  htmlId: string;
  label: string;
}

export interface Multilingual {
  DEU?: string;
  ENG?: string;
}

export enum Language {
  Deu = 'DEU',
  Eng = 'ENG',
}

export interface Amount {
  amount: string;
  currencyID: string;
}

export interface Measure {
  amount: string;
  unit: string;
}

export interface LocalisedText {
  text: string;
  language: string;
}
