import { Injectable } from '@angular/core';
import { AppState, IndicatorData, IndicatorValue, SchemeIdUpdate } from '../store/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as IndicatorActions from '../store/indicator/indicator.action';
import * as NoticeLastEditedActions from '../store/notice-last-edited/notice-last-edited.action';
import * as LanguageActions from '../store/language/language.action';
import * as DevModeActions from '../store/devmode/devmode.action';
import * as SupportModeActions from '../store/supportmode/supportmode.action';
import * as RsDisplaymodeActions from '../store/rs-displaymode/rs-displaymode.action';
import * as ModifiedActions from '../store/modified/modified.action';
import * as FormChangeTypeActions from '../store/form-change-type/form-change-type.action';
import * as ResetActions from '../store/reset/reset.action';
import { selectRsDisplayMode } from '../store/rs-displaymode/rs-displaymode.selector';
import * as SectionIdActions from '../store/section/section.action';
import * as NoticeIdActions from '../store/notice-id/notice-id.action';
import * as ValidActions from '../store/valid/valid.action';
import * as ErrorsActions from '../store/errors/errors.action';
import * as ProcedureTypeActions from '../store/procedure-type/procedure-type.action';
import { selectLanguage } from '../store/language/language.selector';
import { selectIndicatorMap } from '../store/indicator/indicator.selector';
import { selectNoticeId } from '../store/notice-id/notice-id.selector';
import { selectDevMode } from '../store/devmode/devmode.selector';
import { selectValid } from '../store/valid/valid.selector';
import { DisplayMode } from '../types/display-mode';
import { NavigableNotification } from '../types/NavigableNotification';
import { selectErrors } from '../store/errors/errors.selector';
import { selectModified } from '../store/modified/modified.selector';
import { Language } from '../types/data-model';
import { selectSectionId } from '../store/section/section.selector';
import { selectProcedureTypeName } from '../store/procedure-type/procedure-type.selector';
import { selectSupportMode } from '../store/supportmode/supportmode.selector';
import { selectNoticeLastEdited } from '../store/notice-last-edited/notice-last-edited.selector';
import { ModelChangeType } from '../types/model-changed-types';
import { selectModelChangeType } from '../store/form-change-type/form-change-type.selector';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(private store: Store<AppState>) {}

  getIndicator(): Observable<Map<string, IndicatorData>> {
    return this.store.select(selectIndicatorMap);
  }

  addIndicator(value: IndicatorValue): void {
    this.store.dispatch(
      IndicatorActions.add({
        indicator: this.getIndicatorFromValue(value.id),
        value,
        index: this.getIndexFromValue(value.id),
      })
    );
  }

  updateIndicatorSchemeIdRefreshHighest(schemeIdUpdate: SchemeIdUpdate): void {
    this.store.dispatch(IndicatorActions.updateSchemeIdRefreshHighest(schemeIdUpdate));
  }

  updateIndicatorLabel(value: IndicatorValue): void {
    this.store.dispatch(
      IndicatorActions.updateLabel({
        indicator: this.getIndicatorFromValue(value.id),
        value,
      })
    );
  }

  /**
   * Retrieves the current content of BT-105-Procedure
   */
  getActiveProcedureType(): Observable<string> {
    return this.store.select(selectProcedureTypeName);
  }

  /**
   * Updates the state content, when BT-105-Procedure changes
   */
  setActiveProcedureType(procedureType: string) {
    this.store.dispatch(
      ProcedureTypeActions.update({
        name: procedureType,
      })
    );
  }

  removeIndicator(id: string) {
    this.store.dispatch(
      IndicatorActions.remove({
        indicator: this.getIndicatorFromValue(id),
        id,
      })
    );
  }

  getLanguages(): Observable<Language[]> {
    return this.store.select(selectLanguage);
  }

  addLanguage(language: Language): void {
    this.store.dispatch(LanguageActions.add({ lang: language }));
  }

  removeLanguage(language: Language): void {
    this.store.dispatch(LanguageActions.remove({ lang: language }));
  }

  setDevMode(enabled: boolean): void {
    this.store.dispatch(DevModeActions.set({ enabled }));
  }

  getDevMode(): Observable<boolean> {
    return this.store.select(selectDevMode);
  }

  setSupportMode(enabled: boolean): void {
    this.store.dispatch(SupportModeActions.set({ enabled }));
  }

  getSupportMode(): Observable<boolean> {
    return this.store.select(selectSupportMode);
  }

  // ReSy: Displaymodus
  setDisplayMode(displayMode: DisplayMode): void {
    this.store.dispatch(RsDisplaymodeActions.set({ displayMode }));
  }

  // ReSy: Displaymodus
  getDisplayMode(): Observable<DisplayMode> {
    return this.store.select(selectRsDisplayMode);
  }

  setModified(modified: boolean): void {
    this.store.dispatch(ModifiedActions.set({ modified }));
  }

  getModified(): Observable<boolean> {
    return this.store.select(selectModified);
  }

  setFormChangeType(modelChangeType: ModelChangeType): void {
    this.store.dispatch(FormChangeTypeActions.updateChangeType({ modelChangeType }));
  }

  getFormChangeType(): Observable<ModelChangeType> {
    return this.store.select(selectModelChangeType);
  }

  getSectionId(): Observable<string> {
    return this.store.select(selectSectionId);
  }

  changeSectionId(sectionId: string): void {
    this.store.dispatch(SectionIdActions.updateId({ id: sectionId }));
  }

  getNoticeId(): Observable<string> {
    return this.store.select(selectNoticeId);
  }

  updateNoticeId(noticeId: string): void {
    this.store.dispatch(NoticeIdActions.update({ id: noticeId }));
  }

  getValidity(): Observable<boolean> {
    return this.store.select(selectValid);
  }

  changeValidity(valid: boolean) {
    this.store.dispatch(ValidActions.change({ valid }));
  }

  getNotifications(): Observable<NavigableNotification[]> {
    return this.store.select(selectErrors);
  }

  setNotifications(notifications: NavigableNotification[]) {
    return this.store.dispatch(ErrorsActions.set({ notifications }));
  }

  // ReSy: fuer last-edited-Header
  getNoticeLastEdited(): Observable<Map<number, string>> {
    return this.store.select(selectNoticeLastEdited);
  }

  // ReSy: fuer last-edited-Header
  addNoticeLastEdited(rsNoticeId: number, lastEdited: string) {
    this.store.dispatch(NoticeLastEditedActions.put({ rsNoticeId, noticeLastEdited: lastEdited }));
  }

  removeAllNavigableNotifications(): void {
    this.store.dispatch(ErrorsActions.set({ notifications: [] }));
  }

  reset(): void {
    this.store.dispatch(ResetActions.resetState());
  }

  private getIndicatorFromValue(value: string): string {
    return value.split('-')[0];
  }

  private getIndexFromValue(value: string): number {
    return +value.split('-')[1];
  }
}
