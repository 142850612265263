<div class="container-with-margin">
  <div class="filter-box">
    <fieldset>
      <legend>Vorlagen filtern</legend>
      <div class="groupmember-dropdown">
        <label>Erstellt von</label>
        <ng-container *skeleton="!(dropdownData$ | async); height: '2.5em';">
          <app-groupmember-dropdown *ngIf="dropdownData$ | async as data"
                                    [groups]="data.groups"
                                    [ownUser]="{userId: data.loggedInUser.id, name: data.loggedInUser.firstName+' '+data.loggedInUser.lastName, username: data.loggedInUser.username}"
                                    [selectedUserId]="selectedUserId$ | async"
                                    (changeSelection)="userFilterChanged($event)"
          ></app-groupmember-dropdown>
        </ng-container>
      </div>
    </fieldset>
  </div>

  <div *skeleton="loading$ | async; height: '210px'; width: '100%'; repeat: 3; type: 'accordion'">
    <ng-template #noNotices>
      <div class="empty-label">
        <h5>Noch keine Vorlagen vorhanden.</h5>
      </div>
    </ng-template>
    <ng-container *ngIf="dataSource.data.length > 0; else noNotices">
      <app-vorlagen-uebersicht-table
        [noticeTemplateOverviews]="dataSource.data"
        [iAmOwner]="iAmOwner$ | async"
        (clickActionType)="onClickActionType($event)"
        (clickEditName)="onClickEditName($event)"
      >
      </app-vorlagen-uebersicht-table>
    </ng-container>
  </div>
</div>

<app-vorlagen-name-dialog #vorlagenNameDialog dialogTitle="Vorlage bearbeiten"></app-vorlagen-name-dialog>
