import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { StateService } from '../../../services/state.service';
import { FieldModel } from '../../../view-model/field-model';
import { Subscription } from 'rxjs';
import { RsEditorConfigurationProvider } from '../../../services/rs-editor-configuration.provider';

@Component({
  selector: 'app-field-header',
  templateUrl: './field-header.component.html',
  styleUrls: ['./field-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldHeaderComponent implements OnDestroy, AfterViewInit {
  @Input() model: FieldModel<any>;
  subscription = new Subscription();
  supportMode: boolean;
  isTemplate: boolean;

  constructor(
    stateService: StateService,
    private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef),
    private rsConfig: RsEditorConfigurationProvider,
  ) {
    this.subscription.add(
      stateService.getSupportMode().subscribe(next => {
        this.supportMode = next;
        this.cdRef.markForCheck();
      })
    );

    // ReSy: Zeige Tooltip-Komponente fuer ausgewahelte Feldern an, wenn die Maske in der Vorlagenfunktion (Template) geoeffnet ist
    this.isTemplate = this.rsConfig.isTemplateMode();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subscription.add(this.model.modelChangedEmitter.subscribe(() => this.onModelChange()));
    this.subscription.add(this.model.updateViewEmitter.subscribe(() => this.cdRef.markForCheck()));
  }

  private onModelChange(): void {
    this.cdRef.markForCheck();
  }
}
