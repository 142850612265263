<app-template [hasContentFrame]="true" [titel]="'Anleitungen'">
  <p>
    Diese Anleitung stellt eine Kurzübersicht zu den wichtigsten Funktionen des Redaktionssystems
    dar:
  </p>
  <a href="assets/pdf/Kurzanleitung_Redaktionssystem Version 1.4.pdf" target="_blank"
    >Kurzanleitung Redaktionssystem Version 1.4</a
  >
  <p>
    Diese Anleitung beschreibt die seit Version 3.0 zur Verfügung stehende Gruppenfunktion:
  </p>
  <a href="assets/pdf/Anleitung_Gruppenfunktion.pdf" target="_blank"
  >Anleitung Gruppenfunktion</a
  >
  <p>
    Hinweis: Die Durchführung von elektronischen Vergabeverfahren wird im Redaktionssystem nicht
    abgebildet. Nutzende des Redaktionssystems müssen die Durchführung von Vergabeverfahren selbst
    organisieren und durchführen.
  </p>
</app-template>
