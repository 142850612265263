import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
  VorlagenMetadataConfirmedEvent,
  VorlagenNameDialogComponent,
} from '../vorlagen-uebersicht/vorlagen-name-dialog/vorlagen-name-dialog.component';
import {
  ClientPlatform,
  FormMode,
  OperationMode,
  PublicationMode,
} from '../../types/editor-configuration';
import { TemplateNameAndDescriptionService } from '../bekanntmachung/bekanntmachungen-auswahl/template-name-and-description.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NoticeTypesService } from '../bekanntmachung/notice-types.service';
import { FilterTableViewModel } from '../shared/filter-table/FilterTableViewModel';

@Component({
  selector: 'app-vorlagen-auswahl',
  templateUrl: './vorlagen-auswahl.component.html',
  styleUrls: ['./vorlagen-auswahl.component.scss'],
})
export class VorlagenAuswahlComponent implements OnInit {
  data: FilterTableViewModel[];

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @ViewChild(VorlagenNameDialogComponent) vorlagenNameDialog!: VorlagenNameDialogComponent;

  private selectedSubTypeId: string;

  constructor(
    private noticeTypesService: NoticeTypesService,
    private templateNameAndDescriptionService: TemplateNameAndDescriptionService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.noticeTypesService
      .getSubTypeIdsforTemplates()
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(
        noticeTypes => (this.data = noticeTypes.map(this.noticeTypesService.mapToDisplayData))
      );
  }

  onRowClick(subTypeId: string) {
    this.vorlagenNameDialog.openDialog();
    this.selectedSubTypeId = subTypeId;
  }

  changeTemplateMetadata($event: VorlagenMetadataConfirmedEvent) {
    this.templateNameAndDescriptionService.setValues(
      $event.metadata.name,
      $event.metadata.description
    );
    this.router.navigate(['notice', this.selectedSubTypeId, 'details'], {
      relativeTo: this.route,
      queryParams: {
        isTemplate: true,
        mode: FormMode.FORM_CREATE,
        operationMode: OperationMode.PRODUCTION,
        clientPlatform: ClientPlatform.RS,
        cancellation: false,
        resetProcedure: false,
        offerDeadlineChangeAllowed: true,
        participationDeadlineChangeAllowed: true,
        publicationMode: PublicationMode.WITH_PUBLICATION,
        lotSupport: 'null', // Parameter muss fuer die eVergabe gefuellt sein
      },
    });
  }
}
