import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ValidationReportDialogComponent } from './validation-report-dialog.component';
import { ValidationReportDialogData } from './validation-report-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class ValidationReportDialogService {
  constructor(private dialog: MatDialog) {}

  public showValidationReportDialog(
    report: ValidationReportDialogData,
    dismissLabel: string = 'Bearbeitung fortsetzen',
    title: string = 'Unerwarteter Validierungsfehler',
    content: string = 'Bei der Validierung  der Bekanntmachung ist ein unerwarteter Fehler aufgetreten. Bitte wenden Sie sich an den Support.'
  ) {
    this.dialog.open(ValidationReportDialogComponent, {
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
      data: {
        title,
        content,
        dismissLabel,
        report: report.report,
        success: false,
      },
    });
  }
}
