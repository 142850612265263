import { ModelValidator } from '../ModelValidator';
import { ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';
import { FieldInfoType } from '../../../../types/field-types';
import { DateModel } from '../../../../view-model/type/date-model';
import { TimeModel } from '../../../../view-model/type/time-model';

export class PatternValidator extends ModelValidator<FieldModel<any>> {
  public static readonly PATTERN_ERROR_MSG = 'Die Eingabe entspricht nicht dem erlaubten Format.';

  constructor() {
    super();
  }

  validate(fieldModel: FieldModel<any>): Promise<ValidationNotification[]> {
    if (
      fieldModel.value &&
      !new RegExp(fieldModel.fieldInfo.pattern.value).test(fieldModel.value)
    ) {
      const validationMessage = this.getPatternErrorMessage(fieldModel.fieldInfo.type);
      return this.ofSingle(validationMessage);
    }

    return this.empty();
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return (
      !!model.fieldInfo?.pattern && !(model instanceof DateModel) && !(model instanceof TimeModel)
    );
  }

  private getPatternErrorMessage(fieldInfoType: FieldInfoType): string {
    switch (fieldInfoType) {
      case FieldInfoType.PHONE:
        return 'Die Eingabe ist keine gültige Telefonnummer (Beispiel: +49 228 12345).';
      case FieldInfoType.URL:
        return 'Die Eingabe ist keine gültige URL (Beispiel: https://www.beispiel.de).';
      case FieldInfoType.MAIL:
        return 'Die Eingabe ist keine gültige E-Mail Adresse (Beispiel: beispiel@beispiel.de).';
      default:
        return PatternValidator.PATTERN_ERROR_MSG;
    }
  }
}
