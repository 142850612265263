import { NoticeOverviewDto, HistoryNoticeOverviewDto } from '../api/notices';

export class NoticeOverviewViewModel {
  notice: NoticeOverviewDto | HistoryNoticeOverviewDto;
  parent?: Partial<NoticeOverviewDto>;
  isNewestNoticeForSubtypeId: boolean;
  showHistory: boolean;
  hasHistory: boolean;
  isVisible: boolean;
  historyIds: number[];
}
