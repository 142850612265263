import { Component, OnInit } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';
import {
  ClientPlatform,
  FormMode,
  OperationMode,
  PublicationMode,
} from '../../../types/editor-configuration';
import { BekanntmachungenVerwaltungService } from '../bekanntmachungen-verwaltung/bekanntmachungen-verwaltung.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagService } from '../../feature-flag.service';
import { NoticeTypesService } from '../notice-types.service';
import { BehaviorSubject } from 'rxjs';
import { FilterTableViewModel } from '../../shared/filter-table/FilterTableViewModel';

@Component({
  selector: 'app-bekanntmachungen-auswahl',
  templateUrl: './bekanntmachungen-auswahl.component.html',
  styleUrls: ['./bekanntmachungen-auswahl.component.scss'],
})
export class BekanntmachungenAuswahlComponent implements OnInit {
  data: FilterTableViewModel[];

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private bekanntmachungenVerwaltungService: BekanntmachungenVerwaltungService,
    private router: Router,
    private route: ActivatedRoute,
    public featureFlagService: FeatureFlagService,
    private noticeTypesService: NoticeTypesService
  ) {}

  ngOnInit(): void {
    this.noticeTypesService
      .getNoticeSubTypes()
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(
        noticeTypes => (this.data = noticeTypes.map(this.noticeTypesService.mapToDisplayData))
      );
  }

  importXmlFile(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];

    if (file) {
      // Read file
      const reader = new FileReader();
      reader.onload = () => {
        const data = new Blob([reader.result]);
        this.bekanntmachungenVerwaltungService.importNotice(data).subscribe(() => {
          this.router.navigate(['bekanntmachungen', 'verwalten']);
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  onRowClick(subTypeId: string) {
    this.router.navigate(['notice', subTypeId, 'details'], {
      relativeTo: this.route,
      queryParams: {
        mode: FormMode.FORM_CREATE,
        operationMode: OperationMode.PRODUCTION,
        clientPlatform: ClientPlatform.RS,
        cancellation: false,
        resetProcedure: false,
        offerDeadlineChangeAllowed: true,
        participationDeadlineChangeAllowed: true,
        publicationMode: PublicationMode.WITH_PUBLICATION,
        lotSupport: 'null', // Parameter muss fuer die eVergabe gefuellt sein
      },
    });
  }
}
