import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ContactService } from '../../../../../services/contact.service';
import { EditorConfigurationProvider } from '../../../../../services/editor-configuration.provider';
import { Contact } from '../../../../../types/contact';
import { StateService } from '../../../../../services/state.service';
import { Subscription } from 'rxjs';
import { GenericDialogService } from '../../../../../services/ui/generic-dialog.service';
import { GroupModel } from '../../../../../view-model/group-model';
import { ModelStateService } from '../../../../../services/view-model/model-state.service';
import { DialogComponent } from '../../../../layout/dialog/dialog.component';
import { CodelistModel } from '../../../../../view-model/type/codelist-model';
import { NoticeNode } from '../../../../../types/notice-definition';
import { CodelistModelService } from '../../../../../services/ui/codelist-model.service';
import { MultilingualModel } from '../../../../../view-model/type/multilingual-model';
import { Language } from '../../../../../types/data-model';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['/address-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBookComponent implements OnInit, OnDestroy {
  @ViewChild('addressBookDialog') addressBookDialog: DialogComponent;
  @Input() model: GroupModel;
  contacts: Contact[];
  isTouchPoint: boolean;
  filteredContacts: Contact[];
  searchQuery = '';
  codeListLand: { id: string; label: string }[];
  private subscription = new Subscription();
  selectedLanguages: Language[] = [];
  titleModel: MultilingualModel;
  isRS: boolean;

  constructor(
    private contactService: ContactService,
    private stateService: StateService,
    private cdRef: ChangeDetectorRef,
    private genericDialogService: GenericDialogService,
    private codeListModelService: CodelistModelService,
    private editorConfigurationProvider: EditorConfigurationProvider,
  ) {}

  async ngOnInit() {
    this.isRS = this.editorConfigurationProvider.isRS();
    this.isTouchPoint = this.model.noticeNode.id === 'GR-Touch-Point';

    this.titleModel = this.isTouchPoint
      ? ModelStateService.findModelById(this.model, 'BT-500-Organization-TouchPoint')
      : ModelStateService.findModelById(this.model, 'BT-500-Organization-Company');

    this.subscription.add(
      this.titleModel?.modelChangedEmitter.subscribe(() => this.cdRef.markForCheck())
    );

    this.subscription.add(
      this.stateService.getLanguages().subscribe(languages => {
        this.selectedLanguages = languages;
        this.cdRef.markForCheck();
      })
    );

    await this.loadLandCodeList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openAddressBookDialog(): void {
    this.contacts = this.contactService.getContacts();
    this.filteredContacts = this.contacts;
    this.searchQuery = '';
    this.sortAddresses();
    this.addressBookDialog.openDialog();
  }

  async saveAddress(): Promise<void> {
    await this.contactService.addContact(this.model, this.isTouchPoint);
    await this.genericDialogService.showResultDialog(
      'Kontakt speichern',
      'Der Kontakt wurde erfolgreich gespeichert.',
      'Schließen',
      true
    );
  }

  stopDefaultBehavior(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onClose(): void {
    this.contacts = null;
  }

  isSaveAllowed(): boolean {
    if (this.selectedLanguages?.includes(Language.Deu) && this.titleModel?.value.DEU) {
      return true;
    } else return !!(this.selectedLanguages?.includes(Language.Eng) && this.titleModel?.value.ENG);
  }

  async setContact(selected: Contact): Promise<void> {
    await this.contactService.applyContact(this.model, selected, this.isTouchPoint);
    this.addressBookDialog.close();
    this.stateService.setModified(true);
    await this.genericDialogService.showResultDialog(
      'Adresse einfügen',
      'Die Adresse wurde erfolgreich eingefügt.',
      'Schließen',
      true
    );
  }

  async deleteContact(selected: Contact) {
    const confirm = await this.genericDialogService.confirmWithDialog(
      'Möchten Sie wirklich die Adresse löschen?',
      'Löschen',
      'Abbrechen'
    );
    if (confirm) {
      this.contacts = this.contactService.deleteContact(selected);
      this.searchAddress(this.searchQuery);
      this.cdRef.markForCheck();
    }
  }

  saveContactsChanges(contacts: Contact[]) {
    this.contacts = contacts;
    this.searchAddress(this.searchQuery);
    this.sortAddresses();
  }

  sortAddresses() {
    this.contacts.sort((a, b) =>
      a.officialName.toLowerCase() > b.officialName.toLowerCase() ? 1 : -1
    );
  }

  searchAddress(searchAddress: string) {
    if (!searchAddress) {
      this.filteredContacts = this.contacts;
    } else {
      this.filteredContacts = this.contacts.filter(contact => {
        const contactValues: any = Object.values(contact);
        return contactValues.some(
          value =>
            value && String(value).trim().toLowerCase().includes(searchAddress.trim().toLowerCase())
        );
      });
    }
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.searchAddress('');
  }

  findCountryLabel(modelValue: string) {
    const label = this.codeListLand.find(land => land.id === modelValue)?.label;
    return label ? label : modelValue;
  }

  private async loadLandCodeList() {
    const codeListModelLand = new CodelistModel(this.codeListModelService);
    codeListModelLand.noticeNode = { id: 'BT-514-Organization-Company' } as NoticeNode;
    await codeListModelLand.loadSdkProperties();
    this.codeListLand = codeListModelLand.codeList;
  }
}
