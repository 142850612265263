<div *ngIf="isVisible" class="group-wrapper" [hidden]="model.isHidden" [id]="htmlId">
  <mat-expansion-panel [expanded]="alwaysExpanded ? true : model.isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ model.translatedLabel }}</h6>
        <span *ngIf="supportMode" class="support-mode-label-right">{{ model.noticeNode.id }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      class="hint-wrapper hint-wrapper-group"
      *ngIf="model.tooltips && model.tooltips.length > 0">
      <div *ngFor="let tooltip of model.tooltips" class="hint">
        <mat-icon>info</mat-icon>
        <p>{{ tooltip }}</p>
      </div>
    </div>

    <div class="expansion-container">

      <div>
        <div class="field-header">
          <label>{{ codelistModel.translatedLabel }}</label>
          <span class="support-mode-label">{{ supportMode ? codelistModel.noticeNode.id : '' }}</span>
        </div>
        <div>
          <p class="description" *ngIf="codelistModel.description">{{ codelistModel.description }}</p>
        </div>
        <app-alert-message
          [severity]="Severity.ERROR"
          *ngFor="let message of collectNotifications()"
          class="alert-box">
          <p>
            <span>{{ message }}</span>
          </p>
        </app-alert-message>
      </div>

      <div class="form-component-input-group" *ngFor="let field of formFields; index as i">
        <div class="field-header">
          <label>{{ labelPrefix + field.codelistItem.label }}<span [appMarkAsterisk]="isRequired"></span></label>
          <span class="support-mode-label">{{ supportMode ? fieldBtId : '' }} </span>
        </div>

        <input
          [id]="htmlId + '_' + i"
          (change)="onChange()"
          [class.has-error]="field.formControl.invalid && field.formControl.touched"
          [class.required]=isRequired
          [formControl]="field.formControl"
          class="form-text-field"
          appInputNumber
          [maxLength]="model.fieldInfo?.maxLength"
          style="width: 100%" />
      </div>
    </div>
  </mat-expansion-panel>
</div>
