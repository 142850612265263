<div class="container-with-margin">
  <div class="filter-box">
    <fieldset>
      <legend>Verfahren filtern</legend>
      <div class="filter-container">
        <div class="groupmember-dropdown">
          <label>Erstellt von</label>
          <ng-container *skeleton="!(dropdownData$ | async); height: '2.5em';">
            <app-groupmember-dropdown *ngIf="dropdownData$ | async as data"
                                      [groups]="data.groups"
                                      [ownUser]="{userId: data.loggedInUser.id, name: data.loggedInUser.firstName+' '+data.loggedInUser.lastName, username: data.loggedInUser.username}"
                                      [selectedUserId]="data.loggedInUser.id"
                                      (changeSelection)="userFilterChanged($event)"
            ></app-groupmember-dropdown>
          </ng-container>
        </div>
        <div class="filters">
          <mat-checkbox value="favorites" (change)="favoritesFilterChanged($event)">Favoriten </mat-checkbox>
          <mat-checkbox value="showHidden" (change)="hiddenFilterChanged($event)">Ausgeblendete anzeigen</mat-checkbox>
        </div>
      </div>
      <div class="shown-notices">
        <strong>{{ dataSource.filteredData.length }}</strong> von <strong>{{ dataSource.data.length }}</strong> Verfahren sichtbar
      </div>
    </fieldset>
  </div>

  <div *skeleton="loading$ | async; height: '210px'; width: '100%'; repeat: 3; type: 'accordion'">
    <ng-template #noNotices>
      <div class="empty-label">
        <h5>Noch keine Bekanntmachungen vorhanden.</h5>
      </div>
    </ng-template>
    <ng-container *ngIf="dataSource.filteredData && dataSource.filteredData.length > 0; else noNotices">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="procedure-content">
          <ng-container mat-cell *matCellDef="let procedure; let i = index">
            <mat-expansion-panel [expanded]="isFirstPage ? i < 5 : false">
              <mat-expansion-panel-header
                class="mat-expansion-panel-header"
                [ngClass]="{ inactive: procedure.id | isHidden | async }">
                <mat-panel-title class="mat-expansion-panel-header-title">
                  <div class="viewButtons">
                    <button
                      mat-icon-button
                      matIconSuffix
                      class="view-icon"
                      [ngClass]="{ favoriteActive: procedure.id | isFavorite | async }"
                      (click)="toggleFavorite($event, procedure)"
                      [matTooltip]="
                        (procedure.id | isFavorite | async)
                          ? 'Verfahren nicht mehr als Favorit markieren'
                          : 'Verfahren als Favorit markieren'
                      ">
                      <mat-icon>book</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matIconSuffix
                      class="view-icon view-icon-blue"
                      (click)="toggleHidden($event, procedure)"
                      [matTooltip]="
                        (procedure.id | isHidden | async) ? 'Verfahren wieder einblenden' : 'Verfahren ausblenden'
                      ">
                      <mat-icon *ngIf="!(procedure.id | isHidden | async)">visibility_on</mat-icon>
                      <mat-icon *ngIf="procedure.id | isHidden | async">visibility_off</mat-icon>
                    </button>
                  </div>
                  <div class="verfahren">
                    <h2>Verfahren #{{ procedure.id }}</h2>
                  </div>
                  <div class="title">
                    <h2>Titel:</h2>
                    <h4 matTooltip="Titel der jüngsten Bekanntmachung dieses Verfahrens">
                      {{ procedure | procedureGetLatestTitlePipe }}
                    </h4>
                  </div>
                  <div>
                    <span *ngIf="procedure.procedureId">
                      <b>Verfahrenskennung:</b> {{ procedure.procedureId }}
                    </span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <app-bekanntmachung-table
                  [data]="procedure.notices"
                  (triggerAction)="onTriggerAction($event)"
                ></app-bekanntmachung-table>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['procedure-content']"></tr>
      </table>
    </ng-container>
  </div>

  <!-- use hidden over *ngIf to prevent problems with updating this ViewChild -->
  <div class="below-table" [hidden]="dataSource.filteredData?.length <= pageSizeOptions[0]">
    <mat-paginator
      #paginator
      class="paginator"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSizeOptions[1]"
      [showFirstLastButtons]="true"
      (page)="onPageEvent($event)">
    </mat-paginator>
  </div>
</div>
