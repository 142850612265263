import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { TimeModel } from '../../../../view-model/type/time-model';
import { Severity } from '../../../../types/app-types';

@Component({
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  selector: 'app-time',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeComponent extends AbstractFormComponent implements OnInit {
  inputControl: FormControl;

  @Input()
  model: TimeModel;

  @Input()
  isChildInDateComponent = false;

  private static readonly TIME_INPUT_FORMAT = /^([01]\d|2[0-3]):([0-5]\d)$/; // Zeitformat hh:mm

  constructor() {
    super();
    this.inputControl = new FormControl();
  }

  ngOnInit(): void {
    this.initValue();
    if (this.isChildInDateComponent) {
      this.initWithModel(this.model);
    }

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: false });
    } else {
      this.handleInputChanges();
    }
  }

  hasRelatedDateComponentInputError() {
    return this.model.relatedDateModel?.validationNotifications?.some(
      notification => notification.severity === Severity.ERROR
    );
  }

  /**
   * Called after the Value of the Form Component has been changed.
   */
  onInputValueChanged() {
    super.onInputValueChanged();

    if (!this.model.isMandatory && !this.inputControl.value) {
      this.inputControl.setValue(null, { emitEvent: false, emitModelToViewChange: true });
    }
  }

  private handleInputChanges(): void {
    this.subscription.add(
      this.inputControl.valueChanges.subscribe((value: string) => {
        if (TimeComponent.TIME_INPUT_FORMAT.test(value)) {
          const units = value.split(':');
          const hours = units[0];
          const mins = units[1];
          this.model.value = `${hours}:${mins}:00`;
        } else {
          this.model.value = null;
        }
      })
    );
  }

  private initValue(): void {
    if (this.model.value) {
      const units = this.model.value.split(':');
      const hours = units[0];
      const mins = units[1];
      this.inputControl.setValue(`${hours}:${mins}`);
    }
  }
}
