<ng-container *ngIf="notice">
  <app-header [@fadeInOut]="fadeInOutAnimationState"></app-header>

  <div *ngIf="loading" @inOutAnimation [class]="'loading-spinner-container'">
    <div [class]="'loading-spinner-wheel'"></div>
  </div>

  <div [@fadeInOut]="fadeInOutAnimationState" class="container">
    <as-split
      id="as-split-navigation"
      [gutterSize]="8"
      direction="horizontal"
      aria-label="Schieber">
      <as-split-area id="as-split-left" [minSize]="17" [size]="23">
        <aside>
          <app-form-info></app-form-info>
          <app-side-bar></app-side-bar>
        </aside>
      </as-split-area>
      <!--placeholder for as-split-gutter-->
      <as-split-area id="as-split-right" [minSize]="30" [size]="77">
        <main id="main">
          <as-split
            unit="pixel"
            [gutterSize]="8"
            direction="vertical"
            aria-label="Schieber"
            id="as-split-notification-box">
            <as-split-area id="as-split-form" [minSize]="8">
              <h1 id="headingformular" class="visually-hidden">Formular</h1>
              <div class="form-wrapper">
                <app-dynamic-form-container
                  id="form-container"
                  class="app-dynamic-form-container"
                  [noticeId]="notice.noticeId">
                </app-dynamic-form-container>
              </div>
            </as-split-area>
            <!--placeholder for as-split-gutter-->
            <as-split-area
              id="as-split-notification-box-bottom"
              [class.transition-enabled]="transitionsEnabled"
              [minSize]="notificationBoxHeaderHeight"
              [size]="notificationBoxHeaderHeight">
              <app-notification-box
                (headerSizeOut)="setNotificationBoxHeaderHeight($event)"
                (expandOut)="expandNotificationBox($event)">
              </app-notification-box>
            </as-split-area>
          </as-split>
        </main>
      </as-split-area>
    </as-split>
  </div>

  <app-footer [@fadeInOut]="fadeInOutAnimationState" [notice]="notice"></app-footer>
</ng-container>
