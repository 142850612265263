import { Injectable } from '@angular/core';
import { SdkService } from '../sdk.service';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';

@Injectable()
export class CodelistModelService {
  constructor(
    private sdkService: SdkService,
    private editorConfigurationProvider: EditorConfigurationProvider
  ) {}

  public async loadCodeListItems(codelistModel: CodelistModel): Promise<void> {
    const codeListId = this.resolvePrimaryCodeListId(codelistModel);
    const customizedCodeListId = this.resolveCustomizedCodeListId(codeListId, codelistModel);

    const codeList = await this.loadCodeList(customizedCodeListId, codeListId);
    if (!codeListId || !codeList) {
      console.error(`Can't fetch Codelist for Field ${codelistModel.noticeNode.id}`);
      return;
    }

    if (
      !this.editorConfigurationProvider.isRS() &&
      codeListId === 'procurement-procedure-type' &&
      !this.editorConfigurationProvider.isWithoutPublication()
    ) {
      // removes procedure-type 'VERFAHREN_OHNE_TW (neg-wo-call)' from the options of BT-105-Procedure
      // for all but procedure-without-publication
      codelistModel.codeList = codeList.filter(value => value.id !== 'neg-wo-call');
    } else {
      codelistModel.codeList = codeList;
    }

    if (codeListId === 'nuts-lvl3' || codeListId === 'cpv') {
      codelistModel.isCodeField = true;
      return;
    }

    codelistModel.isAutoComplete = codelistModel.codeList.length > 100;
  }

  private resolvePrimaryCodeListId(codelistModel: CodelistModel): string | undefined {
    return this.sdkService.getFieldInfo(codelistModel.noticeNode.id)?.codeList?.value?.id;
  }

  private resolveCustomizedCodeListId(
    primaryCodeListId: string,
    codelistModel: CodelistModel
  ): string | undefined {
    if (primaryCodeListId === 'procurement-procedure-type') {
      return `procurement-procedure-type-${codelistModel.root.noticeId}`;
    }
    return undefined;
  }

  private async loadCodeList(
    customizedCodeList: string,
    primaryCodeList: string
  ): Promise<{ id: string; label: string }[]> {
    let codeList;
    if (customizedCodeList) {
      codeList = await this.sdkService.getCodeList(customizedCodeList);
    }
    if (!codeList && primaryCodeList) {
      codeList = this.sdkService.getCodeList(primaryCodeList);
    }
    return codeList;
  }
}
