<div class="navigation-results-wrapper">
  <a
    (keydown)="onNavigateInResults($event)"
    (keydown.enter)="navigateToResult(result)"
    (mousedown)="navigateToResult(result)"
    (keydown.escape)="onEscDown()"
    *ngFor="let result of results"
    class="search-result"
    tabindex="0">
    <div class="result-left">
      <div class="breadcrumb-wrapper" matTooltip="{{ getToolTipForBreadcrumb(result) }}">
        <div class="rep-container">
          <span class="breadcrumb-arrow inner"></span>
          <span class="breadcrumb-arrow outer"></span>
          <span class="breadcrumb">{{ this.getBreadcrumbsFirstLabelForEntry(result) }}</span>
        </div>

        <span class="breadcrumb-arrow inner"></span>
        <span class="breadcrumb-arrow outer"></span>
        <span class="breadcrumb">...</span>

        <span class="breadcrumb-arrow inner"></span>
        <span class="breadcrumb-arrow outer green"></span>
        <span class="label">{{ result.label }}</span>
      </div>
      <app-highlight-text
        *ngIf="modelHasValue(result.entry)"
        [query]="query"
        [searchResult]="result">
      </app-highlight-text>
    </div>
  </a>
</div>
