import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CreateGroupDialogComponent } from '../create-group-dialog/create-group-dialog.component';
import { EditGroupDialogComponent } from '../edit-group-dialog/edit-group-dialog.component';
import { GruppenEinladungDialogComponent } from '../gruppen-einladung-dialog/gruppen-einladung-dialog.component';
import { GroupMemberModel, GroupModel } from "../../api/user-service";

@Component({
  selector: 'app-gruppen-list',
  templateUrl: './gruppen-list.component.html',
  styleUrls: ['./gruppen-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GruppenListComponent implements OnDestroy {
  @Input() gruppen: GroupModel[];

  @Output() createGroup = new EventEmitter<GroupModel>();
  @Output() updateGroup = new EventEmitter<GroupModel>();
  @Output() leaveGroup = new EventEmitter<GroupModel>();
  @Output() removeGroup = new EventEmitter<GroupModel>();
  @Output() invitationSent = new EventEmitter<{ groupId: number; invitedMember: GroupMemberModel }>();

  @Output() removeMember = new EventEmitter<{ memberId: number; groupId: number }>();
  @Output() transferOwnership = new EventEmitter<{ memberId: number; groupId: number }>();
  @Output() withdrawInvitation = new EventEmitter<{ memberId: number; groupId: number }>();
  @Output() acceptInvitation = new EventEmitter<number>();
  @Output() declineInvitation = new EventEmitter<number>();

  @Output() search = new EventEmitter<string>();

  @ViewChild(EditGroupDialogComponent) editGroupDialog!: EditGroupDialogComponent;
  @ViewChild(CreateGroupDialogComponent) createGroupDialog!: CreateGroupDialogComponent;
  @ViewChild(GruppenEinladungDialogComponent) sendInvitationDialog!: GruppenEinladungDialogComponent;

  loggedInUserId$: Observable<string>;
  hasSupportRole: boolean;

  searchField = new FormControl();

  private keycloak = inject(KeycloakService);

  private destroy$ = new Subject<void>();

  constructor() {
    this.loggedInUserId$ = from(this.keycloak.loadUserProfile()).pipe(map(profile => profile.id));
    this.hasSupportRole = this.keycloak.isUserInRole('support');

    this.searchField.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(searchTerm => this.search.emit(searchTerm));
  }

  handleCreateGroupClick(): void {
    this.createGroupDialog.openDialog();
  }

  onChangeName(gruppe: GroupModel): void {
    this.editGroupDialog.openDialog(gruppe);
  }

  onCreateInvitation(groupId: number): void {
    this.sendInvitationDialog.openDialog(groupId);
  }

  onLeaveGroup(gruppe: GroupModel): void {
    this.leaveGroup.emit(gruppe);
  }

  onRemoveGroup(gruppe: GroupModel): void {
    this.removeGroup.emit(gruppe);
  }

  onChangeNameConfirmation(gruppe: GroupModel): void {
    this.updateGroup.emit(gruppe)
  }

  onGruppeErstellenConfirmation(gruppe: GroupModel): void {
    this.createGroup.emit(gruppe);
  }

  onInvitationSent(payload: { groupId: number; invitedMember: GroupMemberModel }): void {
    this.invitationSent.emit(payload);
  }

  onRemoveMember(payload: { memberId: number; groupId: number }): void {
    this.removeMember.emit(payload);
  }

  onTransferOwnership(payload: { memberId: number; groupId: number }): void {
    this.transferOwnership.emit(payload);
  }

  onWithdrawInvitation(payload: { memberId: number; groupId: number }): void {
    this.withdrawInvitation.emit(payload);
  }

  identify(index: number, item: GroupModel) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
