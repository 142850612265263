<app-dialog
  (accept)="onAccept()"
  (dismiss)="onDismiss()"
  (closed)="onDismiss()"
  [hasCloseButton]="true"
  [autoClose]="false"
  title="Gruppenmitglied einladen"
  acceptLabel="Einladung senden"
  dismissLabel="Abbrechen">
  <div>
    <form *ngIf="form" [formGroup]="form" aria-label="Gruppenmitglied einladen">
      <div>
        <label>
          Benutzername
          <span class="required-asterisk">*</span>
          <input
            formControlName="username"
            class="form-textarea required"
            [class.has-error]="!form.get('username').valid && form.get('username').touched"
            name="username"
            placeholder="rs-döe-0123456789"
            required />
          <mat-error *ngIf="form.get('username').hasError('USER_NOT_FOUND')">Ein Benutzer mit diesem Namen wurde nicht gefunden.</mat-error>
          <mat-error *ngIf="form.get('username').hasError('USER_ALREADY_IN_GROUP')">Der Benutzer wurde bereits eingeladen oder ist bereits Mitglied dieser Gruppe.</mat-error>
        </label>
      </div>
    </form>
  </div>
</app-dialog>
