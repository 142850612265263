import { Injectable } from '@angular/core';
import postalToNuts from '../../assets/postal-to-nuts.json';
import postalToTown from '../../assets/postal-to-town.json';
import countryCodes from '../../assets/country-codes.json';

@Injectable({
  providedIn: 'root',
})
export class PostalCodeService {
  getNutsCodeForPostalCode(postalCode: string): string {
    return postalCode ? postalToNuts.find(obj => obj.plz === postalCode)?.nuts : undefined;
  }

  getTownForPostalCode(postalCode: string): string | undefined {
    const towns = postalToTown.filter(obj => obj.plz === postalCode);
    if (towns.length === 1 && postalCode) {
      return towns[0].town;
    }
    return undefined;
  }

  getThreeDigitCountryCode(nutsCode: string) {
    return nutsCode
      ? countryCodes.find(obj => nutsCode.startsWith(obj.twoDigits))?.threeDigits
      : undefined;
  }
}
