import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { defer, from, of, iif } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { NoticeActionDto, NoticeControllerService } from '../../api/notices';
import { NoticeOverviewViewModel } from '../NoticeOverviewViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VorlagenMetadataConfirmedEvent,
  VorlagenNameDialogComponent,
} from '../../vorlagen-uebersicht/vorlagen-name-dialog/vorlagen-name-dialog.component';
import { TemplateNameAndDescriptionService } from '../bekanntmachungen-auswahl/template-name-and-description.service';
import { RsTemplateSubmittingService } from '../../../services/rs-template-submitting.service';
import { ConceptModel } from '../../../types/concept-node';
import { NavigationUtil } from '../../shared/navigation.util';

@Component({
  selector: 'app-bekanntmachung-aktionen',
  templateUrl: './bekanntmachung-aktionen.component.html',
  styleUrls: ['./bekanntmachung-aktionen.component.scss'],
  providers: [RsTemplateSubmittingService], // TODO: Workaround: Muss hier zugefügt werden, weil der Service sonst nur in der FormRoot-Component provided wird
})
export class BekanntmachungAktionenComponent {
  @ViewChild(VorlagenNameDialogComponent) vorlagenNameDialog!: VorlagenNameDialogComponent;

  @Input() notice: NoticeOverviewViewModel;

  @Output() triggerAction = new EventEmitter<{ actionType: string, rsNoticeId: number; }>();

  protected buildQueryParamsNotice = NavigationUtil.buildQueryParamsNotice;

  constructor(
    private genericDialogService: GenericDialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private templateNameAndDescriptionService: TemplateNameAndDescriptionService,
    private rsTemplateService: RsTemplateSubmittingService,
    private noticeService: NoticeControllerService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {}

  showPreview(actionType: string): void {
    this.triggerAction.emit({ actionType, rsNoticeId: this.notice.notice.rsNoticeId });
  }

  deleteDraft(actionType: string) {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Entwurf löschen',
      'Entwurf löschen',
      'Abbrechen',
      'Sind Sie sicher, dass Sie den Entwurf löschen möchten?'
    );

    from(confirmationResult)
      .pipe(
        filter(confirmed => confirmed),
        tap(() => this.triggerAction.emit({ actionType, rsNoticeId: this.notice.notice.rsNoticeId }))
      )
      .subscribe();
  }

  stopNotice(actionType: string) {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Bekanntmachung zurückziehen',
      'Bekanntmachung zurückziehen',
      'Abbrechen',
      'Sind Sie sicher, dass Sie die Bekanntmachung zurückziehen wollen?'
    );
    from(confirmationResult)
      .pipe(
        filter(confirmed => confirmed),
        tap(() => this.triggerAction.emit({ actionType, rsNoticeId: this.notice.notice.rsNoticeId }))
      )
      .subscribe();
  }

  exportNotice(actionType: string) {
    this.triggerAction.emit({ actionType, rsNoticeId: this.notice.notice.rsNoticeId });
  }

  editDraft(subTypeId: string, action: NoticeActionDto) {
    const navigate$ = iif(
      () => this.notice.notice.latestSdkVersion,
      of(true),
      this.confirmEditDraftModalDialog()
    );

    return navigate$
      .pipe(
        filter(Boolean),
        switchMap(() => this.navigate(subTypeId, action))
      )
      .subscribe();
  }

  createUpdateOrSuccessor(subTypeId: string, action: NoticeActionDto) {
    const navigate$ = iif(
      () => this.notice.notice.latestSdkVersion,
      of(true),
      this.confirmCreateSuccessorModalDialog()
    );

    return navigate$
      .pipe(
        filter(Boolean),
        switchMap(() => this.navigate(subTypeId, action))
      )
      .subscribe();
  }

  vorlageErstellen() {
    const navigate$ = iif(
      () => this.notice.notice.latestSdkVersion,
      of(true),
      this.confirmTemplateModalDialog()
    );

    return navigate$
      .pipe(
        filter(Boolean),
        map(() => this.vorlagenNameDialog.openDialog())
      )
      .subscribe();
  }

  createTemplate($event: VorlagenMetadataConfirmedEvent) {
    this.templateNameAndDescriptionService.setValues(
      $event.metadata.name,
      $event.metadata.description
    );

    this.loadingSpinnerService.start();
    this.noticeService
      .getNoticeFormValueForSavedNotice(this.notice.notice.rsNoticeId)
      .pipe(
        map(json => json as unknown as ConceptModel),
        switchMap(conceptModel =>
          this.rsTemplateService.createTemplateFromNotice(conceptModel).pipe(
            switchMap(rsTemplateId =>
              this.router.navigate(
                ['vorlagen', 'anlegen', 'notice', conceptModel.noticeSubTypeCode, 'details'],
                {
                  queryParams: NavigationUtil.buildQueryParamsTemplate(!this.notice.notice.latestSdkVersion, rsTemplateId),
                }
              )
            )
          )
        ),
        finalize(() => this.loadingSpinnerService.stop())
      )
      .subscribe();
  }

  private navigate(subTypeId: string, action: NoticeActionDto) {
    return from(
      this.router.navigate(['notice', subTypeId, 'details'], {
        relativeTo: this.activatedRoute,
        queryParams: NavigationUtil.buildQueryParamsNotice(action, this.notice),
      })
    );
  }

  private confirmTemplateModalDialog() {
    return defer(() =>
      this.genericDialogService.confirmWithWarningDialog(
        'WARNUNG: Aktualisierung des eForms-Standards',
        'Akzeptieren',
        'Abbrechen',
        '<b>Grund der Warnung</b><br>' +
          'Der eForms-Standard wurde aktualisiert, seit diese Bekanntmachung das letzte Mal bearbeitet wurde.<br><br>' +
          '<b>Was ist zu beachten?</b><br>' +
          'Die Vorlage wird automatisch gemäß des aktuellen Standards generiert. Bitte überprüfen Sie, ob alle wichtigen Daten in die Vorlage übernommen wurden. Unter Umständen kann es bei der automatischen Vorbefüllung der Felder mit den Werten aus der ursprünglichen Bekanntmachung zu Fehlern kommen.'
      )
    );
  }

  private confirmEditDraftModalDialog() {
    return defer(() =>
      this.genericDialogService.confirmWithWarningDialog(
        'WARNUNG: Aktualisierung des eForms-Standards',
        'Akzeptieren',
        'Abbrechen',
        '<b>Grund der Warnung</b><br>' +
          'Der eForms-Standard wurde aktualisiert, seit diese Bekanntmachung das letzte Mal bearbeitet wurde.<br><br>' +
          '<b>Was ist zu beachten?</b><br>' +
          'Nach dem Öffnen können Sie die Bekanntmachung wie gewohnt anzeigen und bearbeiten. Bitte überprüfen ' +
          'Sie vor dem Versenden der Bekanntmachung, ob alle wichtigen Daten vorhanden sind. Unter Umständen ' +
          'kann es bei der Aktualisierung des eForms-Standards zu einem teilweisen Datenverlust kommen.'
      )
    );
  }

  private confirmCreateSuccessorModalDialog() {
    return defer(() =>
      this.genericDialogService.confirmWithWarningDialog(
        'WARNUNG: Aktualisierung des eForms-Standards',
        'Akzeptieren',
        'Abbrechen',
        '<b>Grund der Warnung</b><br>' +
          'Der eForms-Standard wurde aktualisiert, seit die ursprüngliche Bekanntmachung versendet wurde.<br><br>' +
          '<b>Was ist zu beachten?</b><br>' +
          'Nach dem Öffnen können Sie die Bekanntmachung wie gewohnt bearbeiten. Bitte überprüfen ' +
          'Sie vor dem Versenden der Bekanntmachung, ob alle wichtigen Daten vorhanden sind. Unter Umständen ' +
          'kann es bei der automatischen Vorbefüllung der Felder mit den Werten aus der ursprünglichen Bekanntmachung zu Fehlern kommen.'
      )
    );
  }
}
