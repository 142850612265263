import { FieldModel } from '../../view-model/field-model';
import { ModelListener } from './ModelListener';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { StateService } from '../state.service';

export class ProcedureTypeListener extends ModelListener<CodelistModel> {
  constructor(private stateService: StateService) {
    super();
  }

  onChange(model: CodelistModel): void {
    this.stateService.setActiveProcedureType(model.value);
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model.noticeNode?.id === 'BT-105-Procedure';
  }
}
