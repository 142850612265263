import { ValidationNotification } from '../../../../types/app-types';
import { ModelValidator } from '../ModelValidator';
import { TimeModel } from '../../../../view-model/type/time-model';

export class TimeValidator extends ModelValidator<TimeModel> {
  private static readonly TIME_WITHOUT_DATE_INPUT_ERROR_MSG =
    'Die Eingabe ist unvollständig. Eine Zeitangabe ohne Datumsangabe ist nicht erlaubt.';

  validate(timeModel: TimeModel): Promise<ValidationNotification[]> {
    if (!timeModel.isMandatory && !timeModel.relatedDateModel?.value) {
      return this.ofSingle(TimeValidator.TIME_WITHOUT_DATE_INPUT_ERROR_MSG);
    }

    return this.empty();
  }

  shouldAttach(model: TimeModel): boolean {
    return model instanceof TimeModel;
  }
}
