<footer>
  <h1 id="headingfooter" class="visually-hidden">Footer</h1>
  <ng-container *ngIf="isOBA">
    <div class="section">
      <button (click)="exportXML()" class="eForms-button eForms-button--primary">XML-Export</button>
    </div>
    <div class="section">
      <button class="eForms-button eForms-button--primary" (click)="cancelAndClose()">
        {{ cancelText }}
      </button>
    </div>
    <div class="section">
      <button
        *ngIf="canSaveDraft"
        (click)="saveDraft()"
        class="eForms-button eForms-button--primary"
        [disabled]="!modifiedSinceLastSave">
        {{ modifiedSinceLastSave ? ' Entwurf speichern' : ' Gespeichert' }}
      </button>
      <button (click)="validate()" class="eForms-button eForms-button--primary">
        Eingaben prüfen
      </button>
      <button
        (click)="openPreview()"
        *ngIf="!isUpdateMetaData"
        class="eForms-button eForms-button--primary">
        Voransicht öffnen
      </button>
    </div>
    <div
      [matTooltipDisabled]="formValidity"
      matTooltip="Bitte führen Sie zunächst 'Eingaben Prüfen' aus und beheben Sie dann alle verbleibenden Fehler.">
      <button
        (click)="submitForm()"
        class="eForms-button eForms-button--primary"
        [disabled]="!formValidity && !devMode">
        {{ getSendText() }}
      </button>
      <button
        *ngIf="isPriorInformation && !isChangeNotice && !isAlterBeforeRelease"
        (click)="submitForm(true)"
        class="eForms-button eForms-button--primary"
        [disabled]="!formValidity && !devMode">
        Vorinformation freigeben
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="isRS">
    <div class="section">
      <button
        *ngIf="!isRsTemplateMode"
        (click)="saveForRedaktionssystem()"
        class="eForms-button eForms-button--primary"
        [disabled]="!modifiedSinceLastSave">
        {{ modifiedSinceLastSave ? ' Als Entwurf speichern' : ' Gespeichert' }}
      </button>
      <button
        *ngIf="isRsTemplateMode"
        (click)="saveTemplateForRedaktionssystem()"
        class="eForms-button eForms-button--primary"
        [disabled]="!modifiedSinceLastSave">
        {{ modifiedSinceLastSave ? ' Vorlage speichern' : 'Vorlage gespeichert' }}
      </button>
    </div>
    <div class="section">
      <button
        class="eForms-button eForms-button--primary"
        (click)="cancelAndCloseForRedaktionssystem()">
        {{ cancelText }}
      </button>
    </div>
    <div class="section" style="justify-content: flex-end">
      <button (click)="validate()" class="eForms-button eForms-button--primary">
        Eingaben prüfen
      </button>
      <div
        style="display: inline-block"
        [matTooltipDisabled]="formValidity"
        matTooltip="Bitte führen Sie zunächst 'Eingaben prüfen' aus und beheben Sie dann alle verbleibenden Fehler.">
        <button
          *ngIf="!isRsTemplateMode"
          (click)="saveAndSendForRedaktionssystem()"
          [disabled]="!formValidity && !devMode"
          class="eForms-button eForms-button--primary">
          An Vermittlungsdienst senden
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isSEB">
    <div class="section start">
      <button (click)="validateForSEB()" class="eForms-button eForms-button--primary">
        Eingaben prüfen
      </button>
      <button class="eForms-button eForms-button--primary" (click)="openPreview()">
        Voransicht öffnen
      </button>
    </div>
    <div class="section end">
      <button class="eForms-button eForms-button--primary" (click)="cancelAndClose()">
        Erfassung beenden
      </button>
      <button (click)="saveIntermediateStateForSEB()" class="eForms-button eForms-button--primary">
        Zwischenstand speichern
      </button>
      <div
        [matTooltipDisabled]="formValidity"
        matTooltip="Bitte führen Sie zunächst 'Eingaben Prüfen' aus und beheben Sie dann alle verbleibenden Fehler.">
        <button
          (click)="saveFinalStateForSEB()"
          class="eForms-button eForms-button--primary"
          [disabled]="!formValidity && !devMode">
          Finalen Stand speichern
        </button>
      </div>
    </div>
  </ng-container>
</footer>
