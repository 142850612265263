import { Injectable } from '@angular/core';
import { FeatureFlagsControllerService } from './api/notices';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureMap: Map<string, any> = new Map();

  constructor(private featureFlagsControllerService: FeatureFlagsControllerService) {
    this.featureFlagsControllerService.getFeatureFlags().subscribe(response => {
      this.featureMap = new Map(Object.entries(response));
      return response;
    });
  }

  isFeatureEnabled(featureName: string): boolean {
    const featureValue = this.featureMap.get(featureName);
    if (featureValue === undefined) {
      return false;
    } else if (typeof featureValue === 'boolean') {
      return featureValue;
    } else {
      return true;
    }
  }

  getFeatureValue(featureName: string): any {
    return this.featureMap.get(featureName);
  }
}
