import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
})
export class PortalHeaderComponent implements OnInit {
  loggedIn$: Observable<boolean>;
  username$: Observable<string>;

  constructor(private keycloak: KeycloakService) {}

  ngOnInit(): void {
    this.loggedIn$ = from(this.keycloak.isLoggedIn());
    this.username$ = this.loggedIn$.pipe(
      filter(loggedIn => loggedIn),
      map(() => this.keycloak.getUsername())
    );
  }

  login(): void {
    this.keycloak.login();
  }

  logout(): void {
    this.keycloak.logout(window.location.origin);
  }
}
