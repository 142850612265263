import { createReducer, on } from '@ngrx/store';
import * as NoticeLastEditedActions from './notice-last-edited.action';

export const initialState = new Map<number, string>();

/**
 * Reducer für die Notice-Last-Edited Header, damit das Backend pruefen kann, ob in der Zwischenzeit Veraenderungen an der Notice stattgefunden haben. (z.B. durch einen anderen User)
 */
export const noticeLastEditedReducer = createReducer(
  initialState,
  on(NoticeLastEditedActions.put, (state: Map<number, string>, props) => {
    const newState = new Map<number, string>(state);
    newState.set(props.rsNoticeId, props.noticeLastEdited);
    return newState;
  }),
  on(NoticeLastEditedActions.remove, (state: Map<number, string>, props) => {
    const newState = new Map<number, string>(state);
    newState.delete(props.rsNoticeId);
    return newState;
  })
);
