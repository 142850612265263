<div class="form-component-input-group" [hidden]="model.isHidden">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="input-container">
    <form [attr.aria-label]="model.translatedLabel">
      <input
        [id]="htmlId"
        type="radio"
        [name]="'radioForm_' + htmlId"
        [value]="true"
        [formControl]="inputControl"
        class="form-input-radio"
        [class.has-error]="hasInputError()"
        [class.required]="model.isMandatory && !model.isReadonly"
        aria-label="Ja" />
      <label [for]="htmlId" class="form-input-radio-label form-input-radio-label-cursor-pointer"
        >Ja</label
      ><br />
      <input
        [id]="htmlId + '_2'"
        type="radio"
        [name]="'radioForm_' + htmlId"
        [value]="false"
        [formControl]="inputControl"
        class="form-input-radio"
        [class.has-error]="hasInputError()"
        [class.required]="model.isMandatory && !model.isReadonly"
        aria-label="Nein" />
      <label
        [for]="htmlId + '_2'"
        class="form-input-radio-label form-input-radio-label-cursor-pointer"
        >Nein</label
      ><br />
    </form>
    <button
      (click)="reset()"
      *ngIf="!model.isReadonly"
      [disabled]="inputControl.value === null || inputControl.value === undefined"
      class="eForms-button">
      Zurücksetzen
    </button>
  </div>
  <app-validation-message [model]="model"></app-validation-message>
</div>
