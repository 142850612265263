import { LocalDate } from '@js-joda/core';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { DateModel } from '../../../../view-model/type/date-model';
import { ModelValidator } from '../ModelValidator';

export class TooFarInPastValidator extends ModelValidator<DateModel> {
  private static readonly TOO_FAR_IN_PAST_ERROR_MSG= 'Dieses Datum darf nicht so weit in der Vergangenheit liegen.';

  constructor() {
    super();
  }

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    if (dateModel.value && dateModel.asLocalDate().isBefore(LocalDate.of(1970,1,1))) {
      return this.ofSingle(
        TooFarInPastValidator.TOO_FAR_IN_PAST_ERROR_MSG,
        Severity.ERROR
      );
    }

    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return (
      model instanceof DateModel &&
      !model.isReadonly
    );
  }
}
