import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';

export interface DialogData {
  title?: string;
  content?: string;
  acceptLabel?: string;
  dismissLabel?: string;
  hasCloseButton?: boolean;
  success?: boolean;
  warning?: boolean;
  centered?: boolean;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent {
  @Input() title: string;
  @Input() acceptLabel: string;
  @Input() dismissLabel: string;
  @Input() hasCloseButton = true;
  @Input() autoClose = true;

  @Output() accept: EventEmitter<any> = new EventEmitter<any>();
  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  renderDialog = false;
  acceptButtonDisabled = false;

  constructor(
    private dialog: MatDialog,
    @Optional() private dialogRef?: MatDialogRef<DialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: DialogData
  ) {
    if (dialogRef) {
      this.renderDialog = true;
      this.configureDialog(dialogRef);
    }
  }

  openDialog(width?: string) {
    this.data = {
      title: this.title,
      content: null,
      acceptLabel: this.acceptLabel,
      dismissLabel: this.dismissLabel,
      hasCloseButton: this.hasCloseButton,
    } as DialogData;
    this.dialogRef = this.dialog.open(this.dialogTemplate, {
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
      width: width,
    });
    this.configureDialog(this.dialogRef);
    return this.dialogRef;
  }

  close() {
    this.dialogRef.close();
  }

  accepted() {
    this.accept.emit();
  }

  dismissed() {
    this.dismiss.emit();
  }

  public enableAcceptButton() {
    this.acceptButtonDisabled = false;
  }

  public disableAcceptButton() {
    this.acceptButtonDisabled = true;
  }

  private configureDialog(dialogRef: MatDialogRef<DialogComponent>) {
    dialogRef.addPanelClass('eForms-dialog');

    dialogRef.disableClose = true;
    dialogRef
      .keydownEvents()
      .pipe(
        filter((e: KeyboardEvent) => e.code === 'Escape'),
        take(1)
      )
      .subscribe(() => dialogRef.close());

    dialogRef.afterClosed().subscribe(() => {
      this.closed.emit();
    });
  }
}
