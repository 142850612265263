import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DialogModule } from '../components/layout/dialog/dialog.module';
import { MaterialModule } from '../material-modules';
import { NoticeActionTypeToLabelPipe } from './bekanntmachung/bekanntmachung-aktionen/aktion-type-to-label.pipe';
import { FormTypeToLabelPipe } from './bekanntmachung/bekanntmachungen-auswahl/formtype-to-label.pipe';
import { BekanntmachungAktionenComponent } from './bekanntmachung/bekanntmachung-aktionen/bekanntmachung-aktionen.component';
import { BekanntmachungTableComponent } from './bekanntmachung/bekanntmachung-table/bekanntmachung-table.component';
import { IsFavoritePipe } from './bekanntmachung/bekanntmachungen-verwaltung/is-favorite.pipe';
import { IsHiddenPipe } from './bekanntmachung/bekanntmachungen-verwaltung/is-hidden.pipe';
import { CreateGroupDialogComponent } from './gruppen/create-group-dialog/create-group-dialog.component';
import { EditGroupDialogComponent } from './gruppen/edit-group-dialog/edit-group-dialog.component';
import { NutzerkontoComponent } from './nutzerkonto/nutzerkonto.component';
import { BekanntmachungenAuswahlComponent } from './bekanntmachung/bekanntmachungen-auswahl/bekanntmachungen-auswahl.component';
import { LegalBasisToLabelPipe } from './bekanntmachung/bekanntmachungen-auswahl/legal-basis-to-label.pipe';
import { NoticeTypeToLabelPipe } from './bekanntmachung/bekanntmachungen-auswahl/notice-type-to-label.pipe';
import { ProcedureGetLatestTitlePipe } from './bekanntmachung/bekanntmachungen-verwaltung/procedure-get-latest-title.pipe';
import { BekanntmachungenVerwaltungComponent } from './bekanntmachung/bekanntmachungen-verwaltung/bekanntmachungen-verwaltung.component';
import { SubTypeIdToNamePipe } from './bekanntmachung/bekanntmachungen-verwaltung/sub-type-id-to-name.pipe';
import { NeuigkeitenVerwaltungComponent } from './neuigkeiten-verwaltung/neuigkeiten-verwaltung.component';
import { FeatureFlagService } from './feature-flag.service';
import { PortalRoutingModule } from './portal-routing.module';
import { AnleitungenComponent } from './redaktionssystem/anleitung/anleitungen.component';
import { BarrierefreiheitComponent } from './redaktionssystem/barrierefreiheit/barrierefreiheit.component';
import { DatenschutzComponent } from './redaktionssystem/datenschutz/datenschutz.component';
import { FaqItemComponent } from './redaktionssystem/faq-item/faq-item.component';
import { FaqComponent } from './redaktionssystem/faq/faq.component';
import { NeueigkeitComponent } from './redaktionssystem/home/neueigkeit/neueigkeit.component';
import { RedaktionssystemComponent } from './redaktionssystem/home/redaktionssystem/redaktionssystem.component';
import { ImpressumComponent } from './redaktionssystem/impressum/impressum.component';
import { KontaktComponent } from './redaktionssystem/kontakt/kontakt.component';
import { LeichteSpracheComponent } from './redaktionssystem/leichte-sprache/leichte-sprache.component';
import { LizenzenComponent } from './redaktionssystem/lizenzen/lizenzen.component';
import { NutzungsbedingungenComponent } from './redaktionssystem/nutzungsbedingungen/nutzungsbedingungen.component';
import { PortalContentComponent } from './redaktionssystem/portal-content/portal-content.component';
import { PortalFooterComponent } from './redaktionssystem/portal-footer/portal-footer.component';
import { PortalHeaderComponent } from './redaktionssystem/portal-header/portal-header.component';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { FilterDisplayColumnsPipe } from './shared/filter-display-columns.pipe';
import { HasAnyRoleDirective } from './shared/has-any-role.directive';
import { UserServiceJwtInterceptor } from './shared/user-service-jwt.interceptor';
import { ValidationReportDialogComponent } from './shared/validation-report-dialog/validation-report-dialog.component';
import { HttpErrorInterceptor } from './shared/http-error.interceptor';
import { HttpHeaderInterceptor } from './shared/http-header.interceptor';
import { SkeletonComponent } from './shared/loading-skeleton/skeleton.component';
import { SkeletonDirective } from './shared/loading-skeleton/skeleton.directive';
import { TemplateComponent } from './shared/template/template.component';
import { StatusBadgeComponent } from './shared/status-badge/status-badge.component';
import { StatusBadgeClassPipe } from './shared/status-badge/status-badge-class.pipe';
import { VorlagenUbersichtComponent } from './vorlagen-uebersicht/vorlagen-ubersicht.component';
import { VorlagenUebersichtTableComponent } from './vorlagen-uebersicht/vorlagen-uebersicht-table/vorlagen-uebersicht-table.component';
import { VorlagenNameDialogComponent } from './vorlagen-uebersicht/vorlagen-name-dialog/vorlagen-name-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FilterTableComponent } from './shared/filter-table/filter-table.component';
import { VorlagenAuswahlComponent } from './vorlagen-auswahl/vorlagen-auswahl.component';
import { GruppenUebersichtComponent } from './gruppen/gruppen-uebersicht/gruppen-uebersicht.component';
import { GruppenItemComponent } from './gruppen/gruppen-item/gruppen-item.component';
import { GruppenListComponent } from './gruppen/gruppen-list/gruppen-list.component';
import { GruppenMitgliederComponent } from './gruppen/gruppen-mitglieder/gruppen-mitglieder.component';
import { EinladungItemComponent } from './gruppen/einladung/einladung-item/einladung-item.component';
import { EinladungListComponent } from './gruppen/einladung/einladung-list/einladung-list.component';
import { GruppenEinladungDialogComponent } from './gruppen/gruppen-einladung-dialog/gruppen-einladung-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
  GroupmemberDropdownComponent
} from "./bekanntmachung/bekanntmachungen-verwaltung/groupmember-dropdown/groupmember-dropdown.component";
import { AuthErrorDialogComponent } from "./shared/auth-error-dialog/auth-error-dialog.component";
import { AuthErrorDialogService } from "./shared/auth-error-dialog/auth-error-dialog.service";
import {
  AuthErrorDialogRedirectTargetComponent
} from "./shared/auth-error-dialog/auth-error-dialog-redirect-target/auth-error-dialog-redirect-target.component";
import { keycloakFactory } from "./shared/auth/keycloak.factory";
import {HinweisBannerComponent} from "./redaktionssystem/hinweis-banner/hinweis-banner.component";

@NgModule({
  declarations: [
    RedaktionssystemComponent,
    NutzerkontoComponent,
    NeueigkeitComponent,
    TemplateComponent,
    AnleitungenComponent,
    NutzungsbedingungenComponent,
    LeichteSpracheComponent,
    LizenzenComponent,
    DatenschutzComponent,
    FaqComponent,
    KontaktComponent,
    BarrierefreiheitComponent,
    ImpressumComponent,
    PortalFooterComponent,
    PortalHeaderComponent,
    BekanntmachungenVerwaltungComponent,
    IsFavoritePipe,
    IsHiddenPipe,
    BekanntmachungAktionenComponent,
    NoticeActionTypeToLabelPipe,
    SubTypeIdToNamePipe,
    BekanntmachungTableComponent,
    BekanntmachungenAuswahlComponent,
    LegalBasisToLabelPipe,
    NoticeTypeToLabelPipe,
    FormTypeToLabelPipe,
    ProcedureGetLatestTitlePipe,
    PortalContentComponent,
    ErrorDialogComponent,
    ValidationReportDialogComponent,
    FaqItemComponent,
    NeuigkeitenVerwaltungComponent,
    HinweisBannerComponent,
    SkeletonComponent,
    SkeletonDirective,
    StatusBadgeComponent,
    StatusBadgeClassPipe,
    VorlagenUbersichtComponent,
    VorlagenUebersichtTableComponent,
    VorlagenNameDialogComponent,
    NotFoundComponent,
    FilterTableComponent,
    VorlagenAuswahlComponent,
    AuthErrorDialogComponent,
    AuthErrorDialogRedirectTargetComponent,
    GruppenUebersichtComponent,
    EditGroupDialogComponent,
    CreateGroupDialogComponent,
    GruppenItemComponent,
    GruppenListComponent,
    GruppenMitgliederComponent,
    EinladungItemComponent,
    EinladungListComponent,
    GruppenEinladungDialogComponent,
    GroupmemberDropdownComponent,
    FilterDisplayColumnsPipe,
    HasAnyRoleDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    PortalRoutingModule,
    KeycloakAngularModule,
    DialogModule,
    MatPaginatorModule,
  ],
  exports: [PortalFooterComponent, PortalContentComponent, PortalHeaderComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakFactory,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service) => () => {},
      multi: true,
      deps: [AuthErrorDialogService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserServiceJwtInterceptor,
      multi: true,
    },
    FeatureFlagService,
  ],
})
export class PortalModule {}
