import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExpansionService } from '../../../../services/ui/expansion.service';

@Component({
  selector: 'app-collapse-expand',
  templateUrl: './collapse-expand.component.html',
  styleUrls: ['./collapse-expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseExpandComponent {
  constructor(private expansionService: ExpansionService) {}

  expandAllGroups() {
    this.expansionService.expandAllGroups();
  }

  collapseAllGroups() {
    this.expansionService.collapseAllGroups();
  }

  toggleDescriptions() {
    this.expansionService.toggleDescriptions();
  }
}
