<div [hidden]="model.isHidden" class="form-component-input-group" [id]="htmlId">
  <div *ngIf="model?.isKmuSelectionEnabled">
    <app-field-header [model]="dummyHeaderModel"></app-field-header>
    <div class="kmu-input-container">
      <input
        type="checkbox"
        [id]="htmlId + '_1'"
        name="freelance"
        [formControl]="freelanceControl"
        class="form-input-checkbox"
        [class.has-error]="hasInputError()"
        [class.required]="!model.isReadonly"
        aria-label="Freiberufler" />
      <label
        [for]="htmlId + '_1'"
        class="form-input-checkbox-label form-input-checkbox-label-cursor-pointer"
        >Freiberufler</label
      >
      <input
        type="checkbox"
        [id]="htmlId + '_2'"
        name="selbst"
        [formControl]="selbstControl"
        class="form-input-checkbox"
        [class.has-error]="hasInputError()"
        [class.required]="!model.isReadonly"
        aria-label="Selbstständige" />
      <label
        [for]="htmlId + '_2'"
        class="form-input-checkbox-label form-input-checkbox-label-cursor-pointer"
        >Selbstständige</label
      >
      <input
        type="checkbox"
        [id]="htmlId + '_3'"
        name="startup"
        [formControl]="startupControl"
        class="form-input-checkbox"
        [class.has-error]="hasInputError()"
        [class.required]="!model.isReadonly"
        aria-label="Start-Ups" />
      <label
        [for]="htmlId + '_3'"
        class="form-input-checkbox-label form-input-checkbox-label-cursor-pointer"
        >Start-Ups</label
      >
      <input
        type="checkbox"
        [id]="htmlId + '_4'"
        name="otherSme"
        [formControl]="otherSmeControl"
        class="form-input-checkbox"
        [class.has-error]="hasInputError()"
        [class.required]="!model.isReadonly"
        aria-label="Sonstige KMU" />
      <label
        [for]="htmlId + '_4'"
        class="form-input-checkbox-label form-input-checkbox-label-cursor-pointer"
        >Sonstige KMU</label
      >
      <button
        (click)="reset()"
        *ngIf="!model.isReadonly"
        [disabled]="!model.kmuValues.length"
        class="eForms-button">
        Zurücksetzen
      </button>
    </div>
  </div>

  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="multilingual-content-wrapper">
    <app-language-text-area
      (validateField)="validateGermanField()"
      *ngIf="isGerman()"
      [htmlId]="htmlId + '-DEU'"
      [control]="germanControl"
      [focus]="focusGermanEmitter"
      [language]="Language.Deu"
      [model]="model">
    </app-language-text-area>
    <div *ngIf="isGerman() && isEnglish()" class="spacer"></div>
    <app-language-text-area
      (validateField)="validateEnglishField()"
      *ngIf="isEnglish()"
      [htmlId]="htmlId + '-ENG'"
      [control]="englishControl"
      [focus]="focusEnglishEmitter"
      [language]="Language.Eng"
      [model]="model">
    </app-language-text-area>
  </div>
  <ng-container *ngIf="!model.isHidden">
    <app-validation-message [model]="model"></app-validation-message>
  </ng-container>
</div>
