import { NoticeNode } from './notice-definition';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ValidationMode } from './validation-mode';
import { ConceptModel } from './concept-node';
import { Language, ParentGroup } from './data-model';
import { BaseModel } from '../view-model/base-model';
import { LocalDateTime } from '@js-joda/core';

export interface BusinessRuleResult {
  validationMessage?: string;
  messageId?: string;
  severity: Severity;
  error?: Error;
  jsFunction?: string;
  efx?: string | boolean;
  condition?: boolean;
  conditionResult?: boolean;
  jsCondition?: string;
  isConditional: boolean;
  resolvedValues?: any;
  resolvedConditionValues?: any;
}

export interface ValidationContext {
  htmlId: string;
  parentGroups: ParentGroup[];
  noticeNode: NoticeNode;
  currentControl: FormGroup;
  sectionId: string;
  isMandatory: boolean;
  validationMode: ValidationMode;
  conceptModel?: ConceptModel;
  language?: string;
  relatedControl?: AbstractControl;
}

export interface SearchResult {
  label: string;
  entry: BaseModel;
  previewText?: string;
  lot?: string;
  type: 'id' | 'string';
  language?: Language;
}

export enum Severity {
  ERROR = 'Fehler',
  WARNING = 'Warnung',
  INFO = 'Information',
}

export class ValidationNotification {
  private readonly _message: string;
  private readonly _severity: Severity;
  private readonly _targetLanguage: Language;

  constructor(message: string, severity: Severity, targetLanguage?: Language) {
    this._message = message;
    this._severity = severity;
    this._targetLanguage = targetLanguage;
  }

  get message(): string {
    return this._message;
  }

  get severity(): Severity {
    return this._severity;
  }

  get targetLanguage(): Language {
    return this._targetLanguage;
  }
}

export interface MaintenanceWindow {
  description: string;
  englishDescription: string;
  durationFrom: LocalDateTime;
  durationTill: LocalDateTime;
}

export interface TenderingProcedureResponseDto {
  tenderingProcedureId: number;
}
