<div>
  <table
    mat-table
    matSort
    matSortActive="noticeSubTypeCode"
    matSortDirection="asc"
    [dataSource]="dataSource">
    <ng-container matColumnDef="noticeSubTypeCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Formular-<br />Nummer</th>
      <td mat-cell *matCellDef="let element">
        {{ element.noticeSubTypeCode }}
      </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          matTooltip="Name und Beschreibung bearbeiten"
          (click)="onClickEditName(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Beschreibung</th>
      <td mat-cell *matCellDef="let element">
        {{ element.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customizationId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>SDK-Version</th>
      <td mat-cell *matCellDef="let element">
        {{ element.customizationId }}
        <mat-icon
          class="icon warning"
          *ngIf="!element.isLatestSDKVersion"
          (click)="onClickWarnIcon()">
          warning
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastEditedAt">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Letzte Bearbeitung</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastEditedAt | date : 'dd.MM.yyyy HH:mm' }} Uhr
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let element">
        <a
          *ngFor="let action of getActionsOfTemplate(element)"
          class="action-link"
          (click)="onActionClick(element, action)">
          {{ action }}</a
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns | filterDisplayColumns:['edit']:iAmOwner"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns | filterDisplayColumns:['edit']:iAmOwner"></tr>
  </table>
</div>
