import { ModelValidator } from '../ModelValidator';
import { ValidationNotification } from '../../../../types/app-types';
import {CodelistModel} from '../../../../view-model/type/codelist-model';
import {ModelStateService} from '../../../view-model/model-state.service';
import {FieldModel} from '../../../../view-model/field-model';

export class CvdBt723Validator extends ModelValidator<CodelistModel> {
  static readonly MANDATORY_ERROR_MSG = 'Bitte erfassen Sie in allen Feldern unter "Art und Menge der erworbenen Fahrzeuge" einen Wert.';

  constructor() {
    super();
  }

  validate(model: CodelistModel): Promise<ValidationNotification[]> {
    if (model.value) {
      const fieldModels = (ModelStateService.findModelsById(model.parent, 'OPT-156-LotResult') as FieldModel<any>[])
      const valid = fieldModels.length === 3 && fieldModels.every(fieldModel => {
        if (typeof fieldModel.value === 'string') {
          return +fieldModel.value >= 0;
        } else if (typeof fieldModel.value === 'number') {
          return fieldModel.value >= 0;
        }
        return false;
      });

      if (!valid) {
        return this.ofSingle(CvdBt723Validator.MANDATORY_ERROR_MSG);
      }
    }
    return this.empty();
  }

  shouldAttach(model: CodelistModel): boolean {
    return model.noticeNode.id === 'BT-723-LotResult';
  }
}
