import { createReducer, on } from '@ngrx/store';
import * as Modified from './modified.action';

export const initialState = false;

/**
 * Reducer for the Modified Form State
 */
export const modifiedReducer = createReducer(
  initialState,
  on(Modified.set, (_state, props) => props.modified)
);
