import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BekanntmachungenVerwaltungStateService } from './bekanntmachungen-verwaltung-state.service';

@Pipe({ name: 'isHidden' })
export class IsHiddenPipe implements PipeTransform {
  constructor(private state: BekanntmachungenVerwaltungStateService) {}

  transform(procedureId: number): Observable<boolean> {
    return this.state.hiddenProcedures$.pipe(
      map(list => list.includes(procedureId))
    );
  }
}
