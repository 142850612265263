<div class="form-component-input-group" [id]="htmlId">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="input-container">
    <input
      [formControl]="displayProjectControl"
      aria-label="Projekt auswählen"
      class="form-text-field"
      placeholder="Keine Projektzuordnung" />
    <button (click)="openProjectDialog()" class="eForms-button">Projekt wählen</button>
  </div>
</div>

<app-dialog
  #projectChooserDialog
  (accept)="setProject()"
  [hasCloseButton]="false"
  title="Projekt erstellen oder zuordnen"
  acceptLabel="Bestätigen"
  dismissLabel="Abbrechen">
  <form class="dialog-content">
    <div class="radio-label-group">
      <input
        id="project-assigner-radio_1"
        type="radio"
        name="project-assigner-radio"
        [value]="projectIndicator.NONE"
        [formControl]="indicatorControl"
        class="form-input-radio"
        aria-label="Verfahren keinem Projekt zuordnen" />
      <label
        for="project-assigner-radio_1"
        class="form-input-radio-label form-input-radio-label-cursor-pointer"
        >Verfahren keinem Projekt zuordnen</label
      >
    </div>
    <div class="radio-label-group">
      <input
        id="project-assigner-radio_2"
        type="radio"
        name="project-assigner-radio"
        [value]="projectIndicator.EXISTING"
        [formControl]="indicatorControl"
        class="form-input-radio"
        aria-label="Verfahren einem vorhandenen Projekt zuordnen" />
      <label
        for="project-assigner-radio_2"
        class="form-input-radio-label form-input-radio-label-cursor-pointer"
        >Verfahren einem vorhandenen Projekt zuordnen</label
      >
    </div>

    <div class="form-component-input-group">
      <label [for]="'project-selection'">
        Projekt
        <span [appMarkAsterisk]="indicatorControl.value === projectIndicator.EXISTING"></span>
      </label>
      <div class="input-group-addon wrapper">
        <select
          [formControl]="projectSelectControl"
          [id]="'project-selection'"
          class="required"
          euiSelect
          placeholder="{{ placeholder }}">
          <option *ngFor="let project of projects" [value]="project.id">
            {{ project.name }} ({{ project.description }})
          </option>
        </select>
      </div>
    </div>

    <div class="radio-label-group">
      <input
        id="project-assigner-radio_3"
        type="radio"
        name="project-assigner-radio"
        [value]="projectIndicator.NEW"
        [formControl]="indicatorControl"
        class="form-input-radio"
        aria-label="Verfahren einem neuen Projekt zuordnen" />
      <label
        for="project-assigner-radio_3"
        class="form-input-radio-label form-input-radio-label-cursor-pointer"
        >Verfahren einem neuen Projekt zuordnen</label
      >
    </div>

    <div class="form-component-input-group">
      <label for="project-name">
        Projektname
        <span [appMarkAsterisk]="indicatorControl.value === projectIndicator.NEW"></span>
      </label>
      <input
        [class.required]="true"
        [formControl]="projectNameControl"
        id="project-name"
        class="form-text-field" />
    </div>
    <div class="form-component-input-group">
      <label for="project-description">
        Projektbeschreibung
        <span [appMarkAsterisk]="indicatorControl.value === projectIndicator.NEW"></span>
      </label>
      <input
        [class.required]="true"
        [formControl]="projectDescriptionControl"
        id="project-description"
        class="form-text-field" />
    </div>
  </form>
</app-dialog>
