import { Component, ElementRef, TemplateRef, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  template: `
    <ng-container [ngTemplateOutlet]="this[type]"></ng-container>
    <ng-template #default>
      <div class="ske-row">
        <div style="width: 40%;"></div>
        <div style="width: 20%;"></div>
        <div style="width: 5%;"></div>
      </div>
    </ng-template>
    <ng-template #accordion>
      <div class="ske-col">
        <div class="ske-row">
          <div style="width: 40%;"></div>
          <div style="width: 5%;"></div>
        </div>
        <div class="ske-row" *ngFor="let i of [].constructor(3)">
          <div style="width: 50%;"></div>
          <div style="width: 20%;"></div>
          <div style="width: 10%;"></div>
          <div style="width: 10%;"></div>
          <div style="width: 10%;"></div>
        </div>
      </div>
    </ng-template>
    <ng-template #filterForm>
      <div class="ske-col">
        <div class="ske-row">
          <div style="width: 190px; height: 35px;"></div>
        </div>
        <div class="ske-row" style="justify-content: flex-start">
          <div style="width: 380px; height: 40px;"></div>
          <div style="width: 380px; height: 40px;"></div>
          <div style="width: 310px; height: 40px;"></div>
        </div>
      </div>
    </ng-template>
    <ng-template #table>
      <div class="ske-col">
        <div class="ske-row" *ngFor="let i of [].constructor(15)">
          <div style="width: 40%;"></div>
          <div style="width: 40%;"></div>
          <div style="width: 15%;"></div>
          <div style="width: 15%;"></div>
          <div style="width: 10%;"></div>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
        width: var(--skeleton-rect-width);
        height: var(--skeleton-rect-height);
        background-color: #f5f5f5;
        padding: 10px;
        margin: 10px 0;
        border-radius: 6px;
        box-shadow: 0 1px 8px lightgray;

        .ske-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        .ske-row {
          display: flex;
          justify-content: space-between;

          & > div {
            height: 20px;
            margin: 10px;
            background-color: #d9d9d9;
          }
        }

        background-image: linear-gradient(90deg, transparent 0%, #ffffff 50%, transparent 100%);
        background-size: 50%;
        background-repeat: no-repeat;
        animation: flash 2.5s linear infinite;

        @keyframes flash {
          0% {
            background-position: -200%;
          }
          100% {
            background-position: 200%;
          }
        }
      }
    `,
  ],
})
export class SkeletonComponent implements OnInit {
  width: string;
  height: string;
  className: string;
  type: string;

  @ViewChild('default') default: TemplateRef<any>;
  @ViewChild('accordion') accordion: TemplateRef<any>;
  @ViewChild('table') table: TemplateRef<any>;
  @ViewChild('filterForm') filterForm: TemplateRef<any>;

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');
  }
}
