import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'app-font-size',
  templateUrl: './font-size.component.html',
  styleUrls: ['./font-size.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontSizeComponent {
  canDecrease = true;
  canIncrease = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  changeFontsize(increase: boolean) {
    const pixel = +document.getElementsByTagName('html')[0].style['font-size'].split('p')[0];

    const newPixel = increase ? pixel + 1 : pixel - 1;
    document.getElementsByTagName('html')[0].style['font-size'] = `${newPixel}px`;

    this.canDecrease = newPixel > 10;
    this.canIncrease = 32 > newPixel;
    this.cdRef.markForCheck();
  }
}
