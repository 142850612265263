<div class="button-wrapper">
  <button
    mat-mini-fab
    (click)="$event.stopPropagation(); openChangeDialog(false, 'Eintrag bearbeiten')"
    class="eForm-icon-button normal"
    matTooltip="Eignungskriterium bearbeiten"
    matTooltipPosition="above"
    aria-label="Eignungskriterium bearbeiten">
    <mat-icon>edit</mat-icon>
  </button>
  <button
    mat-mini-fab
    (click)="$event.stopPropagation(); openChangeDialog(true, 'Kopie bearbeiten')"
    class="eForm-icon-button normal"
    matTooltip="Eignungskriterium kopieren"
    matTooltipPosition="above"
    aria-label="Eignungskriterium kopieren">
    <mat-icon>file_copy</mat-icon>
  </button>
</div>

<app-dialog
  #changeCriterionDialog
  [hasCloseButton]="false"
  (accept)="saveChanges()"
  (dismiss)="closeChangeDialog()"
  title="Kopie bearbeiten"
  acceptLabel="Änderungen speichern"
  dismissLabel="Erfassung abbrechen"
  aria-labelledby="change-criterion">
  <form
    [formGroup]="criterionInputForm"
    class="criterion-form"
    id="change-criterion"
    aria-label="Eignungskriterium bearbeiten">
    <label> Art<span [appMarkAsterisk]="true"></span> </label>
    <mat-form-field class="form-item-margin">
      <mat-select
        formControlName="criterionType"
        placeholder="Bitte wählen"
        panelClass="selectPanel">
        <mat-option [value]="''">Bitte wählen</mat-option>
        <mat-option *ngFor="let option of codeListType" [value]="option">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-alert-message
      *ngIf="criterionInputForm.get('criterionType').invalid"
      [severity]="Severity.ERROR"
      class="alert-box">
      <p>
        <span>Bitte erfassen Sie einen Wert.</span>
      </p>
    </app-alert-message>
    <label for="description">Beschreibung</label>
    <textarea
      formControlName="description"
      class="form-textarea form-item-margin"
      name="description"
      id="description"
      [appMaxLengthBadge]="6000"></textarea>
    <app-alert-message
      *ngIf="criterionInputForm.get('description').errors?.['maxlength']"
      [severity]="Severity.ERROR"
      class="alert-box">
      <p>
        <span>Die maximale Länge von 6000 Zeichen wurde überschritten.</span>
      </p>
    </app-alert-message>
    <label for="secondPhase">Zweite Phase</label>
    <div id="secondPhase" class="second-phase form-item-margin">
      <label class="radio-option">
        <input formControlName="secondPhase" class="form-input-radio" type="radio" [value]="true" />
        Ja
      </label>
      <label class="radio-option">
        <input
          formControlName="secondPhase"
          class="form-input-radio"
          type="radio"
          [value]="false" />
        Nein
      </label>
      <button
        (click)="reset()"
        [disabled]="criterionInputForm.get('secondPhase').value === null ||
                    criterionInputForm.get('secondPhase').value === undefined"
        class="eForms-button">
        Zurücksetzen
      </button>
    </div>
  </form>
</app-dialog>
