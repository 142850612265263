<app-template [hasContentFrame]="true" [titel]="'Häufige Fragen (FAQ)'">
  <section>
    <h3>Fragen zum Datenservice Öffentlicher Einkauf</h3>
    <mat-accordion>
      <app-faq-item [frage]="'Was ist der Datenservice Öffentlicher Einkauf?'">
        <p>
          Das Bundesministerium des Innern und für Heimat (BMI), das Beschaffungsamt des BMI
          (BeschA) und die Freie Hansestadt Bremen haben mit dem „Datenservice Öffentlicher Einkauf“
          gemeinsam einen zentralen Service entwickelt, der alle veröffentlichungspflichtigen
          Bekanntmachungen zu Vergabeverfahren von Bund, Ländern und Kommunen bereitstellt. Der
          Datenservice Öffentlicher Einkauf wurde im Rahmen der Onlinezugangsgesetz
          (OZG)-Umsetzungsprogramme von Bund und Föderal (OZG-Projekt „Standardbasierte
          Vereinfachung des Unternehmenszugangs zur öffentlichen Beschaffung“/Umsetzungsprojekt
          „Vergabe“) realisiert. Das gemeinsame OZG-Projekt hat sich zum Ziel gesetzt, Bietenden den
          Zugang zu öffentlichen Ausschreibungen zu erleichtern.
        </p>
        <p>
          Das Redaktionssystem ist Teil des Datenservice Öffentlicher Einkauf und bietet die
          Möglichkeit, Bekanntmachungen zur Veröffentlichung an den Vermittlungsdienst des
          Datenservice Öffentlicher Einkauf zu senden. Von dort werden die Bekanntmachungen zu TED
          (Tenders Electronic Daily) und zum Bekanntmachungsservice weitergeleitet.
        </p>
        <p>
          Weitere Informationen zum Datenservice Öffentlicher Einkauf finden Sie unter:
          <a
            href="https://www.bescha.bund.de/DE/ElektronischerEinkauf/Datenservice_Oeffentlicher_Einkauf/DSOE_node.html"
            target="_blank">
            www.bescha.bund.de/DE/ElektronischerEinkauf/Datenservice_Oeffentlicher_Einkauf/DSOE_node.html</a
          >
        </p>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Fragen zu eForms</h3>
    <mat-accordion>
      <app-faq-item [frage]="'Was ist eForms?'">
        <p>
          Auf der Grundlage der Durchführungsverordnung ((EU) 2019/1780) werden "elektronische
          Formulare - eForms" als Standardformulare für die Veröffentlichung von Bekanntmachungen
          eingeführt. Die Durchführungsverordnung ((EU) 2015/1986) wird mit Wirkung vom 25. Oktober
          2023 aufgehoben. Während die bisherigen Formulare strukturell auf Papierformularen
          basieren, gibt die für eForms relevante neue Durchführungsverordnung ((EU) 2019/1780)
          hierzu erstmalig Datenfelder vor, deren Nutzung für die Mitgliedsstaaten in Teilen
          europaweit einheitlich verpflichtend ist, teilweise die Nutzung nationaler Regelungen
          überlässt und teils eine optionale Nutzung vorsieht.
        </p>
        <p>
          Die Durchführungsverordnung ((EU) 2019/1780) findet Anwendung auf Vergaben, deren
          Auftragswert die EU-Schwellenwerte erreicht oder übersteigt (EU-Vergaberecht). Regelungen
          zum EU-Vergaberecht finden sich bundeseinheitlich im vierten Teil des GWB sowie in den auf
          § 113 GWB basierenden Vergabeverordnungen, deren Änderung von Bundestag und Bundesrat
          beschlossen wurde (siehe [
          <a
            href="https://bundesrat.de/SharedDocs/drucksachen/2023/0201-0300/203-23.html"
            target="_blank">
            www.bundesrat.de/SharedDocs/drucksachen/2023/0201-0300/203-23.html</a
          >]).
        </p>
        <p>
          Aus IT-fachlicher Sicht vollzieht die Kommission mit dieser Durchführungsverordnung für
          den Bereich des öffentlichen Einkaufs einen grundlegenden Paradigmenwechsel.
        </p>
        <p>
          Die Kommission setzt mit eForms wesentliche Grundsätze ihrer eGovernment-Strategie um, die
          grundlegende Voraussetzungen „guter“ Prozessdigitalisierung sind und Qualität sowie
          Nutzungs-/Auswertungsmöglichkeiten fortlaufend anfallender Prozessdaten erschließen (bspw.
          „Once-Only-Prinzip“ bei der Datenerfassung, das manuelle Erfassungsaufwände und damit
          einhergehende, zeitlich-inhaltliche Qualitätsmängel signifikant reduziert).
        </p>
        <p>
          Der eForms-DE Standard ist unter
          <a href="https://xeinkauf.de/eforms-de/" target="_blank"> www.xeinkauf.de/eforms-de/</a>
          einsehbar.
        </p>
      </app-faq-item>
      <app-faq-item
        [frage]="
          'Muss ich eForms bei der Veröffentlichung von EU-weiten Bekanntmachungen verwenden?'
        ">
        <p>
          Die Verwendung von eForms zur Publikation von Bekanntmachungen im Rahmen öffentlicher
          Vergabeverfahren ist ab 25. Oktober 2023 für EU-weite Vergabeverfahren verpflichtend (vgl.
          § 83 VgV ).
        </p>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Allgemeine Fragen zum Redaktionssystem</h3>
    <mat-accordion>
      <app-faq-item [frage]="'Wer darf das Redaktionssystem benutzen?'">
        <p>
          Die Plattform richtet sich an Personen und Organisationen, die Bekanntmachungen im
          Oberschwellenbereich veröffentlichen. Dazu zählen:
        </p>
        <ul>
          <li>
            Vergabestellen der öffentlichen Verwaltung,
            <b
              >ohne einen Zugang zu einem an den Datenservice öffentlicher Einkauf angebundenen
              Vergabesystem</b
            >.
          </li>
          <li>
            Auftraggeber, die gemäß § 98 GWB einer öffentlich-rechtlichen Einrichtung gleichgestellt
            sind.
          </li>
          <li>
            Zuwendungsempfänger
            <ul>
              <li>durch Zuwendungen zur Projektförderung.</li>
              <li>durch Zuwendungen zur institutionellen Förderung.</li>
            </ul>
          </li>
          <li>
            Dienstleister, die für Auftraggeber der öffentlichen Verwaltung oder Zuwendungsempfänger
            Bekanntmachungen über das Redaktionssystem einstellen möchten.
          </li>
        </ul>
      </app-faq-item>
      <app-faq-item
        [frage]="
          'Benötigen Dienstleister ein separates Konto für jeden Auftraggeber, den sie vertreten?'
        ">
        <p>Nein, jeder Dienstleister benötigt nur ein Konto.</p>
      </app-faq-item>
      <app-faq-item [frage]="'Ist die Nutzung des Redaktionssystems kostenfrei?'">
        <p>Ja, die Nutzung des Redaktionssystems ist kostenfrei.</p>
      </app-faq-item>
      <app-faq-item
        [frage]="'Was sind die technischen Voraussetzungen zur Nutzung des Redaktionssystems?'">
        <p>
          Zur Nutzung des Redaktionssystems wird ein Internetzugang sowie ein aktueller Webbrowser
          benötigt. Wir empfehlen folgende Browser: Firefox, Chrome, Edge oder Safari.
        </p>
        <p>Eine Nutzung auf mobilen Endgeräten wird nicht unterstützt.</p>
      </app-faq-item>

      <app-faq-item [frage]="'Was kann mit dem Redaktionssystem gemacht werden?'">
        <p>
          Mit dem Redaktionssystem können Bekanntmachungen zur Veröffentlichung an den
          Vermittlungsdienst des Datenservice Öffentlicher Einkauf gesendet werden. Von dort werden
          die Bekanntmachungen zu TED (Tenders Electronic Daily) und zum Bekanntmachungsservice
          gesendet.
        </p>
      </app-faq-item>

      <app-faq-item [frage]="'Was kann nicht mit dem Redaktionssystem gemacht werden?'">
        <p>
          Das Redaktionssystem ist <b>keine Vergabeplattform</b> und
          <b>kein Vergabemanagementsystem</b>. Es können <b>keine</b> Vergabeverfahren vollständig
          elektronisch abgewickelt werden. Es ist <b>nicht</b> möglich, Auftragsunterlagen
          hochzuladen. Es können <b>keine</b> Meldungen gemäß Vergabestatistikverordnung
          (VergStatVO) übermittelt werden.
        </p>
      </app-faq-item>
      <app-faq-item [frage]="'Wo werden die Bekanntmachungen veröffentlicht?'">
        <p>
          Mit dem Redaktionssystem erfasste und versendete Bekanntmachungen werden bei TED (Tenders
          Electronic Daily) unter
          <a href="https://ted.europa.eu" target="_blank">www.ted.europa.eu</a>
          und im Bekanntmachungsservice des Datenservice Öffentlicher Einkauf unter
          <a href="https://oeffentlichevergabe.de" target="_blank">www.oeffentlichevergabe.de</a>
          veröffentlicht.
        </p>
      </app-faq-item>
      <app-faq-item
        [frage]="
          'Gibt es im Redaktionssystem eine Gruppenfunktion? Können mehrere Nutzer eine Bekanntmachung sehen und ggf. anpassen?'
        ">
        <p>Ja, eine Gruppenfunktion steht seit der Version 3.0 zur Verfügung. Weitere Informationen finden Sie unter <a routerLink="/anleitungen">Anleitungen</a>.</p>
      </app-faq-item>
      <app-faq-item [frage]="'Gibt es eine Anleitung / Dokumentation zum Redaktionssystem?'">
        <p>
          Unter <a routerLink="/anleitungen">Anleitungen</a> finden Sie verschiedene Anleitungen zum Redaktionssystem.
        </p>
      </app-faq-item>
      <app-faq-item [frage]="'Gibt es ein Testsystem?'">
        <p>
          Nein, momentan ist auch kein Testsystem geplant. Um sich mit dem Redaktionssystem vertraut
          zu machen, reicht ein Standard-Konto. Es stehen alle Formulare zur Verfügung und über die
          Vorschau können die Formulare ausprobiert werden.
        </p>
        <p><b>Hinweis:</b> Es ist darauf zu achten, dass die Formulare nicht versendet werden.</p>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Fragen zum Anlegen von Bekanntmachungen</h3>
    <mat-accordion>
      <app-faq-item [frage]="'Welche eForms-Formulare werden unterstützt?'">
        <p>Aktuell werden die folgenden eForms-Formulare unterstützt:</p>
        <table>
          <thead>
            <tr>
              <th>eForms-Formularnummer</th>
              <th>Rechtsgrundlage</th>
              <th>Bekanntmachungsart</th>
              <th>Unterart der Bekanntmachung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>VgV §38 Abs. 1</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung nur zu
                Informationszwecken
              </td>
              <td>Vorinformation nur zu Informationszwecken</td>
            </tr>
            <tr>
              <td>5</td>
              <td>SektVO §36 Abs. 1</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung nur zu
                Informationszwecken
              </td>
              <td>Regelmäßige nicht verbindliche Bekanntmachung nur zu Informationszwecken</td>
            </tr>
            <tr>
              <td>6</td>
              <td>VSVgV §17 Abs. 1, 2</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung nur zu
                Informationszwecken
              </td>
              <td>Vorinformation nur zu Informationszwecken</td>
            </tr>
            <tr>
              <td>7</td>
              <td>VgV §38 Abs. 3</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung zum Zweck der
                Verkürzung der Frist für den Eingang der Angebote
              </td>
              <td>
                Vorinformation zum Zweck der Verkürzung der Frist für den Eingang der Angebote
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>SektVO §36 Abs. 3</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung zum Zweck der
                Verkürzung der Frist für den Eingang der Angebote
              </td>
              <td>
                Regelmäßige nicht verbindliche Bekanntmachung zum Zweck der Verkürzung der Frist für
                den Eingang der Angebote
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>VSVgV §17 Abs. 3</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung zum Zweck der
                Verkürzung der Frist für den Eingang der Angebote
              </td>
              <td>
                Vorinformation zum Zweck der Verkürzung der Frist für den Eingang der Angebote
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>VgV §38 Abs. 4</td>
              <td>Vorinformation als Aufruf zum Wettbewerb</td>
              <td>Vorinformation als Aufruf zum Wettbewerb</td>
            </tr>
            <tr>
              <td>11</td>
              <td>SektVO §36 Abs. 4</td>
              <td>Vorinformation als Aufruf zum Wettbewerb</td>
              <td>Regelmäßige nicht verbindliche Bekanntmachung als Aufruf zum Wettbewerb</td>
            </tr>
            <tr>
              <td>12</td>
              <td>VgV §66 Abs. 2, 4</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung als Aufruf
                zum Wettbewerb – Sonderregelung
              </td>
              <td>Vorinformation als Aufruf zum Wettbewerb – Sonderregelung</td>
            </tr>
            <tr>
              <td>13</td>
              <td>SektVO §39 Abs. 1, 2</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung als Aufruf
                zum Wettbewerb – Sonderregelung
              </td>
              <td>
                Regelmäßige nicht verbindliche Bekanntmachung als Aufruf zum Wettbewerb –
                Sonderregelung
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>KonzVgV §22 Abs. 1 ,3</td>
              <td>
                Vorinformation oder eine regelmäßige nicht verbindliche Bekanntmachung als Aufruf
                zum Wettbewerb – Sonderregelung
              </td>
              <td>Vorinformation als Aufruf zum Wettbewerb – Sonderregelung</td>
            </tr>
            <tr>
              <td>15</td>
              <td>SektVO §37 Abs. 1, 2</td>
              <td>Bekanntmachung über das Bestehen eines Prüfungssystems</td>
              <td>Bekanntmachung über das Bestehen eines Qualifizierungssystems</td>
            </tr>
            <tr>
              <td>16</td>
              <td>VgV §37 Abs 1, 2</td>
              <td>Auftrags- oder Konzessionsbekanntmachung</td>
              <td>Auftragsbekanntmachung</td>
            </tr>
            <tr>
              <td>17</td>
              <td>SektVO §35 Abs. 1, 2</td>
              <td>Auftrags- oder Konzessionsbekanntmachung</td>
              <td>Auftragsbekanntmachung</td>
            </tr>
            <tr>
              <td>18</td>
              <td>VSVgV §18 Abs. 1, 2</td>
              <td>Auftrags- oder Konzessionsbekanntmachung</td>
              <td>Auftragsbekanntmachung</td>
            </tr>
            <tr>
              <td>19</td>
              <td>KonzVgV §19 Abs. 1, 2</td>
              <td>Auftrags- oder Konzessionsbekanntmachung</td>
              <td>Konzessionsbekanntmachung</td>
            </tr>
            <tr>
              <td>20</td>
              <td>VgV §66 Abs. 1, 4</td>
              <td>Auftragsbekanntmachung – Sonderregelung</td>
              <td>Auftragsbekanntmachung – Sonderregelung</td>
            </tr>
            <tr>
              <td>21</td>
              <td>SektVO §39 Abs. 1, 2</td>
              <td>Auftragsbekanntmachung – Sonderregelung</td>
              <td>Auftragsbekanntmachung – Sonderregelung</td>
            </tr>
            <tr>
              <td>22</td>
              <td>VSVgV §39 Abs. 1</td>
              <td>Bekanntmachung der Vergabe von Unteraufträgen</td>
              <td>Bekanntmachung über Unteraufträge</td>
            </tr>
            <tr>
              <td>23</td>
              <td>VgV §70 Abs. 1</td>
              <td>Wettbewerbsbekanntmachung</td>
              <td>Wettbewerbsbekanntmachung</td>
            </tr>
            <tr>
              <td>24</td>
              <td>SektVO §61 Abs. 1</td>
              <td>Wettbewerbsbekanntmachung</td>
              <td>Wettbewerbsbekanntmachung</td>
            </tr>
            <tr>
              <td>25</td>
              <td>VgV §37 Abs. 5</td>
              <td>Freiwillige Ex-ante-Transparenzbekanntmachung</td>
              <td>Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz</td>
            </tr>
            <tr>
              <td>26</td>
              <td>SektVO §35 Abs. 5</td>
              <td>Freiwillige Ex-ante-Transparenzbekanntmachung</td>
              <td>Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz</td>
            </tr>
            <tr>
              <td>27</td>
              <td>VSVgV §18 Abs. 6</td>
              <td>Freiwillige Ex-ante-Transparenzbekanntmachung</td>
              <td>Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz</td>
            </tr>
            <tr>
              <td>28</td>
              <td>KonzVgV §19 Abs. 4</td>
              <td>Freiwillige Ex-ante-Transparenzbekanntmachung</td>
              <td>Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz</td>
            </tr>
            <tr>
              <td>29</td>
              <td>VgV §39 Abs. 1, 2</td>
              <td>Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung (Ex-Post)</td>
              <td>Vergabebekanntmachung</td>
            </tr>
            <tr>
              <td>30</td>
              <td>SektVO §38 Abs. 1, 2</td>
              <td>Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung (Ex-Post)</td>
              <td>Vergabebekanntmachung</td>
            </tr>
            <tr>
              <td>31</td>
              <td>VSVgV §35 Abs. 1</td>
              <td>Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung (Ex-Post)</td>
              <td>Vergabebekanntmachung</td>
            </tr>
            <tr>
              <td>32</td>
              <td>KonzVgV §21 Abs. 1</td>
              <td>Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung (Ex-Post)</td>
              <td>Zuschlagsbekanntmachung</td>
            </tr>
            <tr>
              <td>33</td>
              <td>VgV §66 Abs. 3, 4</td>
              <td>
                Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung - Sonderregelung
                (Ex-Post)
              </td>
              <td>Vergabebekanntmachung – Sonderregelung</td>
            </tr>
            <tr>
              <td>34</td>
              <td>SektVO §39 Abs. 3</td>
              <td>
                Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung - Sonderregelung
                (Ex-Post)
              </td>
              <td>Vergabebekanntmachung – Sonderregelung</td>
            </tr>
            <tr>
              <td>35</td>
              <td>KonzVgV §22 Abs. 2, 3</td>
              <td>
                Bekanntmachung vergebener Aufträge oder Zuschlagsbekanntmachung - Sonderregelung
                (Ex-Post)
              </td>
              <td>Zuschlagsbekanntmachung – Sonderregelung</td>
            </tr>
            <tr>
              <td>36</td>
              <td>VgV §70 Abs. 3</td>
              <td>Bekanntmachung der Wettbewerbsergebnisse</td>
              <td>Bekanntmachung über das Ergebnis des Wettbewerbs</td>
            </tr>
            <tr>
              <td>37</td>
              <td>SektVO §61 Abs. 3</td>
              <td>Bekanntmachung der Wettbewerbsergebnisse</td>
              <td>Bekanntmachung über das Ergebnis des Wettbewerbs</td>
            </tr>
            <tr>
              <td>38</td>
              <td>VgV §39 Abs. 5</td>
              <td>Bekanntmachung der Auftragsänderungen</td>
              <td>Bekanntmachung über Auftragsänderung</td>
            </tr>
            <tr>
              <td>39</td>
              <td>SektVO §38 Abs. 5</td>
              <td>Bekanntmachung der Auftragsänderungen</td>
              <td>Bekanntmachung über Auftragsänderung</td>
            </tr>
            <tr>
              <td>40</td>
              <td>KonzVgV §21 Abs. 2</td>
              <td>Bekanntmachung der Auftragsänderungen</td>
              <td>Bekanntmachung über Auftragsänderung</td>
            </tr>
            <tr>
              <td>T01</td>
              <td>Verordnung (EG) Nr. 1370/2007</td>
              <td>Vorinformation zu öffentlichen Personenverkehrsdiensten</td>
              <td>Vorinformation für öffentliche Dienstleistungsaufträge</td>
            </tr>
            <tr>
              <td>T02</td>
              <td>Verordnung (EG) Nr. 1370/2007</td>
              <td>Bekanntmachung vergebener Aufträge für öffentliche Personenverkehrsdienste</td>
              <td>Bekanntmachung über vergebene öffentliche Dienstleistungsaufträge</td>
            </tr>
          </tbody>
        </table>
      </app-faq-item>

      <app-faq-item [frage]="'Welches Formular muss ich auswählen?'">
        <p>
          Die Formulare unterscheiden sich anhand des Vergaberechtsrahmens und der
          Bekanntmachungsart. Welches Formular Sie auswählen müssen, hängt also vom konkreten Fall
          ab.
        </p>
      </app-faq-item>
      <app-faq-item [frage]="'Wie kann ich VOB-Bekanntmachungen ausschreiben?'">
        <p>
          Um VOB-Bekanntmachungen zu veröffentlichen, müssen Sie ein Formular der VgV (oder ggf. der
          VSVgV) auswählen. Innerhalb des Formulars können Sie dann unter “Verfahren” > “Zweck” >
          “Andere Rechtsgrundlage mit Kennung” im Feld “Rechtsgrundlage des Verfahrens (ELI –
          CELEX)” die VOB auswählen.
        </p>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Fragen zur Erfassungsmaske</h3>
    <mat-accordion>
      <app-faq-item [frage]="'Welche Felder müssen ausgefüllt werden?'">
        <p>
          Welche Felder bei der Erfassung einer Bekanntmachung anzeigt werden und welche
          verpflichtend ausgefüllt werden müssen, variiert von Formular zu Formular. Pflichtfelder
          eines Formulars sind gelb hinterlegt und mit einem Stern markiert.
        </p>
        <p>
          Innerhalb der Formulare bestehen verschiedene Abhängigkeiten zwischen den Feldern, so
          werden einige Felder nur angezeigt, wenn andere Felder mit einem bestimmten Wert befüllt
          wurden.<br />
          Einige Felder (z.B. „Art der Bekanntmachung“ und „Formulartyp“) werden automatisch
          ausgefüllt und sind nicht änderbar. Nicht änderbare Felder sind in der Maske ausgegraut.
        </p>
      </app-faq-item>
      <app-faq-item [frage]="'Kann ich Auftragsunterlagen im Redaktionssystem hochladen?'">
        <p>
          Nein. Sie können allerdings einen Web-Link zu einem von Ihnen gewählten Ablageort
          hinterlegen.
        </p>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Fragen zur Verwaltung von Bekanntmachungen</h3>
    <mat-accordion>
      <app-faq-item [frage]="'Welchen Status kann eine Bekanntmachung haben?'">
        <p>
          Die folgenden Statuskombinationen können Bekanntmachungen im Redaktionssystem annehmen:
        </p>
        <table>
          <thead>
            <tr>
              <th>TED-Status</th>
              <th>DöE-Status</th>
              <th>Statusbeschreibung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td>
                Bekanntmachung befindet sich im Entwurf-Status. Der Versand wurde noch nicht
                initiiert.
              </td>
            </tr>
            <tr>
              <td></td>
              <td>AWAITING_TRANSFER</td>
              <td>
                Die Bekanntmachung wurde vom Mediator angenommen und akzeptiert. Die weitere
                Versendung wird vorbereitet.
              </td>
            </tr>
            <tr>
              <td>PENDING</td>
              <td>PENDING</td>
              <td>
                Die Bekanntmachung wurde vom eSender des DÖE zur weiteren Verarbeitung angenommen.
                Die Übermittlung der Bekanntmachung an TED und an den Bekanntmachungsservice stehen
                noch aus.
              </td>
            </tr>
            <tr>
              <td>NO_RESPONSE</td>
              <td>PENDING</td>
              <td>
                Die Übermittlung an TED wird erneut versucht. Die Übermittlung der Bekanntmachung an
                den Bekanntmachungsservice steht noch aus.
              </td>
            </tr>
            <tr>
              <td>ACCEPTED</td>
              <td>PENDING</td>
              <td>
                Die Bekanntmachung wurde von TED akzeptiert, die Übermittlung an den
                Bekanntmachungsservice steht noch aus.
              </td>
            </tr>
            <tr>
              <td>ACCEPTED</td>
              <td>ACCEPTED</td>
              <td>
                Die Bekanntmachung wurde von TED und dem Bekanntmachungsservice akzeptiert, aber
                noch nicht veröffentlicht.
              </td>
            </tr>
            <tr>
              <td>ACCEPTED</td>
              <td>PUBLISHED</td>
              <td>
                Die Bekanntmachung wurde von TED akzeptiert, aber noch nicht veröffentlicht. Die
                Bekanntmachung wurde nach Ablauf von 48 Stunden nach dem Versand an TED bereits im
                Bekanntmachungsservice veröffentlicht.
              </td>
            </tr>
            <tr>
              <td>PUBLISHING</td>
              <td>PENDING</td>
              <td>
                Die Bekanntmachung wird in TED veröffentlicht, die Übermittlung an den
                Bekanntmachungsservice steht noch aus.
              </td>
            </tr>
            <tr>
              <td>PUBLISHING</td>
              <td>ACCEPTED</td>
              <td>
                Die Bekanntmachung wird in TED veröffentlicht, die Bekanntmachung wurde vom
                Bekanntmachungsservice akzeptiert.
              </td>
            </tr>
            <tr>
              <td>PUBLISHING</td>
              <td>PUBLISHED</td>
              <td>
                Die Bekanntmachung wird in TED veröffentlicht, die Bekanntmachung wurde im
                Bekanntmachungsservice veröffentlicht.
              </td>
            </tr>
            <tr>
              <td>STOPPED</td>
              <td>NOT_SEND</td>
              <td>
                Die Bekanntmachung wurde in TED gestoppt und wird nicht zum Bekanntmachungsservice
                gesendet.
              </td>
            </tr>
            <tr>
              <td>STOPPED</td>
              <td>PENDING</td>
              <td>
                Die Bekanntmachung wurde in TED gestoppt und wird nicht zum Bekanntmachungsservice
                gesendet.
              </td>
            </tr>
            <tr>
              <td>STOPPED</td>
              <td>ACCEPTED</td>
              <td>
                Die Bekanntmachung wurde in TED gestoppt und wird in Kürze auch im
                Bekanntmachungsservice gestoppt werden.
              </td>
            </tr>
            <tr>
              <td>STOPPED</td>
              <td>PUBLISHED</td>
              <td>
                Die Bekanntmachung wurde in TED gestoppt und wird in Kürze auch im
                Bekanntmachungsservice gestoppt werden.
              </td>
            </tr>
            <tr>
              <td>STOPPED</td>
              <td>STOPPED</td>
              <td>
                Die Bekanntmachung wurde sowohl in TED als auch im Bekanntmachungsservice gestoppt.
              </td>
            </tr>
            <tr>
              <td>PUBLISHED</td>
              <td>PENDING</td>
              <td>
                Die Bekanntmachung wurde in TED veröffentlicht, die Übermittlung an den
                Bekanntmachungsservice steht noch aus.
              </td>
            </tr>
            <tr>
              <td>PUBLISHED</td>
              <td>ACCEPTED</td>
              <td>
                Die Bekanntmachung wurde in TED veröffentlicht und vom Bekanntmachungsservice
                akzeptiert, aber noch nicht veröffentlicht.
              </td>
            </tr>
            <tr>
              <td>PUBLISHED</td>
              <td>PUBLISHED</td>
              <td>Die Bekanntmachung wurde in TED und im Bekanntmachungsservice veröffentlicht.</td>
            </tr>
            <tr>
              <td>MANUALLY_REJECTED</td>
              <td>NOT_SEND</td>
              <td>
                Die Bekanntmachung wurde von TED auf Grund einer rechtlichen Prüfung manuell
                abgelehnt und wurde nicht zum Bekanntmachungsservice gesendet.
              </td>
            </tr>
            <tr>
              <td>MANUALLY_REJECTED</td>
              <td>PENDING</td>
              <td>
                Die Bekanntmachung wurde von TED auf Grund einer rechtlichen Prüfung manuell
                abgelehnt und wird in Kürze auch im Bekanntmachungsservice gestoppt.
              </td>
            </tr>
            <tr>
              <td>MANUALLY_REJECTED</td>
              <td>ACCEPTED</td>
              <td>
                Die Bekanntmachung wurde von TED auf Grund einer rechtlichen Prüfung manuell
                abgelehnt und wird in Kürze auch im Bekanntmachungsservice gestoppt.
              </td>
            </tr>
            <tr>
              <td>MANUALLY_REJECTED</td>
              <td>PUBLISHED</td>
              <td>
                Die Bekanntmachung wurde von TED auf Grund einer rechtlichen Prüfung manuell
                abgelehnt und wird in Kürze auch im Bekanntmachungsservice gestoppt.
              </td>
            </tr>
            <tr>
              <td>MANUALLY_REJECTED</td>
              <td>STOPPED</td>
              <td>
                Die Bekanntmachung wurde von TED auf Grund einer rechtlichen Prüfung manuell
                abgelehnt und wurde auch im Bekanntmachungsservice gestoppt.
              </td>
            </tr>
            <tr>
              <td>REJECTED</td>
              <td>INTERNAL_ERROR</td>
              <td>
                Ein interner Fehler ist aufgetreten. Das Support-Team wird sich die Bekanntmachung
                genauer ansehen, der Status ändert sich anschließend.
              </td>
            </tr>
            <tr>
              <td>NOT_SEND</td>
              <td>INTERNAL_ERROR</td>
              <td>
                Ein interner Fehler ist aufgetreten. Das Support-Team wird sich die Bekanntmachung
                genauer ansehen, der Status ändert sich anschließend.
              </td>
            </tr>
          </tbody>
        </table>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Fragen zur Vorlagenfunktion</h3>
    <mat-accordion>
      <app-faq-item
        [frage]="
          'Welche eForms-Formulare werden in der Vorlagenfunktion unterstützt?'
        ">
        <p>
          Die Formulare 4-28 sowie T01 werden unterstützt. Vergabe- und Zuschlagsbekanntmachungen sowie Wettbewerbsergebnisse und Auftragsänderungen (Formulare 29-40 und T02) werden nicht unterstützt, da diese üblicherweise als Nachfolger einer bereits bestehenden Bekanntmachung angelegt werden.
        </p>
      </app-faq-item>
      <app-faq-item
        [frage]="
          'Welche Besonderheiten gibt es im Rahmen der Vorlagenfunktion?'
        ">
        <p>
          Im Rahmen der Vorlagenfunktion können bestimmte Referenz-Felder (z.B. „Kennung der vorherigen Bekanntmachung“) nicht ausgefüllt werden. Diese Felder sind mit einem entsprechenden Hinweis markiert. Außerdem werden bestimmte Kennungs- und ID-Felder mit Platzhalterwerten befüllt. Auch diese Felder sind mit einem entsprechenden Hinweis markiert.
        </p>
      </app-faq-item>
    </mat-accordion>
  </section>

  <section>
    <h3>Bekannte Fehlermeldungen bei der Erfassung</h3>
    <mat-accordion>
      <app-faq-item
        [frage]="
          'Fehlermeldung: „Jedes bestehende \'ID - Unternehmen\' (OPT-200-Organization-Company) muss mindestens eine Rolle oder Unterrolle haben“'
        ">
        <p>
          In der Erfassungsmaske müssen alle für eine Bekanntmachung relevanten Organisationen
          zunächst im Bereich "Organisationen" angelegt werden. Jede dieser Organisationen muss
          anschließend an mindestens einer Stelle im Formular (z.B. unter "Vertragspartei und
          Dienstleister") referenziert werden. Sobald alle Organisationen an mindestens einer Stelle
          referenziert sind, sollte die Fehlermeldung verschwinden.
        </p>
      </app-faq-item>
    </mat-accordion>
    <mat-accordion>
      <app-faq-item
        [frage]="
          'Fehlermeldung: Jedes bestehende \'ID – Bieter\' (OPT-210-Tenderer) muss mit mindestens einem \'Referenz des wirtschaftlichen Eigentümers\'(OPT-302-Organization) assoziiert sein'
        ">
        <p>
          Um den Fehler zu beheben, muss unter “Ergebnis” > “Angebote” ein Angebot hinzugefügt und
          dort der Bieter referenziert werden. Das Angebot muss dann außerdem noch unter “Ergebnisse
          der Lose” referenziert werden.
        </p>
      </app-faq-item>
    </mat-accordion>
  </section>
</app-template>
