<button
  mat-mini-fab
  (click)="this.prefillFieldsDialog.openDialog(); $event.stopPropagation();"
  (keydown.enter)="this.prefillFieldsDialog.openDialog(); stopDefaultBehavior($event)"
  (keydown.space)="this.prefillFieldsDialog.openDialog(); stopDefaultBehavior($event)"
  class="eForm-icon-button normal"
  matTooltip="Vorbefüllen aus Verfahren"
  matTooltipPosition="right"
  aria-label="Vorbefüllen aus Verfahren">
  <mat-icon>library_books</mat-icon>
</button>

<app-dialog #prefillFieldsDialog
            (accept)="prefillFields()"
            title="Möchten Sie wirklich die Felder vorbefüllen?"
            dismissLabel="Abbrechen"
            acceptLabel="Vorbefüllen">
  <div>
    <p>Mit dieser Funktion werden Inhalte aus dem Verfahrensabschnitt ins technische {{ typeName }} kopiert. Bestehende
      Inhalte im
      technischen {{ typeName }} können dadurch überschrieben werden. Diese Aktion kann nicht rückgängig gemacht werden.
      Folgende
      Felder wurden aus dem Abschnitt Verfahren in das technische {{ typeName }} übernommen:
    </p>
    <section class="all-selected-checkbox">
      <label>Alle auswählen</label>
      <mat-checkbox [(ngModel)]="allFieldsSelected" (change)="selectAll(allFieldsSelected)"></mat-checkbox>
    </section>
    <mat-selection-list  role="list" (selectionChange)="selectionChanged()" #selectionList>
      <mat-list-option class="list-item" *ngFor="let field of fields" role="list-item" [value]="field">
        <span>{{ this.getFieldLabel(field) }}</span>
      </mat-list-option>
    </mat-selection-list>
  </div>
</app-dialog>
