import { ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';
import { ModelValidator } from '../ModelValidator';
import { ConceptModel } from '../../../../types/concept-node';
import { CodelistModel } from '../../../../view-model/type/codelist-model';

/**
 * Fallback validator for invalid values in Codelists.
 */
export class ValueInCodelistValidator extends ModelValidator<CodelistModel> {
  static readonly INVALID_VALUE_MSG = 'Das Feld enthält ungültige Werte.';
  async validate(
    codelistModel: CodelistModel,
    conceptModel?: ConceptModel
  ): Promise<ValidationNotification[]> {
    if (
      codelistModel.codeList &&
      !codelistModel.codeList.some(value => value.id === codelistModel.value)
    ) {
      return this.ofSingle(ValueInCodelistValidator.INVALID_VALUE_MSG);
    }

    return this.empty();
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model instanceof CodelistModel;
  }
}
