import { FieldModel } from '../field-model';
import { Language, Multilingual } from '../../types/data-model';

export class MultilingualModel extends FieldModel<Multilingual> {
  private _focusedLanguage: Language;
  private _germanValue: string;
  private _englishValue: string;

  constructor() {
    super();
    this.value = {};
  }

  get value(): Multilingual {
    return super.value;
  }

  set value(value: Multilingual) {
    super.value = value;
  }

  get activeLanguages(): Language[] {
    return this.root.activeLanguages;
  }

  get focusedLanguage(): Language {
    return this._focusedLanguage;
  }

  set focusedLanguage(value: Language) {
    this._focusedLanguage = value;
  }

  get germanValue(): string {
    return this._germanValue;
  }

  set germanValue(value: string) {
    this._germanValue = value;
  }

  get englishValue(): string {
    return this._englishValue;
  }

  set englishValue(value: string) {
    this._englishValue = value;
  }
}
