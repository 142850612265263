<div [hidden]="model.isHidden" class="form-component-input-group" [id]="htmlId">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="multilingual-content-wrapper">
    <ng-container *ngIf="isTextArea">
      <app-language-text-area
        (validateField)="validateGermanField()"
        *ngIf="isGerman()"
        [htmlId]="htmlId + '-DEU'"
        [control]="germanControl"
        [focus]="focusGermanEmitter"
        [language]="Language.Deu"
        [model]="model">
      </app-language-text-area>
      <div *ngIf="isGerman() && isEnglish()" class="spacer"></div>
      <app-language-text-area
        (validateField)="validateEnglishField()"
        *ngIf="isEnglish()"
        [htmlId]="htmlId + '-ENG'"
        [control]="englishControl"
        [focus]="focusEnglishEmitter"
        [language]="Language.Eng"
        [model]="model">
      </app-language-text-area>
    </ng-container>
    <ng-container *ngIf="!isTextArea">
      <app-language-text-field
        (validateField)="validateGermanField()"
        *ngIf="isGerman()"
        [htmlId]="htmlId + '-DEU'"
        [control]="germanControl"
        [focus]="focusGermanEmitter"
        [language]="Language.Deu"
        [model]="model">
      </app-language-text-field>
      <div *ngIf="isGerman() && isEnglish()" class="spacer"></div>
      <app-language-text-field
        (validateField)="validateEnglishField()"
        *ngIf="isEnglish()"
        [htmlId]="htmlId + '-ENG'"
        [control]="englishControl"
        [focus]="focusEnglishEmitter"
        [language]="Language.Eng"
        [model]="model">
      </app-language-text-field>
    </ng-container>
  </div>
  <ng-container *ngIf="!model.isHidden">
    <app-validation-message [model]="model"></app-validation-message>
  </ng-container>
</div>
