import { FieldModel } from '../../view-model/field-model';
import { ModelListener } from './ModelListener';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { ProcedureTypeService } from '../procedure-type.service';
import { ModelStateService } from '../view-model/model-state.service';
import { OFFER_DEADLINE_DATE_ID } from '../view-model/evergabe-meta-data.service';
import { DateModel } from '../../view-model/type/date-model';
import { ModelValidationService } from '../view-model/model-validation.service';

/**
 * Listens for ProcedureType Changes and adjusts FieldModels to reflect requirements in a TenderCompetition:
 * * Only one Deadline (BT-131 XOR BT-1311) is allowed in the Form.
 * * e-Vergabe requires always an offer-deadline, so it has to be a Meta-Date.
 * * When no Tender Competition: Offer-Deadline will get synced to meta-data model. Field invisible in Meta-Data section.
 * * When Tender Competition: Offer-Deadline is a FormField inside the e-Vergabe metadata section.
 * *
 */
export class ProcedureTypeChangedListener extends ModelListener<CodelistModel> {
  constructor(
    private procedureTypeService: ProcedureTypeService,
    private modelValidationService: ModelValidationService
  ) {
    super();
  }

  onChange(model: CodelistModel): void {
    const formOfferDeadlineModel = ModelStateService.findModelById(
      model.root,
      'BT-131(d)-Lot'
    ) as DateModel;
    const metaOfferDeadlineModel = ModelStateService.findModelById(
      model.root,
      OFFER_DEADLINE_DATE_ID
    ) as DateModel;
    const participationDeadlineModel = ModelStateService.findModelById(
      model.root,
      'BT-1311(d)-Lot'
    ) as DateModel;

    if (!formOfferDeadlineModel || !metaOfferDeadlineModel || !participationDeadlineModel) {
      return;
    }

    if (this.procedureTypeService.isProcedureTypeWithParticipationPhase(model.value)) {
      this.toggleDateModel(metaOfferDeadlineModel, true);

      this.clearDateModel(formOfferDeadlineModel);
    } else {
      this.toggleDateModel(metaOfferDeadlineModel, false);

      this.clearDateModel(metaOfferDeadlineModel);
      this.clearDateModel(participationDeadlineModel);

      this.clearValidationNotifications(metaOfferDeadlineModel, model);
    }
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model.noticeNode?.id === 'BT-105-Procedure';
  }

  private toggleDateModel(model: DateModel, visible: boolean) {
    if (!model) return;
    model.noticeNode.hidden = !visible;
    model.fieldInfo.mandatory.value = visible;

    model.isHidden = model.noticeNode.hidden;
    model.isMandatory = model.fieldInfo.mandatory.value;

    if (model.relatedTimeModel) {
      model.relatedTimeModel.noticeNode.hidden = !visible;
      model.relatedTimeModel.fieldInfo.mandatory.value = visible;

      model.relatedTimeModel.isHidden = model.relatedTimeModel.noticeNode.hidden;
      model.relatedTimeModel.isMandatory = model.relatedTimeModel.fieldInfo.mandatory.value;
    }
  }

  private clearDateModel(dateWithRelatedTime: DateModel) {
    dateWithRelatedTime.value = null;
    dateWithRelatedTime.relatedTimeModel.value = null;
  }

  private clearValidationNotifications(metaOfferDeadlineModel: DateModel, model: CodelistModel) {
    metaOfferDeadlineModel.validationNotifications = [];
    metaOfferDeadlineModel.relatedTimeModel.validationNotifications = [];
    this.modelValidationService.refreshNotifications(model.root, true);
  }
}
