import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { DateModel } from '../../../../view-model/type/date-model';
import {
  ARCHIVE_DATE_ID,
  OFFER_DEADLINE_DATE_ID,
} from '../../../view-model/evergabe-meta-data.service';
import { LocalDate } from '@js-joda/core';
import { ModelStateService } from '../../../view-model/model-state.service';
import { MeasureModel } from '../../../../view-model/type/measure-model';

export class ArchivingDateBehindOfferValidityPeriod extends EVergabeSpecificValidator<DateModel> {
  private static ARCHIVING_DATE_NOT_BEHIND_OFFER_VALIDITY_PERIOD =
    'Die Archivierungsfrist muss hinter der Gültigkeitsfrist des Angebots liegen.';

  shouldAttach(model: FieldModel<string>): boolean {
    return model.noticeNode.id === ARCHIVE_DATE_ID;
  }

  async validate(archivingDateModel: DateModel): Promise<ValidationNotification[]> {
    const offerValidityPeriodModel = ModelStateService.findModelById(
      archivingDateModel.root,
      'BT-98-Lot'
    ) as MeasureModel;

    const metaOfferDeadlineModel = ModelStateService.findModelById(
      archivingDateModel.root,
      OFFER_DEADLINE_DATE_ID
    ) as DateModel;

    const formOfferDeadlineModel = ModelStateService.findModelById(
      archivingDateModel.root,
      'BT-131(d)-Lot'
    ) as DateModel;

    const amount = Number(offerValidityPeriodModel?.value?.amount);
    const unit = offerValidityPeriodModel?.value?.unit;
    const archiveDate = archivingDateModel?.asLocalDate();
    const offerDeadline =
      metaOfferDeadlineModel?.asLocalDate() ?? formOfferDeadlineModel?.asLocalDate();

    if (isNaN(amount) || !unit || !archiveDate || !offerDeadline) {
      return this.empty();
    }

    const offerValidityDate = this.addPeriodToLocalDate(offerDeadline, unit, amount);
    if (!archiveDate.isAfter(offerValidityDate)) {
      return this.ofSingle(
        ArchivingDateBehindOfferValidityPeriod.ARCHIVING_DATE_NOT_BEHIND_OFFER_VALIDITY_PERIOD
      );
    }

    return this.empty();
  }

  private addPeriodToLocalDate(date: LocalDate, unit: string, amount: number) {
    switch (unit) {
      case 'DAY':
        return date.plusDays(amount);
      case 'WEEK':
        return date.plusWeeks(amount);
      case 'MONTH':
        return date.plusMonths(amount);
      case 'YEAR':
        return date.plusYears(amount);
      default:
        console.error('Unknown Unit in MeasureModel: ' + unit);
    }
  }
}
