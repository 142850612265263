import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DialogComponent } from '../../../../../layout/dialog/dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionCriterion } from '../../../../../../types/selection-criterion';
import { Severity } from '../../../../../../types/app-types';
import { SelectionCriterionService } from '../../../../../../services/selection-criterion.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-selection-criterion',
  templateUrl: './change-selection-criterion.component.html',
  styleUrls: ['./change-selection-criterion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeSelectionCriterionComponent implements OnInit, OnDestroy {
  @ViewChild('changeCriterionDialog') changeCriterionDialog: DialogComponent;
  @Input() importedCriterion: SelectionCriterion;
  @Input() codeListType: { id: string; label: string }[];
  @Output() criteriaEmitter = new EventEmitter<SelectionCriterion[]>();
  isCopy = false;
  criterionInputForm: FormGroup;
  private subscription = new Subscription();

  constructor(
    private selectionCriterionService: SelectionCriterionService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.importCriterion();
    this.registerFormValidityListener();
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openChangeDialog(mode: boolean, title: string) {
    this.changeCriterionDialog.title = title;
    this.isCopy = mode;
    this.changeCriterionDialog.openDialog();
  }

  closeChangeDialog() {
    this.importCriterion();
  }

  importCriterion() {
    this.criterionInputForm = new FormGroup({
      criterionType: new FormControl(
        this.findCodeListEntry(this.codeListType, this.importedCriterion.criterionType),
        Validators.required
      ),
      description: new FormControl(this.importedCriterion.description, Validators.maxLength(6000)),
      //the null value should be set to undefined, otherwise radio button will be set to false
      secondPhase: new FormControl(
        this.importedCriterion.secondPhase != null ? this.importedCriterion.secondPhase : undefined
      ),
    });
  }

  getCurrentCriterion(): SelectionCriterion {
    const criterion = new SelectionCriterion();
    Object.keys(this.criterionInputForm.controls).forEach(control => {
      if (control === 'criterionType' || control === 'used') {
        criterion[control] = this.criterionInputForm.get(control).value?.id
          ? this.criterionInputForm.get(control).value.id
          : null;
      } else {
        criterion[control] =
          this.criterionInputForm.get(control).value != null
            ? this.criterionInputForm.get(control).value
            : undefined;
      }
    });
    return criterion;
  }

  saveChanges() {
    if (this.isCopy) {
      this.copyCriterion();
    } else {
      this.changeCriterion();
    }
    this.importCriterion();
  }

  copyCriterion() {
    const criteria = this.selectionCriterionService.copyCriterion(this.getCurrentCriterion());
    this.criteriaEmitter.emit(criteria);
  }

  changeCriterion() {
    const criteria = this.selectionCriterionService.editCriterion(
      this.getCurrentCriterion(),
      this.importedCriterion
    );
    this.criteriaEmitter.emit(criteria);
  }

  registerFormValidityListener() {
    this.subscription.add(
      this.criterionInputForm.statusChanges.subscribe(status => {
        if (status === 'INVALID') {
          this.changeCriterionDialog.disableAcceptButton();
        } else {
          this.changeCriterionDialog.enableAcceptButton();
        }
      })
    );
  }

  reset(): void {
    this.criterionInputForm.get('secondPhase').setValue(undefined);
  }

  private findCodeListEntry(codeList: { id: string; label: string }[], id: string) {
    return codeList.find(item => item?.id === id);
  }

  protected readonly Severity = Severity;
}
