<div class="container autocomplete-wrapper">
  <mat-form-field
    [class.has-error]="hasError"
    [class.required]="model.isMandatory && !model.isReadonly"
    [class.autocomplete]="model.isAutoComplete"
    [class.codefield]="model.isCodeField">
    <input
      matInput
      [id]="htmlId"
      [matAutocomplete]="autocomplete"
      (input)="onSearch()"
      [formControl]="control"
      type="text"
      placeholder="{{ model.isReadonly ? ' ' : 'Bitte Suchbegriff eingeben' }}" />

    <button
      mat-icon-button
      matIconSuffix
      *ngIf="!model.isReadonly"
      class="input-clearable-icon"
      (click)="clearInput()"
      aria-label="Eingabe löschen">
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #autocomplete [displayWith]="getDisplayValue.bind(this)">
      <mat-option *ngIf="noSearchResult" disabled
        >Für die Eingabe wurde kein Treffer gefunden.</mat-option
      >

      <div *ngIf="model.isAutoComplete">
        <mat-option *ngFor="let item of visibleItems" [value]="item">
          {{ item.label }} <span class="code-key">({{ item.id }})</span>
        </mat-option>
      </div>

      <div *ngIf="model.isCodeField">
        <mat-option *ngIf="insufficientInput" disabled
          >Bitte geben Sie mindestens 3 Zeichen ein.</mat-option
        >
        <mat-option *ngFor="let item of visibleItems" [value]="item">
          <strong>{{ item.id }}: </strong> {{ item.label }}
        </mat-option>
      </div>

      <mat-option *ngIf="!noSearchResult && hiddenResults > 0" disabled
        >{{ hiddenResults }} weitere Treffer vorhanden. Zum Anzeigen Suche verfeinern.
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <span *ngIf="model.isCodeField" class="code-value">
    {{ control.value?.label ? control.value.label : '' }}
  </span>
</div>
