import {
  ClientPlatform,
  FormMode,
  OperationMode,
  PublicationMode,
} from '../../types/editor-configuration';
import { NoticeActionDto } from '../api/notices';
import { NoticeOverviewViewModel } from '../bekanntmachung/NoticeOverviewViewModel';

export class NavigationUtil {
  private static DEFAULT_PARAMS = {
    operationMode: OperationMode.PRODUCTION,
    clientPlatform: ClientPlatform.RS,
    cancellation: false,
    resetProcedure: false,
    offerDeadlineChangeAllowed: true,
    participationDeadlineChangeAllowed: true,
    publicationMode: PublicationMode.WITH_PUBLICATION,
    lotSupport: 'null', // Parameter muss fuer die eVergabe gefuellt sein
  };

  public static buildQueryParamsTemplate(isMigratedFromEarlierEFormsVersion: boolean, rsTemplateId?: number) {
    return {
      ...this.DEFAULT_PARAMS,
      mode: NoticeActionDto.FormModeEnum.Create,
      ...(rsTemplateId ? { rsTemplateId } : {}),
      isTemplate: true,
      migratedFromEarlierEFormsVersion: isMigratedFromEarlierEFormsVersion
    };
  }

  public static buildQueryParamsNotice(action: NoticeActionDto, notice: NoticeOverviewViewModel) {
    return {
      ...this.DEFAULT_PARAMS,
      mode: this.getModeByNoticeAction(action),
      migratedFromEarlierEFormsVersion : !notice.notice.latestSdkVersion,
      rsNoticeId: this.getRsNoticeIdParam(action.type, notice),
      baseRsNoticeId: this.getBaseRsNoticeIdParam(action.type, notice),
    };
  }

  public static buildQueryParamsEditNewNotice(rsNoticeId: number) {
    return {
      ...this.DEFAULT_PARAMS,
      mode: FormMode.FORM_CREATE,
      rsNoticeId,
    };
  }

  private static getModeByNoticeAction(action: NoticeActionDto): FormMode {
    switch (action.type) {
      case NoticeActionDto.TypeEnum.CreateChangeNotice:
        return FormMode.FORM_UPDATE;
      case NoticeActionDto.TypeEnum.EditSentNotice:
        return FormMode.FORM_ALTER_BEFORE_RELEASE;
      case NoticeActionDto.TypeEnum.EditSentChangeNotice:
        return FormMode.FORM_ALTER_UPDATE_BEFORE_RELEASE;
      case NoticeActionDto.TypeEnum.EditDraft:
        return action.formMode as FormMode;
      case NoticeActionDto.TypeEnum.CreateTemplate:
        return action.formMode as FormMode;
      default:
        return FormMode.FORM_CREATE;
    }
  }

  private static getRsNoticeIdParam(
    actionType: NoticeActionDto.TypeEnum,
    notice: NoticeOverviewViewModel
  ): number {
    return actionType === NoticeActionDto.TypeEnum.EditDraft ? notice.notice.rsNoticeId : undefined;
  }

  private static getBaseRsNoticeIdParam(
    actionType: NoticeActionDto.TypeEnum,
    notice: NoticeOverviewViewModel
  ): number {
    // bei allen Aktionen ausser dem Bearbeiten des Entwurfs selbst wird die rsNoticeId als "base"RsNoticeId gesetzt
    return actionType !== NoticeActionDto.TypeEnum.EditDraft ? notice.notice.rsNoticeId : undefined;
  }
}
