import { ModelValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { AmountModel } from '../../../../view-model/type/amount-model';
import { MandatoryValidator } from './MandatoryValidator';
import { MeasureModel } from '../../../../view-model/type/measure-model';

export class MandatoryForAmountAndUnitValidator extends ModelValidator<FieldModel<any>> {
  constructor() {
    super();
  }

  validate(fieldModel: FieldModel<any>): Promise<ValidationNotification[]> {
    if (this.isValueOrUnitMissing(fieldModel)) {
      return this.ofSingle(MandatoryValidator.MANDATORY_ERROR_MSG);
    }
    return this.empty();
  }

  shouldAttach(fieldModel: FieldModel<any>): boolean {
    return (
      (fieldModel instanceof AmountModel || fieldModel instanceof MeasureModel) &&
      fieldModel.isMandatory
    );
  }

  private isValueOrUnitMissing(fieldModel: FieldModel<any>) {
    if (fieldModel instanceof AmountModel) {
      return !(fieldModel.value?.amount && fieldModel.value?.currencyID);
    }
    if (fieldModel instanceof MeasureModel) {
      return !(fieldModel.value?.amount && fieldModel.value?.unit);
    }
  }
}
