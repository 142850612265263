import { createReducer, on } from '@ngrx/store';
import * as IndicatorActions from './indicator.action';
import { IndicatorData, IndicatorValue } from '../app.state';

export const initialState = new Map();

export const indicatorReducer = createReducer(
  initialState,
  on(IndicatorActions.add, (state, addPayload) => {
    const addIndicatorValue = state.get(addPayload.indicator);
    if (addIndicatorValue) {
      return addIndicatorValue.values.includes(addPayload.value)
        ? new Map(state)
        : new Map(state).set(addPayload.indicator, {
            values: [...addIndicatorValue.values, addPayload.value],
            highest:
              addPayload.index > addIndicatorValue.highest
                ? addPayload.index
                : addIndicatorValue.highest,
          });
    } else {
      return new Map(state).set(addPayload.indicator, {
        values: [addPayload.value],
        highest: addPayload.index,
      });
    }
  }),
  on(IndicatorActions.updateLabel, (state, updateLabelPayload) => {
    const modifiedMap = new Map<string, IndicatorData>(structuredClone(state));

    const targetSchemeValue = modifiedMap
      .get(updateLabelPayload.indicator)
      ?.values.find((value: IndicatorValue) => value.id === updateLabelPayload.value.id);
    if (targetSchemeValue) {
      targetSchemeValue.label = updateLabelPayload.value.label;
    }
    return modifiedMap;
  }),
  on(IndicatorActions.updateSchemeIdRefreshHighest, (state, schemeIdUpdate) => {
    const modifiedMap = new Map<string, IndicatorData>(structuredClone(state));

    const indicatorMap: IndicatorData = modifiedMap.get(schemeIdUpdate.indicator);
    const targetSchemeValue = indicatorMap?.values.find(
      (value: IndicatorValue) => value.id === schemeIdUpdate.oldId
    );
    if (targetSchemeValue) {
      targetSchemeValue.id = schemeIdUpdate.newId;
    }

    refreshHighestIndex(indicatorMap);

    return modifiedMap;
  }),
  on(IndicatorActions.remove, (state, removePayload) => {
    const modifiedMap = new Map<string, IndicatorData>(structuredClone(state));
    const removeIndicatorValue: IndicatorData = modifiedMap.get(removePayload.indicator);
    removeIndicatorValue.values = removeIndicatorValue.values.filter(
      value => value.id !== removePayload.id
    );

    if (removeIndicatorValue.values.length === 0) {
      modifiedMap.delete(removePayload.indicator);
    } else {
      refreshHighestIndex(removeIndicatorValue);
    }

    return modifiedMap;
  })
);

/**
 * Updates the highest Index property, by iterating through all Indicator and taking the largest one.
 *
 * @param indicatorMap
 */
const refreshHighestIndex = (indicatorMap: IndicatorData) => {
  if (!indicatorMap?.values || indicatorMap.values.length === 0) return;

  const indicatorToNumeric = (indicator: string) => Number(indicator.split('-')[1]);
  const mappedValues = indicatorMap.values.map(indicatorValue =>
    indicatorToNumeric(indicatorValue.id)
  );

  indicatorMap.highest = Math.max(...mappedValues);
};
