import { DateModel } from '../../../../view-model/type/date-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { EVergabeSpecificValidator } from '../ModelValidator';
import { HolidayUtilsService } from '../../../../utils/holiday-utils.service';
import { FieldModel } from '../../../../view-model/field-model';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';

export class DeadlineOnHolidayValidator extends EVergabeSpecificValidator<DateModel> {
  private static readonly BTS_FOR_DEADLINE_ON_HOLIDAY_VALIDATION: string[] = [
    'BT-1311(d)-Lot', // participation deadline
    'BT-131(d)-Lot', // offer deadline
    OFFER_DEADLINE_DATE_ID, // provisional offer deadline
    'BT-132(d)-Lot', // offer opening deadline
    'BT-738-notice', // preferred publication date
  ];
  private static readonly DEADLINE_ON_HOLIDAY_WARNING_MSG =
    'Das Ende der Frist liegt auf einem bundesweiten Feiertag.';

  constructor(private holidayUtils: HolidayUtilsService) {
    super();
  }
  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    if (
      !dateModel.isReadonly &&
      dateModel.value &&
      this.holidayUtils.isNationwideHoliday(dateModel.asLocalDate())
    ) {
      return this.ofSingle(
        DeadlineOnHolidayValidator.DEADLINE_ON_HOLIDAY_WARNING_MSG,
        Severity.WARNING
      );
    }
    return this.empty();
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return DeadlineOnHolidayValidator.BTS_FOR_DEADLINE_ON_HOLIDAY_VALIDATION.includes(
      model.noticeNode.id
    );
  }
}
