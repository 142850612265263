<div
  [hidden]="model.isHidden"
  class="form-component-input-group"
  [class.subgroup]="isChildInDateComponent">
  <div class="ev-timepicker">
    <input
      (blur)="onInputValueChanged()"
      [class.has-error]="hasInputError() || hasRelatedDateComponentInputError()"
      [class.required]="model.isMandatory && !model.isReadonly"
      [formControl]="inputControl"
      [id]="htmlId"
      aria-label="Zeit auswählen"
      type="time" />
  </div>
  <app-validation-message *ngIf="!isChildInDateComponent" [model]="model"></app-validation-message>
</div>
