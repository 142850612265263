import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ClientPlatform,
  EditorConfiguration,
  FormMode,
  LotSupport,
  OperationMode,
  PublicationMode,
} from '../types/editor-configuration';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EditorConfigurationProvider {
  private editorConfiguration: EditorConfiguration;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    activatedRoute.queryParams
      .pipe(filter(() => !router.routerState.snapshot.url.endsWith('/erfassung')))
      .subscribe(params => {
        this.readConfiguration(params);
      });
  }

  /**
   * Returns true, if this is an initial notice without an existing procedure shell.
   */
  isInitialNoticeWithoutContractFolderOrProcedureId(): boolean {
    return !this.editorConfiguration.tenderingProcedureId && this.isCreationMode();
  }

  /**
   * Checks if the current Notice is already published. Published meaning the initial notice has been sent to VeDi.
   */
  isPublished(): boolean{
    return !this.isInitialNoticeWithoutContractFolderOrProcedureId() && !this.isAlterBeforeRelease();
  }

  isChangeNotice(): boolean {
    return this.editorConfiguration.mode === FormMode.FORM_UPDATE;
  }

  /**
   * Migration from old EU Formular to eForms.
   */
  isMigrationToEForms(): boolean {
    return this.editorConfiguration.isMigration;
  }

  isDevModeAvailable(): boolean {
    return this.editorConfiguration.showDevMode;
  }

  isEVergabePlatform() {
    return [ClientPlatform.OBA, ClientPlatform.SEB].includes(
      this.editorConfiguration.clientPlatform
    );
  }

  isCreationMode(): boolean {
    return this.editorConfiguration.mode === FormMode.FORM_CREATE;
  }

  getClerkMail(): string | undefined {
    return this.editorConfiguration.clerkMail;
  }

  isUpdateMetaData(): boolean {
    return this.editorConfiguration.mode === FormMode.FORM_UPDATE_META;
  }

  isAlterBeforeRelease(): boolean {
    return this.editorConfiguration.mode === FormMode.FORM_ALTER_BEFORE_RELEASE;
  }

  // ReSy: Pruefung auf AlterUpdateBeforeRelease
  isAlterUpdateBeforeRelease(): boolean {
    return this.editorConfiguration.mode === FormMode.FORM_ALTER_UPDATE_BEFORE_RELEASE;
  }

  isTrainingMode(): boolean {
    return this.editorConfiguration.operationMode === OperationMode.TRAINING;
  }

  isProductionMode(): boolean {
    return this.editorConfiguration.operationMode === OperationMode.PRODUCTION;
  }

  isOBA(): boolean {
    return this.editorConfiguration.clientPlatform === ClientPlatform.OBA;
  }

  isSEB(): boolean {
    return this.editorConfiguration.clientPlatform === ClientPlatform.SEB;
  }

  isRS(): boolean {
    return this.editorConfiguration.clientPlatform === ClientPlatform.RS;
  }

  hasLotSupport(): boolean {
    return this.editorConfiguration.lotSupport === LotSupport.STRUCTURE;
  }

  getTenderingProcedureId(): number {
    return this.editorConfiguration.tenderingProcedureId;
  }

  participationRequestDateChangeAllowed(): boolean {
    return this.editorConfiguration.participationDeadlineChangeAllowed;
  }

  offerDeadlineChangeAllowed(): boolean {
    return this.editorConfiguration.offerDeadlineChangeAllowed;
  }

  isWithoutPublication(): boolean {
    return this.editorConfiguration.publicationMode === PublicationMode.WITHOUT_PUBLICATION;
  }

  isUpdateAfterRejected(): boolean {
    return this.editorConfiguration.updateAfterRejected;
  }

  isCancellation(): boolean {
    return this.editorConfiguration.cancellation;
  }

  isResetProcedure(): boolean {
    return this.editorConfiguration.resetProcedure;
  }

  isMigratedFromEarlierEFormsVersion(): boolean {
    return this.editorConfiguration.migratedFromEarlierEFormsVersion;
  }

  private isTenderingProcedureIdMissing(params) {
    return params.mode !== FormMode.FORM_CREATE && !params.tenderingProcedureId;
  }

  private readConfiguration(params: Params) {
    if (
      !params.mode ||
      !params.operationMode ||
      !params.clientPlatform ||
      !params.lotSupport ||
      this.isTenderingProcedureIdMissing(params)
    ) {
      console.error('Parameters missing or incomplete');
    }

    this.editorConfiguration = {
      mode: params.mode,
      operationMode: params.operationMode,
      clientPlatform: params.clientPlatform,
      tenderingProcedureId: params.tenderingProcedureId ? +params.tenderingProcedureId : null,
      publicationMode: params.publicationMode,
      cancellation: params.cancellation === 'true',
      resetProcedure: params.resetProcedure === 'true',
      offerDeadlineChangeAllowed: params.offerDeadlineChangeAllowed === 'true',
      participationDeadlineChangeAllowed: params.participationDeadlineChangeAllowed === 'true',
      updateAfterRejected: params.updateAfterRejected === 'true',
      isMigration: params.isMigration === 'true',
      showDevMode: params.showDevMode === 'true',
      clerkMail: params.clerkMail,
      lotSupport: params.lotSupport,
      migratedFromEarlierEFormsVersion: params.migratedFromEarlierEFormsVersion === 'true'
    };
  }
}
