export class EditorConfiguration {
  mode: FormMode;
  operationMode: OperationMode;
  clientPlatform: ClientPlatform;
  tenderingProcedureId?: number;
  cancellation: boolean;
  resetProcedure: boolean;
  offerDeadlineChangeAllowed: boolean;
  participationDeadlineChangeAllowed: boolean;
  publicationMode: PublicationMode;
  updateAfterRejected: boolean;
  isMigration: boolean;
  showDevMode: boolean;
  clerkMail?: string;
  lotSupport: LotSupport;
  migratedFromEarlierEFormsVersion: boolean;
}

/**
 * The FormMode decides, if we create or update an existing Formulars.
 */
export enum FormMode {
  // Creates a new Notice
  FORM_CREATE = 'Create',
  // Creates a new Change-Notice, based on the Content of an existing Notice.
  FORM_UPDATE = 'Update',
  // Updates an existing Notice, without creating a Change-Notice. Uses for Notices, which have not been released yet.
  FORM_ALTER_BEFORE_RELEASE = 'AlterBeforeRelease',
  // Updates an existing Change-Notice, without creating a new Change-Notice. Uses for Change-Notices, which have not been released yet.
  // ReSy: Pruefung auf AlterUpdateBeforeRelease
  FORM_ALTER_UPDATE_BEFORE_RELEASE = 'AlterUpdateBeforeRelease',
  // e-Vergabe specific Update-Mode, in which only Platform specific Meta-Data can be updated.
  FORM_UPDATE_META = 'UpdateMeta',
}

export enum OperationMode {
  PRODUCTION = 'Production',
  // Training Mode: Disabled multiple validations, which would prevent a Notice release on the same day. This can't be used in Production, as it creates Schematron Errors.
  TRAINING = 'Training',
}

export enum ClientPlatform {
  // Online-Beschaffungs-Assistens
  OBA = 'OBA',
  // Standalone-Editor für Bekanntmachungen
  SEB = 'SEB',
  // Redaktionssystem - e-Vergabe specifics are disabled here.
  RS = 'RS',
}

export enum PublicationMode {
  WITH_PUBLICATION = 'WithPublication',
  WITHOUT_PUBLICATION = 'WithoutPublication',
}

export enum LotSupport {
  NONE = 'None',
  STRUCTURE = 'Structure',
}
