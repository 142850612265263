import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthErrorDialogComponent } from './auth-error-dialog.component';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { from, fromEvent, of, Subject } from "rxjs";
import { catchError, takeUntil, filter, exhaustMap } from "rxjs/operators";
import { AuthErrorDialogType, AuthErrorDialogData } from "./auth-error-dialog.model";
import { initializeKeycloak } from "../auth/keycloak.factory";

@Injectable({
  providedIn: 'root',
})
export class AuthErrorDialogService implements OnDestroy {
  openDialog: MatDialogRef<AuthErrorDialogComponent>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private keycloak: KeycloakService
  ) {
    this.initializeKeycloakEventListener();
  }

  private oldUsername?: string;

  initializeKeycloakEventListener(): void {
    this.keycloak.keycloakEvents$.subscribe(event => {

      if (event.type === KeycloakEventType.OnAuthLogout && !this.openDialog) {
        this.oldUsername = this.keycloak.getUsername();

        this.showDialog({errorType: AuthErrorDialogType.LOGOUT});
        this.setupStorageEventListener();
      }

      if (event.type === KeycloakEventType.OnAuthSuccess && this.openDialog) {
        from(this.keycloak.loadUserProfile(true)).subscribe((userprofile) => {
          if(this.oldUsername && this.oldUsername !== userprofile.username) {
            this.showDialog({
              errorType: AuthErrorDialogType.USER_CHANGED,
              errorText: `Erwarteter Nutzer: ${this.oldUsername} (Aktuell: ${userprofile.username})`
            });
          } else {
            this.closeDialog();
            this.unsubscribeFromStorageEvents();
          }
        });
      }
    });
  }

  private setupStorageEventListener() {
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => event.key.startsWith("kc-callback-") && event.newValue == null),
      exhaustMap(() => this.checkKeycloakLoggedIn()),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  private unsubscribeFromStorageEvents(): void {
    this.unsubscribe$.next();
  }

  private checkKeycloakLoggedIn() {
    return from(initializeKeycloak(this.keycloak)).pipe(
      catchError(err => {
        console.error('Error during Keycloak login check:', err);
        return of(null);
      })
    );
  }

  public showDialog(data: AuthErrorDialogData): void {
    if(this.openDialog) {
      this.openDialog.componentInstance.data = data;
    } else {
      this.openDialog = this.dialog.open(AuthErrorDialogComponent, {
        enterAnimationDuration: 0,
        exitAnimationDuration: 0,
        disableClose: true,
        data: {
          title: 'Sitzung ungültig',
          ...data,
          hasCloseButton: false,
          success: false,
        },
      });
    }
  }

  public closeDialog(): void {
    if (this.openDialog) {
      this.openDialog.close();
      this.openDialog = undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeFromStorageEvents();
  }
}
