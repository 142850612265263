import { BaseModel } from '../../view-model/base-model';

export abstract class ModelListener<T extends BaseModel> {
  /**
   * Gets called on a Model Change
   */
  abstract onChange(model: T): void;

  /**
   * Decides, if the Change-Listener should be attached to the given model.
   */
  abstract shouldAttach(model: BaseModel): boolean;
}
