import { Subject } from 'rxjs';
import { BaseModel } from './base-model';
import { GroupModel } from './group-model';

export class RepeatableGroupModel extends BaseModel {
  private counter = 0;
  private _children?: GroupModel[] = [];
  private _isExpanded = false;
  private _afterExpand = new Subject<void>();

  get children(): GroupModel[] {
    return this._children;
  }

  clear(){
    this.counter = 0;
    this._children = [];
  }

  public addChild(model: GroupModel): void {
    model.repeatableIndex = this.counter++;
    this._children.push(model);
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
    this.emitChange();
  }

  set afterExpand(value: Subject<void>) {
    this._afterExpand = value;
  }

  get afterExpand(): Subject<void> {
    return this._afterExpand;
  }
}
