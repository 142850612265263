import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DialogComponent } from '../../dialog/dialog.component';
import prefillingFields from '../../../../services/prefilling-fields.json';
import { PrefillFieldsService } from '../../../../services/prefill-fields.service';
import { SectionModel } from '../../../../view-model/section-model';
import { TranslationService } from '../../../../services/translation.service';
import { RepeatableSectionModel } from '../../../../view-model/repeatable-section-model';
import { GenericDialogService } from '../../../../services/ui/generic-dialog.service';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-prefill-fields',
  templateUrl: './prefill-fields-dialog.component.html',
  styleUrls: ['./prefill-fields-dialog.component.scss'],
})
export class PrefillFieldsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('prefillFieldsDialog') prefillFieldsDialog: DialogComponent;
  @ViewChild('selectionList') selectionList: MatSelectionList;
  @Input() procedureSection: SectionModel;
  @Input() model: RepeatableSectionModel;
  typeName: string;
  fields: string[];
  selectedFields: string[] = [];
  allFieldsSelected: boolean;

  constructor(
    private genericDialogService: GenericDialogService,
    private prefillFieldsService: PrefillFieldsService,
    private translationService: TranslationService,
    private cdR: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.typeName = this.model?.noticeNode?._idScheme === 'LOT' ? 'Los' : 'Teil';
    this.fields = Object.keys(this.getFields());
  }

  ngAfterViewInit() {
    this.selectAll(true);
    this.cdR.detectChanges();
  }

  async prefillFields() {
    await this.prefillFieldsService.prefillFields(
      this.procedureSection,
      this.model,
      this.selectedFields
    );
    await this.genericDialogService.showResultDialog(
      `Technisches ${this.typeName} vorbefüllen`,
      `Die Inhalte aus dem Verfahrensabschnitt sind ins technische ${this.typeName} kopiert.`,
      'Schließen',
      true
    );
  }

  getFieldLabel(key: string) {
    return this.translationService.translate(`field|name|${key}`);
  }

  private getFields() {
    return this.model?.noticeNode?._idScheme === 'LOT'
      ? prefillingFields.Lot
      : prefillingFields.Part;
  }

  stopDefaultBehavior(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  updateSelectedItems() {
    this.selectedFields = this.selectionList?.selectedOptions?.selected.map(option => option.value);
  }

  updateSelectedAllState() {
    const selectedCount = this.selectionList?.selectedOptions?.selected.length;
    this.allFieldsSelected = selectedCount === this.fields.length;
    if (selectedCount === 0) {
      this.prefillFieldsDialog?.disableAcceptButton();
    } else {
      this.prefillFieldsDialog?.enableAcceptButton();
    }
  }

  selectionChanged() {
    this.updateSelectedItems();
    this.updateSelectedAllState();
  }

  selectAll(isChecked: boolean) {
    this.selectionList?.options?.forEach(option => (option.selected = isChecked));
    this.updateSelectedItems();
    this.updateSelectedAllState();
  }
}
