import { FieldModel } from '../field-model';
import { CodelistModelService } from '../../services/ui/codelist-model.service';

export class CodelistModel extends FieldModel<string> {
  private _codeList: { id: string; label: string }[];
  private _isCodeField = false;
  private _isAutoComplete: boolean;

  constructor(private codelistModelService?: CodelistModelService) {
    super();
  }

  async loadSdkProperties() {
    await this.codelistModelService?.loadCodeListItems(this);
  }

  get codeList(): { id: string; label: string }[] {
    return this._codeList;
  }

  set codeList(value: { id: string; label: string }[]) {
    this._codeList = value;
  }

  get isCodeField(): boolean {
    return this._isCodeField;
  }

  set isCodeField(value: boolean) {
    this._isCodeField = value;
  }

  get isAutoComplete(): boolean {
    return this._isAutoComplete;
  }

  set isAutoComplete(value: boolean) {
    this._isAutoComplete = value;
  }
}
