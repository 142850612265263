<div *hasAnyRole="['support']" class="group-list-search-container">
  <input
    [formControl]="searchField"
    class="form-textarea"
    name="searchTerm"
    placeholder="Suchen Sie hier nach Gruppen- oder Benutzernamen" />
</div>

<div *ngIf="!gruppen || gruppen.length === 0" class="empty-label">
  <h5 *ngIf="searchField.value">Keine Gruppen gefunden.</h5>
  <h5 *ngIf="!searchField.value">Noch keine Gruppenmitgliedschaften.</h5>
</div>

<app-gruppen-item
  *ngFor="let gruppe of gruppen; trackBy: identify"
  [gruppe]="gruppe"
  [loggedInUserId]="loggedInUserId$ | async"
  [hasSupportRole]="hasSupportRole"
  (changeName)="onChangeName($event)"
  (createInvitation)="onCreateInvitation($event)"
  (leaveGroup)="onLeaveGroup($event)"
  (removeGroup)="onRemoveGroup($event)"
  (removeMember)="onRemoveMember($event)"
  (transferOwnership)="onTransferOwnership($event)"
  (withdrawInvitation)="onWithdrawInvitation($event)"></app-gruppen-item>

<button mat-button (click)="handleCreateGroupClick()" class="eForms-button eForms-button--primary">
  Gruppe erstellen
</button>

<app-edit-group-dialog (confirm)="onChangeNameConfirmation($event)"></app-edit-group-dialog>
<app-create-group-dialog (confirm)="onGruppeErstellenConfirmation($event)"></app-create-group-dialog>
<app-gruppen-einladung-dialog (confirm)="onInvitationSent($event)"></app-gruppen-einladung-dialog>
