import { FieldModel } from './field-model';
import { FieldValueType } from '../types/data-model';

export class RepeatableFieldModel<T extends FieldValueType> extends FieldModel<T> {
  private counter = 0;
  private _children?: FieldModel<T>[] = [];

  constructor() {
    super();
  }

  public addChild(model: FieldModel<T>): void {
    model.repeatableIndex = this.counter++;
    this._children.push(model);
  }

  get children(): FieldModel<T>[] {
    return this._children;
  }
}
