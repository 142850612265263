import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TextFieldComponent } from '../text-field.component';
import { PostalCodeService } from '../../../../services/postal-code.service';
import { FieldModel } from '../../../../view-model/field-model';
import { GroupModel } from '../../../../view-model/group-model';

@Component({
  templateUrl: '../text-field.component.html',
  styleUrls: ['../text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostalCodeComponent extends TextFieldComponent implements OnInit {
  private postalCodeService = inject(PostalCodeService);

  ngOnInit(): void {
    super.ngOnInit();
  }

  commitValue(): void {
    this.model.value = this.inputControl.value?.trim();
    if (this.model.value?.length === 5) {
      this.updateRelatedFields(this.model.value);
    }
    this.onInputValueChanged();
  }

  private updateRelatedFields(postalCode: string): void {
    this.updateTownField(postalCode);
    const nutsCode = this.updateNutsField(postalCode);
    this.updateCountryField(nutsCode);
  }

  private updateTownField(postalCode: string): void {
    const town = this.postalCodeService.getTownForPostalCode(postalCode);
    if (town) {
      this.applyValueToModel('BT-513', town);
    }
  }

  private updateNutsField(postalCode: string): string {
    const nuts = this.postalCodeService.getNutsCodeForPostalCode(postalCode);
    this.applyValueToModel('BT-507', nuts);
    return nuts;
  }

  private updateCountryField(nutsCode: string): void {
    const countryCode = this.postalCodeService.getThreeDigitCountryCode(nutsCode);
    this.applyValueToModel('BT-514', countryCode);
  }

  private applyValueToModel(id: string, value: string): void {
    const field = (this.model.parent as GroupModel).children.find(child =>
      child.noticeNode.id.startsWith(id)
    ) as FieldModel<any>;
    field.value = value;
    field.emitChange();
  }
}
