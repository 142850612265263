import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { KeycloakProfile } from 'keycloak-js';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TemplateOverviewResponse } from '../api/templates';
import { GroupModel } from '../api/user-service';
import { GruppenService } from '../gruppen/gruppen.service';
import { VorlagenNameDialogComponent, } from './vorlagen-name-dialog/vorlagen-name-dialog.component';
import { ActionEvent, ActionType } from './vorlagen-uebersicht-table/vorlagen-uebersicht-table.component';
import { VorlagenVerwaltungStateService } from './vorlagen-verwaltung-state.service';

@Component({
  selector: 'app-vorlagen-uebersicht',
  templateUrl: './vorlagen-uebersicht.component.html',
  styleUrls: ['./vorlagen-ubersicht.component.scss'],
})
export class VorlagenUbersichtComponent implements OnInit, OnDestroy {

  @ViewChild(VorlagenNameDialogComponent) vorlagenNameDialog!: VorlagenNameDialogComponent;

  loading$: Observable<boolean>;
  dropdownData$: Observable<{ loggedInUser: KeycloakProfile; groups: GroupModel[] }>;
  groups$: Observable<GroupModel[]>;
  loggedInUser$: Observable<KeycloakProfile>;
  iAmOwner$: Observable<boolean>;
  selectedUserId$: Observable<string>;

  dataSource = new MatTableDataSource<TemplateOverviewResponse>();

  private state = inject(VorlagenVerwaltungStateService);
  private gruppenService = inject(GruppenService);
  private activatedRoute = inject(ActivatedRoute);

  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.loading$ = this.state.loading$;
    this.groups$ = this.gruppenService.groups$;
    this.loggedInUser$ = this.state.loggedInUser$;
    this.iAmOwner$ = this.state.iAmOwner$;
    this.selectedUserId$ = this.state.selectedUserId$;
    this.dropdownData$ = combineLatest({loggedInUser: this.loggedInUser$, groups: this.groups$}).pipe(filter(res => !!res.loggedInUser));
    this.state.templates$.pipe(takeUntil(this.destroy$)).subscribe(procedures => this.dataSource.data = procedures);

    // trigger loading everything
    this.state.init();
    this.gruppenService.loadGroupsAndInvitations();
  }

  userFilterChanged(userId: string): void {
    this.state.selectUser(userId);
  }

  onClickActionType(event: ActionEvent) {
    switch (event.actiontype) {
      case ActionType.CREATE_NOTICE:
      case ActionType.CREATE_NOTICE_FROM_GROUPMEMBER:
        this.state.createDraftFromTemplate(event.id);
        break;
      case ActionType.EDIT_TEMPLATE:
        this.state.editTemplate(event.id, this.activatedRoute);
        break;
      case ActionType.COPY_TEMPLATE:
      case ActionType.COPY_TEMPLATE_OF_GROUPMEMBER:
        this.state.showCopyDialog(event.id, this.vorlagenNameDialog);
        break;
      case ActionType.DELETE_TEMPLATE:
        this.state.removeTemplate(event.id);
        break;
    }
  }

  onClickEditName(templateId: number) {
    this.state.showUpdateDialog(templateId, this.vorlagenNameDialog);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
