import { KeycloakService } from "keycloak-angular";
import { environment } from "../../../../environments/environment";

export const keycloakFactory = (keycloak: KeycloakService) => () => initializeKeycloak(keycloak);

export function initializeKeycloak(keycloak: KeycloakService) {
  return keycloak.init({
    config: {
      url: `${environment.keycloakUrl}/auth`,
      realm: 'redaktionssystem',
      clientId: 'eforms-editor',
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      enableLogging: !environment.production,
    },
    loadUserProfileAtStartUp: true,
    // update token before each request (if necessary).
    shouldUpdateToken: () => true,
  });
}
