<app-dialog>
  <div class="dialog-content">
    <h3>Fehlerdetails</h3>
    <ng-container *ngIf="data.report.xsdValidationErrors?.length">
      <p>Typ: XSD_VALIDATION</p>
      <p>Zeitpunkt: {{ now }}</p>
      <div *ngFor="let error of data.report.xsdValidationErrors; index as idx">
        <pre>
          <div>Beschreibung: {{ error.description }}</div>
        </pre>
        <hr *ngIf="idx < data.report.xsdValidationErrors.length - 1" />
      </div>
    </ng-container>
    <ng-container *ngIf="data.report.schematronValidationErrors?.length">
      <p>Typ: SCHEMATRON_VALIDATION</p>
      <p>Zeitpunkt: {{ now }}</p>
      <div *ngFor="let error of data.report.schematronValidationErrors; index as idx">
        <pre>
          <div>Beschreibung: {{ error.description }}</div>
          <div>Pfad: {{ error.location }}</div>
          <div>Fehlgeschlagener Test: {{ error.failedAssert }}</div>
          <div>Regel-ID: {{ error.ruleId }}</div>
        </pre>
        <hr *ngIf="idx < data.report.schematronValidationErrors.length - 1" />
      </div>
    </ng-container>
    <button class="eForms-button" [cdkCopyToClipboard]="getFullErrorMessage()">
      <mat-icon>content_copy</mat-icon>
      <span>In die Zwischenablage kopieren</span>
    </button>
  </div>
</app-dialog>
