import { BaseModel } from './base-model';
import { GroupModel } from './group-model';
import { FieldModel } from './field-model';
import { RepeatableGroupModel } from './repeatable-group-model';
import { ModelStateService } from '../services/view-model/model-state.service';
import { IdModel } from './type/id-model';

type SectionChildren = FieldModel<any> | GroupModel | RepeatableGroupModel | SectionModel;

export class SectionModel extends BaseModel {
  private _children: SectionChildren[] = [];
  private _customTitle: string;
  private _rootRepeatableSectionModel: SectionModel = this;

  get sectionId(): string {
    if (this.rootRepeatableSectionModel.sectionSchemeId) {
      return `${this.noticeNode.id}-${this.rootRepeatableSectionModel.sectionSchemeId}`;
    }
    return `${this.noticeNode.id}`;
  }

  get children(): SectionChildren[] {
    return this._children;
  }

  public addChild(model: SectionChildren): void {
    this._children.push(model);
  }

  get sectionSchemeId(): string {
    if (!this.noticeNode._identifierFieldId) {
      return undefined;
    }

    const targetIdModel = ModelStateService.findModelById<IdModel>(
      this,
      this.noticeNode._identifierFieldId
    );

    if (!targetIdModel) {
      console.warn(
        `Can't find Identifying field ${this.noticeNode._identifierFieldId} for Section ${this.noticeNode.id}`
      );
    }
    return targetIdModel?.value;
  }

  get customTitle(): string {
    return this._customTitle;
  }

  set customTitle(value: string) {
    this._customTitle = value;
  }

  get rootRepeatableSectionModel(): SectionModel {
    return this._rootRepeatableSectionModel;
  }

  set rootRepeatableSectionModel(value: SectionModel) {
    this._rootRepeatableSectionModel = value;
  }

  isNavigableSection(): boolean {
    return !!this._children.find(
      child =>
        child instanceof GroupModel ||
        child instanceof RepeatableGroupModel ||
        child instanceof FieldModel
    );
  }
}
