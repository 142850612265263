import { Injectable } from '@angular/core';
import { ConceptModel, ConceptNode } from '../../types/concept-node';
import { FieldModelType } from '../../types/data-model';
import { FieldModel } from '../../view-model/field-model';
import { RsEditorConfigurationProvider } from '../rs-editor-configuration.provider';
import { SymbolResolutionProvider } from './resolver/symbol-resolution.provider';
import { FieldAssert, FieldConstraint } from '../../types/field-types';
import { SdkService } from '../sdk.service';

interface EvaluationResult {
  resolutionResultMap: any;
  result: boolean;
}

@Injectable()
export class ExpressionEvaluationService {
  constructor(private sdkService: SdkService, private rsConfig: RsEditorConfigurationProvider) {}

  /**
   * Evaluates a Transpiled EFX Expression.
   *
   * @param validationFnRef name of the function inside the ValidationFunctions.mjs file.
   * @param fieldModel of the Node, the Expression is bound to.
   * @param conceptModel of the Formular
   * @param efxContext context of the EFX Expression
   */
  public async evaluateExpression(
    validationFnRef: string,
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel,
    efxContext: string
  ): Promise<EvaluationResult> {
    const validationFunction = this.getJsExpressionFunctionByReferenceName(validationFnRef);

    const symbolResolverProvider = SymbolResolutionProvider.getResolver(
      fieldModel,
      efxContext,
      conceptModel,
      await this.sdkService.getFields()
    );

    const result = validationFunction(
      symbolResolverProvider.resolveNode.bind(symbolResolverProvider),
      symbolResolverProvider.resolveFieldList.bind(symbolResolverProvider),
      symbolResolverProvider.efxContextNode
    );

    return {
      resolutionResultMap: symbolResolverProvider.resolutionResultMap,
      result,
    };
  }

  /**
   * Evaluates the Result of a Field Assertion.
   *
   * @param fieldModel
   * @param fieldAssert
   * @param conceptModel
   */
  public async evaluateFieldAsserts(
    fieldModel: FieldModel<any>,
    fieldAssert: FieldAssert,
    conceptModel: ConceptModel
  ): Promise<boolean> {
    // This value will be the evaluated result, when no constraints exist or is applicable.
    const defaultPropertyValue = await this.evaluateValueProperty(
      fieldModel,
      fieldAssert,
      conceptModel
    );

    // Will be true, when a Constraint with a different value then defaultPropertyValue has been found.
    for (const constraint of fieldAssert.constraints ?? []) {
      if (
        (await this.isConstraintApplying(fieldModel, constraint, conceptModel)) &&
        (await this.evaluateValueProperty(fieldModel, constraint, conceptModel)) !==
          defaultPropertyValue
      ) {
        return !defaultPropertyValue;
      }
    }

    return defaultPropertyValue;
  }

  /**
   * Checks, if the given Constraint is applicable.
   * Does so, by checking if the current noticeId is included in the noticeTypes Array (when given) and if so, by evaluating the condition Field.
   *
   * @param fieldModel
   * @param constraint
   * @param conceptModel
   */
  private async isConstraintApplying(
    fieldModel: FieldModel<any>,
    constraint: FieldConstraint,
    conceptModel: ConceptModel
  ): Promise<boolean> {
    if (constraint.noticeTypes && !constraint.noticeTypes.includes(fieldModel.root.noticeId)) {
      return false;
    }
    if (constraint.condition) {
      return (
        await this.evaluateExpression(
          constraint.conditionRef,
          fieldModel,
          conceptModel,
          constraint.conditionContext
        )
      ).result;
    }
    return true;
  }

  private async evaluateValueProperty(
    fieldModel: FieldModel<any>,
    fieldAssert: FieldAssert | FieldConstraint,
    conceptModel: ConceptModel
  ): Promise<boolean> {
    if (typeof fieldAssert.value === 'boolean') {
      // Simple boolean
      return fieldAssert.value as boolean;
    } else if (
      !fieldAssert.value ||
      typeof fieldAssert.value !== 'string' ||
      !fieldAssert.valueRef
    ) {
      return false;
    }
    // fieldAssert.value is a string -> EFX Expression
    return (
      await this.evaluateExpression(
        fieldAssert.valueRef,
        fieldModel,
        conceptModel,
        fieldAssert.valueContext
      )
    ).result;
  }

  /**
   *
   * Returns the typed validation function with given reference name.
   *
   * @param refName function name
   * @private
   */
  private getJsExpressionFunctionByReferenceName(
    refName: string
  ): (
    resolveNodeList: (nodeId: string, context: any, absolute?: boolean) => ConceptNode[],
    resolveFieldList: (nodeId: string, context: any, absolute?: boolean) => FieldModelType[],
    context: ConceptNode
  ) => boolean {
    // ReSy: Referenziere die ValidationFunctions des korrekten SDKs, das in Abhaengigkeit der subTypeId in der rsConfig bestimmt wird
    const instanceName = `validationfunctions${this.rsConfig.getSdk()}`;
    if (!(window as any)[instanceName]) {
      throw new Error(
        `${instanceName} is NOT attached at the Window Object! Please add a script Tag and load it in your application.`
      );
    }
    return (window as any)[instanceName][refName].bind((window as any)[instanceName]);
  }
}
