import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Severity } from '../../../../types/app-types';
import { NavigableNotification } from '../../../../types/NavigableNotification';
import { NavigationService } from '../../../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { Language } from '../../../../types/data-model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  @Input() notification: NavigableNotification;
  @Input() index: number;

  multilingual: string;

  protected readonly Severity = Severity;

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.notification.validationNotification.targetLanguage) {
      this.multilingual =
        this.notification.validationNotification.targetLanguage === Language.Deu
          ? ' (DE)'
          : ' (EN)';
    }
  }

  navigateTo(): void {
    this.navigationService.navigate(
      this.notification.model,
      this.activatedRoute,
      this.notification.validationNotification.targetLanguage
    );
  }
}
