<form
  [class.stacked]="totalLabelLength > 60"
  [class.disabled]="control.disabled"
  [class.noValueSelected]="control.value === ''"
>
  <fieldset class="radio-button-group"
      [class.sidebyside]="totalLabelLength <= 60"
      [disabled]="isReadonly">
    <label *ngFor="let option of options; let idx = index" class="radio-option">
      <input
        class="form-input-radio"
        type="radio"
        [id]="htmlId + (idx === 0 ? '' : '_' + option.id)"
        [name]="groupName"
        [value]="option"
        [formControl]="control"
        [class.has-error]="hasError"
        [class.required]="isMandatory && !isReadonly"
      />
      <span>{{ option.label }}</span>
    </label>
    <button
      (click)="reset()"
      *ngIf="!isReadonly"
      [disabled]="control.value === null || control.value === undefined"
      class="eForms-button">
      Zurücksetzen
    </button>
  </fieldset>
</form>
