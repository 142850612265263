import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toStatusBadgeClass' })
export class StatusBadgeClassPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case 'ACCEPTED':
        return 'light-blue';
      case 'NOT_SEND':
      case 'NO_RESPONSE':
      case 'INTERNAL_ERROR':
        return 'yellow';
      case 'AWAITING_TRANSFER':
      case 'PENDING':
      case 'PROCESSING':
        return 'gray';
      case 'PUBLISHING':
        return 'light-green';
      case 'PUBLISHED':
        return 'dark-green';
      case 'STOPPED':
      case 'REJECTED':
      case 'MANUALLY_REJECTED':
        return 'red';
      default:
        return '';
    }
  }
}
