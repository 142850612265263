<div class="address-book-btn-container">
  <button
    mat-mini-fab
    (click)="saveAddress(); $event.stopPropagation();"
    (keydown.space)="saveAddress(); stopDefaultBehavior($event)"
    (keydown.enter)="saveAddress(); stopDefaultBehavior($event)"
    [disabled]="!isSaveAllowed()"
    class="repeatable-control eForm-icon-button normal"
    matTooltip="Adresse speichern"
    aria-label="Adresse speichern">
    <mat-icon>save</mat-icon>
  </button>
  <button
    mat-mini-fab
    (click)="openAddressBookDialog(); $event.stopPropagation();"
    (keydown.space)="openAddressBookDialog(); stopDefaultBehavior($event)"
    (keydown.enter)="openAddressBookDialog(); stopDefaultBehavior($event)"
    class="repeatable-control eForm-icon-button normal"
    matTooltip="Adressbuch öffnen"
    aria-label="Adressbuch öffnen">
    <mat-icon>book</mat-icon>
  </button>
</div>

<app-dialog #addressBookDialog title="Adressbuch" dismissLabel="Schließen" (closed)="onClose()">
  <div *ngIf="contacts">
    <div class="search-function">
      <div class="search-icon-input">
        <mat-icon class="search-icon">search</mat-icon>
        <input
          type="text"
          [(ngModel)]="searchQuery"
          placeholder="Volltextsuche..."
          (input)="searchAddress(searchQuery)" />
      </div>

      <button
        class="clear-button"
        mat-icon-button
        *ngIf="!model.isReadonly"
        (click)="clearSearch()"
        aria-label="Sucheingabe löschen">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div *ngFor="let contact of filteredContacts" class="address-card">
      <div [attr.aria-label]="contact.officialName" class="official-name word-breaker">
        {{ contact.officialName }}
      </div>
      <div class="content">
        <div>
          <p *ngIf="contact.nationalId">
            <span *ngIf="isRS">Identifikationsnummer:</span>
            <span *ngIf="!isRS">Registrierungsnummer:</span>
            {{ contact.nationalId }}
          </p>
          <p *ngIf="contact.url"><span>Internet-Adresse:</span> {{ contact.url }}</p>
          <p *ngIf="contact.address"><span> Postanschrift: </span> {{ contact.address }}</p>
          <p *ngIf="contact.town"><span>Ort:</span> {{ contact.town }}</p>
          <p *ngIf="contact.postalCode"><span>Postleitzahl:</span> {{ contact.postalCode }}</p>
          <p *ngIf="contact.nuts"><span>NUTS-Code:</span> {{ contact.nuts }}</p>
          <p *ngIf="contact.country"><span>Land:</span> {{findCountryLabel(contact.country) }}</p>
          <p *ngIf="contact.contactPoint"><span>Kontaktstelle:</span> {{ contact.contactPoint }}</p>
          <p *ngIf="contact.email"><span>E-Mail:</span> {{ contact.email }}</p>
          <p *ngIf="contact.phone"><span>Telefon:</span> {{ contact.phone }}</p>
          <p *ngIf="contact.fax"><span>Fax:</span> {{ contact.fax }}</p>
          <p *ngIf="contact.urlBuyer">
            <span>Endpunkt für den Informationsaustausch:</span> {{ contact.urlBuyer }}
          </p>
        </div>
        <div class="buttons-container">
          <div class="action-icons">
            <app-change-address
              (contactsEmitter)="saveContactsChanges($event)"
              [importedContact]="contact"></app-change-address>
            <button
              mat-mini-fab
              (click)="deleteContact(contact)"
              class="repeatable-control eForm-icon-button danger"
              matTooltip="Adresse löschen"
              matTooltipPosition="above"
              aria-label="Adresse löschen">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <button (click)="setContact(contact)" class="eForms-button eForms-button--primary">
            Adresse einfügen
          </button>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
