import { ModelListener } from './ModelListener';
import { ModelStateService } from '../view-model/model-state.service';
import { OFFER_TYPE_ID } from '../view-model/evergabe-meta-data.service';
import { FieldModel } from '../../view-model/field-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { MetadataOffertypeCodelistModel } from '../../view-model/type/metadata-offertype-codelist-model';
import { FormType } from '../../types/form-type';
import { OfferType } from '../../view-model/e-vergabe-meta-data';
import { MetadataProcurementtypeCodelistModel } from '../../view-model/type/metadata-procurementtype-codelist-model';

export class ProcurementTypeListener extends ModelListener<FieldModel<any>> {
  constructor(private editorConfigurationProvider: EditorConfigurationProvider) {
    super();
  }

  onChange(model: FieldModel<any>): void {
    const offerTypeModel: MetadataOffertypeCodelistModel = ModelStateService.findModelById(
      model.root,
      OFFER_TYPE_ID
    );

    const procurementTypeModel: MetadataProcurementtypeCodelistModel =
      ModelStateService.findModelById(model.root, 'BT-105-Procedure');

    if (offerTypeModel === null || procurementTypeModel === null || this.procedureIsReleased() || model.root.formType === FormType.PRIOR_INFORMATION) {
      return;
    }
    const nextProcurementType = procurementTypeModel.value;
    const prevProcurementType = procurementTypeModel.previousProcurementType;

    const numberOfLots = ModelStateService.findModelsById(model.root, 'BT-137-Lot').length;

    if (this.procurementTypeHasNegotiationRounds(nextProcurementType)) {
      offerTypeModel.value = this.getOfferTypeWhenLotsAreConsideredInCurrentFormType(
        model.root.formType,
        OfferType.PROCEDURE
      );
      offerTypeModel.isReadonly = true;
    } else {
      if (this.procurementTypeHasNegotiationRounds(prevProcurementType)) {
        if (numberOfLots > 1) {
          offerTypeModel.value = null;
        } else {
          offerTypeModel.value = this.getOfferTypeWhenLotsAreConsideredInCurrentFormType(
            model.root.formType,
            OfferType.PROCEDURE
          );
        }
      }

      offerTypeModel.isReadonly = numberOfLots <= 1;
    }

    procurementTypeModel.previousProcurementType = nextProcurementType;

    offerTypeModel.emitChange();
  }

  procurementTypeHasNegotiationRounds(procurementTypeValue: string) {
    return (
      procurementTypeValue === 'neg-w-call' ||
      procurementTypeValue === 'neg-wo-call' ||
      procurementTypeValue === 'innovation'
    );
  }

  procedureIsReleased() {
    return (
      this.editorConfigurationProvider.isChangeNotice() ||
      this.editorConfigurationProvider.isUpdateMetaData() ||
      this.editorConfigurationProvider.isUpdateAfterRejected()
    );
  }

  getOfferTypeWhenLotsAreConsideredInCurrentFormType(
    formType: FormType,
    selectedOfferType: OfferType
  ) {
    return formType !== FormType.PRIOR_INFORMATION ||
      this.editorConfigurationProvider.hasLotSupport()
      ? selectedOfferType
      : null;
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return this.editorConfigurationProvider.isOBA() && model.noticeNode?.id === 'BT-105-Procedure';
  }
}
