import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '../../../services/state.service';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { RepeatableGroupModel } from '../../../view-model/repeatable-group-model';
import { RepeatableFieldModel } from '../../../view-model/repeatable-field-model';
import { GroupModel } from '../../../view-model/group-model';
import { FieldModel } from '../../../view-model/field-model';
import { TranslationService } from '../../../services/translation.service';
import { OptionalRepeatableService } from '../../../services/view-model/optional-repeatable.service';
import { RepeatableService } from './repeatable.service';

@Component({
  templateUrl: './repeatable.component.html',
  styleUrls: ['./repeatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatableComponent extends AbstractFormComponent implements OnInit {
  model: RepeatableGroupModel | RepeatableFieldModel<any>;
  fieldHeaderModel: RepeatableFieldModel<any>;
  groupChildren: GroupModel[];
  fieldChildren: FieldModel<any>[];
  isField: boolean;
  showBaseElement = false;

  constructor(
    private translationService: TranslationService,
    protected stateService: StateService,
    private optionalRepeatableService: OptionalRepeatableService,
    private repeatableService: RepeatableService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isField = this.model instanceof RepeatableFieldModel;

    if (this.isField) {
      this.fieldChildren = this.model.children as FieldModel<any>[];
      this.fieldHeaderModel = this.model as RepeatableFieldModel<any>;
    } else {
      this.showBaseElement = this.model.children.length < 1;
      this.groupChildren = this.model.children as GroupModel[];
    }
  }

  getPanelTitle(): string {
    const headingDepth = Math.min(this.model.depth + 2, 6);
    return `<h${headingDepth}>
        ${this.translationService.translate(this.model.noticeNode._label)}
      </h${headingDepth}>`;
  }

  stopDefaultBehavior(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  showDeleteButton(childs: GroupModel[]): boolean {
    return (
      childs.length > 1 ||
      this.optionalRepeatableService.isRepeatableOptional(
        this.model.noticeNode?.id,
        this.model.root.formType,
        this.model.root.noticeId
      )
    );
  }

  async addComponent() {
    await this.repeatableService.addComponent(this.model, this.isField);

    if (!this.isField) {
      this.showBaseElement = false;
    }
  }

  async deleteComponent(i: number) {
    await this.repeatableService.deleteComponent(this.model, i);
    this.showBaseElement = this.model.children.length < 1;
    console.log('ShowBaseElement: ' + this.showBaseElement);
    console.log('Amount of children: ' + this.model.children.length);
    this.cdRef.markForCheck();
  }
}
