import { BaseModel } from '../../../view-model/base-model';
import { Severity, ValidationNotification } from '../../../types/app-types';
import { ConceptModel } from '../../../types/concept-node';

export abstract class ModelValidator<T extends BaseModel> {
  /**
   * Validates the Model and returns them as ValidationNotifications. Will only get executed, when value is not null.
   */
  abstract validate(model: T, conceptModel?: ConceptModel): Promise<ValidationNotification[]>;

  /**
   * Decides, if the Validator should be attached to the given model.
   */
  abstract shouldAttach(model: T): boolean;

  protected ofSingle(
    message: string,
    severity = Severity.ERROR
  ): Promise<ValidationNotification[]> {
    return Promise.resolve([new ValidationNotification(message, severity)]);
  }

  protected empty() {
    return Promise.resolve([]);
  }
}

// Extending validators will only be attached, when started with OBA or SEB as Client Platform
export abstract class EVergabeSpecificValidator<T extends BaseModel> extends ModelValidator<T> {}
