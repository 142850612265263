import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EformsControllerService, RsNoticeSubTypeDto } from '../api/notices';
import { LEGAL_BASIS_MAP } from './bekanntmachungen-auswahl/model/legal-basis';
import { FilterTableViewModel } from '../shared/filter-table/FilterTableViewModel';

@Injectable({
  providedIn: 'root',
})
export class NoticeTypesService {
  private _noticeSubTypes$: Observable<RsNoticeSubTypeDto[]>;
  private _templateSubTypesWhitelist: string[] = [
    'T01',
    ...Array.from({ length: 25 }, (_, i) => `${i + 4}`),
  ];

  constructor(private service: EformsControllerService) {
    this._noticeSubTypes$ = this.service.getNoticesOverview().pipe(shareReplay(1));
  }

  getNoticeSubTypes(): Observable<RsNoticeSubTypeDto[]> {
    return this._noticeSubTypes$.pipe(startWith([]));
  }

  getSubTypeIdsforTemplates(): Observable<RsNoticeSubTypeDto[]> {
    return this.getNoticeSubTypes().pipe(
      map(subTypes =>
        subTypes.filter(dto => this._templateSubTypesWhitelist.includes(dto.subTypeId))
      )
    );
  }

  mapToDisplayData(noticeSubType: RsNoticeSubTypeDto): FilterTableViewModel {
    return {
      subTypeId: noticeSubType.subTypeId,
      legalBasis: noticeSubType.legalBasis,
      legalBasisLabel: LEGAL_BASIS_MAP[noticeSubType.legalBasis],
      type: noticeSubType.type,
      formType: noticeSubType.formType,
      formTypeLabel: noticeSubType.formTypeLabel,
      bekanntmachungsart: noticeSubType.typeLabel,
      unterart: noticeSubType.subTypeLabel,
    };
  }

  getNameBySubTypeId(subTypeId: string): any {
    return this.getNoticeSubTypes().pipe(
      map(subTypes => subTypes.find(subType => subType.subTypeId === subTypeId)?.subTypeLabel),
      map(label => `${subTypeId} – ${label}`)
    );
  }

  getTypeLabelByNoticeType(noticeType: string): any {
    return this.getNoticeSubTypes().pipe(
      map(subTypes => subTypes.find(subType => subType.type === noticeType)?.typeLabel)
    );
  }

  getFormTypeLabelByFormType(formType: string): any {
    return this.getNoticeSubTypes().pipe(
      map(subTypes => subTypes.find(subType => subType.formType === formType)?.formTypeLabel)
    );
  }
}
