<div [hidden]="model.isHidden" class="form-component-input-group">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"> </app-field-header>
  <textarea
    [id]="htmlId"
    [formControl]="inputControl"
    (blur)="onInputValueChanged()"
    class="form-textarea"
    [attr.aria-label]="model.translatedLabel"
    [class.has-error]="hasInputError()"
    [class.required]="model.isMandatory && !model.isReadonly"
    [appMaxLengthBadge]="maxLength"
    appTrimAndMapEmptyToNull
    rows="3">
  </textarea>
  <app-validation-message [model]="model"></app-validation-message>
</div>
