import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { ComponentDirective } from '../../component.directive';
import { DynamicComponentFactoryService } from '../../../services/dynamic-component-factory.service';
import { StateService } from '../../../services/state.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { GroupModel } from '../../../view-model/group-model';

@Component({
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GroupComponent extends AbstractFormComponent implements OnInit, AfterViewInit {
  @ViewChild(ComponentDirective, { static: true }) public view: ComponentDirective;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  @Input() model: GroupModel;
  supportMode = false;
  init = false;

  constructor(
    private dynamicComponentFactoryService: DynamicComponentFactoryService,
    stateService: StateService
  ) {
    super();
    this.subscription.add(
      stateService.getSupportMode().subscribe(next => (this.supportMode = next))
    );
  }

  ngOnInit(): void {
    this.lazyGenerateChildren();
  }

  ngAfterViewInit(): void {
    this.handleExpansionChanges();
  }

  validate(): Promise<boolean> {
    return Promise.resolve(true);
  }

  getPanelTitle(): string {
    return `<h${this.model.depth + 2}>
         ${this.model.translatedLabel}
       </h${this.model.depth + 2}>`;
  }

  private handleExpansionChanges(): void {
    if (this.model.isExpanded) {
      this.model.afterExpand.next();
    }

    this.subscription.add(
      this.expansionPanel?.afterExpand.subscribe(() => this.model.afterExpand.next())
    );

    this.subscription.add(
      this.expansionPanel?.expandedChange.subscribe(expanded => {
        this.model.isExpanded = expanded;
        this.lazyGenerateChildren();
      })
    );
  }

  private lazyGenerateChildren() {
    if (this.model.isExpanded && !this.init) {
      this.init = true;
      this.dynamicComponentFactoryService.generateComponents(
        this.view.viewContainerRef,
        this.model
      );
    }
  }
}
