import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean {
    // wenn innerhalb der Maske navigiert wird, soll nicht ueber ungespeicherte Aenderungen informiert werden
    if (!!nextState.url.match(/^\/(bekanntmachungen|vorlagen)\/(anlegen|verwalten)\/notice/)) {
      return true;
    }

    return component.canDeactivate()
      ? true
      : // Diese Nachricht wird nur bei einer internen Navigation angezeigt.
        // Falls der Tab/das Fenster geschlossen wird, erscheint eine browserspezifische Meldung.
        confirm('Erfassungsmaske verlassen?\nVorgenommene Änderungen werden nicht gespeichert.');
  }
}
