import { Pipe, PipeTransform } from '@angular/core';

const noticeActionTypeLabels = {
  SHOW_PREVIEW: 'Vorschau anzeigen',
  EXPORT_CN: 'Bekanntmachung exportieren',
  EDIT_DRAFT: 'Entwurf bearbeiten',
  EDIT_SENT_NOTICE: 'Bekanntmachung aktualisieren',
  EDIT_SENT_CHANGE_NOTICE: 'Bekanntmachung aktualisieren',
  DELETE_DRAFT: 'Entwurf löschen',
  STOP: 'Bekanntmachung zurückziehen',
  CREATE_CHANGE_NOTICE: 'Bekanntmachung korrigieren',
  CREATE_CN: 'Auftragsbekanntmachung anlegen',
  CREATE_CAN: 'Ex-Post anlegen',
  CREATE_CAN_MODIF: 'Auftragsänderung anlegen',
};

@Pipe({ name: 'noticeActionTypeToLabel' })
export class NoticeActionTypeToLabelPipe implements PipeTransform {
  transform(type: string): string {
    return noticeActionTypeLabels[type];
  }
}
