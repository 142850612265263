<div class="form-component-input-group" [hidden]="model.isHidden">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"> </app-field-header>
  <input
    [id]="htmlId"
    [formControl]="inputControl"
    (blur)="commitValue()"
    class="form-text-field"
    [attr.aria-label]="model.translatedLabel"
    [class.has-error]="hasInputError()"
    [class.required]="model.isMandatory && !model.isReadonly"
    appTrimAndMapEmptyToNull
    placeholder="{{ model.isReadonly ? '' : placeholder }}"
    [appMaxLengthBadge]="model.fieldInfo?.maxLength" />
  <app-validation-message [model]="model"></app-validation-message>
</div>
