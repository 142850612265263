import { EVergabeSpecificValidator } from '../ModelValidator';
import { DateModel } from '../../../../view-model/type/date-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { DayOfWeek } from '@js-joda/core';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';

export class DeadlineOnWeekendValidator extends EVergabeSpecificValidator<DateModel> {
  private static readonly BTS_FOR_DEADLINE_ON_WEEKEND_VALIDATION: string[] = [
    'BT-1311(d)-Lot', // participation deadline
    'BT-131(d)-Lot', // offer deadline
    OFFER_DEADLINE_DATE_ID, // provisional offer deadline
    'BT-132(d)-Lot', // offer opening deadline
    'BT-738-notice', // preferred publication date
  ];
  private static readonly DEADLINE_ON_WEEKEND_WARNING_MSG =
    'Das Ende der Frist liegt auf einem Wochenende.';

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    const chosenDay = dateModel.asLocalDate().dayOfWeek();

    if (
      !dateModel.isReadonly &&
      dateModel.value &&
      (chosenDay === DayOfWeek.SATURDAY || chosenDay === DayOfWeek.SUNDAY)
    ) {
      return this.ofSingle(
        DeadlineOnWeekendValidator.DEADLINE_ON_WEEKEND_WARNING_MSG,
        Severity.WARNING
      );
    }
    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return DeadlineOnWeekendValidator.BTS_FOR_DEADLINE_ON_WEEKEND_VALIDATION.includes(
      model.noticeNode.id
    );
  }
}
