<app-dialog
  (accept)="onClickWeiter()"
  (dismiss)="onDismiss()"
  (closed)="onDismiss()"
  [hasCloseButton]="true"
  title="Gruppenname ändern"
  acceptLabel="Speichern"
  dismissLabel="Abbrechen">
  <div>
    <form *ngIf="gruppenInputForm" [formGroup]="gruppenInputForm">
      <div>
        <label>
          Name
          <span class="required-asterisk">*</span>
          <div class="input-maxlength-badge-wrapper">
            <span class="input-maxlength-badge">{{ remainingNameCharacters() }}</span>
          </div>
          <input
            formControlName="name"
            class="form-textarea required"
            name="gruppenName"
            placeholder="Gruppenname"
            (input)="validateIfConfirmButtonIsEnabled()"
            [class.has-error]="
              !gruppenInputForm.get('name').valid && gruppenInputForm.get('name').touched
            "
            [maxLength]="nameMaxLength"
            required />
        </label>
      </div>
    </form>
  </div>
</app-dialog>
