import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentDirective } from '../../../component.directive';
import { DynamicComponentFactoryService } from '../../../../services/dynamic-component-factory.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { GroupModel } from '../../../../view-model/group-model';
import { StateService } from '../../../../services/state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-repeatable-group',
  templateUrl: './repeatable-group.component.html',
  styleUrls: ['./repeatable-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatableGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ComponentDirective, { static: true }) public view: ComponentDirective;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  @Output() addComponent = new EventEmitter<void>();
  @Output() deleteComponent = new EventEmitter<void>();
  @Input() model: GroupModel;
  @Input() showAddButton: boolean;
  @Input() showDeleteButton: boolean;
  supportMode = false;
  init = false;

  private subscription = new Subscription();

  constructor(
    stateService: StateService,
    private dynamicComponentFactoryService: DynamicComponentFactoryService,
    private cdRef: ChangeDetectorRef
  ) {
    this.subscription.add(
      stateService.getSupportMode().subscribe(next => (this.supportMode = next))
    );
  }

  ngOnInit(): void {
    this.lazyGenerateChildren();
    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => this.cdRef.markForCheck())
    );
  }

  ngAfterViewInit(): void {
    this.handleExpansionChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getPanelTitle(): string {
    const headingDepth = Math.min(this.model.depth + 2, 6);
    return `<h${headingDepth}>
        ${this.model.translatedLabel}
      </h${headingDepth}>`;
  }

  add(): void {
    this.addComponent.emit();
  }

  delete(): void {
    this.deleteComponent.emit();
  }

  isSelectionCriteriaBook() {
    return this.model?.noticeNode?.id === 'GR-Lot-SelectionCriteria';
  }

  private handleExpansionChanges(): void {
    if (this.model.isExpanded) {
      this.model.afterExpand.next();
    }

    this.subscription.add(
      this.expansionPanel?.afterExpand.subscribe(() => this.model.afterExpand.next())
    );

    this.subscription.add(
      this.expansionPanel?.expandedChange.subscribe(expanded => {
        this.model.isExpanded = expanded;
        this.lazyGenerateChildren();
      })
    );
  }

  stopDefaultBehavior(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private lazyGenerateChildren() {
    if (this.model.isExpanded && !this.init) {
      this.init = true;
      this.dynamicComponentFactoryService.generateComponents(
        this.view.viewContainerRef,
        this.model
      );
    }
  }
}
