import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { DateModel } from '../../../../view-model/type/date-model';
import { ModelStateService } from '../../../view-model/model-state.service';
import { FieldInfo } from '../../../../types/field-types';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';
import { TranslationService } from '../../../translation.service';

export class OfferOpeningDeadlineAfterOfferDeadlineValidator extends EVergabeSpecificValidator<
  FieldModel<string>
> {
  private static readonly ERROR_MESSAGE =
    "Zeitpunkt muss später sein als 'Frist für den Eingang der Angebote' (BT-131)";

  private static readonly OFFER_OPENING_DEADLINE_BT_ID = 'BT-132(d)-Lot';
  private static readonly OFFER_DEADLINE_DATE_BT_ID = 'BT-131(d)-Lot';

  constructor(
    private editorConfigurationProvider: EditorConfigurationProvider,
    private translationService: TranslationService
  ) {
    super();
  }

  shouldAttach(model: FieldModel<string>): boolean {
    if (
      model.noticeNode.id ===
      OfferOpeningDeadlineAfterOfferDeadlineValidator.OFFER_OPENING_DEADLINE_BT_ID
    ) {
      this.addDependencies(model.fieldInfo);
      return true;
    }
    return false;
  }

  async validate(offerOpeningDeadline: DateModel): Promise<ValidationNotification[]> {
    const offerDeadline = ModelStateService.findModelById(
      offerOpeningDeadline.root,
      OfferOpeningDeadlineAfterOfferDeadlineValidator.OFFER_DEADLINE_DATE_BT_ID
    ) as DateModel;

    if (
      this.isOfferDeadlineOnSameDateButNotBeforeOfferOpeningDeadline(
        offerDeadline,
        offerOpeningDeadline
      )
    ) {
      return this.ofSingle(OfferOpeningDeadlineAfterOfferDeadlineValidator.ERROR_MESSAGE);
    }

    return this.empty();
  }

  private isOfferDeadlineOnSameDateButNotBeforeOfferOpeningDeadline(
    offerDeadline: DateModel,
    offerOpeningDeadline: DateModel
  ): boolean {
    return (
      offerDeadline?.value &&
      offerDeadline.asLocalDate().isEqual(offerOpeningDeadline.asLocalDate()) &&
      !offerDeadline.withRelatedTime().isBefore(offerOpeningDeadline.withRelatedTime())
    );
  }

  /**
   *  Add OfferDeadline as dependency.
   */
  private addDependencies(fieldInfo: FieldInfo) {
    if (
      !fieldInfo.dependencyFields?.includes(
        OfferOpeningDeadlineAfterOfferDeadlineValidator.OFFER_DEADLINE_DATE_BT_ID
      )
    ) {
      fieldInfo.dependencyFields.push(
        OfferOpeningDeadlineAfterOfferDeadlineValidator.OFFER_DEADLINE_DATE_BT_ID
      );
    }
  }
}
