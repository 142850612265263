import { KeycloakProfile } from 'keycloak-js';
import { TemplateOverviewResponse } from '../api/templates';

export interface VorlagenVerwaltungState {
  loading: boolean;
  loggedInUser: KeycloakProfile;
  templates: TemplateOverviewResponse[];

  // filter
  selectedUserId: string;
}

export const initialState: VorlagenVerwaltungState = {
  loading: false,
  loggedInUser: null,
  templates: [],

  // filter
  selectedUserId: null,
};
