import { Component, inject } from '@angular/core';
import { AbstractFormComponent } from '../../abstract-form/abstract-form.component';
import { ModelGenerationService } from '../../../../services/view-model/model-generation.service';

@Component({
  selector: 'app-custom-group-component',
  template: ''
})
export abstract class AbstractCustomGroup extends AbstractFormComponent {
  private modelGenerationService: ModelGenerationService = inject(ModelGenerationService);

  abstract buildSubTree(): Promise<void>;

  async onInputChange() {
    await this.buildSubTree();
    await this.modelGenerationService.initSubtree(this.model);
  }
}
