import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RsEditorConfigurationProvider } from '../services/rs-editor-configuration.provider';

// ReSy: Resolver, um RsEditorConfigurationProvider mit Route-Daten zu initialisieren, sobald auf die Maske navigiert wird.
@Injectable({
  providedIn: 'root'
})
export class EditorConfigResolver implements Resolve<boolean> {

  constructor(
    private rsConfig: RsEditorConfigurationProvider
  ) {
  }

  /* Der EditorConfigurationProvider muss in root provided werden, da er in verschiedenen Modulen verwendet wird.
  * Das hat allerdings zur Folge, dass beim Laden der Erfassungsmaske (FormRootComponent) die Daten der EditorConfig
  * noch nicht aktualisiert sind, die Maske anhand von veralteten Parametern aufgebaut und anschließend nicht mehr
  * aktualisiert wird.
  * Die Lösung: ein Resolver, damit die Component erst aufgebaut wird, nachdem die Config korrekt eingelesen wurde
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.rsConfig.setConfigurationFromRoute(route.params, route.queryParams);
    return true;
  }

}
