import { Injectable } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/layout/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class GenericDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Opens a confirmation-dialog with custom content.
   * Returns a Promise, which will be fulfilled with the Users choice.
   *
   * @param title of the dialog
   * @param acceptLabel label of the accept button
   * @param dismissLabel label of the abort button
   * @param content Optional Text, which will be displayed below the title, in the body section of the Dialog.
   * @return Promise, which resolves to true, if the Dialog was accepted, or false, if the dialog was aborted.
   */
  public async confirmWithDialog(
    title: string,
    acceptLabel: string,
    dismissLabel: string,
    content?: string | TemplatePortal<any>
  ): Promise<boolean> {
    return new Promise<boolean>(res => {
      this.dialog
        .open(DialogComponent, {
          enterAnimationDuration: 0,
          exitAnimationDuration: 0,
          data: {
            title,
            content,
            acceptLabel,
            dismissLabel,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            res(true);
          }
          res(false);
        });
    });
  }

  public async confirmWithWarningDialog(
    title: string,
    acceptLabel: string,
    dismissLabel: string,
    content?: string | TemplatePortal<any>
  ): Promise<boolean> {
    return new Promise<boolean>(res => {
      this.dialog
        .open(DialogComponent, {
          enterAnimationDuration: 0,
          exitAnimationDuration: 0,
          data: {
            title,
            content,
            acceptLabel,
            dismissLabel,
            warning: true,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            res(true);
          }
          res(false);
        });
    });
  }

  public async openWarningDialog(
    title: string,
    acceptLabel: string,
    content?: string | TemplatePortal<any>
  ): Promise<boolean> {
    return new Promise<boolean>(res => {
      this.dialog
        .open(DialogComponent, {
          enterAnimationDuration: 0,
          exitAnimationDuration: 0,
          data: {
            title,
            content,
            acceptLabel,
            warning: true,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            res(true);
          }
          res(false);
        });
    });
  }

  public async showResultDialog(
    title: string,
    content: string,
    acceptLabel: string,
    result: boolean
  ): Promise<boolean> {
    return new Promise<boolean>(res => {
      const dialog = this.dialog.open(DialogComponent, {
        enterAnimationDuration: 0,
        exitAnimationDuration: 0,
        data: {
          title,
          content,
          acceptLabel,
          success: result,
          centered: true,
        },
      });
      dialog.afterClosed().subscribe(choice => {
        if (choice) {
          res(true);
        }
        res(false);
      });
    });
  }

  /**
   * Opens an info-dialog with custom content.
   *
   * @param title of the dialog
   * @param dismissLabel label of the abort button
   * @param content Optional Text, which will be displayed below the title, in the body section of the Dialog.
   * @return Promise, which resolves, if the Dialog was closed.
   */
  public async openInfoDialog(
    title: string,
    dismissLabel: string,
    content?: string | TemplatePortal<any>
  ): Promise<void> {
    return new Promise<void>(res => {
      this.dialog
        .open(DialogComponent, {
          enterAnimationDuration: 0,
          exitAnimationDuration: 0,
          data: {
            title,
            content,
            dismissLabel,
          },
        })
        .afterClosed()
        .subscribe(() => {
          res();
        });
    });
  }
}
