<app-dialog>
  <div class="dialog-content">
    <ng-container *ngIf="data.errorType || data.errorText">
      <h3>Fehlerdetails</h3>
      <p *ngIf="data.errorType">Typ: {{ data.errorType }}</p>
      <p>Zeitpunkt: {{ now }}</p>
      <pre *ngIf="data.errorText">{{ data.errorText }}</pre>
      <button
        class="eForms-button"
        [cdkCopyToClipboard]="getFullErrorMessage(data.errorType, data.errorText)">
        <mat-icon>content_copy</mat-icon>
        <span>In die Zwischenablage kopieren</span>
      </button>
    </ng-container>
  </div>
</app-dialog>
