import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { GroupModel } from "../../api/user-service";
import { GruppenUtils } from '../gruppen.utils';

@Component({
  selector: 'app-gruppen-item',
  templateUrl: './gruppen-item.component.html',
  styleUrls: ['./gruppen-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GruppenItemComponent {
  @Input() gruppe: GroupModel;
  @Input() loggedInUserId: string;
  @Input() hasSupportRole: boolean;

  @Output() changeName = new EventEmitter<GroupModel>();
  @Output() createInvitation = new EventEmitter<number>();
  @Output() leaveGroup = new EventEmitter<GroupModel>();
  @Output() removeGroup = new EventEmitter<GroupModel>();

  @Output() removeMember = new EventEmitter<{ memberId: number; groupId: number }>();
  @Output() transferOwnership = new EventEmitter<{ memberId: number; groupId: number }>();
  @Output() withdrawInvitation = new EventEmitter<{ memberId: number; groupId: number }>();

  private genericDialogService = inject(GenericDialogService);

  iAmOwner(): boolean {
    return GruppenUtils.isOwner(this.gruppe, this.loggedInUserId);
  }

  iAmInGroup(): boolean {
    return GruppenUtils.isInGroup(this.gruppe, this.loggedInUserId);
  }

  handleChangeNameClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.changeName.emit(this.gruppe);
  }

  showInvitationDialog(): void {
    this.createInvitation.emit(this.gruppe.id);
  }

  onRemoveMember(memberId: number): void {
    this.removeMember.emit({ memberId, groupId: this.gruppe.id });
  }

  onTransferOwnership(memberId: number): void {
    this.transferOwnership.emit({ memberId, groupId: this.gruppe.id });
  }

  onWithdrawInvitation(memberId: number): void {
    this.withdrawInvitation.emit({ memberId, groupId: this.gruppe.id });
  }

  confirmLeaveGroup(): void {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Gruppe verlassen',
      'Gruppe verlassen',
      'Abbrechen',
      `Sind Sie sicher, dass Sie die Gruppe "${this.gruppe.name}" verlassen möchten?`
    );
    from(confirmationResult)
      .pipe(filter(confirmed => confirmed))
      .subscribe(() => this.leaveGroup.emit(this.gruppe));
  }

  confirmRemoveGroup(): void {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Gruppe löschen',
      'Gruppe löschen',
      'Abbrechen',
      `Sind Sie sicher, dass Sie die Gruppe "${this.gruppe.name}" löschen möchten?`
    );
    from(confirmationResult)
      .pipe(filter(confirmed => confirmed))
      .subscribe(() => this.removeGroup.emit(this.gruppe));
  }
}
