import { Pipe, PipeTransform } from '@angular/core';
import { ProcedureOverviewDto } from '../../api/notices';

@Pipe({ name: 'procedureGetLatestTitlePipe' })
export class ProcedureGetLatestTitlePipe implements PipeTransform {
  transform(procedure: ProcedureOverviewDto): string {
    if (!procedure.notices || procedure.notices.length === 0) {
      return '';
    }

    return procedure.notices.reduce((latest, current) =>
      new Date(latest.lastUpdated) > new Date(current.lastUpdated) ? latest : current
    ).name;
  }
}
