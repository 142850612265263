import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NoticeDefinition } from '../../../types/notice-definition';
import { NoticeSubType } from '../../../types/notice-types';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
import { FadeInOutAnimations } from '../../../animation';

@Component({
  selector: 'app-formular-container',
  templateUrl: './formular-container.component.html',
  styleUrls: ['./formular-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInOutAnimations],
})
export class FormularContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() notice: NoticeDefinition;
  @Input() noticeSubType: NoticeSubType;

  fadeInOutAnimationState = 'in';
  loading: boolean;
  notificationBoxHeaderHeight = 0;
  notificationBoxExpandedMinSize = 140;
  notificationBoxUserSize = this.notificationBoxExpandedMinSize;
  transitionsEnabled = false;
  private subscription = new Subscription();

  constructor(private loadingService: LoadingService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscription.add(
      this.loadingService.loadingEventEmitter().subscribe(loading => {
        this.loading = loading;
        this.fadeInOutAnimationState = this.fadeInOutAnimationState === 'out' ? 'in' : 'out';
        this.cdRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.reorderSplitSliders();
    this.expandNotificationBox(false);
    this.hideNotificationBoxSlider(true);
  }

  setNotificationBoxHeaderHeight(height: number): void {
    this.notificationBoxHeaderHeight = height;
  }

  expandNotificationBox(expand: boolean): void {
    const asSplitNotificationBoxBottom = document.getElementById(
      'as-split-notification-box-bottom'
    );

    if (expand) {
      asSplitNotificationBoxBottom.style.flexBasis = this.notificationBoxUserSize + 'px';
      this.hideNotificationBoxSlider(false);
      setTimeout(() => (this.transitionsEnabled = false), 200);
    } else {
      this.notificationBoxUserSize = Math.max(
        this.notificationBoxExpandedMinSize,
        Number(asSplitNotificationBoxBottom?.style.flexBasis.match(/\d+/)[0])
      );
      if (asSplitNotificationBoxBottom)
        asSplitNotificationBoxBottom.style.flexBasis = this.notificationBoxHeaderHeight + 'px';
      this.hideNotificationBoxSlider(true);
      this.transitionsEnabled = true;
    }
  }

  /**
   * Angular-Split locates the sliders after both areas. To ensure correct tab-order and behaviour, the sliders are reordered in the DOM.
   *
   * @private
   */
  private reorderSplitSliders(): void {
    const navigationAsSplit = document.getElementById('as-split-navigation');
    const navigationAsSplitGutter = navigationAsSplit?.getElementsByClassName('as-split-gutter')[1];
    const navigationAsSplitAreaLeft = navigationAsSplit?.getElementsByClassName('as-split-area')[0];
    if (navigationAsSplitAreaLeft && navigationAsSplitGutter)
      navigationAsSplitAreaLeft.after(navigationAsSplitGutter);

    const notificationBoxAsSplit = document.getElementById('#as-split-notification-box');
    const notificationBoxAsSplitGutter =
      notificationBoxAsSplit?.getElementsByClassName('as-split-gutter')[0];
    const notificationBoxAsSplitAreaTop =
      notificationBoxAsSplit?.getElementsByClassName('as-split-area')[0];
    if (notificationBoxAsSplitAreaTop && notificationBoxAsSplitGutter)
      notificationBoxAsSplitAreaTop.after(notificationBoxAsSplitGutter);
  }

  private hideNotificationBoxSlider(hidden: boolean): void {
    const asSplitGutterNotificationBox = document
      .getElementById('as-split-notification-box')
      ?.getElementsByClassName('as-split-gutter')[0] as HTMLElement;
    if (asSplitGutterNotificationBox) asSplitGutterNotificationBox.hidden = hidden;
  }
}
