import { Injectable } from '@angular/core';
import { EditorConfigurationProvider } from '../editor-configuration.provider';

@Injectable()
export class PreferredReleaseDateService {
  constructor(private editorConfigurationProvider: EditorConfigurationProvider) {}

  public isFixedToToday(noticeId: string): boolean {
    return (
      this.isWithoutPublishingOrIsUpdateAfterRejected() ||
      this.isExPostOrExAnteAndEVergabe(noticeId) ||
      this.isChangeNotice()
    );
  }

  /**
   * ExPost + ExAnte can only be sent directly by the e-Vergabe, so we have to set it to the current date.
   * This field is also hidden in
   */
  private isExPostOrExAnteAndEVergabe(noticeId: string): boolean {
    const subTypeAsNumber = Number(noticeId);

    return (
      this.editorConfigurationProvider.isEVergabePlatform() &&
      !isNaN(subTypeAsNumber) &&
      subTypeAsNumber >= 25 &&
      subTypeAsNumber <= 37
    );
  }

  /**
   * The release of procedures without participation competition is handled internally and not by the eu
   * and if the procedure gets updated after rejection, the release date gets set to today automatically and not by the user
   * which is why the input is {@link DynamicModelPropertyService#isHidden() hidden}.
   */
  private isWithoutPublishingOrIsUpdateAfterRejected(): boolean {
    return (
      this.editorConfigurationProvider.isWithoutPublication() ||
      this.editorConfigurationProvider.isUpdateAfterRejected()
    );
  }

  private isChangeNotice(): boolean {
    return this.editorConfigurationProvider.isEVergabePlatform() &&
      // ReSy: Pruefung auf AlterUpdateBeforeRelease
      (this.editorConfigurationProvider.isChangeNotice() || this.editorConfigurationProvider.isAlterUpdateBeforeRelease());
  }
}
