import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GroupMemberRole, GroupModel } from '../../../api/user-service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-groupmember-dropdown',
  templateUrl: './groupmember-dropdown.component.html',
  styleUrls: ['./groupmember-dropdown.component.scss']
})
export class GroupmemberDropdownComponent implements OnInit, OnChanges {

  @Input()
  groups: GroupModel[];

  @Input()
  ownUser: {name: string, username: string, userId: string};

  @Input()
  selectedUserId: string;

  @Output()
  changeSelection: EventEmitter<string> = new EventEmitter<string>();

  stateForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.filterGroups();

    this.stateForm = this._formBuilder.group({
      stateGroup: this.selectedUserId
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Skip any onChanges when init didnt run
    if(!this.stateForm)
      return;

    if (changes.groups || changes.ownUserId) {
      this.filterGroups();
    }
    if (changes.selectedUserId) {
      this.stateForm.setValue({ stateGroup: this.selectedUserId });
    }
  }

  filterGroups() {
    this.groups = this.groups.map(group => {
      return {
        ...group,
        members: group.members
          .filter(member => member.userId != this.ownUser.userId)
          .filter(member => member.role != GroupMemberRole.Invited)
      }
    })
  }

  onSelectionChange(event: any) {
    if(this.selectedUserId && this.selectedUserId != event.value){
      this.selectedUserId = event.value;
      this.changeSelection.emit(this.selectedUserId);
    }
  }

  protected readonly GroupMemberRole = GroupMemberRole;
}
