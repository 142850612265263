import { FieldModel } from '../field-model';
import { SdkService } from '../../services/sdk.service';
import { Measure } from '../../types/data-model';

export class MeasureModel extends FieldModel<Measure> {
  protected _units: { id: string; label: string }[] = [];

  constructor(private sdkService: SdkService) {
    super();
  }

  async loadSdkProperties(): Promise<void> {
    this._units = await this.sdkService.getCodeList('duration-unit');
  }

  get units(): { id: string; label: string }[] {
    return this._units;
  }
}
