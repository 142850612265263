<div *ngIf="!einladungen || einladungen.length === 0" class="empty-label">
  <h5>Keine offenen Gruppeneinladungen.</h5>
</div>
<app-einladung-item
  *ngFor="let einladung of einladungen"
  [einladung]="einladung"
  (acceptInvitation)="onAcceptInvitation($event)"
  (declineInvitation)="onDeclineInvitation($event)"
>
</app-einladung-item>
