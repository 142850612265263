<mat-expansion-panel>
  <mat-expansion-panel-header class="mat-expansion-panel-header">
    <mat-panel-title class="mat-expansion-panel-header-title">
      <div>
        {{ gruppe.name }}
        <button
          mat-icon-button
          *ngIf="iAmOwner()"
          matTooltip="Gruppennamen ändern"
          (click)="handleChangeNameClick($event)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div>Erstellt am: {{ gruppe.creationDate | date }}</div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <app-gruppen-mitglieder
      [mitglieder]="gruppe.members"
      [iAmOwner]="iAmOwner()"
      [hasSupportRole]="hasSupportRole"
      (removeMember)="onRemoveMember($event)"
      (transferOwnership)="onTransferOwnership($event)"
      (withdrawInvitation)="onWithdrawInvitation($event)"></app-gruppen-mitglieder>

    <div class="group-actions">
      <button
        mat-button
        class="eForms-button eForms-button--primary"
        *ngIf="iAmInGroup()"
        [disabled]="iAmOwner()"
        (click)="confirmLeaveGroup()">
        Gruppe verlassen
      </button>
      <button
        mat-button
        class="eForms-button eForms-button--primary"
        *ngIf="iAmOwner()"
        (click)="showInvitationDialog()">
        Gruppenmitglied einladen
      </button>
      <button
        mat-button
        class="eForms-button eForms-button--primary"
        *ngIf="iAmOwner()"
        (click)="confirmRemoveGroup()">
        Gruppe löschen
      </button>
    </div>
  </ng-template>
</mat-expansion-panel>
