<ng-template #dialogTemplate [ngIf]="renderDialog">
  <div mat-dialog-title *ngIf="data?.title" class="eForms-dialog-header">
    <h1 class="dialog-title">{{ data.title }}</h1>
    <button
      mat-mini-fab
      mat-dialog-close
      *ngIf="data?.hasCloseButton !== false"
      aria-label="Schließe Dialog"
      class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="eForms-dialog-content" [class.center-text]="data.centered">
    <mat-icon class="icon" *ngIf="data.success === true"> task_alt</mat-icon>
    <mat-icon class="icon danger" *ngIf="data.success === false"> error</mat-icon>
    <mat-icon class="icon warning" *ngIf="data.warning === true"> warning</mat-icon>
    <div [innerHTML]="data?.content"></div>
    <ng-content></ng-content>
  </div>
  <mat-dialog-actions align="end" class="eForms-dialog-footer">
    <button
      mat-button
      mat-dialog-close
      *ngIf="data?.dismissLabel"
      (click)="dismissed()"
      class="eForms-button">
      {{ data.dismissLabel }}
    </button>

    <!-- accept button WITH auto-closing -->
    <button
      mat-button
      [mat-dialog-close]="true"
      *ngIf="data?.acceptLabel && autoClose"
      (click)="accepted()"
      [disabled]="acceptButtonDisabled"
      class="eForms-button eForms-button--primary">
      {{ data.acceptLabel }}
    </button>

    <!-- accept button WITHOUT auto-closing -->
    <button
      mat-button
      *ngIf="data?.acceptLabel && !autoClose"
      (click)="accepted()"
      [disabled]="acceptButtonDisabled"
      class="eForms-button eForms-button--primary">
      {{ data.acceptLabel }}
    </button>
  </mat-dialog-actions>
</ng-template>
