import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { Language, Multilingual } from '../../../types/data-model';
import { DisplayType } from '../../../types/notice-definition';
import { MultilingualModel } from '../../../view-model/type/multilingual-model';

@Component({
  templateUrl: './multi-lingual.component.html',
  styleUrls: ['./multi-lingual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiLingualComponent extends AbstractFormComponent implements OnInit {
  germanControl: FormControl;
  englishControl: FormControl;
  isTextArea: boolean;
  initialValue: Multilingual;
  maxLength: number;
  model: MultilingualModel;
  focusGermanEmitter = new EventEmitter<(el: HTMLElement) => void>();
  focusEnglishEmitter = new EventEmitter<(el: HTMLElement) => void>();

  protected readonly Language = Language;

  constructor() {
    super();
    this.germanControl = new FormControl();
    this.englishControl = new FormControl();
  }

  ngOnInit(): void {
    this.isTextArea = this.model.noticeNode.displayType === DisplayType.TEXTAREA;
    this.maxLength = +this.model.fieldInfo?.maxLength;

    this.initValue();

    if (this.model.isReadonly) {
      this.germanControl.disable({ emitEvent: false });
      this.englishControl.disable({ emitEvent: false });
    }
  }

  validateGermanField() {
    this.model.germanValue = this.germanControl.value;
    this.onInputValueChanged();

    this.cdRef.markForCheck();
  }

  validateEnglishField() {
    this.model.englishValue = this.englishControl.value;
    this.onInputValueChanged();

    this.cdRef.markForCheck();
  }

  isGerman(): boolean {
    return this.model.activeLanguages.includes(Language.Deu);
  }

  isEnglish(): boolean {
    return this.model.activeLanguages.includes(Language.Eng);
  }

  initValue() {
    if (this.model.germanValue) {
      this.germanControl.setValue(this.model.germanValue, { emitEvent: false });
    }

    if (this.model.englishValue) {
      this.englishControl.setValue(this.model.englishValue, { emitEvent: false });
    }
  }

  protected focus(): void {
    if (this.model.focusedLanguage === Language.Deu) {
      this.focusGermanEmitter.emit(super.focus.bind(this));
    }

    if (this.model.focusedLanguage === Language.Eng) {
      this.focusEnglishEmitter.emit(super.focus.bind(this));
    }

    if (!this.model.focusedLanguage) {
      if (this.isGerman()) {
        this.focusGermanEmitter.emit(super.focus.bind(this));
      } else {
        this.focusEnglishEmitter.emit(super.focus.bind(this));
      }
    }

    this.model.focus = false;
    this.model.focusedLanguage = null;
  }

  protected onModelChange() {
    super.onModelChange();

    if (this.isGerman() && this.model.germanValue) {
      const germanChanged = this.model.germanValue !== this.germanControl.value;
      this.germanControl.setValue(this.model.germanValue);
      if (germanChanged) {
        this.onInputValueChanged();
      }
    }

    if (this.isEnglish() && this.model.englishValue) {
      const englishChanged = this.model.englishValue !== this.englishControl.value;
      this.englishControl.setValue(this.model.englishValue);
      if (englishChanged) {
        this.onInputValueChanged();
      }
    }
    this.cdRef.markForCheck();
  }
}
