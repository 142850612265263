<div class="center-container">
  <ng-container *ngIf="isLoggedIn$ | async">
    <h1>Login erfolgreich, Seite schließt automatisch.</h1>
    <p>Falls nicht, können Sie diese Seite selbst schließen</p>
  </ng-container>
  <ng-container *ngIf="!(isLoggedIn$ | async)">
    <h1>Login war nicht erfolgreich, versuchen Sie es bitte erneut.</h1>
    <p>Falls dies immer noch auftritt, melden Sie sich beim Support.</p>
  </ng-container>
</div>
