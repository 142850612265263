<table mat-table [dataSource]="dataSource" matSort matSortActive="unterart" matSortDirection="asc">
  <ng-container matColumnDef="error">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disabled></th>
    <td mat-cell *matCellDef="let element">
      <mat-icon
        *ngIf="element.notice.errorType || element.notice.errorText"
        [matTooltip]="element.notice.errorType"
        (click)="showErrorDetailDialog(element.notice)"
        >error</mat-icon
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="unterart">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Unterart der Bekanntmachung</th>
    <td mat-cell *matCellDef="let element">
      <table>
        <tr>
          <td *ngIf="!element.isNewestNoticeForSubtypeId" class="history-spacer"></td>
          <td>
            <div>
              <p>{{ element.notice.subTypeId | subTypeIdToName | async }}</p>
              <ng-container
                *ngIf="element.isNewestNoticeForSubtypeId && element.historyIds.length > 0">
                <a
                  *ngIf="element.showHistory"
                  (click)="onShowHistory(element)"
                  class="history-toggle"
                  >Historie ausblenden</a
                >
                <a
                  *ngIf="!element.showHistory && element.historyIds.length > 1"
                  (click)="onShowHistory(element)"
                  class="history-toggle"
                  >Historie einblenden ({{ element.historyIds.length }} Elemente)</a
                >
                <a
                  *ngIf="!element.showHistory && element.historyIds.length === 1"
                  (click)="onShowHistory(element)"
                  class="history-toggle"
                  >Historie einblenden ({{ element.historyIds.length }} Element)</a
                >
              </ng-container>
            </div>
          </td>
        </tr>
      </table>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
    <td mat-cell *matCellDef="let element">
      <div class="max-lines-wrapper" [matTooltip]="element.notice.name || 'noch nicht benannt'">
        <p>{{ element.notice.name || 'noch nicht benannt' }}</p>
      </div>
      <br />
      <div>(Bekanntmachungskennung: {{ element.notice.noticeId }})</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="lastEdited">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Letzte Bearbeitung</th>
    <td mat-cell *matCellDef="let element">
      <p>{{ element.notice.lastEdited | date : 'dd.MM.yyyy HH:mm' }} Uhr</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="tedStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status TED</th>
    <td mat-cell *matCellDef="let element">
      <app-status-badge
        [matTooltip]="
          'Zuletzt aktualisiert: ' +
          (element.notice.lastUpdated | date : 'dd.MM.yyyy HH:mm') +
          ' Uhr'
        "
        [status]="element.notice.tedStatus"
        [link]="element.notice.tedPublicationLink"></app-status-badge>
    </td>
  </ng-container>

  <ng-container matColumnDef="dseStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status DÖE</th>
    <td mat-cell *matCellDef="let element">
      <app-status-badge
        [matTooltip]="
          'Zuletzt aktualisiert: ' +
          (element.notice.lastUpdated | date : 'dd.MM.yyyy HH:mm') +
          ' Uhr'
        "
        [status]="element.notice.dseStatus"
        [link]="element.notice.bkmsPublicationLink"></app-status-badge>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>Aktionen</th>
    <td mat-cell *matCellDef="let element">
      <app-bekanntmachung-aktionen
        [notice]="element"
        (triggerAction)="onTriggerAction($event)"
      ></app-bekanntmachung-aktionen>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    [ngClass]="{ history: !row.isNewestNoticeForSubtypeId }"
    mat-row
    *matRowDef="let row; columns: displayedColumns"></tr>
</table>
