import { ModelValidator } from '../ModelValidator';
import { ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';
import { MultilingualModel } from '../../../../view-model/type/multilingual-model';
import { AmountModel } from '../../../../view-model/type/amount-model';
import { MeasureModel } from '../../../../view-model/type/measure-model';

export class MandatoryValidator extends ModelValidator<FieldModel<any>> {
  static readonly MANDATORY_ERROR_MSG = 'Bitte erfassen Sie einen Wert.';

  constructor() {
    super();
  }

  validate(fieldModel: FieldModel<any>): Promise<ValidationNotification[]> {
    if (
      fieldModel.value === null ||
      fieldModel.value === undefined ||
      (typeof fieldModel.value === 'string' && fieldModel.value.length === 0)
    ) {
      return this.ofSingle(MandatoryValidator.MANDATORY_ERROR_MSG);
    }
    return this.empty();
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return (
      !(
        model instanceof MultilingualModel ||
        model instanceof AmountModel ||
        model instanceof MeasureModel
      ) && model.isMandatory
    );
  }
}
