import {Injectable} from '@angular/core';
import {FormType} from '../../types/form-type';

/**
 * Repeatable Groups / Fields have no defined minAmount / maxAmount inside the SDK.
 * Some repeatable are required by xsd/schematron while some are not.
 * This Service decides, if the given Field ID should be required at least one time (repeatable-mandatory) or no time (repeatable-optional).
 * In the future, this needs to be extracted from the SDK.
 */
@Injectable({
  providedIn: 'root',
})
export class OptionalRepeatableService {
  public static readonly OPTIONAL_REPEATABLE_ELEMENTS_FOR_ALL_CONFIGURATIONS = [
    'GR-Touch-Point',
    'GR-TenderingParty',
    'GR-UBO',
    'GR-LotResult-CVD',
    'GR-Subcontractor',
  ];

  /**
   * Decides if the given fieldId / GroupId should exist at least one time in the formular.
   *
   * @param id of the given field/group
   * @param formType of the current notice
   * @param noticeSubType subType of the current notice
   * @return true, if the field doesn't have to exist atleast one time in the Formular.
   */
  public isRepeatableOptional(id: string, formType: FormType, noticeSubType: string): boolean {
    if (
      OptionalRepeatableService.OPTIONAL_REPEATABLE_ELEMENTS_FOR_ALL_CONFIGURATIONS.includes(id)
    ) {
      return true;
    }

    if (id === 'GR-LotTender' && !['38', '39', '40', 'T02'].includes(noticeSubType)) {
      return true;
    }

    if (id === 'GR-SettledContract' && formType !== FormType.CONTRACT_MODIFICATION && formType !== FormType.EXANTE)
      return true;

    if (id === 'GR-Lot-ProcurementDocuments' && ['7', '8', '9'].includes(noticeSubType))
      return true;

    return false;
  }
}
