import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-badge',
  template: `
    <span *ngIf="status">
      <a *ngIf="linkExists()" [href]="link" target="_blank">
        <span [ngClass]="[status | toStatusBadgeClass, 'clickable']">
          <div>
              {{ status }}
          </div>
          <div>
            <mat-icon>open_in_new</mat-icon>
          </div>
        </span>
      </a>
      <span *ngIf="!linkExists()" [ngClass]="status | toStatusBadgeClass">
        <div>
            {{ status }}
        </div>
      </span>
    </span>
  `,
  styles: [
    `
      .mat-icon {
        height: auto;
        width: auto;
        padding-left: 5px;
      }

      span {
        display: flex;
        align-items: center;
        height: 20px;
        border-radius: 10px;
        font-size: 12px;

        a {
          text-decoration: none;
        }

        span {
          padding: 0 12px;
          font-weight: bold;

          background-color: #ddd;
          color: #000;

          &.light-blue {
            background-color: #6290c3;
            color: #fff;
          }

          &.gray {
            background-color: #c3c3c3;
            color: #000;
          }

          &.yellow {
            background-color: #ffc400;
            color: #000;
          }

          &.light-green {
            background-color: #bce79c;
            color: #000;
          }

          &.dark-green {
            background-color: #0c8346;
            color: #fff;
          }

          &.red {
            background-color: #f06543;
            color: #fff;
          }

          &.clickable:hover {
            background-color: white;
            color: black;
          }
        }
      }
    `,
  ],
})
export class StatusBadgeComponent {
  @Input() status: string;
  @Input() link: string;

  linkExists(): boolean {
    return this.link?.length > 0 && this.status === 'PUBLISHED';
  }
}
