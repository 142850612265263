import { createReducer, on } from '@ngrx/store';
import * as ErrorsActions from './errors.action';

export const initialState = [];

/**
 * Reducer für die Fehler der Feldvalidierung
 */
export const errorsReducer = createReducer(
  initialState,
  on(ErrorsActions.set, (state, props) => props.notifications)
);
