import { Injectable } from '@angular/core';
import { FieldConstraint } from '../../types/field-types';
import { BusinessRuleResult, Severity } from '../../types/app-types';
import { TranslationService } from '../translation.service';
import { ConceptModelService } from '../concept-model.service';
import { ConceptModel } from '../../types/concept-node';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { FieldModel } from '../../view-model/field-model';
import { ExpressionEvaluationService } from '../expression/expression-evaluation.service';
import { ModelStateService } from '../view-model/model-state.service';
import trainingExclusions from './trainingmode-exclusions.json';

/**
 * Service for validating Field Assertions of a NoticeNode.
 */
@Injectable()
export class BusinessRuleValidationService {
  constructor(
    private expressionEvaluationService: ExpressionEvaluationService,
    private conceptModelService: ConceptModelService,
    private translationService: TranslationService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private modelStateService: ModelStateService
  ) {}

  /**
   * Validates a FieldModel, by executing all Assertions of that NoticeNode.
   *
   * @param fieldModel of which assertions should be executed
   * @param conceptModel ConceptModel of the whole Form. If none is passed, a Model of the current state will be generated and used for symbol resolution.
   */
  public async validate(
    fieldModel: FieldModel<any>,
    conceptModel?: ConceptModel
  ): Promise<BusinessRuleResult[]> {
    const constraints = fieldModel?.fieldInfo?.assert?.constraints;
    const assertionResults = [];

    const notIgnoredConstraints = constraints?.filter(
      constraint => !this.isIgnoredConstraintForWithoutPublishing(constraint)
    );

    if (!(notIgnoredConstraints && notIgnoredConstraints.length > 0)) {
      return assertionResults;
    }

    const generatedConceptModel =
      conceptModel ??
      (await this.conceptModelService.generateConceptModel(this.modelStateService.componentModel));

    for (const constraint of notIgnoredConstraints) {
      assertionResults.push(
        await this.evaluateConstraint(constraint, fieldModel, generatedConceptModel)
      );
    }

    return assertionResults;
  }

  private async evaluateConstraint(
    constraint: FieldConstraint,
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel
  ): Promise<BusinessRuleResult> {
    const validationResult: BusinessRuleResult = {
      efx: constraint.value,
      isConditional: constraint.condition !== undefined,
      conditionResult: true,
      severity: constraint.severity === 'ERROR' ? Severity.ERROR : Severity.WARNING,
      messageId: constraint.message,
    };

    if (!constraint.valueRef) {
      validationResult.error = new Error(`Missing Assertion JS-Transpile for: ${constraint.value}`);
      return validationResult;
    }

    try {
      if (
        !(await this.isConditionApplying(constraint, validationResult, fieldModel, conceptModel))
      ) {
        validationResult.conditionResult = false;
        return validationResult;
      }

      const result = await this.expressionEvaluationService.evaluateExpression(
        constraint.valueRef,
        fieldModel,
        conceptModel,
        constraint.valueContext
      );
      validationResult.resolvedValues = result.resolutionResultMap;
      validationResult.jsFunction = constraint.valueRef;

      if (!result.result) {
        validationResult.validationMessage = this.translationService.translate(constraint.message);
      }

      // ToDo JuMa: Better way of ignoring Constraints in Training Mode
      // Offers can't be opened on the same day of the offer deadline. In Trainingmode, we need to be able to do this, so we set it to warning.
      if (
        this.editorConfigurationProvider.isTrainingMode() &&
        trainingExclusions.constraints.includes(validationResult.messageId)
      ) {
        validationResult.severity = Severity.WARNING;
      }
    } catch (error) {
      validationResult.error = error;
    }

    return validationResult;
  }

  private isIgnoredConstraintForWithoutPublishing(constraint: FieldConstraint): boolean {
    return (
      this.editorConfigurationProvider.isWithoutPublication() &&
      (constraint?.dependencyFields?.includes('BT-105-Procedure') ||
        constraint.message === 'rule|text|BR-BT-00105-0104')
    );
  }

  /*
   * Checks the Condition of a FieldConstraint. This will evaluate the Condition-Function and return the result.
   */
  private async isConditionApplying(
    constraint: FieldConstraint,
    businessRuleResult: BusinessRuleResult,
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel
  ): Promise<boolean> {
    if (!constraint.condition) {
      return true;
    }

    if (!constraint.conditionRef) {
      businessRuleResult.error = new Error(
        `Missing Condition JS-Transpile for: ${constraint.condition}`
      );
      return false;
    }

    const result = await this.expressionEvaluationService.evaluateExpression(
      constraint.conditionRef,
      fieldModel,
      conceptModel,
      constraint.conditionContext
    );

    businessRuleResult.resolvedConditionValues = result.resolutionResultMap;
    return result.result;
  }
}
