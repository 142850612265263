import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationReportDialogData } from './validation-report-dialog.model';

@Component({
  selector: 'app-validation-report-dialog',
  templateUrl: './validation-report-dialog.component.html',
  styleUrls: ['./validation-report-dialog.component.scss'],
})
export class ValidationReportDialogComponent {
  now = new Date();

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data?: ValidationReportDialogData) {}

  getFullErrorMessage(): string {
    const xsdErrorText = this.data.report.xsdValidationErrors
      .map(error => `Beschreibung: ${error.description}`)
      .join('\n\n---\n\n');
    const schematronErrorText = this.data.report.schematronValidationErrors
      .map(
        error =>
          `Beschreibung: ${error.description}\nPfad: ${error.location}\nFehlgeschlagener Test: ${error.failedAssert}\nRegel-ID: ${error.ruleId}`
      )
      .join('\n\n---\n\n');
    return `${this.now}\n${xsdErrorText ? 'XSD_VALIDATION:\n' + xsdErrorText : ''}${
      schematronErrorText ? '\nSCHEMATRON_VALIDATION:\n' + schematronErrorText : ''
    }`;
  }
}
