import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Severity } from '../../../types/app-types';
import { FieldModel } from '../../../view-model/field-model';
import { Subscription } from 'rxjs';
import { Language } from '../../../types/data-model';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent implements OnInit, OnDestroy {
  protected readonly Severity = Severity;

  errorMessages: string[];
  warningMessages: string[];
  infoMessages: string[];

  @Input() model: FieldModel<any>;
  @Input() secondaryModel: FieldModel<any>;
  @Input() language?: Language;

  private subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => {
        this.splitValidationNotifications();
      })
    );
    if (this.secondaryModel) {
      this.subscription.add(
        this.secondaryModel.modelChangedEmitter.subscribe(() => {
          this.splitValidationNotifications();
        })
      );
    }
    this.splitValidationNotifications();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private splitValidationNotifications() {
    let notifications = this.model.validationNotifications.concat(this.model.fieldNotifications);

    if (this.secondaryModel) {
      // Filter for Duplicate Notifications. We only need to show the once here.
      notifications = notifications.concat(
        this.secondaryModel.validationNotifications.filter(
          secModelNot =>
            !notifications.some(mainModelNot => mainModelNot.message === secModelNot.message)
        )
      );
    }

    this.errorMessages = notifications
      .filter(error => error.targetLanguage === this.language && error.severity === Severity.ERROR)
      .map(value => value.message);

    this.warningMessages = notifications
      .filter(
        error => error.targetLanguage === this.language && error.severity === Severity.WARNING
      )
      .map(value => value.message);

    this.infoMessages = notifications
      .filter(error => error.targetLanguage === this.language && error.severity === Severity.INFO)
      .map(value => value.message);

    this.cdRef.markForCheck();
  }
}
