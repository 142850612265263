import { Injectable } from '@angular/core';
import { ComponentModel } from '../../view-model/component-model';
import {
  ContentType,
  DisplayType,
  NoticeDefinition,
  NoticeNode,
} from '../../types/notice-definition';
import { SectionModel } from '../../view-model/section-model';
import { GroupModel } from '../../view-model/group-model';
import { FieldModel } from '../../view-model/field-model';
import { OPP080Model } from '../../view-model/type/opp-080-model';
import { SdkService } from '../sdk.service';
import { FieldTypeService } from '../field-type.service';
import { CodelistComponent } from '../../components/form-components/codelist/codelist.component';
import { AmountComponent } from '../../components/form-components/amount/amount.component';
import { CodelistModelService } from '../ui/codelist-model.service';
import { RepeatableFieldModel } from '../../view-model/repeatable-field-model';
import { BaseModel, ModelWithChildren, ModelWithParent } from '../../view-model/base-model';
import { RepeatableGroupModel } from '../../view-model/repeatable-group-model';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';
import { DateComponent } from '../../components/form-components/date/date.component';
import { ModelValidationService } from './model-validation.service';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { AmountModel } from '../../view-model/type/amount-model';
import { DateModel } from '../../view-model/type/date-model';
import { MeasureComponent } from '../../components/form-components/measure/measure.component';
import { MeasureModel } from '../../view-model/type/measure-model';
import { TimeComponent } from '../../components/form-components/date/time/time.component';
import { TimeModel } from '../../view-model/type/time-model';
import { MultilingualModel } from '../../view-model/type/multilingual-model';
import { MultiLingualComponent } from '../../components/form-components/multi-lingual/multi-lingual.component';
import {
  Amount,
  FieldValueType,
  LocalisedText,
  Measure,
  Multilingual,
} from '../../types/data-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { EFORMS_METADATA_ID, EFormsMetaDataService } from './eforms-meta-data.service';
import { DETAILS_ID, EVergabeMetaDataService } from './evergabe-meta-data.service';
import { RepeatableSectionService } from './repeatable-section.service';
import { TranslationService } from '../translation.service';
import { SyncedFieldService } from '../synced-field.service';
import { SchemeIdService } from './scheme-id.service';
import { IdComponent } from '../../components/form-components/id/id.component';
import { IdModel } from '../../view-model/type/id-model';
import { IdRefComponent } from '../../components/form-components/id-ref/id-ref.component';
import { IdRefModel } from '../../view-model/type/id-ref.model';
import { DateUtilsService } from '../../utils/date-utils-service';
import { StateService } from '../state.service';
import { firstValueFrom } from 'rxjs';
import { ModelListenerService } from './model-listener.service';
import { ModelStateService } from './model-state.service';
import { ConceptModelService } from '../concept-model.service';
import { ConceptModel, ConceptNode } from '../../types/concept-node';
import { DynamicModelPropertyService } from './dynamic-model-property.service';
import readonlyFields from '../readonly-fields.json';
import { Severity, ValidationNotification } from '../../types/app-types';
import { ReadonlyService } from '../readonly.service';
import { FormTypeService } from '../form-type.service';
import { PreferredReleaseDateService } from './preferred-release-date.service';
import { OptionalRepeatableService } from './optional-repeatable.service';
import { LocalDate, LocalTime } from '@js-joda/core';
import { ConceptModelUtils } from '../parser/concept-model-utils';
import { Bt300BrDeKmuModel } from '../../view-model/type/bt300-br-de-kmu-model';
import { IndicatorModel } from '../../view-model/type/indicator-model';
import { IndicatorComponent } from '../../components/form-components/indicator/indicator.component';
import { EFormsMigrationService } from '../e-forms-migration.service';

@Injectable()
export class ModelGenerationService {
  // e.g. BT-05(x)-notice, x in [a-y] indicates group node with possible successor
  private static readonly IS_GROUP_NODE_WITH_POSSIBLE_SUCCESSOR = /^([A-Z]+-\d+\()([a-y])(\)-.+)$/;

  private static readonly BTS_FOR_DEADLINE_VALIDATION = [
    'BT-1311(d)-Lot', // participation deadline
    'BT-131(d)-Lot', // offer deadline
    'BT-132(d)-Lot', // offer opening deadline
  ];

  private componentModel: ComponentModel;

  constructor(
    private sdkService: SdkService,
    private fieldTypeService: FieldTypeService,
    private codelistModelService: CodelistModelService,
    private modelValidationService: ModelValidationService,
    private eVergabeMetaDataService: EVergabeMetaDataService,
    private eFormsMetaDataService: EFormsMetaDataService,
    private repeatableSectionService: RepeatableSectionService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private translationService: TranslationService,
    private syncedFieldService: SyncedFieldService,
    private schmemeIdService: SchemeIdService,
    private dateUtilsService: DateUtilsService,
    private stateService: StateService,
    private modelListenerService: ModelListenerService,
    private conceptModelService: ConceptModelService,
    private dynamicModelPropertyService: DynamicModelPropertyService,
    private readOnlyService: ReadonlyService,
    private formTypeService: FormTypeService,
    private preferredReleaseDateService: PreferredReleaseDateService,
    private optionalRepeatableService: OptionalRepeatableService,
    private migrationWarningService: EFormsMigrationService
  ) {}

  public async generateModel(
    noticeDefinition: NoticeDefinition,
    importedConceptModel?: ConceptModel
  ): Promise<ComponentModel> {
    this.componentModel = new ComponentModel();

    this.componentModel.noticeId = noticeDefinition.noticeId;
    this.componentModel.formType = await this.formTypeService.getFormType(
      noticeDefinition.noticeId
    );

    this.componentModel.activeLanguages = await firstValueFrom(this.stateService.getLanguages());

    const detailsSection = this.initDetailsSection();

    if (this.editorConfigurationProvider.isOBA()) {
      const metaDataGroup = await this.eVergabeMetaDataService.createEVergabeMetadata(
        detailsSection,
        this.componentModel
      );

      if (this.editorConfigurationProvider.isUpdateMetaData()) {
        metaDataGroup.isExpanded = true;
        return this.componentModel;
      }
    }

    await this.eFormsMetaDataService.createEFormsMetadata(
      detailsSection,
      noticeDefinition.metadata,
      this,
      this.componentModel,
      importedConceptModel
    );

    this.connectRelatedModels(this.eFormsMetaDataService.eFormsMetaData);

    await this.createSections(noticeDefinition.content, importedConceptModel?.root);

    await this.repeatableSectionService.createRepeatableSections(
      this.componentModel,
      noticeDefinition.content,
      this,
      importedConceptModel?.root
    );

    await this.postModelInit();
    this.modelValidationService.refreshNotifications(this.componentModel, false);
    return this.componentModel;
  }

  public async addElementToRepeatableField(
    noticeNode: NoticeNode,
    parent: RepeatableFieldModel<any>,
    value?: FieldValueType
  ) {
    const fieldModel = await this.addField(noticeNode, parent, value, false);

    parent.addChild(fieldModel);

    await this.updateDynamicProperties(fieldModel);

    return fieldModel;
  }

  public async addField(
    noticeNode: NoticeNode,
    parent: SectionModel | GroupModel | RepeatableFieldModel<any>,
    value?: FieldValueType,
    updateProperties = false
  ): Promise<FieldModel<any>> {
    const componentType = this.fieldTypeService.getComponentForNoticeNode(
      noticeNode,
      this.isInsideRepeatable(parent)
    );
    const fieldModel = this.getModelForComponentType(componentType, noticeNode);
    await this.configure(fieldModel, noticeNode, parent, value, updateProperties);

    return fieldModel;
  }

  public async addGroup(
    noticeNode: NoticeNode,
    parent: SectionModel | GroupModel | RepeatableGroupModel,
    importedConceptNode?: ConceptNode
  ): Promise<GroupModel> {
    const groupModel = new GroupModel();
    await this.configure(groupModel, noticeNode, parent);
    for (const subNoticeNode of noticeNode.content) {
      if (this.isGroup(subNoticeNode)) {
        if (subNoticeNode._repeatable) {
          groupModel.addChild(
            await this.addRepeatableGroup(subNoticeNode, groupModel, importedConceptNode)
          );
        } else {
          groupModel.addChild(await this.addGroup(subNoticeNode, groupModel, importedConceptNode));
        }
      }
      if (this.isField(subNoticeNode)) {
        if (subNoticeNode._repeatable) {
          groupModel.addChild(
            await this.addRepeatableField(subNoticeNode, groupModel, importedConceptNode)
          );
        } else {
          groupModel.addChild(
            await this.addField(
              subNoticeNode,
              groupModel,
              this.conceptModelService.findFieldValue(subNoticeNode, importedConceptNode)
            )
          );
        }
      }
    }

    this.connectRelatedModels(groupModel);
    return groupModel;
  }

  public async addRepeatableSection(
    noticeNode: NoticeNode,
    parent: RepeatableSectionModel,
    initialValue?: ConceptNode
  ): Promise<SectionModel> {
    const clonedNoticeNode = structuredClone(noticeNode);
    clonedNoticeNode._repeatable = false;
    const sectionModel = await this.addSection(noticeNode, parent, initialValue);
    parent.addChild(sectionModel);
    return sectionModel;
  }

  public async copyRepeatableSection(
    noticeNode: NoticeNode,
    parent: RepeatableSectionModel,
    sectionSchemeId: string
  ): Promise<SectionModel> {
    const sectionValuesToCopy = await this.getRepeatableSectionValues(
      noticeNode._identifierFieldId,
      sectionSchemeId
    );

    const newSection = await this.addSection(noticeNode, parent, sectionValuesToCopy);
    parent.addChild(newSection);

    return newSection;
  }

  public removeRepeatableSection(sectionModel: SectionModel) {
    if (sectionModel.parent instanceof RepeatableSectionModel) {
      sectionModel.parent.removeChild(sectionModel);
    }
    this.modelValidationService.refreshNotifications(sectionModel.root, true);
  }

  public async addRepeatableField<T extends FieldValueType>(
    noticeNode: NoticeNode,
    parent: SectionModel | GroupModel,
    importedConceptNode?: ConceptNode
  ): Promise<FieldModel<T>> {
    const initialValueNodes = [];
    this.conceptModelService.findRepeatableFieldNode(
      noticeNode,
      importedConceptNode,
      initialValueNodes
    );
    const fieldModel = new RepeatableFieldModel<T>();
    await this.configure(fieldModel, noticeNode, parent);

    if (initialValueNodes.length === 0) {
      const clonedNoticeNode = structuredClone(noticeNode);
      clonedNoticeNode._repeatable = false;
      fieldModel.addChild(await this.addField(clonedNoticeNode, fieldModel));
    } else {
      for (const fieldNode of initialValueNodes) {
        const clonedNoticeNode = structuredClone(noticeNode);
        clonedNoticeNode._repeatable = false;
        fieldModel.addChild(await this.addField(clonedNoticeNode, fieldModel, fieldNode.value));
      }
    }

    return fieldModel;
  }

  public async configure(
    model: ModelWithParent,
    noticeNode: NoticeNode,
    parent: ModelWithChildren,
    value?: FieldValueType,
    updateProperties = false
  ): Promise<void> {
    model.parent = parent;
    model.noticeNode = noticeNode;
    model.fieldInfo = this.sdkService.getFieldInfo(noticeNode.id);
    model.root = this.componentModel;
    if (parent instanceof ComponentModel) {
      model.depth = 1;
    } else if (parent instanceof RepeatableGroupModel || parent instanceof RepeatableSectionModel) {
      model.depth = parent.depth;
    } else {
      model.depth = parent.depth + 1;
    }
    model.translatedLabel = this.translationService.translate(noticeNode._label);
    model.description = this.translationService.translateIfAvailable(
      `field|description|${model.noticeNode.id}`
    );
    model.tooltips = this.translationService.getTooltips(noticeNode);

    // Lot / Part root reference
    if (model instanceof SectionModel && parent instanceof SectionModel) {
      model.rootRepeatableSectionModel = parent.rootRepeatableSectionModel;
    }

    await model.loadSdkProperties();

    this.initValue(value, model);

    this.presetValue(noticeNode, model);

    model.isInsideRepeatable = this.isInsideRepeatable(parent);

    if (model.isReadonly === undefined) {
      model.isReadonly = this.readOnlyService.isModelReadOnly(model);
    }

    if (noticeNode._repeatable) {
      model.repeatable = true;
    }

    if (model instanceof DateModel) {
      this.setSelectableMinDate(model);
    }

    if (model instanceof FieldModel) {
      this.addPlaceholder(model);

      // Checks if the model is a synced model and sync it up to the current value
      this.syncedFieldService.configureNewModel(model);
      model.modelChangeListener = this.modelListenerService.getChangeListenerForModel(model);
    }

    if (model instanceof IdModel) {
      await this.schmemeIdService.createIndicator(model);
    }

    if (updateProperties && model instanceof FieldModel) {
      await this.updateDynamicProperties(model);
    } else {
      model.isHidden = !!model.noticeNode?.hidden;
    }

    if (
      this.editorConfigurationProvider.isMigrationToEForms() &&
      (
        this.editorConfigurationProvider.isChangeNotice() ||
        // ReSy: Pruefung auf AlterUpdateBeforeRelease
        this.editorConfigurationProvider.isAlterUpdateBeforeRelease()
      )
    ) {
      this.setWarningsForMigration(model);
    }
  }

  public findConceptNodesForRepeatableGroup(
    noticeNode: NoticeNode,
    importedConceptNode: ConceptNode,
    matched: ConceptNode[]
  ) {
    if (importedConceptNode?.businessTerm === noticeNode.nodeId) {
      matched.push(importedConceptNode);
    }
    if (!importedConceptNode?.children) {
      return;
    }
    for (const child of importedConceptNode.children) {
      this.findConceptNodesForRepeatableGroup(noticeNode, child, matched);
    }
  }

  /**
   * Initialises a Subtree after a Repeatable Element has been added.
   */
  public async initSubtree(subtree: BaseModel) {
    const conceptModel = await this.conceptModelService.generateConceptModel(this.componentModel);
    this.forEachFieldModel(subtree, conceptModel, this.fieldModelPostInit.bind(this));
    const dependencySet = new Set<string>();
    this.dynamicModelPropertyService.collectIdsInSubtree(subtree, dependencySet);
    await this.dynamicModelPropertyService.updateDependenciesOfFieldIds(
      dependencySet,
      subtree.root
    );
  }

  private async updateDynamicProperties(model: FieldModel<any>) {
    const conceptModel = await this.conceptModelService.generateConceptModel(this.componentModel);
    await this.dynamicModelPropertyService.updateProperties(model, conceptModel);
  }

  private setSelectableMinDate(model: DateModel) {
    if (ModelGenerationService.BTS_FOR_DEADLINE_VALIDATION.includes(model.noticeNode.id)) {
      model.minDate = this.editorConfigurationProvider.isTrainingMode()
        ? this.dateUtilsService.getToday()
        : this.dateUtilsService.getLocalDateOfTomorrow();
    } else if (model.noticeNode.id === 'BT-738-notice') {
      // preferred publication date
      model.minDate = this.dateUtilsService.getToday();
    } else {
      model.minDate = LocalDate.of(1970,1,1);
    }
  }

  private initValue(
    value: string | boolean | number | Amount | Measure | Multilingual | LocalisedText,
    model:
      | SectionModel
      | GroupModel
      | FieldModel<any>
      | RepeatableGroupModel
      | RepeatableSectionModel
  ) {
    if (value != null) {
      if (model instanceof MultilingualModel) {
        const multilingualValue = value as Multilingual;
        if (multilingualValue.DEU || multilingualValue.ENG) {
          model.value = value as Multilingual;
          model.englishValue = multilingualValue.ENG;
          model.germanValue = multilingualValue.DEU;
          model.touched = true;
        }
      } else if (model instanceof TimeModel) {
        model.value = this.dateUtilsService.toLocalIsoTimeFormatTruncToSec(
          LocalTime.parse(value as string)
        );
        model.touched = true;
      } else if (model instanceof IndicatorModel) {
        if (typeof value === 'boolean') {
          model.value = value;
        } else if (value === 'true') {
          model.value = true;
        } else if (value === 'false') {
          model.value = false;
        }
      } else if (model instanceof FieldModel) {
        model.value = value;
        model.touched = true;
      }
      if (model instanceof Bt300BrDeKmuModel) {
        this.extractKmuValueForBT300(model, value as Multilingual);
      }
      (model as FieldModel<any>).initialValue = window.structuredClone(value);
      if (model instanceof CodelistModel) {
        if (!model.codeList.some(item => item.id === model.value)) {
          model.value = null;
        }
      }
    }

    if (
      model.noticeNode?.id === 'BT-738-notice' &&
      this.preferredReleaseDateService.isFixedToToday(model.root.noticeId)
    ) {
      (model as DateModel).value = this.dateUtilsService.toLocalIsoDateFormat(
        this.dateUtilsService.getToday()
      );
    }

    /**
     * Beim Öffnen der Erfassungsmaske werden die Felder BT-05(a)-notice und BT-05(b)-notice auf "heute" gesetzt. Fachlich ist dies das Datum der Übermittlung der Bekanntmachung.
     * Damit wird die Kompatibilität mit dem VD gewährleistet (BT-05 muss immer "heute" sein), falls es sich um einen in der Vergangenheit angelegten
     * Entwurf handelt, der erst jetzt abgesendet wird.
     */
    if (model.noticeNode?.id?.includes('BT-05(a)')) {
      const dateModel = model as DateModel;
      dateModel.value = this.dateUtilsService.toLocalIsoDateFormat(
        this.dateUtilsService.getToday()
      );
    }
    if (model.noticeNode?.id?.includes('BT-05(b)')) {
      const timeModel = model as FieldModel<any>;
      timeModel.value = '00:00:00';
    }

    /**
     * Don't set Document-URLs, if we're creating an initial notice. This could otherwise lead to Document-URLs of prior exported Notices being imported to a new one.
     */
    if (
      ['BT-15-Lot', 'BT-615-Lot', 'BT-15-Part', 'BT-615-Part'].includes(model.noticeNode?.id) &&
      this.editorConfigurationProvider.isInitialNoticeWithoutContractFolderOrProcedureId() &&
      this.editorConfigurationProvider.isEVergabePlatform()
    ) {
      const fieldModel = model as FieldModel<any>;
      fieldModel.value = null;
    }
  }

  private presetValue(
    noticeNode: NoticeNode,
    model:
      | SectionModel
      | GroupModel
      | FieldModel<any>
      | RepeatableGroupModel
      | RepeatableSectionModel
  ) {
    if (
      ((model as FieldModel<any>).value !== null &&
        (model as FieldModel<any>).value !== undefined) ||
      !(model instanceof FieldModel)
    ) {
      return;
    }

    if (
      this.editorConfigurationProvider.isCancellation() &&
      noticeNode.id.startsWith('BT-142-LotResult')
    ) {
      model.value = 'clos-nw';
    }

    if (
      this.editorConfigurationProvider.isWithoutPublication() &&
      model.noticeNode.id === 'BT-105-Procedure'
    ) {
      // The procedure type is fixed when procedure without publishing
      model.value = 'neg-wo-call';
    }

    if (noticeNode.presetValue !== undefined) {
      model.value = noticeNode.presetValue;
    // ReSy: in der eVergabe wird auf "{NOW}" geprueft -> Refactoring im Preprocessing von _presetValue auf presetValue noetig, um diese Unterscheidung loszuwerden
    } else if (noticeNode._presetValue !== undefined) {
      model.value = noticeNode._presetValue;
    }

    if (model.value === '{NOW}') {
      if (model instanceof DateModel) {
        model.value = this.dateUtilsService.toLocalIsoDateFormat(this.dateUtilsService.getToday());
      } else if (model instanceof TimeModel) {
        model.value = this.dateUtilsService.toLocalIsoTimeFormatTruncToSec(
          this.dateUtilsService.getLocalTime()
        );
      }
    }
  }

  /**
   * Parses the BR-DE-26 Rule for specifying the actual type of KMU the Lot applies to.
   * Will extract multiple KMU Types (separated by comma) of the string '#Besonders auch geeignet für:<TYPE>#' or '#Besonders geeignet für:<TYPE>#' and puts it into the Field-Specific Model.
   * Will put the whole text after KMU Type(s) as the model's german or english value.
   *
   * @param model of the Field BT-300-Lot/BT-300-Part
   * @param rawInputValue of the corresponding conceptNode
   */
  private extractKmuValueForBT300(model: Bt300BrDeKmuModel, rawInputValue: Multilingual) {
    const kmuRegExp = /(#Besonders(?: auch)? geeignet für:([a-z-]+)#),?/g;

    if (rawInputValue.ENG) {
      model.kmuValues = this.parseKmuValues(rawInputValue.ENG, kmuRegExp);
      model.englishValue = this.parseRemainingValue(rawInputValue.ENG, kmuRegExp);
    }

    if (rawInputValue.DEU) {
      model.kmuValues = this.parseKmuValues(rawInputValue.DEU, kmuRegExp);
      model.germanValue = this.parseRemainingValue(rawInputValue.DEU, kmuRegExp);
    }
  }

  /**
   * Example:
   *  input: '#Besonders auch geeignet für:selbst#,#Besonders auch geeignet für:freelance#,#Besonders auch geeignet für:other-sme#Das ist ein\nFreitext'
   *  output: ['selbst','freelance','other-sme']
   *
   * @param value
   * @param regex
   */
  private parseKmuValues(value: string, regex: RegExp): string[] {
    const matches = Array.from(value.matchAll(regex));
    return matches.length > 0 ? matches.map(match => match[2]) : [];
  }

  /**
   * Example:
   *  input: '#Besonders auch geeignet für:selbst#,#Besonders auch geeignet für:freelance#,#Besonders auch geeignet für:other-sme#\nDas ist ein\nFreitext'
   *  output: 'Das ist ein\nFreitext'
   *
   * @param value
   * @param regex
   */
  private parseRemainingValue(value: string, regex: RegExp): string {
    const remainingValue = value.replace(regex, '');
    return remainingValue.startsWith('\n')
      ? remainingValue.substring(1).trim()
      : remainingValue.trim();
  }

  private initDetailsSection(): SectionModel {
    const detailsSection = new SectionModel();
    detailsSection.rootRepeatableSectionModel = detailsSection;
    detailsSection.translatedLabel = 'Details';
    detailsSection.root = this.componentModel;
    detailsSection.parent = this.componentModel;
    detailsSection.depth = 1;
    this.componentModel.children.push(detailsSection);

    detailsSection.noticeNode = {
      id: DETAILS_ID,
      contentType: ContentType.GROUP,
      displayType: DisplayType.SECTION,
      description: '',
      _label: `field|name|${DETAILS_ID}`,
      content: [
        {
          id: EFORMS_METADATA_ID,
          contentType: ContentType.GROUP,
          displayType: DisplayType.GROUP,
          description: '',
          _label: `field|name|${EFORMS_METADATA_ID}`,
        },
      ],
    };

    return detailsSection;
  }

  private async createSections(
    content: NoticeNode[],
    importedConceptNode?: ConceptNode
  ): Promise<void> {
    for (const noticeNode of content.filter(node => !node._repeatable)) {
      this.componentModel.children.push(
        await this.addSection(noticeNode, this.componentModel, importedConceptNode)
      );
    }
  }

  private async addSection(
    noticeNode: NoticeNode,
    parent: SectionModel | RepeatableSectionModel | ComponentModel,
    importedConceptNode?: ConceptNode
  ): Promise<SectionModel> {
    const sectionModel = new SectionModel();
    await this.configure(sectionModel, noticeNode, parent);
    for (const subNoticeNode of noticeNode.content) {
      if (this.isSection(subNoticeNode)) {
        sectionModel.addChild(
          await this.addSection(subNoticeNode, sectionModel, importedConceptNode)
        );
      }

      if (this.isGroup(subNoticeNode)) {
        if (subNoticeNode._repeatable) {
          sectionModel.addChild(
            await this.addRepeatableGroup(subNoticeNode, sectionModel, importedConceptNode)
          );
        } else {
          sectionModel.addChild(
            await this.addGroup(subNoticeNode, sectionModel, importedConceptNode)
          );
        }
      }
      if (this.isField(subNoticeNode)) {
        if (subNoticeNode._repeatable) {
          sectionModel.addChild(
            await this.addRepeatableField(subNoticeNode, sectionModel, importedConceptNode)
          );
        } else {
          sectionModel.addChild(
            await this.addField(
              subNoticeNode,
              sectionModel,
              this.conceptModelService.findFieldValue(subNoticeNode, importedConceptNode)
            )
          );
        }
      }
    }
    this.connectRelatedModels(sectionModel);

    return sectionModel;
  }

  private async addRepeatableGroup(
    noticeNode: NoticeNode,
    parent: SectionModel | GroupModel,
    importedConceptNode?: ConceptNode
  ): Promise<RepeatableGroupModel> {
    const importedRepeatableNodes = [];
    this.findConceptNodesForRepeatableGroup(
      noticeNode,
      importedConceptNode,
      importedRepeatableNodes
    );
    const groupModel = new RepeatableGroupModel();
    await this.configure(groupModel, noticeNode, parent);

    if (
      importedRepeatableNodes.length === 0 &&
      !this.optionalRepeatableService.isRepeatableOptional(
        noticeNode.id,
        groupModel.root.formType,
        groupModel.root.noticeId
      )
    ) {
      const clonedNoticeNode = structuredClone(noticeNode);
      clonedNoticeNode._repeatable = false;
      groupModel.addChild(await this.addGroup(clonedNoticeNode, groupModel));
    } else {
      for (const conceptNode of importedRepeatableNodes) {
        const clonedNoticeNode = structuredClone(noticeNode);
        clonedNoticeNode._repeatable = false;
        groupModel.addChild(await this.addGroup(clonedNoticeNode, groupModel, conceptNode));
      }
    }

    return groupModel;
  }

  private getModelForComponentType(componentType: any, noticeNode: NoticeNode): FieldModel<any> {
    const customComponent = this.getCustomFieldComponentModel(noticeNode);
    if (customComponent) {
      return customComponent;
    }

    switch (componentType) {
      case CodelistComponent:
        return new CodelistModel(this.codelistModelService);
      case AmountComponent:
        return new AmountModel(this.sdkService);
      case MeasureComponent:
        return new MeasureModel(this.sdkService);
      case DateComponent:
        return new DateModel();
      case TimeComponent:
        return new TimeModel();
      case MultiLingualComponent:
        return new MultilingualModel();
      case IdComponent:
        if (noticeNode._idScheme) {
          return new IdModel();
        } else {
          return new FieldModel<string>();
        }
      case IdRefComponent:
        return new IdRefModel();
      case IndicatorComponent:
        return new IndicatorModel();
      default:
        return new FieldModel();
    }
  }

  /**
   * Creates Field-Specific Models, which are not defined by the SDK, but custom implementation based on customer needs.
   */
  private getCustomFieldComponentModel(noticeNode: NoticeNode): FieldModel<any> {
    if (noticeNode?.id === 'BT-300-Lot' || noticeNode?.id === 'BT-300-Part') {
      return new Bt300BrDeKmuModel();
    }

    if (noticeNode?.id === 'OPP-080-Tender') {
      return new OPP080Model(this.sdkService);
    }

    return null;
  }

  /**
   * Connects related Time and Date Models inside a GroupModel to each other.
   */
  private connectRelatedModels(groupModel: GroupModel | SectionModel) {
    groupModel.children
      .filter(model => model instanceof DateModel)
      .forEach((dateModel: DateModel) => {
        const match = dateModel.noticeNode.id.match(
          ModelGenerationService.IS_GROUP_NODE_WITH_POSSIBLE_SUCCESSOR
        );

        if (match) {
          const groupCharForRelatedTimeNode = this.determineGroupCharForRelatedTimeNode(match);

          const relatedTimeComponentId = dateModel.noticeNode.id.replace(
            ModelGenerationService.IS_GROUP_NODE_WITH_POSSIBLE_SUCCESSOR,
            `$1${groupCharForRelatedTimeNode}$3`
          );

          const timeModel = groupModel.children.find(
            submodel => submodel.noticeNode.id === relatedTimeComponentId
          ) as TimeModel;
          if (timeModel instanceof TimeModel) {
            timeModel.relatedDateModel = dateModel;
            dateModel.relatedTimeModel = timeModel;
          }
        }
      });
  }

  /*
   * In case of Lot/Part-Nodes the DateNodeId has "d" as groupChar and the related TimeNodeId "t"
   * e.g. DateNodeId: BT-13(d)-Lot -> TimeNodeId: BT-13(t)-Lot
   * in other cases we assume that related TimeNode, if present follows alphabetically on DateNode
   * e.g. DateNodeId: BT-05(a)-notice -> TimeNodeId: BT-05(b)-notice
   */

  private determineGroupCharForRelatedTimeNode(match: RegExpMatchArray): string {
    return match[2] === 'd' ? 't' : String.fromCharCode(match[2].charCodeAt(0) + 1);
  }

  private isSection(noticeNode: NoticeNode): boolean {
    return noticeNode.displayType === DisplayType.SECTION;
  }

  private isGroup(noticeNode: NoticeNode): boolean {
    return noticeNode.displayType === DisplayType.GROUP;
  }

  private isField(noticeNode: NoticeNode): boolean {
    return !this.isGroup(noticeNode) && !this.isSection(noticeNode);
  }

  private isInsideRepeatable(parent: ModelWithChildren) {
    return (
      parent instanceof RepeatableGroupModel ||
      parent instanceof RepeatableFieldModel ||
      parent instanceof RepeatableSectionModel
    );
  }

  /**
   * This will get called, after the whole model tree has been build. Some properties need to be set, by executing EFX, which will need a ConceptModel of the whole formular.
   */
  private async postModelInit() {
    const conceptModel = await this.conceptModelService.generateConceptModel(this.componentModel);
    this.forEachFieldModel(this.componentModel, conceptModel, this.fieldModelPostInit.bind(this));
    this.forEachFieldModel(
      this.componentModel,
      conceptModel,
      (model, _) => (model.valueAfterModelInit = structuredClone(model.value))
    );

    await this.migrationWarningService.loadMigrationWarnings(this.componentModel);
  }

  private fieldModelPostInit(model: FieldModel<any>, conceptModel: ConceptModel) {
    this.dynamicModelPropertyService.updateProperties(model, conceptModel);

    // Notify Model Listener about loaded values
    if (model.value !== undefined && model.value !== null) {
      this.modelListenerService.onModelValueLoaded(model);
    }
  }

  /**
   * Executes the given function for each FieldModel in the ComponentModel.
   *
   * @param model current subtree of the ConceptModel
   * @param conceptModel of the current componentModel
   * @param modelFn function to execute
   */
  private forEachFieldModel(
    model: BaseModel | ComponentModel,
    conceptModel: ConceptModel,
    modelFn: (model: FieldModel<any>, conceptModel: ConceptModel) => void
  ) {
    const hasChildren = ModelStateService.hasChildren(model);
    if (hasChildren) {
      (model as ModelWithChildren).children.forEach(child =>
        this.forEachFieldModel(child, conceptModel, modelFn)
      );
    } else {
      modelFn(model as FieldModel<any>, conceptModel);
    }
  }

  private setWarningsForMigration(model: BaseModel) {
    const migrationWarning =
      'Zur gültigen Migration muss dieser Wert exakt der zu migrierenden Bekanntmachung entsprechen.';
    if (
      readonlyFields.changeNoticeFields.find(value => model.noticeNode.id.startsWith(value)) &&
      !model.isReadonly
    ) {
      if (model instanceof MultilingualModel) {
        model.root.activeLanguages.forEach(language =>
          model.fieldNotifications.push(
            new ValidationNotification(migrationWarning, Severity.WARNING, language)
          )
        );
      } else {
        model.fieldNotifications.push(
          new ValidationNotification(migrationWarning, Severity.WARNING)
        );
      }
    }
  }

  private addPlaceholder(model: FieldModel<any>) {
    const fieldInfo = this.sdkService.getFieldInfo(model.noticeNode.id);
    if (fieldInfo.placeholder) {
      model.placeholder = fieldInfo.placeholder;
    }
  }

  private async getRepeatableSectionValues(identifierFieldId: string, sectionSchemeId: string) {
    const conceptModel = await this.conceptModelService.generateConceptModel(this.componentModel);

    const identifierNodeOfSection = ConceptModelUtils.findChildWithValue(
      conceptModel.root,
      identifierFieldId,
      sectionSchemeId
    );
    identifierNodeOfSection.value = null; // set to null, so that a new identifier gets used in the section creation process

    return identifierNodeOfSection.parent;
  }
}
