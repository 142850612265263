import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { GenericDialogService } from '../../services/ui/generic-dialog.service';
import { AuthErrorDialogService } from './auth-error-dialog/auth-error-dialog.service';
import { ErrorDialogService } from './error-dialog/error-dialog.service';
import { ErrorType } from './error-type.enum';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorDialogService: ErrorDialogService,
    private genericDialogService: GenericDialogService,
    private router: Router,
    private authErrorDialogService: AuthErrorDialogService,
    private keycloak: KeycloakService,
  ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (
            this.isXsdValidationError(err) ||
            this.isSchematronValidationError(err) ||
            this.isSendToVediError(err)
          ) {
            this.errorDialogService.showErrorDetailsDialog(err.error, 'Bearbeitung fortsetzen');
          } else if (
            this.isUserNotFoundError(err) ||
            this.isUserAlreadyInGroupError(err) ||
            this.isNoticeLockedError(err)
          ) {
            // keine Sonderbehandlung, da die Fehler ohne Modal direkt am Input angezeigt werden
          } else if (err.status === 403) {
            // alle 403-Fehler
            return from(this.genericDialogService.showResultDialog('Zugriff verweigert', 'Der Zugriff wurde aufgrund einer fehlenden Berechtigung verweigert.', 'Zur Startseite', false)).pipe(
              tap(() => this.router.navigate(['home'])),
              switchMap(() => throwError(() => err))
            );
          } else if (err.status === 401) {
            if (!this.authErrorDialogService.openDialog) {
              // Wird aufgerufen, falls bei ein Request mit 401 beantwortet wird und der "Sitzung erneuern"-Dialog noch nicht geöffnet ist.
              // `clearToken` fuehrt dann zu einem `OnAuthLogout` event von keycloak, das den "Sitzung erneuern"-Dialog oeffnet.
              this.keycloak.clearToken();
            }
          } else {
            this.errorDialogService.showErrorDetailsDialog({
              errorType: `${err.status} ${err.statusText}`,
              errorText:
                typeof err.error === 'string' && err.error !== '' ? err.error : err.message,
            });
          }
        } else {
          this.errorDialogService.showErrorDetailsDialog(err.message);
        }

        return throwError(() => err);
      })
    );
  }

  private isXsdValidationError(err: HttpErrorResponse): boolean {
    return err.status === 400 && err.error?.errorType === ErrorType.XSD_VALIDATION;
  }

  private isSchematronValidationError(err: HttpErrorResponse): boolean {
    return err.status === 400 && err.error?.errorType === ErrorType.SCHEMATRON_VALIDATION;
  }

  private isSendToVediError(err: HttpErrorResponse): boolean {
    return err.status === 500 && err.error?.errorType === ErrorType.SEND_TO_VEDI;
  }

  private isUserNotFoundError(err: HttpErrorResponse): boolean {
    return err.status === 400 && err.error?.errorType === ErrorType.USER_NOT_FOUND;
  }

  private isUserAlreadyInGroupError(err: HttpErrorResponse): boolean {
    return err.status === 400 && err.error?.errorType === ErrorType.USER_ALREADY_IN_GROUP;
  }

  private isNoticeLockedError(err: HttpErrorResponse): boolean {
    return err.status === 423 && err.error?.errorType === ErrorType.NOTICE_LOCKED;
  }
}
