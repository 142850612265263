import { Project } from '../types/project';
import { LocalDate, LocalDateTime } from '@js-joda/core';
import { LotIdChange } from '../types/lot-id-change';
export class EVergabeMetaData {
  project: Project;
  categoryId: string;
  provisionalOfferDeadline: LocalDateTime;
  archiveDate: LocalDate;
  freelanceService: boolean;
  offerType: OfferType;
  lotIdChanges: LotIdChange[];

  constructor(
    project: Project,
    categoryId: string | number,
    archiveDate: LocalDate,
    freelanceService: boolean,
    provisionalOfferDeadline: LocalDateTime,
    offerType: OfferType,
    lotIdChanges: LotIdChange[]
  ) {
    this.project = project;
    this.categoryId = typeof categoryId === 'number' ? `${categoryId}` : categoryId;
    this.archiveDate = archiveDate;
    this.freelanceService = freelanceService;
    this.provisionalOfferDeadline = provisionalOfferDeadline;
    this.offerType = offerType;
    this.lotIdChanges = lotIdChanges;
  }
}

export enum OfferType {
  PROCEDURE = 'PROCEDURE',
  LOT = 'LOT',
}
