import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule as EuiCoreModule, EUI_CONFIG_TOKEN, translateConfig } from '@eui/core';

import { appConfig } from '../../config';
import { environment } from '../../environments/environment';
import { SharedModule } from './shared.module';

@NgModule({
  imports: [SharedModule, EuiCoreModule.forRoot(), TranslateModule.forRoot(translateConfig)],
  declarations: [],
  exports: [SharedModule],
  providers: [
    {
      provide: EUI_CONFIG_TOKEN,
      useValue: { appConfig, environment },
    },
  ],
})
export class CoreModule {}
