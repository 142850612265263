<div class="form-component-input-group" [hidden]="model.isHidden">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="datepicker-wrapper">
    <div class="datepicker-input-wrapper">
      <input
        [id]="htmlId"
        [formControl]="inputControl"
        [matDatepicker]="matDatePicker"
        [min]="model.minDate"
        (blur)="onInputValueChanged()"
        (dateChange)="onInputValueChanged()"
        [matDatepickerFilter]="datepickerFilter"
        class="datepicker-input-field"
        [class.has-error]="hasInputError()"
        [class.required]="model.isMandatory && !model.isReadonly"
        [attr.aria-label]="model.translatedLabel"
        placeholder="{{ model.isReadonly ? ' ' : 'TT/MM/JJJJ' }}"
        autoComplete="off" />
      <button
        mat-icon-button
        matIconSuffix
        class="input-clearable-icon"
        [disabled]="model.isReadonly"
        *ngIf="inputControl.value"
        (click)="clearInput()"
        aria-label="Eingabe löschen">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-datepicker-toggle
      [tabIndex]="0"
      matIconSuffix
      [disabled]="model.isReadonly"
      [for]="matDatePicker"></mat-datepicker-toggle>
    <mat-datepicker [dateClass]="dateClass" #matDatePicker (opened)="onOpen()"></mat-datepicker>
    <div style="display: none">
      <div class="datepicker-footer" #datePickerFooter>
        <button
          class="eForms-button"
          tabindex="0"
          aria-label="Heute auswählen"
          (click)="setToday()">
          {{ todayBtnLabel }}
        </button>
      </div>
    </div>
    <app-time
      *ngIf="model.relatedTimeModel"
      [model]="model.relatedTimeModel"
      [isChildInDateComponent]="true"></app-time>
  </div>
  <app-validation-message
    [model]="model"
    [secondaryModel]="this.model.relatedTimeModel"></app-validation-message>
</div>
