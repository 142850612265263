import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { memoizeAsync } from 'utils-decorators/dist/cjs';
import { Category } from '../types/category';
import { DisplayMode } from '../types/display-mode';
import { Project } from '../types/project';
import { MaintenanceWindow, TenderingProcedureResponseDto } from '../types/app-types';
import { map } from 'rxjs/operators';
import { ConceptModelUtils } from './parser/concept-model-utils';
import { ConceptModel } from '../types/concept-node';
import { Contact } from '../types/contact';
import { EVergabeMetaData } from '../view-model/e-vergabe-meta-data';
import { DateTimeFormatter, LocalDate, LocalDateTime } from '@js-joda/core';
import { SelectionCriterion } from '../types/selection-criterion';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(private http: HttpClient) {}

  public async loadContacts(): Promise<{ contacts: Contact[] }> {
    return firstValueFrom(this.http.get<{ contacts: Contact[] }>(`api/load-contacts`));
  }

  public async saveContacts(contacts: Contact[]): Promise<void> {
    return firstValueFrom(
      this.http.post<any>(
        `api/save-contacts`,
        { contacts },
        {
          headers: { 'content-type': 'application/json' },
        }
      )
    );
  }

  public async openPreview(lang: string, conceptModel: ConceptModel): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`api/notice/preview/${lang}`, ConceptModelUtils.serialize(conceptModel), {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async createProcedure(
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData
  ): Promise<TenderingProcedureResponseDto> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.post<TenderingProcedureResponseDto>(`api/procedures`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async isInternalReferenceAvailable(reference: string): Promise<boolean> {
    return firstValueFrom(
      this.http.get<boolean>(
        `api/validation/reference-available?reference=${encodeURIComponent(reference)}`
      )
    );
  }

  public async getInvalidCharacterInReferenceNumber(reference: string): Promise<string[]> {
    return firstValueFrom(
      this.http.get<string[]>(
        `api/validation/invalid-characters?reference=${encodeURIComponent(reference)}`
      )
    );
  }

  /**
   * Retrieves the OfferDeadline of the procedure, or if there are negotiation rounds,
   * the offer deadline of the last negotiation round.
   *
   * @param procedureId to retrieve the deadline from
   */
  public async getLatestOfferDeadline(procedureId: string): Promise<LocalDateTime> {
    const response = await firstValueFrom(
      this.http.get(`api/validation/${procedureId}/offer-deadline`, {
        responseType: 'text',
      })
    );

    return LocalDate.parse(response).atStartOfDay();
  }

  @memoizeAsync()
  public async getEVergabeMetaData(): Promise<EVergabeMetaData> {
    const metadata = await firstValueFrom(this.http.get<any>(`api/procedure/metadata`));
    const allProjects = await this.getProjects();
    const project = allProjects.find(p => p.id === metadata.projectId);
    const archiveDate = metadata.archiveDate ? LocalDate.parse(metadata.archiveDate) : null;
    const provisionalOfferDeadline = metadata.provisionalOfferDeadline
      ? LocalDateTime.parse(metadata.provisionalOfferDeadline)
      : null;
    return new EVergabeMetaData(
      project,
      metadata.categoryId,
      archiveDate,
      metadata.freelanceService,
      provisionalOfferDeadline,
      metadata.offerType,
      []
    );
  }

  public async updateMetaData(
    procedureId: number,
    eVergabeMetaData: EVergabeMetaData
  ): Promise<number> {
    return firstValueFrom(
      this.http.put<any>(`api/procedure/${procedureId}/metadata`, eVergabeMetaData, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async createChangeNotice(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData
  ): Promise<any> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.put<any>(`api/procedure/${procedureId}/notice`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async createResetProcedureNotice(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData
  ): Promise<any> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.put<any>(`api/procedure/${procedureId}/reset`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async createExAnte(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData
  ): Promise<any> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.post<any>(`api/procedure/${procedureId}/ex-ante`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async createChangeExAnte(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData
  ): Promise<any> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.put<any>(`api/procedure/${procedureId}/ex-ante`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async createExPost(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData,
    cancellation: boolean
  ): Promise<any> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.post<any>(
        `api/procedure/${procedureId}/ex-post?cancellation=${cancellation}`,
        container,
        {
          headers: { 'content-type': 'application/json' },
        }
      )
    );
  }

  public async createChangeExPost(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData
  ): Promise<boolean> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return await firstValueFrom(
      this.http.put<any>(`api/procedure/${procedureId}/ex-post`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async releasePriorInformation(procedureId: number): Promise<boolean> {
    return await firstValueFrom(
      this.http.post<any>(`api/procedure/${procedureId}/release`, null, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async saveDraft(
    procedureId: number,
    conceptModel: ConceptModel,
    eVergabeMetadata: EVergabeMetaData,
    isCancellation: boolean
  ): Promise<any> {
    const container = {
      metadata: eVergabeMetadata,
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.put<any>(`api/draft/${procedureId}?isCancellation=${isCancellation}`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async exportModel(conceptModel: ConceptModel): Promise<{ exported: boolean }> {
    const container = {
      metadata: {},
      content: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
    };

    return firstValueFrom(
      this.http.put<any>(`api/export`, container, {
        headers: { 'content-type': 'application/json' },
      })
    );
  }

  public async getProjects(): Promise<Project[]> {
    const projects = await firstValueFrom(this.http.get<Project[]>(`api/projects`));
    if (!Array.isArray(projects)) {
      throw new Error(
        `Could not load Projects from Server! Result is not a Collection:${JSON.stringify(
          projects
        )}`
      );
    }
    return projects;
  }

  @memoizeAsync()
  public async getCategories(): Promise<Category[]> {
    return firstValueFrom(this.http.get<Category[]>(`api/categories`));
  }

  @memoizeAsync()
  public async getMaintenanceWindows(): Promise<MaintenanceWindow[]> {
    return firstValueFrom(
      this.http
        .get<any[]>(`api/maintenance-windows`)
        .pipe(map(response => response.map(this.createMaintenanceWindow)))
    );
  }

  /**
   * Signal the OBA to close the Host Frame. This will also start the OBA replication.
   */
  public async closeAndReplicate(procedureId: number) {
    return firstValueFrom(this.http.get<any>(`api/form-completion/complete/${procedureId}`));
  }

  /**
   * Signal the OBA to close the Host Frame. This will NOT start the OBA replication .
   */
  public async cancelAndClose() {
    return firstValueFrom(this.http.get<any>(`api/form-completion/cancel`));
  }

  public async loadData(noticeId: string, tenderingProcedureId?: number): Promise<ConceptModel> {
    const options = {
      headers: { id: noticeId },
    };

    return firstValueFrom(
      this.http.get<ConceptModel>(
        `api/form-value${
          tenderingProcedureId ? `?tenderingProcedureId=${tenderingProcedureId}` : ''
        }`,
        options
      )
    );
  }

  // ReSy: Endpunkt zum Laden des Displaymodus
  public async getDisplayMode(): Promise<DisplayMode> {
    return firstValueFrom(this.http.get<DisplayMode>(`api/display-mode`));
  }

  public async loadCriteria(): Promise<{ criteria: SelectionCriterion[] }> {
    return firstValueFrom(
      this.http.get<{ criteria: SelectionCriterion[] }>(`api/selection-criteria`)
    );
  }
  public async saveCriteria(criteria: SelectionCriterion[]): Promise<void> {
    return firstValueFrom(
      this.http.post<any>(
        `api/selection-criteria`,
        { criteria },
        {
          headers: { 'content-type': 'application/json' },
        }
      )
    );
  }
  private createMaintenanceWindow = (mw: any): MaintenanceWindow => ({
    description: mw.description,
    englishDescription: mw.englishDescription,
    durationFrom: LocalDateTime.parse(mw.durationFrom, DateTimeFormatter.ISO_LOCAL_DATE_TIME),
    durationTill: LocalDateTime.parse(mw.durationTill, DateTimeFormatter.ISO_LOCAL_DATE_TIME),
  });
}
