<div class="select-container">
  <mat-form-field
    [class.disabled]="control?.disabled"
    [class.required]="isMandatory && !isReadonly"
    [attr.aria-label]="ariaLabel"
    [class.has-error]="hasError"
    [class.noValueSelected]="control.value === ''">
    <mat-select [id]="htmlId" [formControl]="control" panelClass="selectPanel">
      <mat-select-trigger>
        <span *ngIf="control.value?.showId" class="show-id">{{
          control.value === '' ? '' : control.value?.id
        }}</span>
        <span>{{ control.value === '' ? placeholder : control.value?.label }} </span>
      </mat-select-trigger>
      <mat-option [value]="''" class="custom-option select-placeholder">{{
        placeholder
      }}</mat-option>
      <mat-option *ngFor="let option of options" [value]="option" class="custom-option">
        <span *ngIf="alwaysShowId || option.showId" class="show-id"> {{ option.id }}</span>
        <span>{{ option.label }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
