import { Pipe, PipeTransform } from '@angular/core';

/**
 * Entfernt `columnsToRemove` aus der Liste `columns`, falls die `condition` nicht zutrifft (falsy ist).
 * Beispiele:
 * - `['a', 'b', 'c', 'd'] | filterDisplayColumns:['b']:true` => `['a', 'b', 'c', 'd']`
 * - `['a', 'b', 'c', 'd'] | filterDisplayColumns:['b', 'c']:false` => `['a', 'd']`
 */
@Pipe({ name: 'filterDisplayColumns' })
export class FilterDisplayColumnsPipe implements PipeTransform {

  transform(columns: string[], columnsToRemove: string[], condition: boolean): string[] {
    return condition ? columns : columns.filter(col => !columnsToRemove.includes(col));
  }
}
