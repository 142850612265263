<nav #container>
  <h1 id="headingnavigation" class="visually-hidden">Navigation</h1>
  <mat-list>
    <div *ngFor="let section of filterContent(sections)">
      <mat-list-item
        tabindex="0"
        [attr.aria-current]="selectedSection === section"
        (click)="changeSection(section)"
        (keydown.enter)="changeSection(section)"
        (keydown.space)="changeSection(section)"
        *ngIf="section.children[0].noticeNode.displayType !== 'SECTION'"
        [class.selectedMenuItem]="selectedSection === section">
        {{ section.translatedLabel }}
      </mat-list-item>
      <mat-expansion-panel
        class="mat-elevation-z0"
        #sectionPanels
        *ngIf="isExpandableSection(section)">
        <mat-expansion-panel-header>
          {{ section.translatedLabel }}
        </mat-expansion-panel-header>
        <mat-list role="listbox">
          <mat-list-item
            tabindex="0"
            role="option"
            [attr.aria-current]="selectedSection === subsection"
            (click)="changeSection(subsection)"
            (keydown.enter)="changeSection(subsection)"
            (keydown.space)="changeSection(subsection)"
            *ngFor="let subsection of section.children"
            [class.selectedMenuItem]="selectedSection === subsection">
            {{ subsection.translatedLabel }}
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </div>
    <app-repeatable-section
      *ngIf="repeatableSection && !isUpdateMetaData"
      [procedureSection]="procedureSection"
      [model]="repeatableSection">
    </app-repeatable-section>
  </mat-list>
</nav>
<app-build-info *ngIf="supportMode && !isRs"></app-build-info>
