import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { AmountModel } from '../../../view-model/type/amount-model';

@Component({
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountComponent extends AbstractFormComponent implements OnInit {
  amountControl = new FormControl();
  currencyControl = new FormControl();
  model: AmountModel;
  ariaLabelForCurrency: string;
  htmlIdForCurrency: string;

  ngOnInit(): void {
    this.ariaLabelForCurrency = `${this.model.translatedLabel}-Währung`;
    this.htmlIdForCurrency = `${this.htmlId}-Währung`;

    this.initValue();
    this.handleCurrencyChanges();

    if (this.model.isReadonly) {
      this.amountControl.disable({ emitEvent: false });
      this.currencyControl.disable({ emitEvent: false });
    }
  }

  commitValue(): void {
    this.model.value = {
      amount: this.amountControl.value,
      currencyID: this.currencyControl.value?.id,
    };
    this.onInputValueChanged();
  }

  private handleCurrencyChanges(): void {
    this.subscription.add(
      this.currencyControl.valueChanges.subscribe(() => {
        this.commitValue();
      })
    );
  }

  private initValue(): void {
    if (this.model.value?.amount) {
      this.amountControl.setValue(this.model.value?.amount, { emitEvent: false });
    }
    if (this.model.value?.currencyID) {
      this.currencyControl.setValue(
        this.model.currencies.find(currency => currency.id === this.model.value.currencyID) ?? null
      );
    }
  }
}
