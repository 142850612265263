export class Contact {
  officialName: string;
  nationalId?: string;
  address?: string;
  town?: string;
  nuts?: string;
  postalCode?: string;
  country?: string;
  contactPoint?: string;
  phone?: string;
  fax?: string;
  email?: string;
  url?: string;
  urlBuyer?: string;
}
