import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { ARCHIVE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';
import { DateModel } from '../../../../view-model/type/date-model';
import { DateUtilsService } from '../../../../utils/date-utils-service';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';

export class ArchivingDeadlineTodayValidator extends EVergabeSpecificValidator<FieldModel<string>> {
  private static readonly DEADLINE_TODAY_ERROR_MSG =
    'Das angegebene Datum muss nach dem heutigen Tag liegen.';

  constructor(
    private dateUtilsService: DateUtilsService,
    private editorConfigurationProvider: EditorConfigurationProvider
  ) {
    super();
  }

  shouldAttach(model: FieldModel<string>): boolean {
    return model.noticeNode.id === ARCHIVE_DATE_ID;
  }

  async validate(model: DateModel): Promise<ValidationNotification[]> {
    const archiveDateDeadline = model.asLocalDate();
    const tomorrow = this.dateUtilsService.getLocalDateOfTomorrow();
    if (
      archiveDateDeadline.isBefore(tomorrow) &&
      this.editorConfigurationProvider.isProductionMode()
    ) {
      return this.ofSingle(ArchivingDeadlineTodayValidator.DEADLINE_TODAY_ERROR_MSG);
    }
    return this.empty();
  }
}
