import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SearchResult } from '../../../../../types/app-types';

@Component({
  selector: 'app-highlight-text',
  templateUrl: './highlight-text.component.html',
  styleUrls: ['./highlight-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighlightTextComponent implements OnInit {
  @Input() searchResult: SearchResult;
  @Input() query: string;

  MAX_VISIBLE_CHARACTERS = 250;

  textNodes: { type: 'TEXT' | 'MARKED'; text: string }[] = [];

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.isEmpty(this.searchResult.previewText) || this.isEmpty(this.query)) {
      return;
    }

    if (this.searchResult.type !== 'string') {
      this.showTextWithoutHighlight();
      return;
    }

    this.createTextNodes();
    this.cdRef.markForCheck();
  }

  showTextWithoutHighlight() {
    this.textNodes = [
      {
        text: this.searchResult.previewText.substring(0, this.MAX_VISIBLE_CHARACTERS),
        type: 'TEXT',
      },
    ];
    if (this.searchResult.previewText.length > this.MAX_VISIBLE_CHARACTERS) {
      this.textNodes.push(this.getDotNode());
    }
  }

  /**
   * Creates Sections of The Search-Result-Preview Text, containing Non-Highlighted and Highlighted sections.
   * Max-Length will be limited to MAX_VISIBLE_CHARACTERS and only the Part of the Text, containing the query, will be shown.
   */
  createTextNodes() {
    let start = 0;
    let end = this.searchResult.previewText.length;

    const matchedIndex = this.searchResult.previewText
      .toLowerCase()
      .indexOf(this.query.toLowerCase());
    if (matchedIndex < 0) {
      this.showTextWithoutHighlight();
      return;
    }

    if (this.searchResult.previewText.length > this.MAX_VISIBLE_CHARACTERS) {
      const centerIndex = matchedIndex + this.query.length / 2;
      start = centerIndex - this.MAX_VISIBLE_CHARACTERS / 2;
      end = centerIndex + this.MAX_VISIBLE_CHARACTERS / 2;

      // Shift text-window right, if start < 0
      if (start < 0) {
        end += -start;
        start = 0;
      }

      if (end > this.searchResult.previewText.length) {
        end = this.searchResult.previewText.length;
      }
    }

    // Build Text
    this.textNodes = [];
    if (start !== 0) this.textNodes.push(this.getDotNode());
    this.textNodes.push(
      { type: 'TEXT', text: this.searchResult.previewText.substring(start, matchedIndex) },
      {
        type: 'MARKED',
        text: this.searchResult.previewText.substring(
          matchedIndex,
          matchedIndex + this.query.length
        ),
      },
      {
        type: 'TEXT',
        text: this.searchResult.previewText.substring(matchedIndex + this.query.length, end),
      }
    );
    if (end !== this.searchResult.previewText.length) this.textNodes.push(this.getDotNode());
  }

  isEmpty(item: any) {
    return item === undefined || item === null || item === '';
  }

  private getDotNode(): { type: 'TEXT'; text: string } {
    return { type: 'TEXT', text: '...' };
  }
}
