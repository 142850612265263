<button
  mat-mini-fab
  (click)="$event.stopPropagation(); openChangeAddressDialog(false, 'Eintrag bearbeiten')"
  class="eForm-icon-button normal"
  matTooltip="Adresse bearbeiten"
  matTooltipPosition="above"
  aria-label="Adresse bearbeiten">
  <mat-icon>edit</mat-icon>
</button>
<button
  mat-mini-fab
  (click)="$event.stopPropagation(); openChangeAddressDialog(true, 'Kopie bearbeiten')"
  class="eForm-icon-button normal"
  matTooltip="Adresse kopieren"
  matTooltipPosition="above"
  aria-label="Adresse kopieren">
  <mat-icon>file_copy</mat-icon>
</button>

<app-dialog
  #changeAddressDialog
  (accept)="saveContactChanges()"
  (dismiss)="closeChangeAddressDialog()"
  [hasCloseButton]="false"
  acceptLabel="Änderungen speichern"
  dismissLabel="Erfassung abbrechen"
  aria-labelledby="change-address">
  <div>
    <form
      *ngIf="contactInputForm"
      [formGroup]="contactInputForm"
      id="change-address"
      aria-label="Kontaktstelle bearbeiten">
      <label>
        Offizielle Bezeichnung
        <span [appMarkAsterisk]="true"></span>
        <input
          type="text"
          placeholder="Offizielle Bezeichnung"
          formControlName="officialName"
          name="officialName"
          [class.required]="true"
          class="form-text-field"
          required />
      </label>
      <app-alert-message
        *ngIf="contactInputForm.get('officialName').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span>Bitte erfassen Sie einen Wert.</span>
        </p>
      </app-alert-message>
      <label>
        <span *ngIf="isRS">Identifikationsnummer</span>
        <span *ngIf="!isRS">Registrierungsnummer</span>
        <input
          type="text"
          placeholder="Nationale Identifikationsnummer"
          formControlName="nationalId"
          name="nationalId"
          class="form-text-field" />
      </label>
      <label>
        Internet-Adresse
        <input
          type="url"
          placeholder="http://www.beispiel.de"
          formControlName="url"
          name="url"
          pattern="^((((H|h)(T|t)|(F|f))(T|t)(P|p)((S|s)?)):(/){2})(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}(\W(.)*$|$)"
          class="form-text-field" />
      </label>
      <app-alert-message
        *ngIf="contactInputForm.get('url').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span>Die Eingabe ist keine gültige URL (Beispiel: https://www.beispiel.de).</span>
        </p>
      </app-alert-message>
      <label>
        Postanschrift
        <input
          type="text"
          placeholder="Postanschrift"
          formControlName="address"
          name="address"
          class="form-text-field" />
      </label>
      <label>
        Ort
        <input
          type="text"
          placeholder="Ort"
          formControlName="town"
          name="town"
          class="form-text-field" />
      </label>
      <label>
        Postleitzahl
        <input
          type="text"
          placeholder="Postleitzahl"
          formControlName="postalCode"
          name="postalContact"
          class="form-text-field" />
      </label>
      <label>
        NUTS-3-Code
        <input
          type="text"
          placeholder="DE123"
          formControlName="nuts"
          (input)="onNUTSSearch()"
          name="nuts"
          class="form-text-field nuts-input"
          [matAutocomplete]="autoNuts" />
        <mat-autocomplete #autoNuts [displayWith]="displayNUTS.bind(this)">
          <mat-option *ngIf="noSearchResult" disabled>
            Für die Eingabe wurde kein Treffer gefunden.
          </mat-option>
          <mat-option *ngIf="insufficientInput" disabled>
            Bitte geben Sie mindestens 3 Zeichen ein.
          </mat-option>
          <mat-option *ngFor="let nuts of visibleItemsNUTS" [value]="nuts">
            ({{ nuts.id }}) {{ nuts.label }}
          </mat-option>
        </mat-autocomplete>
      </label>
      <div class="nuts-label">
        <span>
          {{ this.getLabel(this.codeListNUTS, this.contactInputForm.get('nuts')) }}
        </span>
      </div>
      <app-alert-message
        *ngIf="contactInputForm.get('nuts').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span> Die Eingabe ist kein gültiger NUTS-3-Code. </span>
        </p>
      </app-alert-message>
      <label>
        Land
        <app-codelist
          *ngIf="codeListModelLand?.codeList"
          [model]="codeListModelLand"></app-codelist>
      </label>
      <label>
        Kontaktstelle
        <input
          type="text"
          placeholder="Kontaktstelle"
          formControlName="contactPoint"
          name="contactPoint"
          class="form-text-field" />
      </label>
      <label>
        E-Mail
        <input
          type="mail"
          placeholder="mail@beispiel.de"
          formControlName="email"
          name="email"
          class="form-text-field"
          pattern="^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$"
          email />
      </label>
      <app-alert-message
        *ngIf="contactInputForm.get('email').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span>
            Die Eingabe ist keine gültige E-Mail Adresse (Beispiel: beispiel@beispiel.de).
          </span>
        </p>
      </app-alert-message>
      <label>
        Telefon
        <input
          type="tel"
          placeholder="+49 228 123456"
          formControlName="phone"
          name="phone"
          pattern="^((\(\+?[0-9]+\))|\+?[0-9]+)( - |-| )?(((\(\d+\))|\d+)( - |-| )?)*(\d+)( )?$"
          maxlength="30"
          minlength="2"
          class="form-text-field" />
      </label>
      <app-alert-message
        *ngIf="contactInputForm.get('phone').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span>Die Eingabe ist keine gültige Telefonnummer (Beispiel: +49 228 12345).</span>
        </p>
      </app-alert-message>
      <label>
        Fax
        <input
          type="tel"
          placeholder="+49 228 123456"
          formControlName="fax"
          pattern="^((\(\+?[0-9]+\))|\+?[0-9]+)( - |-| )?(((\(\d+\))|\d+)( - |-| )?)*(\d+)( )?$"
          maxlength="30"
          minlength="2"
          name="fax"
          class="form-text-field" />
      </label>
      <app-alert-message
        *ngIf="contactInputForm.get('fax').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span>Die Eingabe ist keine gültige Telefonnummer (Beispiel: +49 228 12345).</span>
        </p>
      </app-alert-message>
      <label>
        Endpunkt für den Informationsaustausch
        <input
          type="text"
          placeholder="http://www.beispiel.de"
          formControlName="urlBuyer"
          name="urlBuyer"
          pattern="^((((H|h)(T|t)|(F|f))(T|t)(P|p)((S|s)?)):(/){2})(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}(\W(.)*$|$)"
          class="form-text-field" />
      </label>
      <app-alert-message
        *ngIf="contactInputForm.get('urlBuyer').invalid"
        [severity]="Severity.ERROR"
        class="alert-box">
        <p>
          <span>Die Eingabe ist keine gültige URL (Beispiel: https://www.beispiel.de).</span>
        </p>
      </app-alert-message>
    </form>
  </div>
</app-dialog>
