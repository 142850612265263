import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './components/layout/dialog/dialog.module';
import { CoreModule } from './modules/core.module';
import { HttpClientModule } from '@angular/common/http';
import { DynamicFormContainerComponent } from './components/layout/dynamic-form-container/dynamic-form-container.component';
import { TextFieldComponent } from './components/form-components/text-field/text-field.component';
import { GroupComponent } from './components/form-components/group/group.component';
import { FormularContainerComponent } from './components/layout/formular-container/formular-container.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SideBarComponent } from './components/layout/side-bar/side-bar.component';
import { FormInfoComponent } from './components/layout/form-info/form-info.component';
import { SectionComponent } from './components/form-components/section/section.component';
import { ComponentDirective } from './components/component.directive';
import { TextAreaComponent } from './components/form-components/text-area/text-area.component';
import { MultiLingualComponent } from './components/form-components/multi-lingual/multi-lingual.component';
import { StoreModule } from '@ngrx/store';
import { DateComponent } from './components/form-components/date/date.component';
import { LanguageTextFieldComponent } from './components/form-components/multi-lingual/language-text-field/language-text-field.component';
import { LanguageTextAreaComponent } from './components/form-components/multi-lingual/language-text-area/language-text-area.component';
import { RepeatableComponent } from './components/form-components/repeatable/repeatable.component';
import { CodelistComponent } from './components/form-components/codelist/codelist.component';
import { RepeatableFieldComponent } from './components/form-components/repeatable/repeatable-field/repeatable-field.component';
import { UrlComponent } from './components/form-components/text-field/url/url.component';
import { EmailComponent } from './components/form-components/text-field/email/email.component';
import { PhoneComponent } from './components/form-components/text-field/phone/phone.component';
import { NumberComponent } from './components/form-components/number/number.component';
import { IndicatorComponent } from './components/form-components/indicator/indicator.component';
import { IntegerComponent } from './components/form-components/integer/integer.component';
import { TimeComponent } from './components/form-components/date/time/time.component';
import { AmountComponent } from './components/form-components/amount/amount.component';
import { MeasureComponent } from './components/form-components/measure/measure.component';
import { IdRefComponent } from './components/form-components/id-ref/id-ref.component';
import { IdComponent } from './components/form-components/id/id.component';
import { RepeatableSectionComponent } from './components/layout/repeatable-section/repeatable-section.component';
import { CheckLockGuard } from './portal/shared/check-lock.guard';
import { LoadingSpinnerComponent } from './portal/shared/loading-spinner/loading-spinner.component';
import { PendingChangesGuard } from './portal/shared/pending-changes.guard';
import { RemoveLockGuard } from './portal/shared/remove-lock.guard';
import { indicatorReducer } from './store/indicator/indicator.reducer';
import { languageReducer } from './store/language/language.reducer';
import { noticeLastEditedReducer } from './store/notice-last-edited/notice-last-edited.reducer';
import { resetStateReducer } from './store/reset/reset.reducer';
import { rsDisplaymodeReducer } from './store/rs-displaymode/rs-displaymode.reducer';
import { sectionIdReducer } from './store/section/section.reducer';
import { FormRootComponent } from './components/layout/form-root/form-root.component';
import { SelectNoticeComponent } from './components/layout/select-notice/select-notice.component';
import { MaterialModule } from './material-modules';
import { noticeIdReducer } from './store/notice-id/notice-id.reducer';
import { AutocompleteComponent } from './components/form-components/codelist/autocomplete/autocomplete.component';
import { SelectComponent } from './components/form-components/select/select.component';
import { ValidationMessageComponent } from './components/form-components/validation-message/validation-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationStatusComponent } from './components/form-components/validation-status/validation-status.component';
import { devmodeReducer } from './store/devmode/devmode.reducer';
import { validReducer } from './store/valid/valid.reducer';
import { FieldHeaderComponent } from './components/form-components/field-header/field-header.component';
import { AngularSplitModule } from 'angular-split';
import { MaxLengthBadgeDirective } from './components/directives/max-length-badge/max-length-badge.directive';
import { errorsReducer } from './store/errors/errors.reducer';
import { ProjectComponent } from './components/form-components/project/project.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NotificationComponent } from './components/layout/notification-box/notification/notification.component';
import { NotificationBoxComponent } from './components/layout/notification-box/notification-box.component';
import { PostalCodeComponent } from './components/form-components/text-field/postal-code/postal-code.component';
import { ToolbarComponent } from './components/layout/header/toolbar/toolbar.component';
import { SearchComponent } from './components/layout/header/search/search.component';
import { FontSizeComponent } from './components/layout/header/font-size/font-size.component';
import { EuiModules } from './eui-modules';
import { TrimAndMapEmptyToNullDirective } from './components/directives/trim/trim-and-map-empty-to-null.directive';
import { HighlightTextComponent } from './components/layout/header/search/highlight-text/highlight-text.component';
import { CollapseExpandComponent } from './components/layout/header/collapse-expand/collapse-expand.component';
import { AddressBookComponent } from './components/form-components/repeatable/repeatable-group/address-book/address-book.component';
import { ChangeAddressComponent } from './components/form-components/repeatable/repeatable-group/address-book/change-address/change-address.component';
import { RepeatableGroupComponent } from './components/form-components/repeatable/repeatable-group/repeatable-group.component';
import { SearchResultsComponent } from './components/layout/header/search/search-results/search-results.component';
import { modifiedReducer } from './store/modified/modified.reducer';
import { BuildInfoComponent } from './components/layout/build-info/build-info.component';
import { InputNumberDirective } from './components/directives/only-number/input-number.directive';
import { MarkAsteriskDirective } from './components/directives/required-filed-asterix/mark-asterisk.directive';
import { AlertMessageComponent } from './components/form-components/validation-message/alert-message/alert-message.component';

import { PortalModule } from './portal/portal.module';
import { NeuigkeitenBannerComponent } from './portal/redaktionssystem/neuigkeiten-banner/neuigkeiten-banner.component';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { procedureTypeReducer } from './store/procedure-type/procedure-type.reducer';
import { supportmodeReducer } from './store/supportmode/supportmodeReducer';
import { ApiModule, Configuration, ConfigurationParameters } from './portal/api/notices';
import {
  ApiModule as TemplateApiModule,
  Configuration as TemplateConfiguration,
  ConfigurationParameters as TemplateConfigurationParameters,
} from './portal/api/templates';
import {
  ApiModule as UserServiceApiModule,
  Configuration as UserServiceConfiguration,
  ConfigurationParameters as UserServiceConfigurationParameters,
} from './portal/api/user-service';
import {
  JS_JODA_DATE_ADAPTER_OPTIONS,
  JS_JODA_DATE_FORMATS,
  JsJodaDateAdapter,
} from './adapter/js-joda-date-adapter';
import { BT300BR26DeKmuSelectionComponent } from './components/form-components/custom-field-components/bt-300-br-26-de-kmu-selection/bt-300-br-26-de-kmu-selection.component';
import { BT09SelectionComponent } from './components/form-components/custom-field-components/bt-09-selection/bt-09-selection.component';
import { formChangeTypeReducer } from './store/form-change-type/form-change-type.reducer';
import { PrefillFieldsDialogComponent } from './components/layout/repeatable-section/prefill-fields-dialog/prefill-fields-dialog.component';
import { SelectionCriteriaBookComponent } from './components/form-components/repeatable/repeatable-group/selection-criteria-book/selection-criteria-book.component';
import { ChangeSelectionCriterionComponent } from './components/form-components/repeatable/repeatable-group/selection-criteria-book/change-selection-criterion/change-selection-criterion.component';
import { RsTemplateTooltipComponent } from './components/form-components/field-header/rs-template-tooltip/rs-template-tooltip.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getGermanPaginatorIntl } from './portal/shared/german-paginator-intl';
import { GrLotResultCvdVehiclesStatisticsComponent } from './components/form-components/custom-field-components/gr-lot-result-cvd-vehicles-statistics/gr-lot-result-cvd-vehicles-statistics.component';
import { RadioButtonComponent } from './components/form-components/radio-button/radio-button.component';
import { GrLotResultReceivedSubmissionsComponent } from './components/form-components/custom-field-components/gr-lot-result-received-submissions/gr-lot-result-received-submissions.component';
import { ElementForEachCodelistItemComponent } from './components/form-components/custom-field-components/element-for-each-codelist-item/element-for-each-codelist-item.component';

const apiConfigFactory = (): Configuration => {
  const params: ConfigurationParameters = {
    basePath: '',
  };
  return new Configuration(params);
};

const apiConfigTemplateFactory = (): TemplateConfiguration => {
  const params: TemplateConfigurationParameters = {
    basePath: '',
  };
  return new TemplateConfiguration(params);
};

const apiConfigUserServiceFactory = (): UserServiceConfiguration => {
  const params: UserServiceConfigurationParameters = {
    basePath: '',
  };
  return new UserServiceConfiguration(params);
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FormularContainerComponent,
    FooterComponent,
    SideBarComponent,
    FormInfoComponent,
    DynamicFormContainerComponent,
    TextFieldComponent,
    GroupComponent,
    SectionComponent,
    ComponentDirective,
    TextAreaComponent,
    MultiLingualComponent,
    DateComponent,
    LanguageTextFieldComponent,
    LanguageTextAreaComponent,
    RepeatableComponent,
    CodelistComponent,
    RepeatableFieldComponent,
    RepeatableGroupComponent,
    UrlComponent,
    EmailComponent,
    PhoneComponent,
    NumberComponent,
    IndicatorComponent,
    IntegerComponent,
    TimeComponent,
    AmountComponent,
    MeasureComponent,
    IdRefComponent,
    IdComponent,
    RepeatableSectionComponent,
    FormRootComponent,
    SelectNoticeComponent,
    AutocompleteComponent,
    SelectComponent,
    ValidationMessageComponent,
    ValidationStatusComponent,
    FieldHeaderComponent,
    RsTemplateTooltipComponent,
    MaxLengthBadgeDirective,
    ProjectComponent,
    NotificationComponent,
    NotificationBoxComponent,
    PostalCodeComponent,
    AddressBookComponent,
    ChangeAddressComponent,
    ToolbarComponent,
    SearchComponent,
    FontSizeComponent,
    HighlightTextComponent,
    TrimAndMapEmptyToNullDirective,
    CollapseExpandComponent,
    SearchResultsComponent,
    BuildInfoComponent,
    MarkAsteriskDirective,
    InputNumberDirective,
    AlertMessageComponent,
    NeuigkeitenBannerComponent,
    BT300BR26DeKmuSelectionComponent,
    GrLotResultCvdVehiclesStatisticsComponent,
    BT09SelectionComponent,
    PrefillFieldsDialogComponent,
    SelectionCriteriaBookComponent,
    ChangeSelectionCriterionComponent,
    LoadingSpinnerComponent,
    RadioButtonComponent,
    ElementForEachCodelistItemComponent,
    GrLotResultReceivedSubmissionsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    AngularSplitModule,
    EuiModules,
    StoreModule.forRoot(
      {
        languages: languageReducer,
        indicator: indicatorReducer,
        visibleSectionId: sectionIdReducer,
        noticeId: noticeIdReducer,
        devMode: devmodeReducer,
        supportMode: supportmodeReducer,
        rsDisplayMode: rsDisplaymodeReducer,
        modified: modifiedReducer,
        valid: validReducer,
        errors: errorsReducer,
        procedureTypeName: procedureTypeReducer,
        modelChange: formChangeTypeReducer,
        noticeLastEdited: noticeLastEditedReducer,
      },
      {
        metaReducers: [resetStateReducer],
      }
    ),
    FormsModule,
    PortalModule,
    DialogModule,
    ApiModule.forRoot(apiConfigFactory),
    TemplateApiModule.forRoot(apiConfigTemplateFactory),
    // TODO
    UserServiceApiModule.forRoot(apiConfigUserServiceFactory),
  ],
  exports: [DialogModule],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: JS_JODA_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: JsJodaDateAdapter,
      deps: [MAT_DATE_LOCALE, JS_JODA_DATE_ADAPTER_OPTIONS],
    },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    PendingChangesGuard,
    RemoveLockGuard,
    CheckLockGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
