import { ModelValidator } from '../ModelValidator';
import { MultilingualModel } from '../../../../view-model/type/multilingual-model';
import { Language } from '../../../../types/data-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { FieldModel } from '../../../../view-model/field-model';

export class MaxLengthForMultilingualValidator extends ModelValidator<FieldModel<any>> {
  constructor() {
    super();
  }

  validate(fieldModel: MultilingualModel): Promise<ValidationNotification[]> {
    const validationMessage = `Die maximale Länge von ${fieldModel.fieldInfo.maxLength} Zeichen wurde überschritten.`;

    const errors = [];

    if (this.germanInputPresentAndExceedsLengthRestriction(fieldModel)) {
      errors.push(new ValidationNotification(validationMessage, Severity.ERROR, Language.Deu));
    }

    if (this.englishInputPresentAndExceedsLengthRestriction(fieldModel)) {
      errors.push(new ValidationNotification(validationMessage, Severity.ERROR, Language.Eng));
    }

    return Promise.resolve(errors);
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model instanceof MultilingualModel && !!model.fieldInfo?.maxLength;
  }

  private germanInputPresentAndExceedsLengthRestriction(fieldModel: MultilingualModel) {
    return (
      fieldModel.activeLanguages.includes(Language.Deu) &&
      fieldModel.value.DEU?.length > +fieldModel.fieldInfo.maxLength
    );
  }

  private englishInputPresentAndExceedsLengthRestriction(fieldModel: MultilingualModel) {
    return (
      fieldModel.activeLanguages.includes(Language.Eng) &&
      fieldModel.value.ENG?.length > +fieldModel.fieldInfo.maxLength
    );
  }
}
