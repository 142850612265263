import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { FormRootComponent } from '../../components/layout/form-root/form-root.component';
import { NoticeLockControllerService } from '../api/notices';
import { RefreshNoticeLockService } from './refresh-notice-lock.service';

@Injectable()
export class RemoveLockGuard implements CanDeactivate<FormRootComponent> {

  private router = inject(Router);
  private refreshLockService = inject(RefreshNoticeLockService);
  private lockService = inject(NoticeLockControllerService);

  canDeactivate(component: FormRootComponent, currentRoute: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const rsNoticeId = currentRoute.queryParamMap.get('rsNoticeId');
    const bypassLockDeletion = this.router.getCurrentNavigation()?.extras.state?.bypassLockDeletion;
    if (!rsNoticeId || bypassLockDeletion) {
      return true;
    }
    return this.lockService.deleteLock(+rsNoticeId).pipe(
      map(() => true),
      catchError(() => of(true)),
      finalize(() => this.refreshLockService.stop()),
    );
  }
}
