import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TemplateNameAndDescriptionService {
  private name: string;
  private description: string;

  constructor() {
    this.reset();
  }

  setValues(name: string, description: string) {
    this.name = name;
    this.description = description;
  }

  getName() {
    return this.name;
  }

  getDescription() {
    return this.description;
  }

  reset() {
    this.name = 'Neue Vorlage';
    this.description = '';
  }
}
