import { createAction, props } from '@ngrx/store';
import { IndicatorPayload, IndicatorValue, SchemeIdUpdate } from '../app.state';

export const add = createAction('ADD_INDICATOR', props<IndicatorPayload>());

export const updateSchemeIdRefreshHighest = createAction(
  'UPDATE_INDICATOR',
  props<SchemeIdUpdate>()
);

export const updateLabel = createAction(
  'UPDATE_LABEL_INDICATOR',
  props<{ indicator: string; value: IndicatorValue }>()
);
export const remove = createAction('REMOVE_INDICATOR', props<{ indicator: string; id: string }>());
