import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, throwError } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { DialogComponent } from '../../../components/layout/dialog/dialog.component';
import { GroupMemberModel, GroupsService } from '../../api/user-service';
import { ErrorType } from '../../shared/error-type.enum';

@Component({
  selector: 'app-gruppen-einladung-dialog',
  templateUrl: './gruppen-einladung-dialog.component.html',
  styleUrls: ['./gruppen-einladung-dialog.component.scss'],
})
export class GruppenEinladungDialogComponent {
  @ViewChild(DialogComponent) dialog: DialogComponent;

  @Output() confirm = new EventEmitter<{ groupId: number; invitedMember: GroupMemberModel }>();

  form: FormGroup;

  private groupId: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private groupsService: GroupsService
  ) {
  }

  openDialog(groupId: number) {
    this.groupId = groupId;
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
    });

    this.form.statusChanges.pipe(
      startWith(this.form.status)
    ).subscribe(status => {
      this.dialog.acceptButtonDisabled = status !== 'VALID'
    });

    this.dialog.openDialog();
  }

  onAccept() {
    if (this.form.valid) {
      this.groupsService.inviteMember(this.groupId, this.form.get('username').value).pipe(
        catchError(err => {
          if (err.error.errorType === ErrorType.USER_NOT_FOUND) {
            this.form.get('username').setErrors({ USER_NOT_FOUND: true });
            this.cdr.markForCheck();
          } else if (err.error.errorType === ErrorType.USER_ALREADY_IN_GROUP) {
            this.form.get('username').setErrors({ USER_ALREADY_IN_GROUP: true });
            this.cdr.markForCheck();
          } else {
            return throwError(() => err);
          }
          return EMPTY;
        }),
      ).subscribe((invitedMember: GroupMemberModel) => {
        this.dialog.close();
        this.confirm.emit({ groupId: this.groupId, invitedMember });
      });
    }
  }

  onDismiss() {
    this.form.reset();
  }
}
