import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CodelistModel } from '../../../../view-model/type/codelist-model';
import { PostalCodeService } from '../../../../services/postal-code.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() codeListItems: { id: string; label: string }[];
  @Input() hasError: boolean;
  @Input() model: CodelistModel;
  @Input() htmlId: string;

  visibleItems: { id: string; label: string }[] = [];
  noSearchResult = false;
  insufficientInput = false;
  hiddenResults = 0;
  itemLimit = 0;

  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private subscription = new Subscription();

  constructor(private postalCodeService: PostalCodeService) {}

  ngOnInit(): void {
    this.itemLimit = this.model.isAutoComplete ? 12 : 30;

    if (this.control.value) this.visibleItems.push(this.control.value);
    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => this.cdRef.markForCheck())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDisplayValue(item) {
    if (!item?.label || !item.id) {
      return '';
    }
    return this.model.isAutoComplete ? item.label : item.id;
  }

  onSearch(): void {
    if (!this.control.value || typeof this.control.value !== 'string') {
      this.insufficientInput = true;
      this.noSearchResult = this.model.isAutoComplete;
      this.visibleItems = [];
      this.hiddenResults = 0;
      return;
    }

    const searchedValue = this.control.value.toLowerCase();

    if (this.model.isCodeField && searchedValue.length < 3) {
      this.insufficientInput = true;
      this.noSearchResult = false;
      this.visibleItems = [];
      this.hiddenResults = 0;
      return;
    }

    this.insufficientInput = false;
    const nuts = this.findNutsCodeForPostalCode(searchedValue);

    this.visibleItems = this.codeListItems.filter(
      item =>
        item.id.toLowerCase().includes(nuts ? nuts.toLowerCase() : searchedValue) ||
        item.label.toLowerCase().includes(searchedValue)
    );

    if (this.visibleItems.length === 0) {
      this.noSearchResult = true;
      return;
    }
    this.noSearchResult = false;

    if (this.visibleItems.length > this.itemLimit) {
      this.hiddenResults = this.visibleItems.length - this.itemLimit;
    } else {
      this.hiddenResults = 0;
    }

    if (this.model.isAutoComplete) {
      this.visibleItems.sort(this.orderAutocomplete);
    }
    this.visibleItems = this.visibleItems.slice(0, this.itemLimit);
  }

  clearInput(): void {
    this.control.setValue('');
    this.onSearch();
  }

  private orderAutocomplete(a, b): number {
    if (a.id === 'DEU' && a.label === 'Deutschland') {
      return -1;
    } else if (b.id === 'DEU' && b.label === 'Deutschland') {
      return 1;
    } else {
      return a.label.localeCompare(b.label);
    }
  }

  private findNutsCodeForPostalCode(postalCode: string) {
    const nuts = this.postalCodeService.getNutsCodeForPostalCode(postalCode);
    return nuts ? this.codeListItems.find(item => item.id === nuts).id : null;
  }
}
