<div [hidden]="model.isHidden" class="form-component-input-group" [id]="htmlId">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>
  <div class="checkbox-wrapper">
    <input
      type="checkbox"
      [id]="htmlId + '_1'"
      name="crossBorderLawCheckbox"
      [formControl]="enableCrossBorderLawControl"
      class="form-input-checkbox"
      [class.has-error]="hasInputError()"
      [class.required]="model.isMandatory"
      aria-label="Beteiligung von Beschaffern aus verschiedenen Ländern an der Auftragsvergabe" />
    <label
      [for]="htmlId + '_1'"
      class="form-input-checkbox-label form-input-checkbox-label-cursor-pointer"
      >Beteiligung von Beschaffern aus verschiedenen Ländern an der Auftragsvergabe</label
    >
  </div>
  <app-validation-message [model]="model"></app-validation-message>
</div>
