import { ActionReducer } from '@ngrx/store';
import * as ResetActions from './reset.action';

export const resetStateReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === ResetActions.resetState.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
