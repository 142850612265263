import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BusinessRuleResult } from '../../../types/app-types';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-validation-status',
  templateUrl: './validation-status.component.html',
  styleUrls: ['./validation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationStatusComponent implements OnInit, OnDestroy {
  succesfulValidations: BusinessRuleResult[];
  inactiveValidations: BusinessRuleResult[];
  failedValidations: BusinessRuleResult[];
  validationsWithError: BusinessRuleResult[];
  devmode = false;

  subscriptions = new Subscription();

  constructor(private stateService: StateService, private cdRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.stateService.getDevMode().subscribe(devmode => {
        this.devmode = devmode;
        this.cdRef.markForCheck();
      })
    );
  }

  @Input() set validationResults(value: BusinessRuleResult[]) {
    this.succesfulValidations = [];
    this.inactiveValidations = [];
    this.failedValidations = [];
    this.validationsWithError = [];
    value?.forEach(current => {
      if (current.error) {
        this.validationsWithError.push(current);
        return;
      }

      if (current.isConditional && !current.conditionResult) {
        this.inactiveValidations.push(current);
      }

      if (current.validationMessage) {
        this.failedValidations.push(current);
      } else if (current.conditionResult) {
        this.succesfulValidations.push(current);
      }
    });
  }

  print(validations: BusinessRuleResult[]) {
    console.log(validations);
  }
}
