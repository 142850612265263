// Types der fields/fields.json aus dem e-Forms SDK

import { FieldValueType } from './data-model';

export interface Fields {
  ublVersion: string;
  sdkVersion: string;
  metadataDatabase: {
    version: string;
    createdOn: string;
  };
  xmlStructure: XmlStructure[];
  fields: FieldInfo[];
}

export interface FieldInfo {
  id?: string;
  parentNodeId?: string;
  name?: string;
  btId?: string;
  xpathAbsolute?: string;
  xpathRelative?: string;
  type?: FieldInfoType;
  presetValue?: FieldValueType;
  maxLength?: string;
  legalType?: string;
  placeholder?: string;
  repeatable?: {
    value: boolean;
    severity: string;
  };

  pattern?: {
    value: string;
    severity: string;
  };
  forbidden?: FieldAssert;
  codeList?: {
    value: {
      id: string;
      type: string;
    };
  };
  mandatory?: FieldAssert;
  assert?: FieldAssert;

  /**
   * Every Field ID, which is occurring in the following array, has an EFX Term in its field definition, which resolves to this exact Field ID.
   */
  dependencyFields?: string[];
}

export interface CodelistItem {
  id: string;
  label: string;
}

export interface FieldAssert {
  value: boolean | string; // Default-Value for an assert. Can be EFX Expression.
  severity: string;
  constraints: FieldConstraint[];
  valueRef?: string; // Reference to JavaScript function, if value is EFX Expression
  valueContext?: string;
}

export interface FieldConstraint {
  value: string | boolean; // EFX Expression
  severity: string;
  message?: string;
  valueRef?: string;
  valueContext?: string;
  conditionRef?: string;
  conditionContext?: string;
  condition?: string;
  dependencyFields?: string[];
  noticeTypes?: string[];
}

export interface XmlStructure {
  id: string;
  parentId: string;
  xpathRelative: string;
  xpathAbsolute: string;
  repeatable: boolean;
  identifierFieldId?: string;
  captionFieldId?: string;
}

export enum FieldInfoType {
  TEXT_MULTILINGUAL = 'text-multilingual',
  AMOUNT = 'amount',
  INTEGER = 'integer',
  INDICATOR = 'indicator',
  URL = 'url',
  ID = 'id',
  TEXT = 'text',
  DATE = 'date',
  PROJECT = 'project',
  CODE = 'code',
  ID_REF = 'id-ref',
  MEASURE = 'measure',
  MAIL = 'email',
  NUMBER = 'number',
  PHONE = 'phone',
  TIME = 'time',
}
