import { BaseModel } from './base-model';
import { SectionModel } from './section-model';

export class RepeatableSectionModel extends BaseModel {
  private _children?: SectionModel[] = [];
  private _isExpanded = false;

  get children(): SectionModel[] {
    return this._children;
  }

  public addChild(model: SectionModel): void {
    this._children.push(model);
  }

  public removeChild(model: SectionModel) {
    this._children = this._children.filter(child => child !== model);
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
  }
}
