import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ComponentDirective } from '../../component.directive';
import { DynamicComponentFactoryService } from '../../../services/dynamic-component-factory.service';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { SectionModel } from '../../../view-model/section-model';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent extends AbstractFormComponent implements OnChanges {
  @ViewChild(ComponentDirective, { static: true }) public view: ComponentDirective;
  @Input() model: SectionModel;

  constructor(private dynamicComponentFactoryService: DynamicComponentFactoryService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.view.viewContainerRef.clear();
      this.dynamicComponentFactoryService.generateComponents(
        this.view.viewContainerRef,
        this.model
      );
    }
  }
}
