import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { FieldModel } from '../../../view-model/field-model';

@Component({
  templateUrl: './integer.component.html',
  styleUrls: ['./integer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegerComponent extends AbstractFormComponent implements OnInit {
  inputControl = new FormControl();
  model: FieldModel<number>;

  ngOnInit(): void {
    if (this.model.value) {
      this.inputControl.setValue(this.model.value);
    }

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: false });
    }
  }

  commitModel() {
    this.model.value = this.inputControl.value == null ? null : +this.inputControl.value;
    this.onInputValueChanged();
  }
}
