<div>
  <div class="filter-box">
    <fieldset [formGroup]="searchForm">
      <legend>Formulare filtern</legend>
      <div class="search-wrapper">
        <div class="search-field" *ngFor="let key of filterKeys | keyvalue : returnZero">
          <label>{{ filterKeys[key.key] }}</label>
          <mat-form-field>
            <mat-select [formControlName]="key.key" multiple>
              <mat-option *ngFor="let option of filterValues[key.key]" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </div>

  <div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="subTypeId"
      matSortDirection="asc">
      <ng-container matColumnDef="subTypeId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Unterart der Bekanntmachung</th>
        <td mat-cell *matCellDef="let element">
          {{ element.subTypeId | subTypeIdToName | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bekanntmachungsart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bekanntmachungsart</th>
        <td mat-cell *matCellDef="let element">{{ element.bekanntmachungsart }}</td>
      </ng-container>

      <ng-container matColumnDef="formTypeLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Formulartyp</th>
        <td mat-cell *matCellDef="let element">{{ element.formTypeLabel }}</td>
      </ng-container>

      <ng-container matColumnDef="legalBasisLabel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vergaberechtsrahmen</th>
        <td mat-cell *matCellDef="let element">{{ element.legalBasisLabel }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onRowClick(row)"
        class="table-row"></tr>
    </table>
  </div>
</div>
