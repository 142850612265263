import { createReducer, on } from '@ngrx/store';
import * as NoticeIdActions from './notice-id.action';

/**
 * Reducer für die Id des Formulars
 */
export const noticeIdReducer = createReducer(
  '',
  on(NoticeIdActions.update, (_state, props) => props.id)
);
