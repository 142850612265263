<app-template [hasContentFrame]="true" [titel]="'Leichte Sprache'">
  <p>Dieser Text ist in leicht verständlicher Sprache geschrieben.</p>
  <p>So können alle Menschen den Text gut verstehen.</p>
  <p>
    Die Behörden und öffentliche Einrichtungen in Deutschland vergeben Aufgaben und Arbeiten an
    Unternehmen.
  </p>
  <p>Die Aufgaben und Arbeiten werden im Internet bekannt gegeben.</p>
  <p>Damit man das machen kann, braucht man eine Plattform.</p>
  <p>Das Redaktionssystem ist eine solche Internet-Plattform.</p>
  <p>
    Auf dieser Plattform können Behörden und öffentliche Einrichtungen die Aufgaben und Arbeiten zur
    Veröffentlichung eintragen.
  </p>
</app-template>
