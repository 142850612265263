<app-dialog
  #vorlagenNameDialog
  (accept)="onClickWeiter()"
  (dismiss)="onDismiss()"
  (closed)="onDismiss()"
  [hasCloseButton]="true"
  title="{{ dialogTitle ?? 'Vorlagen Metadaten' }}"
  acceptLabel="{{ acceptButtonLabel ?? 'Weiter' }}"
  dismissLabel="Abbrechen">
  <div>
    <form
      *ngIf="vorlagenInputForm"
      [formGroup]="vorlagenInputForm"
      aria-label="Vorlagen Metadaten bearbeiten">

      <div>
        <label>
          Name
          <span class="required-asterisk">*</span>
          <div class="input-maxlength-badge-wrapper"><span class="input-maxlength-badge">{{ remainingNameCharacters() }}</span></div>
          <input
            formControlName="name"
            class="form-textarea required"
            name="vorlagenName"
            placeholder="Name der Vorlage"
            (input)="validateIfConfirmButtonIsEnabled()"
            [class.has-error]="!vorlagenInputForm.get('name').valid && vorlagenInputForm.get('name').touched"
            [maxLength]="nameMaxLength"
            required />
        </label>
      </div>
      <div class="form-field-spacer"></div>
      <div>
        <label>
        Beschreibung
        <div class="input-maxlength-badge-wrapper"><span class="input-maxlength-badge">{{ remainingDescriptionCharacters() }}</span></div>
        <input
          formControlName="description"
          class="form-textarea"
          name="vorlagenDescription"
          placeholder="Beschreibung der Vorlage"
          [maxLength]="descriptionMaxLength"/>
      </label>
      </div>
    </form>
  </div>
</app-dialog>
