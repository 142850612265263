import { createReducer, on } from '@ngrx/store';
import * as LanguageActions from './language.action';
import { Language } from '../../types/data-model';

export const initialState = [Language.Deu];

/**
 * Reducer für die Sprache in den multilingualen Feldern.
 */
export const languageReducer = createReducer(
  initialState,
  on(LanguageActions.add, (state: Language[], props) =>
    state.includes(props.lang) ? state : [...state, props.lang]
  ),
  on(LanguageActions.remove, (state, props) => state.filter(lang => lang !== props.lang))
);
