import { Injectable } from '@angular/core';
import { BaseModel, ModelWithChildren } from '../view-model/base-model';
import { RepeatableGroupModel } from '../view-model/repeatable-group-model';
import { SectionModel } from '../view-model/section-model';
import { GroupModel } from '../view-model/group-model';
import { ComponentModel } from '../view-model/component-model';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MultilingualModel } from '../view-model/type/multilingual-model';
import { Language } from '../types/data-model';

@Injectable()
export class NavigationService {
  constructor(private router: Router) {}

  navigate(model: BaseModel, activatedRoute: ActivatedRoute, language: Language): void {
    let parent = model.parent;
    const afterExpandEmitters: Promise<void>[] = [];
    while (!(parent instanceof ComponentModel)) {
      const afterExpandEmitter = this.handleParent(parent, activatedRoute);

      if (afterExpandEmitter) {
        afterExpandEmitters.push(afterExpandEmitter);
      }

      parent = parent.parent;
    }

    Promise.all(afterExpandEmitters).then(() => {
      if (model instanceof MultilingualModel) {
        model.focusedLanguage = language;
      }
      model.focus = true;
      model.emitChange();
    });
  }

  changeSection(section: SectionModel, activatedRoute: ActivatedRoute): void {
    this.router.navigate(['../', section.sectionId], {
      queryParamsHandling: 'merge',
      relativeTo: activatedRoute,
    });
  }

  private handleParent(model: ModelWithChildren, activatedRoute: ActivatedRoute): Promise<void> {
    if (model instanceof SectionModel && model.isNavigableSection()) {
      this.changeSection(model, activatedRoute);
    }

    if (model instanceof GroupModel || model instanceof RepeatableGroupModel) {
      if (model.isExpanded) {
        return null;
      }

      model.isExpanded = true;
      return firstValueFrom(model.afterExpand);
    }

    return null;
  }
}
