import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InvitationModel } from '../../model/invitation.model';

@Component({
  selector: 'app-einladung-list',
  templateUrl: './einladung-list.component.html',
  styleUrls: ['./einladung-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinladungListComponent {
  @Input() einladungen: InvitationModel[];

  @Output() acceptInvitation = new EventEmitter<{ groupId: number, invitationId: number }>();
  @Output() declineInvitation = new EventEmitter<{ groupId: number, invitationId: number }>();

  onAcceptInvitation(event): void {
    this.acceptInvitation.emit(event);
  }

  onDeclineInvitation(event): void {
    this.declineInvitation.emit(event);
  }
}
