import { Injectable } from '@angular/core';
import { FormType } from '../types/form-type';
import { SdkService } from './sdk.service';

@Injectable()
export class FormTypeService {
  constructor(private sdkService: SdkService) {}

  async getFormType(noticeId: string): Promise<FormType> {
    const noticeSubType = await this.sdkService.getNoticeSubType(noticeId);

    if (noticeSubType.documentType === 'PIN') {
      return FormType.PRIOR_INFORMATION;
    }

    if (noticeSubType.formType === 'dir-awa-pre') {
      return FormType.EXANTE;
    }

    if (noticeSubType.formType === 'result') {
      return FormType.EXPOST;
    }

    if (noticeSubType.formType === 'cont-modif') {
      return FormType.CONTRACT_MODIFICATION;
    }

    return FormType.NOTICE;
  }
}
