<mat-expansion-panel
  (closed)="panelOpenState = false"
  (opened)="panelOpenState = true"
  class="panel-wrapper"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="frage">
      {{frage}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
