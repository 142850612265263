<app-alert-message
  [severity]="Severity.ERROR"
  *ngFor="let message of errorMessages"
  class="alert-box">
  <p>
    <span>{{ message }}</span>
  </p>
</app-alert-message>
<app-alert-message
  [severity]="Severity.WARNING"
  *ngFor="let message of warningMessages"
  class="alert-box">
  <p>
    <span>{{ message }}</span>
  </p>
</app-alert-message>
<app-alert-message *ngFor="let message of infoMessages" class="alert-box">
  <p>
    <span>{{ message }}</span>
  </p>
</app-alert-message>
