import { Injectable } from '@angular/core';
import { FieldModel } from '../../view-model/field-model';
import { ModelStateService } from './model-state.service';
import { ConceptModel } from '../../types/concept-node';
import { BaseModel, ModelWithChildren } from '../../view-model/base-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { ExpressionEvaluationService } from '../expression/expression-evaluation.service';
import { RepeatableFieldModel } from '../../view-model/repeatable-field-model';
import { ConceptModelService } from '../concept-model.service';
import { ComponentModel } from '../../view-model/component-model';
import { ModelValidationService } from './model-validation.service';
import { PreferredReleaseDateService } from './preferred-release-date.service';

/**
 * Service for re-calculating the dynamic part of e-Forms properties.
 */
@Injectable()
export class DynamicModelPropertyService {
  constructor(
    private editorConfigurationProvider: EditorConfigurationProvider,
    private expressionEvaluationService: ExpressionEvaluationService,
    private conceptModelService: ConceptModelService,
    private modelValidationService: ModelValidationService,
    private preferredReleaseDateService: PreferredReleaseDateService
  ) {}

  /**
   * Sets the FieldModel Properties, based on their field.json properties and the current state of the Form.
   *
   * @param fieldModel model to update the properties in
   * @param conceptModel of the current form state
   * @return ChangeSet indicating which property on the model has been changed
   */
  public async updateProperties(
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel
  ): Promise<ChangeSet> {
    if (fieldModel instanceof RepeatableFieldModel) {
      // RepeatableFieldModels are just wrapper models. They need to be excluded from the property calculation, as they have no own representation inside the concept-model.
      // They only exist inside the view-model.
      return { forbiddenChanged: false, mandatoryChanged: false };
    }
    const wasForbidden = fieldModel.isForbidden;
    const wasMandatory = fieldModel.isMandatory;
    fieldModel.isForbidden = await this.isForbidden(fieldModel, conceptModel);
    fieldModel.isMandatory = await this.isMandatory(fieldModel, conceptModel);
    fieldModel.isHidden = this.isHidden(fieldModel);

    if (fieldModel.parent instanceof RepeatableFieldModel) {
      this.propagatePropsToParentRepeatableFieldModel(fieldModel);
    }
    return {
      forbiddenChanged: wasForbidden !== fieldModel.isForbidden,
      mandatoryChanged: wasMandatory !== fieldModel.isMandatory,
    };
  }

  /**
   * Gets called whenever the value of FieldModel has been changed.
   * This will search for other Models, which have a dependency onto this exact field, to then update their dynamic properties.
   *
   * @param fieldModel model which just has been changed
   * @param conceptModel based of the current form state
   * @return list of dependencies, where the forbidden or mandatory flag has changed-
   */
  public async updatePropsOfDependencies(
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel
  ): Promise<FieldModel<any>[]> {
    const modelsWithDependenciesOnChangedModel = ModelStateService.findModelsByDependencyId(
      fieldModel.root,
      fieldModel.noticeNode.id
    );

    const changedDependencySet: FieldModel<any>[] = [];
    for (const model of modelsWithDependenciesOnChangedModel) {
      if (model instanceof FieldModel) {
        const changeSet = await this.updateProperties(model, conceptModel);
        if (changeSet.mandatoryChanged || changeSet.forbiddenChanged) {
          if (model instanceof RepeatableFieldModel) {
            changedDependencySet.push(...model.children, model);
          } else {
            changedDependencySet.push(model);
          }
          model.emitChange();
        }
      }
    }
    return changedDependencySet;
  }

  /**
   * Gets called, whenever a child has been added or removed to/from a component.
   * When this happens, we need to recalculate all dynamic-properties of the fields which are dependent on fields in this added/removed subtree.
   */
  public async updateDependenciesOfFieldIds(fieldIdSet: Set<string>, root: ComponentModel) {
    const dependentFieldModels = ModelStateService.findFieldModelsByDependencyIds(root, fieldIdSet);

    const affectedModels: FieldModel<any>[] = [];
    // Calculate dynamic properties
    let conceptModel = await this.conceptModelService.generateConceptModel(root);
    for (const fieldModel of dependentFieldModels) {
      const changeset = await this.updateProperties(fieldModel, conceptModel);
      if (changeset.forbiddenChanged || changeset.mandatoryChanged) {
        affectedModels.push(fieldModel);
      }
    }

    if (affectedModels.length > 0) {
      conceptModel = await this.conceptModelService.generateConceptModel(root);
      for (const fieldModel of affectedModels) {
        await this.updatePropsOfDependencies(fieldModel, conceptModel);
      }

      const modelsToValidate: FieldModel<any>[] = affectedModels
        .map(model => this.expandRepeatableFieldChildren(model))
        .flat();

      await Promise.all(
        modelsToValidate
          .filter(model => model.validationNotifications.length > 0)
          .map(model => this.modelValidationService.validateModel(model, false, conceptModel))
      );
    }
  }

  public collectIdsInSubtree(baseModel: BaseModel, dependencySet: Set<string>) {
    if (ModelStateService.hasChildren(baseModel)) {
      (baseModel as ModelWithChildren).children.forEach((child: BaseModel) => {
        if (child instanceof FieldModel) {
          dependencySet.add(child.noticeNode.id);
        } else {
          this.collectIdsInSubtree(child, dependencySet);
        }
      });
    }
  }

  private expandRepeatableFieldChildren(model: FieldModel<any>): FieldModel<any>[] {
    if (model instanceof RepeatableFieldModel) {
      return model.children;
    }
    return [model];
  }

  private isHidden(fieldModel: FieldModel<any>): boolean {
    /**
     * If the user is not allowed to change the preferred release date, we can hide it because it's just a technical field.
     */
    if (
      fieldModel.noticeNode?.id === 'BT-738-notice' &&
      this.preferredReleaseDateService.isFixedToToday(fieldModel.root.noticeId)
    ) {
      return true;
    }

    /**
     * If the procedure gets updated after rejection, the release date gets updated internally
     * to today {@link ModelGenerationService#initValue} and does not have to be set by the user,
     * therefore the input is hidden.
     */
    if (
      this.editorConfigurationProvider.isUpdateAfterRejected() &&
      fieldModel.fieldInfo?.id?.includes('BT-738-notice')
    ) {
      return true;
    }

    return (
      (fieldModel.noticeNode._idScheme ? false : fieldModel.noticeNode.hidden) ||
      fieldModel.isForbidden
    );
  }

  /**
   * Checks if the Given Model should be Mandatory, based on the information the SDK provides.
   *
   * @param model to check
   * @param conceptModel conceptModel with current form state
   */
  private async isForbidden(model: BaseModel, conceptModel: ConceptModel): Promise<boolean> {
    if (!model.fieldInfo?.forbidden || !(model instanceof FieldModel<any>)) {
      return false;
    }

    return await this.expressionEvaluationService.evaluateFieldAsserts(
      model,
      model.fieldInfo.forbidden,
      conceptModel
    );
  }

  /**
   * Checks if the Given Model should be Mandatory, based on the information the SDK provides and state of the ConceptModel.
   *
   * @param model to check
   * @param conceptModel conceptModel of the current form state
   */
  private async isMandatory(model: FieldModel<any>, conceptModel: ConceptModel): Promise<boolean> {
    if (
      this.editorConfigurationProvider.isCancellation() &&
      model.noticeNode.id.startsWith('BT-144-LotResult')
    ) {
      return true;
    }

    if (!model.fieldInfo?.mandatory) {
      return false;
    }

    if (model.isForbidden) {
      return false;
    }

    return await this.expressionEvaluationService.evaluateFieldAsserts(
      model,
      model.fieldInfo.mandatory,
      conceptModel
    );
  }

  private propagatePropsToParentRepeatableFieldModel(childOfRepeatableFieldModel: FieldModel<any>) {
    const parent = childOfRepeatableFieldModel.parent as RepeatableFieldModel<any>;
    parent.isForbidden = childOfRepeatableFieldModel.isForbidden;
    parent.isHidden = childOfRepeatableFieldModel.isHidden;
    parent.isMandatory = childOfRepeatableFieldModel.isMandatory;
    parent.emitChange();
  }
}

interface ChangeSet {
  mandatoryChanged: boolean;
  forbiddenChanged: boolean;
}
