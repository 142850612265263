import { SdkService } from '../../services/sdk.service';
import { MeasureModel } from './measure-model';

export class OPP080Model extends MeasureModel {
  constructor(sdkService: SdkService) {
    super(sdkService);
    this.value = { amount: null, unit: 'km' }; // Vorbelegung
  }

  async loadSdkProperties(): Promise<void> {
    this._units = await Promise.resolve([{ id: 'km', label: 'Kilometer' }]);
  }
}
