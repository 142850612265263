<div class="wrapper">
  <br />
  <mat-card class="mid-panel">
    <mat-card-content class="mid-panel no-padding">
      <div class="options-wrapper">
        <div class="control-wrapper">
          <p>FormMode:</p>
          <select [formControl]="formModeControl">
            <option *ngFor="let option of formModeOptions" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>OperatorMode:</p>
          <select [formControl]="operatorModeControl">
            <option *ngFor="let option of operatorModeOptions" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Clientplatform:</p>
          <select [formControl]="clientPlatformControl">
            <option *ngFor="let option of clientPlatformOptions" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Cancellation:</p>
          <select [formControl]="cancellationControl">
            <option>false</option>
            <option>true</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Reset Procedure:</p>
          <select [formControl]="resetProcedureControl">
            <option>false</option>
            <option>true</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Angebotsfrist änderbar</p>
          <select [formControl]="offerDeadlineChangeAllowedControl">
            <option>true</option>
            <option>false</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Teilnahmefrist änderbar</p>
          <select [formControl]="participationDeadlineChangeAllowedControl">
            <option>true</option>
            <option>false</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Tendering-Procedure-ID:</p>
          <input [formControl]="tenderingProcedureIdControl" appInputNumber />
        </div>
        <div class="control-wrapper">
          <p>PublicationMode:</p>
          <select [formControl]="publicationModeControl">
            <option *ngFor="let option of publicationModeOptions" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Aktualisierung nach Ablehnung</p>
          <select [formControl]="updateAfterRejected">
            <option>true</option>
            <option>false</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Migration durch Change-Notice</p>
          <select [formControl]="isMigration">
            <option>true</option>
            <option>false</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Zeige Entwicklermodus</p>
          <select [formControl]="showDevMode">
            <option>true</option>
            <option>false</option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Unterstützung für Lose</p>
          <select [formControl]="lotSupportControl">
            <option *ngFor="let option of lotSupportOptions" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="control-wrapper">
          <p>Migriert von alter eForms Version</p>
          <select [formControl]="migratedFromEarlierEFormsVersionControl">
            <option>false</option>
            <option>true</option>
          </select>
        </div>
      </div>
      <p class="desc">
        Falls Sie die eForms-Erfassungsmaske in einem Browser aufgerufen haben, wählen Sie bitte
        eines der folgenden Verfahren aus:
      </p>
      <div *ngIf="!!noticeTypes" class="list-wrapper">
        <div
          *ngFor="let noticeSubType of noticeTypes.noticeSubTypes"
          class="notice-item"
          (click)="navigate(noticeSubType)">
          <div class="notice-item-id">{{ noticeSubType.subTypeId }}</div>
          <span *ngIf="isWithInitialData(noticeSubType.subTypeId)" class="notice-item-initial">
            MOCK DATA
          </span>
          <span *ngIf="!isWithInitialData(noticeSubType.subTypeId)" class="notice-item-empty">
            EMPTY
          </span>
          <div class="notice-item-label">{{ translate(noticeSubType._label) }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
