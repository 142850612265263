import { Component } from '@angular/core';
import version from '../../../../environments/version.json';
@Component({
  selector: 'app-build-info',
  templateUrl: './build-info.component.html',
  styleUrls: ['./build-info.component.scss'],
})
export class BuildInfoComponent {
  getGitHash() {
    return version.gitHash;
  }

  getVersion() {
    return version.version;
  }

  getTimestamp() {
    return version.timestamp;
  }

  getBuild() {
    return version.buildNumber;
  }
}
