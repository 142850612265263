<app-template [hasContentFrame]="true" [titel]="'Nutzungsbedingungen'">
  <section>
    <h3 style="font-weight: bold">§1 Präambel</h3>
    <ul>
      <li>Die Bundesrepublik Deutschland, vertreten durch das Beschaffungsamt des BMI, nachfolgend Betreiber genannt, stellt eine
        Internetplattform, nachfolgend Redaktionssystem oder Plattform genannt, zur Verfügung. Das Redaktionssystem bietet die Möglichkeit,
        Bekanntmachungen im XML-Format nach dem eForms-DE-Standard zu erstellen und zur Veröffentlichung an den Vermittlungsdienst des
        Datenservice öffentlicher Einkauf zu senden.
      </li>
      <li>Das Redaktionssystem richtet sich an Personen und Organisationen, die Bekanntmachungen im Oberschwellenbereich veröffentlichen.
        Dazu zählen:
      </li>
      <ul>
        <li>Vergabestellen der öffentlichen Verwaltung, <b>ohne einen Zugang zu einem an den Datenservice öffentlicher Einkauf angebundenen
          Vergabesystem.</b></li>
        <li>Auftraggeber, die gemäß § 98 GWB einer öffentlich-rechtlichen Einrichtung gleichgestellt sind.</li>
        <li>Zuwendungsempfänger</li>
        <ul>
          <li>durch Zuwendungen zur Projektförderung (Anlage 2 zur VV Nr. 5.1 zu § 44 BHO).</li>
          <li>durch Zuwendungen zur institutionellen Förderung (Anlage 1 zur VV Nr. 5.1 zu § 44 BHO).</li>
        </ul>
        <li>Dienstleister, die für Auftraggeber der öffentlichen Verwaltung oder Zuwendungsempfänger Bekanntmachungen über das
          Redaktionssystem einstellen möchten.
        </li>
      </ul>
    </ul>
  </section>

  <section>
    <h3 style="font-weight: bold">§2 Nutzungsverhältnis</h3>
    <ul>
      <li>Um das Redaktionssystem für die Erfassung und Veröffentlichung von Bekanntmachungen nutzen zu können, muss sich der Nutzer auf der
        Plattform unter Berücksichtigung der technischen und organisatorischen Voraussetzungen für die Nutzung gemäß den Informationen unter
        <a href="https://resy.datenservice-oeffentlicher-einkauf.de" target="_blank">www.resy.datenservice-oeffentlicher-einkauf.de</a>
        registrieren lassen.
      </li>
      <li>Unrichtige oder unvollständige Angaben sowie fehlende Erklärungen bei der Registrierung berechtigen den Betreiber zur Verweigerung
        der Registrierung bzw. zur sofortigen Beendigung des Nutzungsverhältnisses. Der Betreiber behält sich deshalb eine Kontrolle der im
        Rahmen der Registrierung übermittelten Angaben vor.
      </li>
      <li>Das Redaktionssystem dient lediglich der Erfassung und Veröffentlichung von Bekanntmachungen nach dem eForms-DE-Standard. Die
        Verantwortung für die ordnungsgemäße Durchführung des einzelnen Vergabeverfahrens obliegt ausschließlich dem Auftraggeber/der
        Vergabestelle. Das aufgrund der Teilnahme an einem Vergabeverfahren zustande kommende Rechtsverhältnis betrifft ausschließlich den
        jeweiligen Auftraggeber/Vergabestelle und die Teilnehmer am Vergabeverfahren.
      </li>
      <li>Die vom Betreiber bereitgestellte Plattform ist für die Nutzer kostenfrei.</li>
      <li>Der Betreiber ist berechtigt, die Plattform jederzeit an den jeweiligen Stand der Technik und die rechtlichen Erfordernisse
        anzupassen. Die Plattform kann hierzu vorübergehend außer Betrieb genommen werden. Die geplanten Zeiten für Wartungsarbeiten werden
        nach Möglichkeit rechtzeitig bekannt gegeben.
      </li>
      <li>Dem Nutzer erwachsen außerhalb der Gewährung der Rechte, die für eine bestimmungsgemäße Nutzung der Plattform nötig sind, keine
        weiteren Rechte. Sämtliche Urheber-, Namens-, Marken-, oder anderweitigen Schutzrechte bleiben dem Betreiber der Plattform bzw. dem
        jeweiligen Inhaber vorbehalten.
      </li>
      <li>Das Nutzungsverhältnis kann durch den Nutzer durch Deregistrierung beendet werden. Die Deregistrierung erfolgt über den Support
        unter der E-Mail-Adresse: <a href="mailto:resy-doe@bescha.bund.de">resy-doe@bescha.bund.de</a>. Die Deregistrierung kann erst
        erfolgen, sobald alle über das Nutzerkonto veröffentlichten Vergabeverfahren beendet sind. Eine gesonderte Kündigung seitens des
        Nutzers ist nicht erforderlich. Nach erfolgter Deregistrierung werden sämtliche erfassten Daten des Nutzers gelöscht, soweit sie
        keiner Archivierungspflicht im Rahmen eines Vergabeverfahrens unterliegen.
      </li>
      <li>Die Beendigung des Nutzungsverhältnisses hat keine Auswirkungen auf die Rechtspflichten des Nutzers aus einem gegebenenfalls über
        das Redaktionssystem initiierten Vergabeverfahren.
      </li>
    </ul>
  </section>

  <section>
    <h3 style="font-weight: bold">§3 Pflichten des Nutzers</h3>
    <ul>
      <li>Der Nutzer verpflichtet sich, innerhalb der zur Verfügung gestellten Funktionen keine rechtswidrigen, die Gesetze, behördlichen
        Auflagen oder Rechte Dritter verletzenden Inhalte zu verfassen.
      </li>
      <li>Der Betreiber ist zur Sperrung des betroffenen Inhalts oder zu Sperrung des Zugangs berechtigt, wenn der begründete Verdacht
        besteht, dass der gespeicherte Inhalt rechtswidrig ist und/oder Rechte Dritter verletzt. Ein begründeter Verdacht für eine
        Rechtswidrigkeit und/oder eine Rechtsverletzung liegt insbesondere dann vor, wenn Gerichte, Behörden und/oder sonstige Dritte den
        Betreiber davon in Kenntnis setzen. Die Sperre ist aufzuheben, sobald der Verdacht entkräftet ist.
      </li>
      <li>Der Nutzer verpflichtet sich, seinen Zugang zum Redaktionssystem gegen unbefugte Nutzung zu schützen.</li>
      <li>Der Nutzer ist selbst für die Eingabe und Pflege seiner zur Nutzung erforderlichen Daten und Informationen verantwortlich.</li>
      <li>Der Nutzer verpflichtet sich weiter, seine Daten und Informationen vor der Eingabe auf Viren oder sonstigen Schadcode zu prüfen
        und hierzu dem Stand der Technik entsprechende Maßnahmen vorzunehmen.
      </li>
      <li>Des Weiteren verpflichtet sich der Nutzer, keine fahrlässigen oder vorsätzlichen Aktivitäten zu ergreifen, die die
        Funktionsfähigkeit des Redaktionssystems beeinträchtigen.
      </li>
      <li>Eine nicht nur geringfügige Verletzung der Pflichten, denen der Nutzer innerhalb dieses Nutzungsverhältnisses unterliegt,
        berechtigt den Betreiber zur fristlosen Beendigung des Nutzungsverhältnisses.
      </li>
    </ul>
  </section>

  <section>
    <h3 style="font-weight: bold">§4 Leistungsstörungen</h3>
    <ul>
      <li>Der Betreiber hat keinen Einfluss auf die Datenübertragung im Internet. Er übernimmt keine Verantwortung für die Verfügbarkeit,
        Zuverlässigkeit und Qualität von Telekommunikationsnetzen, Datennetzen sowie technischen Einrichtungen Dritter. Leistungsstörungen
        auf Grund höherer Gewalt hat der Betreiber nicht zu vertreten.
      </li>
      <li>Der Betreiber kann die Nutzung des Redaktionssystems sperren oder den Zugang zu ihm beschränken, wenn die Plattform oder ihre
        elektronischen Einrichtungen technisch gestört oder überlastet sind bzw. eine solche Störung oder Überlastung droht. In diesem Falle
        ist der Betreiber bemüht, die vollständige Funktionsfähigkeit der Plattform umgehend wiederherzustellen.
      </li>
    </ul>
  </section>

  <section>
    <h3 style="font-weight: bold">§5 Geheimhaltung</h3>
    <ul>
      <li>Der Betreiber verpflichtet sich, über alle ihm im Rahmen dieses Nutzungsverhältnisses zur Kenntnis gelangten vertraulichen
        Vorgänge, insbesondere Geschäfts- oder Betriebsgeheimnisse des Nutzers, Stillschweigen zu bewahren und diese weder weiterzugeben
        noch auf sonstige Art zu verwerten. Dies gilt gegenüber jeglichen unbefugten Dritten, d.h. auch gegenüber unbefugten Mitarbeitern
        sowohl des Betreibers als auch des Nutzers, sofern die Weitergabe von Informationen nicht zur ordnungsgemäßen Erfüllung der des
        Nutzungsverhältnisses erforderlich ist.
      </li>
    </ul>
  </section>

  <section>
    <h3 style="font-weight: bold">§6 Haftung</h3>
    <ul>
      <li>
        Soweit sich aus den nachfolgenden Bestimmungen nichts anderes ergibt, sind weitergehende Haftungs- oder Gewährleistungsansprüche
        des Nutzers ausgeschlossen.
      </li>
      <li>Der Betreiber haftet nicht für die korrekte Funktion von Infrastrukturen oder Übertragungswegen des Internets, die nicht im
        Verantwortungsbereich des Betreibers oder seiner Vertreter bzw. Erfüllungsgehilfen liegen.
      </li>
      <li>Für den Verlust von Daten haftet der Betreiber nach Maßgabe der vorstehenden Absätze nur dann, wenn ein solcher Verlust durch
        angemessene Datensicherungs- und Datensicherheitsmaßnahmen seitens des Nutzers nicht vermeidbar gewesen wäre. Der Betreiber
        übernimmt keine Haftung für Schäden, die durch die Nutzung von Drittsystemen entstehen.
      </li>
      <li>Die Haftungsbeschränkungen gelten nicht für Ansprüche wegen Vorsatz und grober Fahrlässigkeit, bei der Verletzung des Lebens, des
        Körpers oder der Gesundheit, bei Arglist, soweit das Produkthaftungsgesetz zur Anwendung kommt sowie bei Garantieversprechen.
        Übernimmt der Betreiber für bestimmte Eigenschaften der vertraglich geschuldeten Leistung eine Garantie, so ist eine solche Garantie
        nur dann für den Betreiber verbindlich, wenn diese durch ihn schriftlich erklärt worden ist. Für leichte Fahrlässigkeit haftet der
        Betreiber nur, wenn eine der wesentlichen Pflichten des Nutzungsverhältnisses durch den ihn verletzt wurde. Der Betreiber haftet
        dabei nur für vorhersehbare Schäden, mit deren Entstehung typischerweise gerechnet werden muss. Wesentliche Pflichten des
        Nutzungsverhältnisses sind solche Pflichten, die die Grundlage des Verhältnisses bilden, die entscheidend für den Abschluss des
        Verhältnisses waren und auf deren Erfüllung der Nutzer vertrauen darf.
      </li>
    </ul>
  </section>

  <section>
    <h3 style="font-weight: bold">§7 Schlussbestimmungen</h3>
    <ul>
      <li>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Normen, die in eine andere Rechtsordnung verweisen, und
        unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG).
      </li>
      <li>Liegen die Voraussetzungen für eine Gerichtsstandsvereinbarung nach §§ 38, 40 ZPO vor, ist der Gerichtsstand, für alle sich aus
        dem Nutzungsverhältnis ergebenden Streitigkeiten, Bonn.
      </li>
      <li>Abweichende oder entgegenstehende Nutzungsbedingungen des Nutzers finden keine Anwendung. Dies gilt auch, wenn der Betreiber den
        Geschäftsbedingungen des Nutzers nicht ausdrücklich widerspricht.
      </li>
    </ul>
  </section>
</app-template>
