import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Severity } from '../../../../types/app-types';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMessageComponent {
  protected readonly Severity = Severity;

  @Input() severity: Severity;

  getSeverityClass(): string {
    switch (this.severity) {
      case Severity.ERROR:
        return 'alert-danger';
      case Severity.WARNING:
        return 'alert-warning';
      default:
        return 'alert-info';
    }
  }
}
