import { Component, OnDestroy, OnInit } from '@angular/core';
import { merge } from 'rxjs';
import { MultiLingualComponent } from '../../multi-lingual/multi-lingual.component';
import { Bt300BrDeKmuModel } from '../../../../view-model/type/bt300-br-de-kmu-model';
import { FormControl } from '@angular/forms';
import { FieldModel } from '../../../../view-model/field-model';
import { NoticeNode } from '../../../../types/notice-definition';

/**
 * This Component is a Wrapper Component for the BT-300-Lot Multilingual Component.
 * The German Standard states, that BT-300-Lot/BT-300-Part must begin with "#Besonders auch geeignet für:(startup/freelance/selbst/other-sme)#", when BT-726-Lot/BT-726-Part is set to true.
 * To spare the user the horrible experience of entering this by hand, this wrapper adds 4 checkboxes with these options.
 * The final string will be generated automatically in the background, to conform the german standard.
 */
@Component({
  selector: 'app-bt300br26de-kmuselector',
  templateUrl: './bt-300-br-26-de-kmu-selection.component.html',
  styleUrls: ['./bt-300-br-26-de-kmu-selection.component.scss'],
})
export class BT300BR26DeKmuSelectionComponent
  extends MultiLingualComponent
  implements OnInit, OnDestroy
{
  freelanceControl: FormControl;
  startupControl: FormControl;
  selbstControl: FormControl;
  otherSmeControl: FormControl;
  model: Bt300BrDeKmuModel;

  dummyHeaderModel: FieldModel<any>;

  ngOnInit(): void {
    this.freelanceControl = new FormControl(this.model.kmuValues.includes('freelance'));
    this.startupControl = new FormControl(this.model.kmuValues.includes('startup'));
    this.selbstControl = new FormControl(this.model.kmuValues.includes('selbst'));
    this.otherSmeControl = new FormControl(this.model.kmuValues.includes('other-sme'));
    merge(
      this.freelanceControl.valueChanges,
      this.startupControl.valueChanges,
      this.selbstControl.valueChanges,
      this.otherSmeControl.valueChanges
    ).subscribe(() => {
      const values = [];
      if (this.freelanceControl.value) {
        values.push('freelance');
      }
      if (this.startupControl.value) {
        values.push('startup');
      }
      if (this.selbstControl.value) {
        values.push('selbst');
      }
      if (this.otherSmeControl.value) {
        values.push('other-sme');
      }
      this.model.kmuValues = values;
      this.onInputValueChanged();
    });

    this.dummyHeaderModel = this.createDummyHeaderModel();

    this.initValue();
  }

  reset(): void {
    this.freelanceControl.setValue(false);
    this.startupControl.setValue(false);
    this.selbstControl.setValue(false);
    this.otherSmeControl.setValue(false);
  }

  private createDummyHeaderModel(): FieldModel<any> {
    const dummyModel = new FieldModel<any>();
    dummyModel.translatedLabel = 'Welche Art von KMU soll adressiert sein?';
    dummyModel.description = `Wenn im Feld "Diese Auftragsvergabe ist besonders geeignet für kleine und mittlere Unternehmen (KMU)" Ja ausgewählt ist, dann muss hier angegeben werden, welche Art von KMU addressiert sein sollen. Der hier ausgewählte Wert wird in Dokumenten im Feld "Zusätzliche Informationen" (${this.model.noticeNode.id}) dargestellt.`;
    dummyModel.noticeNode = { id: 'technical-br-26-de' } as NoticeNode;
    dummyModel.isMandatory = true;

    return dummyModel;
  }
}
