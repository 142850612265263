<div class="group-wrapper" [hidden]="model.isHidden">
  <mat-expansion-panel [expanded]="model.isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span [outerHTML]="getPanelTitle()"></span>
        <span *ngIf="supportMode" class="support-mode-label-right">{{ model.noticeNode.id }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      class="hint-wrapper hint-wrapper-group"
      *ngIf="model.tooltips && model.tooltips.length > 0">
      <div *ngFor="let tooltip of model.tooltips" class="hint">
        <mat-icon>info</mat-icon>
        <p>{{ tooltip }}</p>
      </div>
    </div>
    <div class="expansion-container">
      <ng-template view></ng-template>
    </div>
  </mat-expansion-panel>
</div>
