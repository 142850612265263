import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { StateService } from '../../../services/state.service';
import { FieldModel } from '../../../view-model/field-model';
import { IdModel } from '../../../view-model/type/id-model';

@Component({
  templateUrl: './id.component.html',
  styleUrls: ['./id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdComponent extends AbstractFormComponent implements OnInit, OnDestroy {
  inputControl: FormControl;
  model: IdModel | FieldModel<string>;
  placeholder = '';

  constructor(private stateService: StateService) {
    super();
    this.inputControl = new FormControl();
  }

  ngOnInit(): void {
    if (this.model.value) {
      this.inputControl.setValue(this.model.value);
    }

    if (this.model.placeholder) {
      this.placeholder = this.model.placeholder;
    }

    if (this.model.isReadonly) {
      this.inputControl.disable();
    }

    this.subscription.add(
      this.stateService.getDevMode().subscribe(devMode => {
        if (['BT-04-notice', 'BT-701-notice'].includes(this.model.noticeNode.id)) {
          if (devMode) {
            this.inputControl.enable();
          } else {
            this.inputControl.disable();
          }
          this.cdRef.markForCheck();
        }
      })
    );
  }

  commitValue(): void {
    this.model.value = this.inputControl.value;
    this.onInputValueChanged();
  }

  protected onModelChange() {
    const valueChanged = this.model.value !== this.inputControl.value;
    this.setValue();

    if (valueChanged) {
      this.onInputValueChanged();
    }
    super.onModelChange();
  }

  private setValue() {
    this.inputControl.setValue(this.model.value);
  }
}
