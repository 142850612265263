<div class="field-header">
  <label>{{ model.translatedLabel }}<span [appMarkAsterisk]="model.isMandatory"></span></label>
  <span class="support-mode-label">{{ supportMode ? model.noticeNode.id : '' }} </span>
  <app-validation-status [validationResults]="model.businessRulesResult"></app-validation-status>
</div>
<div>
  <p class="description" *ngIf="model.showDescription && model.description">
    {{ model.description }}
  </p>
  <div class="hint-wrapper" *ngIf="model.tooltips && model.tooltips.length > 0">
    <div *ngFor="let tooltip of model.tooltips" class="hint">
      <mat-icon>info</mat-icon>
      <p>{{ tooltip }}</p>
    </div>
  </div>

  <!-- ReSy: Zeige Tooltip-Komponente fuer ausgewahelte Feldern an, wenn die Maske in der Vorlagenfunktion (Template) geoeffnet ist -->
  <app-rs-template-tooltips
    *ngIf="isTemplate"
    [btId]="model.noticeNode.id"></app-rs-template-tooltips>
</div>
