import { createReducer, on } from '@ngrx/store';
import { DisplayMode } from '../../types/display-mode';
import * as RsDisplaymodeActions from './rs-displaymode.action';

export const initialState = DisplayMode.DETAIL;

/**
 * Reducer for the Modified Form State
 */
export const rsDisplaymodeReducer = createReducer(
  initialState,
  on(RsDisplaymodeActions.set, (_state, props) => props.displayMode)
);
