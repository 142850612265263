import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { DateModel } from '../../../../view-model/type/date-model';
import { ModelStateService } from '../../../view-model/model-state.service';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';
import { FieldInfo } from '../../../../types/field-types';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';

export class DeadlineAfterPreferredPublicationDateValidator extends EVergabeSpecificValidator<
  FieldModel<string>
> {
  private static readonly PARTICIPATION_DEADLINE_BT_ID = 'BT-1311(d)-Lot';
  private static readonly PARTICIPATION_DEADLINE_NOT_AFTER_PREFERRED_PUBLICATION_DATE_MSG =
    'Das Datum der Frist für den Eingang der Teilnahmeanträge muss nach dem bevorzugten Veröffentlichungsdatum liegen.';

  private static readonly PROVISIONAL_OFFER_DEADLINE_BT_ID = OFFER_DEADLINE_DATE_ID;
  private static readonly OFFER_DEADLINE_LOT_BT_ID = 'BT-131(d)-Lot';
  private static readonly PROVISIONAL_OFFER_DEADLINE_NOT_AFTER_PREFERRED_PUBLICATION_DATE_MSG =
    'Das Datum der vorläufigen Angebotsfrist muss nach dem bevorzugten Veröffentlichungsdatum liegen.';

  private static readonly PREFERRED_PUBLICATION_DATE_BT_ID = 'BT-738-notice';

  constructor(private editorConfigurationProvider: EditorConfigurationProvider) {
    super();
  }

  shouldAttach(model: FieldModel<string>): boolean {
    if (
      this.isParticipationDeadline(model.noticeNode.id) || this.isOfferDeadline(model.noticeNode.id)
    ) {
      this.addDependencies(model.fieldInfo);
      return true;
    }
    return false;
  }

  async validate(deadlineModel: DateModel): Promise<ValidationNotification[]> {
    if (deadlineModel.isReadonly) {
      return this.empty();
    }

    const preferredPublicationDateModel = ModelStateService.findModelById(
      deadlineModel.root,
      DeadlineAfterPreferredPublicationDateValidator.PREFERRED_PUBLICATION_DATE_BT_ID
    ) as DateModel;

    if (this.isNotADayOrMoreAfter(deadlineModel, preferredPublicationDateModel)) {
      if (
        this.isInTrainingMode() &&
        this.isDeadlineOnSameDateButAfterPreferredPublicationDate(
          deadlineModel,
          preferredPublicationDateModel
        )
      ) {
        return this.ofSingle(this.getMessage(deadlineModel.noticeNode.id), Severity.WARNING);
      } else {
        return this.ofSingle(this.getMessage(deadlineModel.noticeNode.id), Severity.ERROR);
      }
    }

    return this.empty();
  }

  private isNotADayOrMoreAfter(deadlineModel: DateModel, preferredPublicationDateModel: DateModel) {
    return (
      deadlineModel.value &&
      preferredPublicationDateModel.value &&
      !deadlineModel.asLocalDate().isAfter(preferredPublicationDateModel.asLocalDate())
    );
  }

  private isInTrainingMode() {
    return this.editorConfigurationProvider.isTrainingMode();
  }

  private isDeadlineOnSameDateButAfterPreferredPublicationDate(
    deadlineModel: DateModel,
    preferredPublicationDateModel: DateModel
  ): boolean {
    return (
      deadlineModel.asLocalDate().isEqual(preferredPublicationDateModel.asLocalDate()) &&
      deadlineModel.withRelatedTime().isAfter(preferredPublicationDateModel.withRelatedTime())
    );
  }

  private addDependencies(fieldInfo: FieldInfo) {
    if (
      !fieldInfo.dependencyFields?.includes(
        DeadlineAfterPreferredPublicationDateValidator.PREFERRED_PUBLICATION_DATE_BT_ID
      )
    ) {
      fieldInfo.dependencyFields.push(
        DeadlineAfterPreferredPublicationDateValidator.PREFERRED_PUBLICATION_DATE_BT_ID
      );
    }
  }

  private getMessage(btId: string) {
    if (
      this.isParticipationDeadline(btId)
    ) {
      return DeadlineAfterPreferredPublicationDateValidator.PARTICIPATION_DEADLINE_NOT_AFTER_PREFERRED_PUBLICATION_DATE_MSG;
    }
    if (
      this.isOfferDeadline(btId)
    ) {
      return DeadlineAfterPreferredPublicationDateValidator.PROVISIONAL_OFFER_DEADLINE_NOT_AFTER_PREFERRED_PUBLICATION_DATE_MSG;
    }
  }

  private isParticipationDeadline(btId: string): boolean {
    return btId === DeadlineAfterPreferredPublicationDateValidator.PARTICIPATION_DEADLINE_BT_ID;
  }

  private isOfferDeadline(btId: string): boolean {
    return btId === DeadlineAfterPreferredPublicationDateValidator.PROVISIONAL_OFFER_DEADLINE_BT_ID
      || btId === DeadlineAfterPreferredPublicationDateValidator.OFFER_DEADLINE_LOT_BT_ID
  }
}
