import { EVergabeSpecificValidator } from '../ModelValidator';
import { DateModel } from '../../../../view-model/type/date-model';
import { DateUtilsService } from '../../../../utils/date-utils-service';
import { ValidationNotification } from '../../../../types/app-types';

export class PreferredPublicationDateNotInPastValidator extends EVergabeSpecificValidator<DateModel> {
  private static readonly PREFERRED_PUBLICATION_DATE_ID = 'BT-738-notice';

  private static readonly DEADLINE_IN_PAST_ERROR_MSG =
    'Der angegebene Zeitpunkt darf nicht in der Vergangenheit liegen.';

  constructor(private dateUtils: DateUtilsService) {
    super();
  }

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    if (
      !dateModel.isReadonly &&
      dateModel.value &&
      dateModel.asLocalDate().isBefore(this.dateUtils.getToday())
    ) {
      return this.ofSingle(PreferredPublicationDateNotInPastValidator.DEADLINE_IN_PAST_ERROR_MSG);
    }
    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return (
      model.noticeNode.id ===
      PreferredPublicationDateNotInPastValidator.PREFERRED_PUBLICATION_DATE_ID
    );
  }
}
