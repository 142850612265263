import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTimeFormatter, DayOfWeek, LocalDate, Month, nativeJs, ZoneId } from '@js-joda/core';
import { Locale } from '@js-joda/locale_de-de';
import '@js-joda/timezone';

export const JS_JODA_DATE_FORMATS = {
  parse: {
    dateInput: ['d.M.uuuu', 'd/M/uuuu'],
  },
  display: {
    dateInput: 'dd/MM/uuuu',
    monthYearLabel: 'MMMM uuuu',
    dateA11yLabel: 'dd/MM/uuuu',
    monthYearA11yLabel: 'MMMM uuuu',
  },
};

/** Configurable options for the `JsJodaDateAdapter`. */
export interface JsJodaDateAdapterOptions {
  /**
   * Sets the first day of week.
   * Changing this will change how Angular Material DatePicker shows start of week.
   */
  firstDayOfWeek: number;

  /**
   * Sets the Locale.
   * Changing this will change how Angular Material DatePicker output dates.
   */
  locale: Locale;
}

/** InjectionToken for js-joda to configure options. */
export const JS_JODA_DATE_ADAPTER_OPTIONS = new InjectionToken<JsJodaDateAdapterOptions>(
  'JS_JODA_DATE_ADAPTER_OPTIONS',
  {
    providedIn: 'root',
    factory: (): JsJodaDateAdapterOptions => ({
      firstDayOfWeek: 1,
      locale: Locale.GERMANY,
    }),
  }
);

/** Creates an array and fills it with values. */
const range = <T>(length: number, valueFunction: (index: number) => T): T[] => {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
};

/** Adapts js-joda date for use with Angular-Material-Datepicker. */
@Injectable({
  providedIn: 'root',
})
export class JsJodaDateAdapter extends DateAdapter<LocalDate> {
  private static readonly ZONE_ID = ZoneId.of('Europe/Berlin');

  private readonly _firstDayOfWeek: number;
  private readonly _locale: Locale;

  private _localeData: {
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
  };

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(JS_JODA_DATE_ADAPTER_OPTIONS) options?: JsJodaDateAdapterOptions
  ) {
    super();
    this._firstDayOfWeek = options?.firstDayOfWeek || 1;
    this._locale = options?.locale || Locale.GERMANY;
    this.setLocale(dateLocale || 'de-DE');
  }

  override setLocale(locale: string) {
    super.setLocale(locale);

    const months = Month.values();

    //  shift the array once, because js-joda returns monday as the first day of the week,
    //  whereas angular material datepicker assumes that it's sunday
    const daysOfWeeks = DayOfWeek.values();
    daysOfWeeks.unshift(daysOfWeeks.pop());

    const longMonthFormatter = DateTimeFormatter.ofPattern('MMMM').withLocale(this._locale);
    const shortMonthFormatter = DateTimeFormatter.ofPattern('MMM').withLocale(this._locale);
    const dateFormatter = DateTimeFormatter.ofPattern('d').withLocale(this._locale);
    const longDayOfWeekFormatter = DateTimeFormatter.ofPattern('eeee').withLocale(this._locale);
    const shortDayOfWeekFormatter = DateTimeFormatter.ofPattern('eee').withLocale(this._locale);

    this._localeData = {
      longMonths: months.map(month => longMonthFormatter.format(month)),
      shortMonths: months.map(month => shortMonthFormatter.format(month)),
      dates: range(31, i => this.createDate(2017, 0, i + 1).format(dateFormatter)),
      longDaysOfWeek: daysOfWeeks.map(month => longDayOfWeekFormatter.format(month)),
      shortDaysOfWeek: daysOfWeeks.map(month => shortDayOfWeekFormatter.format(month)),
    };
  }

  getYear(date: LocalDate): number {
    return date.year();
  }

  getMonth(date: LocalDate): number {
    // js-joda works with 1-indexed months, whereas angular-material-datepicker expects 0-indexed.
    return date.month().value() - 1;
  }

  getDate(date: LocalDate): number {
    return date.dayOfMonth();
  }

  getDayOfWeek(date: LocalDate): number {
    return date.dayOfWeek().value();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this._localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long' ? this._localeData.longDaysOfWeek : this._localeData.shortDaysOfWeek;
  }

  getYearName(date: LocalDate): string {
    return date.format(DateTimeFormatter.ofPattern('uuuu').withLocale(this._locale));
  }

  getFirstDayOfWeek(): number {
    return this._firstDayOfWeek;
  }

  getNumDaysInMonth(date: LocalDate): number {
    return date.lengthOfMonth();
  }

  clone(date: LocalDate): LocalDate {
    return LocalDate.from(date);
  }

  createDate(year: number, month: number, date: number): LocalDate {
    // js-joda uses 1-indexed months, so we need to add one to the month for angular-material-datepicker.
    return LocalDate.of(year, month + 1, date);
  }

  today(): LocalDate {
    return LocalDate.now(JsJodaDateAdapter.ZONE_ID);
  }

  parse(value: any, parseFormat: any): LocalDate {
    if (value && typeof value == 'string') {
      for (const currentFormat of parseFormat) {
        try {
          return LocalDate.parse(
            value,
            DateTimeFormatter.ofPattern(currentFormat).withLocale(this._locale)
          );
        } catch (e) {
          // catch parsing exception, so that all formats are tried out
        }
      }
    }
    return null;
  }

  format(date: LocalDate, displayFormat: any): string {
    return DateTimeFormatter.ofPattern(displayFormat).withLocale(this._locale).format(date);
  }

  addCalendarYears(date: LocalDate, years: number): LocalDate {
    return date.plusYears(years);
  }

  addCalendarMonths(date: LocalDate, months: number): LocalDate {
    return date.plusMonths(months);
  }

  addCalendarDays(date: LocalDate, days: number): LocalDate {
    return date.plusDays(days);
  }

  toIso8601(date: LocalDate): string {
    return DateTimeFormatter.ISO_LOCAL_DATE.withLocale(this._locale).format(date);
  }

  /**
   * Returns the LocalDate, if a LocalDate is given
   * or converts a given date object into a LocalDate
   * or deserializes a valid ISO 8601 string into a LocalDate
   * or an empty string into null.
   */
  override deserialize(value: any): LocalDate | null {
    if (this.isDateInstance(value)) {
      return value;
    } else if (value instanceof Date) {
      return nativeJs(value).withZoneSameInstant(JsJodaDateAdapter.ZONE_ID).toLocalDate();
    } else if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      return LocalDate.parse(value, DateTimeFormatter.ISO_LOCAL_DATE.withLocale(this._locale));
    }

    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof LocalDate;
  }

  isValid(date: LocalDate): boolean {
    // An existing instance in js-joda is always valid.
    return true;
  }

  invalid(): LocalDate {
    // An existing instance in js-joda is always valid.
    return undefined;
  }
}
