/**
 * Swagger User-Service - OpenAPI 3.0
 * Ein Service zum verwalten von User-Einstellungen im Redaktionssystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GroupMemberRole = 'OWNER' | 'MEMBER' | 'INVITED';

export const GroupMemberRole = {
    Owner: 'OWNER' as GroupMemberRole,
    Member: 'MEMBER' as GroupMemberRole,
    Invited: 'INVITED' as GroupMemberRole
};

