import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})

export class SortUtilsService {
  public sortCodeListItems<T extends { id: string; label: string }>(a: T, b: T): number {
    // item "none" at the top
    if (a.id === 'none') return -1;
    if (b.id === 'none') return 1;

    // "oth-" after "other"
    if (a.id.startsWith('oth-')) return 1;
    if (b.id.startsWith('oth-')) return -1;

    // "other" at the end
    if (a.id === 'other') return 1;
    if (b.id === 'other') return -1;

    return a.label.localeCompare(b.label, 'de', { sensitivity: 'base' });
  }
}
