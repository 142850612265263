import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap, take, finalize } from 'rxjs/operators';
import { NoticeControllerService } from '../portal/api/notices';
import { ValidationReportDialogService } from '../portal/shared/validation-report-dialog/validation-report-dialog.service';
import { ConceptModel } from '../types/concept-node';
import { EditorConfigurationProvider } from './editor-configuration.provider';
import { LoadingService } from './loading.service';
import { ConceptModelUtils } from './parser/concept-model-utils';
import { RsEditorConfigurationProvider } from './rs-editor-configuration.provider';
import { StateService } from './state.service';
import { GenericDialogService } from './ui/generic-dialog.service';
import { ComponentModel } from '../view-model/component-model';
import { ConceptModelService } from './concept-model.service';

@Injectable()
export class RsNoticeSubmittingServiceV2 {
  constructor(
    private noticeService: NoticeControllerService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private rsConfig: RsEditorConfigurationProvider,
    private stateService: StateService,
    private genericDialogService: GenericDialogService,
    private loadingService: LoadingService,
    private conceptModelService: ConceptModelService,
    private validationReportDialogService: ValidationReportDialogService
  ) {}

  public save(conceptModel: ConceptModel, showSuccessMessage: boolean): Observable<number> {
    const rsNoticeId = this.rsConfig.getRsNoticeId();
    const baseRsNoticeId = this.rsConfig.getBaseRsNoticeId();

    const dto = { metadata: null, content: JSON.parse(ConceptModelUtils.serialize(conceptModel)) };

    let savedNoticeId: Observable<number>;
    this.loadingService.setLoading(true);
    if (rsNoticeId) {
      savedNoticeId = this.stateService.getNoticeLastEdited().pipe(
        take(1),
        filter(headerMap => headerMap.has(rsNoticeId)),
        map(headerMap => `${rsNoticeId}=${headerMap.get(rsNoticeId)}`),
        switchMap(headerOfNotice =>
          this.noticeService
            .saveNoticeDraft(rsNoticeId, headerOfNotice, dto)
            .pipe(map(() => rsNoticeId))
        )
      );
    } else {
      if (baseRsNoticeId) {
        if (this.editorConfigurationProvider.isChangeNotice()) {
          savedNoticeId = this.noticeService.createChangeNoticeForNotice(baseRsNoticeId, dto);
        } else if (
          this.editorConfigurationProvider.isAlterBeforeRelease() ||
          // ReSy: Pruefung auf AlterUpdateBeforeRelease
          this.editorConfigurationProvider.isAlterUpdateBeforeRelease()
        ) {
          savedNoticeId = this.noticeService.createUpdateForNotice(baseRsNoticeId, dto);
        } else {
          savedNoticeId = this.noticeService.createSuccessorForNotice(baseRsNoticeId, dto);
        }
      } else {
        savedNoticeId = this.noticeService.createNoticeForNewProcedure(dto);
      }
    }

    return savedNoticeId.pipe(
      tap(() => {
        if (showSuccessMessage) {
          this.showSuccessDialog(false);
        }
      }),
      finalize(() => this.loadingService.setLoading(false))
    );
  }

  public validateComponentModel(componentModel: ComponentModel): Promise<boolean> {
    return this.conceptModelService
      .generateConceptModel(componentModel)
      .then(conceptModel => firstValueFrom(this.validateConceptModel(conceptModel)));
  }

  public send(rsNoticeId: number): Observable<number> {
    this.loadingService.setLoading(true);
    return this.noticeService.sendNoticeToVeDi(rsNoticeId).pipe(
      tap(() => this.showSuccessDialog(true)),
      finalize(() => this.loadingService.setLoading(false))
    );
  }

  public loadData(noticeSubTypeId: string): Observable<ConceptModel> {
    const rsNoticeId = this.rsConfig.getRsNoticeId();
    const baseRsNoticeId = this.rsConfig.getBaseRsNoticeId();

    let answerAsJSON: Observable<string> = null;
    if (rsNoticeId) {
      answerAsJSON = this.noticeService.getNoticeFormValueForSavedNotice(rsNoticeId);
    } else {
      if (baseRsNoticeId) {
        if (this.editorConfigurationProvider.isChangeNotice()) {
          answerAsJSON = this.noticeService.getFormValueForChangeNotice(baseRsNoticeId);
        } else if (
          this.editorConfigurationProvider.isAlterBeforeRelease() ||
          // ReSy: Pruefung auf AlterUpdateBeforeRelease
          this.editorConfigurationProvider.isAlterUpdateBeforeRelease()
        ) {
          answerAsJSON = this.noticeService.getNoticeFormValueForUpdate(baseRsNoticeId);
        } else {
          answerAsJSON = this.noticeService.getFormValueForSuccessor(
            baseRsNoticeId,
            noticeSubTypeId
          );
        }
      } else {
        // TODO Endpunkt im BE entfernen?
        // answerAsJSON = this.noticeService.getNoticeFormValueForNewProcedure(noticeSubTypeId);
        return of(null);
      }
    }

    return answerAsJSON.pipe(map(json => json as unknown as ConceptModel));
  }

  public async confirmSubmit() {
    return this.genericDialogService.confirmWithDialog(
      'Anlage und Senden an den Vermittlungsdienst',
      'Anlegen und senden',
      'Abbrechen',
      'Bei Bestätigung wird die Bekanntmachung angelegt und gesendet. Bei Abbruch können Sie das Formular weiter bearbeiten.'
    );
  }

  private validateConceptModel(conceptModel: ConceptModel): Observable<boolean> {
    return this.noticeService
      .validateNotice(JSON.parse(ConceptModelUtils.serialize(conceptModel)))
      .pipe(
        map(response => {
          if (response?.xsdValidationErrors.length || response?.schematronValidationErrors.length) {
            this.validationReportDialogService.showValidationReportDialog({ report: response });
            return false;
          }
          return true;
        }),
        tap(valid => this.stateService.changeValidity(valid))
      );
  }

  private async showSuccessDialog(wasSend: boolean) {
    const content = wasSend ? 'Erfolgreich gespeichert und versendet.' : 'Die Bekanntmachung wurde erfolgreich gespeichert.';
    await this.genericDialogService.showResultDialog(
      `Aktion erfolgreich`,
      content,
      'Schließen',
      true
    );
  }
}
