import { DateUtilsService } from '../../../../utils/date-utils-service';
import { DateModel } from '../../../../view-model/type/date-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { EVergabeSpecificValidator } from '../ModelValidator';

export class FiveDaysInFutureWarningValidator extends EVergabeSpecificValidator<DateModel> {
  private static readonly NOT_FIVE_DAYS_IN_FUTURE_WARNING = `Der angegebene Zeitpunkt sollte mindestens fünf Tage in der Zukunft liegen, damit er nach der EU-weiten Veröffentlichung endet, deren Bearbeitungszeit maximal fünf Kalendertage in Anspruch nehmen kann.`;

  private euDeadlines = ['BT-131(d)-Lot', 'BT-1311(d)-Lot'];

  constructor(private dateUtils: DateUtilsService) {
    super();
  }

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    const inFiveDays = this.dateUtils.getToday().plusDays(5);

    if (dateModel.value && dateModel.asLocalDate().isBefore(inFiveDays)) {
      return this.ofSingle(
        FiveDaysInFutureWarningValidator.NOT_FIVE_DAYS_IN_FUTURE_WARNING,
        Severity.WARNING
      );
    }

    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return (
      model instanceof DateModel &&
      this.euDeadlines.some(bt => bt === model.noticeNode?.id) &&
      !model.isReadonly
    );
  }
}
