import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const FadeInOutAnimations = [
  trigger('fadeInOut', [
    state(
      'in',
      style({
        opacity: '1',
      })
    ),
    state(
      'out',
      style({
        opacity: '0.5',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '25ms ease-in-out',
          style({
            opacity: '0.5',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '25ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
  trigger('inOutAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('25ms ease-out', style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate('25ms ease-in', style({ opacity: 0 }))]),
  ]),
];
