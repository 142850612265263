import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormInfo, FormTitleService } from '../../../services/ui/form-title.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-info',
  templateUrl: './form-info.component.html',
  styleUrls: ['./form-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormInfoComponent implements OnInit {
  formInfo$: Observable<FormInfo>;

  constructor(private formTitleService: FormTitleService) {}

  ngOnInit(): void {
    this.formInfo$ = this.formTitleService.getFormInfo();
  }
}
