import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TrimAndMapEmptyToNullDirective } from '../trim/trim-and-map-empty-to-null.directive';
import { Subscription } from 'rxjs';

/**
 * Defines the MaxLengthBadge-Directive.
 */
@Directive({
  selector: '[appMaxLengthBadge]',
})
/**
 * MaxLengthBadge-Class.
 */
export class MaxLengthBadgeDirective implements OnInit, OnDestroy {
  @Input('appMaxLengthBadge') maxLength;
  @Output() maxLengthExceeded = new EventEmitter<boolean>();

  private _badge;
  private _invalid = false;

  private readonly subscriptions = new Subscription();

  /**
   * @param _elRef
   *   An ElementRef object instance of the host.
   * @param _renderer
   *   A Renderer2 object instance.
   * @param ngControl
   *   A reference to the ngControl object instance of the host, if present.
   * @param trimAndMapEmptyToNullDirective
   *   A reference to the TrimAndMapEmptyToNullDirective of the host, if present.
   */
  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    @Optional() private ngControl?: NgControl,
    @Optional() private trimAndMapEmptyToNullDirective?: TrimAndMapEmptyToNullDirective
  ) {}

  /**
   * Implements the OnInit Angular lifecycle hook.
   */
  ngOnInit(): void {
    if (this.maxLength > 0) {
      this.createBadge(this.maxLength);

      if (this.ngControl) {

        this.subscriptions.add(this.ngControl.valueChanges.subscribe(this.onFormControlInput.bind(this)))
        if(this.ngControl.value){
          this.onInput({ value: this.ngControl.value });
        }
      }

      this.subscriptions.add(
        this.trimAndMapEmptyToNullDirective?.ngModelChange.subscribe(value => {
          this.onInput({ value });
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Handles input events from the host component.
   */
  @HostListener('input', ['$event.target'])
  onInput(target: any) {
    this.updateBadgeCounter(target.value);
  }

  /**
   * React to updates from the FormControl
   */
  onFormControlInput(newValue: string) {
    this.updateBadgeCounter(newValue);
  }

  updateBadgeCounter(text: string){
    if (this._badge && text) {
      const count = text.length;

      // When the field on the host is updated, update the badge display.
      this._renderer.setProperty(this._badge, 'innerText', (this.maxLength - count).toString());

      // Case: The count exceeds the limit and the previous status was valid.
      if (count > this.maxLength && !this._invalid) {
        this._invalid = true;
        // Set the colour of the badge to red and inform the host element about the status change to invalid.
        this._renderer.addClass(this._badge, 'input-maxlength-badge--invalid');
        this.maxLengthExceeded.emit(true);
      }

      // Case: The count does not exceed the limit and the previous status was invalid.
      if (count <= this.maxLength && this._invalid) {
        this._invalid = false;
        // Set the colour of the badge back to normal and inform the host element about the status change to valid.
        this._renderer.removeClass(this._badge, 'input-maxlength-badge--invalid');
        this.maxLengthExceeded.emit(false);
      }
    }
  }

  /**
   * Creates the badge for the host element.
   */
  private createBadge(value: string) {
    this._badge = this._renderer.createElement('span');

    this._renderer.addClass(this._badge, 'input-maxlength-badge');
    this._renderer.setProperty(this._badge, 'innerText', value);

    const wrapper = this.createWrapper();

    this._renderer.appendChild(wrapper, this._badge);
  }

  /**
   * Creates a wrapper for the host and badge alignment.
   */
  private createWrapper() {
    const wrapper = this._renderer.createElement('div');

    this._renderer.addClass(wrapper, 'input-maxlength-badge-wrapper');

    this._renderer
      .parentNode(this._elRef.nativeElement)
      .replaceChild(wrapper, this._elRef.nativeElement);

    this._renderer.appendChild(wrapper, this._elRef.nativeElement);

    return wrapper;
  }
}
