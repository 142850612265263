import { Injectable } from '@angular/core';
import { NoticeDefinition, NoticeNode } from '../../types/notice-definition';

@Injectable({
  providedIn: 'root',
})
export class CaptionService {
  private captionFieldIdForFieldIdMap: Map<string, string> = new Map<string, string>();

  public init(noticeDefinition: NoticeDefinition): void {
    this.addContent(noticeDefinition.content);
  }

  public getTargetFieldId(fieldId: string): string {
    return this.captionFieldIdForFieldIdMap.get(fieldId);
  }

  public isCaptionForAnyField(id: string): boolean {
    return this.captionFieldIdForFieldIdMap.has(id);
  }

  private addContent(content: NoticeNode[]): void {
    for (const node of content) {
      if (node._captionFieldId) {
        this.captionFieldIdForFieldIdMap.set(node._captionFieldId, node.id);
      }

      if (node.content) {
        this.addContent(node.content);
      }
    }
  }
}
