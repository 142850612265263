import { FieldModel } from '../field-model';

export class IdRefModel extends FieldModel<string> {
  get value(): string {
    return super.value;
  }

  set value(value: string) {
    super.value = value;
  }
}
