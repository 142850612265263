import { Injectable } from '@angular/core';
import { HistoryNoticeOverviewDto, NoticeOverviewDto } from '../../api/notices';
import { NoticeOverviewViewModel } from '../NoticeOverviewViewModel';

@Injectable({
  providedIn: 'root',
})
export class CreateHistoryService {
  create(data: NoticeOverviewDto[]): NoticeOverviewViewModel[] {
    return data
      .map(firstLevelNotice => [
        this.mapToOverviewViewModel(firstLevelNotice),
        ...firstLevelNotice.history.map(notice =>
          this.mapToOverviewViewModel(notice, firstLevelNotice)
        ),
      ])
      .flat();
  }

  private mapToOverviewViewModel(
    notice: NoticeOverviewDto | HistoryNoticeOverviewDto,
    parent: NoticeOverviewDto = null
  ): NoticeOverviewViewModel {
    return {
      notice: this.omitHistory(notice),
      parent: parent
        ? {
            lastEdited: parent.lastEdited,
            dseStatus: parent.dseStatus,
            tedStatus: parent.tedStatus,
          }
        : null,
      isNewestNoticeForSubtypeId: !parent,
      showHistory: false,
      hasHistory: this.hasHistory(notice) ? notice.history.length > 0 : false,
      isVisible: !parent,
      historyIds: this.hasHistory(notice) ? notice.history.map(n => n.rsNoticeId) : [],
    };
  }

  private omitHistory(
    notice: NoticeOverviewDto | HistoryNoticeOverviewDto
  ): HistoryNoticeOverviewDto {
    if (this.hasHistory(notice)) {
      const { history, ...noticeWithoutHistory } = notice;
      return noticeWithoutHistory;
    }
    return notice;
  }

  private hasHistory(
    notice: NoticeOverviewDto | HistoryNoticeOverviewDto
  ): notice is NoticeOverviewDto {
    return 'history' in notice;
  }
}
