<div *ngIf="devmode" class="validation-status-wrapper">
  <div
    class="validation-status-item icon-check-circle"
    (click)="print(succesfulValidations)"
    matTooltip="Erfolgreiche Validierungen"
    matTooltipPosition="above"
    aria-label="Erfolgreiche Validierungen">
    <mat-icon class="center-icon width-fit-content">check_circle </mat-icon>
    <span>{{ succesfulValidations.length }}</span>
  </div>
  <div
    class="validation-status-item icon-exclamation"
    (click)="print(failedValidations)"
    matTooltip="Fehlgeschlagene Validierungen"
    matTooltipPosition="above"
    aria-label="Fehlgeschlagene Validierungen">
    <mat-icon class="center-icon width-fit-content font-weight-bold">! </mat-icon>
    <span>{{ failedValidations.length }}</span>
  </div>
  <div
    class="validation-status-item icon-visibility-off"
    (click)="print(inactiveValidations)"
    matTooltip="Inaktive Validierungen"
    matTooltipPosition="above"
    aria-label="Inaktive Validierungen">
    <mat-icon class="center-icon width-fit-content">visibility_off </mat-icon>
    <span>{{ inactiveValidations.length }}</span>
  </div>
  <div
    class="validation-status-item icon-times-circle"
    (click)="print(validationsWithError)"
    matTooltip="Fehlerhafte Validierungen"
    matTooltipPosition="above"
    aria-label="Fehlerhafte Validierungen">
    <mat-icon class="center-icon width-fit-content">cancel </mat-icon>
    <span>{{ validationsWithError.length }}</span>
  </div>
</div>
