import { ModelListener } from "./ModelListener";
import { MultilingualModel } from "../../view-model/type/multilingual-model";
import { FieldModel } from "../../view-model/field-model";
import { Bt300BrDeKmuModel } from "../../view-model/type/bt300-br-de-kmu-model";


/**
 * Listens for Changes on MultilingualModels
 */
export class MultilingualValueChangedListener extends ModelListener<MultilingualModel> {

  onChange(model: MultilingualModel): void {
    model.value = { DEU: model.germanValue, ENG: model.englishValue };
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model instanceof MultilingualModel && !(model instanceof Bt300BrDeKmuModel);
  }

}
