import { Inject, Injectable } from '@angular/core';
import { MaintenanceWindowService } from './maintenance-window.service';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { DOCUMENT } from '@angular/common';
import { HolidayUtilsService } from '../utils/holiday-utils.service';
import { LocalDate } from '@js-joda/core';
import { DateUtilsService } from '../utils/date-utils-service';
import { DateModel } from '../view-model/type/date-model';

@Injectable()
export class DatepickerService {
  constructor(
    private maintenanceWindowService: MaintenanceWindowService,
    private holidayUtils: HolidayUtilsService,
    private dateUtilsService: DateUtilsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /*
   * Function to filter for date-components with time-component dates which are completely covered and for dates
   * without a time-component dates that have maintenance windows at midnight.
   */
  public filterMaintenanceWindows(dateModel: DateModel) {
    let hasRelatedTime = !!dateModel.relatedTimeModel;
    const isPreferredPublicationDate = dateModel.noticeNode.id === 'BT-738-notice';

    return (date: LocalDate): boolean => {
      if (date) {
        if (isPreferredPublicationDate && date.isEqual(this.dateUtilsService.getToday())) {
          // preferred publication date gets handled as if it has a time component on today
          // so that the day is only disabled in the datepicker if the complete day is covered.
          hasRelatedTime = true;
        }

        return (
          (hasRelatedTime && !this.maintenanceWindowService.checkIfDayIsCompletelyCovered(date)) ||
          (!hasRelatedTime &&
            this.maintenanceWindowService.findSingleMaintenanceWindowCollision(
              date.atStartOfDay()
            ) === undefined)
        );
      }
      return false;
    };
  }

  /**
   * Function for the DatePicker to highlight maintenance dates and holidays
   * and generate tooltips with information about the maintenance window and holiday.
   */
  public highlightMaintenanceWindowDatesAndHolidays: MatCalendarCellClassFunction<any> = (
    cellDate: LocalDate,
    view
  ) => {
    // only customize dates inside the month view.
    if (view !== 'month') {
      return '';
    }

    // queue tooltip creation only once
    if (cellDate.dayOfMonth() === 1) {
      setTimeout(() => {
        this.createMaintenanceWindowTooltips(cellDate);
      });
    }

    let classesToAdd = '';

    if (this.holidayUtils.isNationwideHoliday(cellDate)) {
      classesToAdd += 'holiday-date';
    }

    const maintenanceWindows = this.maintenanceWindowService.findWindowsForDate(cellDate);

    if (maintenanceWindows.length) {
      classesToAdd += ' maintenance-window-date';

      if (maintenanceWindows.length === 1) {
        const start = cellDate.isEqual(maintenanceWindows[0].durationFrom.toLocalDate());
        const end = cellDate.isEqual(maintenanceWindows[0].durationTill.toLocalDate());

        if (start) classesToAdd += ' start';
        if (end) classesToAdd += ' end';
      }
    }

    return classesToAdd;
  };

  private createMaintenanceWindowTooltips(cellDate: LocalDate) {
    const calenderBody = this.document.querySelector('.mat-calendar-body');
    const calenderCells = calenderBody?.querySelectorAll('.mat-calendar-body-cell');

    calenderCells?.forEach((calenderCell: HTMLElement) => {
      const innerCellLocalDate = LocalDate.of(
        cellDate.year(),
        cellDate.monthValue(),
        +calenderCell.innerText
      );

      let tooltip = '';

      tooltip += this.holidayUtils.getNationwideHolidayByDate(innerCellLocalDate);

      const maintenanceWindowArray =
        this.maintenanceWindowService.findWindowsForDate(innerCellLocalDate);

      if (maintenanceWindowArray.length) {
        tooltip += tooltip ? '\n\n' : '';
        tooltip +=
          'Wartungsfenster:\n' +
          maintenanceWindowArray
            .map(maintenanceWindow => this.maintenanceWindowService.toString(maintenanceWindow))
            .join('\u000D\u000A'); // cr lf
      }

      calenderCell.setAttribute('data-title', tooltip);
    });
  }
}
