import { createReducer, on } from '@ngrx/store';
import * as ValidActions from './valid.action';

export const initialState = false;

/**
 * Reducer für die Gültigkeit der Eingaben
 */
export const validReducer = createReducer(
  initialState,
  on(ValidActions.change, (_state, props) => props.valid)
);
