<app-template [hasContentFrame]="true" [titel]="'Neuigkeiten verwalten'">
  <h3>Neuigkeit erfassen</h3>
  <form (ngSubmit)="onSubmit()" [formGroup]="newsForm" #newsFormElm class="news-form">
    <div>
      <label for="date">Datum *</label>
      <input
        [class.required]="true"
        class="news-date news-field"
        formControlName="date"
        id="date"
        type="datetime-local" />
    </div>

    <div style="position: relative">
      <label for="title">Titel *</label>
      <mat-form-field [class.required]="true" class="content-textarea">
        <textarea class="textarea" id="title" formControlName="title" rows="5" matInput></textarea>
      </mat-form-field>
      <span
        class="remaining-characters-container"
        [ngClass]="{ exceeded: titleMaxLength - title.value.length < 0 }">
        {{ titleMaxLength - title.value.length }}
      </span>
    </div>

    <div>
      <label>Typ *</label>
      <mat-form-field [class.required]="true">
        <mat-select formControlName="type" id="type">
          <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <label for="content">Inhalt</label>
      <mat-form-field class="content-textarea">
        <textarea
          matInput
          class="textarea"
          formControlName="content"
          rows="16"
          id="content"></textarea>
      </mat-form-field>
    </div>

    <button [disabled]="!newsForm.valid" class="speichern-button news-field" type="submit">
      Speichern
    </button>
  </form>

  <hr class="divider" />

  <div class="news-list">
    <h3>Veröffentlichte Neuigkeiten</h3>
    <table mat-table [dataSource]="news$">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let news">{{ news.newsDate | date : 'dd.MM.yyyy HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Typ</th>
        <td mat-cell *matCellDef="let news">{{ news.type }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Titel</th>
        <td mat-cell *matCellDef="let news">{{ news.title }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Aktionen</th>
        <td mat-cell *matCellDef="let news" class="actions">
          <button
            (click)="copyNews(news)"
            mat-icon-button
            matIconSuffix
            matTooltip="Neuigkeit in Formular kopieren">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button
            (click)="deleteNews(news.id)"
            mat-icon-button
            matIconSuffix
            matTooltip="Neuigkeit löschen">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <button mat-button (click)="deleteAll()" class="btn-warn">
      Alle Neuigkeiten löschen
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
</app-template>
