import { Injectable } from '@angular/core';
import {
  ChronoUnit,
  convert,
  DateTimeFormatter,
  LocalDate,
  LocalDateTime,
  LocalTime,
  nativeJs,
  ZoneId,
} from '@js-joda/core';
import '@js-joda/timezone';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  private readonly _zoneId = ZoneId.of('Europe/Berlin');

  constructor() {
    /* Utility class for date purposes */
  }

  get zoneId(): ZoneId {
    return this._zoneId;
  }

  public getTodayMidnight(): LocalDateTime {
    return this.getToday().atStartOfDay();
  }

  public toLocalIsoDateFormat(localDate: LocalDate): string {
    return localDate.format(DateTimeFormatter.ISO_LOCAL_DATE);
  }

  public toLocalIsoTimeFormatTruncToSec(localTime: LocalTime): string {
    return localTime.truncatedTo(ChronoUnit.SECONDS).format(DateTimeFormatter.ISO_LOCAL_TIME);
  }

  public getToday(): LocalDate {
    return LocalDate.now(this.zoneId);
  }

  public getLocalDateOfTomorrow(): LocalDate {
    return this.getToday().plusDays(1);
  }

  public getLocalDateTime(): LocalDateTime {
    return LocalDateTime.now(this.zoneId);
  }

  public getLocalTime(): LocalTime {
    return LocalTime.now(this.zoneId);
  }

  public toLocalDate(date: Date): LocalDate {
    return nativeJs(date).withZoneSameInstant(this.zoneId).toLocalDate();
  }

  public toLocalDateTime(date: Date): LocalDateTime {
    return nativeJs(date).withZoneSameInstant(this.zoneId).toLocalDateTime();
  }

  public toJsDate(localDateTime: LocalDateTime): Date {
    return convert(localDateTime.atZone(this.zoneId)).toDate();
  }
}
