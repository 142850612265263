import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { ModelStateService } from '../../../services/view-model/model-state.service';
import { SectionModel } from '../../../view-model/section-model';
import { RepeatableSectionModel } from '../../../view-model/repeatable-section-model';
import { NavigationService } from '../../../services/navigation.service';
import { BaseModel } from '../../../view-model/base-model';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SideBarComponent implements OnInit, OnDestroy {
  @ViewChildren('sectionPanels') expansionPanels!: QueryList<MatExpansionPanel>;
  @ViewChild('container') container: ElementRef;
  sections: SectionModel[];
  repeatableSection: RepeatableSectionModel;
  selectedSection: SectionModel;
  isChange: boolean;
  isUpdateChange: boolean;
  isUpdateMetaData: boolean;
  isRs: boolean;
  supportMode: boolean;
  procedureSection: SectionModel;

  private subscription = new Subscription();

  constructor(
    private stateService: StateService,
    private activatedRoute: ActivatedRoute,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private modelStateService: ModelStateService,
    private cdRef: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.isChange = this.editorConfigurationProvider.isChangeNotice();
    // ReSy: Pruefung auf AlterUpdateBeforeRelease
    this.isUpdateChange = this.editorConfigurationProvider.isAlterUpdateBeforeRelease();
    this.isUpdateMetaData = this.editorConfigurationProvider.isUpdateMetaData();
    this.isRs = this.editorConfigurationProvider.isRS();

    const content = this.modelStateService.componentModel.children;

    this.sections = content.filter(section => section instanceof SectionModel) as SectionModel[];

    this.repeatableSection = content.find(
      section => section instanceof RepeatableSectionModel
    ) as RepeatableSectionModel;

    this.subscription.add(
      this.stateService.getSupportMode().subscribe(supportMode => {
        this.supportMode = supportMode;
        this.cdRef.markForCheck();
      })
    );
    this.subscription.add(
      this.stateService.getSectionId().subscribe(this.onSectionChanges.bind(this))
    );

    this.procedureSection = this.sections.find(section => section.noticeNode.id === 'GR-Procedure');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSectionChanges(sectionId: string) {
    this.selectedSection = this.modelStateService.getSectionModel(sectionId);

    const panelIndex = this.sections
      .filter(this.isExpandableSection)
      .findIndex(section => section?.children?.includes(this.selectedSection));
    if (panelIndex > -1) {
      this.expansionPanels?.get(panelIndex)?.open();
    }
    this.moveScrollbar();

    this.cdRef.markForCheck();
  }

  changeSection(section: BaseModel): void {
    if (section instanceof SectionModel) {
      this.navigationService.changeSection(section, this.activatedRoute);
    }
  }

  filterContent(content: SectionModel[]): SectionModel[] {
    let filteredContent = content.filter(section => section.noticeNode.id !== 'GR-LotsGroup');

    if (!this.isChange && !this.isUpdateChange) {
      filteredContent = filteredContent.filter(section => section.noticeNode.id !== 'GR-Change');
    }

    return filteredContent;
  }

  isExpandableSection(section: SectionModel) {
    return section.children[0].noticeNode.displayType === 'SECTION';
  }

  private moveScrollbar() {
    const itemIndex = this.sections.findIndex(
      section =>
        section?.noticeNode?.id === this.selectedSection?.noticeNode?.id ||
        section?.children?.includes(this.selectedSection)
    );
    if (itemIndex > -1) {
      const container = this.container?.nativeElement;
      const element = container?.querySelectorAll('div')[itemIndex];
      element?.scrollIntoView();
    }
  }
}
