export * from './contactController.service';
import { ContactControllerService } from './contactController.service';
export * from './eformsController.service';
import { EformsControllerService } from './eformsController.service';
export * from './featureFlagsController.service';
import { FeatureFlagsControllerService } from './featureFlagsController.service';
export * from './newsController.service';
import { NewsControllerService } from './newsController.service';
export * from './noticeController.service';
import { NoticeControllerService } from './noticeController.service';
export * from './noticeLockController.service';
import { NoticeLockControllerService } from './noticeLockController.service';
export * from './noticeTypeController.service';
import { NoticeTypeControllerService } from './noticeTypeController.service';
export * from './noticeUpdaterController.service';
import { NoticeUpdaterControllerService } from './noticeUpdaterController.service';
export * from './procedureController.service';
import { ProcedureControllerService } from './procedureController.service';
export * from './selectionCriteriaController.service';
import { SelectionCriteriaControllerService } from './selectionCriteriaController.service';
export * from './versionController.service';
import { VersionControllerService } from './versionController.service';
export const APIS = [ContactControllerService, EformsControllerService, FeatureFlagsControllerService, NewsControllerService, NoticeControllerService, NoticeLockControllerService, NoticeTypeControllerService, NoticeUpdaterControllerService, ProcedureControllerService, SelectionCriteriaControllerService, VersionControllerService];
