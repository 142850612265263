import { FieldModel } from '../../view-model/field-model';
import { ModelListener } from './ModelListener';
import { MultilingualModel } from '../../view-model/type/multilingual-model';
import { SchemeIdService } from '../view-model/scheme-id.service';

/**
 * Listens for Lot-Description Changes and Updates the Name of the Lot in the Sidebar
 */
export class SchemeCaptionUpdateListener extends ModelListener<MultilingualModel> {
  constructor(private schemeIdService: SchemeIdService) {
    super();
  }

  onChange(model: FieldModel<any>): void {
    const captionRef = this.schemeIdService.captionFieldsRef.find(
      capRef => capRef.captionFieldId === model.noticeNode?.id
    );
    if (!captionRef) {
      return;
    }

    this.schemeIdService.updateSchemeCaption(model, captionRef);
  }

  /**
   * At the Time the Listeners are Created, the captionFieldRef is not initialised yet.
   *
   * @param model
   */
  shouldAttach(model: FieldModel<any>): boolean {
    return true;
  }
}
