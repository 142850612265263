import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {VersionControllerService} from "../../api/notices";
import {Observable} from "rxjs";

@Component({
  selector: 'app-lizenzen',
  templateUrl: './lizenzen.component.html',
  styleUrls: ['./lizenzen.component.scss']
})
export class LizenzenComponent {

  npmLicences: string;
  resyVersion$: Observable<string>;

  constructor(private http: HttpClient, private versionControllerService: VersionControllerService) {
  }

  ngOnInit() {
    this.http.get('assets/licences/license-report-npmjs.html', {responseType: 'text'})
      .subscribe(data => this.npmLicences = data);

    this.resyVersion$ = this.versionControllerService.getResyVersion();
  }
}
