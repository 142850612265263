import { inject, Injectable } from '@angular/core';
import { GroupModel } from '../../../view-model/group-model';
import { RepeatableFieldModel } from '../../../view-model/repeatable-field-model';
import { RepeatableGroupModel } from '../../../view-model/repeatable-group-model';
import { ModelGenerationService } from '../../../services/view-model/model-generation.service';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { StateService } from '../../../services/state.service';
import { SchemeIdService } from '../../../services/view-model/scheme-id.service';
import { DynamicModelPropertyService } from '../../../services/view-model/dynamic-model-property.service';
import { ModelValidationService } from '../../../services/view-model/model-validation.service';

@Injectable()
export class RepeatableService {
  private modelGenerationService: ModelGenerationService = inject(ModelGenerationService);
  private genericDialogService: GenericDialogService = inject(GenericDialogService);
  private stateService: StateService = inject(StateService);
  private schemeIdService: SchemeIdService = inject(SchemeIdService);
  private dynamicModelPropertyService: DynamicModelPropertyService = inject(
    DynamicModelPropertyService
  );
  private modelValidationService: ModelValidationService = inject(ModelValidationService);

  async addComponent(
    model: RepeatableGroupModel | RepeatableFieldModel<any>,
    isField: boolean,
    initSubtree: boolean = true
  ): Promise<GroupModel | void> {
    if (isField) {
      await this.addField(model as RepeatableFieldModel<any>);
    } else {
      return this.addGroup(model as RepeatableGroupModel, initSubtree);
    }
    this.stateService.setModified(true);
  }

  async deleteComponent(
    model: RepeatableGroupModel | RepeatableFieldModel<any>,
    index: number,
    skipConfirmByDialog?: boolean
  ): Promise<void> {
    let deleteConfirmed = false;

    if (skipConfirmByDialog) {
      deleteConfirmed = true;
    } else {
      deleteConfirmed = await this.deletionConfirmed();
    }

    if (deleteConfirmed) {
      if (model instanceof RepeatableGroupModel) {
        this.schemeIdService.removeIndicatorRefsInCompModelForRemovedGroup(model.children[index]);
      }

      const fieldIdsInSubtree = new Set<string>();
      this.dynamicModelPropertyService.collectIdsInSubtree(
        model.children[index],
        fieldIdsInSubtree
      );

      model.children.splice(index, 1);

      this.modelValidationService.refreshNotifications(model.root, true);
      if (fieldIdsInSubtree.size > 0) {
        await this.dynamicModelPropertyService.updateDependenciesOfFieldIds(
          fieldIdsInSubtree,
          model.root
        );
      }
    }
  }

  private async addField(model: RepeatableFieldModel<any>) {
    const clonedNoticeNode = structuredClone(model.noticeNode);
    clonedNoticeNode._repeatable = false;

    await this.modelGenerationService.addElementToRepeatableField(clonedNoticeNode, model, null);
  }

  private async addGroup(model: RepeatableGroupModel, initSubtree = true) {
    const clonedNoticeNode = structuredClone(model.noticeNode);
    clonedNoticeNode._repeatable = false;

    const addedGroup = await this.modelGenerationService.addGroup(clonedNoticeNode, model);
    model.addChild(addedGroup);
    if (initSubtree) {
      await this.modelGenerationService.initSubtree(addedGroup);
    }
    return addedGroup;
  }

  private deletionConfirmed(): Promise<boolean> {
    return this.genericDialogService.confirmWithDialog(
      'Löschen des Elements bestätigen',
      'Element löschen',
      'Abbrechen',
      'Möchten Sie dieses Element wirklich löschen? Alle Daten innerhalb des Elements gehen dabei verloren.'
    );
  }
}
