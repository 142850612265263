import { FieldModel } from '../field-model';

export class IdModel extends FieldModel<string> {
  getSchemeId(): string {
    return this.noticeNode._idScheme;
  }

  get value(): string {
    return super.value;
  }

  set value(value: string) {
    super.value = value;
  }
}
