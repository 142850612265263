import { ModelValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { MultilingualModel } from '../../../../view-model/type/multilingual-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { Language } from '../../../../types/data-model';

export class MandatoryForMultilingualValidator extends ModelValidator<FieldModel<any>> {
  static readonly MANDATORY_ERROR_MSG = 'Bitte erfassen Sie einen Wert.';

  constructor() {
    super();
  }

  validate(fieldModel: MultilingualModel): Promise<ValidationNotification[]> {
    const errors = [];
    if (this.germanInputPresentAndRequiredValueMissing(fieldModel)) {
      errors.push(
        new ValidationNotification(
          MandatoryForMultilingualValidator.MANDATORY_ERROR_MSG,
          Severity.ERROR,
          Language.Deu
        )
      );
    }
    if (this.englishInputPresentAndRequiredValueMissing(fieldModel)) {
      errors.push(
        new ValidationNotification(
          MandatoryForMultilingualValidator.MANDATORY_ERROR_MSG,
          Severity.ERROR,
          Language.Eng
        )
      );
    }
    return Promise.resolve(errors);
  }

  shouldAttach(model: FieldModel<any>): boolean {
    return model instanceof MultilingualModel && model.isMandatory;
  }

  private germanInputPresentAndRequiredValueMissing(fieldModel: MultilingualModel) {
    return fieldModel.activeLanguages.includes(Language.Deu) && !fieldModel.value.DEU;
  }

  private englishInputPresentAndRequiredValueMissing(fieldModel: MultilingualModel) {
    return fieldModel.activeLanguages.includes(Language.Eng) && !fieldModel.value.ENG;
  }
}
