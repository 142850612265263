import { AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Severity } from '../../../types/app-types';
import { FieldModel } from '../../../view-model/field-model';
import { GroupModel } from '../../../view-model/group-model';
import { SectionModel } from '../../../view-model/section-model';
import { RepeatableGroupModel } from '../../../view-model/repeatable-group-model';
import { IdGenerationService } from '../../../services/id-generation.service';
import { ModelListenerService } from '../../../services/view-model/model-listener.service';

@Component({
  template: '',
})
export abstract class AbstractFormComponent implements OnDestroy, AfterViewInit {
  model: FieldModel<any> | GroupModel | RepeatableGroupModel | SectionModel;
  subscription = new Subscription();
  htmlId: string;
  protected modelListenerService: ModelListenerService = inject(ModelListenerService);
  protected cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected idGenerationService: IdGenerationService = inject(IdGenerationService);

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.model.focus) {
      this.focus();
    }
  }

  /**
   * Sets the Model of the Component and registers ChangeListeners on it.
   */
  public initWithModel(model: FieldModel<any> | GroupModel | RepeatableGroupModel | SectionModel) {
    this.model = model;
    this.subscription.add(this.model.modelChangedEmitter.subscribe(() => this.onModelChange()));
    this.subscription.add(this.model.updateViewEmitter.subscribe(() => this.cdRef.markForCheck()));
    this.htmlId = this.idGenerationService.getHtmlId();
  }

  hasInputError(): boolean {
    return this.model.validationNotifications.some(
      notification => notification.severity === Severity.ERROR
    );
  }

  /**
   * Called after the Value of the Form Component has been changed.
   */
  onInputValueChanged() {
    if (this.model instanceof FieldModel) {
      this.modelListenerService.onModelValueChange(this.model);
    }
  }

  protected onModelChange(): void {
    if (this.model.focus) {
      this.focus();
    }

    this.cdRef.markForCheck();
  }

  protected focus(input?: HTMLElement): void {
    input = input ?? document.getElementById(this.htmlId);

    setTimeout(() => {
      if (this.model.isReadonly) {
        input.scrollIntoView({
          block: 'start',
          inline: 'nearest',
        });
      } else {
        input?.focus();
      }
    });
    this.model.focus = false;
  }
}
