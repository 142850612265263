import { Injectable } from '@angular/core';
import { ContentType, DisplayType, NoticeNode } from '../types/notice-definition';
import { SdkService } from './sdk.service';
import { AbstractFormComponent } from '../components/form-components/abstract-form/abstract-form.component';
import { GroupComponent } from '../components/form-components/group/group.component';
import { SectionComponent } from '../components/form-components/section/section.component';
import { FieldInfo, FieldInfoType } from '../types/field-types';
import { TextFieldComponent } from '../components/form-components/text-field/text-field.component';
import { TextAreaComponent } from '../components/form-components/text-area/text-area.component';
import { MultiLingualComponent } from '../components/form-components/multi-lingual/multi-lingual.component';
import { DateComponent } from '../components/form-components/date/date.component';
import { RepeatableComponent } from '../components/form-components/repeatable/repeatable.component';
import { CodelistComponent } from '../components/form-components/codelist/codelist.component';
import { UrlComponent } from '../components/form-components/text-field/url/url.component';
import { EmailComponent } from '../components/form-components/text-field/email/email.component';
import { PhoneComponent } from '../components/form-components/text-field/phone/phone.component';
import { NumberComponent } from '../components/form-components/number/number.component';
import { IndicatorComponent } from '../components/form-components/indicator/indicator.component';
import { IntegerComponent } from '../components/form-components/integer/integer.component';
import { TimeComponent } from '../components/form-components/date/time/time.component';
import { AmountComponent } from '../components/form-components/amount/amount.component';
import { MeasureComponent } from '../components/form-components/measure/measure.component';
import { IdRefComponent } from '../components/form-components/id-ref/id-ref.component';
import { IdComponent } from '../components/form-components/id/id.component';
import { PostalCodeComponent } from '../components/form-components/text-field/postal-code/postal-code.component';
import {
  ARCHIVE_DATE_ID,
  CATEGORY_ID,
  CLERK_MAIL_ID,
  FREELANCE_ID,
  OFFER_DEADLINE_DATE_ID,
  OFFER_DEADLINE_TIME_ID,
  OFFER_TYPE_ID,
  PROJECT_ID,
} from './view-model/evergabe-meta-data.service';
import { ProjectComponent } from '../components/form-components/project/project.component';
import { BT300BR26DeKmuSelectionComponent } from '../components/form-components/custom-field-components/bt-300-br-26-de-kmu-selection/bt-300-br-26-de-kmu-selection.component';
import { BT09SelectionComponent } from '../components/form-components/custom-field-components/bt-09-selection/bt-09-selection.component';
import { GrLotResultCvdVehiclesStatisticsComponent } from '../components/form-components/custom-field-components/gr-lot-result-cvd-vehicles-statistics/gr-lot-result-cvd-vehicles-statistics.component';
import { GrLotResultReceivedSubmissionsComponent } from "../components/form-components/custom-field-components/gr-lot-result-received-submissions/gr-lot-result-received-submissions.component";

@Injectable()
export class FieldTypeService {
  private groupTypeComponentMap = new Map();
  private fieldTypeComponentMap = new Map();

  // Mapper for Custom Fields Components
  private customFieldTypeComponentMap = new Map();

  constructor(private sdkService: SdkService) {
    this.groupTypeComponentMap.set('GROUP', GroupComponent);
    this.groupTypeComponentMap.set('SECTION', SectionComponent);

    this.customFieldTypeComponentMap.set('BT-300-Lot', BT300BR26DeKmuSelectionComponent);
    this.customFieldTypeComponentMap.set('BT-300-Part', BT300BR26DeKmuSelectionComponent);
    this.customFieldTypeComponentMap.set('BT-09(a)-Procedure', BT09SelectionComponent);

    this.fieldTypeComponentMap.set(FieldInfoType.TEXT_MULTILINGUAL, MultiLingualComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.TEXT, TextFieldComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.DATE, DateComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.URL, UrlComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.MAIL, EmailComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.PHONE, PhoneComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.NUMBER, NumberComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.INDICATOR, IndicatorComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.INTEGER, IntegerComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.TIME, TimeComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.AMOUNT, AmountComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.MEASURE, MeasureComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.ID_REF, IdRefComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.ID, IdComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.PROJECT, ProjectComponent);
    this.fieldTypeComponentMap.set(FieldInfoType.CODE, CodelistComponent);
  }

  public getComponentForNoticeNode(noticeNode: NoticeNode, isInsideRepeatable: boolean): any {
    if (noticeNode.id.startsWith('GR-LotResult-ReceivedSubmissions')) {
      return GrLotResultReceivedSubmissionsComponent;
    }

    if (noticeNode.id.startsWith('BT-759-LotResult')) {
      return NumberComponent;
    }

    if (noticeNode.id.startsWith('BT-760-LotResult')) {
      return CodelistComponent;
    }

    if (noticeNode.id.startsWith('GR-LotResult-CVD-Vehicles-Statistics')) {
      return GrLotResultCvdVehiclesStatisticsComponent;
    }

    if (noticeNode.id.startsWith('BT-512')) {
      return PostalCodeComponent;
    }

    if (!isInsideRepeatable && noticeNode._repeatable && noticeNode.displayType !== 'SECTION') {
      return RepeatableComponent;
    }

    if (noticeNode.contentType === ContentType.GROUP) {
      return this.getComponentTypeForGroup(noticeNode);
    }

    if (noticeNode.contentType === ContentType.FIELD) {
      return this.getComponentTypeForField(noticeNode) as AbstractFormComponent;
    }

    console.log("Can't load Component for field id " + noticeNode.id);
    return TextFieldComponent;
  }

  private getComponentTypeForGroup(noticeNode: NoticeNode): AbstractFormComponent {
    const displayType = noticeNode.displayType;

    if (this.groupTypeComponentMap.has(displayType)) {
      return this.groupTypeComponentMap.get(displayType);
    } else {
      throw new Error("Can't find Group ContentType with DisplayType " + displayType);
    }
  }

  private getComponentTypeForField(noticeNode: NoticeNode) {
    const noticeNodeId = noticeNode.id;
    let fieldInfo = this.sdkService.getFieldInfo(noticeNodeId);

    if (!fieldInfo) {
      fieldInfo = this.getFieldInfoForEVergabeMetaData(noticeNode);
    }

    if (!fieldInfo) {
      throw new Error("Can't find FieldInfo for Field '" + noticeNodeId + "'");
    }

    if (this.customFieldTypeComponentMap.has(fieldInfo.id)) {
      return this.customFieldTypeComponentMap.get(fieldInfo.id);
    }

    if (noticeNode.displayType === DisplayType.TEXTAREA) {
      if (this.fieldTypeComponentMap.has(fieldInfo.type)) {
        return this.fieldTypeComponentMap.get(fieldInfo.type);
      } else {
        return TextAreaComponent;
      }
    }

    if (this.fieldTypeComponentMap.has(fieldInfo.type)) {
      return this.fieldTypeComponentMap.get(fieldInfo.type);
    }

    console.log(
      "Can't find FieldInfo Type for Field '" +
        noticeNodeId +
        "' with Type '" +
        fieldInfo.type +
        "'"
    );
    return TextFieldComponent;
  }

  private getFieldInfoForEVergabeMetaData(noticeNode: NoticeNode): FieldInfo {
    switch (noticeNode.id) {
      case PROJECT_ID:
        return { type: FieldInfoType.PROJECT };
      case CATEGORY_ID:
        return { type: FieldInfoType.CODE };
      case ARCHIVE_DATE_ID:
        return { type: FieldInfoType.DATE };
      case OFFER_DEADLINE_DATE_ID:
        return { type: FieldInfoType.DATE };
      case OFFER_DEADLINE_TIME_ID:
        return { type: FieldInfoType.TIME };
      case FREELANCE_ID:
        return { type: FieldInfoType.INDICATOR };
      case OFFER_TYPE_ID:
        return { type: FieldInfoType.CODE };
      case CLERK_MAIL_ID:
        return { type: FieldInfoType.TEXT };
      default:
        return null;
    }
  }
}
