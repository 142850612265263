<app-template [hasContentFrame]="true" [titel]="'Erklärung zur Barrierefreiheit'">
  <section>
    <p>
      Diese Erklärung zur Barrierefreiheit gilt für die unter
      <a href="https://resy.datenservice-oeffentlicher-einkauf.de" target="_blank">www.resy.datenservice-oeffentlicher-einkauf.de</a>
      veröffentlichte
      Webseite
      des Beschaffungsamts des BMI.
    </p>
    <p>
      Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bemüht, unsere
      Webanwendung im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes des
      Bundes (BGG) sowie der Barrierefreien-Informationstechnik-Verordnung (BITV 2.0) zur Umsetzung
      der Richtlinie (EU) 2016/2102 barrierefrei zugänglich zu machen.
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">
      Stand der Vereinbarkeit mit den Anforderungen
    </h3>
    <p>
      Die Anforderungen der Barrierefreiheit ergeben sich aus §§ 3 Absätze 1 bis 4 und 4 der BITV
      2.0, die auf der Grundlage von § 12d BGG erlassen wurde. Die Überprüfung der Barrierefreiheit
      ist derzeit in Planung. Das Ergebnis wird zu gegebenem Zeitpunkt hier veröffentlicht.
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">
      Datum der Erstellung bzw. der letzten Aktualisierung der Erklärung
    </h3>
    <p>
      Diese Erklärung wurde am 01.09.2023 erstellt und am 25.10.2023 aktualisiert.
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">Barriere melden</h3>
    <p>
      Sollten Sie auf unseren Seiten dennoch auf Barrieren stoßen, senden Sie uns bitte per E-Mail
      an <a href="mailto:resy-doe@bescha.bund.de">resy-doe@bescha.bund.de</a> einen entsprechenden
      Hinweis mit einer Beschreibung, wo Ihnen der Fehler aufgefallen ist.
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">Schlichtungsverfahren</h3>
    <p>
      Beim Beauftragten der Bundesregierung für die Belange von Menschen mit Behinderungen gibt es
      eine Schlichtungsstelle gemäß § 16 BGG. Die Schlichtungsstelle hat die Aufgabe, Konflikte
      zwischen Menschen mit Behinderungen und öffentlichen Stellen des Bundes zu lösen. Sie können
      die Schlichtungsstelle einschalten, wenn Sie mit den Antworten aus der oben genannten
      Kontaktmöglichkeit nicht zufrieden sind. Dabei geht es nicht darum, Gewinner oder Verlierer zu
      finden. Vielmehr ist es das Ziel, mit Hilfe der Schlichtungsstelle gemeinsam und
      außergerichtlich eine Lösung für ein Problem zu finden.
    </p>
    <p>
      Das Schlichtungsverfahren ist kostenlos. Sie brauchen auch keinen Rechtsbeistand.
    </p>
    <p>
      <a
        href="https://schlichtungsstelle-bgg.de" target="_blank"
      >Auf der Internetseite der Schlichtungsstelle</a
      >
      finden Sie alle Informationen zum Schlichtungsverfahren. Dort können Sie nachlesen, wie ein
      Schlichtungsverfahren abläuft und wie Sie den Antrag auf Schlichtung stellen.
    </p>
    <p>
      Sie erreichen die Schlichtungsstelle unter folgender Adresse:
    </p>
    <p>
      Schlichtungsstelle nach dem Behindertengleichstellungsgesetz bei dem Beauftragten der
      Bundesregierung für die Belange von Menschen mit Behinderungen<br />
      Mauerstraße 53<br />
      10117 Berlin<br />
      <b>Telefon:</b> 030 18 527-2805 <br />
      <b>Fax: </b>030 18 527-2901 <br />
      <b>E-Mail: </b
      ><a href="mailto:info@schlichtungsstelle-bgg.de">info@schlichtungsstelle-bgg.de</a><br />
      <b>Internet: </b
      ><a href="https://schlichtungsstelle-bgg.de" target="_blank">www.schlichtungsstelle-bgg.de</a><br />
    </p>
  </section>
</app-template>
