<div class="alert_message_content-wrapper" [ngClass]="getSeverityClass()">
  <div
    class="alert_message_content_type-container"
    [ngClass]="getSeverityClass()"
    [ngSwitch]="severity">
    <mat-icon *ngSwitchCase="Severity.ERROR">dangerous</mat-icon>
    <mat-icon *ngSwitchCase="Severity.WARNING">warning</mat-icon>
    <mat-icon *ngSwitchDefault>info</mat-icon>
  </div>
  <div class="alert_message_content" role="alert">
    <ng-content></ng-content>
  </div>
</div>
