<app-template [hasContentFrame]="true" [titel]="'Lizenzen'">
  <section>
    <h3 style="font-weight: bold">Open-Source-Bibliotheken</h3>
    <h4>Im Redaktionssystem werden folgenden Open-Source-Bibliotheken verwendet:</h4>
    <div [innerHTML]="npmLicences" class="table-wrapper"></div>
  </section>
  <section>
    <h3 style="font-weight: bold">Versionshinweis</h3>
    <p>Version des Redaktionssystems: {{ resyVersion$ | async }}</p>
  </section>
</app-template>
