import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VorlagenService } from '../portal/api/templates';
import { ConceptModel } from '../types/concept-node';
import { ConceptModelUtils } from './parser/concept-model-utils';
import { RsEditorConfigurationProvider } from './rs-editor-configuration.provider';
import { GenericDialogService } from './ui/generic-dialog.service';
import { TemplateNameAndDescriptionService } from '../portal/bekanntmachung/bekanntmachungen-auswahl/template-name-and-description.service';

@Injectable()
export class RsTemplateSubmittingService {
  constructor(
    private vorlagenService: VorlagenService,
    private rsConfig: RsEditorConfigurationProvider,
    private genericDialogService: GenericDialogService,
    private templateNameAndDescriptionService: TemplateNameAndDescriptionService
  ) {}

  public createTemplateFromNotice(conceptModel: ConceptModel) {
    return this.vorlagenService
      .importNoticeAsTemplateForUser({
        name: this.templateNameAndDescriptionService.getName(),
        description: this.templateNameAndDescriptionService.getDescription(),
        conceptModel: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
      })
      .pipe(tap(() => this.templateNameAndDescriptionService.reset()));
  }

  public save(conceptModel: ConceptModel): Observable<number> {
    const rsTemplateId = this.rsConfig.getRsTemplateId();
    let templateServiceCreateOrUpdate$: Observable<number>;
    if (rsTemplateId) {
      templateServiceCreateOrUpdate$ = this.vorlagenService.updateTemplateDataById(
        rsTemplateId,
        {
          conceptModel: JSON.parse(ConceptModelUtils.serialize(conceptModel)),
        },
        'body'
      );
    } else {
      templateServiceCreateOrUpdate$ = this.createTemplateFromNotice(conceptModel);
    }

    return templateServiceCreateOrUpdate$.pipe(tap(() => this.showSuccessDialog()));
  }

  public loadData(): Observable<ConceptModel> {
    const rsTemplateId = this.rsConfig.getRsTemplateId();
    if (rsTemplateId) {
      return this.vorlagenService
        .getTemplateDataById(rsTemplateId)
        .pipe(map(json => json as unknown as ConceptModel));
    } else {
      return of(null);
    }
  }

  private async showSuccessDialog() {
    const content = 'Die Vorlage wurde erfolgreich gespeichert.';
    await this.genericDialogService.showResultDialog(
      `Aktion erfolgreich`,
      content,
      'Schließen',
      true
    );
  }
}
