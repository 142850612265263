import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

import { MatTableDataSource } from '@angular/material/table';
import { FilterTableViewModel } from './FilterTableViewModel';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
})
export class FilterTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data: FilterTableViewModel[];
  dataSource = new MatTableDataSource<FilterTableViewModel>();

  @Output() rowClick: EventEmitter<string> = new EventEmitter();

  displayedColumns: string[] = [
    'subTypeId',
    'bekanntmachungsart',
    'formTypeLabel',
    'legalBasisLabel',
  ];

  filterKeys: { [key: string]: string } = {
    formTypeLabel: 'Formulartyp',
    legalBasisLabel: 'Vergaberechtsrahmen',
    bekanntmachungsart: 'Bekanntmachungsart',
  };
  filterValues: { [key: string]: string[] } = {};
  searchForm: FormGroup;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder) {}

  returnZero() {
    return 0;
  }

  ngOnInit(): void {
    this.initializeFilters();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(): void {
    this.dataSource.data = this.data;
    this.generateFilterValues();
  }

  onRowClick(row: any) {
    this.rowClick.emit(row.subTypeId);
  }

  initializeFilters(): void {
    this.searchForm = this.fb.group({});
    Object.keys(this.filterKeys).forEach(key => {
      this.searchForm.addControl(key, new FormControl([]));
    });

    this.searchForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  applyFilters(): void {
    const filter = {};
    Object.keys(this.filterKeys).forEach(key => {
      filter[key] = this.searchForm.get(key).value;
    });

    this.dataSource.filterPredicate = (data, filterString: string) => {
      const searchTerms = JSON.parse(filterString);
      return Object.keys(searchTerms).every(key => {
        if (!searchTerms[key].length) return true;
        return searchTerms[key].includes(data[key]);
      });
    };

    this.dataSource.filter = JSON.stringify(filter);
  }

  generateFilterValues(): void {
    Object.keys(this.filterKeys).forEach(key => {
      this.filterValues[key] = [...new Set(this.dataSource.data.map(item => item[key]))];
    });
  }
}
