import { Injectable } from '@angular/core';
import { ComponentModel } from '../../view-model/component-model';
import { NoticeNode } from '../../types/notice-definition';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';
import { ModelGenerationService } from './model-generation.service';
import { ConceptNode } from '../../types/concept-node';

@Injectable()
export class RepeatableSectionService {
  private _section: RepeatableSectionModel;

  public async createRepeatableSections(
    componentModel: ComponentModel,
    content: NoticeNode[],
    modelGenerationService: ModelGenerationService,
    importedConceptNode?: ConceptNode
  ): Promise<void> {
    const repeatableNoticeNode = content.find(noticeNode => noticeNode._repeatable);

    if (repeatableNoticeNode) {
      componentModel.children.push(
        await this.initRepeatableSectionModel(
          repeatableNoticeNode,
          componentModel,
          modelGenerationService,
          importedConceptNode
        )
      );
    }
  }

  private async initRepeatableSectionModel(
    noticeNode: NoticeNode,
    componentModel: ComponentModel,
    modelGenerationService: ModelGenerationService,
    importedConceptNode?: ConceptNode
  ): Promise<RepeatableSectionModel> {
    const repeatableSectionModel = new RepeatableSectionModel();
    await modelGenerationService.configure(repeatableSectionModel, noticeNode, componentModel);

    if (!importedConceptNode) {
      await modelGenerationService.addRepeatableSection(noticeNode, repeatableSectionModel);
    } else {
      const sectionNodes = [];
      modelGenerationService.findConceptNodesForRepeatableGroup(
        noticeNode,
        importedConceptNode,
        sectionNodes
      );
      for (const child of sectionNodes) {
        await modelGenerationService.addRepeatableSection(
          noticeNode,
          repeatableSectionModel,
          child
        );
      }
    }
    this._section = repeatableSectionModel;
    return repeatableSectionModel;
  }

  get section(): RepeatableSectionModel {
    return this._section;
  }
}
