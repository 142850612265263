import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { FieldModel } from '../../../view-model/field-model';

@Component({
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent extends AbstractFormComponent implements OnInit {
  placeholder = '';
  inputControl = new FormControl();
  maxLength: number;
  model: FieldModel<string>;

  ngOnInit(): void {
    if (this.model.value) {
      this.setValue();
    }

    if (this.model.placeholder) {
      this.placeholder = this.model.placeholder;
    }

    this.maxLength = +this.model.fieldInfo?.maxLength;

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: false });
    }
  }

  commitValue(): void {
    this.model.value = this.inputControl.value;
    this.onInputValueChanged();
  }

  protected onModelChange() {
    const valueChanged = this.model.value !== this.inputControl.value;
    this.setValue();

    if (valueChanged) {
      this.onInputValueChanged();
    }
    super.onModelChange();
  }

  private setValue() {
    this.inputControl.setValue(this.model.value);
  }
}
