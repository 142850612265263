export enum AuthErrorDialogType {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  LOGOUT = 'LOGOUT',
  USER_CHANGED = 'USER_CHANGED',
}

export interface AuthErrorDialogData {
  errorType: AuthErrorDialogType;
  errorText?: string,
}
