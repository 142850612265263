import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewsDto } from '../../api/notices';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NewsService } from '../../../services/news.service';

@Component({
  selector: 'app-neuigkeiten-banner',
  templateUrl: './neuigkeiten-banner.component.html',
  styleUrls: ['./neuigkeiten-banner.component.scss'],
})
export class NeuigkeitenBannerComponent implements OnInit, OnDestroy {
  newsList: NewsDto[] = [];
  routerSubscription: Subscription;
  newsSubscription: Subscription;

  constructor(private newsService: NewsService, private router: Router) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.newsService.updateNews();
      });

    this.newsSubscription = this.newsService.getLatestBannerList().subscribe(newsList => {
      this.newsList = newsList;
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.newsSubscription.unsubscribe();
  }
}
