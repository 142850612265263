import { Injectable } from '@angular/core';
import { v4 } from 'uuid';

@Injectable()
export class IdGenerationService {
  private static counter = 1;
  public getRandomUUIDv4(): string {
    return v4();
  }

  public getHtmlId(): string {
    return 'a' + IdGenerationService.counter++;
  }
}
