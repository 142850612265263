<div [hidden]="model.isHidden" class="form-component-input-group">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"> </app-field-header>
  <input
    [id]="htmlId"
    [attr.aria-label]="model.translatedLabel"
    (blur)="commitModel()"
    [class.has-error]="hasInputError()"
    [class.required]="model.isMandatory && !model.isReadonly"
    [formControl]="inputControl"
    class="form-text-field"
    appInputNumber
    [maxLength]="model.fieldInfo?.maxLength"
  />
  <app-validation-message [model]="model"></app-validation-message>
</div>
