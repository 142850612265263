<footer>
  <div class="footer-menu">
    <a class="footer-item" routerLink="/leichte-sprache" routerLinkActive="active"
      >Leichte Sprache</a
    >
    <a class="footer-item" routerLink="/barrierefreiheit" routerLinkActive="active"
      >Barrierefreiheit</a
    >
    <a class="footer-item" routerLink="/nutzungsbedingungen" routerLinkActive="active"
      >Nutzungsbedingungen</a
    >
    <a class="footer-item" routerLink="/impressum" routerLinkActive="active">Impressum</a>
    <a class="footer-item" routerLink="/lizenzen" routerLinkActive="active">Lizenzen</a>
    <a class="footer-item" routerLink="/datenschutz" routerLinkActive="active">Datenschutz</a>
    <a class="footer-item" routerLink="/faq" routerLinkActive="active">Häufige Fragen (FAQ)</a>
    <a class="footer-item" routerLink="/kontakt" routerLinkActive="active">Kontakt</a>
    <a class="footer-item" routerLink="/anleitungen" routerLinkActive="active">Anleitungen</a>
  </div>
  <ng-container *ngIf="!(useLiteFooter$ | async)">
    <div class="footer-technical-infos">
      <div class="footer-item">
        <img src="assets/img/server_icon.svg" />
        <div>Server-Standort Deutschland</div>
      </div>
      <div class="footer-item">
        <img src="assets/img/secure_icon.svg" />
        <div>SSL/TLS 256-bit Verschlüsselung</div>
      </div>
    </div>
    <div class="footer-copyright">&copy; Beschaffungsamt des BMI, 2024</div>
  </ng-container>
</footer>
