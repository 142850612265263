import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { NoticeDefinition } from '../types/notice-definition';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { NoticeSubType, NoticeTypes } from '../types/notice-types';
import { FieldInfo, Fields, XmlStructure } from '../types/field-types';
import { memoizeAsync } from 'utils-decorators/dist/cjs';
import { RsEditorConfigurationProvider } from './rs-editor-configuration.provider';

@Injectable()
export class SdkService {
  private idToFieldInfoMap = new Map<string, FieldInfo>();

  private idToXmlStructureMap = new Map<string, XmlStructure>();

  constructor(
    private http: HttpClient,
    private keycloak: KeycloakService,
    private rsConfig: RsEditorConfigurationProvider
  ) {}

  public async getCodeList(codelist: string): Promise<{ id: string; label: string }[] | null> {
    const codeLists = await this.getCodeLists();
    if (!codeLists[codelist]) return null;
    return Object.entries(codeLists[codelist]).map(([key, value]) => ({
      id: String(key),
      label: String(value),
    }));
  }

  public async getNoticeSubType(noticeId: string): Promise<NoticeSubType> {
    const types = await this.getNoticeTypes();
    const noticeSubTypes = types.noticeSubTypes;
    return noticeSubTypes.find(subType => subType.subTypeId === noticeId);
  }

  public async doGenericApiCall(endpoint: string): Promise<string> {
    return firstValueFrom(this.http.get(endpoint, { responseType: 'text' }));
  }

  public async getCodeLists(): Promise<Map<string, any>> {
    return this.requestSdkFile<Map<string, any>>(`api/codelists`, this.rsConfig.getSubTypeId());
  }

  public async getNoticeDefinition(noticeId: string): Promise<NoticeDefinition> {
    return this.requestSdkFile<NoticeDefinition>(
      `api/notice/${noticeId}`,
      this.rsConfig.getSubTypeId()
    );
  }

  public async getFields(): Promise<Fields> {
    return this.requestSdkFile<Fields>(`api/fields`, this.rsConfig.getSubTypeId());
  }

  public getTranslationFiles(): Promise<XMLDocument> {
    return this.requestXMLDocument(`api/translations/de`, this.rsConfig.getSubTypeId());
  }

  public async getNoticeTypes(): Promise<NoticeTypes> {
    return this.requestSdkFile<NoticeTypes>(`api/notice-types`, this.rsConfig.getSubTypeId());
  }

  public async getMigratedFields(version: string): Promise<{ [subType: string]: string[] }> {
    return new Promise<{ [subType: string]: string[] }>(res => {
      this.http
        .get<any>(`api/migration/${version}`, {
          headers: { 'content-type': 'application/json' },
        })
        .subscribe({ next: value => res(value), error: () => res(null) });
    });
  }

  public getFieldInfo(noticeNodeId: string): FieldInfo {
    return this.idToFieldInfoMap.get(noticeNodeId);
  }

  public async initFieldTypes() {
    const fields: Fields = await this.getFields();

    fields.fields.forEach((fieldInfo: FieldInfo) =>
      this.idToFieldInfoMap.set(fieldInfo.id, fieldInfo)
    );

    fields.xmlStructure.forEach(xmlStructure =>
      this.idToXmlStructureMap.set(xmlStructure.id, xmlStructure)
    );
  }

  @memoizeAsync()
  private async requestSdkFile<T>(url: string, subTypeId: string): Promise<T> {
    return firstValueFrom(this.http.get<T>(`${url}?subTypeId=${subTypeId}`));
  }

  /**
   * Fragt ein XMLDocument ab.
   * Hier wird der native XMLHttpRequest verwendet, damit ein XMLDocument Object erzeugt wird.
   *
   * @param url des Documents
   * @param subTypeId subTypeId des aktuellen Formulars (optional)
   */
  private async requestXMLDocument(url: string, subTypeId: string = null): Promise<XMLDocument> {
    const accessToken = await this.keycloak.getToken();
    return new Promise((resolve, reject) => {
      const xmlRequest = new XMLHttpRequest();
      xmlRequest.responseType = 'document';
      xmlRequest.overrideMimeType('text/xml');
      xmlRequest.onerror = reject;
      xmlRequest.onloadend = () => {
        console.debug(xmlRequest.response);
        resolve(xmlRequest.responseXML);
      };

      xmlRequest.open('GET', this.buildUrl(url, subTypeId));

      // ReSy: Hier muss der Authorization-Header manuell ergaenzt werden, da der Interceptor aus der Keycloak-Lib bei manuellen Requests nicht greift.
      if (accessToken) {
        xmlRequest.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      }

      xmlRequest.send();
    });
  }

  private buildUrl(url: string, subTypeId: string = null): string {
    let params = new HttpParams();
    if (subTypeId) {
      params = params.set('subTypeId', subTypeId);
    }
    const queryParams = params.keys().length ? `?${params.toString()}` : '';
    return `${url}${queryParams}`;
  }
}
