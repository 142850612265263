import { FieldModel } from '../field-model';
import { SdkService } from '../../services/sdk.service';
import { Amount } from '../../types/data-model';

export class AmountModel extends FieldModel<Amount> {
  private _currencies: { id: string; label: string }[] = [];

  constructor(private sdkService: SdkService) {
    super();
    this.value = { currencyID: 'EUR', amount: null };
  }

  async loadSdkProperties(): Promise<void> {
    this._currencies = await this.sdkService.getCodeList('currency');
    for (const entry of this._currencies) {
      entry.label = entry.id + ' - ' + entry.label;
    }
  }

  get currencies(): { id: string; label: string }[] {
    return this._currencies;
  }
}
