import { AbstractControl } from '@angular/forms';

/** Nuts code id isn't from the code list */
export function nutsCodeValidator(codeList: { id: string; label: string }[]) {
  return (control: AbstractControl) => {
    let validNutsCode: { id: string; label: string };
    if (control.value?.id) {
      validNutsCode = codeList?.find(item => item?.id === control.value.id);
    } else {
      validNutsCode = codeList?.find(item => item?.id === control.value);
    }

    if (control.value && !validNutsCode) {
      return { invalidNutsCode: true };
    }

    return null;
  };
}
