import {Injectable} from '@angular/core';
import {BaseModel} from '../../view-model/base-model';
import {FiveDaysInFutureWarningValidator} from '../validation/validators/date/FiveDaysInFutureWarningValidator';
import {DateUtilsService} from '../../utils/date-utils-service';
import {EVergabeSpecificValidator, ModelValidator} from '../validation/validators/ModelValidator';
import {MandatoryValidator} from '../validation/validators/base/MandatoryValidator';
import {MaxLengthValidator} from '../validation/validators/base/MaxLengthValidator';
import {PatternValidator} from '../validation/validators/base/PatternValidator';
import {BusinessRuleValidator} from '../validation/validators/base/BusinessRuleValidator';
import {DeadlineInPastValidator} from '../validation/validators/deadline/DeadlineInPastValidator';
import {ComponentModel} from '../../view-model/component-model';
import {GroupModel} from '../../view-model/group-model';
import {SectionModel} from '../../view-model/section-model';
import {RepeatableGroupModel} from '../../view-model/repeatable-group-model';
import {RepeatableFieldModel} from '../../view-model/repeatable-field-model';
import {RepeatableSectionModel} from '../../view-model/repeatable-section-model';
import {NavigableNotification} from '../../types/NavigableNotification';
import {BehaviorSubject, Subject} from 'rxjs';
import {BusinessRuleValidationService} from '../validation/business-rule-validation.service';
import {InternalReferenceValidator} from '../validation/validators/evergabe/InternalReferenceValidator';
import {CommunicationService} from '../communication.service';
import {Severity, ValidationNotification} from '../../types/app-types';
import {DeadlineTodayValidator} from '../validation/validators/deadline/DeadlineTodayValidator';
import {EditorConfigurationProvider} from '../editor-configuration.provider';
import {DeadlineOnHolidayValidator} from '../validation/validators/deadline/DeadlineOnHolidayValidator';
import {HolidayUtilsService} from '../../utils/holiday-utils.service';
import {MaintenanceWindowService} from '../maintenance-window.service';
import {DeadlineOnWeekendValidator} from '../validation/validators/deadline/DeadlineOnWeekendValidator';
import {
  ArchivingDateBehindAllOfferDeadlinesValidator
} from '../validation/validators/evergabe/ArchivingDateBehindAllOfferDeadlinesValidator';
import {FormTypeService} from '../form-type.service';
import {ArchivingDeadlineTodayValidator} from '../validation/validators/evergabe/ArchivingDeadlineTodayValidator';
import {
  ArchivingDateInValidCenturyValidator
} from '../validation/validators/evergabe/ArchivingDateInValidCenturyValidator';
import {FieldModel} from '../../view-model/field-model';
import {TimeValidator} from '../validation/validators/time/TimeValidator';
import {StateService} from '../state.service';
import {ModelStateService} from './model-state.service';
import {TimeModel} from '../../view-model/type/time-model';
import {DateModel} from '../../view-model/type/date-model';
import {SyncedFieldService} from '../synced-field.service';
import {ConceptModel} from '../../types/concept-node';
import {ConceptModelService} from '../concept-model.service';
import {MaxLengthForMultilingualValidator} from '../validation/validators/base/MaxLengthForMultilingualValidator';
import {MandatoryForMultilingualValidator} from '../validation/validators/base/MandatoryForMultilingualValidator';
import {MultilingualModel} from '../../view-model/type/multilingual-model';
import {
  DeadlineOnMaintenanceWindowCollisionValidator
} from '../validation/validators/deadline/DeadlineOnMaintenanceWindowCollisionValidator';
import {
  ProcedureDeadlineAfterParticipationDeadlineValidator
} from '../validation/validators/evergabe/ProcedureDeadlineAfterParticipationDeadlineValidator';
import {ValueInCodelistValidator} from '../validation/validators/codelist/ValueInCodelistValidator';
import {
  ArchivingDateBehindOfferValidityPeriod
} from '../validation/validators/evergabe/ArchivingDateBehindOfferValidityPeriod';
import {ProcedureTypeService} from '../procedure-type.service';
import {
  OfferOpeningDeadlineAfterOfferDeadlineValidator
} from '../validation/validators/evergabe/OfferOpeningDeadlineAfterOfferDeadlineValidator';
import {
  MetaOfferDeadlineNotBeforeLastParticipationDeadline
} from '../validation/validators/deadline/MetaOfferDeadlineNotBeforeLastParticipationDeadline';
import {TranslationService} from '../translation.service';
import {
  PreferredPublicationDateNotInPastValidator
} from '../validation/validators/evergabe/PreferredPublicationDateNotInPastValidator';
import {
  DeadlineAfterPreferredPublicationDateValidator
} from '../validation/validators/evergabe/DeadlineAfterPreferredPublicationDateValidator';
import {MandatoryForAmountAndUnitValidator} from '../validation/validators/base/MandatoryForAmountAndUnitValidator';
import {MeasureModel} from '../../view-model/type/measure-model';
import {AmountModel} from '../../view-model/type/amount-model';
import {
  IncompleteAmountAndUnitInputValidator
} from '../validation/validators/base/IncompleteAmountAndUnitInputValidator';
import {MainNatureInAtLeastOneLotValidator} from '../validation/validators/base/MainNatureInAtLeastOneLotValidator';
import {ModelChangeDetectionService} from './model-change-detection.service';
import {OfferTypeValidator} from '../validation/validators/evergabe/OfferTypeValidator';
import { TooFarInPastValidator } from '../validation/validators/date/TooFarInPastValidator';
import { CvdBt723Validator } from '../validation/validators/codelist/CvdBt723Validator';

@Injectable()
export class ModelValidationService {
  private static BTS_FOR_DRAFT = [
    'BT-105-Procedure',
    'BT-23-Procedure',
    'BT-22-Procedure',
    'BT-14-Lot',
    'BT-14-Part',
    'BT-01-notice',
  ];

  private modelValidators: ModelValidator<any>[] = [];

  private mandatoryValidator = new MandatoryValidator();
  private mandatoryValidatorForMultilingual = new MandatoryForMultilingualValidator();
  private mandatoryForAmountAndUnitValidator = new MandatoryForAmountAndUnitValidator();
  private _formNavigableNotifications = new BehaviorSubject<NavigableNotification[]>([]);
  private _entireValidationOngoing: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dateUtilsService: DateUtilsService,
    private businessRuleValidationService: BusinessRuleValidationService,
    private communicationService: CommunicationService,
    private formTypeService: FormTypeService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private stateService: StateService,
    private syncedFieldService: SyncedFieldService,
    private conceptModelService: ConceptModelService,
    private holidayUtilsService: HolidayUtilsService,
    private maintenanceWindowService: MaintenanceWindowService,
    private modelStateService: ModelStateService,
    private procedureTypeService: ProcedureTypeService,
    private translationService: TranslationService,
    private modelChangeDetectionService: ModelChangeDetectionService
  ) {
    this.initializeValidators();
  }

  public async validateModel(
    fieldModel: FieldModel<any>,
    validateDependencies: boolean,
    conceptModel?: ConceptModel,
    collectNotifications = true,
    draft = false
  ): Promise<NavigableNotification[]> {
    if (fieldModel.isForbidden) {
      fieldModel.validationNotifications = [];
      if (validateDependencies) {
        await this.triggerDependencyValidation(fieldModel, conceptModel);
      }
      return [];
    }
    const validationResult: ValidationNotification[] = [];

    // Execute just Mandatory and BusinessRuleValidator, when value is null.
    const applicableValidators = draft
      ? this.getDraftValidatorsForModel(fieldModel)
      : this.getValidatorsForModel(fieldModel);

    const firstStageValidationNotifications = await this.runValidators(
      this.getFirstStageValidators(applicableValidators),
      fieldModel,
      conceptModel
    );
    validationResult.push(...firstStageValidationNotifications);
    if (firstStageValidationNotifications.length === 0) {
      const secondStageValidationNotification = await this.runValidators(
        this.getSecondStageValidators(applicableValidators),
        fieldModel,
        conceptModel
      );
      validationResult.push(...secondStageValidationNotification);
    }

    const changed = validationResult.length > 0 || fieldModel.validationNotifications.length > 0;
    fieldModel.validationNotifications = validationResult;
    if (changed) {
      fieldModel.emitChange();
      // also update TimeComponent to reflect error state
      if (fieldModel instanceof DateModel) {
        fieldModel?.relatedTimeModel?.updateView();
      }
    }

    if (collectNotifications) {
      this.refreshNotifications(fieldModel.root, true);
    }

    fieldModel.touched = true;
    if (validateDependencies) {
      await this.triggerDependencyValidation(fieldModel, conceptModel);
    }

    return this.mapModelNotifications(fieldModel);
  }

  public refreshNotifications(componentModel: ComponentModel, markFormAsModified = false) {
    if (markFormAsModified) {
      this.stateService.setModified(true);
    }

    this.updateModelChangeType(componentModel);
    this.formNavigableNotifications.next(this.collectNotifications(componentModel));
  }

  /**
   * Starts the Validation of the entire ComponentModel.
   *
   * @param componentModel
   */
  public async validateAll(componentModel: ComponentModel): Promise<boolean> {
    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );
    const validationNotifications = await this._validateTree(componentModel, conceptModel);

    this._formNavigableNotifications.next(validationNotifications);

    const valid = !validationNotifications.some(
      item => item.validationNotification.severity === Severity.ERROR
    );
    this.updateModelChangeType(componentModel);
    return valid;
  }

  /**
   * Starts the Draft Validation, which only requires a subset of the validation rules.
   *
   * @param componentModel
   */
  public async validateForDraft(componentModel: ComponentModel): Promise<boolean> {
    this.resetModelFormValidation(componentModel);
    const conceptModel = await this.conceptModelService.generateConceptModel(
      this.modelStateService.componentModel
    );
    const validationNotifications = await this._validateTree(componentModel, conceptModel, true);
    this._formNavigableNotifications.next(validationNotifications);
    return !validationNotifications.some(
      item => item.validationNotification.severity === Severity.ERROR
    );
  }

  private async runValidators(
    validators: ModelValidator<any>[],
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel
  ): Promise<ValidationNotification[]> {
    const result = await Promise.all(validators.map(v => v.validate(fieldModel, conceptModel)));
    return result.flat();
  }

  private getFirstStageValidators(validators: ModelValidator<any>[]): ModelValidator<any>[] {
    return validators.filter(v => this.isMandatoryValidator(v));
  }

  private getSecondStageValidators(validators: ModelValidator<any>[]): ModelValidator<any>[] {
    return validators.filter(v => !this.isMandatoryValidator(v));
  }

  private isMandatoryValidator(v: ModelValidator<any>): boolean {
    return (
      v instanceof MandatoryValidator ||
      v instanceof MandatoryForMultilingualValidator ||
      v instanceof MandatoryForAmountAndUnitValidator
    );
  }

  /**
   * Gets the applicable Validators for the given FieldModel.
   *
   * @param fieldModel to get the Validators for.
   */
  private getValidatorsForModel<T extends FieldModel<any>>(fieldModel: T): ModelValidator<any>[] {
    return this.modelValidators
      .filter(
        // Only allow EVergabe Validators, when in SEB / OBA-Client
        validator =>
          !(
            !this.editorConfigurationProvider.isEVergabePlatform() &&
            validator instanceof EVergabeSpecificValidator
          )
      )
      .filter(validator => this.shouldAttachModelValidatorWhenModelEmpty(validator, fieldModel))
      .filter(validator => validator.shouldAttach(fieldModel));
  }

  private updateModelChangeType(componentModel: ComponentModel) {
    const changeType = this.modelChangeDetectionService.getModelChangeType(componentModel);
    this.stateService.setFormChangeType(changeType);
  }

  /**
   * Returns regular Validators in addition to Mandatory Validators, if not already given.
   * All ModelValidationService.BTS_FOR_DRAFT are mandatory.
   */
  private getDraftValidatorsForModel<T extends FieldModel<any>>(
    fieldModel: T
  ): ModelValidator<any>[] {
    const validators = this.getValidatorsForModel(fieldModel);
    if (!fieldModel.isMandatory) {
      if (fieldModel instanceof MultilingualModel) {
        validators.push(this.mandatoryValidatorForMultilingual);
      } else if (fieldModel instanceof AmountModel || fieldModel instanceof MeasureModel) {
        validators.push(this.mandatoryForAmountAndUnitValidator);
      } else {
        validators.push(this.mandatoryValidator);
      }
    }

    return validators;
  }

  /**
   * When the Model is empty, only Mandatory and BusinessRuleValidator should be executed, so only these Errors will be shown.
   */
  private shouldAttachModelValidatorWhenModelEmpty(
    validator: ModelValidator<any>,
    fieldModel: FieldModel<any>
  ): boolean {
    if (fieldModel.value === null || fieldModel.value === undefined) {
      return (
        validator instanceof BusinessRuleValidator ||
        validator instanceof MandatoryValidator ||
        validator instanceof MandatoryForMultilingualValidator ||
        validator instanceof MandatoryForAmountAndUnitValidator
      );
    }
    return true;
  }

  /**
   * Deletes the ValidationNotifications in all Models, by traversing the whole form-tree.
   */
  private resetModelFormValidation(model: ComponentModel | BaseModel) {
    if (
      model instanceof GroupModel ||
      model instanceof SectionModel ||
      model instanceof RepeatableGroupModel ||
      model instanceof RepeatableFieldModel ||
      model instanceof RepeatableSectionModel ||
      model instanceof ComponentModel
    ) {
      model.children.forEach((child: BaseModel | ComponentModel) =>
        this.resetModelFormValidation(child)
      );
    } else {
      model.validationNotifications = [];
      model.emitChange();
    }
  }

  private async triggerDependencyValidation(
    fieldModel: FieldModel<any>,
    conceptModel: ConceptModel
  ) {
    const dependencyModels = ModelStateService.findModelsByDependencyId(
      fieldModel.root,
      fieldModel.noticeNode.id
    );

    const syncedModels = this.syncedFieldService.findSyncedModels(fieldModel);
    if (syncedModels.length > 0) {
      dependencyModels.push(...syncedModels);
    }

    if (fieldModel instanceof TimeModel) {
      if (!dependencyModels.includes(fieldModel.relatedDateModel)) {
        dependencyModels.push(fieldModel.relatedDateModel);
      }
    }

    if (fieldModel instanceof DateModel) {
      if (fieldModel.relatedTimeModel && !dependencyModels.includes(fieldModel.relatedTimeModel)) {
        dependencyModels.push(fieldModel.relatedTimeModel);
      }
    }

    await Promise.all(
      dependencyModels
        .filter(model => model instanceof FieldModel && model.touched)
        .map(model => this.validateModel(model as FieldModel<any>, false, conceptModel, false))
    );

    this.refreshNotifications(fieldModel.root);
  }

  private async _validateTree(
    model: BaseModel | ComponentModel,
    conceptModel: ConceptModel,
    draft = false
  ): Promise<NavigableNotification[]> {
    if (
      model instanceof GroupModel ||
      model instanceof SectionModel ||
      model instanceof RepeatableGroupModel ||
      model instanceof RepeatableFieldModel ||
      model instanceof RepeatableSectionModel ||
      model instanceof ComponentModel
    ) {
      const validationNotifications = await Promise.all(
        model.children.map((childModel: BaseModel | ComponentModel) =>
          this._validateTree(childModel, conceptModel, draft)
        )
      );
      return validationNotifications.flat();
    } else {
      if (draft) {
        return this.validateDraftInTree(model as FieldModel<any>, conceptModel);
      }

      return this.validateModel(model as FieldModel<any>, false, conceptModel, false);
    }
  }

  private validateDraftInTree(
    model: FieldModel<any>,
    conceptModel: ConceptModel
  ): Promise<NavigableNotification[]> {
    if (!ModelValidationService.BTS_FOR_DRAFT.includes(model.noticeNode.id)) {
      return Promise.resolve([]);
    } else {
      return this.validateModel(model, false, conceptModel, false, true);
    }
  }

  /**
   * Iterates over the Model and collects all ValidationNotifications of each Model.
   */
  private collectNotifications(model: BaseModel | ComponentModel): NavigableNotification[] {
    if (
      model instanceof GroupModel ||
      model instanceof SectionModel ||
      model instanceof RepeatableGroupModel ||
      model instanceof RepeatableFieldModel ||
      model instanceof RepeatableSectionModel ||
      model instanceof ComponentModel
    ) {
      return model.children
        .map((child: BaseModel | ComponentModel) => this.collectNotifications(child))
        .flat();
    } else {
      if(model instanceof FieldModel && model.isForbidden){
        return [];
      }
      return this.mapModelNotifications(model);
    }
  }

  private mapModelNotifications(model: BaseModel): NavigableNotification[] {
    return model.validationNotifications
      .map(notification => new NavigableNotification(notification, model))
      .concat(
        model.fieldNotifications
          .map(fieldNotification => new NavigableNotification(fieldNotification, model))
      );
  }

  private initializeValidators() {
    this.add(this.mandatoryValidator);
    this.add(this.mandatoryValidatorForMultilingual);
    this.add(this.mandatoryForAmountAndUnitValidator);
    this.add(new MaxLengthValidator());
    this.add(new MaxLengthForMultilingualValidator());
    this.add(new PatternValidator());
    this.add(new BusinessRuleValidator(this.businessRuleValidationService));
    this.add(new FiveDaysInFutureWarningValidator(this.dateUtilsService));
    this.add(new TooFarInPastValidator());
    this.add(
      new InternalReferenceValidator(this.communicationService, this.editorConfigurationProvider)
    );
    this.add(new DeadlineInPastValidator(this.dateUtilsService));
    this.add(new DeadlineTodayValidator(this.dateUtilsService, this.editorConfigurationProvider));
    this.add(new DeadlineOnHolidayValidator(this.holidayUtilsService));
    this.add(
      new DeadlineOnMaintenanceWindowCollisionValidator(
        this.maintenanceWindowService,
        this.editorConfigurationProvider,
        this.dateUtilsService
      )
    );
    this.add(new DeadlineOnWeekendValidator());
    this.add(
      new ArchivingDateBehindAllOfferDeadlinesValidator(
        this.formTypeService,
        this.editorConfigurationProvider,
        this.communicationService,
        this.dateUtilsService,
        this.procedureTypeService
      )
    );
    this.add(
      new ArchivingDeadlineTodayValidator(this.dateUtilsService, this.editorConfigurationProvider)
    );
    this.add(new ArchivingDateInValidCenturyValidator());
    this.add(new TimeValidator());
    this.add(new ProcedureDeadlineAfterParticipationDeadlineValidator());
    this.add(new ValueInCodelistValidator());
    this.add(new ArchivingDateBehindOfferValidityPeriod());
    this.add(
      new OfferOpeningDeadlineAfterOfferDeadlineValidator(
        this.editorConfigurationProvider,
        this.translationService
      )
    );
    this.add(new MetaOfferDeadlineNotBeforeLastParticipationDeadline());
    this.add(new PreferredPublicationDateNotInPastValidator(this.dateUtilsService));
    this.add(new DeadlineAfterPreferredPublicationDateValidator(this.editorConfigurationProvider));
    this.add(new IncompleteAmountAndUnitInputValidator());
    this.add(new MainNatureInAtLeastOneLotValidator());
    this.add(new OfferTypeValidator());
    this.add(new CvdBt723Validator())
  }

  private add(validator: ModelValidator<any>) {
    this.modelValidators.push(validator);
  }

  get formNavigableNotifications(): BehaviorSubject<NavigableNotification[]> {
    return this._formNavigableNotifications;
  }

  get entireValidationOngoing(): Subject<boolean> {
    return this._entireValidationOngoing;
  }
}
