<div class="font-size-box">
  <button
    mat-mini-fab
    (click)="changeFontsize(false)"
    [disabled]="!canDecrease"
    class="eForm-icon-button normal"
    matTooltip="Schriftgröße verringern"
    aria-label="Schriftgröße verringern">
    <mat-icon>&minus;</mat-icon>
  </button>
  <mat-icon aria-hidden="false" fontIcon="text_fields" class="font-icon"></mat-icon>
  <button
    mat-mini-fab
    (click)="changeFontsize(true)"
    [disabled]="!canIncrease"
    class="eForm-icon-button normal"
    matTooltip="Schriftgröße erhöhen"
    aria-label="Schriftgröße erhöhen">
    <mat-icon>+</mat-icon>
  </button>
</div>
