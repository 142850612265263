<div class="training-mode item" *ngIf="isTrainingMode">
  <mat-icon class="icon-default bell-icon" aria-hidden="true">notification_important</mat-icon>
  <span>Achtung Schulungsmodus!</span>
</div>

<div class="item">
  <app-collapse-expand></app-collapse-expand>
</div>

<div class="item">
  <app-font-size></app-font-size>
</div>

<div class="item search">
  <app-search class="search-host"></app-search>
</div>

<div class="item mode-toggle" *ngIf="devModeAvailable && (showDevModeToggle$ | async) === true">
  <mat-icon class="icon-default mode-icon" aria-hidden="true">bug_report</mat-icon>
  <mat-slide-toggle
    [formControl]="devModeControl"
    class="eFormsSlider"
    aria-label="Schalter für den Entwicklungsmodus">
  </mat-slide-toggle>
</div>

<div class="item mode-toggle">
  <mat-icon class="icon-default mode-icon" fontSet="material-icons-outlined" aria-hidden="true"
    >support_agent</mat-icon
  >
  <!-- // ReSy: andere Tooltips anzeigen -->
  <mat-slide-toggle
    [formControl]="supportModeControl"
    class="eFormsSlider"
    matTooltip="Schalter für den Support-Modus"
    aria-label="Schalter für den Support-Modus">
  </mat-slide-toggle>
</div>
