import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatCalendarCellClassFunction, MatDatepicker } from '@angular/material/datepicker';
import { DatepickerService } from '../../../services/datepicker.service';
import { DateModel } from '../../../view-model/type/date-model';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { DeadlineOnMaintenanceWindowCollisionValidator } from '../../../services/validation/validators/deadline/DeadlineOnMaintenanceWindowCollisionValidator';
import { DateUtilsService } from '../../../utils/date-utils-service';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { Locale } from '@js-joda/locale_de-de';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent extends AbstractFormComponent implements OnInit, AfterViewInit {
  inputControl: FormControl = new FormControl();
  model: DateModel;
  datepickerFilter: (date: LocalDate) => boolean;
  dateClass: MatCalendarCellClassFunction<any>;

  today: LocalDate;
  todayBtnLabel: string;

  @ViewChild('matDatePicker') datePicker: MatDatepicker<Date>;
  @ViewChild('datePickerFooter') datePickerFooter: ElementRef;

  constructor(
    private datepickerService: DatepickerService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private dateUtilsService: DateUtilsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit(): void {
    this.initValue();

    if (
      DeadlineOnMaintenanceWindowCollisionValidator.BTS_FOR_DEADLINE_IN_MW_VALIDATION.includes(
        this.model.noticeNode.id
      )
    ) {
      this.dateClass = this.datepickerService.highlightMaintenanceWindowDatesAndHolidays;
      if (this.editorConfigurationProvider.isProductionMode()) {
        this.datepickerFilter = this.datepickerService.filterMaintenanceWindows(this.model);
      }
    }

    if (this.model.isReadonly) {
      this.inputControl.disable({ emitEvent: true });
      this.cdRef.markForCheck();
    }

    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => {
        this.cdRef.markForCheck();
      })
    );
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (!this.model.isReadonly) {
      this.handleValueChanges();
    }
  }

  onInputValueChanged() {
    super.onInputValueChanged();

    if (!this.model.isMandatory && !this.inputControl.value) {
      this.inputControl.setValue(null, { emitEvent: false, emitModelToViewChange: true });
    }
  }

  clearInput() {
    this.inputControl.setValue(null);
    this.onInputValueChanged();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.model.isReadonly) {
      if (event.key === 'Enter') {
        this.datePicker.open();
        event.preventDefault();
      }
      if (event.key === 'Escape') {
        this.datePicker.close();
        event.preventDefault();
      }
    }
  }

  setToday() {
    this.inputControl.setValue(this.today);
    this.datePicker.close();
  }

  onOpen() {
    this.today = this.dateUtilsService.getToday();
    this.addTodayBtnToDatepicker();
  }

  private addTodayBtnToDatepicker() {
    const matDatepickerContentCloseBtn = this.document.querySelector(
      '.mat-datepicker-content-container .mat-datepicker-close-button'
    );

    if (matDatepickerContentCloseBtn) {
      this.todayBtnLabel = `Heute ist der ${this.today.format(
        DateTimeFormatter.ofPattern('d. MMM uuuu').withLocale(Locale.GERMANY)
      )}`;

      matDatepickerContentCloseBtn.before(this.datePickerFooter.nativeElement);
    }
  }

  private initValue(): void {
    if (this.model.value) {
      this.inputControl.setValue(this.model.value, { emitEvent: false });
    }
  }

  private handleValueChanges(): void {
    this.subscription.add(
      this.inputControl.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        if (value) {
          value = value.format(DateTimeFormatter.ISO_LOCAL_DATE);
        }
        this.model.value = value;
      })
    );
  }
}
