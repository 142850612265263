import { SectionModel } from './section-model';
import { RepeatableSectionModel } from './repeatable-section-model';
import { Language } from '../types/data-model';
import { FormType } from '../types/form-type';

export class ComponentModel {
  private _children: (SectionModel | RepeatableSectionModel)[] = [];
  private _noticeId: string;
  private _activeLanguages: Language[];
  private _formType: FormType;

  /**
   * Tracks all Lots by ID, which initially have been loaded, but then have been deleted.
   * Initially Loaded Lots can have attached documents on the Server, which also have to be deleted when the Lot doesn't exist anymore.
   * Change Lots and Added lots can be tracked by their initial data
   */
  private _deletedInitialLotIds = [];

  get children(): (SectionModel | RepeatableSectionModel)[] {
    return this._children;
  }

  set children(value: (SectionModel | RepeatableSectionModel)[]) {
    this._children = value;
  }

  get noticeId(): string {
    return this._noticeId;
  }

  set noticeId(value: string) {
    this._noticeId = value;
  }

  get activeLanguages(): Language[] {
    return this._activeLanguages;
  }

  set activeLanguages(value: Language[]) {
    this._activeLanguages = value;
  }

  get formType(): FormType {
    return this._formType;
  }

  set formType(value: FormType) {
    this._formType = value;
  }

  get deletedInitialLotIds(): any[] {
    return this._deletedInitialLotIds;
  }
}
