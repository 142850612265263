import { inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { from, of, Subject, interval, Observable } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GenericDialogService } from '../../services/ui/generic-dialog.service';
import { NoticeLockControllerService } from '../api/notices';
import { ErrorType } from './error-type.enum';

@Injectable({
  providedIn: 'root'
})
export class RefreshNoticeLockService implements OnDestroy {

  private static REFRESH_INTERVAL = 20 * 1000;

  private stop$: Subject<void> = new Subject<void>();

  private router = inject(Router);
  private lockService = inject(NoticeLockControllerService);
  private genericDialogService = inject(GenericDialogService);

  start(rsNoticeId: number): void {
    interval(RefreshNoticeLockService.REFRESH_INTERVAL).pipe(
      takeUntil(this.stop$),
      switchMap(() => this.lockService.createOrUpdateLock(rsNoticeId).pipe(
        catchError(err => {
          if (err.error?.errorType === ErrorType.NOTICE_LOCKED) {
            this.stop();
            return this.showNoticeLockedErrorDialog('Bearbeitung aktuell nicht möglich').pipe(
              tap(() => this.router.navigate(['bekanntmachungen', 'verwalten'], {
                state: { bypassLockDeletion: true } // Im RemoveLockGuard das Loeschen des Locks ueberspringen, da es nicht mehr mir gehoert
              })),
            );
          }
          return of(null);
        }),
      )),
    ).subscribe();
  }

  stop(): void {
    this.stop$.next();
  }

  showNoticeLockedErrorDialog(title: string): Observable<boolean> {
    return from(this.genericDialogService.showResultDialog(
      title,
      'Diese Bekanntmachung wird aktuell von einer anderen Person bearbeitet.',
      'OK',
      false
    ));
  }

  ngOnDestroy(): void {
    this.stop();
  }
}
