<div class="repeatable-group-wrapper">
  <mat-expansion-panel [expanded]="model.isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title [matTooltip]="model.customTitle">
        <app-address-book
          *ngIf="
            this.model.noticeNode.id === 'GR-Organisations' ||
            this.model.noticeNode.id === 'GR-Touch-Point'
          "
          [model]="this.model">
        </app-address-book>
        <app-selection-criteria-book *ngIf="isSelectionCriteriaBook()" [model]="this.model">
        </app-selection-criteria-book>

        <span [outerHTML]="getPanelTitle()"></span>

        <div class="custom-group-title" *ngIf="model.customTitle">
          {{ model.customTitle }}
        </div>

        <span *ngIf="supportMode" class="support-mode-label-right">{{
          this.model.noticeNode.id + '-' + model.repeatableIndex
        }}</span>
      </mat-panel-title>
      <div *ngIf="!this.model.isReadonly" class="button-container">
        <button
          mat-mini-fab
          (click)="delete(); $event.stopPropagation();"
          (keydown.space)="delete(); stopDefaultBehavior($event)"
          (keydown.enter)="delete(); stopDefaultBehavior($event)"
          *ngIf="showDeleteButton"
          class="repeatable-control eForm-icon-button danger"
          matTooltip="Element entfernen"
          aria-label="Element entfernen">
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-mini-fab
          (click)="add(); $event.stopPropagation();"
          (keydown.space)="add(); stopDefaultBehavior($event)"
          (keydown.enter)="add(); stopDefaultBehavior($event)"
          *ngIf="showAddButton"
          class="repeatable-control eForm-icon-button normal"
          matTooltip="Element hinzufügen"
          aria-label="Element hinzufügen">
          <mat-icon>+</mat-icon>
        </button>
      </div>
    </mat-expansion-panel-header>
    <div
      class="hint-wrapper hint-wrapper-group"
      *ngIf="model.tooltips && model.tooltips.length > 0">
      <div *ngFor="let tooltip of model.tooltips" class="hint">
        <mat-icon>info</mat-icon>
        <p>{{ tooltip }}</p>
      </div>
    </div>
    <div class="expansion-container">
      <ng-template view></ng-template>
    </div>
  </mat-expansion-panel>
</div>
