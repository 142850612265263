import { Pipe, PipeTransform } from '@angular/core';
import { NoticeTypesService } from '../notice-types.service';
import { Observable } from 'rxjs';

@Pipe({ name: 'formTypeToLabel' })
export class FormTypeToLabelPipe implements PipeTransform {
  constructor(private noticeTypesService: NoticeTypesService) {}

  transform(formType: string): Observable<string> {
    return this.noticeTypesService.getFormTypeLabelByFormType(formType);
  }
}
