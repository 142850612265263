<div *ngFor="let aktion of notice.notice?.actions">
  <ng-container *ngIf="aktion.subTypeIds">
    <ng-container *ngIf="aktion.subTypeIds.length === 1">
      <ng-container
        [ngTemplateOutlet]="actionLink"
        [ngTemplateOutletContext]="{
          aktion: aktion.action,
          subTypeId: aktion.subTypeIds[0]
        }"></ng-container>
    </ng-container>
    <ng-container *ngIf="aktion.subTypeIds.length > 1">
      <div class="action-with-info">
        <a class="action-link" [matMenuTriggerFor]="menu"
          >{{ aktion.action.type | noticeActionTypeToLabel }}
        </a>
        <mat-icon>expand_more</mat-icon>
      </div>
      <mat-menu #menu="matMenu" class="navigation-menu">
        <ng-container
          *ngFor="let subTypeId of aktion.subTypeIds; let last = last"
          [ngTemplateOutlet]="menuActionLink"
          [ngTemplateOutletContext]="{ aktion: aktion.action, subTypeId, last }"></ng-container>
      </mat-menu>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!aktion.subTypeIds">
    <ng-container
      [ngTemplateOutlet]="actionLink"
      [ngTemplateOutletContext]="{
        aktion: aktion.action,
        subTypeId: notice.notice.subTypeId
      }"></ng-container>
  </ng-container>
</div>

<ng-template #actionLink let-aktion="aktion" let-subTypeId="subTypeId">
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="aktion.type === 'SHOW_PREVIEW'">
      <div class="action-with-info">
        <a
          class="action-link"
          (click)="showPreview(aktion.type)"
          [ngClass]="{ history: !notice.isNewestNoticeForSubtypeId }">
          {{ aktion.type | noticeActionTypeToLabel }}
        </a>
        <mat-icon>open_in_new</mat-icon>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="aktion.type === 'DELETE_DRAFT'">
      <a class="action-link" (click)="deleteDraft(aktion.type)">
        {{ aktion.type | noticeActionTypeToLabel }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="aktion.type === 'STOP'">
      <a class="action-link" (click)="stopNotice(aktion.type)">
        {{ aktion.type | noticeActionTypeToLabel }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="aktion.type === 'EXPORT_CN'">
      <div class="action-with-info">
        <a
          class="action-link"
          (click)="exportNotice(aktion.type)"
          [ngClass]="{ history: !notice.isNewestNoticeForSubtypeId }">
          {{ aktion.type | noticeActionTypeToLabel }}
        </a>
        <mat-icon>ios_share</mat-icon>
        <mat-icon class="cursor-pointer" matTooltip="Stellt XML-Daten für Fehlerbehebung bereit."
          >info</mat-icon
        >
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="aktion.type === 'EDIT_DRAFT'">
      <a class="action-link" (click)="editDraft(subTypeId, aktion)">
        {{ aktion.type | noticeActionTypeToLabel }}
      </a>
    </ng-container>
    <ng-container
      *ngSwitchCase="
        aktion.type === 'EDIT_SENT_NOTICE' ||
        aktion.type === 'EDIT_SENT_CHANGE_NOTICE' ||
        aktion.type === 'CREATE_CHANGE_NOTICE' ||
        aktion.type === 'CREATE_CN' ||
        aktion.type === 'CREATE_CAN' ||
        aktion.type === 'CREATE_CAN_MODIF'
      ">
      <a class="action-link" (click)="createUpdateOrSuccessor(subTypeId, aktion)">
        {{ aktion.type | noticeActionTypeToLabel }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="aktion.type === 'CREATE_TEMPLATE'">
      <a
        class="action-link"
        [ngClass]="{ history: !notice.isNewestNoticeForSubtypeId }"
        (click)="vorlageErstellen()"
        >Vorlage erzeugen
      </a>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <a
        class="action-link"
        [routerLink]="['notice', subTypeId, 'details']"
        [queryParams]="buildQueryParamsNotice(aktion, notice)">
        {{ aktion.type | noticeActionTypeToLabel }}
      </a>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #menuActionLink let-aktion="aktion" let-subTypeId="subTypeId" let-last="last">
  <a class="action-link" (click)="createUpdateOrSuccessor(subTypeId, aktion)">
    {{ subTypeId | subTypeIdToName | async }}
  </a>
  <mat-divider *ngIf="!last"></mat-divider>
</ng-template>

<app-vorlagen-name-dialog
  #vorlagenNameDialog
  (confirm)="createTemplate($event)"></app-vorlagen-name-dialog>
