import { FieldInfo } from '../types/field-types';
import { NoticeNode } from '../types/notice-definition';
import { GroupModel } from './group-model';
import { SectionModel } from './section-model';
import { ComponentModel } from './component-model';
import { BusinessRuleResult, ValidationNotification } from '../types/app-types';
import { RepeatableFieldModel } from './repeatable-field-model';
import { FieldModel } from './field-model';
import { RepeatableGroupModel } from './repeatable-group-model';
import { RepeatableSectionModel } from './repeatable-section-model';
import { EventEmitter } from '@angular/core';

export type ModelWithChildren =
  | SectionModel
  | GroupModel
  | ComponentModel
  | RepeatableGroupModel
  | RepeatableFieldModel<any>
  | RepeatableSectionModel;

export type ModelWithParent =
  | SectionModel
  | GroupModel
  | FieldModel<any>
  | RepeatableGroupModel
  | RepeatableSectionModel;

export abstract class BaseModel {
  private static GLOBAL_MODEL_INDEX = 0;
  private _modelIndex = BaseModel.GLOBAL_MODEL_INDEX++;
  private _parent: ModelWithChildren;
  private _fieldInfo: FieldInfo;
  private _noticeNode: NoticeNode;
  private _isInsideRepeatable = false;
  private _isMandatory: boolean;
  private _isForbidden: boolean;
  private _isReadonly: boolean;
  private _isHidden: boolean;
  private _repeatableIndex: number;
  private _repeatable: boolean;
  private _validationNotifications: ValidationNotification[] = [];
  private _businessRulesResult: BusinessRuleResult[] = [];
  // Stores Notifications, which are present all the Time
  private _fieldNotifications: ValidationNotification[] = [];
  private _depth = 0;
  private _modelChangedEmitter = new EventEmitter<void>();
  private _updateViewEmitter = new EventEmitter<void>();
  private _translatedLabel: string;
  private _tooltips: string[] = [];
  private _root: ComponentModel;
  private _focus = false;
  private _description: string;

  async loadSdkProperties() {
    /* Can be implemented by models, to load init data from the SDK */
  }

  /**
   * Notifies components about a Model-Value change. Used to update their related controls with the new Value and trigger a change detection.
   */
  emitChange() {
    this._modelChangedEmitter.emit();
  }

  /**
   * Triggers a change-detection on currently subscribed components. Useful, on property-changes, like mandatory or readonly.
   */
  updateView() {
    this._updateViewEmitter.emit();
  }

  get depth(): number {
    return this._depth;
  }

  set depth(value: number) {
    this._depth = value;
  }

  get parent(): ModelWithChildren {
    return this._parent;
  }

  set parent(value: ModelWithChildren) {
    this._parent = value;
  }

  get fieldInfo(): FieldInfo {
    return this._fieldInfo;
  }

  set fieldInfo(value: FieldInfo) {
    this._fieldInfo = value;
  }

  get noticeNode(): NoticeNode {
    return this._noticeNode;
  }

  set noticeNode(value: NoticeNode) {
    this._noticeNode = value;
  }

  get isInsideRepeatable(): boolean {
    return this._isInsideRepeatable;
  }

  set isInsideRepeatable(value: boolean) {
    this._isInsideRepeatable = value;
  }

  get isMandatory(): boolean {
    return this._isMandatory;
  }

  set isMandatory(value: boolean) {
    this._isMandatory = value;
  }

  get isReadonly(): boolean {
    return this._isReadonly;
  }

  set isReadonly(value: boolean) {
    this._isReadonly = value;
  }

  get isHidden(): boolean {
    return this._isHidden;
  }

  set isHidden(value: boolean) {
    this._isHidden = value;
  }

  get repeatableIndex(): number {
    return this._repeatableIndex;
  }

  set repeatableIndex(value: number) {
    this._repeatableIndex = value;
  }

  get repeatable(): boolean {
    return this._repeatable;
  }

  set repeatable(value: boolean) {
    this._repeatable = value;
  }

  get validationNotifications(): ValidationNotification[] {
    return this._validationNotifications;
  }

  set validationNotifications(value: ValidationNotification[]) {
    this._validationNotifications = value;
  }

  get businessRulesResult(): BusinessRuleResult[] {
    return this._businessRulesResult;
  }

  set businessRulesResult(value: BusinessRuleResult[]) {
    this._businessRulesResult = value;
  }

  get fieldNotifications(): ValidationNotification[] {
    return this._fieldNotifications;
  }

  set fieldNotifications(value: ValidationNotification[]) {
    this._fieldNotifications = value;
  }

  get modelChangedEmitter(): EventEmitter<void> {
    return this._modelChangedEmitter;
  }

  get updateViewEmitter(): EventEmitter<void> {
    return this._updateViewEmitter;
  }

  get translatedLabel(): string {
    return this._translatedLabel;
  }

  set translatedLabel(value: string) {
    this._translatedLabel = value;
  }

  get tooltips(): string[] {
    return this._tooltips;
  }

  set tooltips(value: string[]) {
    this._tooltips = value;
  }

  get root(): ComponentModel {
    return this._root;
  }

  set root(value: ComponentModel) {
    this._root = value;
  }

  get focus(): boolean {
    return this._focus;
  }

  set focus(value: boolean) {
    this._focus = value;
  }

  get isForbidden(): boolean {
    return this._isForbidden;
  }

  set isForbidden(value: boolean) {
    this._isForbidden = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get modelIndex(): number {
    return this._modelIndex;
  }
}
