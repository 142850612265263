import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '../../../services/state.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { Language } from '../../../types/data-model';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { CodelistModel } from '../../../view-model/type/codelist-model';
import { RepeatableFieldModel } from '../../../view-model/repeatable-field-model';
import { ModelGenerationService } from '../../../services/view-model/model-generation.service';
import { EFormsMetaDataService } from '../../../services/view-model/eforms-meta-data.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  controlGerman: FormControl;
  controlEnglish: FormControl;

  isUpdateMetaData: boolean;
  isTrainingMode: boolean;
  isEVergabe: boolean;

  private subscription = new Subscription();

  constructor(
    private stateService: StateService,
    private modelGenerationService: ModelGenerationService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private eFormsMetaDataService: EFormsMetaDataService
  ) {
    this.isUpdateMetaData = this.editorConfigurationProvider.isUpdateMetaData();
    this.isTrainingMode = this.editorConfigurationProvider.isTrainingMode();
    this.isEVergabe = this.editorConfigurationProvider.isEVergabePlatform();
    this.controlGerman = new FormControl();
    this.controlEnglish = new FormControl();

    if(this.editorConfigurationProvider.isPublished()){
      this.controlEnglish.disable();
      this.controlGerman.disable();
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.isUpdateMetaData) {
      return;
    }

    this.subscription.add(this.handleGermanChanges());
    this.subscription.add(this.handleEnglishChanges());
    this.subscription.add(this.handleLanguageChanges());

    const languages = await firstValueFrom(this.stateService.getLanguages());

    this.controlEnglish.setValue(languages.includes(Language.Eng), { emitEvent: false });
    this.controlGerman.setValue(languages.includes(Language.Deu), { emitEvent: false });

    if (!this.controlGerman.value) {
      this.controlEnglish.disable({ emitEvent: false });
    }
    if (!this.controlEnglish.value) {
      this.controlGerman.disable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private handleLanguageChanges(): Subscription {
    return this.stateService.getLanguages().subscribe(async languages => {
      let mainLang = Language.Deu;
      let secondLang = null;

      if (!languages.find(lang => lang === Language.Deu)) {
        mainLang = Language.Eng;
      } else if (languages.find(lang => lang === Language.Eng)) {
        secondLang = Language.Eng;
      }

      const mainLangModel = this.eFormsMetaDataService.eFormsMetaData.children.find(
        child => child.noticeNode.id === 'BT-702(a)-notice'
      ) as CodelistModel;
      mainLangModel.value = mainLang;

      const secondLangModel = this.eFormsMetaDataService.eFormsMetaData.children.find(
        child => child.noticeNode.id === 'BT-702(b)-notice'
      ) as RepeatableFieldModel<string>;

      if (!secondLang) {
        secondLangModel.children.pop();
      } else {
        if (secondLangModel.children.length === 0) {
          const clonedNoticeNode = structuredClone(secondLangModel.noticeNode);
          clonedNoticeNode._repeatable = false;
          const languageFieldModel = await this.modelGenerationService.addField(
            clonedNoticeNode,
            secondLangModel
          );
          secondLangModel.addChild(languageFieldModel);
        }
        secondLangModel.children[0].value = secondLang;
      }

      this.eFormsMetaDataService.eFormsMetaData.emitChange();
    });
  }

  private handleGermanChanges(): Subscription {
    return this.controlGerman.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      if (value) {
        this.stateService.addLanguage(Language.Deu);
      } else {
        this.stateService.removeLanguage(Language.Deu);
      }

      if (!this.controlGerman.value) {
        this.controlEnglish.disable();
      } else {
        this.controlEnglish.enable();
      }
    });
  }

  private handleEnglishChanges(): Subscription {
    return this.controlEnglish.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      if (value) {
        this.stateService.addLanguage(Language.Eng);
      } else {
        this.stateService.removeLanguage(Language.Eng);
      }

      if (!this.controlEnglish.value) {
        this.controlGerman.disable();
      } else {
        this.controlGerman.enable();
      }
    });
  }
}
