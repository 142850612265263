import { FieldModel } from './field-model';
import { BaseModel } from './base-model';
import { RepeatableGroupModel } from './repeatable-group-model';
import { Subject } from 'rxjs';

type GroupChildren = FieldModel<any> | GroupModel | RepeatableGroupModel;
export class GroupModel extends BaseModel {
  private _children?: GroupChildren[] = [];
  private _isExpanded = false;
  private _showDescription = false;
  private _afterExpand = new Subject<void>();
  private _customTitle?: string;

  get children(): GroupChildren[] {
    return this._children;
  }

  public addChild(model: GroupChildren): void {
    this._children.push(model);
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
    this.emitChange();
  }

  get showDescription(): boolean {
    return this._showDescription;
  }

  set showDescription(value: boolean) {
    this._showDescription = value;
    this.updateView();
  }

  set afterExpand(value: Subject<void>) {
    this._afterExpand = value;
  }

  get afterExpand(): Subject<void> {
    return this._afterExpand;
  }

  get customTitle(): string {
    return this._customTitle;
  }

  set customTitle(value: string) {
    this._customTitle = value;
  }
}
