<form [formGroup]="stateForm">
  <mat-form-field>
    <mat-select formControlName="stateGroup" (selectionChange)="onSelectionChange($event)">
      <mat-option [value]="ownUser.userId">
        {{ ownUser.name }} ({{ ownUser.username }})
        <mat-icon><ng-container *ngIf="stateForm.get('stateGroup')?.value == ownUser.userId">check</ng-container></mat-icon>
      </mat-option>

      <ng-container *ngIf="groups.length == 0">
        <mat-divider></mat-divider>
        <mat-option disabled>
          <i>Keine Gruppenmitgliedschaften gefunden</i>
          <mat-icon>info</mat-icon>
        </mat-option>
      </ng-container>

      <ng-container *ngFor="let group of groups">
        <mat-divider></mat-divider>
        <mat-option class="group-name" disabled>{{ group.name }}</mat-option>

        <mat-option disabled *ngIf="group.members.length == 0">
          <i>Keine anderen Mitglieder</i>
          <mat-icon>info</mat-icon>
        </mat-option>

        <mat-option class="member-name" *ngFor="let member of group.members" [value]="member.userId">
          {{ member.name }} ({{ member.username }})
          <mat-icon>
            <ng-container *ngIf="stateForm.get('stateGroup')?.value == member.userId">check</ng-container>
          </mat-icon>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</form>
