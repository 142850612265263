import { createReducer, on } from '@ngrx/store';
import * as SupportModeActions from './supportmode.action';

export const initialState = false;

/**
 * Reducer for the State of the Support Mode.
 */
export const supportmodeReducer = createReducer(
  initialState,
  on(SupportModeActions.set, (_state, props) => props.enabled)
);
