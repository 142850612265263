import { createReducer, on } from '@ngrx/store';
import * as DevmodeActions from './devmode.action';

export const initialState = localStorage.getItem('devmode') === 'true';

/**
 * Reducer for the State of the Development Mode. Also persists the state into the localStorage.
 */
export const devmodeReducer = createReducer(
  initialState,
  on(DevmodeActions.set, (_state, props) => {
    localStorage.setItem('devmode', `${props.enabled}`);
    return props.enabled;
  })
);
