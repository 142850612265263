import { ConceptModel, ConceptNode } from '../../types/concept-node';

export class ConceptModelUtils {
  public static findChildren(
    context: ConceptNode,
    id: string,
    matches: ConceptNode[] = []
  ): ConceptNode[] {
    if (context.businessTerm === id) {
      matches.push(context);
    }

    context.children?.forEach(child => ConceptModelUtils.findChildren(child, id, matches));

    return matches;
  }

  public static findFirstChild(context: ConceptNode, id: string): ConceptNode | undefined {
    const matches = ConceptModelUtils.findChildren(context, id);
    if (matches.length > 0) {
      return matches[0];
    }
    return undefined;
  }

  public static findChildWithValue(
    context: ConceptNode,
    id: string,
    value: string
  ): ConceptNode | undefined {
    return ConceptModelUtils.findChildren(context, id).find(node => node.value === value);
  }

  public static hasLeaf(context: ConceptNode): boolean {
    if (context.value) {
      return true;
    }
    return context.children?.find(node => ConceptModelUtils.hasLeaf(node)) !== undefined;
  }

  public static getTimeZone(date: Date): string {
    // in case of DST switch
    date.setHours(10);
    const offset = date.getTimezoneOffset();
    const o = Math.abs(offset);
    const sign = offset < 0 ? '+' : '-';
    const hours = ConceptModelUtils.format(o / 60);
    const minutes = ConceptModelUtils.format(o % 60);
    return `${sign}${hours}:${minutes}`;
  }

  private static format(o: number): string {
    return Math.floor(o).toString().padStart(2, '0');
  }

  private static isTechnicalNode(conceptNode: ConceptNode) {
    if (conceptNode?.sourceModel?.isForbidden) {
      return true;
    }

    return (
      (conceptNode.value === null || conceptNode.value === undefined) &&
      (!conceptNode.children || conceptNode.children.length === 0)
    );
  }

  public static removeTechnicalNodes(conceptNode: ConceptNode) {
    if (!conceptNode || !conceptNode.children) {
      return;
    }
    conceptNode.children.forEach(child => ConceptModelUtils.removeTechnicalNodes(child));
    conceptNode.children = conceptNode.children.filter(
      child => !ConceptModelUtils.isTechnicalNode(child)
    );
  }

  public static serialize(conceptModel: ConceptModel): string {
    ConceptModelUtils.removeTechnicalNodes(conceptModel.root);
    const internalFields = [
      'rawValue',
      'metadata',
      'parent',
      'sourceModel',
      'nativeValue',
      'nodeIndex',
      'globalNodeIndex',
    ];
    return JSON.stringify(conceptModel, (key, value) => {
      if (value == null) {
        return undefined;
      }
      if (Array.isArray(value) && value.length === 0) {
        return undefined;
      }
      if (internalFields.find(it => it === key)) {
        return undefined;
      }
      return value;
    });
  }
}
