<div class="invitation-item">
  <div>
    <div>Gruppe: {{ einladung.groupName }}</div>
    <div class="invited-by">
      <div>{{ einladung.datetime | date:'dd.MM.yyyy HH:mm' }} Uhr von {{ einladung.invitedBy.name }}</div>
      <div>Organisation: {{ einladung.invitedBy.organization }}</div>
    </div>
    <div class="invitation-actions">
      <button mat-button class="eForms-button" (click)="onDeclineInvitation(einladung.groupId,einladung.id)">
        <mat-icon>close</mat-icon>
        ablehnen
      </button>
      <button mat-button class="eForms-button eForms-button--primary" (click)="onAcceptInvitation(einladung.groupId,einladung.id)">
        <mat-icon>check</mat-icon>
        annehmen
      </button>
    </div>
  </div>
</div>
