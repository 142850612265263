import { FieldModelType } from '../types/data-model';
import { BaseModel } from './base-model';
import { ModelListener } from '../services/model-listener/ModelListener';

export class FieldModel<T extends FieldModelType> extends BaseModel {
  private _value: T = null;
  private _initialValue: T = null;
  private _placeholder: string;
  private _showDescription = true;
  private _touched = false;
  private _modelChangeListener: ModelListener<any>[] = [];
  private _valueAfterModelInit: T;

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
  }

  get showDescription(): boolean {
    return this._showDescription;
  }

  set showDescription(value: boolean) {
    this._showDescription = value;
    this.updateView();
  }

  /**
   * True, when the user has interacted with this model at least one time.
   * This is to prevent the validation of the field, before the user has interacted with it.
   * This is only used for the reactive validation of single Form-Fields, not the full validation.
   */
  get touched(): boolean {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  get modelChangeListener(): ModelListener<any>[] {
    return this._modelChangeListener;
  }

  set modelChangeListener(value: ModelListener<any>[]) {
    this._modelChangeListener = value;
  }

  get initialValue(): T {
    return this._initialValue;
  }

  set initialValue(value: T) {
    this._initialValue = value;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  get valueAfterModelInit(): T {
    return this._valueAfterModelInit;
  }

  /**
   * Initial model value, right after the complete componentModel was created. This will differ from the initialValue, because some Fields might get preset or changed by ChangeListener.
   * This value helps to decide, if there are changes in the ComponentModel since initial construction.
   */
  set valueAfterModelInit(value: T) {
    this._valueAfterModelInit = value;
  }
}
