import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../../../services/communication.service';
import { Project } from '../../../types/project';
import { AbstractFormComponent } from '../abstract-form/abstract-form.component';
import { FieldModel } from '../../../view-model/field-model';
import { DialogComponent } from '../../layout/dialog/dialog.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectComponent extends AbstractFormComponent implements OnInit {
  @ViewChild('projectChooserDialog') projectChooserDialog: DialogComponent;

  model: FieldModel<Project>;
  displayProjectControl = new FormControl();
  projectSelectControl = new FormControl();
  projectNameControl = new FormControl();
  projectDescriptionControl = new FormControl();
  indicatorControl = new FormControl(ProjectIndicator.NONE);
  projectIndicator = ProjectIndicator;
  placeholder = 'Bitte wählen';
  projects: Project[];

  constructor(private communicationService: CommunicationService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.displayProjectControl.disable();
    this.projectSelectControl.disable();
    this.projectNameControl.disable();
    this.projectDescriptionControl.disable();
    this.projects = await this.communicationService.getProjects();
    this.setValue(this.model.value);

    this.handleIndicatorChanges();
    this.handleProjectSelectionChanges();
    this.handleProjectNameChanges();
    this.handleProjectDescriptionChanges();
  }

  openProjectDialog() {
    this.projectChooserDialog.openDialog();
    this.applyIndicatorState(this.indicatorControl.value);
    this.cdRef.markForCheck();
  }

  setProject() {
    let project: Project = null;

    if (this.indicatorControl.value === ProjectIndicator.EXISTING) {
      project = this.projects.find(p => +p.id === +this.projectSelectControl.value);
    }

    if (this.indicatorControl.value === ProjectIndicator.NEW) {
      project = new Project(
        null,
        this.projectNameControl.value,
        this.projectDescriptionControl.value
      );
    }

    this.setValue(project);
  }

  protected focus(): void {
    document.getElementById(this.htmlId).scrollIntoView({
      block: 'start',
      inline: 'nearest',
    });

    this.model.focus = false;
  }

  private setValue(project: Project) {
    const projectValue = project ? `${project.name} (${project.description})` : null;
    this.displayProjectControl.setValue(projectValue);
    this.model.value = project;
  }

  private handleIndicatorChanges() {
    this.subscription.add(
      this.indicatorControl.valueChanges.subscribe(value => {
        this.applyIndicatorState(value);
      })
    );
  }

  private applyIndicatorState(value: string): void {
    if (ProjectIndicator.NONE === value) {
      this.enableAcceptButton();
    }

    if (ProjectIndicator.EXISTING === value) {
      this.projectSelectControl.enable();
    } else {
      this.projectSelectControl.disable({ emitEvent: false });
    }

    if (ProjectIndicator.NEW === value) {
      this.projectNameControl.enable();
      this.projectDescriptionControl.enable();
    } else {
      this.projectNameControl.disable({ emitEvent: false });
      this.projectDescriptionControl.disable({ emitEvent: false });
    }
  }

  private handleProjectSelectionChanges(): void {
    this.subscription.add(
      this.projectSelectControl.valueChanges.subscribe(value => {
        if (ProjectIndicator.EXISTING !== this.indicatorControl.value) {
          return;
        }
        if (value && value !== this.placeholder) {
          this.enableAcceptButton();
        } else {
          this.disableAcceptButton();
        }
      })
    );
  }

  private handleProjectNameChanges(): void {
    this.subscription.add(
      this.projectNameControl.valueChanges.subscribe(value => {
        if (ProjectIndicator.NEW !== this.indicatorControl.value) {
          return;
        }
        if (value && this.projectDescriptionControl.value) {
          this.enableAcceptButton();
        } else {
          this.disableAcceptButton();
        }
      })
    );
  }

  private handleProjectDescriptionChanges(): void {
    this.subscription.add(
      this.projectDescriptionControl.valueChanges.subscribe(value => {
        if (ProjectIndicator.NEW !== this.indicatorControl.value) {
          return;
        }
        if (value && this.projectNameControl.value) {
          this.enableAcceptButton();
        } else {
          this.disableAcceptButton();
        }
      })
    );
  }

  private enableAcceptButton() {
    this.projectChooserDialog.enableAcceptButton();
  }

  private disableAcceptButton() {
    this.projectChooserDialog.disableAcceptButton();
  }
}

export enum ProjectIndicator {
  NONE = 'NONE',
  EXISTING = 'EXISTING',
  NEW = 'NEW',
}
