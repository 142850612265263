<div class="header">
  <ng-container
    *ngIf="{
      isLoggedIn: (loggedIn$ | async),
      username: (username$ | async)
    } as user">
    <div class="header-left">
      <a class="header-item" routerLink="/home" routerLinkActive="active">
        <img alt="Redaktionssystem-Logo" src="assets/img/logo-redaktionssystem.svg" />
      </a>
      <ng-container *ngIf="user.isLoggedIn">
        <a [matMenuTriggerFor]="menuBekanntmachungen" class="header-item" routerLinkActive="active">
          <div class="menu-trigger">
            Bekanntmachungen
            <mat-icon>expand_more</mat-icon>
          </div>
          <mat-menu #menuBekanntmachungen="matMenu" class="navigation-menu">
            <a class="header-item-menu" routerLink="/bekanntmachungen/verwalten">Verwalten</a>
            <mat-divider></mat-divider>
            <a class="header-item-menu" routerLink="/bekanntmachungen/anlegen">Anlegen</a>
          </mat-menu>
        </a>
        <a [matMenuTriggerFor]="menuVorlagen" class="header-item" routerLinkActive="active">
          <div class="menu-trigger">
            Vorlagen
            <mat-icon>expand_more</mat-icon>
          </div>
          <mat-menu #menuVorlagen="matMenu" class="navigation-menu">
            <a class="header-item-menu" routerLink="/vorlagen/verwalten">Verwalten</a>
            <mat-divider></mat-divider>
            <a class="header-item-menu" routerLink="/vorlagen/anlegen">Anlegen</a>
          </mat-menu>
        </a>
        <a class="header-item" routerLink="/gruppen" routerLinkActive="active"> Gruppen </a>
        <a
          *hasAnyRole="['reporter']"
          class="header-item"
          routerLink="/neuigkeiten"
          routerLinkActive="active"
          >Neuigkeiten</a
        >
      </ng-container>
    </div>
    <div class="header-right">
      <ng-container *ngIf="user.isLoggedIn">
        <a [matMenuTriggerFor]="menuUser" class="header-item" routerLinkActive="active">
          <div class="menu-trigger">
            <mat-icon>person</mat-icon>
            {{ user.username }}
            <mat-icon>expand_more</mat-icon>
          </div>
          <mat-menu #menuUser="matMenu" class="navigation-menu" xPosition="before">
            <a class="header-item-menu" routerLink="/nutzerkonto">Benutzerdaten verwalten</a>
            <mat-divider></mat-divider>
            <a class="header-item-menu" (click)="logout()" class="header-item-menu">Abmelden</a>
          </mat-menu>
        </a>
      </ng-container>
      <ng-container *ngIf="!user.isLoggedIn">
        <a (click)="login()" class="header-item">Anmelden</a>
      </ng-container>
    </div>
  </ng-container>
</div>
