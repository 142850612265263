import { ModelValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { AmountModel } from '../../../../view-model/type/amount-model';
import { MeasureModel } from '../../../../view-model/type/measure-model';

export class IncompleteAmountAndUnitInputValidator extends ModelValidator<FieldModel<any>> {
  static readonly INCOMPLETE_INPUT_ERROR_MSG =
    'Die Eingabe ist unvollständig. Bitte erfassen Sie eine dazugehörige Einheit.';

  constructor() {
    super();
  }

  validate(fieldModel: FieldModel<any>): Promise<ValidationNotification[]> {
    if (this.isInputIncomplete(fieldModel)) {
      return this.ofSingle(IncompleteAmountAndUnitInputValidator.INCOMPLETE_INPUT_ERROR_MSG);
    }
    return this.empty();
  }

  shouldAttach(fieldModel: FieldModel<any>): boolean {
    return (
      !fieldModel.isMandatory &&
      (fieldModel instanceof AmountModel || fieldModel instanceof MeasureModel)
    );
  }

  private isInputIncomplete(fieldModel: FieldModel<any>) {
    if (fieldModel instanceof AmountModel) {
      return fieldModel.value?.amount && !fieldModel.value?.currencyID;
    }
    if (fieldModel instanceof MeasureModel) {
      return fieldModel.value?.amount && !fieldModel.value?.unit;
    }
  }
}
