import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewsControllerService, NewsDto } from '../portal/api/notices';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private static MAX_LANDING_PAGE_NEWS_COUNT = 3;
  private static MAX_BANNER_COUNT = 2;

  private newsSubject: BehaviorSubject<NewsDto[]>;
  private readonly news$: Observable<NewsDto[]>;
  private lastUpdate: Date;

  constructor(private newsControllerService: NewsControllerService) {
    this.newsSubject = new BehaviorSubject<NewsDto[]>([]);
    this.news$ = this.newsSubject.asObservable();
    this.lastUpdate = new Date(0);
  }

  getNews(): Observable<NewsDto[]> {
    return this.news$;
  }

  getBannerNews(): Observable<NewsDto[]> {
    return this.getNews().pipe(
      map(news => news.filter(n => [NewsDto.TypeEnum.WarningBanner, NewsDto.TypeEnum.InfoBanner].includes(n.type)))
    );
  }

  getLatestLandingpageNewsList(): Observable<NewsDto[]> {
    return this.getNews().pipe(
      map(news => news.filter(n => n.type === NewsDto.TypeEnum.LandingPageNews)),
      map(news => news.slice(0, NewsService.MAX_LANDING_PAGE_NEWS_COUNT))
    );
  }

  /**
   * Gibt die neusten x Banner-Items aus der Liste der News zurueck.
   * Warnungen werden bevorzugt behandelt, d.h. es wird erst nach Typ sortiert (Warnung > Info),
   * anschliessend nach Datum (Liste kommt bereits nach Datum sortiert vom BE).
   */
  getLatestBannerList(): Observable<NewsDto[]> {
    return this.getBannerNews().pipe(
      map(news =>
        news.sort((a, b) => {
          if (a.type === NewsDto.TypeEnum.WarningBanner && b.type === NewsDto.TypeEnum.InfoBanner) {
            return -1;
          } else if (
            a.type === NewsDto.TypeEnum.InfoBanner &&
            b.type === NewsDto.TypeEnum.WarningBanner
          ) {
            return 1;
          } else {
            return 0;
          }
        })
      ),
      map(news => news.slice(0, NewsService.MAX_BANNER_COUNT))
    );
  }

  updateNews(): void {
    const now = new Date();
    if (now.getTime() - this.lastUpdate.getTime() < 15 * 60 * 1000) {
      return;
    }
    this.lastUpdate = now;
    this.newsControllerService.getNews().subscribe(data => {
      this.newsSubject.next(data);
    });
  }

  addNews(newsItem: NewsDto): Observable<NewsDto> {
    return this.newsControllerService.addNews(newsItem).pipe(
      map(newsDto => {

        const newsList = this.newsSubject.value;
        newsList.unshift(newsDto);
        this.newsSubject.next(newsList);

        return newsDto;
      })
    );
  }

  deleteNews(id: number): Observable<void> {
    return this.newsControllerService.deleteNews(id).pipe(
      map(data => {
        const newsList = this.newsSubject.value;
        const index = newsList.findIndex(news => news.id === id);
        if (index > -1) {
          newsList.splice(index, 1);
        }
        this.newsSubject.next(newsList);

        return data;
      })
    );
  }

  deleteAllNews(): Observable<number | void> {
    return this.newsControllerService.deleteAllNews().pipe(
      tap(() => {
        this.newsSubject.next([]);
      })
    );
  }
}
