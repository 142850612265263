import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Severity } from '../../../../types/app-types';
import { MultilingualModel } from '../../../../view-model/type/multilingual-model';
import { Subscription } from 'rxjs';
import { Language } from '../../../../types/data-model';

@Component({
  selector: 'app-language-text-field',
  templateUrl: './language-text-field.component.html',
  styleUrls: ['./language-text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageTextFieldComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() language: Language;
  @Input() htmlId: string;
  @Input() model: MultilingualModel;
  @Input() focus: EventEmitter<(el: HTMLElement) => void>;
  @Output() validateField = new EventEmitter();

  label: string;
  maxLength: number;
  private subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.label = this.language === Language.Deu ? 'DE' : 'EN';
    this.maxLength = +this.model.fieldInfo.maxLength;
    this.subscription.add(this.focus.subscribe(() => fn => this.focusInput(fn)));
    this.subscription.add(
      this.model.modelChangedEmitter.subscribe(() => this.cdRef.markForCheck())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validate(): void {
    this.validateField.emit();
  }

  hasInputError(): boolean {
    return this.model.validationNotifications.some(
      notification =>
        notification.targetLanguage === this.language && notification.severity === Severity.ERROR
    );
  }

  private focusInput(fn: (el: HTMLElement) => void) {
    const input = document.getElementById(this.htmlId);
    fn(input);
  }
}
