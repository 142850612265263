<app-template [hasContentFrame]="true" [isContentColorWhite]="false">
  <div class="redaktionssystem-wrapper">
    <div class="redaktionssystem-row">
      <div class="redaktionssystem-column">
        <h1>eForms-DE online erfassen!</h1>
        <p>
          Willkommen beim Redaktionssystem des Datenservice Öffentlicher Einkauf. Das
          Redaktionssystem bietet die Möglichkeit, Bekanntmachungen nach dem eForms-DE-Standard zu
          erstellen und zur Veröffentlichung an den Vermittlungsdienst des Datenservice öffentlicher
          Einkauf (DÖE) zu senden. Über den DÖE werden die Bekanntmachungen an das Amt für
          Veröffentlichugen der EU (TED) und an den Bekanntmachungsservice (<a
            href="https://oeffentlichevergabe.de/ui/de/"
            target="_blank"
            >www.oeffentlichevergabe.de/ui/de/</a
          >) weitergeleitet. Das Redaktionssystem richtet sich an Personen und Organisationen, die
          Bekanntmachungen im Oberschwellenbereich veröffentlichen. Dazu zählen:
        </p>
        <ul>
          <li>
            Vergabestellen der öffentlichen Verwaltung,
            <b
              >ohne einen Zugang zu einem an den Datenservice öffentlicher Einkauf angebundenen
              Vergabesystem</b
            >.
          </li>
          <li>
            Auftraggeber, die gemäß § 98 GWB einer öffentlich-rechtlichen Einrichtung gleichgestellt
            sind.
          </li>
          <li>
            Zuwendungsempfänger
            <ul>
              <li>durch Zuwendungen zur Projektförderung.</li>
              <li>durch Zuwendungen zur institutionellen Förderung.</li>
            </ul>
          </li>
          <li>
            Dienstleister, die für Auftraggeber der öffentlichen Verwaltung oder Zuwendungsempfänger
            Bekanntmachungen über das Redaktionssystem einstellen möchten.
          </li>
        </ul>
        <p>
          Das Redaktionssystem ist <b>keine Vergabeplattform</b> und
          <b>kein Vergabemanagementsystem</b>. Es können <b>keine</b> Vergabeverfahren vollständig
          elektronisch abgewickelt werden. Es ist <b>nicht</b> möglich, Auftragsunterlagen
          hochzuladen.
        </p>
      </div>
    </div>

    <ng-container *ngIf="news$ | async as news">
      <ng-container *ngIf="!!news && news.length">
        <h2 class="news">Neuigkeiten</h2>
        <div class="redaktionssystem-row">
          <div *ngFor="let newsItem of news" class="redaktionssystem-column">
            <app-neueigkeit
              [contentHtmlAsString]="newsItem.content"
              [datum]="newsItem.newsDate"
              [titel]="newsItem.title"></app-neueigkeit>
          </div>
        </div>
        <h2 class="news-divider"></h2>
      </ng-container>
    </ng-container>

    <div class="redaktionssystem-row">
      <div class="redaktionssystem-column">
        <h1>Registrierung / Zugangsdaten</h1>
        <p>
          Um sich als Redakteurin oder Redakteur beim Redaktionssystem des Datenservice Öffentlicher
          Einkauf zu registrieren, senden Sie uns bitte eine E-Mail an folgende E-Mail-Adresse:
          <a href="mailto:resy-doe@bescha.bund.de">resy-doe@bescha.bund.de</a>
        </p>
        <p>Die E-Mail muss folgende Informationen enthalten:</p>
        <ul>
          <li>
            <b>Vor- und Zuname</b> sowie die <b>dienstliche E-Mail-Adresse</b> und
            <b>Telefonnummer</b>,
          </li>
          <li>genaue Bezeichnung Ihrer Behörde / Organisation,</li>
          <li>Angaben darüber, welche Bekanntmachungen Sie einstellen möchten,</li>
          <li>
            ggf. Nachweis einer Beauftragung durch eine Behörde (Kopie als *.PDF) oder dass Ihre
            Einrichtung gemäß § 98 GWB einer öffentlich-rechtlichen Einrichtung gleichgestellt ist.
            Bei Zuwendungsempfängern benötigen wir zusätzlich das Deckblatt des Zuwendungsbescheids
            mit Aktenzeichen und Zuwendungsgeber in Kopie als *.PDF.
          </li>
          <li>
            Wenn Sie als Dienstleister für Auftraggeber der öffentlichen Verwaltung Bekanntmachungen
            über das Redaktionssystem einstellen möchten, benötigen wir von Ihnen eine entsprechende
            Bescheinigung/Beauftragung dieses Auftraggebers (z.B. Deckblatt als Kopie im PDF-Format)
            per E-Mail. Wenn es sich bei diesem Auftraggeber um einen Zuwendungsempfänger handelt
            oder er gemäß § 98 GWB einer öffentlich-rechtlichen Einrichtung gleichgestellt ist,
            benötigen wir außerdem den entsprechenden Nachweis des Auftraggebers. Hier ist bspw. das
            Deckblatt des Zuwendungsbescheides mit Aktenzeichen etc. in Kopie als *.pdf als Nachweis
            absolut ausreichend.
          </li>
          <li>
            Nach positiver Prüfung erhalten Sie einen Nutzernamen sowie einen Link zur
            Registrierung. Die Einstellung und Veröffentlichung von Bekanntmachungen erfolgen
            <b>kostenfrei</b>.
          </li>
        </ul>
      </div>
      <div class="redaktionssystem-column">
        <h1>Support</h1>
        <p>
          Bei technischen Fragen zum Redaktionssystem nutzen Sie bitte das <a routerLink="/kontakt">Kontaktformular</a>.
        </p>
        <p>
          Viele Fragen sind bereits im <a routerLink="/faq">FAQ</a> beantwortet. Schauen Sie dort
          nach, ob es auch auf Ihre Frage bereits eine Antwort gibt.
        </p>
        <p>
          <b
            >Bei Fehlermeldungen helfen folgenden Informationen dem Support-Team, Ihre Anfrage
            schnellstmöglich zu beantworten:</b
          >
        </p>
        <ul>
          <li>Benutzername im Redaktionssystem</li>
          <li>Möglichst genaue Beschreibung wie der Fehler aufgetreten ist</li>
          <li>Genaue Fehlermeldung (z.B. als Screenshot)</li>
          <li>Wann ist der Fehler aufgetreten?</li>
          <li>Lässt sich der Fehler wiederholen?</li>
          <li>Welcher Browser wurde benutzt?</li>
          <li>Welcher Formulartyp ist betroffen?</li>
          <li>
            XML-Export des betroffenen Formulars. Sie können das XML über die Aktion "Bekanntmachung
            exportieren" herunterladen und dann an Ihre Supportanfrage anfügen.
          </li>
        </ul>
        <p style="font-style: italic">
          <b>Bei folgenden Fragestellungen können wir NICHT weiterhelfen:</b>
        </p>
        <ul>
          <li>Inhaltliche Fragen zu Ausschreibungsunterlagen oder Hilfestellung zum Ausfüllen</li>
          <li>Inhaltliche Fragen zu Vergabeunterlagen</li>
          <li>Fragen zum Verfahrensverlauf</li>
          <li>Rechtliche Rahmenbedingungen zu Ausschreibungen</li>
          <li>Rechtliche Fragestellungen im Allgemeinen</li>
        </ul>
      </div>
    </div>
  </div>
</app-template>
