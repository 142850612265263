<div class="selection-criteria-book-btn-container">
  <button
    mat-mini-fab
    [disabled]="!isSaveAllowed()"
    (click)="$event.stopPropagation(); saveSelectionCriterion()"
    class="repeatable-control eForm-icon-button normal"
    matTooltip="Eignungskriterium speichern"
    aria-label="Eignungskriterium speichern">
    <mat-icon>save</mat-icon>
  </button>
  <button
    mat-mini-fab
    (click)="$event.stopPropagation(); openSelectionCriteriaBookDialog()"
    [disabled]="!hasSelectionCriteria()"
    class="repeatable-control eForm-icon-button normal"
    matTooltip="Eignungskriterien Katalog öffnen"
    aria-label="Eignungskriterien Katalog öffnen">
    <mat-icon>book</mat-icon>
  </button>
</div>

<app-dialog #selectionCriteriaBookDialog title="Eignungskriterien" dismissLabel="Schließen">
  <div *ngIf="criteria">
    <div class="search-function">
      <div class="search-icon-input">
        <mat-icon class="search-icon">search</mat-icon>
        <input
          type="text"
          [(ngModel)]="searchQuery"
          placeholder="Volltextsuche..."
          (input)="searchCriterion(searchQuery)" />
      </div>

      <button
        class="clear-button"
        mat-icon-button
        *ngIf="!model.isReadonly"
        (click)="clearSearch()"
        aria-label="Sucheingabe löschen">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div *ngFor="let criterion of filteredCriteria" class="criteria-card">
      <div class="content">
        <div class="criteria">
          <p *ngIf="criterion.criterionType">
            <span><strong>Art:</strong></span>
            {{ findCodeListItemLabel(criterion.criterionType, codeListType) }}
          </p>
          <p *ngIf="criterion.description" class="description-wrapper">
            <span><strong>Beschreibung:</strong></span>
          {{ criterion.description }}
          </p>
          <p *ngIf="criterion.secondPhase">
            <span><strong>Zweite Phase:</strong></span
            ><span> Ja </span>
          </p>
          <p *ngIf="criterion.secondPhase === false">
            <span><strong>Zweite Phase:</strong></span
            ><span> Nein </span>
          </p>
        </div>
        <div class="buttons-container">
          <div class="action-icons">
            <app-change-selection-criterion
              [codeListType]="codeListType"
              (criteriaEmitter)="saveCriteriaChanges($event)"
              [importedCriterion]="criterion">
            </app-change-selection-criterion>
            <button
              mat-mini-fab
              (click)="deleteCriterion(criterion)"
              class="repeatable-control eForm-icon-button danger"
              matTooltip="Eignungskriterium löschen"
              matTooltipPosition="above"
              aria-label="Eignungskriterium löschen">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <button (click)="setCriterion(criterion)" class="eForms-button eForms-button--primary">
            Einfügen
          </button>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
