import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
import { EditorConfigurationProvider } from '../../../services/editor-configuration.provider';
import { ModelStateService } from '../../../services/view-model/model-state.service';
import { SectionModel } from '../../../view-model/section-model';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-dynamic-form-container',
  templateUrl: './dynamic-form-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormContainerComponent implements OnInit, OnDestroy {
  @Input() noticeId: string;

  activeSectionModel: SectionModel;
  private subscription = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private modelStateService: ModelStateService,
    private stateService: StateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.changeSection(paramMap.get('sectionId'));
      this.cdRef.markForCheck();
    });

    this.stopLoadingAnimation();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private stopLoadingAnimation() {
    setTimeout(() => {
      this.loadingService.loadingEventEmitter().next(false);
      this.cdRef.markForCheck();
    }, 1000);
  }

  private changeSection(sectionId: string) {
    this.activeSectionModel = this.modelStateService.getSectionModel(sectionId);
    this.stateService.changeSectionId(this.activeSectionModel?.sectionId);

    //reset scroll position
    document.getElementById('as-split-form').scrollTop = 0;
  }
}
