import { ModelValidator } from '../ModelValidator';
import { DateModel } from '../../../../view-model/type/date-model';
import { Severity, ValidationNotification } from '../../../../types/app-types';
import { MaintenanceWindowService } from '../../../maintenance-window.service';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';
import { DateUtilsService } from '../../../../utils/date-utils-service';

export class DeadlineOnMaintenanceWindowCollisionValidator extends ModelValidator<DateModel> {
  public static readonly BTS_FOR_DEADLINE_IN_MW_VALIDATION: string[] = [
    'BT-1311(d)-Lot', // participation deadline
    'BT-131(d)-Lot', // offer deadline
    OFFER_DEADLINE_DATE_ID, // provisional offer deadline
    'BT-132(d)-Lot', // offer opening deadline
    'BT-738-notice', // preferred publication date
  ];

  constructor(
    private maintenanceWindowService: MaintenanceWindowService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private dateUtilsService: DateUtilsService
  ) {
    super();
  }

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    if (dateModel.isReadonly) {
      return this.empty();
    }

    let localDateTime = dateModel.withRelatedTime();

    if (
      dateModel.noticeNode.id === 'BT-738-notice' &&
      this.dateUtilsService.getToday().isEqual(dateModel.asLocalDate())
    ) {
      // preferred publication date gets the actual timestamp for the collision check on today
      // so that it is not validated against a possible midnight maintenance window.
      localDateTime = dateModel.asLocalDate().atTime(this.dateUtilsService.getLocalTime());
    }

    const maintenanceWindowCollision =
      this.maintenanceWindowService.findSingleMaintenanceWindowCollision(localDateTime);

    if (dateModel.value && maintenanceWindowCollision) {
      const collisionMessage =
        this.maintenanceWindowService.createMaintenanceWindowCollisionMessage(
          maintenanceWindowCollision
        );

      if (this.editorConfigurationProvider.isTrainingMode()) {
        return this.ofSingle(collisionMessage, Severity.WARNING);
      } else {
        return this.ofSingle(collisionMessage);
      }
    }
    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return DeadlineOnMaintenanceWindowCollisionValidator.BTS_FOR_DEADLINE_IN_MW_VALIDATION.includes(
      model.noticeNode.id
    );
  }
}
