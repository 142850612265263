import { KeycloakProfile } from 'keycloak-js';
import { ProcedureOverviewDto } from '../../api/notices';

export interface BekanntmachungenVerwaltungState {
  loading: boolean;
  loggedInUser: KeycloakProfile;
  procedures: ProcedureOverviewDto[];

  // filter
  selectedUserId: string;
  showFavorites: boolean;
  showHidden: boolean;

  // user settings
  maxProceduresPerPage: number;
  favoriteProcedures: number[];
  hiddenProcedures: number[];
}

export const initialState: BekanntmachungenVerwaltungState = {
  loading: false,
  loggedInUser: null,
  procedures: [],

  // filter
  selectedUserId: null,
  showFavorites: false,
  showHidden: false,

  // user settings
  maxProceduresPerPage: 25,
  favoriteProcedures: [],
  hiddenProcedures: [],
};
