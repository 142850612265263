<div id="collapse-expand-wrapper">
  <div id="collapse-expand-box">
    <button
      mat-mini-fab
      id="button-expand-all"
      (click)="expandAllGroups()"
      class="eForm-icon-button normal"
      matTooltip="Gruppen aufklappen"
      aria-label="Gruppen aufklappen">
      <mat-icon>unfold_more</mat-icon>
    </button>
    <button
      mat-mini-fab
      (click)="collapseAllGroups()"
      class="eForm-icon-button normal"
      matTooltip="Gruppen einklappen"
      aria-label="Gruppen einklappen">
      <mat-icon>unfold_less</mat-icon>
    </button>
  </div>
  <div id="tooltip-button-box">
    <button
      mat-mini-fab
      id="tooltip-button"
      (click)="toggleDescriptions()"
      class="eForm-icon-button normal"
      matTooltip="Beschreibungen anzeigen/ausblenden"
      aria-label="Beschreibungen anzeigen/ausblenden">
      <mat-icon>?</mat-icon>
    </button>
  </div>
</div>
