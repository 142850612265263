import { Injectable } from '@angular/core';
import { FieldModel } from '../../view-model/field-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { ModelValidationService } from './model-validation.service';
import { SyncedFieldService } from '../synced-field.service';
import { ModelListener } from '../model-listener/ModelListener';
import { CaptionValueChangedListener } from '../model-listener/CaptionValueChangedListener';
import { DynamicModelPropertyService } from './dynamic-model-property.service';
import { ConceptModelService } from '../concept-model.service';
import { ProcedureTypeChangedListener } from '../model-listener/ProcedureTypeChangedListener';
import { ProcedureTypeService } from '../procedure-type.service';
import { ProcedureTypeListener } from '../model-listener/ProcedureTypeListener';
import { StateService } from '../state.service';
import { SchemeCaptionUpdateListener } from '../model-listener/SchemeCaptionUpdateListener';
import { SchemeIdService } from './scheme-id.service';
import { NumberOfLotsListener } from '../model-listener/NumberOfLotsListener';
import { DateModel } from '../../view-model/type/date-model';
import { ConceptModel } from '../../types/concept-node';
import { ProcurementTypeListener } from '../model-listener/ProcurementTypeListener';
import { BT300BrDe26KmuChangedListener } from '../model-listener/BT300BrDe26KmuChangedListener';
import { MultilingualValueChangedListener } from '../model-listener/MultilingualValueChangedListener';
import { CaptionService } from './caption.service';

@Injectable()
export class ModelListenerService {
  /**
   * Service for listening on changes in models
   */
  private changeListener: ModelListener<any>[] = [];

  constructor(
    private modelValidationService: ModelValidationService,
    private syncedFieldService: SyncedFieldService,
    private dynamicModelService: DynamicModelPropertyService,
    private conceptModelService: ConceptModelService,
    private procedureTypeService: ProcedureTypeService,
    private stateService: StateService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private schemeIdService: SchemeIdService,
    private captionService: CaptionService
  ) {
    this.initListeners();
  }

  /**
   * Get called after the Model-Generation process is done and the model has been assigned an imported value.
   */
  public onModelValueLoaded<T extends FieldModel<any>>(model: T) {
    model.modelChangeListener.forEach(listener => listener.onChange(model));
  }

  /**
   * Gets called by the FormComponents, when a Value has changed.
   *
   * @param model which has been changed
   */
  public async onModelValueChange<T extends FieldModel<any>>(model: T) {
    // ReSy: Wenn sich der Wert eines Feldes aendert, markiere das gesamte Formular als invalid.
    this.stateService.changeValidity(false);
    this.syncedFieldService.updateSyncedFields(model);
    model.modelChangeListener.forEach(listener => listener.onChange(model));
    model.emitChange();

    // Calculate dynamic properties
    let conceptModel = await this.conceptModelService.generateConceptModel(model.root);
    const changedDependencies = await this.dynamicModelService.updatePropsOfDependencies(
      model,
      conceptModel
    );

    // Calculate again if a forbidden value has flipped!
    // Fields can have dependencies on fieldValues of Fields, which just became forbidden and thus not set.
    // The Validation-Status of dependencies can also change, because for example they just became mandatory.
    if (changedDependencies.length > 0) {
      conceptModel = await this.conceptModelService.generateConceptModel(model.root);
      await Promise.all(
        changedDependencies.map(async dependency => {
          await this.dynamicModelService.updatePropsOfDependencies(dependency, conceptModel);
          return this.triggerValidationForDependency(dependency, model, conceptModel);
        })
      );
    }

    await this.modelValidationService.validateModel(model, true, conceptModel);
  }

  public getChangeListenerForModel(model: FieldModel<any>): ModelListener<any>[] {
    return this.changeListener.filter(listener => listener.shouldAttach(model));
  }

  /**
   * Adds ChangeListener for specific fields.
   *
   * Please note: Listeners that depend on MutlilingualModels need to be added AFTER MultilingualValueChangedListener, these are:
   * - CaptionValueChangedListener
   * - SchemeCaptionUpdateListener
   * Otherwise updating values/captions based on MutlilingualModels will not work properly.
   */
  private initListeners() {
    this.add(
      new ProcedureTypeChangedListener(this.procedureTypeService, this.modelValidationService)
    );
    this.add(new ProcedureTypeListener(this.stateService));
    this.add(new NumberOfLotsListener(this.editorConfigurationProvider));
    this.add(new ProcurementTypeListener(this.editorConfigurationProvider));
    this.add(new BT300BrDe26KmuChangedListener('Part'));
    this.add(new BT300BrDe26KmuChangedListener('Lot'));
    this.add(new MultilingualValueChangedListener());
    this.add(new CaptionValueChangedListener(this.captionService)); // add AFTER MultilingualValueChangedListener!
    this.add(new SchemeCaptionUpdateListener(this.schemeIdService)); // add AFTER MultilingualValueChangedListener!
  }

  /**
   * Triggers the Validation of the updated Dependency.
   * Will not trigger the Validation of a TimeModel, which contains the TimePart of the given source FieldModel. This prevents a mandatory error from showing up, as so as a Date has been entered.
   *
   * @param dependency model which should be validated
   * @param sourceModel model which triggered the update of the dependency
   * @param conceptModel current conceptModel of the ComponentModel
   */
  private async triggerValidationForDependency(
    dependency: FieldModel<any>,
    sourceModel: FieldModel<any>,
    conceptModel: ConceptModel
  ) {
    if (
      !(
        sourceModel instanceof DateModel &&
        sourceModel.relatedTimeModel === dependency &&
        !sourceModel.relatedTimeModel?.touched
      )
    ) {
      await this.modelValidationService.validateModel(dependency, true, conceptModel);
    }
  }

  private add(listenerToAdd: ModelListener<any>) {
    this.changeListener.push(listenerToAdd);
  }
}
