import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GenericDialogService } from '../../../services/ui/generic-dialog.service';
import { GroupMemberModel, GroupMemberRole } from "../../api/user-service";

@Component({
  selector: 'app-gruppen-mitglieder',
  templateUrl: './gruppen-mitglieder.component.html',
  styleUrls: ['./gruppen-mitglieder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GruppenMitgliederComponent implements OnChanges, AfterViewInit {
  @Input() mitglieder: GroupMemberModel[];
  @Input() iAmOwner: boolean;
  @Input() hasSupportRole: boolean;

  @Output() removeMember = new EventEmitter<number>();
  @Output() transferOwnership = new EventEmitter<number>();
  @Output() withdrawInvitation = new EventEmitter<number>();

  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  displayedColumns: string[] = ['username', 'name', 'role', 'actions'];

  private genericDialogService = inject(GenericDialogService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mitglieder && changes.mitglieder.currentValue) {
      this.dataSource.data = changes.mitglieder.currentValue;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  confirmRemoveMember(mitglied: GroupMemberModel): void {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Mitglied entfernen',
      'Mitglied entfernen',
      'Abbrechen',
      `Sind Sie sicher, dass Sie ${mitglied.name} (${mitglied.username}) aus der Gruppe entfernen möchten?`
    );
    from(confirmationResult)
      .pipe(filter(confirmed => confirmed))
      .subscribe(() => this.removeMember.emit(mitglied.id));
  }

  confirmTransferOwnership(mitglied: GroupMemberModel): void {
    const confirmationResult = this.genericDialogService.confirmWithDialog(
      'Gruppenbesitzer ändern',
      'Gruppenbesitzer ändern',
      'Abbrechen',
      `Sind Sie sicher, dass Sie ${mitglied.name} (${mitglied.username}) zum Gruppenbesitzer ernennen wollen?`
    );
    from(confirmationResult)
      .pipe(filter(confirmed => confirmed))
      .subscribe(() => this.transferOwnership.emit(mitglied.id));
  }

  handleWithdrawInvitation(mitglied: GroupMemberModel): void {
    this.withdrawInvitation.emit(mitglied.id);
  }

  // TODO: in pipe auslagern
  roleToLabel(role: GroupMemberRole): string {
    switch (role) {
      case GroupMemberRole.Owner:
        return 'Besitzer';
      case GroupMemberRole.Member:
        return 'Mitglied';
      case GroupMemberRole.Invited:
        return 'eingeladen';
    }
  }

  isOwner(mitglied: GroupMemberModel): boolean {
    return mitglied.role === GroupMemberRole.Owner;
  }

  isMember(mitglied: GroupMemberModel): boolean {
    return mitglied.role === GroupMemberRole.Member;
  }

  isInvited(mitglied: GroupMemberModel): boolean {
    return mitglied.role === GroupMemberRole.Invited;
  }
}
