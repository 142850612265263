import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { AuthErrorDialogData, AuthErrorDialogType } from './auth-error-dialog.model';

@Component({
  selector: 'app-auth-error-dialog',
  templateUrl: './auth-error-dialog.component.html',
  styleUrls: ['./auth-error-dialog.component.scss'],
})
export class AuthErrorDialogComponent implements OnInit {
  protected readonly AuthErrorDialogType = AuthErrorDialogType;
  now = new Date();

  currentUsername = this.keycloak.getUsername();

  constructor(
    private keycloak: KeycloakService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: AuthErrorDialogData
  ) {}

  ngOnInit(): void {
  }

  loginPage() {
    if (this.data?.errorType == AuthErrorDialogType.USER_CHANGED) {
      window.open(
        this.keycloak.getKeycloakInstance().createLogoutUrl({
          redirectUri: this.generateLoginUriClosePage(this.currentUsername),
        }),
        '_blank'
      );
    } else {
      window.open(
        this.generateLoginUriClosePage(this.currentUsername),
        '_blank'
      );
    }
  }

  generateLoginUriClosePage(loginHint?: string){
    return this.keycloak.getKeycloakInstance().createLoginUrl({
      redirectUri: `${location.origin}/_special/AuthErrorDialogRedirectTarget`,
      loginHint: loginHint,
    })
  }
}
