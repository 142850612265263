<section id="notification-box">
  <div id="notification-box-header">
    <button
      mat-button
      (click)="buttonClicked($event, Severity.ERROR)"
      [class.selectedTab]="selectedSeverity === Severity.ERROR"
      [disabled]="errors.length === 0">
      <span class="eForm-badge danger" [attr.aria-label]="errors.length">{{ errors.length }}</span>
      <span>Fehler</span>
    </button>
    <button
      mat-button
      (click)="buttonClicked($event, Severity.WARNING)"
      [class.selectedTab]="selectedSeverity === Severity.WARNING"
      [disabled]="warnings.length === 0">
      <span class="eForm-badge warning" [attr.aria-label]="warnings.length">{{
        warnings.length
      }}</span>
      <span>Warnungen</span>
    </button>
    <div
      class="clickable"
      tabindex="0"
      (click)="headerClicked()"
      (keydown.space)="headerClicked()"
      (keydown.enter)="headerClicked()"
      [class.disabled]="selectedSeverity === null">
      <span
        class="mat-expansion-indicator"
        [hidden]="selectedSeverity === null"
        [class.rotated]="!isExpanded"></span>
    </div>
  </div>
  <div id="notification-box-body" class="list-container" [hidden]="!isExpanded">
    <mat-list *ngIf="selectedSeverity === Severity.ERROR">
      <mat-list-item
        *ngFor="let error of errors; let index = index"
        class="notification-box-list-item cursor-pointer">
        <app-notification [notification]="error" [index]="index"></app-notification>
      </mat-list-item>
    </mat-list>
    <mat-list *ngIf="selectedSeverity === Severity.WARNING">
      <mat-list-item
        *ngFor="let warning of warnings; let index = index"
        class="notification-box-list-item cursor-pointer">
        <app-notification [notification]="warning" [index]="index"></app-notification>
      </mat-list-item>
    </mat-list>
  </div>
</section>
