import { DateUtilsService } from '../../../../utils/date-utils-service';
import { DateModel } from '../../../../view-model/type/date-model';
import { ValidationNotification } from '../../../../types/app-types';
import { EVergabeSpecificValidator } from '../ModelValidator';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';

export class DeadlineInPastValidator extends EVergabeSpecificValidator<DateModel> {
  private static readonly BTS_FOR_DEADLINE_IN_PAST_VALIDATION: string[] = [
    'BT-1311(d)-Lot', // participation deadline
    'BT-131(d)-Lot', // offer deadline
    OFFER_DEADLINE_DATE_ID, // provisional offer deadline
    'BT-132(d)-Lot', // offer opening deadline
  ];
  private static readonly DEADLINE_IN_PAST_ERROR_MSG =
    'Der angegebene Zeitpunkt darf nicht in der Vergangenheit liegen.';

  constructor(private dateUtils: DateUtilsService) {
    super();
  }

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    if (
      !dateModel.isReadonly &&
      dateModel.value &&
      this.dateUtils.getLocalDateTime().isAfter(dateModel.withRelatedTime()) &&
      (!dateModel.relatedTimeModel?.isMandatory || dateModel.relatedTimeModel?.value !== null)
    ) {
      return this.ofSingle(DeadlineInPastValidator.DEADLINE_IN_PAST_ERROR_MSG);
    }
    return this.empty();
  }

  shouldAttach(model: DateModel): boolean {
    return (
      model instanceof DateModel &&
      DeadlineInPastValidator.BTS_FOR_DEADLINE_IN_PAST_VALIDATION.includes(model.noticeNode.id)
    );
  }
}
