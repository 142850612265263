<div [hidden]="model.isHidden" class="form-component-input-group">
  <app-field-header *ngIf="!model.isInsideRepeatable" [model]="model"></app-field-header>

  <ng-container [ngSwitch]="inputType">
    <app-select
      *ngSwitchCase="CodelistComponentType.Select"
      [control]="control"
      [options]="codeListItems"
      [ariaLabel]=""
      [isMandatory]="model.isMandatory"
      [isReadonly]="model.isReadonly"
      [hasError]="hasInputError()"
      [alwaysShowId]="supportMode"
      [htmlId]="htmlId">
    </app-select>

    <app-autocomplete
      *ngSwitchCase="CodelistComponentType.Autocomplete"
      [control]="control"
      [codeListItems]="codeListItems"
      [model]="model"
      [hasError]="hasInputError()"
      [htmlId]="htmlId">
    </app-autocomplete>

    <app-radio-button
      *ngSwitchCase="CodelistComponentType.RadioButton"
      [control]="control"
      [options]="codeListItems"
      [isMandatory]="model.isMandatory"
      [isReadonly]="model.isReadonly"
      [hasError]="hasInputError()"
      [htmlId]="htmlId">
    </app-radio-button>
  </ng-container>

  <ng-container *ngIf="!model.isHidden">
    <app-validation-message [model]="model"></app-validation-message>
  </ng-container>
</div>
