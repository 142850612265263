<div class="section-wrapper">
  <div #container>
    <mat-expansion-panel
      *ngFor="let repeatable of this.model.children; index as index"
      class="mat-elevation-z0"
      #sectionPanels>
      <mat-expansion-panel-header matTooltipClass="mat-tooltip-long">
        <mat-panel-title [matTooltip]="repeatable.customTitle ?? ''">
          <div class="custom-content-wrapper">
            <span class="custom-content-wrapper-lot-id">
              {{ getSchemeIdentifier(repeatable) }}
            </span>
            <span class="custom-content-wrapper-description">
              {{ repeatable.customTitle ?? '-' }}
            </span>
          </div>
          <mat-icon
            *ngIf="index === 0 && this.model.children.length === 1"
            class="info-icon"
            matTooltip="{{ getTooltip() }}"
          >info
          </mat-icon>
        </mat-panel-title>
        <div class="button-bar">
          <app-prefill-fields
            *ngIf="isTechnical && structuralChangeAllowed"
            [procedureSection]="procedureSection"
            [model]="this.model"
          ></app-prefill-fields>
          <button
            mat-mini-fab
            (click)="copy(repeatable); stopDefaultBehavior($event)"
            (keydown.enter)="copy(repeatable); stopDefaultBehavior($event)"
            (keydown.space)="copy(repeatable); stopDefaultBehavior($event)"
            *ngIf="structuralChangeAllowed"
            class="eForm-icon-button normal"
            matTooltip="{{ typeName }} kopieren"
            matTooltipPosition="right"
            attr.aria-label="{{ typeName }} kopieren">
            <mat-icon>file_copy</mat-icon>
          </button>
          <button
            mat-mini-fab
            (click)="remove(repeatable); stopDefaultBehavior($event)"
            (keydown.enter)="remove(repeatable); stopDefaultBehavior($event)"
            (keydown.space)="remove(repeatable); stopDefaultBehavior($event)        "
            *ngIf="this.model.children.length > 1 && structuralChangeAllowed"
            class="eForm-icon-button danger"
            matTooltip="{{ typeName }} aus der Liste entfernen"
            matTooltipPosition="right"
            attr.aria-label="{{ typeName }} aus der Liste entfernen">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <mat-list role="listbox">
        <mat-list-item
          (click)="changeSection(subNote)"
          (keydown.enter)="changeSection(subNote)"
          (keydown.space)="changeSection(subNote)"
          *ngFor="let subNote of repeatable.children"
          [attr.aria-current]="selectedSection === subNote"
          [class.selectedMenuItem]="selectedSection === subNote"
          role="option"
          tabindex="0">
          {{ subNote.translatedLabel }}
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </div>

  <div *ngIf="structuralChangeAllowed" class="add-button-wrapper">
    <button
      (click)="add()"
      class="eForms-button eForms-button--primary"
      attr.aria-label="{{ typeName }} hinzufügen">
      <mat-icon class="plus-icon">+</mat-icon>
      <span>{{ typeName }} hinzufügen</span>
    </button>
  </div>
</div>
