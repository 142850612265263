import { Injectable } from '@angular/core';
import { EditorConfigurationProvider } from './editor-configuration.provider';
import readonlyFields from './readonly-fields.json';
import { IdModel } from '../view-model/type/id-model';
import { BaseModel } from '../view-model/base-model';
import { GroupModel } from '../view-model/group-model';
import { RepeatableGroupModel } from '../view-model/repeatable-group-model';
import { RsEditorConfigurationProvider } from './rs-editor-configuration.provider';
import {
  OFFER_DEADLINE_DATE_ID,
  OFFER_DEADLINE_TIME_ID,
} from './view-model/evergabe-meta-data.service';
import { FormType } from '../types/form-type';

@Injectable()
export class ReadonlyService {
  constructor(private editorConfigurationProvider: EditorConfigurationProvider, private rsConfig: RsEditorConfigurationProvider) {}

  public isModelReadOnly(model: BaseModel): boolean {
    const staticReadOnlyFields = this.getReadonlyFieldsForEditorConfiguration();
    const staticReadOnlyGroups = this.getReadonlyGroupsForEditorConfiguration();

    // Read-Only by static Editor-Configuration fields
    if (this.isInReadOnlyList(staticReadOnlyFields, staticReadOnlyGroups, model)) {
      return true;
    }

    // Model specific read-only flag
    if (this.getReadOnlyForModel(model)) {
      return true;
    }

    return !!this.getParentGroups(model).find(groupModel =>
      this.isInReadOnlyList(staticReadOnlyFields, [], groupModel)
    );
  }

  getReadonlyFieldsForEditorConfiguration(): string[] {
    if (this.editorConfigurationProvider.isResetProcedure()) {
      return readonlyFields.resetProcedureFields;
    }
    if (this.editorConfigurationProvider.isMigrationToEForms()) {
      return readonlyFields.migrationFields;
    }
    if (
      this.editorConfigurationProvider.isChangeNotice()
      // ReSy: Pruefung auf AlterUpdateBeforeRelease
      || this.editorConfigurationProvider.isAlterUpdateBeforeRelease()
    ) {
      return readonlyFields.changeNoticeFields;
    }
    // ReSy: In der Vorlagenfunktion gibt es weitere Felder, die nicht bearbeitbar sein sollen
    if (this.rsConfig.isTemplateMode()) {
      return [
        ...readonlyFields.rsTemplateFields.fieldsWithPlaceholderValue,
        ...readonlyFields.rsTemplateFields.fieldsWithoutPlaceholderValue,
      ];
    }

    return [];
  }

  getReadonlyGroupsForEditorConfiguration(): string[] {
    if (this.editorConfigurationProvider.isChangeNotice()) {
      return readonlyFields.changeNoticeGroups;
    }

    return [];
  }

  private getReadOnlyForModel(model: BaseModel) {
    if (
      !this.editorConfigurationProvider.offerDeadlineChangeAllowed() &&
      (model.noticeNode.id.match('BT-131\\(.+\\)-Lot') ||
        model.noticeNode.id === OFFER_DEADLINE_DATE_ID ||
        model.noticeNode.id === OFFER_DEADLINE_TIME_ID)
    ) {
      return true;
    }

    if (
      !this.editorConfigurationProvider.participationRequestDateChangeAllowed() &&
      model.noticeNode.id.match('BT-1311\\(.+\\)-Lot')
    ) {
      return true;
    }

    // We are not allowed to change the Procedure Type when procedure without publishing
    if (
      this.editorConfigurationProvider.isWithoutPublication() &&
      model.noticeNode.id === 'BT-105-Procedure'
    ) {
      return true;
    }

    if (this.editorConfigurationProvider.isCancellation() && this.isReadonlyInCancellation(model)) {
      return true;
    }

    if (model.noticeNode.readOnly) {
      return true;
    }

    if (
      !this.editorConfigurationProvider.isRS() && // BT-22-Procedure ist fuer ReSy niemals readonly
      model.noticeNode?.id === 'BT-22-Procedure' &&
      (this.editorConfigurationProvider.isAlterBeforeRelease() ||
        (this.editorConfigurationProvider.isCreationMode() && [FormType.EXPOST, FormType.EXANTE, FormType.CONTRACT_MODIFICATION].includes(model.root.formType)))
    ) {
      return true;
    }

    return model instanceof IdModel;
  }

  private getParentGroups(model: BaseModel): (GroupModel | RepeatableGroupModel)[] {
    if (model.parent instanceof GroupModel || model.parent instanceof RepeatableGroupModel) {
      return this.getParentGroups(model.parent).concat(model.parent);
    }
    return [];
  }

  private isInReadOnlyList(readOnlyFields: string[], readOnlyGroups: string[], model: BaseModel) {
    const isReadOnly = readOnlyFields.some(value => model?.noticeNode?.id?.startsWith(value));

    if (isReadOnly) {
      return true;
    }

    return readOnlyGroups.some(value => model?.noticeNode?.id?.startsWith(value));
  }

  private isReadonlyInCancellation(model: BaseModel): boolean {
    if (this.editorConfigurationProvider.isMigrationToEForms()) {
      return false;
    }

    if (model.noticeNode.id === 'BT-142-LotResult') {
      return true;
    }

    if (readonlyFields.editableInCancellation.some(id => id === model.noticeNode?.id)) {
      return false;
    }

    return !this.getParentGroups(model).some(groupModel =>
      readonlyFields.editableInCancellation.some(
        readOnlyId => groupModel.noticeNode?.id === readOnlyId
      )
    );
  }
}
