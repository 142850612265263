import { Injectable } from '@angular/core';
import { ContentType, DisplayType } from '../../types/notice-definition';
import { GroupModel } from '../../view-model/group-model';
import { EVergabeMetaData, OfferType } from '../../view-model/e-vergabe-meta-data';
import { SectionModel } from '../../view-model/section-model';
import { DateModel } from '../../view-model/type/date-model';
import { FieldModel } from '../../view-model/field-model';
import { CodelistModel } from '../../view-model/type/codelist-model';
import { Project } from '../../types/project';
import { ComponentModel } from '../../view-model/component-model';
import { EditorConfigurationProvider } from '../editor-configuration.provider';
import { DateUtilsService } from '../../utils/date-utils-service';
import { TimeModel } from '../../view-model/type/time-model';
import { CommunicationService } from '../communication.service';
import { Severity, ValidationNotification } from '../../types/app-types';
import { ReadonlyService } from '../readonly.service';
import { FormType } from '../../types/form-type';
import { ModelStateService } from './model-state.service';
import { LotIdChange } from '../../types/lot-id-change';
import { RepeatableSectionModel } from '../../view-model/repeatable-section-model';
import { IdModel } from '../../view-model/type/id-model';
import { MetadataOffertypeCodelistModel } from '../../view-model/type/metadata-offertype-codelist-model';

@Injectable()
export class EVergabeMetaDataService {
  static METADATA_UPDATE_NOTE =
    'Bitte beachten Sie, dass Änderungen an den e-Vergabe Metadaten bei einer Aktualisierung der Bekanntmachung unmittelbar in der e-Vergabe wirksam werden. Dies gilt auch, wenn zeitgleich Änderungen an anderen Formulardaten vorgenommen wurden, die im Rahmen einer Änderungsbekanntmachung an den Vermittlungsdienst gesendet werden. Diese Daten sind erst nach erfolgreicher Veröffentlichung wirksam. Im Fall einer Ablehnung der Änderungsbekanntmachung durch den Vermittlungsdienst bleibt die Wirksamkeit vorab geänderter e-Vergabe Metadaten weiterhin bestehen.';

  private eVergabeMetaDataModel: GroupModel;
  private initialMetaData: EVergabeMetaData;

  constructor(
    private editorConfigurationProvider: EditorConfigurationProvider,
    private dateUtilsService: DateUtilsService,
    private communicationService: CommunicationService,
    private readonlyService: ReadonlyService
  ) {}

  public initMetaData(eVergabeMetaData: EVergabeMetaData) {
    this.initialMetaData = eVergabeMetaData;
  }

  public async createEVergabeMetadata(
    detailsSection: SectionModel,
    componentModel: ComponentModel
  ): Promise<GroupModel> {
    const eVergabeMetaDataGroup = this.createGroup(detailsSection, componentModel);

    this.addProject(eVergabeMetaDataGroup, componentModel);
    await this.addCategory(eVergabeMetaDataGroup, componentModel);
    this.addArchivingDate(eVergabeMetaDataGroup, componentModel);
    this.addFreelance(eVergabeMetaDataGroup, componentModel);
    this.addOfferDeadline(eVergabeMetaDataGroup, componentModel);
    this.addOfferType(eVergabeMetaDataGroup, componentModel);
    this.addClerkMailField(eVergabeMetaDataGroup, componentModel);

    this.eVergabeMetaDataModel = eVergabeMetaDataGroup;
    return eVergabeMetaDataGroup;
  }

  private createGroup(detailsSection: SectionModel, componentModel: ComponentModel): GroupModel {
    const eVergabeMetaDataGroup = new GroupModel();
    detailsSection.children.push(eVergabeMetaDataGroup);
    eVergabeMetaDataGroup.parent = detailsSection;
    eVergabeMetaDataGroup.root = componentModel;
    eVergabeMetaDataGroup.translatedLabel = 'e-Vergabe Metadaten';
    eVergabeMetaDataGroup.noticeNode = {
      id: EVERGABE_METADATA_ID,
      contentType: ContentType.GROUP,
      displayType: DisplayType.GROUP,
      description: '',
      _label: `field|name|${EVERGABE_METADATA_ID}`,
    };
    return eVergabeMetaDataGroup;
  }

  private addProject(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const project = new FieldModel<Project>();
    eVergabeMetaData.addChild(project);
    project.parent = eVergabeMetaData;
    project.root = componentModel;
    project.translatedLabel = 'Projekt';
    project.noticeNode = {
      id: PROJECT_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.TEXTBOX,
      description: '',
      _label: `field|name|${PROJECT_ID}`,
    };
    project.value = this.initialMetaData?.project;
  }

  private async addCategory(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const category = new CodelistModel();
    eVergabeMetaData.addChild(category);
    category.parent = eVergabeMetaData;
    category.root = componentModel;
    category.translatedLabel = 'Kategorie';
    category.noticeNode = {
      id: CATEGORY_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.COMBOBOX,
      description: '',
      _label: `field|name|${CATEGORY_ID}`,
    };
    category.fieldInfo = { mandatory: { value: true, severity: Severity.ERROR, constraints: [] } };
    category.value = this.initialMetaData?.categoryId;
    await this.loadEVergabeCategories(category);
  }

  private addArchivingDate(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const archiveDate = new DateModel();
    eVergabeMetaData.addChild(archiveDate);
    archiveDate.parent = eVergabeMetaData;
    archiveDate.root = componentModel;
    archiveDate.translatedLabel = 'Archivierungsfrist';
    archiveDate.noticeNode = {
      id: ARCHIVE_DATE_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.TEXTBOX,
      description: '',
      _label: `field|name|${ARCHIVE_DATE_ID}`,
    };
    archiveDate.minDate = this.editorConfigurationProvider.isTrainingMode()
      ? this.dateUtilsService.getToday()
      : this.dateUtilsService.getLocalDateOfTomorrow();
    archiveDate.fieldInfo = {
      dependencyFields: ['BT-131(d)-Lot', 'BT-98-Lot', OFFER_DEADLINE_DATE_ID],
      mandatory: { value: true, severity: Severity.ERROR, constraints: [] },
    };

    archiveDate.value = this.initialMetaData?.archiveDate
      ? this.dateUtilsService.toLocalIsoDateFormat(this.initialMetaData.archiveDate)
      : this.dateUtilsService.toLocalIsoDateFormat(this.dateUtilsService.getToday().plusMonths(6));
  }

  private addFreelance(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const freelance = new FieldModel<boolean>();
    eVergabeMetaData.addChild(freelance);
    freelance.parent = eVergabeMetaData;
    freelance.root = componentModel;
    freelance.translatedLabel = 'Freiberufler';
    freelance.noticeNode = {
      id: FREELANCE_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.RADIO,
      description: '',
      _label: `field|name|${FREELANCE_ID}`,
    };
    freelance.fieldInfo = { mandatory: { value: true, severity: Severity.ERROR, constraints: [] } };
    freelance.value = this.initialMetaData?.freelanceService;
    freelance.tooltips = [
      'Durch Ihre Angabe unterstützen Sie die Suche für Anbieter (wird nicht veröffentlicht)',
    ];
  }

  private addOfferType(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const offerType = new MetadataOffertypeCodelistModel();
    eVergabeMetaData.addChild(offerType);
    offerType.parent = eVergabeMetaData;
    offerType.root = componentModel;
    offerType.translatedLabel = 'Art der Abgabe von Teilnahmeanträgen / Angeboten ';
    offerType.noticeNode = {
      id: OFFER_TYPE_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.COMBOBOX,
      description: '',
      _label: `field|name|${OFFER_TYPE_ID}`,
    };
    offerType.fieldInfo = {
      mandatory: {
        value: false,
        severity: Severity.ERROR,
        constraints: [
          {
            value: true,
            noticeTypes: ['16', '17', '18', '19', '20', '21', '22', '23', '24'],
            severity: Severity.ERROR,
          },
        ],
      },
      forbidden: {
        value:
          !this.editorConfigurationProvider.hasLotSupport() ||
          this.editorConfigurationProvider.isSEB() ||
          this.editorConfigurationProvider.isMigrationToEForms(),
        severity: Severity.ERROR,
        constraints: [
          {
            value: true,
            noticeTypes: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '25',
              '26',
              '27',
              '28',
              '29',
              '30',
              '31',
              '32',
              '33',
              '34',
              '35',
              '36',
              '37',
              '38',
              '39',
              '40',
            ],
            severity: Severity.ERROR,
          },
        ],
      },
    };
    offerType.tooltips = [
      'Mit diesem Parameter können Sie steuern, ob Sie die Abgabe von Teilnahmeanträgen / Angeboten auf das einzelne Los bezogen oder für das ganze Verfahren entgegen nehmen. Entsprechend ändert sich die Abgabefunktion für den Anbieter. Bei Verfahren mit Verhandlungsrunden ist die Abgabe von Angeboten/Teilnahmeanträgen nur für das Verfahren möglich und nicht Los bezogen.',
    ];
    offerType.codeList = [
      { id: OfferType.PROCEDURE, label: 'Verfahren' },
      { id: OfferType.LOT, label: 'Lose' },
    ];

    offerType.value =
      this.editorConfigurationProvider.hasLotSupport() &&
      FormType.PRIOR_INFORMATION !== componentModel.formType
        ? this.initialMetaData?.offerType
        : null;

    offerType.isReadonly =
      this.editorConfigurationProvider.isChangeNotice() ||
      this.editorConfigurationProvider.isUpdateMetaData() ||
      this.editorConfigurationProvider.isUpdateAfterRejected();
  }

  private addOfferDeadline(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const offerDeadlineDate = new DateModel();
    eVergabeMetaData.addChild(offerDeadlineDate);
    offerDeadlineDate.parent = eVergabeMetaData;
    offerDeadlineDate.root = componentModel;
    offerDeadlineDate.translatedLabel =
      this.editorConfigurationProvider.participationRequestDateChangeAllowed()
        ? 'Vorläufige Angebotsfrist'
        : 'Angebotsfrist';
    offerDeadlineDate.fieldInfo = {
      dependencyFields: ['BT-131(d)-Lot'],
      mandatory: {
        value: false,
        severity: Severity.ERROR,
        constraints: [],
      },
    };
    offerDeadlineDate.noticeNode = {
      id: OFFER_DEADLINE_DATE_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.TEXTBOX,
      description: '',
      _label: `field|name|${OFFER_DEADLINE_DATE_ID}`,
      hidden: true,
    };
    offerDeadlineDate.minDate = this.editorConfigurationProvider.isTrainingMode()
      ? this.dateUtilsService.getToday()
      : this.dateUtilsService.getLocalDateOfTomorrow();
    offerDeadlineDate.tooltips = [
      'Bitte beachten Sie bei einer Fristsetzung kurz nach einem Wartungsfenster: Dies kann den Anbieter bei der Abgabe von Teilnahmeanträgen oder Angeboten behindern, da Wartungsfenster die verfügbare Zeit in der finalen Abgabephase beeinträchtigen',
    ];

    const offerDeadlineTime = new TimeModel();
    eVergabeMetaData.addChild(offerDeadlineTime);
    offerDeadlineTime.parent = eVergabeMetaData;
    offerDeadlineTime.root = componentModel;
    offerDeadlineTime.translatedLabel = 'Vorläufige Angebotsfrist';
    offerDeadlineTime.fieldInfo = {
      dependencyFields: ['BT-131(d)-Lot'],
      mandatory: {
        value: false,
        severity: Severity.ERROR,
        constraints: [],
      },
    };
    offerDeadlineTime.noticeNode = {
      id: OFFER_DEADLINE_TIME_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.TEXTBOX,
      description: '',
      _label: `field|name|${OFFER_DEADLINE_TIME_ID}`,
      hidden: true,
    };

    offerDeadlineDate.relatedTimeModel = offerDeadlineTime;
    offerDeadlineTime.relatedDateModel = offerDeadlineDate;

    offerDeadlineDate.isReadonly = this.readonlyService.isModelReadOnly(offerDeadlineDate);
    offerDeadlineTime.isReadonly = this.readonlyService.isModelReadOnly(offerDeadlineTime);

    if (this.initialMetaData?.provisionalOfferDeadline) {
      offerDeadlineDate.value = this.dateUtilsService.toLocalIsoDateFormat(
        this.initialMetaData.provisionalOfferDeadline.toLocalDate()
      );

      offerDeadlineTime.value = offerDeadlineTime.value =
        this.dateUtilsService.toLocalIsoTimeFormatTruncToSec(
          this.initialMetaData.provisionalOfferDeadline.toLocalTime()
        );
    }
  }

  private addClerkMailField(eVergabeMetaData: GroupModel, componentModel: ComponentModel) {
    const clerkMailModel = new FieldModel<string>();
    clerkMailModel.value = this.editorConfigurationProvider.getClerkMail();
    clerkMailModel.fieldInfo = {
      dependencyFields: [],
      mandatory: {
        value: false,
        severity: Severity.ERROR,
        constraints: [],
      },
    };
    clerkMailModel.noticeNode = {
      id: CLERK_MAIL_ID,
      contentType: ContentType.FIELD,
      displayType: DisplayType.TEXTBOX,
      description: '',
      _label: `field|name|${CLERK_MAIL_ID}`,
      readOnly: true,
    };
    clerkMailModel.isReadonly = true;
    clerkMailModel.translatedLabel = 'E-Mail-Kontaktadresse';
    clerkMailModel.tooltips = [
      'Mit eForms benötigt die EU die Emailadresse des Autors des Verfahrens zur Kontaktaufnahme im Falle einer Ablehnung. Diese Emailadresse wird automatisch übermittelt und Sie müssen nichts weiter tun. Die Anzeige dient der reinen Information. Die Emailadresse wird an keine andere Stelle außer der EU kommuniziert.',
    ];

    clerkMailModel.parent = eVergabeMetaData;
    clerkMailModel.root = componentModel;

    // See EVKANBAN-2765
    if (
      // ReSy: Pruefung auf AlterUpdateBeforeRelease
      (this.editorConfigurationProvider.isChangeNotice() || this.editorConfigurationProvider.isAlterUpdateBeforeRelease()) &&
      [FormType.NOTICE, FormType.PRIOR_INFORMATION].includes(componentModel.formType)
    ) {
      clerkMailModel.fieldNotifications.push(
        new ValidationNotification(EVergabeMetaDataService.METADATA_UPDATE_NOTE, Severity.INFO)
      );
    }
    eVergabeMetaData.addChild(clerkMailModel);
  }

  get eVergabeMetaData(): EVergabeMetaData {
    if (!this.eVergabeMetaDataModel) return undefined;
    const project = ModelStateService.findModelById(
      this.eVergabeMetaDataModel,
      PROJECT_ID
    ) as FieldModel<Project>;
    const category = ModelStateService.findModelById(
      this.eVergabeMetaDataModel,
      CATEGORY_ID
    ) as CodelistModel;
    const archiveDate = ModelStateService.findModelById(
      this.eVergabeMetaDataModel,
      ARCHIVE_DATE_ID
    ) as DateModel;
    const freelance = ModelStateService.findModelById(
      this.eVergabeMetaDataModel,
      FREELANCE_ID
    ) as FieldModel<boolean>;
    const provisionalOfferDeadline = ModelStateService.findModelById(
      this.eVergabeMetaDataModel,
      OFFER_DEADLINE_DATE_ID
    ) as DateModel;
    const offerType = ModelStateService.findModelById(
      this.eVergabeMetaDataModel,
      OFFER_TYPE_ID
    ) as CodelistModel;

    return new EVergabeMetaData(
      project.value,
      category.value,
      archiveDate.asLocalDate(),
      freelance.value,
      provisionalOfferDeadline.withRelatedTime(),
      OfferType[offerType.value],
      this.getLotIdChanges()
    );
  }

  /**
   * Creates a simple LotIdChange List, which contains all Lots, which have been deleted, created or , because a successor has been deleted.
   *
   * @private
   */
  private getLotIdChanges(): LotIdChange[] {
    if (!this.editorConfigurationProvider.isAlterBeforeRelease()) {
      // Lot-Altering is only possible in AlterBeforeRelease
      return [];
    }

    const lotIdChanges: LotIdChange[] = [];

    // Deleted Lots
    this.eVergabeMetaDataModel.root.deletedInitialLotIds.forEach(deletedLotSchemeId => {
      lotIdChanges.push({ oldLotIdentifier: deletedLotSchemeId, newLotIdentifier: null });
    });

    // Current existing Lots
    this.eVergabeMetaDataModel.root.children.forEach(section => {
      if (section instanceof RepeatableSectionModel) {
        section.children.forEach(lotOrPart => {
          const repeatableSectionIdentifierFieldId = lotOrPart.noticeNode._identifierFieldId;
          const idModel = ModelStateService.findModelById(
            lotOrPart,
            repeatableSectionIdentifierFieldId
          ) as IdModel;

          lotIdChanges.push({
            oldLotIdentifier: idModel.initialValue ?? null,
            newLotIdentifier: idModel.value,
          });
        });
      }
    });

    return lotIdChanges;
  }

  private async loadEVergabeCategories(codelistModel: CodelistModel): Promise<void> {
    if (this.editorConfigurationProvider.isOBA()) {
      const categories = await this.communicationService.getCategories();
      categories.sort((a, b) => a.title.localeCompare(b.title));
      codelistModel.codeList = categories.map(category => ({
        id: String(category.id),
        label: category.title,
      }));
    }
  }
}

export const EVERGABE_METADATA_ID = 'evergabe-meta-data';
export const PROJECT_ID = 'project';
export const ARCHIVE_DATE_ID = 'archive-date';
export const OFFER_DEADLINE_DATE_ID = 'offer-deadline-date';
export const OFFER_DEADLINE_TIME_ID = 'offer-deadline-time';
export const CLERK_MAIL_ID = 'clerk-mail';
export const CATEGORY_ID = 'category';
export const FREELANCE_ID = 'freelance';
export const OFFER_TYPE_ID = 'offerType';
export const DETAILS_ID = 'details';
