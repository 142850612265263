import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {

  private router = inject(Router);
  private route = inject(ActivatedRoute);

  routeData$: Observable<unknown>;

  constructor() {
    /**
     * Basierend auf https://stackoverflow.com/a/58654690/5276055 und https://ultimatecourses.com/blog/dynamic-page-titles-angular-2-router-events.
     */
    this.routeData$ = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    );
  }

}
