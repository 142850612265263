import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchResult } from '../../../../types/app-types';
import { SearchService } from '../../../../services/search/search.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements AfterContentInit {
  searchControl = new FormControl('');
  minimumCharsReached = false;
  $isSearchActive = new BehaviorSubject(false);
  visibleItems = 10;
  searchResults: SearchResult[] = [];
  focusFirstResultEmitter = new EventEmitter<void>();

  constructor(private searchService: SearchService, private renderer: Renderer2) {
    this.searchControl.valueChanges.subscribe(this.triggerSearch.bind(this));
  }

  ngAfterContentInit(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      const search = document.getElementById('search');
      if (search && !search.contains(e.target as Node)) {
        this.$isSearchActive.next(false);
      }
    });
  }

  onFocus() {
    if (this.searchControl.value) {
      this.triggerSearch(this.searchControl.value);
    } else {
      this.minimumCharsReached = this.searchControl.value.length >= 3;
      this.$isSearchActive.next(true);
    }
  }

  triggerSearch(value: string) {
    this.$isSearchActive.next(true);
    this.visibleItems = 10;
    this.minimumCharsReached = value.length >= 3;
    if (this.minimumCharsReached) {
      this.searchResults = this.searchService.search(value);
    } else {
      this.searchResults = [];
    }
  }

  onNavigateOut() {
    this.$isSearchActive.next(false);
  }

  focusOrClearSearch() {
    if (this.searchControl.value) {
      this.searchControl.setValue('');
    }

    setTimeout(() => {
      document.getElementById('search-input').focus();
    });
  }

  onShowAllResults() {
    this.visibleItems = 999999;
    this.focusFirstResultEmitter.emit();
  }

  hasResults(): boolean {
    return this.searchResults.length > 0;
  }
}
