import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SkeletonComponent } from './skeleton.component';

@Directive({ selector: '[skeleton]' })
export class SkeletonDirective implements OnChanges {
  private _isLoading = false;
  @Input()
  set skeleton(value: boolean) {
    this._isLoading = value;
  }

  private _size = 1;
  @Input()
  set skeletonRepeat(value: number) {
    this._size = value;
  }

  private _width: string;
  @Input()
  set skeletonWidth(value: string) {
    this._width = value;
  }

  private _height: string;
  @Input()
  set skeletonHeight(value: string) {
    this._height = value;
  }

  private _className: string;
  @Input()
  set skeletonClassName(value: string) {
    this._className = value;
  }

  private _type: string;
  @Input()
  set skeletonType(value: string) {
    this._type = value;
  }

  constructor(private tpl: TemplateRef<any>, private vcr: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.vcr.clear();

    if (this._isLoading) {
      Array.from({ length: this._size }).forEach(() => {
        const ref = this.vcr.createComponent(SkeletonComponent);

        Object.assign(ref.instance, {
          width: this._width === 'rand' ? `${Math.random() * 100}%` : this._width,
          height: this._height,
          className: this._className,
          type: this._type || 'default',
        });
        ref.changeDetectorRef.detectChanges();
      });
    } else {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }
}
