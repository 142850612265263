<app-template [hasContentFrame]="true" [titel]="'Kontakt'">
  <p>
    Wenn Sie <strong>Fragen zur Registrierung</strong> oder <strong>Probleme bei der Anmeldung</strong> haben, senden Sie uns bitte eine E-Mail an folgende E-Mail-Adresse:
    <a href="mailto:resy-doe@bescha.bund.de">resy-doe@bescha.bund.de</a>
  </p>
  <p>
    Wenn Sie Verbesserungsvorschläge, Anregungen und Hinweise für uns haben, nutzen Sie gerne unser Kontaktformular. Wir freuen uns über jede Kontaktaufnahme.
  </p>
  <iframe src="https://form.jotform.com/242214184793357" width="820" height="1165" style="border: 0; outline: 0;"></iframe>
</app-template>
