import { EVergabeSpecificValidator } from '../ModelValidator';
import { FieldModel } from '../../../../view-model/field-model';
import { ValidationNotification } from '../../../../types/app-types';
import { CommunicationService } from '../../../communication.service';
import { DateModel } from '../../../../view-model/type/date-model';
import {
  ARCHIVE_DATE_ID,
  OFFER_DEADLINE_DATE_ID,
} from '../../../view-model/evergabe-meta-data.service';
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core';
import { FormType } from '../../../../types/form-type';
import { FormTypeService } from '../../../form-type.service';
import { EditorConfigurationProvider } from '../../../editor-configuration.provider';
import { ModelStateService } from '../../../view-model/model-state.service';
import { DateUtilsService } from '../../../../utils/date-utils-service';
import { ProcedureTypeService } from '../../../procedure-type.service';
import { CodelistModel } from '../../../../view-model/type/codelist-model';

export class ArchivingDateBehindAllOfferDeadlinesValidator extends EVergabeSpecificValidator<DateModel> {
  constructor(
    private formTypeService: FormTypeService,
    private editorConfigurationProvider: EditorConfigurationProvider,
    private communicationService: CommunicationService,
    private dateUtils: DateUtilsService,
    private procedureTypeService: ProcedureTypeService
  ) {
    super();
  }

  shouldAttach(model: FieldModel<string>): boolean {
    return model.noticeNode.id === ARCHIVE_DATE_ID;
  }

  async validate(model: DateModel): Promise<ValidationNotification[]> {
    const twoDaysBeforeArchivingDate = model.asLocalDate().minusDays(2).atStartOfDay();
    const latestOfferDeadlineDate = await this.getLatestOfferDeadlineDate(model);

    if (!latestOfferDeadlineDate) {
      return this.empty();
    }

    if (twoDaysBeforeArchivingDate.isBefore(latestOfferDeadlineDate)) {
      const formattedLatestOfferDeadlineDate = latestOfferDeadlineDate.format(
        DateTimeFormatter.ofPattern('d.M.yyyy')
      );
      return this
        .ofSingle(`Die Archivierungsfrist muss mindestens zwei Tage nach der Angebotsfrist aller Verhandlungsrunden liegen.
      Die letzte Angebotsfrist endet am (${formattedLatestOfferDeadlineDate}).`);
    }

    return this.empty();
  }

  private async getLatestOfferDeadlineDate(model: DateModel) {
    const formType = await this.formTypeService.getFormType(model.root.noticeId);
    const isChangeNotice = this.editorConfigurationProvider.isChangeNotice();
    // ReSy: Pruefung auf AlterUpdateBeforeRelease
    const isUpdateChangeNotice = this.editorConfigurationProvider.isAlterUpdateBeforeRelease();

    if (formType === FormType.PRIOR_INFORMATION) {
      // no offer deadline available yet, therefore return null
      return null;
    }

    if (this.editorConfigurationProvider.isUpdateMetaData()) {
      // offer deadline not loaded/present, because not adjustable, therefore query latest offer deadline from db
      return this.queryLatestOfferDeadline();
    }

    const resolvedOfferDeadlineModel = this.resolveOfferDeadline(model);

    if (formType === FormType.NOTICE && !isChangeNotice && !isUpdateChangeNotice) {
      // only the offer deadline of the form input is decisive
      return resolvedOfferDeadlineModel;
    }

    if (this.offerDeadlinePresentAndNotExpired(resolvedOfferDeadlineModel)) {
      // if the offer deadline has not yet expired, take the form input, as no negotiation rounds are possible
      if (isChangeNotice || isUpdateChangeNotice) {
        // return the more recent date to validate against, cause the input value is still pending for 48h and could be rejected
        const persistedValidOfferDeadline = await this.queryLatestOfferDeadline();
        return persistedValidOfferDeadline?.isAfter(resolvedOfferDeadlineModel)
          ? persistedValidOfferDeadline
          : resolvedOfferDeadlineModel;
      }
      return resolvedOfferDeadlineModel;
    } else {
      // but check for possible negotiation rounds, if the offer deadline has expired
      return this.queryLatestOfferDeadline();
    }
  }

  private offerDeadlinePresentAndNotExpired(resolvedOfferDeadlineFormInput: LocalDateTime) {
    const now = this.dateUtils.getLocalDateTime();
    return resolvedOfferDeadlineFormInput && !resolvedOfferDeadlineFormInput.isBefore(now);
  }

  private resolveOfferDeadline(model: DateModel): LocalDateTime {
    const procedureType = ModelStateService.findModelById(model.root, 'BT-105-Procedure');

    if (
      this.procedureTypeService.isProcedureTypeWithParticipationPhase(
        (procedureType as CodelistModel)?.value
      )
    ) {
      return this.getOfferDeadline(model, OFFER_DEADLINE_DATE_ID);
    } else {
      return this.getOfferDeadline(model, 'BT-131(d)-Lot');
    }
  }

  private getOfferDeadline(model: DateModel, id: string) {
    const offerDeadlineDateModel = ModelStateService.findModelById(model.root, id) as DateModel;

    if (!offerDeadlineDateModel) {
      return null;
    }

    return offerDeadlineDateModel.withRelatedTime();
  }

  private queryLatestOfferDeadline() {
    const procedureId = this.editorConfigurationProvider.getTenderingProcedureId();
    if (procedureId) {
      return this.communicationService.getLatestOfferDeadline(procedureId.toString());
    } else {
      return null;
    }
  }
}
