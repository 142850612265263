import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Directive({
  selector: '[hasAnyRole]'
})
export class HasAnyRoleDirective {

  private roles: string[] = [];
  private invert: boolean;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private keycloak: KeycloakService,
  ) {
  }

  @Input()
  set hasAnyRole(roles: string[]) {
    this.roles = roles;
    this.updateView();
  }

  @Input()
  set hasAnyRoleInvert(invert: boolean) {
    this.invert = invert;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    const hasAnyRole = this.roles.some(role => this.keycloak.isUserInRole(role));
    return this.invert ? !hasAnyRole : hasAnyRole;
  }
}
