import { DateModel } from '../../../../view-model/type/date-model';
import { ValidationNotification } from '../../../../types/app-types';
import { EVergabeSpecificValidator } from '../ModelValidator';
import { BaseModel } from '../../../../view-model/base-model';
import { OFFER_DEADLINE_DATE_ID } from '../../../view-model/evergabe-meta-data.service';
import { ModelStateService } from '../../../view-model/model-state.service';
import { LocalDate, LocalTime } from '@js-joda/core';

export class MetaOfferDeadlineNotBeforeLastParticipationDeadline extends EVergabeSpecificValidator<DateModel> {
  private static readonly META_OFFER_DEADLINE_BEFORE_LAST_PUBLISHED_PARTICIPATION_DEADLINE_MSG =
    'Die Angebotsfrist darf nicht vor der zuletzt veröffentlichten Teilnahmefrist liegen. Bitte aktualisieren Sie zunächst nur die Teilnahmefrist und ändern Sie die Angebotsfrist erst, nachdem die neue Teilnahmefrist veröffentlicht wurde.';

  validate(dateModel: DateModel): Promise<ValidationNotification[]> {
    const participationDeadlineModels = ModelStateService.findModelsById(
      dateModel.root,
      'BT-1311(d)-Lot'
    ) as DateModel[];

    for (const participationDeadlineModel of participationDeadlineModels) {
      if (participationDeadlineModel?.value && participationDeadlineModel?.initialValue) {
        const initialLocalDate = LocalDate.parse(participationDeadlineModel.initialValue);
        const initialLocalTime = LocalTime.parse(
          participationDeadlineModel.relatedTimeModel.initialValue
        );

        if (!dateModel.withRelatedTime().isAfter(initialLocalDate.atTime(initialLocalTime))) {
          return this.ofSingle(
            MetaOfferDeadlineNotBeforeLastParticipationDeadline.META_OFFER_DEADLINE_BEFORE_LAST_PUBLISHED_PARTICIPATION_DEADLINE_MSG
          );
        }
      }
    }

    return this.empty();
  }

  shouldAttach(model: BaseModel): boolean {
    return model.noticeNode?.id === OFFER_DEADLINE_DATE_ID;
  }
}
