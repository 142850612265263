<app-template [hasContentFrame]="true" [titel]="'Impressum'">
  <section>
    <h3 style="font-weight: bold">Adresse</h3>
    <p>Beschaffungsamt des BMI<br />- E-Beschaffung –</p>
    <p>
      Brühler Straße 3<br />
      53119 Bonn<br />
      Deutschland
    </p>
    <p>Telefon: +49 22899 610-0</p>
  </section>
  <section>
    <h3 style="font-weight: bold">Kontakt</h3>
    <p>
      E-Mail: <a href="mailto:resy-doe@bescha.bund.de">resy-doe@bescha.bund.de</a>
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">Rechtliche Hinweise</h3>
    <h3 style="font-weight: bold">Haftungsausschluss:</h3>
    <p>
      Alle Angaben auf der Website des Redaktionssystems wurden sorgfältig geprüft. Wir bemühen uns,
      dieses aus zugelieferten Daten bestehende Informationsangebot aktuell und inhaltlich richtig
      sowie vollständig anzubieten. Dennoch ist das Auftreten von Fehlern nicht völlig
      auszuschließen. Eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität kann
      daher nicht übernommen werden.
    </p>
    <p>
      Der Herausgeber kann diese Website nach eigenem Ermessen jederzeit ohne Ankündigung verändern.
    </p>
    <p>
      Der Zugang und die Benutzung dieser Webseite erfolgen auf eigene Gefahr des Benutzers. Das
      Beschaffungsamt des BMI ist nicht verantwortlich und übernimmt keinerlei Haftung für Schäden,
      u.a. für direkte, indirekte, zufällige, vorab konkret zu bestimmende oder Folgeschäden, die
      angeblich durch den oder in Verbindung mit dem Zugang und/oder der Benutzung dieser Website
      aufgetreten sind.
    </p>
    <p>
      Der Betreiber übernimmt keine Verantwortung für die Inhalte und die Verfügbarkeit von Websites
      Dritter, die über externe Links dieses Informationsangebotes erreicht werden. Der Herausgeber
      distanziert sich ausdrücklich von allen Inhalten, die möglicherweise straf- oder
      haftungsrechtlich relevant sind oder gegen die guten Sitten verstoßen.
    </p>
    <p>
      Sofern innerhalb der Website die Möglichkeit zur Eingabe persönlicher oder geschäftlicher
      Daten (E-Mailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten
      seitens des Nutzers auf ausdrücklich freiwilliger Basis.
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">Grafiken</h3>
    <p>
      Alle auf dieser Website zitierten Warenzeichen, Produktnamen und Firmennamen sowie abgebildete
      Logos stehen im Eigentum der jeweiligen Inhaber.
    </p>
    <p>
      Hinweise zum Datenschutz sind auf der Startseite des Redaktionssystems unter „Datenschutz“ zu
      finden.
    </p>
  </section>
  <section>
    <h3 style="font-weight: bold">Anbieter</h3>
    <p>
      Bundesrepublik Deutschland, letztvertreten durch das Beschaffungsamt des BMI.
    </p>
  </section>
</app-template>
